import React, { useState } from 'react';
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBIcon,
} from 'mdbreact';

const ImageZoomModal = ({ src, alt }) => {
  const [modal, setModal] = useState(false);
  const [zoom, setZoom] = useState(1);
  const toggle = () => setModal(!modal);

  const handleZoomIn = () => {
    setZoom(zoom * 1.2); // Aumenta o zoom em 20%
  };

  const handleZoomOut = () => {
    setZoom(zoom / 1.2); // Diminui o zoom em 20%
  };

  const handleZoomReset = () => {
    setZoom(1); // Reseta o zoom para 100%
  };

  return (
    <MDBContainer>
      <div
        style={{
          backgroundImage: `url(${src})`,
          backgroundSize: 'contain', // Mantém toda a imagem visível
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          height: '200px',
          cursor: 'pointer',
        }}
        className="bg-image"
        onClick={toggle}
      ></div>

      <MDBModal isOpen={modal} toggle={toggle} centered size="fluid">
        <MDBModalBody style={{ textAlign: 'center' }}>
          <div style={{ overflow: 'auto', maxHeight: '80vh' }}>
            <img
              src={src}
              alt={alt}
              style={{
                transform: `scale(${zoom})`,
                transition: 'transform 0.3s ease',
                transformOrigin: 'top left', // Ajuste aqui para encontrar a posição ideal
              }}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <MDBIcon
              icon="search-plus"
              size="2x"
              className="mr-5"
              style={{ cursor: 'pointer' }}
              onClick={handleZoomIn}
            />
            <MDBIcon
              icon="search-minus"
              size="2x"
              className="mr-5"
              style={{ cursor: 'pointer' }}
              onClick={handleZoomOut}
            />
            <MDBIcon
              fas
              icon="compress"
              size="2x"
              className="mr-5"
              style={{ cursor: 'pointer' }}
              onClick={handleZoomReset}
            />
            <MDBIcon
              fas
              icon="times"
              size="2x"
              style={{ cursor: 'pointer' }}
              onClick={toggle}
            />
          </div>
        </MDBModalBody>
      </MDBModal>
    </MDBContainer>
  );
};

export default ImageZoomModal;
