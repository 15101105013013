import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCard,
  MDBCardBody,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Loading from '../../../components/Loading';

import SendImg from '../../../components/SendImg';
import Select from 'react-select';

// import { Container } from './styles';

export default function App() {
  let history = useHistory();
  const [abordagens, setAbordagens] = useState([]);
  const [loading, setLoading] = useState(false);

  const [qtdTotal, setQtdTotal] = useState(0);
  const [page, setPage] = useState('1');

  const [modalFoto, setModalFoto] = useState(null);

  const [selectRevisao, setSelectRevisao] = useState({ label: 'Todas' });

  const [idAbordagem, setIdAbordagem] = useState(null);
  const [searchAbordagem, setSearchAbordagem] = useState(false);

  const minhasAbordagens = async () => {
    setLoading(true);
    const params = { pag: page };
    if (selectRevisao.value === 'idabordagem') {
      if (idAbordagem === null) {
        setAbordagens([]);
        setLoading(false);
        setQtdTotal(1);
        return;
      }
      params.abordagem_id = idAbordagem;
    } else {
      params.revisao = selectRevisao.value;
    }

    let result = await api.get('/minhas-abordagens', {
      params,
    });

    result.data.rows.map(abord => {
      let qtdRev = 0;
      let qtdAb = 0;
      abord.sgo_revisaos.forEach(rev => {
        if (rev.data_final === null) {
          if (rev.data_resposta === null) {
            qtdRev++;
          } else {
            qtdAb++;
          }
        }
      });

      const newRow = [];
      abord.ocorrencia.forEach(oco => {
        if (oco.ativo === true) newRow.push(oco);
      });
      abord.revisao_aberta = qtdRev;
      abord.revisao_resposta = qtdAb;

      abord.ocorrencia = newRow;
    });

    setAbordagens(result.data.rows);

    setQtdTotal(Math.ceil(Number(result.data.count) / Number(10)));
    setLoading(false);
  };

  useEffect(() => {
    minhasAbordagens();
  }, [page]);

  useEffect(() => {
    if (selectRevisao.value === 'idabordagem') {
      setSearchAbordagem(true);
      setAbordagens([]);
      setPage(1);
      return;
    } else {
      setIdAbordagem(null);
      setSearchAbordagem(false);
    }
    page === 1 && minhasAbordagens();
    setPage(1);
  }, [selectRevisao]);

  useEffect(() => {
    minhasAbordagens();
  }, []);

  return (
    <NavBar>
      <MDBContainer fluid>
        <MDBRow className="h4-responsive" center>
          Abordagens
        </MDBRow>
        <MDBRow className="h4-responsive justify-content-center ">
          <MDBBtn onClick={() => history.push(`/add-abordagem`)}>
            Nova Abordagem
          </MDBBtn>
        </MDBRow>
        <MDBRow center>Minhas Abordagens</MDBRow>

        <MDBCard>
          <MDBCardBody>
            <MDBRow className="align-items-center">
              <MDBCol
                className="mx-3 align-items-center justify-content-center"
                size="11"
                md="3"
              >
                <Select
                  options={[
                    { label: 'Todas', value: 'Todas' },
                    { label: 'Aberta', value: 'Aberta' },
                    { label: 'Respondida', value: 'Respondida' },
                    { label: 'Finalizada', value: 'Finalizada' },
                    { label: 'ID ABORDAGEM', value: 'idabordagem' },
                  ]}
                  onChange={e => setSelectRevisao(e)}
                  value={selectRevisao}
                />
              </MDBCol>

              {searchAbordagem === true && (
                <>
                  <MDBCol col="12" md="6">
                    <MDBInput
                      label="Número da Abordagem"
                      outline
                      type="number"
                      maxLength="11"
                      onChange={e => setIdAbordagem(e.target.value)}
                      value={idAbordagem}
                      // onChange={e => setRenavam(e.target.value.toUpperCase())}
                      // value={renavam}
                      // onChange={e => this.setState({ renavam: e.target.value })}
                    />
                  </MDBCol>
                  <MDBCol className="text-center">
                    <MDBBtn onClick={() => minhasAbordagens()} size="sm">
                      Buscar
                    </MDBBtn>
                  </MDBCol>
                </>
              )}
            </MDBRow>
          </MDBCardBody>
        </MDBCard>

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="1">
            N:
          </MDBCol>
          <MDBCol size="12" md="3">
            Gerência / Ala
          </MDBCol>
          <MDBCol size="12" md="3">
            Início da Abordagem:
          </MDBCol>
          <MDBCol size="12" md="2">
            Ocorrências:
          </MDBCol>
          <MDBCol size="12" md="2"></MDBCol>
          {/* <div className="col-12 col-md-3 font-weight-bold text-white">
            Gerência / Ala
          </div>
          <div className="col-12 col-md-3 font-weight-bold text-white">
            Início da Abordagem:
          </div>

          <div className="col-12 col-md-1 font-weight-bold text-white">
            Ocorrências:
          </div>
          <div className="col-12 col-md-3 d-flex font-weight-bold text-white">
            sd
          </div> */}
          {/* <div className="col-12 col-md-1 d-flex flex-column font-weight-bold text-white">
            <span>Frequência</span> */}
          {/*
                    <span className="text-danger">Ausentes</span> */}
          {/* </div> */}
          <div className="col-12 col-md-1 d-flex justify-content-between font-weight-bold text-white"></div>
        </MDBRow>
        {loading === true ? (
          <Loading />
        ) : (
          abordagens.length !== 0 &&
          abordagens.map(abordagem => (
            <>
              <MDBRow
                key={abordagem.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol size="12" md="1">
                  {abordagem.id}
                </MDBCol>
                <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start align-items-center">
                  {/* {abordagem.sgo_revisaos.length > 0 &&
                    abordagem.sgo_revisaos.map(rev => {
                      rev.revisor_final_id === null ?
                      return (

                      );
                    })} */}

                  {abordagem.revisao_aberta > 0 ? (
                    abordagem.revisao_aberta > 1 ? (
                      <>
                        <span className="font-weight-bolder mr-1">
                          {abordagem.revisao}
                        </span>
                        <MDBIcon className="mr-2 red-text" icon="circle" />
                      </>
                    ) : (
                      <MDBIcon className="mr-2 red-text" icon="circle" />
                    )
                  ) : abordagem.revisao_resposta > 0 ? (
                    <MDBIcon className="mr-2 blue-text" icon="circle" />
                  ) : (
                    ''
                  )}
                  {`${abordagem.lotacao.gerencia.nome} / ${abordagem.lotacao.ala.nome}`}
                </div>
                <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                  <span className="d-sm-inline d-md-none mr-3">Dia Início</span>

                  {format(
                    zonedTimeToUtc(
                      new Date(abordagem.dt_h_inicio),
                      'America/Sao_Paulo'
                    ),
                    'dd/MM/yyyy - HH:mm'
                  )}
                </div>
                <div className="col-12 offset-md-1 col-md-1 mb-md-0 text-center text-md-center font-weight-bolder">
                  <span className="d-sm-inline d-md-none mr-3">
                    Ocorrências:
                  </span>

                  {abordagem.ocorrencia.length}
                </div>

                <div className="col-12  col-md-1  mt-3 mt-md-0 col-md-1 d-flex justify-content-center justify-md-content-end align-items-center font-weight-bold cursor-pointer">
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={() => setModalFoto(abordagem.id)}
                  >
                    <MDBIcon icon="camera" />
                    <div className="ml-2">Fotos</div>
                  </div>
                </div>
                <div className="col-12 col-md-2  mt-3 mt-md-0 d-flex justify-content-center justify-md-content-end">
                  <Link
                    to={`/add-ocorrencia/${abordagem.id}`}
                    className="blue-grey-text"
                  >
                    <div
                      className="d-flex align-items-center font-weight-bold text-success"
                      style={{ cursor: 'pointer' }}
                      // onClick={() => {
                      //   carregaDadosDeServico(linha.id);

                      //   visualizaServico();
                      // }}
                    >
                      <MDBIcon icon="plus" />
                      {/* <MDBIcon fas icon="expand-arrows-alt" /> */}
                      <div className="ml-2">Ocorrência</div>
                    </div>
                  </Link>
                </div>

                <div className="col-12 col-md-1 mt-2 mt-md-0  d-flex justify-content-center justify-content-md-end">
                  <Link
                    to={`/abordagem/${abordagem.id}`}
                    className="blue-grey-text"
                  >
                    <div
                      className="d-flex mr-3 align-items-center font-weight-bold"
                      style={{ cursor: 'pointer', color: '#59698d' }}
                    >
                      <MDBIcon far icon="eye" />

                      <div className="ml-2">Ver</div>
                    </div>
                  </Link>
                </div>
              </MDBRow>
            </>
          ))
        )}
        {loading === true ? (
          ''
        ) : (
          <MDBRow center className="my-4 align-items-center">
            <div>
              Página {page} de {qtdTotal}
            </div>
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page == 1) {
                  return;
                }
                setPage(Number(page) - 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-left"
            />
            |
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page >= qtdTotal) {
                  return;
                }
                setPage(Number(page) + 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-right"
            />
          </MDBRow>
        )}
      </MDBContainer>
      {/* MODAL PARA CRIACAO DE VEICULOS */}
      <MDBModal
        isOpen={modalFoto}
        toggle={() => {
          setModalFoto(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalFoto(null);
          }}
        >
          Inserir Foto
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <MDBCol size="12" middle>
              <SendImg
                // abordagem_id={modalFoto}
                maxImages="5"
                getFotos={{
                  buscaFotosURL: '/sgo-imagem',
                  buscaFotosWhere: { abordagem_id: modalFoto },
                }}
                postFotos={{
                  enviarFotoURL: '/sgo-insere-imagem',
                  enviarRefId: { abordagem_id: modalFoto },
                }}
                deleteFotos={{
                  deletarFotoURL: '/sgo-delete-img',
                  // refId: { id: modalFoto },
                }}
              />
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter center>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalFoto(null);
            }}
          >
            Cancelar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </NavBar>
  );
}
