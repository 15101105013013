import React from 'react';
import { useState, useEffect } from 'react';
import api from '../../services/api';
import Select from 'react-select';
import { getData } from '../../services/auth';
import { Pie, Line } from 'react-chartjs-2';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBTooltip,
  MDBIcon,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { format, addHours, differenceInDays, parseISO } from 'date-fns';
import { toast } from 'react-toastify';

// import { Container } from './styles';

function ServicoEstatisticas() {
  const [dadosGerencias, setDadosGerencias] = useState([]);
  const [optionsEscala, setOptionsEscala] = useState([]);

  const [gerenciaSelecionada, setGerenciaSelecionada] = useState();

  const [lotacaoSelecionada, setLotacaoSelecionada] = useState('');
  const [dataInicioSelecionada, setDataInicioSelecionada] = useState('');
  const [dataFimSelecionada, setDataFimSelecionada] = useState('');

  const [dadosLicencaSaude, setDadosLicencaSaude] = useState([]);
  const [dadosFerias, setDadosFerias] = useState([]);

  // configuracao inicial do grafico de pizza
  const [dataPie, setDataPie] = useState({
    labels: ['', ''],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#F7464A',
          '#46BFBD',
          '#FDB45C',
          '#949FB1',
          '#4D5360',
          '#AC64AD',
        ],
        hoverBackgroundColor: [
          '#FF5A5E',
          '#5AD3D1',
          '#FFC870',
          '#A8B3C5',
          '#616774',
          '#DA92DB',
        ],
      },
    ],
  });

  // configuracao inicial do grafico de linha
  const [dataLine, setDataLine] = useState({
    labels: [],
    datasets: [
      {
        label: 'Ausência',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(225, 204,230, .3)',
        borderColor: 'rgb(205, 130, 158)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(205, 130, 158)',
        pointBackgroundColor: 'rgb(255, 255, 255)',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(0, 0, 0)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
      {
        label: 'Presenças',
        fill: false,
        lineTension: 0.1,
        backgroundColor: 'rgba(184, 185, 210, .3)',
        borderColor: 'rgb(35, 26, 136)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(35, 26, 136)',
        pointBackgroundColor: 'rgb(255, 255, 255)',
        pointBorderWidth: 5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(0, 0, 0)',
        pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: [],
      },
    ],
  });

  const [tabelaAusencias, setTabelaAusencias] = useState([]);

  const geraGraficoDeLinha = async () => {
    const estatisticas = await api.get('/servico-estatisticas-linha', {
      params: {
        gerencia_logica: gerenciaSelecionada,
        lotacao_id: lotacaoSelecionada,
        data_inicio: dataInicioSelecionada,
        data_fim: dataFimSelecionada,
      },
    });

    const dias = [];
    const presentes = [];
    const ausentes = [];

    estatisticas.data.forEach(element => {
      dias.push(
        `${format(addHours(new Date(element.dtserv), '3'), 'dd/MM/yyyy')}`
      );
      // dias.push(element.dtserv);
      presentes.push(element.presentes);
      ausentes.push(element.ausentes);
    });
    setDataLine({
      labels: dias,
      datasets: [
        {
          label: 'Ausência',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(225, 204,230, .3)',
          borderColor: 'rgb(205, 130, 158)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(205, 130, 158)',
          pointBackgroundColor: 'rgb(255, 255, 255)',
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(0, 0, 0)',
          pointHoverBorderColor: 'rgba(220, 220, 220,1)',
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          pointHitRadius: 10,
          data: ausentes,
        },
        {
          label: 'Presenças',
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'rgba(184, 185, 210, .3)',
          borderColor: 'rgb(35, 26, 136)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgb(35, 26, 136)',
          pointBackgroundColor: 'rgb(255, 255, 255)',
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgb(0, 0, 0)',
          pointHoverBorderColor: 'rgba(220, 220, 220, 1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: presentes,
        },
      ],
    });
  };

  const geraGraficoDePizza = async () => {
    const estatisticas = await api.get('/servico-estatisticas-pizza', {
      params: {
        gerencia_logica: gerenciaSelecionada,
        lotacao_id: lotacaoSelecionada,
        data_inicio: dataInicioSelecionada,
        data_fim: dataFimSelecionada,
      },
    });

    const labels = [];
    const ausentes = [];

    estatisticas.data.forEach(data => {
      labels.push(data.motivo);
      ausentes.push(data.agausentes);
    });

    setDataPie({
      labels: labels,
      datasets: [
        {
          data: ausentes,
          backgroundColor: [
            '#F7464A',
            '#46BFBD',
            '#FDB45C',
            '#949FB1',
            '#4D5360',
            '#AC64AD',
          ],
          hoverBackgroundColor: [
            '#FF5A5E',
            '#5AD3D1',
            '#FFC870',
            '#A8B3C5',
            '#616774',
            '#DA92DB',
          ],
        },
      ],
    });
  };

  const geraTabelaAusencias = async () => {
    const teste = await api.get('/servico-tabela-ausencias', {
      params: {
        gerencia_logica: gerenciaSelecionada,
        lotacao_id: lotacaoSelecionada,
        data_inicio: dataInicioSelecionada,
        data_fim: dataFimSelecionada,
      },
    });

    setTabelaAusencias(teste.data);
  };

  const geraEstatisticas = async () => {
    if (gerenciaSelecionada === '' || gerenciaSelecionada === undefined) {
      toast.error('Preencha o Campo Gerência');
      return;
    }

    if (dataInicioSelecionada === '' || dataInicioSelecionada === undefined) {
      toast.error('Preencha o Campo Data Início');
      return;
    }

    if (dataFimSelecionada === '' || dataFimSelecionada === undefined) {
      toast.error('Preencha o Campo Data Fim');
      return;
    }

    if (dataInicioSelecionada > dataFimSelecionada) {
      toast.error('Data Início maior que Fim');
      return;
    }

    if (
      differenceInDays(
        new Date(dataInicioSelecionada),
        new Date(dataFimSelecionada)
      ) < -60
    ) {
      toast.error('Horizonte máximo de 60 dias');
      return;
    }

    geraGraficoDeLinha();
    geraGraficoDePizza();

    geraTabelaAusencias();
  };

  const estLogica = getData().gerencia;
  const gerenciasDados = async () => {
    const gerencias = await api.get('/gerencia', {
      params: {
        estrutura_logica: estLogica,
      },
    });

    let listaGerencias = [];
    gerencias.data.map(gerencia => {
      listaGerencias.push({
        value: gerencia.id,
        logica: gerencia.estrutura_logica,
        label: gerencia.nome,
      });
    });

    setDadosGerencias(listaGerencias);
  };

  const carregaLotacao = async gerencia => {
    setOptionsEscala([]);
    const lotacoes = await api.get('/lotacao', {
      params: {
        gerencia_id: gerencia,
      },
    });

    const arrayLotacoes = [];
    lotacoes.data.map(t => {
      arrayLotacoes.push(t.id);
    });

    let listaEscala = [
      {
        value: 'todas',
        label: 'Todas',
      },
    ];
    lotacoes.data.map(lotacao => {
      listaEscala.push({
        value: lotacao.id,
        label: lotacao.escala.nome + ' / ' + lotacao.ala.nome,
      });
    });
    // traz o primeiro objeto do array de lotacoes e insere no select , valor : Todos
    setLotacaoSelecionada(listaEscala[0].value);

    setOptionsEscala(listaEscala);
  };

  useEffect(() => {
    gerenciasDados();

    // geraTabelaAusencias();
    // geraEstatisticas();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="justify-content-center">
            <h4 className=" font-weight-bold">Estatísticas de Serviços</h4>
          </MDBRow>
          <br />
          <MDBRow className="rounded-top mt-1 justify-content-center text-end  bg-white">
            <MDBCol md="3" col="12" className="mt-2">
              <Select
                className="text-left w-100 mb-4 mt-4"
                onChange={e => {
                  carregaLotacao(e.value);

                  setGerenciaSelecionada(e.logica);
                }}
                placeholder="Gerência"
                options={dadosGerencias}
              />
            </MDBCol>
            <MDBCol md="3" col="12" className="mt-2">
              <Select
                className="text-left w-100 mb-4 mt-4"
                onChange={e => {
                  setLotacaoSelecionada(e.value);
                }}
                // value={lotacaoSelecionada}
                placeholder="Lotação"
                options={optionsEscala}
              />
            </MDBCol>
            <MDBCol md="3" col="12" className="mt-2">
              <span
                style={{ fontSize: '0.9rem', color: 'gray' }}
                className="font-weight-bolder"
              >
                Data Início
              </span>
              <input
                type="date"
                style={{ border: '1px silver solid', borderRadius: '3px' }}
                className="px-2 py-1 w-100"
                onChange={e => {
                  setDataInicioSelecionada(e.target.value);
                }}
              />
            </MDBCol>
            <MDBCol
              className="flex-collumn justify-content-center align-items-center mt-2"
              md="3"
              col="12"
            >
              <span
                style={{ fontSize: '0.9rem', color: 'gray' }}
                className="font-weight-bolder"
              >
                Data Fim
              </span>
              <input
                type="date"
                className="px-2 py-1 w-100"
                style={{ border: '1px silver solid', borderRadius: '3px' }}
                onChange={e => {
                  setDataFimSelecionada(e.target.value);
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="rounded-bottom justify-content-center text-center  bg-white">
            <MDBBtn
              color="mdb-color"
              onClick={() => geraEstatisticas()}
              className=" d-flex justify-content-around align-items-center "
            >
              Buscar
            </MDBBtn>
          </MDBRow>
          <br />
        </MDBContainer>
        <MDBContainer>
          <MDBRow>
            <MDBCol className="d-none d-md-flex" col="12" md="8">
              <Line
                height={100}
                data={dataLine}
                options={{ responsive: true }}
              />
            </MDBCol>
            <MDBCol col="12" md="4">
              <Pie
                height={240}
                data={dataPie}
                options={{ responsive: false }}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer className="mt-4">
          <MDBRow between>
            <MDBCol className="pr-4" col="12" md="12">
              <MDBRow className="p-1   mdb-color font-weight-bolder">
                <MDBCol className="text-center text-white">
                  Ausências por Tipo
                </MDBCol>
              </MDBRow>
              <MDBRow className="p-1 d-none d-md-flex  mdb-color lighten-3 font-weight-bolder">
                <MDBCol size="12" md="3" className="text-start">
                  Agente
                </MDBCol>
                <MDBCol size="12" md="2" className="text-start">
                  Gerência
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>DC</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>

                    <span>Dispensa Coordenação</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>F</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>
                    <span>Férias</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>LF</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>
                    <span>Licença Falecimento</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>LMP</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>
                    <span>Licença Maternidade Paternidade</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>LS</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>
                    <span>Licença Saúde</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  <MDBTooltip domElement tag="span" material placement="top">
                    <span>
                      <span>NC</span>
                      <MDBIcon className="white-text ml-1" icon="info-circle" />
                    </span>
                    <span>Não Comparecimento</span>
                  </MDBTooltip>
                </MDBCol>
                <MDBCol size="12" md="1" className="text-center">
                  Total
                </MDBCol>
              </MDBRow>
              {tabelaAusencias
                ? tabelaAusencias.map(agente => (
                    <MDBRow
                      key={agente.id}
                      className="p-1  mdb-color lighten-5"
                      style={{ borderBottom: '1px  #929fba solid' }}
                    >
                      <MDBCol size="12" md="3" className="text-start">
                        <Link
                          className="font-weight-bolder"
                          to={`/usuarios/${agente.id}`}
                        >
                          {agente.agente}
                        </Link>
                      </MDBCol>
                      <MDBCol size="12" md="2" className="text-start">
                        {agente.gerencia}
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Dispensa Coordenação
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.dc}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Férias
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.f}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Licença Falecimento
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.lf}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Licença Maternidade
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.lmp}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Licença Saúde
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.ls}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Não Comparecimento
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.nc}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol size="12" md="1" className="text-center d-flex">
                        <MDBCol size="10" md="0" className="d-flex d-md-none">
                          Total
                        </MDBCol>
                        <MDBCol size="2" md="12">
                          {agente.total}
                        </MDBCol>
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}
export default ServicoEstatisticas;
