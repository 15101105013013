import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Context, BuscaLatLong } from '../../pages/SGO/ContextApi/Context';
import { MDBRow, MDBCol, MDBBtn, MDBSpinner } from 'mdbreact';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Container } from './styles';

// const defaultIcon = L.icon({
//   iconUrl: require('leaflet/dist/images/marker-icon.png'),
//   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
//   iconSize: [25, 41],
//   iconAnchor: [12, 41],
// });

// const center = ['-22.8450', '-43.2566'];

// filho
export function DisplayPosition(props) {
  const [position, setPosition] = useState(() => props.map.getCenter());
  const [coord, setCoord] = useContext(Context);
  // const [buscaLatLong, setBuscaLatLong] = useContext(BuscaLatLong);
  // const onClick = useCallback(() => {
  //   props.map.setView(center, zoom);
  // }, [props.map]);

  const onMove = useCallback(() => {
    setPosition(props.map.getCenter());

    // setCoord([position.lat.toFixed(4), position.lng.toFixed(4)]);
    // enviarDadosParaPai(position.lat.toFixed(4), position.lng.toFixed(4));
  }, [props.map]);

  const enviaNovaGeo = () => {
    setCoord({
      geo: [position.lat.toFixed(4), position.lng.toFixed(4)],
      newGeo: false,
    });
  };

  useEffect(() => {
    props.map.on('move', onMove);
    return () => {
      props.map.off('move', onMove);
    };
  }, [props.map, onMove]);

  useEffect(() => {
    if (coord.newGeo === true) {
      enviaNovaGeo();
    }
  }, [coord]);

  return (
    <MDBRow>
      <MDBCol className="text-left">
        {`Latitude: ${position.lat.toFixed(4)}`}
      </MDBCol>
      <MDBCol className="text-right">
        {`Longitude: ${position.lng.toFixed(4)}`}
      </MDBCol>

      {/* <MDBBtn color="success" onClick={() => console.log(coord)}>
        Salvar
      </MDBBtn> */}
    </MDBRow>
  );
}

export function ExternalStateExample(props) {
  const [mapa, setMapa] = useState(null);
  const [latitude, setLatitude] = useState('-22.9475');
  const [longitude, setLongitude] = useState('-43.1840');
  const [loadingBusca, setLoadingBusca] = useState(false);

  useEffect(() => {
    // if (latitude !== '0' || longitude !== '0') {
    if (mapa !== null) {
      mapa.setView([latitude, longitude], 15);
    }

    // latitude !== '-22.9387' faz nada
    // longitude !== '-43.1846' faz nada

    // }
  }, [latitude, longitude]);

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  // Função assíncrona para esperar a localização e fechar o loading
  const fetchDataAndCloseLoading = async () => {
    try {
      setLoadingBusca(true);
      // Abra o loading aqui, caso você não tenha aberto ainda.

      // Aguarde a resolução da Promise e obtenha a posição.
      const position = await getLocation();

      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);

      setLoadingBusca(false);
    } catch (error) {
      setLoadingBusca(false);
      // Se ocorrer algum erro ao obter a localização, você pode tratar aqui.
      // Por exemplo, exibir uma mensagem de erro ou tomar alguma outra ação.
    }
  };

  // Chame a função fetchDataAndCloseLoading para iniciar o processo.

  //Lembre-se de adaptar o código conforme necessário para a estrutura do seu projeto. Note que dentro da função fetchDataAndCloseLoading, você pode executar outras ações com as coordenadas obtidas, ou chamar outras funções para manipular os dados conforme a lógica da sua aplicação.

  useEffect(() => {
    if (navigator.geolocation) {
      fetchDataAndCloseLoading();
      // setLoadingBusca(false);
    } else {
      console.log('Geolocalização não é suportada pelo seu navegador.');
      // setLatitude('-22.8450');
      // setLongitude('-43.2566');
    }
  }, []);

  const handleLocation = position => {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  };

  const displayMap = useMemo(
    () => (
      <>
        <MapContainer
          className="d-flex"
          style={{ width: '100%', height: '500px' }}
          center={[latitude, longitude]}
          zoom="15"
          minZoom="8"
          maxZoom="18"
          scrollWheelZoom={false}
          ref={setMapa}
        >
          <TileLayer
            attribution='<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Container />
        </MapContainer>
      </>
    ),
    []
  );

  return (
    <div>
      {displayMap}
      {loadingBusca ? (
        <MDBRow center className="align-items-center my-2">
          <MDBSpinner />
          <div className="ml-2">Buscando Localização</div>
        </MDBRow>
      ) : mapa ? (
        <>
          <DisplayPosition map={mapa} />
        </>
      ) : null}
    </div>
  );
}
