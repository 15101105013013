import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoutes';
import { isAuthenticated, getPerfil } from './services/auth';

import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import QrCode from './pages/QRCode';
import App from './pages/App';
// import { ScrollToTop } from './components/ScrollToTop';

import Perfil from './pages/efetivo/Perfil';
import MeuPerfil from './pages/efetivo/MeuPerfil';
import Editar from './pages/efetivo/Editar';
import EditarLotacao from './pages/efetivo/EditarLotacao';

import Usuarios from './pages/efetivo/Usuarios';
import AddUsuario from './pages/efetivo/AddUsuario';

import Gerencia from './pages/Gerencia';

import Alocacao from './pages/Alocacao';

// import Sgo from './pages/Sgo';

import Patrimonio from './pages/Patrimonio/ListaPatrimonio';
import AddPatrimonio from './pages/Patrimonio/AddPatrimonio';
import VisualizarPatrimonio from './pages/Patrimonio/VisualizarPatrimonio';

import EscalaCalendario from './pages/Calendario';

import Lotacao from './pages/Lotacao';

import ControlePresenca from './pages/ControlePresenca';

import EfetivoEstatisticas from './pages/efetivo/Estatisticas';
import FrequenciaAuditoria from './pages/FrequenciaAuditoria';

import ServicoEstatisticas from './pages/ServicoEstatisticas';
import Adm from './pages/Adm';
import Demo from './pages/Demo';
import Estrategico from './pages/Estrategico';
import Operacional from './pages/Operacional';
import Operador from './pages/Operador';

//
//
//
// ROTAS DE RH
//
//
//
//
import rhVagas from './pages/RH/Vagas';
import rhLista from './pages/RH/Lista';

//
//
//
// ROTAS DE ABORDAGEM
//
//
//
// import RotasAbordagem from './pages/SGO/routes';
import AddAbordagem from './pages/SGO/AddAbordagem';
import ListarAbordagens from './pages/SGO/ListarAbordagens';
import Abordagens from './pages/SGO/Abordagens';
import Imprimir from './pages/SGO/Abordagens/Imprimir';
import AddOcorrencia from './pages/SGO/AddOcorrencia';
import VerAbordagem from './pages/SGO/VerAbordagem';
import InsereItens from './pages/SGO/InsereItens';
import RevisaoAbordagens from './pages/SGO/RevisaoAbordagens';

//
//
// missao
//
//

import Inteligencia from './pages/SGO/Inteligencia';
import AddRelato from './pages/Inteligencia/AddRelato';
import Relatos from './pages/Inteligencia/Relatos';
// const PrivateRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       isAuthenticated() ? (
//         <>
//           {window.scrollTo(0, 0)}
//           <Component {...props} />
//         </>
//       ) : (
//         <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//       )
//     }
//   />
// );

import AddAssunto from './pages/LPD/AddAssunto';
import ListaLPD from './pages/LPD/ListaLPD';
import addLpd from './pages/LPD/AddLpd';

// AVALIAÇÃO
import EfetivoAvaliacao from './pages/EfetivoAvaliacao';

// DADOS OPERACIONAIS
import AddDadosOperacionais from './pages/SGO/DadosOperacionais/Assuntos';
import DadosOperacionais from './pages/SGO/DadosOperacionais/Lista';

// ROTAS DE FROTAS
import Frotas from './routes/Frotas';
import Denuncias from './pages/Denuncias';

//FERIAS
import Ferias from './pages/Ferias';

//
import Alerta from './pages/Notificacao/Alerta';

const Routes = () => (
  <BrowserRouter>
    {/* <ScrollToTop /> */}
    <Switch>
      <Route exact path="/" component={SignIn} />

      {/* <Route path="/signup" component={SignUp} /> */}
      <PrivateRoute path="/qrcode" component={QrCode} />
      <PrivateRoute path="/app" component={App} />
      {/* MENU - VISUALIZA PROPRIO PERFIL */}
      <PrivateRoute path="/meu-perfil" component={MeuPerfil} />

      {/*CARD SGE -- SGE - ROTAS DE CONTROLE DE USUARIOS */}
      <PrivateRoute exact path="/usuarios" component={Usuarios} />
      <PrivateRoute path="/usuario/add" component={AddUsuario} />
      <PrivateRoute path="/usuarios/:id" component={Perfil} />
      <PrivateRoute path="/editar/:id" component={Editar} />
      <PrivateRoute path="/editar-lotacao/:id" component={EditarLotacao} />
      <PrivateRoute
        path="/efetivo-estatisticas"
        component={EfetivoEstatisticas}
      />

      {/*CARD ALOCACAO -- ROTAS DE CONTROLE DE GERENCIA */}
      <PrivateRoute path="/gerencias" component={Gerencia} />
      <PrivateRoute path="/lotacao" component={Lotacao} />
      <PrivateRoute path="/alocacao" component={Alocacao} />

      {/* CARD GESTAO DE SERVICO  */}
      <PrivateRoute path="/escala-calendario" component={EscalaCalendario} />
      <PrivateRoute path="/controle-de-presenca" component={ControlePresenca} />
      <PrivateRoute
        path="/frequencia-auditoria"
        component={FrequenciaAuditoria}
      />
      <PrivateRoute
        path="/servico-estatisticas"
        component={ServicoEstatisticas}
      />

      {/* <PrivateRoute path="/add-ocorrencia" component={Sgo} /> */}

      <PrivateRoute path="/add-patrimonio" component={AddPatrimonio} />
      <PrivateRoute path="/patrimonio" component={Patrimonio} />

      <PrivateRoute
        path="/visualizar-patrimonio"
        component={VisualizarPatrimonio}
      />

      <PrivateRoute path="/adm" component={Adm} />
      <PrivateRoute path="/estrategico" component={Estrategico} />
      <PrivateRoute path="/operacional" component={Operacional} />
      <PrivateRoute path="/operador" component={Operador} />
      <PrivateRoute path="/demo" component={Demo} />
      {/* <PrivateRoute path="/add-ocorrencia/:id" component={AddOcorrencia} /> */}
      {/* ROTAS DE ABORDAGENS */}
      <PrivateRoute path="/add-abordagem" component={AddAbordagem} />
      <PrivateRoute path="/minhas-abordagens" component={ListarAbordagens} />
      <PrivateRoute path="/abordagens" component={Abordagens} />
      <PrivateRoute path="/revisao-abordagens" component={RevisaoAbordagens} />

      <PrivateRoute
        path="/imprime-abordagem/:abordagem_id"
        component={Imprimir}
      />

      <PrivateRoute path="/insere-item" component={InsereItens} />
      {/* <PrivateRoute
        path="/edita-abordagem/:abordagem_id"
        component={EditarAbordagem}
      /> */}
      <PrivateRoute
        path="/add-ocorrencia/:abordagem_id"
        component={AddOcorrencia}
      />
      <PrivateRoute path="/abordagem/:abordagem_id" component={VerAbordagem} />

      <PrivateRoute path="/inteligencia/abordagem" component={Inteligencia} />

      <PrivateRoute path="/rh/vagas" component={rhVagas} />
      <PrivateRoute path="/rh-lista" component={rhLista} />

      <PrivateRoute path="/lpd-assunto/adicionar" component={AddAssunto} />
      <PrivateRoute path="/lpd" exact component={ListaLPD} />
      <PrivateRoute path="/lpd-criar" component={addLpd} />

      <PrivateRoute path="/relatos-criar" component={AddRelato} />
      <PrivateRoute path="/relatos" component={Relatos} />

      <PrivateRoute path="/efetivo-avaliacao" component={EfetivoAvaliacao} />

      <PrivateRoute
        path="/operacionais-assuntos"
        component={AddDadosOperacionais}
      />
      <PrivateRoute path="/dados-operacionais" component={DadosOperacionais} />
      <PrivateRoute path="/denuncias" component={Denuncias} />
      <PrivateRoute path="/ferias" component={Ferias} />
      <PrivateRoute path="/alertas" component={Alerta} />
      <Frotas />

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
