import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBCard,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardText,
  MDBCardTitle,
  MDBCardT,
  MDBSwitch,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
  MDBCardBody,
  MDBTypography,
  MDBBox,
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

import { getData, getPerfil } from '../../../services/auth';

export default function App() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [qtdTotal, setQtdTotal] = useState(0);
  const [qtdPorPagina, setQtdPorPagina] = useState({
    label: '10 registros',
    value: 10,
  });

  const [loadingDespacho, setLoadingDespacho] = useState(false);
  const [novoAssunto, setNovoAssunto] = useState('');

  const [relatos, setRelatos] = useState([]);

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');

  const [listaGerencias, setListaGerencias] = useState([]);

  const [gerenciaSelecionada, setGerenciaSelecionada] = useState({
    value: '',
    label: '',
  });
  const [listaAssuntos, setListaAssuntos] = useState([]);
  const [assuntoSelecionado, setAssuntoSelecionado] = useState({
    label: '',
    value: '',
  });

  const [dataInicio, setDataInicio] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const [collapseDespachoId, setCollapseDespachoId] = useState('');
  const [despachoId, setDespachoId] = useState('');
  const [darCiencia, setDarCiencia] = useState(false);
  const [despacho, setDespacho] = useState('');

  const [modalLimpaForm, setModalLimpaForm] = useState(false);
  const [modalLimpaFormId, setModalLimpaFormId] = useState('');

  const [enviarDespachoLoading, setEnviarDespachoLoading] = useState(false);

  const [mostraDespacho, setMostraDespacho] = useState(false);

  const [checkSemDespacho, setCheckSemDespacho] = useState(true);

  const mudaCollapse = () => {
    setDarCiencia(false);
    setDespacho('');
    setModalLimpaForm(false);
    setCollapseDespachoId(modalLimpaFormId);
  };

  const enviaDespacho = async () => {
    const dados = {};
    if (despachoId !== '') {
      dados.despacho_id = despachoId;
    }
    if (darCiencia === false && despacho === '') {
      toast.error('Preencha o despacho');
      return;
    }
    setEnviarDespachoLoading(true);
    if (darCiencia === true) {
      dados.despacho = null;
      dados.lpd_id = collapseDespachoId;
    } else {
      dados.despacho = despacho;
      dados.lpd_id = collapseDespachoId;
    }

    const result = await api.post('/lpd-despacho', {
      ...dados,
      // despacho: checkSemDespacho,
    });

    // relatos.forEach(t => console.log(t));
    buscaAssuntos();
    mudaCollapse();
    setEnviarDespachoLoading(false);
  };

  const abreCollapse = (id, despacho_id) => {
    setDespachoId(despacho_id);
    if (collapseDespachoId === '') {
      setCollapseDespachoId(id);
    } else if (collapseDespachoId !== id && despacho !== '') {
      setModalLimpaForm(true);
      setModalLimpaFormId(id);
    } else if (collapseDespachoId !== id && despacho === '') {
      setCollapseDespachoId(id);
    } else {
      if (despacho !== '') {
        setModalLimpaForm(true);
      } else {
        setCollapseDespachoId('');
      }
      // setCollapseDespachoId('');
    }
  };

  const carregaGerencias = () => {
    const select = [];
    api
      .get('/lpd/lista-assunto', {
        params: { ativo: true },
      })
      .then(assuntos => {
        assuntos.data.forEach(element => {
          select.push({ label: element.assunto, value: element.id });
        });
        setListaAssuntos(select);
      });

    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
    });

    api
      .get('/verifica-funcao', {
        params: {
          rota: 'rota-despacho',
          tipo: 'LPD',
        },
      })
      .then(res => {
        if (res.data === null) {
          return;
        }

        if (res.data.id) {
          setMostraDespacho(true);
        }
      });

    // setBotaoEditarLotacao(acessoEditarLotacao.data);
  };
  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    setLoading(true);
    // console.log({
    //   lpd_assunto_id: assuntoSelecionado.value,
    //   data_inicio: dataInicio,
    //   data_final: dataFinal,
    //   gerencia_id: gerenciaSelecionada.value,

    //   page: page,
    // });
    const params = {
      lpd_assunto_id: assuntoSelecionado.value,
      data_inicio: dataInicio,
      data_final: dataFinal,
      gerencia_id: gerenciaSelecionada.value,
      ciencia: checkSemDespacho,
      // qtd_por_pagina: qtdPorPagina.value,

      page: page,
    };

    // if (checkSemDespacho === true) {
    //   params.ciencia = checkSemDespacho;
    // }

    const lista = await api.get('/lpd', {
      params: params,
    });



    setQtdTotal(Math.ceil(Number(lista.data.count) / Number(10)));
    const novaLista = [];
    lista.data.rows &&
      lista.data.rows.length > 0 &&
      lista.data.rows.forEach(element => {
        const novosDespachos = [];
        element.lpd_despachos.forEach(desp => {
          let novoArrayDespacho = {
            ...desp,
            despacho:
              desp.despacho === null ? ['Ciente'] : desp.despacho.split('\n'),
          };
          novosDespachos.push({ ...desp, ...novoArrayDespacho });
        });
        let arrayRelato = element.relato.split('\n');
        novaLista.push({
          ...element,
          relato: arrayRelato,
          lpd_despachos: [...novosDespachos],
        });
      });

    setRelatos(novaLista);
    // console.log(novaLista);
    setLoading(false);
  };

  useEffect(() => {
    carregaGerencias();
    buscaAssuntos();
  }, []);

  useEffect(() => {
    buscaAssuntos();
  }, [page, qtdPorPagina]);

  return (
    <NavBar>
      <MDBContainer fluid className="p-0 mx-0 mx-md-3">
        <MDBRow className="h4-responsive" center>
          Livro de Parte Diária
        </MDBRow>
        <MDBRow center>
          <MDBCol size="12" md="3">
            <div>Selecione a Gerência</div>
            <Select
              options={listaGerencias}
              isClearable
              onChange={e => {
                if (e === null) {
                  setGerenciaSelecionada({ label: '', value: '' });
                  return;
                }
                setGerenciaSelecionada(e);
              }}
              placeholder="Gerência"
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Assunto</div>
            <Select
              options={listaAssuntos}
              onChange={e => {
                if (e === null) {
                  setAssuntoSelecionado({ label: '', value: '' });
                  return;
                }
                setAssuntoSelecionado(e);
              }}
              placeholder="Assuntos"
              isClearable
            />
          </MDBCol>

          <MDBCol size="12" md="3">
            <span>Data Início</span>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataInicio(parseISO(e.target.value))}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Data Final</div>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataFinal(parseISO(e.target.value))}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow center className="mb-3">
          {/* <MDBSwitch
            checked={checkSemDespacho}
            onChange={e => {
              setCheckSemDespacho(!checkSemDespacho);
            }}
            labelLeft="Sem Despacho"
            labelRight="Mostrar Tudo"
          /> */}
          <MDBInput
            containerClass="form-check checkbox-teal font-weight-bolder"
            labelClass="form-check-label"
            label="Sem Despacho"
            type="checkbox"
            id="semDespacho"
            checked={checkSemDespacho}
            onClick={e => {
              setCheckSemDespacho(!checkSemDespacho);
            }}
          />
        </MDBRow>
        <MDBRow center>
          <MDBBtn
            disabled={loading}
            onClick={() => {
              setPage(Number(1));
              buscaAssuntos();
            }}
          >
            Buscar
          </MDBBtn>
        </MDBRow>

        {loading === true ? (
          <MDBRow className="mt-5 text-center d-flex justify-content-center align-items-center">
            <MDBSpinner />
            <span className="ml-3 font-weight-bold">Carregando</span>
          </MDBRow>
        ) : relatos.length !== 0 ? (
          relatos.map(item => (
            <MDBCard className="mt-3" key={item.id}>
              <MDBCardTitle
                style={{
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  borderBottom: '1px solid rgba(0,0,0,0.1)',
                }}
                className="text-center p-2 h5-responsive"
              >
                <MDBRow>
                  <MDBCol size="12" md="3">
                    <span>LPD N:</span> <br />
                    <span className="font-weight-bolder">{item.id}</span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="3">
                    <span>Assunto:</span> <br />
                    <span className="font-weight-bolder">
                      {item.lpd_assunto.assunto}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="2">
                    <span>Gerência:</span> <br />
                    <span className="font-weight-bolder">
                      {item.lotacao.gerencia.nome}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="2">
                    <span>Agente:</span> <br />
                    <span className="font-weight-bolder">
                      {' '}
                      {item.usuario.nome_social}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="2">
                    <span>Criação:</span>
                    <br />
                    <span className="font-weight-bolder">
                      {format(
                        zonedTimeToUtc(
                          new Date(item.created_at),
                          'America/Sao_Paulo'
                        ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCardTitle>
              <MDBCardBody>
                {item.relato.map((linha, index) => (
                  <div key={index}>
                    {linha} <br />
                  </div>
                ))}
                {mostraDespacho === true ? (
                  item.lpd_despachos.length === 0 ? (
                    <MDBRow end>
                      <MDBBtn
                        color="elegant"
                        onClick={() => abreCollapse(item.id, '')}
                        style={{ marginBottom: '1rem' }}
                        size="sm"
                      >
                        Despacho
                      </MDBBtn>
                    </MDBRow>
                  ) : (
                    ''
                  )
                ) : (
                  ''
                )}

                {item.lpd_despachos.length > 0
                  ? item.lpd_despachos.map((despacho, index) => (
                      <div key={`despacho${despacho.id}`}>
                        <hr />
                        <MDBTypography blockquote bqColor="danger">
                          <MDBBox
                            tag="p"
                            mb={0}
                            className="h5-responsive danger-text"
                          >
                            Despacho: {despacho.usuario.nome_social}
                            <br />
                            {format(
                              zonedTimeToUtc(
                                new Date(despacho.created_at),
                                'America/Sao_Paulo'
                              ),
                              'dd/MM/yyyy - HH:mm'
                            )}
                          </MDBBox>

                          <div>
                            {despacho.despacho.map((desp, index) => (
                              <div key={`${index}`}>
                                {desp}
                                <br />
                              </div>
                            ))}
                            {mostraDespacho === true ? (
                              item.lpd_despachos.length - 1 === index ? (
                                <MDBRow end>
                                  <MDBBtn
                                    color="elegant"
                                    onClick={() =>
                                      abreCollapse(item.id, despacho.id)
                                    }
                                    style={{ marginBottom: '1rem' }}
                                    size="sm"
                                  >
                                    Despacho
                                  </MDBBtn>
                                </MDBRow>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </MDBTypography>
                      </div>
                    ))
                  : ''}

                <MDBCollapse
                  id={`${item.id}`}
                  isOpen={collapseDespachoId === item.id ? true : false}
                >
                  <MDBRow center className="py-0">
                    <MDBInput
                      containerClass="form-check checkbox-teal"
                      labelClass="form-check-label"
                      label="Dar Somente Ciência"
                      type="checkbox"
                      id="tealExample"
                      checked={darCiencia}
                      onClick={e => {
                        setDarCiencia(!darCiencia);
                      }}
                    />
                  </MDBRow>

                  <MDBRow className="px-3 py-0">
                    <MDBCol size="12">
                      {darCiencia ? (
                        <br />
                      ) : (
                        <MDBInput
                          // onChange={e => setRelato(e.target.value)}
                          className={`${darCiencia ? 'grey lighten-3' : ''}`}
                          disabled={darCiencia}
                          style={{ borderRadius: '5px' }}
                          type="textarea"
                          outline
                          rows="8"
                          counter={1200}
                          maxLength="1200"
                          value={despacho}
                          onChange={e => setDespacho(e.target.value)}
                        />
                      )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow center>
                    {enviarDespachoLoading ? (
                      <MDBRow className="text-center d-flex justify-content-center align-items-center">
                        <MDBSpinner size="sm" />
                        <span className="ml-3 font-weight-bold">
                          Carregando
                        </span>
                      </MDBRow>
                    ) : (
                      <MDBBtn
                        color={darCiencia ? 'blue-grey' : 'elegant'}
                        onClick={() => enviaDespacho(item.id)}
                      >
                        {darCiencia ? 'Enviar ciência' : 'Enviar Despacho'}
                      </MDBBtn>
                    )}
                  </MDBRow>
                </MDBCollapse>
              </MDBCardBody>
            </MDBCard>
          ))
        ) : (
          'Sem Dados'
        )}
        <br />
        <MDBRow className="justify-content-center align-items-center">
          {/* <MDBCol size="2">teste</MDBCol> */}
          <MDBCol
            size="8"
            className="d-flex justify-content-center align-items-center"
          >
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  // className={`mx-3 grey-text`}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(1));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-left"
                />
              </span>
              <span>Primeira Página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>

            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(page) - 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-left"
                />
              </span>
              <span>Página Anterior</span>
            </MDBTooltip>
            <div>
              Página {page} de {qtdTotal}
            </div>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page >= qtdTotal) {
                      return;
                    }
                    setPage(Number(page) + 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-right"
                />
              </span>
              <span>Próxima página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == qtdTotal) {
                      return;
                    }
                    setPage(Number(qtdTotal));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-right"
                />
              </span>
              <span>Última página</span>
            </MDBTooltip>
          </MDBCol>
          {/* <MDBCol className="text-center" size="2">
            <span className="font-weight-bolder">Quantidade:</span>
            <Select
              className="text-center"
              placeholder="Quantidade"
              options={[
                { label: '10 registros', value: 10 },
                { label: '20 registros', value: 20 },
                { label: '50 registros', value: 50 },
              ]}
              value={qtdPorPagina}
              onChange={e => setQtdPorPagina(e)}
            />
          </MDBCol> */}
        </MDBRow>
        <br />
        <MDBModal
          isOpen={modalLimpaForm}
          toggle={() => setModalLimpaForm(!modalLimpaForm)}
        >
          <MDBModalHeader toggle={() => setModalLimpaForm(!modalLimpaForm)}>
            Atenção
          </MDBModalHeader>
          <MDBModalBody>
            Você irá perder os dados inseridos no campo despacho.
            <br />
            Deseja realmente fechar essa janela ?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => setModalLimpaForm(!modalLimpaForm)}
            >
              Não
            </MDBBtn>
            <MDBBtn onClick={() => mudaCollapse()} color="primary">
              Sim
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
