import React from 'react';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBTypography,
  MDBBox,
} from 'mdbreact';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import InputMask from 'react-input-mask';

import UserJpg from '../../../assets/user.jpg';
import { getData } from '../../../services/auth';
// import { Container } from './styles';
import api from '../../../services/api';
import { useState, useEffect, useRef } from 'react';

function Perfil(props) {
  const [dados, setDados] = useState({});
  const [foto, setFoto] = useState('');
  const [lotacao, setLotacao] = useState({});
  const [nomeacao, setNomeacao] = useState([]);

  const [modalEditPerfil, setModalEditPerfil] = useState(false);
  const [editContato, setEditContato] = useState({});

  const [modalEditPassword, setModalEditPassword] = useState(false);
  const [editPassword, setEditPassword] = useState({});

  const [newPassword, setNewPassword] = useState({});
  const [historicoLista, setHistoricoLista] = useState([]);

  const [feriasAntigas, setFeriasAntigas] = useState([]);
  const [feriasSecc, setFeriasSecc] = useState([]);
  const [loading, setLoading] = useState(false);

  const idReq = getData().id;

  const historicoFerias = useRef([]);

  const loadDados = async () => {
    // const response = await api.get(`/usuario/${idReq}`);

    const response = await api.get(`/usuario/${idReq}`);
    const { lotacao, ...dados } = response.data;

    setNomeacao(dados.nomeacaos);

    let escala = {};
    escala.lotacaoGerencia = lotacao.gerencia.nome;
    escala.lotacaoAla = lotacao.ala.nome;
    escala.lotacaoEscala = lotacao.escala.nome;
    // escala.setorArea = lotacao.setor.area.nome
    // escala.setorGuarnicao = lotacao.setor.guarnicao.nome

    setDados(dados);
    setLotacao(escala);
  };

  const fetchFerias = async () => {
    try {
      const response = await api.get(`/ferias-historico/${idReq}`);
      setFeriasAntigas(response.data.feriasAntigas);
      setFeriasSecc(response.data.feriasSecc);
    } catch (error) {
      console.error('Erro ao buscar as férias do usuário:', error);
    } finally {
      setLoading(false);
    }
  };

  const editaDadosContato = async () => {
    // return;
    try {
      const edit = await api.put(`/usuario/${idReq}`, editContato);

      if (edit.status === 203) {
        toast.error(edit.data.erro);
        return;
      }
      toast.success('Contato Alterado!');
      loadDados();
      setModalEditPerfil(false);
    } catch (error) {
      toast.error('Erro, entre em contato com administrador!');
    }
  };

  const alteraSenha = async () => {
    if (!newPassword.password) {
      toast.error('Preencha os campos');
      return;
    }
    if (newPassword.password != newPassword.confirm) {
      toast.error('As senhas não são iguais');
      return;
    }
    if (newPassword.password.length < 5) {
      toast.error('Senha precisa mais de 6 caracteres');
      return;
    }
    try {
      const edit = await api.put(`/usuario/${idReq}`, {
        password: newPassword.password,
      });

      if (edit.status === 203) {
        toast.error(edit.data.erro);
        return;
      }
      toast.success('Senha Alterada!');
      loadDados();
      setModalEditPassword(false);
    } catch (error) {
      toast.error('Erro, entre em contato com administrador!');
    }
  };

  const listaDeAvaliacao = async () => {
    // setModalHistoricoListaLoading(true);
    const historico = await api.get('/usuario-avaliacao', {
      params: { avaliado_id: idReq },
    });

    setHistoricoLista(historico.data);
  };

  useEffect(() => {
    loadDados();
    listaDeAvaliacao();
    fetchFerias();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex flex-column-reverse flex-md-row  align-items-stretch">
            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between mt-2 mt-md-0"
            >
              <MDBCard>
                <MDBCardBody className="d-flex flex-column">
                  <form>
                    <p className="h5 text-center">Informações</p>
                    <div className="grey-text ">
                      <MDBInput
                        label="Contato"
                        icon="phone"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value={dados.telefone}
                        disabled
                      />

                      <MDBInput
                        label="Email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={dados.email}
                        disabled
                      />
                    </div>
                    <div className=" d-flex justify-content-between">
                      <div className="black-text">Status:</div>
                      {dados.ativo ? (
                        <div className="text-success rounded-pill px-4  border border-success font-weight-bold">
                          Ativo
                        </div>
                      ) : (
                        <div className="text-danger rounded-pill px-4 border border-danger font-weight-bold">
                          Inativo
                        </div>
                      )}
                    </div>
                    <br />
                    <MDBRow className="justify-content-between">
                      <MDBBtn
                        onClick={() => {
                          setModalEditPerfil(!modalEditPerfil);
                        }}
                        color="primary"
                      >
                        Alterar Contatos
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => {
                          setModalEditPassword(!modalEditPassword);
                        }}
                        color="primary"
                      >
                        Alterar Senha
                      </MDBBtn>
                    </MDBRow>
                  </form>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100">
                  <p className="h5 text-center">Lotação</p>

                  <MDBTable striped>
                    <MDBTableBody>
                      <tr>
                        <td>Gerencia</td>
                        <td className="font-weight-bold">
                          {lotacao.lotacaoGerencia}
                        </td>
                      </tr>
                      <tr>
                        <td>Escala</td>
                        <td>{lotacao.lotacaoEscala}</td>
                      </tr>
                      <tr>
                        <td>ALA</td>
                        <td>{lotacao.lotacaoAla}</td>
                      </tr>
                      {/* <tr>
                      <td>Guarnição</td>
                      <td>{lotacao.setorGuarnicao}</td>
                    </tr> */}
                      {/* <tr>
                      <td>Área</td>
                      <td>{lotacao.setorArea}</td>
                    </tr> */}
                      {/* <tr>
                        <td>Último Serviço:</td>
                        <td className="red-text font-weight-bold">
                          03/12/2019
                        </td>
                      </tr>
                      <tr>
                        <td>Proximo Serviço:</td>
                        <td className="green-text font-weight-bold">
                          07/12/2019
                        </td>
                      </tr> */}
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between"
            >
              <MDBCard className="h-100">
                <MDBCardBody>
                  <p className="h5 text-center">Perfil</p>
                  <form>
                    <div className="text-center">
                      <img
                        className="img-fluid"
                        width="200"
                        alt="foto do perfil"
                        src={
                          dados.foto_usuario ? dados.foto_usuario.url : UserJpg
                        }
                      />
                    </div>

                    <div className="grey-text m-4 text-center">
                      <h5>Nome:</h5>
                      <h4 className="red-text">
                        {dados.nome} {dados.sobrenome}
                      </h4>
                      <br />

                      <div>
                        {/* <div className="black-text d-flex justify-content-between">
                        <div>Cargo:</div>
                        <div>{dados.segov_admissao}</div>
                        <div>FALTA COLOCAR</div>
                      </div>
                      <hr /> */}
                        <div className="black-text d-flex justify-content-between">
                          <div>CPF: </div>
                          <div>{dados.cpf}</div>
                        </div>

                        <hr />
                        <div className="black-text d-flex justify-content-between">
                          <div>Id Funcional:</div>
                          <div>{dados.id_funcional}</div>
                        </div>
                        <hr />

                        <div className="black-text">
                          <div>Admissão:</div>
                          <br />
                          {nomeacao.map(linha => (
                            <MDBRow
                              key={linha.id}
                              className="d-flex justify-content-between mt-2"
                            >
                              <MDBCol className="d-flex">
                                <div>Data:</div>
                                <div className="ml-2">
                                  {format(new Date(linha.data), 'dd/MM/yyyy')}
                                </div>
                              </MDBCol>
                              <MDBCol className="d-flex">
                                <div>DO Nº: </div>
                                <div className="ml-2">
                                  {linha.diario_oficial}
                                </div>
                              </MDBCol>
                              <MDBCol className="d-flex">
                                <div>Tipo:</div>
                                <div className="ml-2">{linha.tipo}</div>
                              </MDBCol>
                            </MDBRow>
                          ))}
                          {/* <div>{dados.nomeacaos.diario_oficial}</div> */}
                          {/* <div>teste</div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100">
                  <p className="h5 text-center">Histórico de Avaliação</p>

                  {historicoLista.length > 0 ? (
                    historicoLista.map(avaliacao => (
                      <>
                        <MDBTypography
                          key={avaliacao.id}
                          blockquote
                          bqColor={`${avaliacao.elogio ? 'success' : 'danger'}`}
                        >
                          <MDBBox
                            tag="p"
                            mb={0}
                            className="bq-title font-weight-bolder"
                          >
                            {avaliacao.elogio ? 'Elogio' : 'Crítica'}
                          </MDBBox>

                          <MDBRow between>
                            <MDBCol className="font-weight-bolder">
                              Data:{' '}
                              {format(
                                parseISO(avaliacao.data_fato),
                                'dd/MM/yyyy'
                              )}
                            </MDBCol>
                            <MDBCol className="font-weight-bolder">
                              Avaliador: {avaliacao.avaliador.nome_social}
                            </MDBCol>
                          </MDBRow>

                          <p>
                            {avaliacao.descricao.split('\n').map((t, index) => (
                              <div key={index}>
                                {t} <br />
                              </div>
                            ))}
                          </p>
                          <MDBRow
                            end
                            style={{ fontSize: '1rem' }}
                            className="font-italic "
                          >
                            Autorizador por: {avaliacao.permissor.nome_social}{' '}
                            em{' '}
                            {format(
                              parseISO(avaliacao.updatedAt),
                              'dd/MM/yyyy'
                            )}
                          </MDBRow>
                        </MDBTypography>
                      </>
                    ))
                  ) : (
                    <MDBRow center className="font-weight-bolder">
                      Sem Avaliação
                    </MDBRow>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-4">
            <MDBCol>
              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100">
                  <MDBRow>
                    <MDBCol size="12">
                      <h4 className="deep-orange lighten-5 text-center p-2">
                        Férias Antigas
                      </h4>
                      {feriasAntigas.length > 0 ? (
                        feriasAntigas.map(ferias => (
                          <>
                            <MDBRow between key={ferias.id} className="mt-3">
                              <MDBCol>
                                Ano de Referência: {ferias.ano_referencia}
                                <br />
                                Dias Restantes:{' '}
                                {ferias.dias -
                                  (ferias.ferias?.reduce(
                                    (acc, curr) => acc + curr.qtd_dias,
                                    0
                                  ) || 0)}
                              </MDBCol>

                              {ferias.ferias && ferias.ferias.length > 0 ? (
                                <>
                                  <MDBCol className="text-center">
                                    Períodos Tirados:
                                    {ferias.ferias.map(t => (
                                      <div key={t.id}>
                                        {`${format(
                                          parseISO(t.data_inicio),
                                          'dd/MM/yyyy'
                                        )} - ${format(
                                          parseISO(t.data_fim),
                                          'dd/MM/yyyy'
                                        )}`}
                                      </div>
                                    ))}
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    {ferias.ferias.some(
                                      t => t.data_coordenador === null
                                    ) ? (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-warning">
                                          Aguardando Confirmação
                                        </h4>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-success">
                                          Confirmada
                                        </h4>
                                      </MDBCol>
                                    )}
                                  </MDBCol>
                                </>
                              ) : (
                                <MDBCol className="text-right">
                                  <h5>Status</h5>
                                  <h4 className="text-danger">Pendente</h4>
                                </MDBCol>
                              )}
                            </MDBRow>
                            <hr />
                          </>
                        ))
                      ) : (
                        <p>Nenhuma férias antiga registrada.</p>
                      )}
                    </MDBCol>
                    <MDBCol size="12">
                      <h4 className="mdb-color lighten-5 lighten-5 text-center p-2">
                        Férias Recebidas
                      </h4>
                      {feriasSecc.length > 0 ? (
                        feriasSecc.map(ferias => (
                          <>
                            <MDBRow between key={ferias.id}>
                              <MDBCol>
                                Ano de Referência: {ferias.ano_referencia}
                                <br />
                                Dias Restantes:{' '}
                                {30 -
                                  ferias.ferias.reduce(
                                    (acc, curr) => acc + curr.qtd_dias,
                                    0
                                  )}
                              </MDBCol>

                              {ferias.ferias && ferias.ferias.length > 0 ? (
                                <>
                                  <MDBCol className="text-center">
                                    Períodos Tirados:
                                    {ferias.ferias.map(t => (
                                      <div key={t.id}>
                                        {`${format(
                                          parseISO(t.data_inicio),
                                          'dd/MM/yyyy'
                                        )} - ${format(
                                          parseISO(t.data_fim),
                                          'dd/MM/yyyy'
                                        )}`}
                                      </div>
                                    ))}
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    {ferias.ferias.some(
                                      t => t.data_coordenador === null
                                    ) ? (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-warning">
                                          Aguardando Confirmação
                                        </h4>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-success">
                                          Confirmada
                                        </h4>
                                      </MDBCol>
                                    )}
                                  </MDBCol>
                                </>
                              ) : (
                                <MDBCol className="text-right">
                                  <h5>Status</h5>
                                  <h4 className="text-danger">Pendente</h4>
                                </MDBCol>
                              )}
                            </MDBRow>
                            <hr />
                          </>
                        ))
                      ) : (
                        <p>Nenhuma férias SECC registrada.</p>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBModal
            isOpen={modalEditPerfil}
            toggle={() => {
              setModalEditPerfil(!modalEditPerfil);
            }}
          >
            <MDBModalHeader
              toggle={() => {
                setModalEditPerfil(!modalEditPerfil);
              }}
            >
              Alterar Contatos
            </MDBModalHeader>
            <MDBModalBody>
              <br />
              <div className="d-flex align-items-center">
                <MDBIcon icon="phone mr-4 " size="2x" />
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1rem',
                  }}
                  className="d-flex w-100 font-weight-light"
                  label="Telefone"
                  type="text"
                  placeholder="Telefone"
                  onChange={e =>
                    setEditContato({ ...editContato, telefone: e.target.value })
                  }
                  defaultValue={dados.telefone}
                  mask="(99) 9999 - 99999"
                  maskChar=" "
                />
              </div>
              <MDBInput
                className="mt-5"
                label="email"
                icon="envelope"
                group
                type="email"
                validate
                error="wrong"
                success="right"
                valueDefault={dados.email}
                onChange={e =>
                  setEditContato({ ...editContato, email: e.target.value })
                }
              />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="danger"
                onClick={() => {
                  setModalEditPerfil(!modalEditPerfil);
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn onClick={() => editaDadosContato()} color="success">
                Alterar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            isOpen={modalEditPassword}
            toggle={() => {
              setModalEditPassword(!modalEditPassword);
            }}
          >
            <MDBModalHeader
              toggle={() => {
                setModalEditPassword(!modalEditPassword);
              }}
            >
              Alterar Senha
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                label="Digite a nova senha"
                icon="unlock"
                group
                type="password"
                validate
                error="wrong"
                success="right"
                // valueDefault={dados.email}
                onChange={e =>
                  setNewPassword({ ...newPassword, password: e.target.value })
                }
              />
              <MDBInput
                className="mt-5"
                label="Repita a senha"
                icon="unlock"
                group
                type="password"
                validate
                error="wrong"
                success="right"
                // valueDefault={dados.email}
                onChange={e => {
                  setNewPassword({ ...newPassword, confirm: e.target.value });
                }}
              />
              {newPassword.password &&
              newPassword.password == newPassword.confirm ? (
                <div className="d-flex mb-3 justify-content-center align-items-center green-text">
                  <MDBIcon icon="check-circle" size="2x" />
                  <span className="ml-3 font-weight-bold">
                    As Senhas conferem
                  </span>
                </div>
              ) : (
                ''
              )}
              <span style={{ fontSize: '12px' }}>
                * A senha precisa ter pelo menos 6 caracteres
              </span>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="danger"
                onClick={() => {
                  setModalEditPassword(!modalEditPassword);
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                onClick={() => {
                  alteraSenha();
                }}
                color="success"
              >
                Alterar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Perfil;
