import React, { Component } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBInput,
  MDBContainer,
} from 'mdbreact';

import api from '../../../services/api';

import NavBar from '../../../components/NavBar';

import item from '../../../assets/item.jpg';
// fazer roteamento com history

import Notificacao from '../../../components/Notificacao';

class AddPatrimonio extends Component {
  state = {
    placa: '',
    renavan: '',
    modelo: '',
    cor: '',
    caracterizada: '',
    giroflex: '',
    sirene: '',
  };

  enviaNotificacao = () => {
    this.setState({ notificacao: <Notificacao /> });
  };

  dados = async e => {
    e.preventDefault();

    try {
      await api.post('/usuario', this.state);
      this.setState({});

      this.enviaNotificacao();
      window.scrollTo(0, 0);
      setTimeout(() => {
        this.setState({ notificacao: '' });
      }, 5000);
    } catch (error) {
      alert(`Erro ao criar Frota! ${error}`);
    }
  };

  render() {
    return (
      <>
        {this.state.notificacao}
        <NavBar />
        <MDBContainer>
          <h3 className="h3-responsive text-center font-weight-bolder">
            Adicionar Patrimônio
          </h3>
          <MDBRow>
            <MDBCol col="12" md="6">
              <MDBCard>
                <MDBCardBody>
                  <MDBInput
                    label="Data de Aquisição"
                    icon="calendar-check"
                    group
                    type="date"
                    onChange={e => this.setState({ cor: e.target.value })}
                    value={this.state.cor}
                  />
                  <div className="mt-4">
                    <h4>Tipo do bem</h4>
                    <select className="browser-default custom-select">
                      <option disabled>Selecione</option>
                      <option value="true">Mobilia</option>
                      <option value="false">Equipamento</option>
                    </select>
                  </div>
                  <MDBInput
                    label="Nome"
                    icon="tag"
                    group
                    type="text"
                    onChange={e => this.setState({ placa: e.target.value })}
                    value={this.state.placa}
                  />

                  <MDBInput
                    label="Número BP"
                    icon="digital-tachograph"
                    group
                    type="text"
                    onChange={e => this.setState({ renavam: e.target.value })}
                    value={this.state.renavam}
                  />

                  <MDBInput
                    label="Número UC"
                    icon="tags"
                    group
                    type="text"
                    onChange={e => this.setState({ modelo: e.target.value })}
                    value={this.state.modelo}
                  />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol className="mt-4 text-center" col="12" md="6">
              <h6> Insira a imagem do Patrimônio</h6>
              <input type="file" />
              <img
                style={{ maxWidth: '350px' }}
                className="img-fluid mt-3"
                src={item}
                alt="documento do veiculo"
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <MDBContainer className="d-flex justify-content-center mt-4">
          <MDBBtn type="submit" className=" mb-4" color="success">
            {/* <MDBIcon icon="user-plus" size="2x" /> */}
            Cadastrar Patrimônio
          </MDBBtn>
        </MDBContainer>
      </>
    );
  }
}

export default AddPatrimonio;
