import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NavBar from '../NavBar';

import { isAuthenticated, getData } from '../../services/auth';
import { toast } from 'react-toastify';

const trataUrl = props => {
  const teste2 = props.match.path.split('/:');
  return teste2[0];
};

const PrivateRoute = ({ component: Component, ...rest }) => (
  <>
    <Route
      {...rest}
      render={props => {
        const userData = getData(); // Obter dados do usuário

        if (!userData) {
          // Se não há dados do usuário, redirecione para o login
          return <Redirect to="/" />;
        }

        return isAuthenticated() ? (
          <>
            {userData.acessos.find(teste => teste.rota === trataUrl(props)) ===
            undefined ? (
              <>
                {toast.error('Rota não Autorizada')}
                <Redirect
                  to={{
                    pathname: `/`,
                    state: { from: props.location },
                  }}
                />
              </>
            ) : (
              <>
                {/* {window.scrollTo(0, 0)} */}
                {props.location.pathname === '/app' ? (
                  <Component {...props} />
                ) : (
                  <>
                    {/* <NavBar> */}
                    <Component {...props} />
                    {/* </NavBar> */}
                  </>
                )}
                {/* </NavBar> */}
              </>
            )}
          </>
        ) : (
          <>
            <Redirect
              to={{
                pathname: `/`,
                state: { from: props.location },
              }}
            />
          </>
        );
      }}
    />
  </>
);

export default PrivateRoute;
