import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBRow,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBAnimation,
  MDBSelect,
  MDBSwitch,
} from 'mdbreact';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { getData } from '../../../services/auth';
import { useState } from 'react';
import { useEffect } from 'react';
// import { compareAsc } from 'date-fns';

import api from '../../../services/api';
import { toast } from 'react-toastify';
import Select from 'react-select';

export function AddDenuncia(props) {
  const [listaClassificacao, setListaClassificacao] = useState([]);
  const [classificacaoSelecionada, setClassificacaoSelecionada] = useState(
    null
  );

  const [loading, setLoading] = useState(false);
  const [listanivelSigilo, setListaNivelSigilo] = useState([]);
  const [nivelSigiloSelecionado, setNivelSigiloSelecionado] = useState(null);
  const [switchDenunciaAnonima, setSwitchDenunciaAnonima] = useState(true);

  const [nomeDenunciante, setNomeDenunciante] = useState('');
  const [contatoDenunciante, setContatoDenunciante] = useState('');
  const [denuncia, setDenuncia] = useState('');

  const buscaClassificacao = async () => {
    const resultSelect = [];
    const result = await api.get('/dd-classificacao');
    result.data.forEach(element => {
      resultSelect.push({ label: element.classificacao, value: element.id });
    });
    setListaClassificacao(resultSelect);
  };
  const buscaNivelSigilo = async () => {
    const resultSelect = [];
    const result = await api.get('/dd-nivel-sigilo');
    result.data.forEach(element => {
      resultSelect.push({ label: element.nivel_sigilo, value: element.id });
    });
    setListaNivelSigilo(resultSelect);
  };

  const enviarDenuncia = async () => {
    const dados = {};
    if (classificacaoSelecionada === null) {
      toast.error('Classificação não selecionada');
      return;
    }
    dados.dd_classificacao_id = classificacaoSelecionada.value;

    if (nivelSigiloSelecionado === null) {
      toast.error('Nivel de Sigilo não selecionado');
      return;
    }
    dados.dd_nivel_sigilo_id = nivelSigiloSelecionado.value;

    if (switchDenunciaAnonima === false) {
      if (nomeDenunciante === '') {
        toast.error('Preencha o Nome do Denunciante');
        return;
      }
      dados.nome = nomeDenunciante;

      if (contatoDenunciante === '') {
        toast.error('Preencha o Contato do Denunciante');
        return;
      }
      dados.contato = contatoDenunciante;
    }
    if (denuncia === '') {
      toast.error('Preencha a Denuncia');
      return;
    }
    dados.denuncia = denuncia;
    setLoading(true);
    try {
      const result = await api.post('/dd-denuncia', dados);
      toast.success('Denuncia criada com sucesso');
      props.addAbreModal(false);
      setClassificacaoSelecionada(null);
      setNivelSigiloSelecionado(null);
      setSwitchDenunciaAnonima(true);
      setNomeDenunciante('');
      setContatoDenunciante('');
      setDenuncia('');
      setLoading(false);
      props.buscaDenuncias();
    } catch (error) {
      toast.error('Denuncia não pôde ser criado');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.openModal === true) {
      buscaClassificacao();
      buscaNivelSigilo();
    }
  }, [props.openModal]);

  return (
    <MDBModal
      size="lg"
      isOpen={props.openModal === true}
      toggle={() => {
        props.addAbreModal(false);
      }}
    >
      <MDBModalHeader
        toggle={() => {
          // setModalPreviaServico(!modalPreviaServico);

          props.addAbreModal(false);
        }}
      >
        Criar Nova Denúncia
      </MDBModalHeader>
      <MDBModalBody>
        <MDBRow>
          <MDBCol middle>
            <Select
              placeholder="Classificação"
              options={listaClassificacao}
              onChange={e => setClassificacaoSelecionada(e)}
              value={classificacaoSelecionada}
            />
          </MDBCol>
          <MDBCol middle>
            <Select
              placeholder="Nível de Sigilo"
              options={listanivelSigilo}
              onChange={e => setNivelSigiloSelecionado(e)}
              value={nivelSigiloSelecionado}
            />
          </MDBCol>
          {/* <MDBCol>
            <Select placeholder="Status" />
          </MDBCol> */}
          <MDBCol className="d-flex flex-column text-center">
            <div className="font-weight-bolder"> Denúncia Anônima </div>
            <MDBSwitch
              checked={switchDenunciaAnonima}
              onChange={() => setSwitchDenunciaAnonima(!switchDenunciaAnonima)}
              labelLeft="Não"
              labelRight="Sim"
            />

            {/* <MDBInput outline label="Denunciante" /> */}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBCollapse
              id="basicCollapse"
              isOpen={switchDenunciaAnonima === false}
            >
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    outline
                    label="Nome do Denunciante"
                    onChange={e => setNomeDenunciante(e.target.value)}
                    value={nomeDenunciante}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    outline
                    label="Contato do Denunciante"
                    onChange={e => setContatoDenunciante(e.target.value)}
                    value={contatoDenunciante}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBCol>
        </MDBRow>
        {/* <MDBRow>
          <MDBCol
            size="4"
            middle
            className="d-flex align-items-center justify-content-between"
          >
            <MDBInput outline label="Veículos" />
            <MDBIcon size="2x" className="green-text" icon="plus-square" />
          </MDBCol>
          <MDBCol
            middle
            size="4"
            className="d-flex align-items-center justify-content-between"
          >
            <MDBInput outline label="Destinação" />
            <MDBIcon size="2x" className="green-text" icon="plus-square" />
          </MDBCol>
          <MDBCol
            middle
            className="d-flex align-items-center justify-content-between"
          >
            <MDBInput outline label="Empresas" />
            <MDBIcon size="2x" className="green-text" icon="plus-square" />
          </MDBCol>
        </MDBRow> */}
        <MDBRow>
          <MDBCol>
            <MDBInput
              label="Descreva a Denúncia"
              outline
              rows="12"
              type="textarea"
              onChange={e => setDenuncia(e.target.value)}
              value={denuncia}
            />
          </MDBCol>
        </MDBRow>
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn
          color="danger"
          size="sm"
          onClick={() => props.addAbreModal(false)}
        >
          Cancelar
        </MDBBtn>
        <MDBBtn
          disabled={loading}
          onClick={() => enviarDenuncia()}
          color="green"
          size="sm"
        >
          {/* <MDBSpinner small /> */}
          {loading ? 'Enviando...' : 'Confirmar'}
        </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  );
}
