import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBRow,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBAnimation,
  MDBSelect,
} from 'mdbreact';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import { getData } from '../../services/auth';
import { useState } from 'react';
import { useEffect } from 'react';
// import { compareAsc } from 'date-fns';

import api from '../../services/api';
import { toast } from 'react-toastify';

// import { Container } from './styles';

function ControleServico() {
  const [modalVisualizaServico, setModalVisualizarServico] = useState(false);
  const [collapseFiltro, setCollapseFiltro] = useState(false);

  const [servicoAtivo, setServicoAtivo] = useState(true);
  const [listaFrequencia, setListaFrequencia] = useState([]);
  const [collapseID, setCollapseID] = useState();
  const [setIdServico, idservico] = useState();
  const [updateDados, setUpdateDados] = useState({});

  const [motivoFalta, setMotivoFalta] = useState('Não comparecimento');
  const [apurado, setApurado] = useState([]);
  const [naoApurado, setNaoApurado] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [dadosDeServico, setDadosDeServico] = useState({});
  const [modalPreviaServico, setModalPreviaServico] = useState(false);
  const [efetivoPrevia, setEfetivoPrevia] = useState([]);

  const [escondeBotaoConfirmar, setEscondeBotaoConfirmar] = useState(false);

  const [listaGerencias, setListaGerencias] = useState([
    {
      text: '',
      value: '',
      logica: '',
    },
  ]);
  const [listaLotacoes, setListaLotacoes] = useState([]);
  const [lotacaoSelecionada, setLotacaoSelecionada] = useState('');
  const [dataInicioSelecionada, setDataInicioSelecionada] = useState('');
  const [dataFimSelecionada, setDataFimSelecionada] = useState('');

  // funcoes de abrir e fechar
  const visualizaPrevia = () => {
    if (modalPreviaServico === false) {
      // const lotacoesIds = [];
      api.get('/confirma-efetivo').then(async result => {
        if (result.data.erro) {
          toast.error(result.data.erro);
          return;
        }
        const servicos = await api.get('/verifica-duplicidade', {
          params: {
            lotacao_id: result.data[0].lotacao_id,
          },
        });

        if (servicos.data?.erro) {
          toast.error('Serviço Já Apurado');
          return;
        }
        setModalPreviaServico(!modalPreviaServico);
        setEfetivoPrevia(result.data);

        // console.log(result.data);
        // result.data.forEach(cal => {
        //   lotacoesIds.push(cal.lotacao_id);
        // });

        // lotacoesIds.forEach(async cal => {
        //   const servicos = await api.get('/verifica-duplicidade', {
        //     params: {
        //       lotacao_id: cal,
        //     },
        //   });
        //   if (servicos.data?.erro) {
        //     insereErros(cal);
        //   }
        // });

        // if (erros.length > 0) {
        //   toast.error('Serviço Já Apurado');
        //   return;
        // }
        // console.log(result.data);
      });
    }
  };

  const verificaServico = async () => {
    const response = await api.get('/verifica-servico');
    if (response.data.length > 0) {
      setServicoAtivo(false);
      listar();
      return;
    }
  };

  let apurado1 = [];
  let napurado1 = [];
  const listar = async () => {
    const result = await api.get('/frequencia');

    setListaFrequencia(result.data);
    if (result.data.erro) {
      return;
    }
    // if (result.data.length > 0) {
    //   setIdServico(result.data[0].servico_id);
    // }
    result.data.forEach(servico => {
      servico.frequencia.forEach(usuarios => {
        if (usuarios.presente !== null) {
          apurado1.push(usuarios);
        } else {
          napurado1.push(usuarios);
        }
      });
    });

    setNaoApurado(napurado1);
    setApurado(apurado1);
  };

  const listaHistorico = async () => {
    // carregaLotacoes(getData().gerencia_id);
    // console.log(getData().gerencia_id);
    try {
      const response = await api.get('/servico', {
        params: {
          lotacao_id: lotacaoSelecionada,
          data_inicio: dataInicioSelecionada,
          data_fim: dataFimSelecionada,
        },
      });

      setHistorico(response.data);
    } catch (error) {
      console.log(error);
    }

    // setHistorico(response.data);
  };

  const novoServico = async () => {
    setEscondeBotaoConfirmar(true);
    const response = await api.post('/servico');
    if (response.data.erro) {
      toast.error(response.data.erro);
      setEscondeBotaoConfirmar(false);
      return;
    } else {
      listar();
    }
    setServicoAtivo(false);

    setModalPreviaServico(false);
    // listar();
    // setTimeout(function() {
    //   verificaServico();
    // }, 1000);
    setEscondeBotaoConfirmar(false);
  };

  const updateFrequencia = async (id, dados) => {
    const response = await api.put(`/frequencia/${id}`, dados);

    setMotivoFalta('Não comparecimento');
    toast.success('Alterção feita com sucesso!');
    // verificaServico();
    listar();
  };

  const encerrarServico = async () => {
    const response = await api.put(`/encerra-servico`);

    if (response.data.erro) {
      toast.error(response.data.erro);
      return;
    }
    setServicoAtivo(true);
    verificaServico();
    listaHistorico();
    // listar();
  };

  const carregaDadosDeServico = async idHistorico => {
    const response = await api.get(`/servico/${idHistorico}`);

    setDadosDeServico(response.data);
  };

  const visualizaServico = () => {
    setModalVisualizarServico(!modalVisualizaServico);
  };

  const carregaSelectsFiltros = () => {
    carregaGerencias();
  };

  // fazer uma requisicao e descobrir se esse servico ja existe

  useEffect(() => {
    // listar();
    // listaHistorico();
    verificaServico();
  }, []);

  const presentes = array => {
    let presente = 0;
    let ausente = 0;
    array.map(frequencia => {
      if (frequencia.presente === true) {
        presente++;
      }
      if (frequencia.presente === false) {
        ausente++;
      }
    });

    return { presente, ausente };
  };

  // carrega as lotacoes do select
  const carregaLotacoes = gerenciaid => {
    if (gerenciaid === undefined || gerenciaid === '' || gerenciaid === null) {
      return;
    }
    api.get(`/lotacao-por-gerencia/${gerenciaid}`).then(linhas => {
      const arrayValuesLotacao = [];

      // insere a opcao todas , buscando todas as lotacoes
      const selectLotacao = [
        {
          text: 'Todas',
          value: arrayValuesLotacao,
        },
      ];

      linhas.data.forEach(linha => {
        arrayValuesLotacao.push(linha.id);
        selectLotacao.push({
          text: `${linha.escala.nome}/ ${linha.ala.nome}`,
          value: String(linha.id),
        });
      });
      setListaLotacoes(selectLotacao);
    });
  };

  // carrega as gerencias do select
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          text: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
    // carregaLotacoes(gerenciaSelecionada);
  };

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-between mx-3">
            <MDBCol className="h3-responsive text-left">
              Controle de Presença
            </MDBCol>

            {servicoAtivo ? (
              <MDBBtn color="mdb-color" onClick={() => visualizaPrevia()}>
                <span>Criar Novo Serviço</span>
              </MDBBtn>
            ) : (
              ''
            )}
          </MDBRow>
          <br />

          {servicoAtivo ? (
            <>
              <MDBRow className={servicoAtivo ? 'd-flex' : 'd-none'}>
                <MDBCol
                  col="12"
                  sm="12"
                  md="4"
                  className="h4-responsive d-flex justify-content-end align-items-center"
                ></MDBCol>
              </MDBRow>
              <MDBContainer className="mt-5">
                <MDBRow className="d-flex align-items-center">
                  <MDBCol>
                    <h4>Histórico:</h4>
                  </MDBCol>
                  <MDBCol>
                    <MDBBtn
                      color="mdb-color"
                      onClick={() => {
                        setCollapseFiltro(!collapseFiltro);
                        carregaSelectsFiltros();
                      }}
                      className="d-flex align-items-center ml-auto"
                    >
                      <MDBIcon icon="filter" size="1x" />
                      <span className="ml-2">Filtrar</span>
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
                <MDBCollapse id="basicCollapse" isOpen={collapseFiltro}>
                  <MDBRow className="rounded-top p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center text-end  bg-white">
                    <MDBCol md="3" col="12">
                      <MDBSelect
                        className="font-weight-bold"
                        // options={listaGerencias}
                        options={listaGerencias}
                        label="Gerencia"
                        getValue={t => {
                          carregaLotacoes(t[0]);
                        }}
                      />
                    </MDBCol>
                    <MDBCol md="3" col="12">
                      <MDBSelect
                        className="font-weight-bold"
                        // options={listaGerencias}
                        options={listaLotacoes}
                        label="Escala/Ala"
                        getValue={t => {
                          setLotacaoSelecionada(t[0]);
                        }}
                      />
                    </MDBCol>
                    <MDBCol md="3" col="12" className="mt-2">
                      <span
                        style={{ fontSize: '0.9rem', color: 'gray' }}
                        className="font-weight-bolder"
                      >
                        Data Início
                      </span>
                      <input
                        type="date"
                        style={{ border: '1px silver solid' }}
                        className="px-2"
                        onChange={e => {
                          setDataInicioSelecionada(e.target.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol
                      className="flex-collumn justify-content-center align-items-center mt-2"
                      md="3"
                      col="12"
                    >
                      <span
                        style={{ fontSize: '0.9rem', color: 'gray' }}
                        className="font-weight-bolder"
                      >
                        Data Término
                      </span>
                      <input
                        type="date"
                        className="px-2"
                        style={{ border: '1px silver solid' }}
                        onChange={e => {
                          setDataFimSelecionada(e.target.value);
                        }}
                      />
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="rounded-bottom mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white">
                    <MDBBtn
                      color="mdb-color"
                      onClick={() => listaHistorico()}
                      className=" d-flex justify-content-around align-items-center "
                    >
                      Buscar
                    </MDBBtn>
                  </MDBRow>
                </MDBCollapse>

                <MDBRow
                  between
                  style={{ borderRadius: '5px' }}
                  className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex"
                >
                  <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                    Escala/Ala
                  </div>
                  <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                    Data Início
                  </div>

                  <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                    Data Término
                  </div>
                  <div className="col-12 col-md-1 d-flex flex-column font-weight-bold text-white">
                    <span>Frequência</span>
                    {/*
                    <span className="text-danger">Ausentes</span> */}
                  </div>
                  <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white"></div>
                </MDBRow>
                {historico &&
                  historico.map(linha => (
                    <MDBRow
                      key={linha.id}
                      between
                      style={{ borderRadius: '5px' }}
                      className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                    >
                      <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                        <span className="d-sm-inline d-md-none mr-3">
                          Escala/Ala
                        </span>
                        {linha.lotacao.escala.nome} / {linha.lotacao.ala.nome}
                      </div>

                      <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                        <span className="d-sm-inline d-md-none mr-3">
                          Data Início
                        </span>
                        {format(
                          new Date(linha.dt_h_inicio),
                          'dd/MM/yyyy - HH:mm'
                        )}
                      </div>

                      <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                        <span className="d-sm-inline d-md-none mr-3">
                          Data Término
                        </span>
                        {linha.dt_h_fim === null ? (
                          <>Serviço Aberto</>
                        ) : (
                          format(new Date(linha.dt_h_fim), 'dd/MM/yyyy - HH:mm')
                        )}
                      </div>

                      <div className="col-12 col-md-1 mb-md-0 d-flex justify-content-between justify-content-md-start font-weight-bold">
                        <span className="d-sm-inline d-md-none mr-3">
                          Presentes / Ausentes
                        </span>
                        <div className="text-success">
                          {String(presentes(linha.frequencia).presente)}
                        </div>
                        <div className="mx-1 grey-text">/</div>
                        <div className="text-danger">
                          {String(presentes(linha.frequencia).ausente)}
                        </div>
                      </div>

                      <div className="col-12 col-md-2 d-flex justify-content-end">
                        <span className="d-sm-inline d-md-none mr-3">
                          Hora Término
                        </span>

                        <div
                          className="font-weight-bold"
                          style={{ cursor: 'pointer', color: '#59698d' }}
                          onClick={() => {
                            carregaDadosDeServico(linha.id);
                            visualizaServico();
                          }}
                        >
                          Visualizar
                        </div>
                      </div>
                    </MDBRow>
                  ))}
              </MDBContainer>
            </>
          ) : (
            <>
              {naoApurado.length == 0 ? (
                <MDBRow className="justify-content-center mt-3 font-weight-bold">
                  {/* lista vazia */}
                </MDBRow>
              ) : (
                ''
              )}
              {listaFrequencia.map(servico => (
                <>
                  <MDBRow
                    key={servico.id}
                    between
                    style={{ borderRadius: '5px' }}
                    className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
                  >
                    <div className="col-12 col-md-6 d-flex justify-content-between font-weight-bold text-white">
                      {`Escala / Ala :  ${servico.lotacao.escala.nome} ${servico.lotacao.ala.nome}`}
                    </div>

                    <div className="col-12 col-md-6 d-flex justify-content-between font-weight-bold text-white">
                      <span>
                        Data Início:
                        {format(
                          new Date(servico.dt_h_inicio),
                          ' dd/MM/yyyy - HH:mm'
                        )}
                      </span>
                    </div>
                  </MDBRow>
                  {servico.frequencia.map(linha => (
                    <>
                      {linha.presente === null ? (
                        <>
                          <MDBRow
                            key={linha.id}
                            between
                            style={{ borderRadius: '5px' }}
                            className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                          >
                            <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                              {/* {setor.gerencia.nome} */}
                              <span className="d-sm-inline d-md-none mr-3">
                                Nome Social
                              </span>
                              <span className="font-weight-bold">
                                {linha.usuario.nome_social}
                              </span>
                            </div>

                            <div className="col-12 col-md-4 d-flex justify-content-between">
                              <span className="d-sm-inline d-md-none mr-3">
                                Nome
                              </span>
                              {`${linha.usuario.nome} ${linha.usuario.sobrenome}`}
                            </div>

                            <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                              {/* {setor.gerencia.nome} */}
                              <span className="d-sm-inline d-md-none mr-3">
                                Telefone
                              </span>
                              {linha.usuario.telefone}
                            </div>

                            {/* <div className="col-12 col-md-3 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3"> Nome</span>
                      {linha.usuario.telefone}
                    </div> */}
                            <div className="col-12 col-md-3 d-flex justify-content-between">
                              {/* {setor.ativo ? ( */}
                              <span className="d-sm-inline d-md-none mr-3">
                                Status:
                              </span>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setCollapseID(`linha${linha.id}`);
                                }}
                              >
                                <div className="font-weight-bold text-danger ">
                                  Ausente{' '}
                                  <MDBIcon
                                    className="ml-1"
                                    far
                                    icon="thumbs-down"
                                  />
                                </div>
                              </div>
                              {/* ) : ( */}
                              <span className="font-weight-bold text-success ">
                                {' '}
                                <div
                                  onClick={() =>
                                    updateFrequencia(linha.id, {
                                      presente: true,
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  Presente{' '}
                                  <MDBIcon
                                    className="ml-1"
                                    far
                                    icon="thumbs-up"
                                  />
                                </div>
                              </span>
                              {/* )} */}
                            </div>
                          </MDBRow>
                          <MDBCollapse
                            id="basicCollapse"
                            isOpen={`linha${linha.id}` === collapseID}
                            style={{
                              borderRadius: '5px',
                              backgroundColor: 'rgba(250,250,250,0.3)',
                            }}
                            className=" p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center"
                          >
                            <MDBRow className="align-items-center">
                              <MDBCol className="col-1 col-md-0"></MDBCol>
                              <MDBCol className="col-9 col-md-8">
                                <select
                                  className="browser-default custom-select"
                                  value={motivoFalta}
                                  onChange={e => setMotivoFalta(e.target.value)}
                                >
                                  <option>Não comparecimento</option>
                                  <option>Férias</option>

                                  {/* <option>Licença Saúde</option>
                                  <option>
                                    Licença Maternidade/Paternidade
                                  </option>
                                  <option>Licença Matrimonial</option>
                                  <option>Licença Falecimento</option> */}
                                  <option>Dispensa Coordenação</option>
                                  <option>Em Treinamento</option>
                                </select>
                              </MDBCol>

                              <MDBCol className="col-4 col-md-3">
                                <MDBBtn
                                  onClick={() =>
                                    updateFrequencia(linha.id, {
                                      presente: false,
                                      observacao: motivoFalta,
                                    })
                                  }
                                >
                                  Enviar
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </MDBCollapse>
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  ))}
                  <br /> <br />
                </>
              ))}
              <br />
              <br />
              <br />
              <MDBRow>
                <span className="h5 responsive">APURADOS</span>
              </MDBRow>

              {apurado.map(linha => (
                <>
                  <MDBRow
                    key={linha.id}
                    between
                    className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                    style={{
                      borderRadius: '5px',
                      border: `2px solid ${
                        linha.presente === false ? 'red' : 'white'
                      }`,
                    }}
                  >
                    <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Nome Social
                      </span>
                      <span className="font-weight-bold">
                        {linha.usuario.nome_social}
                      </span>
                    </div>

                    <div className="col-12 col-md-4 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">Nome</span>

                      {linha.presente === true
                        ? ''
                        : linha.observacao === null
                        ? ''
                        : linha.observacao}
                    </div>

                    <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Telefone
                      </span>
                      {linha.usuario.telefone}
                    </div>

                    {/* <div className="col-12 col-md-3 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3"> Nome</span>
                      {linha.usuario.telefone}
                    </div> */}
                    <div className="col-12 col-md-3 d-flex justify-content-between">
                      {/* {setor.ativo ? ( */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Status:
                      </span>
                      {linha.presente === true ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setCollapseID(`linha${linha.id}`);
                          }}
                        >
                          <div className="font-weight-bold text-danger ">
                            Dar Ausência
                            <MDBIcon className="ml-1" far icon="thumbs-down" />
                          </div>
                        </div>
                      ) : (
                        ''
                      )}

                      {/* ) : ( */}
                      <span className="font-weight-bold text-success ">
                        {linha.presente === false ? (
                          <div
                            onClick={() =>
                              updateFrequencia(linha.id, { presente: true })
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            Dar Presença
                            <MDBIcon className="ml-1" far icon="thumbs-up" />
                          </div>
                        ) : (
                          ''
                        )}
                      </span>
                      {/* )} */}
                    </div>
                  </MDBRow>

                  <MDBCollapse
                    id="basicCollapse"
                    isOpen={`linha${linha.id}` === collapseID}
                    style={{
                      borderRadius: '5px',
                      backgroundColor: 'rgba(250,250,250,0.3)',
                    }}
                    className=" p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center"
                  >
                    <MDBRow className="align-items-center">
                      <MDBCol className="col-1 col-md-0"></MDBCol>
                      <MDBCol className="col-9 col-md-8">
                        <select
                          className="browser-default custom-select"
                          value={motivoFalta}
                          onChange={e => setMotivoFalta(e.target.value)}
                        >
                          <option>Não comparecimento</option>
                          <option>Férias</option>
                          {/* <option>Licença Saúde</option>
                          <option>Licença Maternidade/Paternidade</option>
                          <option>Licença Matrimonial</option>
                          <option>Licença Falecimento</option> */}
                          <option>Dispensa Coordenação</option>
                          <option>Em Treinamento</option>
                        </select>
                      </MDBCol>

                      <MDBCol className="col-4 col-md-3">
                        <MDBBtn
                          onClick={() =>
                            updateFrequencia(linha.id, {
                              presente: false,
                              observacao: motivoFalta,
                            })
                          }
                        >
                          Enviar
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </MDBCollapse>
                </>
              ))}
              <br />
              <br />
              <MDBRow className="justify-content-center">
                <MDBBtn color="mdb-color" onClick={() => encerrarServico()}>
                  Encerrar Serviço
                </MDBBtn>
              </MDBRow>
            </>
          )}
        </MDBContainer>

        <MDBModal
          isOpen={modalVisualizaServico}
          toggle={() => {
            setModalVisualizarServico({
              modalVisualizaServico: !modalVisualizaServico,
            });
          }}
          size="lg"
        >
          <MDBModalHeader toggle={visualizaServico}>
            {Object.entries(dadosDeServico) == 0 ? (
              ''
            ) : (
              <>
                {`${dadosDeServico.lotacao.gerencia.sigla} / ${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`}
              </>
            )}

            {/* format(
                          new Date(servico.dt_h_inicio),
                          ' dd/MM/yyyy - HH:mm'
                        ) */}
            {/* GERENCIA / ESCALA / ALA - inicio e fim */}
            {/* {dadosDeServico.lotacao.ala.id} */}
            {/* `${dadosDeServico.lotacao.gerencia.sigla} / ${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`} */}
          </MDBModalHeader>
          <MDBModalBody style={{ backgroundColor: 'rgba(82,101,140,0.2)' }}>
            {Object.entries(dadosDeServico) == 0 ? (
              ''
            ) : (
              <>
                <MDBRow>
                  <MDBCol className="d-flex" col="12" md="6">
                    <div className="mr-2">Data Início:</div>
                    <div>
                      {format(
                        new Date(dadosDeServico.dt_h_inicio),
                        ' dd/MM/yyyy - HH:mm'
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol className="d-flex" col="12" md="6">
                    <div className="mr-2">Data Fim:</div>
                    <div>
                      {format(
                        new Date(dadosDeServico.dt_h_fim),
                        ' dd/MM/yyyy - HH:mm'
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow className="d-flex justify-content-center font-weight-bold grey-text">
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Presentes</div>
                    <div className="text-success">
                      {presentes(dadosDeServico.frequencia).presente}
                    </div>
                  </MDBCol>
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Ausentes</div>
                    <div className="text-danger">
                      {presentes(dadosDeServico.frequencia).ausente}
                    </div>
                  </MDBCol>
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Total</div>
                    <div>
                      {presentes(dadosDeServico.frequencia).presente +
                        presentes(dadosDeServico.frequencia).ausente}
                    </div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <br />

                <MDBRow
                  between
                  style={{ borderRadius: '5px' }}
                  className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1 "
                >
                  <div className="col-12 col-md-5 d-flex justify-content-between font-weight-bold text-white">
                    Nome Social
                  </div>

                  <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
                    Frequência
                  </div>

                  <div className="col-12 col-md-5 d-flex justify-content-between font-weight-bold text-white">
                    Motivo
                  </div>
                </MDBRow>

                {dadosDeServico.frequencia.map(frequencia => (
                  <MDBRow
                    key={frequencia.id}
                    between
                    style={{ borderRadius: '5px' }}
                    className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                  >
                    <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Nome Social
                      </span>
                      <span
                        style={{ color: 'rgba(0,0,0,0.7)' }}
                        className="font-weight-bold"
                      >
                        {frequencia.usuario.nome_social}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Frequência
                      </span>
                      <span className="font-weight-bold">
                        {frequencia.presente === true ? (
                          <span className="text-success">Presente</span>
                        ) : (
                          <span className="text-danger">Ausente</span>
                        )}
                      </span>
                    </div>
                    <div className="col-12 col-md-5 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Observação
                      </span>
                      <span
                        style={{ color: 'rgba(0,0,0,0.7)' }}
                        className="font-weight-bold"
                      >
                        {frequencia.observacao}
                      </span>
                    </div>
                  </MDBRow>
                ))}
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="success" onClick={visualizaServico}>
              Concluir
            </MDBBtn>
            {/* <MDBBtn
              // onClick={enviaResetSenha}
              color="success"
            >
              Resetar Senha
            </MDBBtn> */}
          </MDBModalFooter>
        </MDBModal>
        <br />
        <br />

        <MDBModal
          isOpen={modalPreviaServico}
          toggle={() => {
            setModalPreviaServico(!modalPreviaServico);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalPreviaServico(!modalPreviaServico);
            }}
          >
            Confirme o Efetivo Escalado
          </MDBModalHeader>
          <MDBModalBody>
            {efetivoPrevia.length > 0 ? (
              <>
                {efetivoPrevia.map(calendario => (
                  <>
                    <MDBRow
                      key={calendario.id}
                      between
                      style={{ borderRadius: '5px' }}
                      className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1 "
                    >
                      <div className="col-12 col-md-5 d-flex justify-content-between font-weight-bold text-white">
                        Nome Social
                      </div>
                      <div className="col-12 col-md-5 d-flex justify-content-between font-weight-bold text-white">
                        Ala: {calendario.lotacao.ala.nome}
                      </div>
                    </MDBRow>

                    {calendario.lotacao.usuarios.map(efetivo => (
                      <MDBRow
                        key={efetivo.id}
                        between
                        style={{ borderRadius: '5px' }}
                        className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                      >
                        <div className="col-12  mb-md-0 d-flex justify-content-between justify-content-md-start">
                          {/* {setor.gerencia.nome} */}

                          <span
                            style={{ color: 'rgba(0,0,0,0.7)' }}
                            className="font-weight-bold"
                          >
                            {efetivo.nome_social}
                          </span>
                        </div>
                      </MDBRow>
                    ))}
                  </>
                ))}
              </>
            ) : (
              ''
            )}
          </MDBModalBody>
          <MDBModalFooter>
            {escondeBotaoConfirmar ? (
              <MDBAnimation
                type="bounceIn"
                className="d-flex align-items-center p-2"
              >
                <MDBSpinner small />
                <span className="ml-3 font-weight-bolder">Carregando</span>
              </MDBAnimation>
            ) : (
              <>
                <MDBBtn
                  color="danger"
                  onClick={() => {
                    // visualizaPrevia(!modalPreviaServico);
                    setModalPreviaServico(!modalPreviaServico);
                  }}
                >
                  Cancelar
                </MDBBtn>
                <MDBBtn onClick={() => novoServico()} color="success">
                  Confirmar
                </MDBBtn>
              </>
            )}
          </MDBModalFooter>
        </MDBModal>
      </NavBar>
    </>
  );
}

export default ControleServico;
