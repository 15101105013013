import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBNav,
  MDBRow,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Barreira from '../../assets/barreira.png';
import LeiSeca from '../../assets/leiseca.jpg';
import Select from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { compareAsc } from 'date-fns';
import { getData } from '../../services/auth';

import api from '../../services/api';
import { toast } from 'react-toastify';
// import { Container } from './styles';

function Alocacao() {
  const [collapseFiltro, setCollapseFiltro] = useState(false);

  const [lotacoes, setLotacoes] = useState([]);

  const [novaLotacao, setNovaLotacao] = useState({});
  const [novaEscala, setNovaEscala] = useState({});

  const [novaescala, setNovaescala] = useState({});

  // select com as gerencias
  const [optionGerencias, setOptionGerencias] = useState([]);

  const [optionEscalas, setOptionEscalas] = useState([]);

  const [todasGerencias, setTodasGerencias] = useState(false);

  const [checkNovaEscala, setCheckEscala] = useState(false);

  const [gerencia, setGerencia] = useState('');

  // lista lotacoes na pagina
  const buscaGerencia = getData().gerencia;
  const loadLotacoes = async gerencia => {
    const response = await api.get('/lotacao', {
      params: {
        gerencia: buscaGerencia,
      },
    });

    setLotacoes(response.data);
  };

  // armazena dados de gerencia no select
  const selectGerencia = async () => {
    const response = await api.get('/gerencia?ativo=true');

    let optionGerencias = [];
    response.data.map(gerencia => {
      optionGerencias.push({
        value: gerencia.id,
        label: gerencia.nome,
      });
    });

    setOptionGerencias(optionGerencias);
  };

  // armazena dados de escala no select
  const selectEscalas = async () => {
    const response = await api.get('/escala');

    // // setEscalas(response.data);

    let optionEscalas = [];
    response.data.map(escala => {
      // optionlotacao.push({ value: lotacao.id , label: lotacao.escala.nome+' / '+lotacao.ala.nome })
      optionEscalas.push({ value: escala.id, label: escala.nome });
    });
    setOptionEscalas(optionEscalas);
  };

  // requisao para uma nova lotacao
  const enviaLotacao = async () => {
    if (gerencia == '') {
      toast.error('Preecha o campo Gerência');
      return;
    }

    if (checkNovaEscala === true) {
      delete novaLotacao.escala;
      novaLotacao.novaEscala = novaEscala;
    }
    if (checkNovaEscala === false) {
      delete novaLotacao.novaEscala;

      // escala já esta sendo adicionda no novalotacao
    }

    if (checkNovaEscala === true) {
      if (!novaEscala?.h_trabalho || !novaEscala?.h_folga) {
        toast.error('Preecha os campos da Nova Escala');
        return;
      }
    }

    if (!novaLotacao.escala && !novaLotacao.novaEscala) {
      toast.error('Preecha o campo Escala');
      return;
    }

    const response = await api.post('/lotacao', { gerencia, ...novaLotacao });

    if (response.data.erro) {
      toast.error(response.data.erro);
      return;
    }
    toast.success('Lotação Adicionada!');

    loadLotacoes();
  };

  // const ativarDesativar = async (idLotacao, status) => {
  //   let inverteValor;
  //   if (status === true) {
  //     inverteValor = false;
  //   }
  //   if (status === false) {
  //     inverteValor = true;
  //   }

  //   try {
  //     const response = await api.put(`/lotacao/${idLotacao}`, {
  //       ativo: inverteValor,
  //     });
  //     toast.success('Lotação Alterada!');
  //   } catch (error) {
  //     toast.success('Lotação não pode ser alterada!');
  //   }

  //   loadLotacoes();
  // };

  useEffect(() => {
    // loadSetores();
    selectEscalas();
    // loadEscalaServico();
    loadLotacoes();
    selectGerencia();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-between align-items-center mx-3">
            <MDBCol col="4" md="4" className="h4-responsive">
              Configuração de Lotação
            </MDBCol>
            <MDBCol col="8" md="4" className="d-flex justify-content-end">
              <MDBBtn
                color="primary"
                onClick={() => setCollapseFiltro(!collapseFiltro)}
                className="d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="plus" size="2x" />
                <h5 className="m-0">Nova Lotacao</h5>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          <MDBCollapse id="basicCollapse" isOpen={collapseFiltro}>
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
            >
              <h4>Insira nova Lotação</h4>
            </MDBRow>
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
            >
              <MDBCol col="5" className="text-left">
                {/* <div className="custom-control custom-checkbox">
                <input
                  onChange={() => {
                    setTodasGerencias(!todasGerencias);
                  }}
                  type="checkbox"
                  className="custom-control-input"
                  id="todasGerencias"
                />
                <label
                  className="custom-control-label font-weight-bold blue-grey-text"
                  htmlFor="todasGerencias"
                >
                  Gerar para todas as Gerências subordinadas
                </label>
              </div> */}
                <br />
                <Select
                  className="text-left w-100 mb-4 mt-4"
                  onChange={e => {
                    setGerencia(e.value);
                    // ajuda de uma usestate para armazenar o valor caso o input de todas seja marcado
                    // setGerenciaFilha(e.value)
                  }}
                  isDisabled={todasGerencias}
                  placeholder="Selecione a Gerência"
                  options={optionGerencias}
                />
              </MDBCol>

              <MDBCol col="5" className="text-left">
                <div className="custom-control custom-checkbox">
                  {/* <input
                    onChange={() => {
                      setCheckEscala(!checkNovaEscala);
                      setNovaEscala();
                    }}
                    type="checkbox"
                    className="custom-control-input"
                    id="defaultUnchecked"
                  />
                  <label
                    className="custom-control-label font-weight-bold blue-grey-text"
                    htmlFor="defaultUnchecked"
                  >
                    Criar Nova Escala
                  </label> */}
                </div>
                {checkNovaEscala ? (
                  <>
                    <MDBInput
                      onChange={e => {
                        setNovaEscala({
                          ...novaEscala,
                          h_trabalho: e.target.value,
                        });
                      }}
                      type="number"
                      label="Horas trabalhadas ex:( 24 )"
                    />
                    <MDBInput
                      onChange={e => {
                        setNovaEscala({
                          ...novaEscala,
                          h_folga: e.target.value,
                        });
                      }}
                      type="number"
                      label="Horas folga ex:( 72 )"
                    />
                  </>
                ) : (
                  <Select
                    className="text-left mb-4 mt-4"
                    onChange={e => {
                      setNovaLotacao({ ...novaLotacao, escala: e.value });
                    }}
                    placeholder="Escala"
                    options={optionEscalas}
                  />
                )}
              </MDBCol>
              <MDBBtn
                color="success"
                onClick={() => enviaLotacao()}
                className="h-50 col-8 col-md-2 d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="plus" size="2x" />
                <h5 className="m-0">Cadastrar</h5>
              </MDBBtn>
            </MDBRow>
          </MDBCollapse>

          <MDBRow
            between
            style={{ borderRadius: '5px' }}
            className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
          >
            <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
              Gerência
            </div>

            <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
              Escala
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-between font-weight-bold text-white">
              Ala
            </div>
            {/* <div className="col-12 col-md-2 d-flex justify-content-center font-weight-bold text-white">
            Status
          </div> */}
          </MDBRow>

          {lotacoes === [] ? (
            <>Sem Lotações</>
          ) : (
            lotacoes.map(lotacao => (
              <MDBRow
                key={lotacao.id}
                between
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <div className="col-12 col-md-3 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                  {lotacao.gerencia.nome}
                </div>

                <div className="col-12 col-md-3 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Guarnicao:</span>
                  {lotacao.escala.nome}
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Área:</span>
                  {lotacao.ala.nome}
                </div>
                {/* <div className="col-12 col-md-2 d-flex justify-content-end">
                {lotacao.ativo ? (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => ativarDesativar(lotacao.id, lotacao.ativo)}
                    className="font-weight-bold text-danger "
                  >
                    Desativar{' '}
                    <MDBIcon className="ml-1" far icon="thumbs-down" />
                  </div>
                ) : (
                  <span className="font-weight-bold text-success ">
                    {' '}
                    <div
                      onClick={() => ativarDesativar(lotacao.id, lotacao.ativo)}
                      style={{ cursor: 'pointer' }}
                    >
                      Ativar <MDBIcon className="ml-1" far icon="thumbs-up" />
                    </div>
                  </span>
                )}
              </div> */}
              </MDBRow>
            ))
          )}
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Alocacao;
