import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBNavbarNav,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';

// import { Container } from './styles';
import api from '../../../services/api';
import Select from 'react-select';
import { getData } from '../../../services/auth';

export default function ListaVtrs(props) {
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState(null);
  const [dataVtrs, setDataVtrs] = useState([]);

  const [visualizaVtr, setVisualizaVtr] = useState(null);
  const [dadosVtr, setDadosVtr] = useState(null);
  const [ativos, setAtivos] = useState({ label: 'Ativo', value: true });

  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
  };

  const buscaVtrs = async () => {
    const where = { ativo: ativos.value };
    // // const where = { data_fim: null };

    if (gerenciaSelecionada !== null) {
      where.gerencia_id = gerenciaSelecionada.value;
    } else {
      where.gerencia_id = getData().gerencia_id;
    }

    const result = await api.get('/frota', {
      params: where,
    });

    setDataVtrs(result.data);
  };

  const verificaVtrAtiva = vtr => {
    if (vtr.atividade_fim !== null) {
      return (
        <MDBIcon
          className="text-danger"
          style={{ fontSize: '21px' }}
          icon="window-close"
        />
      );
    } else {
      if (
        vtr?.manutencoes[0] &&
        vtr?.manutencoes[0]?.data_iniciado !== null &&
        vtr?.manutencoes[0]?.data_executado === null
      ) {
        return (
          <MDBIcon
            className="text-warning"
            style={{ fontSize: '21px' }}
            fas
            icon="exclamation-triangle"
          />
        );
      }
      if (
        vtr?.manutencoes[0] &&
        vtr?.manutencoes[0]?.data_agendado !== null &&
        vtr?.manutencoes[0]?.data_iniciado === null &&
        vtr?.manutencoes[0]?.data_executado === null
      ) {
        return (
          <MDBIcon
            className="blue-text"
            style={{ fontSize: '21px' }}
            icon="calendar-check"
          />
        );
      }
      if (vtr?.status_veiculos[0]?.tipos_status_veiculo.utilizavel === false) {
        return (
          <MDBIcon
            className="text-warning"
            style={{ fontSize: '21px' }}
            fas
            icon="exclamation-triangle"
          />
        );
      }
    }

    return (
      <MDBIcon
        className="text-success"
        style={{ fontSize: '21px' }}
        icon="check-circle"
      />
    );
  };

  useEffect(() => {
    carregaGerencias();
  }, []);
  useEffect(() => {
    buscaVtrs();
  }, [gerenciaSelecionada, ativos]);

  return (
    <NavBar>
      <MDBContainer fluid>
        <MDBRow className="align-items-between mb-5">
          <MDBCol size="12" md="3">
            <Select
              className="font-weight-bolder"
              options={listaGerencias}
              placeholder="Gerência"
              onChange={e => setGerenciaSelecionada(e)}
              // isLoading={loadingSelect}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <Select
              className="font-weight-bolder"
              options={[
                { label: 'Ativo', value: true },
                { label: 'Inativo', value: false },
              ]}
              placeholder="Ativo"
              onChange={e => setAtivos(e)}
              value={ativos}
              // isLoading={loadingSelect}
            />
          </MDBCol>
        </MDBRow>

        <h3 className="h3-responsive text-center font-weight-bolder">
          Listagem de Veículos
        </h3>
        <MDBRow className="m-3 d-none d-md-flex">
          <div className="col-12 col-md-2">Status</div>
          <div className="col-12 col-md-2">Placa</div>
          <div className="col-12 col-md-2">Modelo</div>
          <div className="col-12 col-md-2">Unidade</div>
          <div className="col-12 col-md-2">Ultimo Usuário</div>
        </MDBRow>
        {dataVtrs.map(vtr => (
          <MDBRow
            style={{ borderRadius: '5px' }}
            className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3  bg-white"
          >
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              {/* {console.log(
                vtr?.status_veiculos[0].tipos_status_veiculo.utilizavel
              )} */}

              {/* {vtr?.manutencoes[0]?.data_executado === undefined ||
                vtr?.manutencoes[0]?.data_executado === null ||
                (vtr?.status_veiculos[0]?.tipos_status_veiculo.utilizavel ===
                  false || vtr.ativo === false ? (
                  <MDBIcon
                    className="text-danger"
                    style={{ fontSize: '21px' }}
                    icon="window-close"
                  />
                ) : (
                  <MDBIcon
                    className="text-success"
                    style={{ fontSize: '21px' }}
                    icon="check-circle"
                  />
                ))} */}

              <span className="d-sm-inline d-md-none mr-3">Status:</span>
              {verificaVtrAtiva(vtr)}
              {/* <span
                className={`${
                  vtr.ativo ? 'text-success' : 'text-danger'
                } font-weight-bold`}
              >
                {vtr.ativo ? (
                  <MDBIcon style={{ fontSize: '21px' }} icon="check-circle" />
                ) : (
                  <MDBIcon style={{ fontSize: '21px' }} icon="window-close" />
                )}
              </span> */}
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Placa:</span>
              <span>{vtr.placa}</span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Modelo:</span>
              <span>{vtr.modelo}</span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Unidade:</span>
              <span>{vtr?.veiculos_gerencia[0]?.gerencia?.nome}</span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">
                Ultimo Usuário:
              </span>
              <span>{vtr?.bdts[0]?.usuario?.nome_social}</span>
            </MDBCol>

            <MDBCol className="col-12 col-md-2 d-flex justify-content-end font-weight-bolder">
              <Link to={`/visualiza-vtr/${vtr.id}`} className="danger-text">
                <MDBIcon className="mr-1 " far icon="fas fa-eye" size="1x" />

                <span>Ver</span>
              </Link>
            </MDBCol>
          </MDBRow>
        ))}
      </MDBContainer>
    </NavBar>
  );
}
