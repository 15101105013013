import api from '../../services/api';
import { toast } from 'react-toastify';

export const VerificaAcesso = async path => {
  var splits = path.split('/');
  const response = await api.get(`/verifica-acesso`, {
    params: { path: `/${splits[1]}` },
  });
  if (response.data === null) {
    toast.error('Acesso Negado, Falha reportada ao administrador!');
    return false;
  }

  return true;
};
