import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { addDays, parseISO, isBefore, subYears } from 'date-fns';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBTooltip,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { getData, permissaoDeRota } from '../../../services/auth';

import { format, formatDistanceStrict, differenceInDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { toast } from 'react-toastify';

// import { Container } from './styles';

export default function Ferias() {
  let history = useHistory();

  // TIPOS ALERTAS PARA SELECT
  const listaTipoAlertas = useRef([]);

  // VALOR DO SELECT SELECIONADO
  const [tipoAlertaSelecionado, setTipoAlertaSelecionado] = useState(null);

  // LISTAGEM DE ALERTAS
  const [listaAlertas, setListaAlertas] = useState([]);
  const [loading, setLoading] = useState(false);

  // CARREGA TIPOS DE ALERTA
  const buscaTiposAlerta = async () => {
    // buscar funcionalidades no token, nao dá , precisa mexer
    try {
      const params = {
        // tipo_alertas: ativoSelected?.value,
      };
      const result = await api.get('/tipo-alerta', { params });

      listaTipoAlertas.current = result.data.rows;
    } catch (error) {
      toast.error('Erro ao buscar alertas');
    }
  };

  // LISTAGEM DE ALERTAS PARA TELA
  const buscaAlertas = async () => {
    try {
      setLoading(true);
      const params = {
        // tipo_alertas: ativoSelected?.value,
      };
      const result = await api.get('/alertas', { params });

      setListaAlertas(result.data.rows);
    } catch (error) {
      toast.error('Erro ao buscar alertas');
    }
    setLoading(false);
  };

  useEffect(() => {
    buscaTiposAlerta();
    buscaAlertas();
  }, []);

  return (
    <NavBar>
      <MDBContainer fluid>
        <MDBRow center className="h4">
          Meus Alertas
        </MDBRow>

        <MDBCard>
          <MDBCardBody>
            <MDBRow center className="text-danger h4-responsive">
              Atenção, os alertas somente serão excluidos quando o sistema
              detectar que o erro foi sanado.
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" md="4">
                <small className="text-muted">Filtrar tipo de alerta</small>
                <Select
                  placeholder="Tipo de Alerta"
                  isClearable
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  // onChange={e => setAtivoSelected(e)}
                  // value={ativoSelected || null}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-3 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="4">
            Funcionalidade:
          </MDBCol>

          <MDBCol size="12" md="4">
            link
          </MDBCol>
          <MDBCol size="12" md="4">
            Criado Em:
          </MDBCol>
        </MDBRow>
        {listaAlertas.length > 0 &&
          listaAlertas.map(alerta => (
            <>
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                  <span className="font-weight-bolder">
                    {/* {usuario.ativo === false && (
                      <MDBIcon
                        fas
                        icon="exclamation-triangle"
                        className="mr-2 text-danger"
                      />
                    )}
                    {usuario.nome_social} */}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Lotação:</span>
                  {/* <span>{usuario?.lotacao?.gerencia?.nome}</span> */}
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Adido:</span>
                  {/* {usuario.adido === true ? 'Adido' : ''} */}
                </MDBCol>
                <MDBCol size="12" md="3" className="mb-md-0 ">
                  <MDBRow>
                    <span className="d-sm-inline d-md-none mr-3">Período:</span>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </>
          ))}
      </MDBContainer>
    </NavBar>
  );
}
