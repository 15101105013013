import React from 'react';
import { useState, useEffect } from 'react';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import api from '../../../../services/api';
export default function Veiculos(props) {
  // ARRAY DE VEICULOS ABORDADOS
  const [veiculos, setVeiculos] = useState([]);

  // MODAL PARA ADICIONAR NOVO VEICULO
  const [modalVeiculo, setModalVeiculo] = useState(false);
  // DELETAR VEICULOS
  const [modalDeleteVeiculo, setModalDeleteVeiculos] = useState(false);
  const [idDelecaoVeiculo, setIdDelecaoVeiculo] = useState();

  // SWITCH TRUE OU FALSE PARA PLACA OU CHASSI - ONDE FALSE = PLACA E TRUE = CHASSI
  const [switchChassi, setSwitchChassi] = useState(false);

  // VALOR DO CAMPO DA PLACA OU CHASSI
  const [
    abordagemNumeroPlacaOuChassi,
    setAbordagemNumeroPlacaOuChassi,
  ] = useState('');

  // VALOR DO SELECT DE TIPO
  const [abordagemTipo, setAbordagemTipo] = useState('');

  // VALOR DO LABEL DO SELECT , PARA MOSTRAR NA LISTA
  const [abordagemTipoLabel, setAbordagemTipoLabel] = useState('');

  // SELECT DE TIPO DE VEICULOS
  const [selectVeiculos, setSelectVeiculos] = useState([]);

  // CHECKBOX VEICULO ESTRANGEIRO
  const [veiculoEstrangeiro, setVeiculoEstrangeiro] = useState(false);

  // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  const listaTiposVeiculos = async () => {
    const lista = await api.get('/tipo-veiculos');
    const select = [];
    lista.data.forEach(t => {
      select.push({
        label: t.nome,
        value: t.id,
      });
    });
    setSelectVeiculos(select);
  };

  const veiculosAbordados = async () => {
    setLoadingListar(true);
    const veiAbordados = await api.get(
      `/veiculos-abordagem/${props.abordagemId}`
    );

    setVeiculos(veiAbordados.data);
    setLoadingListar(false);
  };

  // FUNCOES DE VEICULOS
  const adicionaVeiculo = async () => {
    if (abordagemNumeroPlacaOuChassi === '') {
      toast.error(
        `Preencha o campo ${switchChassi === false ? 'Placa' : 'Chassi'}`
      );
      return;
    }
    // console.log('teste veiculo');
    if (veiculoEstrangeiro === false) {
      if (switchChassi === false && abordagemNumeroPlacaOuChassi.length !== 7) {
        toast.error(`Placa deve conter 7 caracteres`);
        return;
      }
    }

    if (switchChassi === false && veiculoEstrangeiro === false) {
      const regex = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';

      if (abordagemNumeroPlacaOuChassi.match(regex) === null) {
        toast.error('Placa Inválida');
        return;
      }
    }

    if (switchChassi === true && abordagemNumeroPlacaOuChassi.length !== 17) {
      toast.error(`Chassi deve conter 17 caracteres`);
      return;
    }
    if (abordagemTipo === '') {
      toast.error(`Preencha o campo Tipo`);
      return;
    }
    const newVeiculo = {};
    if (switchChassi === false) {
      newVeiculo.placa = abordagemNumeroPlacaOuChassi;
    } else {
      newVeiculo.chassi = abordagemNumeroPlacaOuChassi;
    }
    newVeiculo.abordagem_id = props.abordagemId;
    newVeiculo.tipo_veiculo_id = abordagemTipo;
    setLoadingEnviar(true);
    const veiculo = await api.post('/veiculo-abordagem', newVeiculo);
    veiculosAbordados();

    setSwitchChassi(false);
    setAbordagemNumeroPlacaOuChassi('');
    setAbordagemTipo('');
    setAbordagemTipoLabel('');
    setModalVeiculo(!modalVeiculo);
    setLoadingEnviar(false);
  };

  const deletarVeiculo = async idVeiculo => {
    setLoadingDeletar(true);
    const veiAbordados = await api.delete(`/veiculo-abordagem/${idVeiculo}`);
    veiculosAbordados();
    setLoadingDeletar(false);
  };

  useEffect(() => {
    veiculosAbordados();
    listaTiposVeiculos();
  }, [props.abordagemId]);

  return (
    <>
      {loadingListar === true ? (
        <div className="d-flex justify-content-center align-items-center">
          <MDBSpinner />
          <div className="ml-3 d-flex align-items-center font-weight-bolder">
            Carregando
          </div>
        </div>
      ) : (
        veiculos.map(veiculo => (
          <>
            <MDBRow
              key={veiculo.id}
              className="mt-1 p-2"
              style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
            >
              <MDBCol
                size="12"
                md="4"
              >{`Tipo: ${veiculo.tipo_veiculo.nome}`}</MDBCol>
              {veiculo.placa !== null ? (
                <MDBCol size="12" md="4">{`Placa: ${veiculo.placa}`}</MDBCol>
              ) : (
                <MDBCol size="12" md="4">{`Chassi: ${veiculo.chassi}`}</MDBCol>
              )}
              <MDBCol
                size="12"
                md="4"
                className="d-flex  justify-content-center justify-content-md-end "
              >
                <div
                  style={{ cursor: 'pointer' }}
                  className="danger-text d-print-none"
                  onClick={() => {
                    setModalDeleteVeiculos(true);

                    setIdDelecaoVeiculo(veiculo.id);
                    // deletarVeiculo(index);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </div>
              </MDBCol>
            </MDBRow>
          </>
        ))
      )}
      {loadingListar === false && veiculos.length === 0 ? (
        <MDBRow center>Sem Veículos</MDBRow>
      ) : (
        ''
      )}

      <br />
      <MDBRow center>
        <MDBBtn
          color="mdb-color"
          className="d-print-none"
          onClick={() => {
            setModalVeiculo(!modalVeiculo);
          }}
        >
          Adicionar Veículo
        </MDBBtn>
      </MDBRow>

      {/* MODAL PARA CRIACAO DE VEICULOS */}
      <MDBModal
        isOpen={modalVeiculo}
        toggle={() => {
          setModalVeiculo(!modalVeiculo);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalVeiculo(!modalVeiculo);
          }}
        >
          Inserir Veículo Abordado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <MDBSwitch
              checked={switchChassi}
              labelLeft="Placa"
              labelRight="Chassi"
              onChange={() => {
                setSwitchChassi(!switchChassi);
                setAbordagemNumeroPlacaOuChassi('');
              }}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" middle>
              <MDBInput
                maxLength={
                  veiculoEstrangeiro === true
                    ? '10'
                    : switchChassi === false
                    ? '7'
                    : '17'
                }
                type="text"
                placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                onChange={e => {
                  setAbordagemNumeroPlacaOuChassi(e.target.value.toUpperCase());
                }}
                value={abordagemNumeroPlacaOuChassi}
              />
            </MDBCol>
            <MDBCol size="12" middle className="d-flex justify-content-center">
              <MDBInput
                containerClass="form-check checkbox-teal"
                labelClass="form-check-label"
                label="Veículo Estrangeiro"
                type="checkbox"
                id="veiculo"
                checked={veiculoEstrangeiro}
                onClick={e => {
                  setVeiculoEstrangeiro(!veiculoEstrangeiro);
                }}
              />
            </MDBCol>

            <MDBCol size="12" middle>
              <div>Tipo de Veículo:</div>
              <Select
                placeholder="Tipo"
                options={selectVeiculos}
                onChange={e => {
                  setAbordagemTipo(e.value);
                  setAbordagemTipoLabel(e.label);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalVeiculo(!modalVeiculo);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaVeiculo();
            }}
            disabled={loadingEnviar}
            color="success"
          >
            {loadingEnviar ? 'Carregando...' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR VEICULOS */}
      <MDBModal
        isOpen={modalDeleteVeiculo}
        toggle={() => {
          setModalDeleteVeiculos({
            modalDeleteVeiculo: !modalDeleteVeiculo,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteVeiculos(!modalDeleteVeiculo);
          }}
        >
          Deletar Veículo Abordado
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse veículo?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteVeiculos(!modalDeleteVeiculo);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarVeiculo(idDelecaoVeiculo);
              setModalDeleteVeiculos(!modalDeleteVeiculo);
            }}
            disabled={loadingDeletar}
            color="warning"
          >
            {loadingDeletar ? 'Carregando...' : 'Deletar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
