import React, { useCallback, useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { addDays, parseISO, isBefore, subYears } from 'date-fns';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBTooltip,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from 'mdbreact';
import NavBar from '../../components/NavBar';
import api from '../../services/api';
import { getData, permissaoDeRota } from '../../services/auth';

import { format, formatDistanceStrict, differenceInDays } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import Loading from '../../components/Loading';
import { toast } from 'react-toastify';

// import { Container } from './styles';

export default function Ferias() {
  let history = useHistory();

  // CARREGA LISTAS
  const gerencias = useRef([]);
  const orgaoList = useRef([]);
  const motivoAlteracao = useRef([]);
  const feriasSecc = useRef([]);

  //
  //
  // INSERÇÃO DE FERIAS
  //
  //

  // CAMPOS PARA INSERIR FERIAS
  const [insereAnoSelecionado, setInsereAnoSelecionado] = useState(null);
  const [insereInicioFerias, setInsereInicioFerias] = useState('');
  const [insereFimFerias, setInsereFimFerias] = useState('');
  const [marcarFeriasID, setMarcarFeriasID] = useState(null);
  const [qtdDiasParaTirar, setqtdDiasParaTirar] = useState(null);

  //
  //
  // FIM DA INSERÇÃO DE FERIAS
  //
  //

  //
  // modificar para baixo
  const [loading, setLoading] = useState(false);

  const [modalEdit, setModalEdit] = useState(false);
  const [qtdTotal, setQtdTotal] = useState(0);
  const [page, setPage] = useState('1');
  // const [gerencias, setGerencias] = useState([]);
  const [gerenciasSelecionada, setGerenciasSelecionada] = useState(null);
  const [anoReferencia, setAnoReferencia] = useState([]);
  const [
    filtroAnoReferenciaSelecionado,
    setFiltroAnoReferenciaSelecionado,
  ] = useState(null);

  const [statusSelected, setStatusSelected] = useState(null);

  // const [orgaoList, setOrgaoList] = useState([]);
  const [orgaoListSelected, setOrgaoListSelected] = useState(null);

  const [listaFerias, setListaFerias] = useState([]);

  const [marcarFeriasAnoLista, setMarcarFeriasAnoLista] = useState([]);

  const [ativoSelected, setAtivoSelected] = useState({
    label: 'Sim',
    value: true,
  });

  const [marcarFeriasUsuario, setMarcarFeriasUsuario] = useState([]);

  const [collapseMotivoDeleteID, setCollapseMotivoDeleteID] = useState(null);
  const [motivoDeleteID, setMotivoDeleteID] = useState(null);

  const [loadingCard, setLoadindCard] = useState(false);

  // FILTROS -- LISTA DE GERENCIAS
  const carregaGerencias = async () => {
    const response = await api.get('/gerencia', {
      params: {
        estrutura_logica: getData().gerencia,
      },
    });
    let dados = response.data.map(row => ({
      value: row.id,
      logica: row.estrutura_logica,
      label: row.nome,
    }));
    gerencias.current = dados;
  };

  // FILTROS -- BUSCA OS ORGAOS
  // const buscaOrgaos = async () => {
  //   const result = await api.get('/orgao');
  //   const select = [{ label: 'Não', value: false }];

  //   result.data.forEach(res => {
  //     select.push({ label: res.nome, value: res.id });
  //   });
  //   orgaoList.current = select;
  // };

  // DELETE -- LISTA DE MOTIVO ALTERAÇÃO PARA DELETAR A FERIAS CRIADA
  const buscaMotivoAlteracao = async () => {
    const select = [];
    const params = {};
    const result = await api.get('/ferias-motivo', params);
    result.data.forEach(res =>
      select.push({ label: res.motivo, value: res.id })
    );
    motivoAlteracao.current = select;
  };

  // FERIAS ATUAIS - MODEL CARREGA AS FERIAS ATUAIS DO USUARIO, EM FERIAS SECC
  const feriasSeccSelect = async () => {
    const result = await api.get('/ferias-secc');

    const select = [];
    result.data.forEach(res => {
      select.push({
        label: res.ano_referencia,
        value: res.ano_referencia,
        tabela: res.tabela,
      });
    });
    feriasSecc.current = select;

    setFiltroAnoReferenciaSelecionado(
      feriasSecc.current[feriasSecc.current.length - 1]
    );
  };

  // INSERE -- DIAS DE FERIAS NO BANCO DE DADOS
  const insereDiasFerias = async () => {
    if (insereAnoSelecionado === null) {
      toast.error('Selecione o Ano');
      return;
    }
    if (insereInicioFerias === '') {
      toast.error('Selecione o data inicio');
      return;
    }
    if (insereFimFerias === '') {
      toast.error('Selecione a data fim');
      return;
    }
    if (insereInicioFerias > insereFimFerias) {
      toast.error('Data fim menor que data início');
      return;
    }
    if (
      differenceInDays(
        new Date(insereFimFerias),
        new Date(insereInicioFerias)
      ) === 0
    ) {
      toast.error('Zero dias inválido');
      return;
    }
    if (
      qtdDiasParaTirar <
      differenceInDays(new Date(insereFimFerias), new Date(insereInicioFerias))
    ) {
      toast.error('Data superior a quantidade máxima');
      return;
    }

    api
      .post('/ferias', {
        ano: insereAnoSelecionado,
        qtd_dias: differenceInDays(
          new Date(insereFimFerias),
          new Date(insereInicioFerias)
        ), // criar funcao pra quando for selecionado
        data_inicio: insereInicioFerias,
        data_fim: insereFimFerias,
        usuario: marcarFeriasID.id,
      })
      .then(res => {
        setInsereAnoSelecionado(null);
        buscaAnosFeriasUsuarios(marcarFeriasID);
        // buscaAnosFeriasUsuarios(marcarFeriasID);
        // feriasAnoUsuario(insereAnoSelecionado);
        // feriasAnoUsuario(insereAnoSelecionado);
        buscaFerias();
      });

    // setInsereAnoSelecionado(null);

    setInsereInicioFerias('');
    setInsereFimFerias('');
  };

  const setToMidnight = date => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };
  // BUSCA FERIAS PARA USUARIO DENTRO DO MODAL
  const buscaAnosFeriasUsuarios = async usuario => {
    if (!usuario) {
      return;
    }

    setqtdDiasParaTirar(null);
    setMarcarFeriasUsuario([]);

    const result = await api.get(`/usuario-anos-ferias/${usuario.id}`, {
      params: {
        adido: usuario.adido,
        // , orgao_id: usuario.adidos[0].orgao_id
      },
    });

    const selectAnos = [];
    result.data.feriasAntigas.forEach(ferias =>
      selectAnos.push({
        label: ferias.ano_referencia,
        value: `antigas${ferias.id}`,
        id: `${ferias.id}`,
        tabela: 'antigas',
        dias: ferias.dias - ferias.total_qtd_dias,
      })
    );

    result.data.feriasAtuais.forEach(ferias => {
      selectAnos.push({
        label: ferias.ano_referencia,
        value: `seccFerias${ferias.id}`,
        id: `${ferias.id}`,
        tabela: 'secc',
        dias: 30 - ferias.total_qtd_dias,
      });
    });
    setMarcarFeriasAnoLista(selectAnos);
  };

  // TRAZ LISTAGEM DE FERIAS NA TELA
  const buscaFerias = async () => {
    if (filtroAnoReferenciaSelecionado === undefined) {
      return;
    }
    setLoading(true);
    const params = {
      ativo: ativoSelected?.value,
      ano: filtroAnoReferenciaSelecionado?.value,
      tabela: filtroAnoReferenciaSelecionado?.tabela,
      status: statusSelected?.value || null,
      estrutura_logica:
        (gerenciasSelecionada && gerenciasSelecionada.logica) || null,
    };

    // if (orgaoListSelected !== undefined && orgaoListSelected !== null) {
    //   params.orgao = orgaoListSelected.value;
    // }

    const result = await api.get('/ferias', { params });

    setListaFerias(result.data.rows);
    setLoading(false);
  };

  // QUANDO ANO É SELECIONADO FAZ A BUSCA
  const feriasAnoUsuario = async (ano, usuario) => {
    if (ano === null || ano === undefined) {
      return;
    }
    const result = await api.get(`/ferias-usuario/${usuario}`, {
      params: {
        id: ano.id,
        tabela: ano.tabela,
      },
    });

    buscaAnosFeriasUsuarios(marcarFeriasID);
    setqtdDiasParaTirar(ano.dias);
    setMarcarFeriasUsuario(result.data);
  };
  // EDIÇÃO DE FERIAS
  const deletaFerias = async (idFerias, motivoId) => {
    try {
      const result = await api.put(`/ferias/${idFerias}`, {
        deleted_at: new Date(),
        motivo_alteracao_id: motivoId.value,
      });

      if (result.data.erro) {
        toast.error(result.data.erro);
        return;
      }

      setInsereAnoSelecionado(null);
      // setInsereAnoSelecionado(insereAnoSelecionado);

      buscaAnosFeriasUsuarios(marcarFeriasID);
      // setMarcarFeriasUsuario([]);
      // feriasAnoUsuario(insereAnoSelecionado);
      // buscaAnosFeriasUsuarios(marcarFeriasID);

      toast.success(result.data.ok);
      buscaFerias();
      // setqtdDiasParaTirar(null);
      // feriasAnoUsuario(insereAnoSelecionado);
    } catch (error) {
      console.log(error);
    }
  };
  // EDIÇÃO DE FERIAS
  const alteraFerias = async (idFerias, edicao) => {
    let feriasIds = [];

    if (Array.isArray(idFerias)) {
      feriasIds = idFerias.map(tes => tes.id);
    } else if (typeof idFerias === 'number') {
      feriasIds.push(idFerias);
    } else {
      console.error(
        'idFerias deve ser um número ou um array de números:',
        idFerias
      );
      return;
    }

    const alteracao = {
      ids: feriasIds,
    };
    if (edicao.data_coordenador) {
      alteracao.data_coordenador = edicao.data_coordenador;
    }

    if (edicao.motivo_alteracao_id) {
      alteracao.motivo_alteracao_id = edicao.motivo_alteracao_id;
    }
    try {
      const result = await api.put(`/ferias-confirma`, alteracao);

      if (result.data.erro) {
        toast.error(result.data.erro);
        return;
      }

      setInsereAnoSelecionado(null);
      setInsereAnoSelecionado(insereAnoSelecionado);

      buscaAnosFeriasUsuarios(marcarFeriasID);
      toast.success(result.data.ok);
      buscaFerias();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // listarVagas();
    feriasSeccSelect();
    carregaGerencias();
    // buscaOrgaos();
    buscaMotivoAlteracao();
  }, []);

  useEffect(() => {
    if (filtroAnoReferenciaSelecionado === null) {
      return;
    }
    buscaFerias();
  }, [
    gerenciasSelecionada,
    statusSelected,
    ativoSelected,
    orgaoListSelected,
    filtroAnoReferenciaSelecionado,
  ]);

  useEffect(() => {
    if (insereAnoSelecionado !== null && marcarFeriasID !== null) {
      feriasAnoUsuario(insereAnoSelecionado, marcarFeriasID.id);
    } else {
      setqtdDiasParaTirar(null);
    }
  }, [insereAnoSelecionado]);

  useEffect(() => {
    // ver se é adido
    // ver se tem ferias antigas que não foram tiradas
    // buscar na tabela ferias ano para saber se tem ferias para ainstituicao
    // se tiver descontar dias pq nao pode tirar mais de 30 dias por ano
    // ver se tem ferias marcadas na secc
    // se tiver descontar dias pq nao pode tirar mais de 30 dias por ano

    if (marcarFeriasID !== null) {
      buscaAnosFeriasUsuarios(marcarFeriasID);
    } else {
      setMarcarFeriasUsuario([]);
    }
  }, [marcarFeriasID]);

  return (
    <NavBar>
      <MDBContainer fluid>
        <MDBRow center className="h4">
          Gestão de Férias
        </MDBRow>

        <MDBCard>
          <MDBCardBody>
            <MDBRow>
              {/* <MDBCol size="12" md="2">
                {statusSelected !== null ? (
                  <small className="text-muted">Status</small>
                ) : (
                  <br />
                )}

                <Select
                  onChange={e => setStatusSelected(e)}
                  value={statusSelected}
                  placeholder="Status"
                  isClearable
                  options={[
                    { label: 'Aberta', value: 'aberta' },
                    { label: 'Prevista', value: 'prevista' },
                    { label: 'Confirmada', value: 'confirmada' },
                  ]}
                />
              </MDBCol> */}
              <MDBCol size="12" md="4">
                <small className="text-muted">Ativo</small>
                <Select
                  placeholder="Ativo"
                  isClearable
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  onChange={e => setAtivoSelected(e)}
                  value={ativoSelected || null}
                />
              </MDBCol>
              {/* <MDBCol size="12" md="3">
                {orgaoListSelected !== null ? (
                  <small className="text-muted">Adido</small>
                ) : (
                  <br />
                )}

                <Select
                  placeholder="Adido"
                  isClearable
                  onChange={e => setOrgaoListSelected(e)}
                  value={orgaoListSelected}
                  options={orgaoList.current}
                />
              </MDBCol> */}
              <MDBCol size="12" md="4">
                {gerenciasSelecionada !== null ? (
                  <small className="text-muted">Gerência</small>
                ) : (
                  <br />
                )}

                <Select
                  onChange={e => setGerenciasSelecionada(e)}
                  placeholder="Gerência"
                  options={gerencias.current}
                  value={gerenciasSelecionada}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                {filtroAnoReferenciaSelecionado !== null ? (
                  <small className="text-muted">Ano</small>
                ) : (
                  <br />
                )}
                <Select
                  placeholder="Ano"
                  options={feriasSecc.current}
                  onChange={e => setFiltroAnoReferenciaSelecionado(e)}
                  value={filtroAnoReferenciaSelecionado}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-3 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="3">
            Nome:
          </MDBCol>
          <MDBCol size="12" md="3">
            Lotação
          </MDBCol>
          <MDBCol size="12" md="1">
            Adido
          </MDBCol>
          <MDBCol size="12" md="3">
            Período:
          </MDBCol>

          <MDBCol size="12" md="2">
            Status:
          </MDBCol>
        </MDBRow>

        {loading === true ? (
          <Loading />
        ) : (
          listaFerias &&
          listaFerias.map(usuario => (
            <>
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                  <span className="font-weight-bolder">
                    {usuario.ativo === false && (
                      <MDBIcon
                        fas
                        icon="exclamation-triangle"
                        className="mr-2 text-danger"
                      />
                    )}
                    {usuario.nome_social}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Lotação:</span>
                  <span>{usuario?.lotacao?.gerencia?.nome}</span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Adido:</span>
                  {usuario.adido === true ? 'Adido' : ''}
                </MDBCol>
                <MDBCol size="12" md="3" className="mb-md-0 ">
                  <MDBRow>
                    <span className="d-sm-inline d-md-none mr-3">Período:</span>

                    {usuario.ativo === true ? (
                      usuario.ferias.length > 0 ? (
                        usuario.ferias.map(tes =>
                          tes.deleted_at === null ? (
                            <MDBCol size="12" md="12">
                              {`${format(
                                parseISO(tes.data_inicio),
                                'dd/MM/yyyy'
                              )} - ${format(
                                parseISO(tes.data_fim),
                                'dd/MM/yyyy'
                              )}`}
                            </MDBCol>
                          ) : (
                            ''
                          )
                        )
                      ) : (
                        ''
                      )
                    ) : (
                      <small className="text-danger center-text font-weight-bold">
                        Exonerado
                      </small>
                    )}
                  </MDBRow>
                </MDBCol>
                <MDBCol size="12" md="2" className="mb-md-0">
                  <span className="d-sm-inline d-md-none mr-3">Status:</span>
                  <MDBRow center>
                    {usuario.ferias.length > 0 ? (
                      usuario.ferias.every(tes => tes.deleted_at !== null) ? (
                        permissaoDeRota('marcador') === true && (
                          <MDBBtn
                            onClick={() => setMarcarFeriasID(usuario)}
                            size="sm"
                          >
                            Marcar Férias
                          </MDBBtn>
                        )
                      ) : usuario.ferias.some(
                          tes =>
                            tes.data_fim !== null &&
                            tes.data_coordenador === null &&
                            tes.deleted_at === null
                        ) ? (
                        <>
                          {permissaoDeRota('marcador') === true && (
                            <MDBBtn
                              onClick={() => setMarcarFeriasID(usuario)}
                              className="w-100"
                              size="sm"
                              color="deep-orange font-weight-bold"
                            >
                              Editar
                            </MDBBtn>
                          )}
                          {usuario.ferias.reduce((total, tes) => {
                            if (tes.deleted_at === null) {
                              const dias = differenceInDays(
                                addDays(new Date(tes.data_fim), 1),
                                new Date(tes.data_inicio)
                              );

                              return total + dias;
                            }
                            return total;
                          }, 0) === (usuario.total_dias_disponiveis || 30) &&
                            (permissaoDeRota('confirmador') === true ? (
                              <MDBBtn
                                onClick={() =>
                                  alteraFerias(usuario.ferias, {
                                    data_coordenador: new Date(),
                                  })
                                }
                                color="elegant font-weight-bold"
                                className="w-100"
                                size="sm"
                              >
                                Confirmar
                              </MDBBtn>
                            ) : (
                              <MDBRow center>
                                <small>Aguardando Confirmação</small>
                              </MDBRow>
                            ))}
                        </>
                      ) : (
                        <MDBRow
                          center
                          className="small font-weight-bold green-text"
                        >
                          <MDBCol middle className="d-flex align-items-center">
                            Confirmado
                            {permissaoDeRota('auditor') === true && (
                              <MDBIcon
                                style={{ fontSize: '18px' }}
                                className="red-text ml-2 cursor-pointer my-auto"
                                onClick={() => setMarcarFeriasID(usuario)}
                                icon="edit"
                              />
                            )}
                          </MDBCol>
                        </MDBRow>
                      )
                    ) : (
                      permissaoDeRota('marcador') === true && (
                        <MDBBtn
                          onClick={() => setMarcarFeriasID(usuario)}
                          size="sm"
                        >
                          Marcar Férias
                        </MDBBtn>
                      )
                    )}
                  </MDBRow>
                </MDBCol>
                <MDBCol size="12" className="small text-danger">
                  {usuario.nomeacaos &&
                    isBefore(
                      new Date(usuario.nomeacaos[0].data),
                      subYears(new Date(), 1)
                    ) === false && (
                      <>
                        Última nomeação:
                        {format(
                          parseISO(usuario.nomeacaos[0].data),
                          ' dd/MM/yyyy'
                        )}
                      </>
                    )}
                </MDBCol>
              </MDBRow>
            </>
          ))
        )}
        {listaFerias.length === 0 && (
          <MDBRow center className="font-weight-bolder p-2 text-danger">
            Sem Registro
          </MDBRow>
        )}

        <MDBRow className="justify-content-center my-4 align-items-center">
          <MDBTooltip domElement tag="span" material placement="top">
            <span>
              <MDBIcon
                className={`mx-3 ${
                  page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                } `}
                // className={`mx-3 grey-text`}
                onClick={e => {
                  if (page == 1) {
                    return;
                  }
                  setPage(Number(1));
                }}
                style={{
                  fontSize: '30px',
                }}
                icon="angle-double-left"
              />
            </span>
            <span>Primeira Página</span>
          </MDBTooltip>
          <span className="grey-text font-weight-bolder">|</span>

          <MDBTooltip domElement tag="span" material placement="top">
            <span>
              <MDBIcon
                className={`mx-3 ${
                  page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                } `}
                onClick={e => {
                  if (page == 1) {
                    return;
                  }
                  setPage(Number(page) - 1);
                }}
                style={{
                  fontSize: '30px',
                }}
                icon="angle-left"
              />
            </span>
            <span>Página Anterior</span>
          </MDBTooltip>
          <div>
            Página {page} de {qtdTotal}
          </div>
          <MDBTooltip domElement tag="span" material placement="top">
            <span>
              <MDBIcon
                className={`mx-3 ${
                  page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                } `}
                onClick={e => {
                  if (page >= qtdTotal) {
                    return;
                  }
                  setPage(Number(page) + 1);
                }}
                style={{
                  fontSize: '30px',
                }}
                icon="angle-right"
              />
            </span>
            <span>Próxima página</span>
          </MDBTooltip>
          <span className="grey-text font-weight-bolder">|</span>
          <MDBTooltip domElement tag="span" material placement="top">
            <span>
              <MDBIcon
                className={`mx-3 ${
                  page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                } `}
                onClick={e => {
                  if (page == qtdTotal) {
                    return;
                  }
                  setPage(Number(qtdTotal));
                }}
                style={{
                  fontSize: '30px',
                }}
                icon="angle-double-right"
              />
            </span>
            <span>Última página</span>
          </MDBTooltip>
        </MDBRow>
        <br />

        <MDBModal
          // backdrop={false}
          // fullHeight
          // frame
          position="right"
          isOpen={modalEdit}
          toggle={() => {
            setModalEdit(!modalEdit);
          }}
          fade={false}
        >
          <MDBModalHeader
            toggle={() => {
              setModalEdit(!modalEdit);
            }}
          >
            Filtros Avançados
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="10"></MDBCol>

              {/* {show17 && ( */}

              {/* )} */}
            </MDBRow>
            <MDBRow className="ml-2 h6-responsive font-weight-bolder mb-2">
              Insira o filtro:
            </MDBRow>
            <MDBRow
              center
              className="ml-2 h6-responsive font-weight-bolder mb-2"
            >
              <MDBCol className="col-2"></MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow between className="w-100">
              <MDBBtn
                color="secondary"
                // onClick={() => {
                //   setModalEdit(!modalEdit);
                // }}
              >
                Sair
              </MDBBtn>
              <MDBBtn
                color="primary"
                // onClick={() => {
                //   setPage(1);
                //   listaAbordagens();
                // }}
              >
                Filtrar
              </MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          // backdrop={false}
          // fullHeight
          // frame
          position="right"
          size="lg"
          isOpen={marcarFeriasID !== null}
          toggle={() => {
            setMarcarFeriasID(null);
          }}
          fade={false}
        >
          <MDBModalHeader
            toggle={() => {
              setInsereAnoSelecionado(null);
              setMarcarFeriasID(null);
              setInsereInicioFerias('');
              setInsereFimFerias('');
            }}
          >
            MARCAÇÃO DE FÉRIAS
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow className="mt-3">
              <MDBCol end size="12" md="3">
                <div>Ano de refência</div>
                <Select
                  placeholder="Ano"
                  options={marcarFeriasAnoLista}
                  onChange={e => setInsereAnoSelecionado(e)}
                  value={insereAnoSelecionado}
                />
              </MDBCol>
              <MDBCol end className="text-center" size="12" md="3">
                <MDBInput
                  type="date"
                  // className="mt-1"
                  onChange={e => {
                    setInsereInicioFerias(e.target.value);
                    setInsereFimFerias('');
                  }}
                  label="Início das Férias"
                  value={insereInicioFerias}
                  // disablePast
                  disabled={insereAnoSelecionado === null}
                  // value={insereInicioFerias}
                  min={format(new Date(), 'yyyy-MM-dd')}
                  // max="2018-12-31"
                  // format="DD-MM-YYYY"
                  // getValue={e => {
                  //   setInsereInicioFerias(
                  //     e
                  //     // formatISO(new Date(e), { representation: 'date' })
                  //   );
                  // }}
                />
              </MDBCol>
              <MDBCol end className="text-center" size="12" md="3">
                <div>Período de</div>
                <Select
                  options={[
                    { label: '10 Dias', value: 10 },
                    { label: '15 Dias', value: 15 },
                    { label: '20 Dias', value: 20 },
                    { label: '30 Dias', value: 30 },
                  ]}
                  isDisabled={insereInicioFerias === ''}
                  onChange={e => {
                    setInsereFimFerias(
                      addDays(new Date(insereInicioFerias), e.value)
                    );
                  }}
                />
                {/* <MDBInput
                  type="date"
                  className="mt-1"
                  // disablePast
                  // minDateMessage="Data Inferior a data inicial"
                  disabled={insereInicioFerias === ''}
                  min={`${insereInicioFerias &&
                    format(
                      addDays(new Date(insereInicioFerias), 2),
                      'yyyy-MM-dd'
                    )}`}
                  max={`${insereInicioFerias &&
                    format(
                      addDays(
                        new Date(insereInicioFerias),
                        insereAnoSelecionado?.dias + 1 || 30
                      ),
                      'yyyy-MM-dd'
                    )}`}
                  // format="DD-MM-YYYY"
                  onChange={e => setInsereFimFerias(e.target.value)}
                  value={insereFimFerias}
                  // getValue={e =>
                  //   setInsereFimFerias(
                  //     e
                  //     // formatISO(new Date(e), { representation: 'date' })
                  //   )
                  // }
                /> */}
              </MDBCol>
              <MDBCol
                size="12"
                md="3"
                middle
                className="text-center font-weight-bold"
              >
                {/* Dia de Regresso
                {console.log(insere)} */}
                {/* {insereFimFerias !== '' && console.log(`${insereFimFerias}`)
                // format(parse(insereFimFerias), 'dd/MM/yyyy')
                }

                {insereFimFerias !== '' &&
                  console.log('new', new Date(insereFimFerias))
                // format(parse(insereFimFerias), 'dd/MM/yyyy')
                }
                // Exibindo a data diretamente como string
                <span>{insereFimFerias !== '' ? insereFimFerias : ''}</span> */}
                {/* {insereFimFerias !== '' &&
                  format(parseISO(insereFimFerias), 'dd/MM/yyyy')} */}
                {/* {insereInicioFerias !== '' &&
                  insereFimFerias !== '' &&
                  formatDistanceStrict(
                    new Date(insereInicioFerias),
                    new Date(insereFimFerias),
                    {
                      locale: ptBR,
                      unit: 'day',
                    }
                  )} */}
                <MDBBtn
                  className="ml-0 ml-md-3"
                  onClick={() => insereDiasFerias()}
                  size="sm"
                >
                  <MDBIcon icon="plus" />
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            {qtdDiasParaTirar !== null ? (
              <MDBRow center className="font-weight-bold">
                Marque até {qtdDiasParaTirar} dias
              </MDBRow>
            ) : (
              ''
            )}
            {/*
            <br />
            {feriasMarcadasUsuarioAno.map(res => (
              <MDBRow className="font-weight-bolder square border m-3 p-2">
                <MDBCol size="9">
                  <MDBRow>

                    <MDBCol>
                      DIAS:{' '}
                      {formatDistanceStrict(
                        new Date(res.data_inicio),
                        new Date(res.data_fim),
                        {
                          locale: ptBR,
                          unit: 'day',
                        }
                      )}
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol>INÍCIO: {res.data_inicio}</MDBCol>
                    <MDBCol>
                      REGRESSO EM:{' '}
                      {format(new Date(res.data_fim), 'dd/MM/yyyy')}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol
                  size="3"
                  middle
                  className="right-text text-danger font-weight-bold"
                >
                  <MDBRow end>


                    <MDBBtn size="sm" color="danger">
                      <MDBIcon className=" mr-2" size="1x" icon="trash" />
                      DELETAR
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            ))} */}
            {marcarFeriasUsuario.length > 0 &&
              marcarFeriasUsuario.map(res => (
                <MDBRow
                  key={res.id}
                  className="font-weight-bolder square border m-3 p-2"
                >
                  <MDBCol size="12" md="9">
                    <MDBRow>
                      {/* <MDBCol>ANO DE REFERÊNCIA: {res.ano.label}</MDBCol> */}
                      <MDBCol>
                        {formatDistanceStrict(
                          new Date(res.data_inicio),
                          addDays(new Date(res.data_fim), 1), // Adiciona um dia à data final
                          {
                            locale: ptBR,
                            unit: 'day',
                          }
                        )}
                      </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow>
                      <MDBCol>
                        INÍCIO:
                        {format(parseISO(res.data_inicio), 'dd/MM/yyyy')}
                      </MDBCol>
                      <MDBCol>
                        REGRESSO EM:{' '}
                        {format(
                          addDays(parseISO(res.data_fim), 1),
                          'dd/MM/yyyy'
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>

                  <MDBCol
                    size="12"
                    md="3"
                    middle
                    className="right-text text-danger font-weight-bold"
                  >
                    <MDBRow end>
                      {/* <MDBBtn size="sm" color="warning">
                      <MDBIcon className=" mr-2" size="1x" icon="edit" />
                      ALTERAR
                    </MDBBtn> */}
                      {motivoDeleteID !== null &&
                      collapseMotivoDeleteID === res.id ? (
                        <MDBCol>
                          <MDBBtn
                            onClick={() => deletaFerias(res.id, motivoDeleteID)}
                            size="sm"
                            color="green"
                          >
                            <MDBIcon fas icon="check" />
                          </MDBBtn>
                          <MDBBtn
                            onClick={() => setMotivoDeleteID(null)}
                            size="sm"
                            outline
                            color="red"
                          >
                            <MDBIcon fas icon="times" />
                          </MDBBtn>
                        </MDBCol>
                      ) : (
                        <MDBCol className="text-center text-md-right">
                          <MDBBtn
                            // onClick={() => alteraFerias(res.id)}
                            onClick={() => {
                              setMotivoDeleteID(null);

                              if (collapseMotivoDeleteID === res.id) {
                                setCollapseMotivoDeleteID(null);
                              } else {
                                setCollapseMotivoDeleteID(res.id);
                              }
                            }}
                            size="sm"
                            color="danger"
                          >
                            <MDBIcon className=" mr-2" size="1x" icon="trash" />
                            DELETAR
                          </MDBBtn>
                        </MDBCol>
                      )}
                    </MDBRow>
                  </MDBCol>
                  <MDBCol size="12">
                    <MDBCollapse
                      className="rgba-red-slight mt-3 p-3 rounded mb-0"
                      isOpen={collapseMotivoDeleteID === res.id}
                    >
                      <MDBRow>
                        <MDBCol middle> Selecione o motivo da deleção </MDBCol>
                        <MDBCol>
                          <Select
                            placeholder="Selecione"
                            onChange={e => setMotivoDeleteID(e)}
                            options={motivoAlteracao.current}
                            value={motivoDeleteID}
                            className="w-100"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCollapse>
                  </MDBCol>
                </MDBRow>
              ))}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow between className="w-100">
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setInsereAnoSelecionado(null);
                  setMarcarFeriasID(null);
                  setInsereInicioFerias('');
                  setInsereFimFerias('');
                  //   setModalEdit(!modalEdit);
                }}
              >
                Sair
              </MDBBtn>
              {/* <MDBBtn
                color="success"
                // onClick={() => {
                //   setPage(1);
                //   listaAbordagens();
                // }}
              >
                Salvar
              </MDBBtn> */}
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
