import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Iframe from 'react-iframe';
import { format, subDays } from 'date-fns';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBView,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSwitch,
  MDBAnimation,
  MDBSelect,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import { validarCNPJ } from '../Components/ValidarCnpj';
import { getPerfil, getData } from '../../../services/auth';
export default function App() {
  let history = useHistory();

  const [btnNewItem, setBtnNewItem] = useState(false);
  const [selectTipoOcorrencia, setSelectTipoOcorrencia] = useState([]);
  const [tipoOcorrenciaSelecionada, setTipoOcorrenciaSelecionada] = useState({
    value: '',
    label: '',
  });
  const [selectgrupo, setSelectgrupo] = useState([]);
  const [grupoSelecionado, setGrupoSelecionado] = useState({
    value: '',
    label: '',
  });

  const [listaItens, setListaItens] = useState([]);

  const [newItem, setNewItem] = useState('');

  const [existeItem, setExisteItem] = useState({ label: 'Sim', value: true });

  const [todosItens, setTodosItens] = useState([]);

  const [unidadesMedidas, setUnidadesMedidas] = useState([]);
  const [unidadesMedidasSelecionada, setUnidadesMedidasSelecionada] = useState(
    ''
  );

  const [itemExistente, setItemExistente] = useState({
    value: '',
    label: '',
  });

  const [loadingNew, setLoadingNew] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');

  const buscaTipoOcorrencia = () => {
    api
      .get('/select/tipo-ocorrencia')
      .then(result => setSelectTipoOcorrencia(result.data));
  };

  const buscaGrupos = tipoOcorrencia => {
    tipoOcorrencia.value !== '' &&
      api
        .get(`/select/grupo-tipo-ocorrencia/${tipoOcorrencia.value}`)
        .then(result => {
          setSelectgrupo(result.data);
        });
  };

  const buscaItens = async grupoId => {
    setLoading(true);
    if (grupoId.value !== '') {
      const result = await api.get(`/itens-por-grupo/${grupoId.value}`, {
        params: { todosItens: true },
      });
      setListaItens(result.data);
    }

    setLoading(false);
  };

  const buscaTodosItens = () => {
    api.get('/select/item-ocorrencia').then(result => {
      setTodosItens(result.data);
    });
  };

  const addNewItem = () => {
    if (tipoOcorrenciaSelecionada.value === '') {
      toast.error('Tipo ocorrência não selecionado');
      return;
    }

    if (grupoSelecionado.value === '') {
      toast.error('Grupo não selecionado');
      return;
    }

    if (existeItem.value === true) {
      if (itemExistente.value === '') {
        toast.error('Selecione Item');
        return;
      }
    } else {
      if (unidadesMedidasSelecionada === '') {
        toast.error('Selecione Unidade de Medida');
        return;
      }
      if (newItem === '') {
        toast.error('Preencha o Item');
        return;
      }
    }

    const query = {};
    query.existe = existeItem.value;
    if (existeItem.value === true) {
      query.item = itemExistente.value;
    } else {
      query.item = newItem;
      query.und_medida = unidadesMedidasSelecionada.value;
    }

    query.grupo = grupoSelecionado.value;

    setLoadingNew(true);
    api
      .post('/add-item', query)
      .then(t => {
        // history.push(`/abordagens`);
        toast.success('Item criado com sucesso');
        buscaItens(grupoSelecionado);
        setLoadingNew(false);
      })
      .catch(t => {
        setLoadingNew(false);
      });
  };

  const unidadeMedidas = async () => {
    const result = await api.get('/select/unidades-medidas');

    setUnidadesMedidas(result.data);
  };

  const alterarItem = async (id, ativo) => {
    const teste = await api.put(`/altera-grupo-item/${id}`, {
      ativo,
    });
    setModalAltera(false);
    if (teste.data.sucesso) {
      toast.success(teste.data.sucesso);
    }
    if (teste.data.error) {
      toast.success(teste.data.error);
    }

    buscaItens(grupoSelecionado);
  };

  useEffect(() => {
    // unidadeMedidas();
    buscaTipoOcorrencia();
    buscaTodosItens();

    // buscaGrupos();
  }, []);

  useEffect(() => {
    buscaGrupos(tipoOcorrenciaSelecionada);
  }, [tipoOcorrenciaSelecionada]);

  useEffect(() => {
    buscaItens(grupoSelecionado);
  }, [grupoSelecionado]);

  useEffect(() => {
    if (existeItem.value === false) {
      unidadeMedidas();
    }
  }, [existeItem]);

  return (
    <NavBar>
      <MDBContainer>
        <MDBRow>
          <MDBCol size="12" md="6">
            Tipo de Ocorrência
            <Select
              options={selectTipoOcorrencia}
              value={tipoOcorrenciaSelecionada}
              onChange={e => {
                setTipoOcorrenciaSelecionada(e);
                setGrupoSelecionado({ label: '', value: '' });
                setListaItens([]);
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="6">
            Grupo de Itens
            <Select
              options={selectgrupo}
              value={grupoSelecionado}
              onChange={e => {
                setGrupoSelecionado(e);
              }}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow
          center
          style={{ borderRadius: '10px 10px 0px 0px' }}
          className={`mt-3 ${btnNewItem ? 'bg-white' : ''}`}
        >
          <MDBCol className="text-center">
            <MDBBtn
              color={btnNewItem ? 'red' : 'mdb-color'}
              onClick={() => {
                setBtnNewItem(!btnNewItem);
              }}
            >
              Insere Item
            </MDBBtn>
          </MDBCol>
        </MDBRow>

        {btnNewItem ? (
          <>
            <MDBRow className="bg-white">
              <MDBCol size="12" md="3">
                Este Item já existe?
              </MDBCol>
              <MDBCol size="12" md="3">
                <Select
                  options={[
                    { label: 'Sim', value: true },
                    { label: 'Não', value: false },
                  ]}
                  value={existeItem}
                  onChange={e => setExisteItem(e)}
                />
              </MDBCol>
            </MDBRow>
            {existeItem.value === true ? (
              <>
                <MDBRow
                  center
                  style={{ borderRadius: '0px 0px 10px 10px' }}
                  className="bg-white pb-4 py-3"
                >
                  <MDBCol middle size="12" md="5">
                    Inserir novo item em grupo
                    <Select
                      options={todosItens}
                      value={itemExistente}
                      onChange={e => {
                        setNewItem('');
                        setItemExistente(e);
                      }}
                    />
                  </MDBCol>
                  <MDBBtn onClick={() => addNewItem()} size="md">
                    {loadingNew === true ? 'Carregando' : 'Cadastrar'}
                  </MDBBtn>
                </MDBRow>
              </>
            ) : (
              <MDBRow
                style={{ borderRadius: '0px 0px 10px 10px' }}
                className="bg-white pb-4 mb-3"
              >
                <MDBCol middle size="12" md="5">
                  <label className="text-weight-bolder" htmlFor="exampleInput">
                    Insira o Novo Item
                  </label>
                  <input
                    type="email"
                    id="exampleInput"
                    className="form-control mb-1"
                    maxLength="50"
                    onChange={e => {
                      setItemExistente({ label: '', value: '' });
                      setNewItem(e.target.value);
                    }}
                  />
                </MDBCol>
                <MDBCol middle size="12" md="5">
                  Unidade de Medida
                  <Select
                    options={unidadesMedidas}
                    value={unidadesMedidasSelecionada}
                    onChange={e => {
                      setUnidadesMedidasSelecionada(e);
                    }}
                  />
                </MDBCol>
                <MDBCol center bottom className="text-center" size="12" md="2">
                  <MDBBtn disabled={loadingNew} onClick={() => addNewItem()}>
                    {loadingNew === true ? (
                      <div
                        className="spinner-border text-warning"
                        role="status"
                      >
                        <span className="sr-only">Carregando</span>
                      </div>
                    ) : (
                      'Cadastrar'
                    )}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            )}
          </>
        ) : (
          ''
        )}

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol>NOME DO ITEM</MDBCol>

          <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white" />
        </MDBRow>
        {loading === true ? (
          <MDBRow center className="d-flex align-items-center mt-3">
            <MDBSpinner crazy />
            <div className="font-weight-bolder ml-3">Carregando</div>
          </MDBRow>
        ) : (
          ''
        )}
        {listaItens.length > 0
          ? listaItens.map(grupo => (
              <MDBRow
                key={grupo.item.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white "
              >
                <MDBCol
                  size="9"
                  className="d-flex justify-content-start  align-items-center"
                >
                  {grupo.item.nome}
                </MDBCol>

                <MDBCol
                  size="3"
                  style={{ cursor: 'Pointer' }}
                  onClick={() => {
                    setModalAltera(true);
                    setItemModal({
                      grupo_nome_id: grupo.id,
                      item_nome: grupo.item.nome,
                      item_id: grupo.item.id,
                      ativo: grupo.ativo,
                    });

                    // setDeletaData(calendario.dt_h_inicio_cal);
                    // setDeleteLotacaoID(calendario.lotacao.id);
                    // modalExcluir();
                  }}
                >
                  {grupo.ativo === true ? (
                    <div className="d-flex justify-content-end align-items-center red-text">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 d-none d-md-block">
                        Desativar
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center success-text">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 d-none d-md-block">
                        Reativar
                      </div>
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
            ))
          : loading === true
          ? ''
          : 'Sem Itens'}
        <br />
        <br />
      </MDBContainer>
      <MDBModal
        isOpen={modalAltera}
        toggle={() => setModalAltera(!modalAltera)}
        size="lg"
      >
        <MDBModalHeader toggle={() => setModalAltera(!modalAltera)}>
          Alteração
        </MDBModalHeader>
        <MDBModalBody>
          {/* <MDBRow className="font-weight-bolder">
            Deseja desativar este item ?
          </MDBRow> */}
          <MDBRow
            className="p-2 mb-1"
            style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
          >
            <MDBCol className="font-weight-bolder" size="12" md="4">
              Tipo de Ocorrência:
            </MDBCol>
            <MDBCol>{tipoOcorrenciaSelecionada.label}</MDBCol>
          </MDBRow>
          <MDBRow
            className="p-2 mb-1"
            style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
          >
            <MDBCol className="font-weight-bolder" size="12" md="4">
              Grupo:
            </MDBCol>
            <MDBCol>{grupoSelecionado.label}</MDBCol>
          </MDBRow>
          <MDBRow
            className="p-2 mb-1"
            style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
          >
            <MDBCol className="font-weight-bolder" size="12" md="4">
              Item:
            </MDBCol>
            <MDBCol>{itemModal.item_nome}</MDBCol>
          </MDBRow>
          <MDBRow
            className="p-2 mb-1"
            style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
          >
            <MDBCol className="font-weight-bolder" size="12" md="4">
              Ativo:
            </MDBCol>
            <MDBCol>
              {itemModal.ativo === true ? (
                <div className="d-flex ">
                  <span className="success-text font-weight-bold">Sim</span>
                  <span className="ml-3">-</span>
                  <div
                    className="danger-text font-weight-bold ml-3 cursor-pointer"
                    onClick={() => {
                      alterarItem(itemModal['grupo_nome_id'], false);
                    }}
                  >
                    Desativar Item
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <span className="danger-text font-weight-bold">Não</span>
                  <span className="ml-3">-</span>
                  <div
                    className="success-text font-weight-bold ml-3 cursor-pointer"
                    onClick={() => {
                      alterarItem(itemModal['grupo_nome_id'], true);
                    }}
                  >
                    Reativar Item
                  </div>
                </div>
              )}
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={() => setModalAltera(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </NavBar>
  );
}
