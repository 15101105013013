import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Loading from '../../../components/Loading';

// import { Container } from './styles';

export default function App() {
  let history = useHistory();
  // const [abordagens, setAbordagens] = useState([]);
  const [loading, setLoading] = useState(false);

  // const [tipoBusca, setTipoBusca] = useState({
  //   label: 'Nº da Vaga',
  //   value: 'numvaga',
  // });

  const [inputValue, setInputValue] = useState('');

  const [descricaoVaga, setDescricaoVaga] = useState({});
  const [listavagas, setListaVagas] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);

  // const [qtdTotal, setQtdTotal] = useState(0);
  // const [page, setPage] = useState('1');

  const listarVagas = async () => {
    // setLoading(true);
    const result = await api.get('/lista-vagas');
    // const result = await api.get('/lista-vagas', {
    //   params: {
    //     tipo: tipoBusca.value,
    //     valor: inputValue,
    //   },
    // });

    setListaVagas(result.data);
  };

  const modalToogle = () => {
    if (modalEdit === true) {
      setModalEdit(false);
    } else {
      setModalEdit(true);
    }
  };

  useEffect(() => {
    listarVagas();
  }, []);

  return (
    <NavBar>
      <MDBContainer className="p-0">
        {/* <MDBRow center>

          <MDBCol size="12" md="4" middle>
            <MDBInput
              value={inputValue}
              label={tipoBusca.label}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="1" middle>
            <MDBBtn
              size="sm"
              onClick={() => {
                minhasAbordagens();
              }}
            >
              Buscar
            </MDBBtn>
          </MDBCol>
        </MDBRow> */}

        {/* {Object.keys(descricaoVaga).length === 0 ? (
          ''
        ) : (
          <MDBCard reverse>
            <MDBCardBody cascade className="text-center">
              <h5 className="indigo-text">
                <strong>DESCRIÇÃO DA VAGA: {descricaoVaga.id}</strong>
              </h5>
              <MDBCardText>
                <MDBRow>
                  <MDBCol>
                    Ativa: {descricaoVaga.ativo === false ? 'Não' : 'Sim'}
                  </MDBCol>
                  <MDBCol>
                    Disponível:
                    {descricaoVaga.disponivel === false ? (
                      <span className="text-danger"> Não</span>
                    ) : (
                      <span className="text-success"> Sim</span>
                    )}
                  </MDBCol>
                  <MDBCol>Cargo: {descricaoVaga?.cargo?.nome}</MDBCol>
                  <MDBCol>Símbolo: {descricaoVaga?.cargo?.simbolo}</MDBCol>
                  <MDBCol>Função: {descricaoVaga?.funcao?.nome}</MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        )} */}

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="1">
            Vaga:
          </MDBCol>
          <MDBCol size="12" md="3">
            Função
          </MDBCol>
          <MDBCol size="12" md="2">
            Cargo
          </MDBCol>
          <MDBCol size="12" md="1">
            Disp:
          </MDBCol>

          <MDBCol size="12" md="4">
            quem:
          </MDBCol>
          <MDBCol size="12" md="1"></MDBCol>
        </MDBRow>
        {loading === true ? (
          <Loading />
        ) : listavagas.length !== 0 ? (
          listavagas.map(vaga => (
            <>
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">
                    Nº da Vaga:
                  </span>
                  <span className="font-weight-bolder">Nº: {vaga.id}</span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Função:</span>
                  <span>{vaga.funcao.nome}</span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="2"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Cargo:</span>
                  <span>
                    {vaga.cargo &&
                      vaga?.cargo?.simbolo + ' - ' + vaga?.cargo?.nome}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Disp:</span>
                  <span>
                    {vaga.disponivel ? (
                      <span className="text-success font-weight-bolder">
                        Sim
                      </span>
                    ) : (
                      <span className="text-danger font-weight-bolder">
                        Não
                      </span>
                    )}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="4"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                  <span>
                    {vaga.vaga_usuarios &&
                      vaga?.vaga_usuarios[0]?.usuario?.nome_social}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-end"
                >
                  {/* <span className="d-sm-inline d-md-none mr-3">icon</span> */}

                  <MDBIcon
                    style={{ cursor: 'Pointer' }}
                    icon="edit"
                    size="1x"
                    onClick={() => modalToogle()}
                  />
                </MDBCol>
                {/* <MDBModal isOpen={modalEdit} toggle={() => modalToogle()}>
                  <MDBModalHeader toggle={() => modalToogle()}>
                    MDBModal title
                  </MDBModalHeader>
                  <MDBModalBody>(...)</MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={() => modalToogle()}>
                      Cancelar
                    </MDBBtn>
                    <MDBBtn color="primary">Salvar</MDBBtn>
                  </MDBModalFooter>
                </MDBModal> */}
                {/* {format(
                    zonedTimeToUtc(
                      new Date(vaga.createdAt),
                      'America/Sao_Paulo'
                    ),
                    'dd/MM/yyyy'
                  )}
               */}
              </MDBRow>
            </>
          ))
        ) : (
          <MDBRow center className="font-weight-bolder p-2 text-danger">
            Sem Registro
          </MDBRow>
        )}
        <br />

        <MDBModal
          // backdrop={false}
          // fullHeight
          // frame
          position="right"
          isOpen={modalEdit}
          toggle={() => {
            setModalEdit(!modalEdit);
          }}
          fade={false}
        >
          <MDBModalHeader
            toggle={() => {
              setModalEdit(!modalEdit);
            }}
          >
            Filtros Avançados
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="10"></MDBCol>

              {/* {show17 && ( */}

              {/* )} */}
            </MDBRow>
            <MDBRow className="ml-2 h6-responsive font-weight-bolder mb-2">
              Insira o filtro:
            </MDBRow>
            <MDBRow
              center
              className="ml-2 h6-responsive font-weight-bolder mb-2"
            >
              <MDBCol className="col-2"></MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow between className="w-100">
              <MDBBtn
                color="secondary"
                // onClick={() => {
                //   setModalEdit(!modalEdit);
                // }}
              >
                Sair
              </MDBBtn>
              <MDBBtn
                color="primary"
                // onClick={() => {
                //   setPage(1);
                //   listaAbordagens();
                // }}
              >
                Filtrar
              </MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
