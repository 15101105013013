import React from 'react';

// import { Container } from './styles';

export default function TransformaMoeda(val) {
  let valor = val;
  valor = valor + '';
  valor = parseInt(valor.replace(/[\D]+/g, ''));
  valor = valor + '';
  valor = valor.replace(/([0-9]{2})$/g, ',$1');
  if (valor == 'NaN') valor = 0;

  return valor;
  // if (valor.length > 6) {
  //   valor = valor.replace(
  //     /([0-9]{3}),([0-9]{2}$)/g,
  //     '.$1,$2'
  //   );
  // }
}
