import React from 'react';
import Routes from './routes';
import GlobalStyle from './styles/global';
import { ToastContainer } from 'react-toastify';

// import { MDBRow } from 'mdbreact';

const App = () => (
  <>
    <Routes />
    <GlobalStyle />
    <ToastContainer autoClose={6000} />
  </>
);

export default App;
