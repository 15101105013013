import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getData } from '../../../services/auth';
import api from '../../../services/api';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBSpinner,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import Paginacao from '../../../components/Paginacao';

function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);
  const [collapseFiltro, setCollapseFiltro] = useState(false);

  const [linhas, setLinhas] = useState('');

  const [search, setSearch] = useState('');
  const [pagina, setPagina] = useState('1');
  const [qtd, setQtd] = useState('20');

  const [orgaos, setOrgaos] = useState([]);
  const [titulos, setTitulos] = useState([]);

  const [adSearch, setAdSearch] = useState({});
  const [gerencias, setGerencias] = useState();
  const [escala, setEscala] = useState([]);
  const [ala, setAla] = useState([]);

  const [loading, setLoading] = useState(false);

  const [dadosCSV, setDadosCSV] = useState([]);

  const [botaoRelatorio, setBotaoRelatorio] = useState(false);

  const prevPage = async () => {
    if (pagina == 1) return;
    const backP = Number(pagina) - 1;
    await setPagina(backP);
    // list(backP);
  };

  const loadPermissao = async () => {
    const botao = await api.get('/verifica-funcao', {
      params: {
        rota: 'sem rota',
        tipo: 'efetivo',
      },
    });

    if (botao.data === null) {
      setBotaoRelatorio(false);
    } else {
      setBotaoRelatorio(true);
    }
  };

  const nextPage = async () => {
    if (Number(pagina) + 1 > Math.ceil(Number(linhas) / Number(qtd))) return;
    const nextP = Number(pagina) + 1;
    await setPagina(nextP);
    // list(nextP);
  };

  const carregaOrgaos = async () => {
    const response = await api.get('/orgao', {});
    let dados = [];
    response.data.map(row => {
      dados.push({ value: row.id, label: row.nome });
    });

    setOrgaos(dados);
  };

  const exportCSV = user => {
    const dados = [];

    user.map(usuario => {
      dados.push({
        nome: `${usuario.nome} ${usuario.sobrenome}`,
        cpf: '=""' + usuario.cpf + '""',
        rg: '=""' + usuario.rg + '""',
        id_funcional: '=""' + usuario.id_funcional + '""',
        telefone: usuario.telefone,
        email: usuario.email,
        cnh: '=""' + usuario.cnh + '""',
        ativo: usuario.ativo === true ? 'Nomeado' : 'Exonerado',
        cnh_categoria: usuario.cnh_categoria,
        cnh_validade: usuario.cnh_validade === null ? '' : usuario.cnh_validade,
        // : format(new Date(usuario.cnh_validade), 'dd/MM/yyyy'), // dia / mes / ano
        posto: usuario.lotacao.gerencia.sigla,
        ala: usuario.lotacao.ala.nome,
        adido:
          usuario.adidos.length === 0
            ? 'Agente Civil'
            : usuario?.adidos[0]?.orgao?.sigla,
        titulo:
          usuario.adidos.length === 0
            ? 'Agente Civil'
            : usuario?.adidos[0]?.titulo?.nome,
      });
    });
    return dados;
    // setDadosCSV(dados);
  };

  const carregaTitulos = async idOrgao => {
    const response = await api.get('/titulo', {
      params: {
        orgao_id: idOrgao,
      },
    });
    let dados = [];
    response.data.map(row => {
      dados.push({ value: row.id, label: row.nome });
    });
    setTitulos(dados);

    // setLinhas(response.data.count);
  };

  const carregaGerencias = async () => {
    const response = await api.get('/gerencia', {
      params: {
        estrutura_logica: getData().gerencia,
      },
    });
    let dados = [];
    response.data.map(row => {
      dados.push({
        value: row.id,
        logica: row.estrutura_logica,
        label: row.nome,
      });
    });

    setGerencias(dados);
  };

  const carregaEscalas = async () => {
    const response = await api.get('/filtra-escalas', {
      params: {
        gerencia_id: adSearch.gerencia_id,
      },
    });
    let dados = [];
    response.data.map(row => {
      dados.push({ value: row.id, label: row.nome });
    });

    setEscala(dados);
  };

  const carregaAlas = async escala => {
    const response = await api.get('/filtra-alas', {
      params: {
        gerencia_id: adSearch.gerencia_id,
        escala: escala,
      },
    });
    let dados = [];
    response.data.map(row => {
      dados.push({ value: row.id, label: row.nome });
    });
    setAla(dados);
  };

  const listar = async () => {
    setLoading(true);
    const response = await api.get('/usuario', {
      params: {
        // pagina: pagina,
        // qtd: qtd,
        search: search,
        // ...dadosModal,
        // adSearch,
        // sort,
        // typeSort,
      },
    });

    setUsuarios(response.data.rows);
    setLinhas(response.data.count);
    setLoading(false);
    setDadosCSV([]);
    exportCSV(response.data.rows);
  };

  const listarFiltros = async () => {
    const response = await api.get('/usuario-filtro', {
      params: {
        ...adSearch,
        // sort,
        // typeSort,
      },
      // order: [['nome']],
    });
    setDadosCSV([]);
    setUsuarios(response.data.rows);
    setLinhas(response.data.count);
    exportCSV(response.data.rows);
  };
  // useEffect(() => {
  //   listar();
  //   carregaGerencias();
  // }, [pagina, qtd]);
  useEffect(() => {
    carregaGerencias();
    loadPermissao();
  }, []);

  useEffect(() => {
    carregaEscalas();
  }, [adSearch.gerencia_id]);

  // useEffect(() => {
  //   if (search.length > 2 || search.length == 0) {
  //     listar();
  //     return;
  //   }
  // }, [search]);
  useEffect(() => {
    // Se o campo de pesquisa estiver vazio, listar imediatamente
    if (search.length === 0) {
      listar();
      return;
    }

    // Se houver texto, aguardar 3 segundos antes de listar

    const delayDebounceFn = setTimeout(() => {
      listar();
    }, 2000);

    // Limpa o timeout se o componente for desmontado ou se o 'search' mudar novamente
    return () => clearTimeout(delayDebounceFn);
  }, [search]); // Executa novamente se 'search' mudar

  // useEffect(() => {
  //   // Cancela a busca anterior se o usuário continuar digitando
  //   const delayDebounceFn = setTimeout(() => {
  //     if (search.length > 2 || search.length === 0) {
  //       listar();
  //     }
  //   }, 3000); // Espera 3 segundos antes de executar

  //   return () => clearTimeout(delayDebounceFn); // Limpa o timeout se o componente for desmontado ou se o 'search' mudar novamente
  // }, [search]); // Executa novamente se 'search' mudar

  return (
    <>
      <NavBar>
        <MDBContainer fluid>
          <MDBRow
            style={{ borderRadius: '5px' }}
            className="flex-column-reverse flex-md-row justify-content-center align-items-center text-center"
          >
            <h3 className="h3-responsive  font-weight-bolder">
              Lista de Usuários
            </h3>
          </MDBRow>
        </MDBContainer>

        <MDBRow between className="md-form">
          <MDBCol col="12" md="3">
            {loading === true ? (
              <div className="d-flex justify-content-center align-items-center">
                <MDBSpinner />
                <div className="ml-3 d-flex align-items-center font-weight-bolder">
                  Carregando
                </div>
              </div>
            ) : (
              ''
            )}
          </MDBCol>

          <MDBCol
            className="d-flex justify-content-around align-items-center"
            col="12"
            md="5"
          >
            <input
              onChange={e => {
                setSearch(e.target.value);
              }}
              className="form-control ml-3 flex-1"
              type="text"
              placeholder="Pesquisar Usuário"
              aria-label="Search"
            />
          </MDBCol>
          <MDBCol
            className="d-flex align-items-center justify-content-center justify-content-md-start"
            col="3"
            md="4"
          >
            <MDBBtn
              color="mdb-color"
              onClick={() => setCollapseFiltro(!collapseFiltro)}
              className=" d-flex justify-content-around align-items-center"
            >
              <MDBIcon icon="filter" size="1x" />
            </MDBBtn>

            {botaoRelatorio === false ? (
              ''
            ) : (
              <>
                <MDBBtn
                  className="btn mdb-color lighten-1 white-text d-flex justify-content-around align-items-center"
                  onClick={() => {
                    api
                      .get('/usuario-relatorio', {
                        params: {
                          ...adSearch,
                        },
                      })
                      .then(async t => {
                        setDadosCSV(exportCSV(usuarios));
                      });
                  }}
                >
                  <MDBIcon fas icon="cloud-download-alt" size="1x" />
                </MDBBtn>

                {dadosCSV.length === 0 ? (
                  ''
                ) : (
                  <CSVLink
                    className="ml-2 danger-text font-weight-bolder"
                    filename={'usuarios.csv'}
                    data={dadosCSV}
                    separator={';'}
                    // asyncOnClick={true}
                  >
                    usuarios.csv
                  </CSVLink>
                )}
              </>
            )}
          </MDBCol>
        </MDBRow>

        <MDBCollapse id="basicCollapse" isOpen={collapseFiltro}>
          <MDBRow className="rounded-top p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white">
            <MDBCol md="3" col="12">
              <Select
                className="text-left mb-2"
                placeholder="Gênero"
                options={[
                  { value: 'M', label: 'Masculino' },
                  { value: 'F', label: 'Feminino' },
                ]}
                isClearable={true}
                onChange={e => {
                  if (e === null) {
                    const { genero, ...resto } = adSearch;
                    setAdSearch(resto);
                    return;
                  }
                  setAdSearch({
                    ...adSearch,
                    genero: e.value,
                  });
                }}
              />
            </MDBCol>

            <MDBCol md="3" col="12">
              <Select
                className="text-left mb-2"
                placeholder="Ativo"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                isClearable={true}
                onChange={e => {
                  if (e === null) {
                    const { ativo, ...resto } = adSearch;
                    setAdSearch(resto);
                    return;
                  }
                  setAdSearch({
                    ...adSearch,
                    ativo: e.value == null ? '' : e.value,
                  });
                }}
              />
            </MDBCol>

            <MDBCol md="3" col="12">
              <Select
                placeholder="Adido"
                className="text-left mb-2"
                options={[
                  { value: true, label: 'Sim' },
                  { value: false, label: 'Não' },
                ]}
                isClearable={true}
                onChange={e => {
                  if (e === null) {
                    const { adido, ...resto } = adSearch;
                    setAdSearch(resto);

                    return;
                  }
                  carregaOrgaos();
                  setAdSearch({
                    ...adSearch,
                    adido: e.value,
                  });
                }}
              />
              {adSearch.adido === true ? (
                <>
                  <Select
                    placeholder="Instituição"
                    className="text-left mb-2"
                    options={orgaos}
                    isClearable={true}
                    onChange={e => {
                      if (e === null) {
                        const { orgao, ...resto } = adSearch;
                        setAdSearch(resto);
                        return;
                      }
                      carregaTitulos(e.value);
                      setAdSearch({
                        ...adSearch,
                        orgao: e.value,
                      });
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </MDBCol>
            <MDBCol md="3" col="12">
              <Select
                placeholder="Gerência"
                className="text-left mb-2"
                options={gerencias}
                isClearable={true}
                onChange={e => {
                  if (e === null) {
                    const {
                      gerencia_id,
                      gerencia_logica,
                      escala,
                      ala,
                      ...resto
                    } = adSearch;
                    setAdSearch(resto);
                    return;
                  }
                  setAdSearch({
                    ...adSearch,
                    gerencia_id: e.value,
                    gerencia_logica: e.logica,
                  });
                }}
              />

              {adSearch.gerencia_id ? (
                <Select
                  md="3"
                  col="12"
                  placeholder="Escala"
                  className="text-left mb-2"
                  options={escala}
                  isClearable={true}
                  onChange={e => {
                    if (e === null) {
                      const { escala, ala, ...resto } = adSearch;
                      setAdSearch(resto);
                      return;
                    }

                    carregaAlas(e.value);
                    setAdSearch({
                      ...adSearch,
                      escala: e.value,
                    });
                  }}
                />
              ) : (
                ''
              )}
              {adSearch.escala ? (
                <Select
                  md="3"
                  col="12"
                  placeholder="Ala"
                  className="text-left mb-2"
                  options={ala}
                  isClearable={true}
                  onChange={e => {
                    if (e === null) {
                      const { ala, ...resto } = adSearch;
                      setAdSearch(resto);
                      return;
                    }
                    setAdSearch({
                      ...adSearch,
                      ala: e.value,
                    });
                  }}
                />
              ) : (
                ''
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow className="rounded-bottom p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white">
            <MDBBtn
              color="mdb-color"
              onClick={listarFiltros}
              className=" d-flex justify-content-around align-items-center "
            >
              Buscar
            </MDBBtn>
          </MDBRow>
        </MDBCollapse>

        <MDBRow className="d-flex justify-content-end mx-4">
          <div className="font-weight-bold">
            Total:
            <span className="ml-1">
              {!usuarios.length ? (usuarios.length = 0) : usuarios.length}
            </span>
          </div>
        </MDBRow>
        <MDBRow
          between
          style={{ backgroundColor: 'rgba(250,250,250,0.8)' }}
          className="mx-3 p-2 d-none font-weight-bold d-md-flex rounded-top"
        >
          <div className="col-12 col-md-1 ">Status</div>
          <div className="col-12 col-md-4 ">Nome</div>
          <div className="col-12 col-md-3 ">Lotação</div>
          <div className="col-12 col-md-2 ">Adido</div>
          <div className="col-12 col-md-2 text-right" />
        </MDBRow>

        {usuarios.map(t => (
          <div key={t.id}>
            <MDBRow
              style={{ borderTop: '1px solid silver' }}
              between
              className="p-2 mr-1 ml-1 mr-md-3 ml-md-3  bg-white"
            >
              <div className="col-12 col-md-1 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Status:</span>
                <span>
                  {t.ativo === true ? (
                    <MDBIcon className="text-success" far icon="check-circle" />
                  ) : (
                    <MDBIcon className="text-danger" far icon="times-circle" />
                  )}
                </span>
              </div>

              <div className="col-12 col-md-4 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                <span>{`${t.nome} ${t.sobrenome}`}</span>
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Lotação:</span>
                {`${t.lotacao.gerencia.sigla} /${t.lotacao.escala.nome} /${t.lotacao.ala.nome}`}
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Adido:</span>
                {t.adidos.length === 0 ? (
                  'Agente Civil'
                ) : (
                  <div className="font-weight-bolder">{`${t?.adidos[0]?.orgao?.sigla} - ${t?.adidos[0]?.titulo?.nome}`}</div>
                )}
              </div>

              <div className="col-12 col-md-2 d-flex font-weight-bold justify-content-center justify-content-md-end">
                {getData().id === t.id ? (
                  ''
                ) : (
                  <Link to={`/usuarios/${t.id}`} className="blue-grey-text">
                    <MDBIcon
                      className="mr-1 "
                      far
                      icon="fas fa-eye"
                      size="1x"
                    />

                    <span>Visualizar</span>
                  </Link>
                )}
              </div>
            </MDBRow>
          </div>
        ))}

        {/* <Paginacao page={1} qtdTotal={linhas} /> */}

        <br />
        <br />
      </NavBar>
    </>
  );
}

export default Usuarios;
