import React from 'react';
import { useState, useEffect } from 'react';
import api from '../../../services/api';
// import { Link } from 'react-router-dom';
import Select from 'react-select';
import { getData } from '../../../services/auth';
import { Pie } from 'react-chartjs-2';
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';

// import Pizza from '../../../components/graficos/pizza';
// import Barras from '../../../components/graficos/barras';
// import Barras2 from '../../../components/graficos/barras2';
// import Rosca from '../../../components/graficos/rosca';
// import Rosca2 from '../../../components/graficos/rosca2';
// import Linha from '../../../components/graficos/linha';
// import Minimalista from '../../../components/graficos/minimalista';

function Alocacao() {
  const [dataPie, setDataPie] = useState({
    labels: ['Outros - 70%', 'PMERJ - 30%'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#F7464A',
          '#46BFBD',
          '#FDB45C',
          '#949FB1',
          '#4D5360',
          '#AC64AD',
        ],
        hoverBackgroundColor: [
          '#FF5A5E',
          '#5AD3D1',
          '#FFC870',
          '#A8B3C5',
          '#616774',
          '#DA92DB',
        ],
      },
    ],
  });

  const [tabelaAlas, setTabelaAlas] = useState([]);
  const [gerencias, setGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSeleciona] = useState();
  const [totais, setTotais] = useState({
    pmerj: 0,
    outros: 0,
  });
  const [detalheOutros, setDetalheOutros] = useState([]);

  const carregaGerencias = async () => {
    const response = await api.get('/gerencia', {
      params: {
        estrutura_logica: getData().gerencia,
      },
    });
    let dados = [];
    response.data.map(row => {
      dados.push({
        value: row.id,
        logica: row.estrutura_logica,
        label: row.nome,
      });
    });
    setGerencias(dados);
  };

  const criarTabelas = async tabela => {
    let pmerj = 0;
    let outros = 0;
    tabela.map(total => {
      if (total.pmerj) {
        pmerj += Number(total.pmerj);
      }
      if (total.outros) {
        outros += Number(total.outros);
      }
    });

    setTotais({ pmerj, outros });

    setDataPie({
      labels: [
        `PMERJ - ${((pmerj / (pmerj + outros)) * 100).toFixed(0)} %`,
        `Outros - ${((outros / (pmerj + outros)) * 100).toFixed(0)} %`,
      ],
      datasets: [
        {
          data: [pmerj, outros],
          backgroundColor: [
            '#F7464A',
            '#46BFBD',
            '#FDB45C',
            '#949FB1',
            '#4D5360',
            '#AC64AD',
          ],
          hoverBackgroundColor: [
            '#FF5A5E',
            '#5AD3D1',
            '#FFC870',
            '#A8B3C5',
            '#616774',
            '#DA92DB',
          ],
        },
      ],
    });
  };

  const criaDetalheOutros = outros => {
    setDetalheOutros(outros);
  };

  const estatisticas = async () => {
    const qtd = await api.get('/usuario-estatisticas', {
      params: { gerencia: gerenciaSelecionada },
    });

    let { outros, pmerj, detalheoutros } = qtd.data;
    const total = [...outros, ...pmerj];
    const resultado = [];
    // console.log(total);
    // mapeia os dados que vem do backend
    total.map(linha => {
      // verifica se já existe algum dado e insere o primeiro
      if (resultado.length === 0) {
        if (linha.org === 'PMERJ') {
          resultado.push({ ala: linha.alau, pmerj: linha.agenten });
        } else {
          resultado.push({ ala: linha.alau, outros: linha.agenten });
        }
        return;
      }
      //busca no resultado do array novo
      resultado.map(resultLine => {
        // se já existe a ala
        if (linha.alau === resultLine.ala) {
          // se a linha for pmerj
          if (linha.org === 'PMERJ') {
            // se pmerj nao existir criar uma chave | se existir pega o valor que ja existe e soma
            if (!resultLine.pmerj) {
              resultLine.pmerj = linha.agenten;
            } else {
              resultLine.pmerj =
                Number(resultLine.pmerj) + Number(linha.agenten);
            }
            return;
          } else {
            if (!resultLine.outros) {
              resultLine.outros = linha.agenten;
            } else {
              resultLine.outros =
                Number(resultLine.outros) + Number(linha.outros);
            }
            return;
          }
        }

        // else {
        //   // se a linha for pmerj
        //   if (linha.org === 'PMERJ') {
        //     // resultado.push({ ala: linha.alau, pmerj: linha.agenten });
        //     // se pmerj nao existir criar uma chave | se existir pega o valor que ja existe e soma
        //     if (!resultLine.pmerj) {
        //       resultado.push({ ala: linha.alau, pmerj: linha.agenten });
        //     }
        //     return;
        //   } else {
        //     // resultado.push({ ala: linha.alau, outros: linha.agenten });
        //     if (!resultLine.outros) {
        //       resultado.push({ ala: linha.alau, outros: linha.agenten });
        //     }
        //     return;
        //   }
        // }

        // se nao for pmerj insere um novo objeto com a chave outros

        return;
      });

      if (linha.org === 'PMERJ') {
        // resultado.push({ ala: linha.alau, pmerj: linha.agenten });
        // se pmerj nao existir criar uma chave | se existir pega o valor que ja existe e soma
        // if (!linha.pmerj) {
        //   resultado.push({
        //     ala: linha.alau,
        //     pmerj: linha.agenten,
        //     teste: 'eee',
        //   });
        // }

        // precisa ver isso com urgencia
        return;
      } else {
        // resultado.push({ ala: linha.alau, outros: linha.agenten });
        if (!linha.outros) {
          resultado.push({
            ala: linha.alau,
            outros: linha.agenten,
          });
        }
        return;
      }
    });
    setTabelaAlas(resultado);

    criarTabelas(resultado);

    criaDetalheOutros(detalheoutros);
  };

  useEffect(() => {
    carregaGerencias();
    estatisticas();
  }, []);

  useEffect(() => {
    estatisticas();
  }, [gerenciaSelecionada]);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="justify-content-center">
            <h4 className=" font-weight-bold">Estatísticas de Efetivo</h4>
          </MDBRow>
          {/* <MDBRow>
            <MDBCol col="12" md="4" className="d-flex flex-column">
              <span
                style={{ fontSize: '.8rem' }}
                className="font-weight-bolder"
              >
                Gerência:
              </span>
              <select className="browser-default custom-select">
                <option>Selecione uma Gerência</option>
                <option value="1">Segov</option>
                <option value="2">Barreira Fiscal</option>
                <option value="3">Lei Seca</option>
                <option value="4">Segurança Presente</option>
                <option value="4">Maxrcha da Cidadania</option>
              </select>
            </MDBCol>

            <MDBCol
              col="12"
              md="4"
              className="d-flex flex-column align-items-stretch"
            >
              <span
                style={{ fontSize: '.8rem' }}
                className="font-weight-bolder"
              >
                Data Início:
              </span>
              <input
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '.25rem',
                  padding: '.30rem',
                }}
                className="px-3"
                type="date"
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="4"
              className="d-flex flex-column align-items-stretch"
            >
              <span
                style={{ fontSize: '.8rem' }}
                className="font-weight-bolder"
              >
                Data Fim:
              </span>
              <input
                style={{
                  border: '1px solid #ced4da',
                  borderRadius: '.25rem',
                  padding: '.30rem',
                }}
                className="px-3"
                type="date"
              />
            </MDBCol>
          </MDBRow> */}

          <MDBRow></MDBRow>
          <br />
          {/* {console.log(tabelaTotais)} */}
          <MDBRow className="font-weight-bolder align-items-center">
            <MDBCol className="mr-3" col="12">
              <MDBCol col="12">
                <Select
                  placeholder="Gerência"
                  className="text-left mb-2"
                  // closeMenuOnSelect={false}
                  // defaultValue={[colourOptions[0], colourOptions[1]]}
                  // isMulti

                  options={gerencias}
                  onChange={e => {
                    setGerenciaSeleciona(e.logica);
                    // console.log(e);
                  }}
                />
              </MDBCol>
              <br />
              <MDBRow className="p-2 my-1 mdb-color lighten-3">
                <MDBCol className="text-center">Total</MDBCol>
                <MDBCol className="text-center">
                  {totais.pmerj + totais.outros}
                </MDBCol>
              </MDBRow>

              <MDBRow className="p-2  my-1  mdb-color lighten-5">
                <MDBCol className="text-center">Outros</MDBCol>
                <MDBCol className="text-center">{totais.outros}</MDBCol>
              </MDBRow>

              <MDBRow className=" p-2 mdb-color lighten-5">
                <MDBCol className="text-center">PMERJ</MDBCol>
                <MDBCol className="text-center">{totais.pmerj} </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol>
              <Pie data={dataPie} options={{ responsive: true }} />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow between>
            <MDBCol className="pr-4" col="12" md="6">
              <MDBRow className="p-1  mdb-color lighten-3 font-weight-bolder">
                <MDBCol className="text-center">Outros</MDBCol>
                <MDBCol className="text-center">Agentes</MDBCol>
              </MDBRow>
              {detalheOutros
                ? detalheOutros.map(outros => (
                    <MDBRow
                      key={outros.org}
                      className="p-1  mdb-color lighten-5"
                      style={{ borderBottom: '1px  #929fba solid' }}
                    >
                      <MDBCol className="text-center">{outros.org}</MDBCol>
                      <MDBCol className="text-center">
                        {Number(outros.agenten)}
                      </MDBCol>
                    </MDBRow>
                  ))
                : ''}
            </MDBCol>
            <MDBCol className="pl-4" col="12" md="6">
              <MDBRow className="p-1  mdb-color lighten-3 font-weight-bolder">
                <MDBCol className="text-center">Ala</MDBCol>
                <MDBCol className="text-center">Outros</MDBCol>
                <MDBCol className="text-center">PMERJ</MDBCol>
                <MDBCol className="text-center">Total</MDBCol>
              </MDBRow>
              {tabelaAlas.map(ala => (
                <MDBRow
                  key={ala.ala}
                  className="p-1  mdb-color lighten-5"
                  style={{ borderBottom: '1px  #929fba solid' }}
                >
                  <MDBCol className="text-center">{ala.ala}</MDBCol>
                  <MDBCol className="text-center">{ala.outros}</MDBCol>
                  <MDBCol className="text-center">{ala.pmerj}</MDBCol>
                  <MDBCol className="text-center">
                    {Number(ala.outros ? ala.outros : 0) +
                      Number(ala.pmerj ? ala.pmerj : 0)}
                  </MDBCol>
                </MDBRow>
              ))}
            </MDBCol>
          </MDBRow>
          <br />
          {/* <MDBRow>
            <MDBCol><Pizza /></MDBCol>
            <MDBCol><Rosca /></MDBCol>
            <MDBCol><Rosca2 /></MDBCol>
          </MDBRow> */}
          {/* <MDBRow>
            <MDBCol md="7"><Linha /></MDBCol>
            <MDBCol md="5"><Minimalista /></MDBCol>
          </MDBRow> */}
          {/* <MDBRow>
            <div style={{ width: '50%' }}><Barras /></div>
            <div style={{ width: '50%' }}><Barras2 /></div>
          </MDBRow> */}
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Alocacao;
