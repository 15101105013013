import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBNav,
  MDBRow,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Barreira from '../../assets/barreira.png';
import LeiSeca from '../../assets/leiseca.jpg';
import Select from 'react-select';
import { useState } from 'react';
import { useEffect } from 'react';
import { compareAsc } from 'date-fns';

import api from '../../services/api';
import { toast } from 'react-toastify';
// import { Container } from './styles';

function Alocacao() {
  const [activeItem, setActiveItem] = useState('setor');
  const [collapseFiltro, setCollapseFiltro] = useState(false);

  const [setores, setSetores] = useState([]);

  const [escalaServico, setEscalaServico] = useState([]);

  const [novaEscala, setNovaEscala] = useState({});

  const [novoSetor, setNovoSetor] = useState({});
  const [optionSetor, setOptionSetor] = useState([]);

  const [lotacoes, setLotacoes] = useState([]);
  const [optionLotacoes, setOptionLotacoes] = useState([]);

  const [optionEscalas, setOptionEscalas] = useState([]);

  const [guarnicao, setGuarnicao] = useState([]);
  const [optionGuarnicao, setOptionGuarnicao] = useState([]);

  const [area, setArea] = useState([]);
  const [optionArea, setOptionArea] = useState([]);

  const [checkNovaArea, setCheckNovaArea] = useState('false');
  const [checkNovaGuarnicao, setCheckNovaGuarnicao] = useState('false');

  const [enviaGuarnicao, setEnviaGuarnicao] = useState({
    guarnicao_id: '',
  });
  const [enviaArea, setEnviaArea] = useState({
    area_id: '',
  });

  const [escalas, setEscalas] = useState([]);

  const toggle = tab => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  const selectGuarnicao = async () => {
    const response = await api.get('/guarnicao');
    setGuarnicao(response.data);

    let optionGuarnicao = [];
    response.data.map(guarnicao => {
      optionGuarnicao.push({ value: guarnicao.id, label: guarnicao.nome });
    });

    setOptionGuarnicao(optionGuarnicao);
  };

  const selectArea = async () => {
    const response = await api.get('/area');
    setArea(response.data);

    let optionArea = [];
    response.data.map(area => {
      optionArea.push({ value: area.id, label: area.nome });
    });

    setOptionArea(optionArea);
  };

  const loadEscalas = async () => {
    const response = await api.get('/select-escala');

    // console.log(response)
    // return

    // setEscalas(response.data);

    let optionescalas = [];
    response.data.map(escala => {
      // optionlotacao.push({ value: lotacao.id , label: lotacao.escala.nome+' / '+lotacao.ala.nome })
      optionescalas.push({ value: escala.id, label: escala.nome });
    });
    setOptionEscalas(optionescalas);
  };

  const loadLotacoes = async () => {
    const response = await api.get('/lotacao');

    setLotacoes(response.data);

    let optionlotacao = [];
    response.data.map(lotacao => {
      // optionlotacao.push({ value: lotacao.id , label: lotacao.escala.nome+' / '+lotacao.ala.nome })
      optionlotacao.push({ value: lotacao.id, label: lotacao.escala.nome });
    });
    setOptionLotacoes(optionlotacao);
  };

  const loadSetores = async () => {
    const response = await api.get('/setor');
    setSetores(response.data);

    let opselectSetor = [];
    response.data.map(setor => {
      opselectSetor.push({
        value: setor.id,
        label: setor.guarnicao.nome + ' / ' + setor.area.nome,
      });
    });

    setOptionSetor(opselectSetor);
  };

  const loadEscalaServico = async () => {
    const response = await api.get('/escala-de-servico');
    setEscalaServico(response.data);
  };

  const enviaSetor = async () => {
    if (!enviaGuarnicao.guarnicao_id && !enviaGuarnicao.novaGuarnicao) {
      toast.error('Preecha o campo guarnição');
      return;
    }
    if (!enviaArea.area_id && !enviaArea.novaArea) {
      toast.error('Preecha o campo área');
      return;
    }

    const response = await api.post('/setor', {
      ...enviaArea,
      ...enviaGuarnicao,
    });

    if (response.data.erro) {
      toast.error(response.data.erro);
      return;
    }
    toast.success('Setor Adicionado!');

    loadSetores();
  };

  const enviaEscala = async () => {
    const data = compareAsc(new Date(novaEscala.dt_inicio), new Date());

    if (data == -1) {
      toast.error('Data anterior ao dia permitido');
      return;
    }
    if (!novaEscala.dt_inicio) {
      toast.error('Preecha a data de Início');
      return;
    }
    if (!novaEscala.escala) {
      toast.error('Preecha o campo Lotaçao');
      return;
    }
    if (!novaEscala.setor_id) {
      toast.error('Preecha o campo Setor');
      return;
    }

    try {
      const response = await api.post('/escala-de-servico', novaEscala);
      loadEscalaServico();
    } catch (error) {
      console.log(error);
    }

    // if(response.data.erro){
    //   toast.error(response.data.erro);
    //   return
    // }

    toast.success('Escala de Serviço Adicionada!');
  };

  const novaGuarnicao = async () => {
    setCheckNovaGuarnicao(!checkNovaGuarnicao);
  };

  const ativarDesativar = async (idSetor, status) => {
    let inverteValor;
    if (status === true) {
      inverteValor = false;
    }
    if (status === false) {
      inverteValor = true;
    }

    const response = await api.put(`/setor/${idSetor}`, {
      ativo: inverteValor,
    });

    loadSetores();
  };

  useEffect(() => {
    loadSetores();
    loadEscalas();
    loadEscalaServico();
    loadLotacoes();
    selectGuarnicao();
    selectArea();
  }, []);

  return (
    <>
      <NavBar />
      <MDBContainer>
        <MDBNav className="nav-tabs">
          <MDBNavItem>
            <MDBNavLink
              className=" font-weight-bold"
              link
              to="#"
              active={activeItem === 'setor'}
              onClick={() => toggle('setor')}
              role="tab"
            >
              Setores
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              className="font-weight-bold"
              link
              to="#"
              active={activeItem === 'escalaservico'}
              onClick={() => toggle('escalaservico')}
              role="tab"
            >
              Escala de Serviço
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem>
            <MDBNavLink
              className="font-weight-bold"
              link
              to="#"
              active={activeItem === 'guarnicao'}
              onClick={() => toggle('guarnicao')}
              role="tab"
            >
              Guarnição
            </MDBNavLink>
          </MDBNavItem> */}
          {/* <MDBNavItem>
            <MDBNavLink
              className="font-weight-bold"
              link
              to="#"
              active={activeItem === 'area'}
              onClick={() => toggle('area')}
              role="tab"
            >
              Área
            </MDBNavLink>
          </MDBNavItem> */}
        </MDBNav>
        <MDBTabContent activeItem={activeItem}>
          {/* ABA QUE TRATA SETOR  */}
          <MDBTabPane tabId="setor" role="tabpanel">
            <MDBRow className="d-flex justify-content-between align-items-center mx-3">
              <MDBCol col="4" md="4" className="h4-responsive">
                Configuração de Setores
              </MDBCol>
              <MDBCol col="8" md="4" className="d-flex justify-content-end">
                <MDBBtn
                  color="primary"
                  onClick={() => setCollapseFiltro(!collapseFiltro)}
                  className="d-flex justify-content-around align-items-center"
                >
                  <MDBIcon className="mr-2" icon="plus" size="2x" />
                  <h5 className="m-0">Novo Setor</h5>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBCollapse id="basicCollapse" isOpen={collapseFiltro}>
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
              >
                <h4>Insira novo setor</h4>
              </MDBRow>
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
              >
                <MDBCol col="5" className="text-left">
                  <div className="custom-control custom-checkbox">
                    <input
                      onChange={() => {
                        setCheckNovaGuarnicao(!checkNovaGuarnicao);
                        setEnviaGuarnicao({});
                      }}
                      type="checkbox"
                      className="custom-control-input"
                      id="checkedGuarnicao"
                    />
                    <label
                      className="custom-control-label font-weight-bold blue-grey-text"
                      htmlFor="checkedGuarnicao"
                    >
                      Criar Nova Guarnição
                    </label>
                  </div>
                  {!checkNovaGuarnicao ? (
                    <MDBInput
                      onChange={e => {
                        setEnviaGuarnicao({ novaGuarnicao: e.target.value });
                      }}
                      label="Guarnição"
                    />
                  ) : (
                    <Select
                      className="text-left w-100 mb-4 mt-4"
                      onChange={e => {
                        setEnviaGuarnicao({ guarnicao_id: e.value });
                      }}
                      placeholder="Guarnição"
                      options={optionGuarnicao}
                    />
                  )}
                </MDBCol>

                <MDBCol col="5" className="text-left">
                  <div className="custom-control custom-checkbox">
                    <input
                      onChange={() => {
                        setEnviaArea({});
                        setCheckNovaArea(!checkNovaArea);
                      }}
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultUnchecked"
                    />
                    <label
                      className="custom-control-label font-weight-bold blue-grey-text"
                      htmlFor="defaultUnchecked"
                    >
                      Criar Nova Área
                    </label>
                  </div>
                  {!checkNovaArea ? (
                    <MDBInput
                      onChange={e => {
                        setEnviaArea({ novaArea: e.target.value });
                      }}
                      label="Área"
                    />
                  ) : (
                    <Select
                      className="text-left mb-4 mt-4"
                      onChange={e => {
                        setEnviaArea({ area_id: e.value });
                      }}
                      placeholder="Área"
                      options={optionArea}
                    />
                  )}
                </MDBCol>
                <MDBBtn
                  color="success"
                  onClick={() => enviaSetor()}
                  className="h-50 col-8 col-md-2 d-flex justify-content-around align-items-center"
                >
                  <MDBIcon className="mr-2" icon="plus" size="2x" />
                  <h5 className="m-0">Cadastrar</h5>
                </MDBBtn>
              </MDBRow>
            </MDBCollapse>
            <MDBRow
              between
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
            >
              <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                Gerência
              </div>

              <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                Guarnição
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
                Área
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-center font-weight-bold text-white">
                Setor
              </div>
            </MDBRow>
            {setores.map(setor => (
              <MDBRow
                key={setor.id}
                between
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <div className="col-12 col-md-3 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                  {setor.gerencia.nome}
                </div>

                <div className="col-12 col-md-3 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Guarnicao:</span>
                  {setor.guarnicao.nome}
                </div>
                <div className="col-12 col-md-4 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Área:</span>
                  {setor.area.nome}
                </div>
                <div className="col-12 col-md-2 d-flex justify-content-end">
                  {setor.ativo ? (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => ativarDesativar(setor.id, setor.ativo)}
                      className="font-weight-bold text-danger "
                    >
                      Desativar{' '}
                      <MDBIcon className="ml-1" far icon="thumbs-down" />
                    </div>
                  ) : (
                    <span className="font-weight-bold text-success ">
                      {' '}
                      <div
                        onClick={() => ativarDesativar(setor.id, setor.ativo)}
                        style={{ cursor: 'pointer' }}
                      >
                        Ativar <MDBIcon className="ml-1" far icon="thumbs-up" />
                      </div>
                    </span>
                  )}
                </div>
              </MDBRow>
            ))}

            <br />
            <br />
          </MDBTabPane>
          {/* FIM DA ABA QUE TRATA SETOR */}

          {/* ABA QUE TRATA ESCALA DE SERVIÇO */}
          <MDBTabPane tabId="escalaservico" role="tabpanel">
            <MDBRow className="d-flex justify-content-between align-items-center">
              <MDBCol col="4" md="4" className="h4-responsive">
                Configuração de Escalas de Serviço
              </MDBCol>
              <MDBCol col="8" md="4" className="d-flex justify-content-end">
                <MDBBtn
                  color="primary"
                  onClick={() => setCollapseFiltro(!collapseFiltro)}
                  className="d-flex justify-content-around align-items-center"
                >
                  <MDBIcon className="mr-2" icon="plus" size="2x" />
                  <h5 className="m-0">Nova Escala</h5>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBCollapse
              style={{ borderRadius: '5px' }}
              className="bg-white"
              id="basicCollapse"
              isOpen={collapseFiltro}
            >
              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
              >
                <h4>Insira nova Escala de Serviço</h4>
              </MDBRow>
              {/* ADD NOVA ESCALA DE SERVICO */}

              <MDBRow
                style={{ borderRadius: '5px' }}
                className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center align-items-center text-center"
              >
                <MDBCol col="4">
                  <MDBInput
                    onChange={e => {
                      setNovaEscala({
                        ...novaEscala,
                        dt_inicio: e.target.value,
                      });
                    }}
                    type="date"
                    label="Data"
                  />
                </MDBCol>

                <MDBCol col="4">
                  <Select
                    className="text-left"
                    onChange={e => {
                      setNovaEscala({ ...novaEscala, setor_id: e.value });
                    }}
                    placeholder="Setor"
                    options={optionSetor}
                  />
                </MDBCol>
                <MDBCol col="4">
                  <Select
                    className="text-left"
                    placeholder="Lotação"
                    onChange={e => {
                      setNovaEscala({ ...novaEscala, escala: e.value });
                    }}
                    options={optionEscalas}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-center">
                <MDBBtn
                  color="success"
                  onClick={() => enviaEscala()}
                  className="h-50 col-8 col-md-2 d-flex justify-content-around align-items-center"
                >
                  <MDBIcon className="mr-2" icon="plus" size="2x" />
                  <h5 className="m-0">Cadastrar</h5>
                </MDBBtn>
              </MDBRow>

              {/* FIM ADD NOVA ESCALA DE SERVICO   */}
            </MDBCollapse>

            <MDBRow
              between
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
            >
              <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                Lotação ( Escala / Ala )
              </div>

              <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                Guarnição
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
                Área
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-center font-weight-bold text-white">
                Setor
              </div>
            </MDBRow>

            {escalaServico.map(escala => (
              <MDBRow
                key={escala.id}
                between
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <div className="col-12 col-md-3 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                  {escala?.lotacao?.escala?.nome} / {escala?.lotacao?.ala?.nome}
                </div>

                <div className="col-12 col-md-3 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Guarnicao:</span>
                  {escala.setor.guarnicao.nome}
                </div>
                <div className="col-12 col-md-2 d-flex justify-content-between">
                  <span className="d-sm-inline d-md-none mr-3">Área:</span>
                  {escala.setor.area.nome}
                </div>
                <div className="col-12 col-md-2 d-flex justify-content-end">
                  {escala.ativo ? (
                    <span className="font-weight-bold text-danger ">
                      Desativar{' '}
                      <MDBIcon className="ml-1" far icon="thumbs-down" />
                    </span>
                  ) : (
                    <span className="font-weight-bold text-success ">
                      {' '}
                      Ativar <MDBIcon className="ml-1" far icon="thumbs-up" />
                    </span>
                  )}
                </div>
              </MDBRow>
            ))}
            <br />
            <br />
          </MDBTabPane>
          {/* ABA QUE TRATA ESCALA DE SERVIÇO */}

          <MDBTabPane tabId="guarnicao" role="tabpanel">
            <p className="mt-2">
              Quisquam aperiam, pariatur. Tempora, placeat ratione porro
              voluptate odit minima. Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Nihil odit magnam minima, soluta doloribus
              reiciendis molestiae placeat unde eos molestias.
            </p>
          </MDBTabPane>
          <MDBTabPane tabId="area" role="tabpanel">
            <p className="mt-2">tab 4</p>
          </MDBTabPane>
        </MDBTabContent>
      </MDBContainer>
    </>
  );
}

export default Alocacao;
