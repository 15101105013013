import React from 'react';
import { useState, useEffect } from 'react';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  MDBAnimation,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import api from '../../../../../services/api';

export default function Autuados(props) {
  // const idReq = props.match.params.abordagem_id;

  // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  const [modalRemoveAutuado, setModalRemoveAutuado] = useState(null);
  // const [autuados, setAutuados] = useState([]);
  const [modalAutuado, setModalAutuado] = useState(false);
  // autuado selecionado
  const [autuadoSelecionado, setAutuadoSelecionado] = useState({
    label: '',
    value: '',
    cpf: '',
  });
  const [ocorrenciaSelecionada, setOcorrenciaSelecionada] = useState(null);
  const [selectAutuados, setSelectAutuados] = useState([]);
  const modalAdicionaAutuado = oco_id => {
    setModalAutuado(!modalAutuado);
    PreencheSelectAutuados();
    setOcorrenciaSelecionada(oco_id);
  };

  // ADD AUTUADO
  const adicionaAutuado = async () => {
    if (ocorrenciaSelecionada === null || autuadoSelecionado.value === '') {
      toast.error('Falha no sistema, tente mais tarde');
      return;
    }

    setLoadingEnviar(true);
    const resultado = await api.post('/ocorrencia-autuado-add', {
      ocorrencia_id: ocorrenciaSelecionada,
      abordado_id: autuadoSelecionado.value,
    });

    if (resultado.data.result) {
      toast.success(resultado.data.result);
    } else {
      toast.error(resultado.data.error);
      setLoadingEnviar(false);
      return;
    }
    setModalAutuado(null);
    setAutuadoSelecionado({
      label: '',
      value: '',
      cpf: '',
    });
    setOcorrenciaSelecionada(null);
    setLoadingEnviar(false);
    props.listar();
  };

  const excluirAutuado = async () => {
    let semAutuado = 0;

    props.ocorrencia.forEach(t => {
      if (t.ativo === true) {
        semAutuado++;
      }
    });

    if (semAutuado === 1) {
      toast.error('Precisa conter pelo menos 1 autuado');
      return;
    }

    setLoadingDeletar(true);
    const resultado = await api.put(
      `/autuado-ocorrencia/${modalRemoveAutuado}`,
      {
        ativo: false,
      }
    );
    setLoadingDeletar(false);
    setModalRemoveAutuado(null);
    resultado.data.ok
      ? toast.success(resultado.data.ok)
      : toast.error(resultado.data.error);
    props.listar();
  };
  // SELECT QUE PREENCHE AUTUADOS
  const PreencheSelectAutuados = async () => {
    const select = [];
    setLoadingListar(true);
    const result = await api.get(`/sgo/busca-abordados/${props.abordagem_id}`);

    result.data.forEach(abordado => {
      select.push({
        label: abordado.nome,
        value: abordado.id,
        cpf: abordado.cpf,
      });
    });

    setSelectAutuados(select);
    setLoadingListar(false);
  };

  return (
    <>
      <MDBRow
        center
        className="font-weight-bolder"
        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
      >
        Pessoas Autuadas:
      </MDBRow>

      {props.ocorrencia.map(autuado =>
        autuado.ativo ? (
          <MDBRow>
            <MDBCol>Nome: {autuado.abordado.nome}</MDBCol>
            <MDBCol>Tipo: {autuado.abordado.tipo}</MDBCol>
            <MDBCol>CPF: {autuado.abordado.cpf}</MDBCol>
            <MDBCol className="text-right">
              <div
                style={{ cursor: 'pointer' }}
                className="danger-text mx-3"
                onClick={() => {
                  setModalRemoveAutuado(autuado.id);
                  // setModalDeleteAbordado(abordado.id);
                }}
              >
                <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                <span style={{ fontSize: '14px' }}>Remover</span>
              </div>
            </MDBCol>
          </MDBRow>
        ) : (
          ''
        )
      )}

      <MDBRow center className="my-2">
        <MDBBtn
          color="mdb-color"
          onClick={() => {
            modalAdicionaAutuado(props.ocorrencia_id);
          }}
        >
          Adicionar Autuado
        </MDBBtn>
      </MDBRow>
      {/* modal de remover autuado */}
      <MDBModal
        isOpen={modalRemoveAutuado !== null}
        size="lg"
        toggle={() => {
          setModalRemoveAutuado(null);
        }}
        // onClick={() => setOcorrenciaSelecionada('')}
      >
        <MDBModalHeader
          className="grey lighten-4"
          toggle={() => {
            setModalRemoveAutuado(null);
          }}
          // onClick={() => setOcorrenciaSelecionada('')}
        >
          <span className="text-weight-bold danger-text">ATENÇÃO</span>
        </MDBModalHeader>
        <MDBModalBody className="h4-responsive my-5 text-center">
          Deseja realmente remover este autuado?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              setModalRemoveAutuado(null);
            }}
          >
            NÃO
          </MDBBtn>
          <MDBBtn
            disabled={loadingDeletar}
            onClick={() => excluirAutuado()}
            color="primary"
          >
            {loadingDeletar ? 'Removendo' : 'SIM'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA CRIACAO DE AUTUADOS */}
      <MDBModal
        isOpen={modalAutuado}
        toggle={() => {
          setModalAutuado(!modalAutuado);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAutuado(!modalAutuado);
          }}
        >
          Inserir Autuado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center></MDBRow>
          <MDBRow>
            <MDBCol size="12" middle>
              <Select
                isLoading={loadingListar}
                placeholder="Escolha o abordado"
                options={selectAutuados}
                onChange={e => {
                  setAutuadoSelecionado(e);
                }}
              />
              {/* <MDBInput
                // maxlength={switchChassi === false ? '7' : '17'}
                type="text"
                label="Nome"
                // placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                onChange={e => setNome(e.target.value)}
                value={nome}
              /> */}
            </MDBCol>

            {/* <MDBCol size="12" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className="w-100 font-weight-light md-form form-group form-lg"
                type="text"
                placeholder="CPF"
                onChange={e => setCpf(e.target.value.replace(/\D+/g, ''))}
                value={cpf}
                mask="999.999.999-99"
                maskChar=" "
                disabled
              />
            </MDBCol> */}
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalAutuado(!modalAutuado);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            disabled={loadingEnviar}
            onClick={() => {
              adicionaAutuado();
            }}
            color="success"
          >
            {loadingEnviar ? 'Cadastrando' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
