import React, { useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import InputMask from 'react-input-mask';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBCardHeader,
} from 'mdbreact';
import { isPast, getYear } from 'date-fns';

import api from '../../../../services/api';
import { getData } from '../../../../services/auth';
import format from 'date-fns/format';
import Paginacao from '../../../../components/Paginacao';

export function Manutencao() {
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState([]);

  // manutencao
  const [manutencaoSelected, setManutencaoSelected] = useState(null);
  // subtipos de manutencao
  const [listaManutencao, setListaManutencao] = useState([]);
  // subtipos de manutencao selecionado
  const [manutencaoSubSelecionado, setManutencaoSubSelecionado] = useState(
    null
  );

  const [listaStatus, setListaStatus] = useState([]);
  const [statusSelected, setStatusSelected] = useState(null);

  const [listaVtrs, setListaVtrs] = useState([]);
  const [vtrSelect, setVtrSelect] = useState(null);

  const [resultaDados, setResultaDados] = useState([]);
  const [dadosManutencao, setDadosManutencao] = useState([]);
  const [page, setPage] = useState(1);
  const [qtdTotal, setQtdTotal] = useState(0);
  const dataInicio = useRef(null);
  const dataFim = useRef(null);
  // select de gerecias
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
      setGerenciaSelecionada(select[0]);
      carregaVtr(select[0]);
    });
  };

  // busca subtipos
  const buscaTiposManutencao = () => {
    api.get('/tipos-manutencoes').then(t => {
      const select = [];
      t.data.forEach(element => {
        select.push({ label: element.tipo_manutencao, value: element.id });
      });

      setListaManutencao(select);
    });
  };

  const carregaVtr = gerencia => {
    setGerenciaSelecionada(gerencia);
    setVtrSelect(null);
    api
      .get('/frota', {
        params: { gerencia_id: gerencia.value, ativo: true },
      })
      .then(t => {
        const veiculosSelect = [];
        t.data.forEach(element => {
          veiculosSelect.push({ label: element.placa, value: element.id });
        });
        setListaVtrs(veiculosSelect);
      });
  };
  // busca Resultado Final
  const buscaDadosManutencoes = async numPage => {
    const where = {};

    if (numPage !== undefined) {
      where.page = numPage;
      setPage(numPage);
    }

    if (manutencaoSelected !== null) {
      where.fk_tipos_manutencoes_id = manutencaoSelected.value;
    }
    if (gerenciaSelecionada !== null) {
      where.fk_gerencia_id = gerenciaSelecionada.value;
    }
    if (vtrSelect !== null) {
      where.fk_veiculos_id = vtrSelect.value;
    }
    if (manutencaoSubSelecionado !== null) {
      where.fk_tipos_manutencoes_id = manutencaoSubSelecionado.value;
    }

    // if(dataInicio !== null){
    //   where.
    // }

    const result = await api.get('/manutencoes', {
      params: where,
    });

    setDadosManutencao(result.data.rows);

    setQtdTotal(result.data.count);
    // setResultaDados(result.data);
  };

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    buscaDadosManutencoes(page);
  }, [page]);

  useEffect(() => {
    carregaGerencias();
    buscaTiposManutencao();
  }, []);
  return (
    <>
      <MDBCard>
        <MDBCardHeader className="bg-white">
          Filtros de Manutenção
        </MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            {/* <MDBCol col="12" md="4">
      <Select
        className="mt-4"
        outline
        required
        placeholder="Selecione o tipo"
        options={listTipos}
        onChange={e => {
          buscaTipoSelecionado(e);
        }}
        value={tipoSelected}
      />

      {tipoSelected?.value === 1 && (
        <Select
          className="mt-5"
          outline
          isClearable
          placeholder="Tipo de Manutencao"
          options={listaManutencao}
          onChange={e => setManutencaoSubSelecionado(e)}
          value={manutencaoSubSelecionado}
        />
      )}
      {tipoSelected?.value === 2 && (
        <Select
          className="mt-5"
          outline
          isClearable
          placeholder="Tipo de Status"
          options={listaStatus}
          value={statusSelected}
          onChange={e => setStatusSelected(e)}
        />
      )}
    </MDBCol> */}
            <MDBCol col="12" md="4" bottom className=" mt-md-0">
              <Select
                className="mt-4"
                outline
                required
                placeholder="Selecione Posto"
                options={listaGerencias}
                value={gerenciaSelecionada}
                onChange={e => carregaVtr(e)}
              />
            </MDBCol>
            <MDBCol col="12" md="4" middle className=" mt-md-0">
              <Select
                className="mt-5"
                outline
                isClearable
                placeholder="Tipo de Manutencao"
                options={listaManutencao}
                onChange={e => setManutencaoSubSelecionado(e)}
                value={manutencaoSubSelecionado}
              />
            </MDBCol>
            <MDBCol col="12" md="4" middle className=" mt-md-0">
              <Select
                className="mt-4 mt-md-5"
                outline
                placeholder="Selecione VTR"
                isClearable
                options={listaVtrs}
                onChange={e => setVtrSelect(e)}
                value={vtrSelect}
              />
            </MDBCol>
          </MDBRow>
          {/* <MDBRow>
            <MDBCol col="12" md="4" bottom>
              <MDBInput
                className="mt-5 mt-md-4"
                label="Data Início"
                outline
                type="date"
                onChange={e => (dataInicio.current = e.target.value)}
                // onChange={e => this.setState({ km: e.target.value })}
                // value={this.state.km}
              />
            </MDBCol>
            <MDBCol col="12" md="4" bottom>
              <MDBInput
                className="mt-5"
                label="Data Fim"
                outline
                type="date"
                onChange={e => (dataFim.current = e.target.value)}
                // onChange={e => this.setState({ km: e.target.value })}
                // value={this.state.km}
              />
            </MDBCol>
          </MDBRow> */}
          <br />
          <MDBRow center>
            <MDBBtn
              onClick={() => {
                buscaDadosManutencoes(1);
                // setPage(1);
              }}
              size="sm"
              color="green"
            >
              Buscar
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="mt-3">
        <MDBCardHeader className="grey lighten-3">Manutenções</MDBCardHeader>
        <MDBCardBody>
          {dadosManutencao.map(manut => (
            <div
              key={manut.id}
              className="border rounded p-2 mb-3 grey lighten-4"
            >
              <MDBRow>
                <MDBCol size="12" md="3">
                  Placa: {manut?.veiculo?.placa}
                </MDBCol>
                <MDBCol size="12" md="3" className="mt-4">
                  KM: {manut?.quilometragem}
                </MDBCol>
                <MDBCol size="12" md="3" className="mt-4">
                  <div>Tipo Manutenção:</div>
                  <div>{manut?.tipos_manutenco.tipo_manutencao}</div>
                </MDBCol>
                <MDBCol size="12" md="3" className="mt-4">
                  <div> Agendado em:</div>
                  <div>
                    {format(new Date(manut?.createdAt), 'dd/MM/yyyy HH:mm')}
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" md="3" className="mt-4">
                  <div> Agendado para:</div>
                  <div>
                    {format(new Date(manut?.data_agendado), 'dd/MM/yyyy HH:mm')}
                  </div>
                </MDBCol>
                <MDBCol size="12" md="3" className="mt-4">
                  <div> Iniciado:</div>
                  <div>
                    {manut?.data_iniciado === null &&
                      manut?.data_executado === null && (
                        <div className="text-danger">Manutenção Agendada</div>
                      )}

                    {manut?.data_iniciado !== null &&
                      manut?.data_executado === null && (
                        <div className="text-danger">Em Manutenção</div>
                      )}

                    {manut?.data_iniciado === null &&
                      manut?.data_executado !== null && (
                        <div className="text-danger">Cancelado</div>
                      )}

                    {manut?.data_iniciado !== null &&
                      manut?.data_executado !== null &&
                      format(
                        new Date(manut?.data_iniciado),
                        'dd/MM/yyyy HH:mm'
                      )}
                  </div>
                </MDBCol>
                <MDBCol size="12" md="3" className="mt-4">
                  {manut?.data_iniciado === null &&
                  manut?.data_executado !== null ? (
                    <div className="text-danger">Cancelado em:</div>
                  ) : (
                    <div>Executado:</div>
                  )}

                  <div>
                    {manut?.data_iniciado === null &&
                    manut?.data_executado !== null ? (
                      <div className="text-danger">
                        {format(
                          new Date(manut.data_executado),
                          'dd/MM/yyyy HH:mm'
                        )}
                      </div>
                    ) : manut?.data_executado === null &&
                      manut?.data_executado === null ? (
                      <div className="text-danger">Manutenção Aberta</div>
                    ) : (
                      format(
                        new Date(manut?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-2">
                <MDBCol>
                  {manut?.data_iniciado === null &&
                    manut?.data_executado !== null && (
                      <div>
                        <div className="font-weight-bold">Descrição:</div>
                        <div>{manut?.descricao}</div>
                      </div>
                    )}
                </MDBCol>
              </MDBRow>
            </div>
          ))}
          <Paginacao
            page={page}
            qtdTotal={qtdTotal}
            onPageChange={handlePageChange}
          />
        </MDBCardBody>
      </MDBCard>
    </>
  );
}
