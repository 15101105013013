import React from 'react';
import { useState, useEffect } from 'react';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  MDBAnimation,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import api from '../../../../../services/api';

export default function Equipe(props) {
  // // const idReq = props.match.params.abordagem_id;

  // // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalRemoveEquipe, setModalRemoveEquipe] = useState(null);
  const [modalEquipe, setModalEquipe] = useState(false);
  const [selectAgentes, setSelectAgentes] = useState([]);
  const [agenteSelecionado, setAgenteSelecionado] = useState({
    label: '',
    value: '',
  });
  // // const [autuados, setAutuados] = useState([]);
  // const [modalAutuado, setModalAutuado] = useState(false);
  // // autuado selecionado
  // const [autuadoSelecionado, setAutuadoSelecionado] = useState({
  //   label: '',
  //   value: '',
  //   cpf: '',
  // });
  // const [ocorrenciaSelecionada, setOcorrenciaSelecionada] = useState(null);
  // const [selectAutuados, setSelectAutuados] = useState([]);
  // const modalAdicionaAutuado = oco_id => {
  //   setModalAutuado(!modalAutuado);
  //   PreencheSelectAgentes();
  //   setOcorrenciaSelecionada(oco_id);
  // };

  // // ADD AUTUADO
  // const adicionaAutuado = async () => {
  //   if (ocorrenciaSelecionada === null || autuadoSelecionado.value === '') {
  //     toast.error('Falha no sistema, tente mais tarde');
  //     return;
  //   }

  //   setLoading(true);
  //   const resultado = await api.post('/ocorrencia-autuado-add', {
  //     ocorrencia_id: ocorrenciaSelecionada,
  //     abordado_id: autuadoSelecionado.value,
  //   });

  //   if (resultado.data.result) {
  //     toast.success(resultado.data.result);
  //   } else {
  //     toast.error(resultado.data.error);
  //   }
  //   setModalAutuado(null);
  //   setAutuadoSelecionado({
  //     label: '',
  //     value: '',
  //     cpf: '',
  //   });
  //   setOcorrenciaSelecionada(null);
  //   setLoading(false);
  //   props.listar();
  // };

  const addEquipe = async () => {
    if (agenteSelecionado.value === '') {
      toast.error('Preencha o campo de agente');
      return;
    }

    setLoading(true);

    const resultado = await api.post(`/add-agente`, {
      ocorrencia_id: props.ocorrencia_id,
      usuario_id: agenteSelecionado.value,
    });

    setLoading(false);

    setModalEquipe(false);
    resultado.data.ok
      ? toast.success(resultado.data.ok)
      : toast.error(resultado.data.error);

    setAgenteSelecionado({
      label: '',
      value: '',
    });

    props.listar();
  };

  const excluirEquipe = async () => {
    let semEquipe = 0;
    props.equipe.forEach(t => {
      if (t.ativo === true) {
        semEquipe++;
      }
    });

    if (semEquipe === 1) {
      toast.error('Precisa conter pelo menos 1 autuado');
      return;
    }

    setLoading(true);

    const resutado = await api.put(
      `/altera-agentes-frequencia/${modalRemoveEquipe}`,
      {
        ativo: false,
      }
    );
    if (resutado.data.ok) {
      toast.success(resutado.data.ok);
    } else {
      toast.error(resutado.data.error);
    }

    setLoading(false);
    setModalRemoveEquipe(null);

    props.listar();
  };
  // // SELECT QUE PREENCHE AUTUADOS
  const PreencheSelectAgentes = async () => {
    const select = [];
    setLoadingListar(true);

    const result = await api.get('/busca-usuarios-por-ala', {
      params: { abordagem_id: props.abordagem_id },
    });

    // const result = await api.get(
    //   `/sgo/busca-agentes-data/${props.abordagem_id}`,
    //   {
    //     params: {
    //       gerencia: props.gerencia,
    //       data: props.abordagem_data,
    //     },
    //   }
    // );

    result.data.forEach(agente => {
      select.push({
        label: agente.nome_social,
        value: agente.id,
      });
    });

    setSelectAgentes(select);
    setLoadingListar(false);
  };
  useEffect(() => {
    if (modalEquipe === true) {
      PreencheSelectAgentes();
    }
  }, [modalEquipe]);

  return (
    <>
      <MDBRow
        center
        className="font-weight-bolder"
        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
      >
        Agentes Responsáveis:
      </MDBRow>

      {props.equipe.map(agente =>
        agente.ativo ? (
          <div
            // style={{
            //   backgroundColor: 'rgba(0,0,0,0.08)',
            //   borderRadius: '10px',
            // }}
            className="mt-2"
          >
            <MDBRow className="p-2">
              <MDBCol>
                Nome: {agente.usuario.nome} {agente.usuario.sobrenome}
              </MDBCol>
              <MDBCol>Nome Social: {agente.usuario.nome_social}</MDBCol>
              <MDBCol className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                <div
                  style={{ cursor: 'pointer' }}
                  className="danger-text "
                  onClick={() => {
                    setModalRemoveEquipe(agente.id);
                    // setIdDelecaoEquipe(index);
                    // deletarVeiculo(index);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </div>
              </MDBCol>
            </MDBRow>
          </div>
        ) : (
          ''
        )
      )}
      <MDBRow center>
        <MDBBtn
          color="mdb-color"
          onClick={() => {
            setModalEquipe(true);
          }}
        >
          Adicionar Agente
        </MDBBtn>
      </MDBRow>
      {/* MODAL PARA CRIACAO DE EQUIPE */}
      <MDBModal
        isOpen={modalEquipe === true}
        toggle={() => {
          setModalEquipe(false);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalEquipe(false);
          }}
        >
          Inserir Agentes
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" middle>
              <Select
                placeholder="Agentes"
                options={selectAgentes}
                value={
                  agenteSelecionado.value === '' ? null : agenteSelecionado
                }
                onChange={e => {
                  setAgenteSelecionado(e);
                }}
              />
            </MDBCol>
          </MDBRow>

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalEquipe(false);
            }}
          >
            Fechar
          </MDBBtn>
          <MDBBtn
            disabled={loading}
            onClick={() => {
              addEquipe();
            }}
            color="success"
          >
            {loading ? 'Cadastrando...' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR EQUIPE */}
      <MDBModal
        isOpen={modalRemoveEquipe !== null}
        toggle={() => {
          setModalRemoveEquipe(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalRemoveEquipe(null);
          }}
        >
          Deletar Agente
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse agente da sua equipe?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalRemoveEquipe(null);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            disabled={loading}
            onClick={() => {
              excluirEquipe(modalRemoveEquipe);
              // setModalDeleteEquipe(!modalDeleteEquipe);
            }}
            color="warning"
          >
            {loading ? 'Deletando...' : 'Deletar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
