import React from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBRow,
  MDBIcon,
} from 'mdbreact';
import { Link } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import img from '../../assets/images.jpg';

import ocorrenciaImg from '../../assets/ocorrencia.jpg';
import frotaImg from '../../assets/frota.jpg';

import parteDiaria from '../../assets/partediaria.png';

// import { Link } from '@material-ui/core';
const Operador = /* props */ () => {
  return (
    <>
      <NavBar />
      <MDBContainer fluid>
        {/* espacamento  */}

        <MDBRow className="d-flex justify-content-center">
          <MDBCard
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 mt-md-0 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">ESCALA DE SERVIÇO</MDBCardTitle>
            <MDBCardText className="grey-text text-left">
              Escala e trocas de serviços.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around  pt-3 mt-3"
            >
              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Estatísticas
                </span>
                <MDBIcon icon="chart-pie" size="2x" />
              </Link>
              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${frotaImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 mt-3 mt-md-0 col-md-3 mr-2 ml-2 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">VIATURA</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Controle dos veículos empregados.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3 "
            >
              <Link
                to="/add-ocorrencia"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Assumir Viatura
                </span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>
              <Link
                to="/frota"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Relatórios
                </span>

                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${parteDiaria})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-3 mt-3 mt-md-0 mr-2 ml-2  blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">PATRIMÔNIOS</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Registrar a posse de patrimônios.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3 "
            >
              <Link
                to="/add-ocorrencia"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Assumir patrimônio
                </span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>
              <Link
                to="/patrimonio"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>

                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
        </MDBRow>

        <MDBRow className="d-flex justify-content-center">
          <MDBCard
            style={{
              backgroundImage: `url(${ocorrenciaImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">OCORRÊNCIAS</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Adicionar novas ocorrências, editar e extrair estatísticas de
              ocorrências registradas.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3"
            >
              <Link
                to="/add-ocorrencia"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>

              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${ocorrenciaImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">SARQUE</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Sarque de pessoas suspeitas.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3"
            >
              <Link
                to="/add-ocorrencia"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>

              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${ocorrenciaImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">LEVANTAMENTO VEICULAR</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Sarque de pessoas suspeitas.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3"
            >
              <Link
                to="/add-ocorrencia"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>

              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
        </MDBRow>

        <br />
        <br />
      </MDBContainer>
    </>
  );
};

export default Operador;
