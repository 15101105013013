import React, { useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import InputMask from 'react-input-mask';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBCardHeader,
} from 'mdbreact';
import { isPast, getYear } from 'date-fns';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { getData } from '../../../services/auth';

export default function App() {
  const history = useHistory();

  const [addCollapse, setAddCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const [novoAssunto, setNovoAssunto] = useState('');

  const [assuntos, setAssuntos] = useState([]);

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');
  // INSERE NOVO ASSUNTO
  const [renavam, setRenavam] = useState('');

  const atividadeInicioRef = useRef(null);
  const tanqueRef = useRef(null);
  const kmInicialRef = useRef('');
  const cambioAutomaticoRef = useRef(false);
  const placaRef = useRef('');
  const [placa, setPlaca] = useState('');
  const [chassi, setChassi] = useState('');

  const [tipo, setTipo] = useState('');
  const capacidadePassageirosRef = useRef('');

  const [fabricante, setFabricante] = useState('');
  const anoFabricacaoRef = useRef('');
  const anoModeloRef = useRef('');

  const [modelo, setModelo] = useState('');
  const [versao, setVersao] = useState('');
  const [cor, setCor] = useState('');

  const corRef = useRef('');
  const fotoDocumentoRef = useRef(null);
  const fkContratoId = useRef(null);

  const [caracterizada, setCaracterizada] = useState(false);
  const [giroflex, setGiroflex] = useState(false);
  const [sirene, setSirene] = useState(false);
  const [selectContratos, setSelectContratos] = useState([]);
  const [cambio, setCambio] = useState(false);

  const [listaTipoCombustiveis, setListaTipoCombustiveis] = useState([]);
  const [combustiveisSelecionados, setCombustiveisSelecionados] = useState([]);

  const [listaCategoriasHabilitacao, setListaCategoriasHabilitacao] = useState(
    []
  );
  const [
    categoriasPermitidasSelecionadas,
    setCategoriasPermitidasSelecionadas,
  ] = useState([]);

  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState([]);

  const buscaContratos = () => {
    const select = [];
    api.get('/contratos').then(result => {
      result.data.forEach(res =>
        select.push({
          text: `${res.numero_contrato} - ${res.empresa.nome}`,
          value: res.id,
        })
      );
    });

    setSelectContratos(select);
  };

  const buscaTiposCombustiveis = () => {
    api.get('/tipo-combustiveis').then(result => {
      const select = [];
      result.data.forEach(element => {
        select.push({ label: element.tipo_combustivel, value: element.id });
      });
      setListaTipoCombustiveis(select);
    });
  };

  const buscaCategoriasHabilitacao = () => {
    api.get('/tipo-habilitacao').then(result => {
      const lista = [];
      result.data.forEach(element => {
        lista.push({ label: element.categoria, value: element.id });
      });
      setListaCategoriasHabilitacao(lista);
    });
  };
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
  };

  const criarVtr = () => {
    if (fkContratoId.current === null || fkContratoId.current === undefined) {
      toast.error('Selecione o Contrato!');
      return;
    }

    if (atividadeInicioRef.current === null) {
      toast.error('Selecione uma data valida!');
      return;
    }
    if (isPast(new Date(atividadeInicioRef.current)) === false) {
      toast.error('Data futura invalida!');
      return;
    }
    if (placa.length !== 7) {
      toast.error('Placa invalida!');
      return;
    }
    if (
      Number(anoFabricacaoRef.current) > getYear(Date.now()) ||
      Number(anoFabricacaoRef.current) < getYear(Date.now()) - 20
    ) {
      toast.error('Ano de fabricação inválido');
      return;
    }
    if (
      Number(anoModeloRef.current) > getYear(Date.now()) + 1 ||
      Number(anoModeloRef.current) < getYear(Date.now()) - 20
    ) {
      toast.error('Modelo do ano inválido');
      return;
    }
    if (renavam.length < 9 || renavam.length > 11) {
      toast.error('Renavam inválido');
      return;
    }
    if (chassi.length !== 17) {
      toast.error('Chassi inválido');
      return;
    }

    if (fabricante.length > 50 || fabricante.length < 2) {
      toast.error('Campo fabricante inválido');
      return;
    }
    if (modelo.length > 25 || modelo.length < 2) {
      toast.error('Campo modelo inválido');
      return;
    }
    if (versao.length > 15 || versao.length < 2) {
      toast.error('Campo versao inválido');
      return;
    }
    if (tipo.length > 20 || tipo.length < 2) {
      toast.error('Campo tipo de veículo inválido');
      return;
    }
    if (cor.length > 25 || cor.length < 2) {
      toast.error('Campo cor inválido');
      return;
    }

    if (tanqueRef.current > 600 || tanqueRef.current < 20) {
      toast.error('Capacidade do Tanque inválido');
      return;
    }
    if (
      capacidadePassageirosRef.current > 70 ||
      capacidadePassageirosRef.current < 1
    ) {
      toast.error('Capacidade de Passageiros inválida');
      return;
    }

    if (
      combustiveisSelecionados === null ||
      combustiveisSelecionados.length < 1
    ) {
      toast.error('Selecione pelo menos 1 tipo de combustível');
      return;
    }

    if (
      categoriasPermitidasSelecionadas === null ||
      categoriasPermitidasSelecionadas.length < 1
    ) {
      toast.error('Selecione os Tipos de habilitação');
      return;
    }

    const veiculos_tipos_combustiveis = [];
    combustiveisSelecionados.forEach(res =>
      veiculos_tipos_combustiveis.push({ fk_tipos_combustiveis_id: res.value })
    );

    const veiculos_tipos_habilitacao = [];
    categoriasPermitidasSelecionadas.forEach(res =>
      veiculos_tipos_habilitacao.push({ fk_tipos_habilitacao_id: res.value })
    );

    api
      .post('/cria-vtr', {
        atividade_inicio: atividadeInicioRef.current,
        capacidade_tanque: tanqueRef.current,
        quilometragem_inicial: kmInicialRef.current,
        cambio_automatico: cambio,
        placa: placa,
        renavam: renavam,
        chassi: chassi,
        tipo_veiculo: tipo,
        capacidade_passageiros: capacidadePassageirosRef.current,
        fabricante: fabricante,
        ano_fabricacao: anoFabricacaoRef.current,
        ano_modelo: anoModeloRef.current,
        modelo: modelo,
        versao: versao,
        cor: cor,
        fk_contratos_id: fkContratoId.current,

        gerencia_id: gerenciaSelecionada.value,

        veiculos_caracteristicas: {
          giroflex: giroflex,
          sirene: sirene,
          adesivado: caracterizada,
        },

        veiculos_tipos_combustiveis,
        veiculos_tipos_habilitacao,
        // outras tabelas
        // combustiveis: combustiveisSelecionados,
        // categorias_permitadas: categoriasPermitidasSelecionadas,
      })
      .then(result => {
        if (result.data.erro) {
          toast.error(result.data.erro);
          return;
        }
        toast.success(result.data.ok);
        history.push('/frota');
      });
  };

  useEffect(() => {
    // buscaAssuntos();
    buscaContratos();
    buscaTiposCombustiveis();
    buscaCategoriasHabilitacao();
    carregaGerencias();
  }, []);

  return (
    <NavBar>
      <MDBContainer>
        <h3 className="h3-responsive text-center font-weight-bolder">
          Adicionar Veículo
        </h3>

        <MDBRow>
          <MDBCol col="12">
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol col="12" md="6" middle>
                    <MDBSelect
                      outline
                      required
                      label="Empresa contratada"
                      options={selectContratos}
                      getValue={e => (fkContratoId.current = e[0])}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="6">
                    <MDBInput
                      label="Data Início"
                      outline
                      type="date"
                      onChange={e =>
                        (atividadeInicioRef.current = e.target.value)
                      }
                      // onChange={e => this.setState({ km: e.target.value })}
                      // value={this.state.km}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <br />
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol col="12" md="3">
                    {/* <MDBInput
                      label="Placa"
                      outline
                      type="text"
                      onChange={e => (placaRef.current = e.target.value)}
                    /> */}
                    <small>Placa</small>
                    <InputMask
                      // style={{
                      // border: 0,
                      // borderBottom: '1px solid #ced4da',
                      // fontSize: '1.25rem',
                      // }}
                      className="form-control form-control-md md-outline"
                      type="text"
                      placeholder="Placa"
                      onChange={
                        e => setPlaca(e.target.value.toUpperCase())
                        // (placaRef.current = e.target.value.toUpperCase())
                      }
                      value={placa}
                      mask="aaa9*99"
                      maskChar=""
                    />
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    <small>Ano Fabricação</small>
                    <InputMask
                      style={{
                        // border: 0,
                        borderBottom: '1px solid #ced4da',
                        // fontSize: '1.25rem',
                      }}
                      className="form-control form-control-md md-outline"
                      type="text"
                      placeholder="YYYY"
                      onChange={e =>
                        (anoFabricacaoRef.current = e.target.value)
                      }
                      mask="9999"
                      maskChar=""
                    />
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    <small>Ano Modelo</small>
                    <InputMask
                      style={{
                        // border: 0,
                        borderBottom: '1px solid #ced4da',
                        // fontSize: '1.25rem',
                      }}
                      className="form-control form-control-md md-outline"
                      type="text"
                      placeholder="YYYY"
                      onChange={e => (anoModeloRef.current = e.target.value)}
                      mask="9999"
                      maskChar=""
                    />
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    <MDBInput
                      label="KM de entrega"
                      outline
                      type="number"
                      onChange={e => (kmInicialRef.current = e.target.value)}
                      // onChange={e => this.setState({ km: e.target.value })}
                      // value={this.state.km}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol col="12" md="6">
                    <MDBInput
                      label="Renavam (9 a 11 caracteres)"
                      outline
                      type="number"
                      maxLength="11"
                      onChange={e => setRenavam(e.target.value.toUpperCase())}
                      value={renavam}
                      // onChange={e => this.setState({ renavam: e.target.value })}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="6">
                    <MDBInput
                      label="Chassis"
                      outline
                      type="text"
                      maxLength="17"
                      onChange={e => setChassi(e.target.value.toUpperCase())}
                      value={chassi}
                      // onChange={e => this.setState({ modelo: e.target.value })}
                      // value={this.state.modelo}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <br />
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol col="12" md="4">
                    <MDBInput
                      label="Fabricante"
                      outline
                      type="text"
                      maxLength="50"
                      onChange={e =>
                        setFabricante(e.target.value.toUpperCase())
                      }
                      // onChange={e => this.setState({ km: e.target.value })}
                      value={fabricante}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="4">
                    <MDBInput
                      label="Modelo"
                      outline
                      type="text"
                      maxLength="25"
                      onChange={e => setModelo(e.target.value.toUpperCase())}
                      // onChange={e => this.setState({ modelo: e.target.value })}
                      value={modelo}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="4">
                    <MDBInput
                      label="Versão"
                      outline
                      type="text"
                      maxLength="15"
                      onChange={e => setVersao(e.target.value.toUpperCase())}
                      // onChange={e => this.setState({ km: e.target.value })}
                      value={versao}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol col="12" md="3">
                    <MDBInput
                      label="Tipo Veículo"
                      outline
                      type="text"
                      maxLength="20"
                      onChange={e => setTipo(e.target.value.toUpperCase())}
                      // onChange={e => this.setState({ km: e.target.value })}
                      value={tipo}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    <MDBInput
                      label="Cor"
                      outline
                      type="text"
                      maxLength="25"
                      onChange={e => setCor(e.target.value.toUpperCase())}
                      // onChange={e => this.setState({ cor: e.target.value })}
                      value={cor}
                    />
                  </MDBCol>

                  <MDBCol col="12" md="3">
                    <MDBInput
                      label="Quantidade tanque"
                      outline
                      type="number"
                      onChange={e => (tanqueRef.current = e.target.value)}
                      // onChange={e => this.setState({ km: e.target.value })}
                      // value={this.state.km}
                    />
                  </MDBCol>
                  <MDBCol col="12" md="3">
                    <MDBInput
                      label="Capacidade de passageiros"
                      outline
                      type="number"
                      onChange={e =>
                        (capacidadePassageirosRef.current = e.target.value)
                      }
                      // onChange={e => this.setState({ km: e.target.value })}
                      // value={this.state.km}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <br />
            <MDBCard>
              <MDBCardBody>
                <MDBRow center>
                  <MDBCol middle size="12" md="3">
                    Tipo de Combustiveis:
                  </MDBCol>
                  <MDBCol size="12" md="6">
                    <Select
                      isMulti
                      options={listaTipoCombustiveis}
                      onChange={e => {
                        setCombustiveisSelecionados(e);
                      }}
                      // className="basic-multi-select"
                      // classNamePrefix="select"
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow center>
                  <MDBCol middle size="12" md="3">
                    Categorias Permitidas:
                  </MDBCol>
                  <MDBCol size="12" md="6">
                    <Select
                      isMulti
                      options={listaCategoriasHabilitacao}
                      onChange={e => setCategoriasPermitidasSelecionadas(e)}
                      // className="basic-multi-select"
                      // classNamePrefix="select"
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow center>
                  <MDBCol middle size="12" md="3">
                    Selecione a gerência:
                  </MDBCol>
                  <MDBCol size="12" md="6">
                    <Select
                      options={listaGerencias}
                      onChange={e => setGerenciaSelecionada(e)}
                      // className="basic-multi-select"
                      // classNamePrefix="select"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <br />
            <MDBCard>
              <MDBCardBody>
                <MDBRow around>
                  <MDBCol size="12" md="3" className="text-center">
                    <h5>Cambio</h5>
                    <MDBSwitch
                      getValue={e => setCambio(!cambio)}
                      checked={cambio}
                      labelLeft="Manual"
                      labelRight="Automatico"

                      // defaultChecked
                      // disabled
                      // id="flexSwitchCheckCheckedDisabled"
                      // label="Disabled checked switch checkbox input"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="3" className="text-center">
                    <h5>Caracterizada</h5>
                    <MDBSwitch
                      getValue={e => setCaracterizada(!caracterizada)}
                      checked={caracterizada}
                      labelLeft="Não"
                      labelRight="Sim"

                      // defaultChecked
                      // disabled
                      // id="flexSwitchCheckCheckedDisabled"
                      // label="Disabled checked switch checkbox input"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="3" className="text-center">
                    <h5>Giroflex</h5>
                    <MDBSwitch
                      labelLeft="Não"
                      labelRight="Sim"
                      getValue={e => setGiroflex(!giroflex)}
                      checked={giroflex}
                      // defaultChecked
                      // disabled
                      // id="flexSwitchCheckCheckedDisabled"
                      // label="Disabled checked switch checkbox input"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="3" className="text-center">
                    <h5>Sirene</h5>
                    <MDBSwitch
                      // onClick={e => {
                      //   setSirene(!sirene);
                      // }}
                      getValue={e => setSirene(!sirene)}
                      checked={sirene}
                      labelLeft="Não"
                      labelRight="Sim"

                      // defaultChecked
                      // disabled
                      // id="flexSwitchCheckCheckedDisabled"
                      // label="Disabled checked switch checkbox input"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <br />
          </MDBCol>

          {/* <MDBCol className="mt-4" col="12" md="4">
            <h6> Insira a imagem do CRLV </h6>
            <input type="file" />
            <img
              className="img-fluid mt-3"
              src={Doc}
              alt="documento do veiculo"
            />
          </MDBCol> */}
        </MDBRow>
        <MDBRow center className="danger-text font-weight-bolder">
          OBS: Não esqueça de fazer o primeiro BDT desta viatura após o
          cadastro!
        </MDBRow>
      </MDBContainer>

      <MDBContainer className="d-flex justify-content-center mt-4">
        <MDBBtn
          onClick={() => criarVtr()}
          type="submit"
          className=" mb-4"
          color="success"
        >
          {/* <MDBIcon icon="user-plus" size="2x" /> */}
          Cadastrar Veículo
        </MDBBtn>
      </MDBContainer>
    </NavBar>
  );
}
