import React, { useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import MaskReal from '../../../../../components/MaskReal';
import { getData, getPerfil } from '../../../../../services/auth';
import Loading from '../../../../../components/Loading';
// import { Link } from 'react-router-dom';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBSwitch,
  MDBIcon,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBTypography,
  MDBBox,
} from 'mdbreact';
// import NavBar from '../../../components/NavBar';
import api from '../../../../../services/api';

import { toast } from 'react-toastify';
import { format } from 'date-fns';

// import { Container } from './styles';

export default function Add(props) {
  // const [loadingBdtAberto, setLoadingBdtAberto] = useState(false);
  // const [loadingBdtHistorico, setloadingBdtHistorico] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState(false);
  const [bdtsAbertos, setBdtsAbertos] = useState([]);
  const [bdtSelecionado, setBdtSelecionado] = useState(null);
  const [bdt, setBdt] = useState({});

  const [modalDescricao, setModalDescricao] = useState(null);
  const [deleteDescricao, setDeleteDescricao] = useState(null);
  const [tipoBdtsOcorrencias, setTipoBdtsOcorrencias] = useState([]);
  const [
    tipoBdtsOcorrenciasSelecionado,
    setTipoBdtsOcorrenciasSelecionado,
  ] = useState(null);
  const [
    tipoBdtsOcorrenciasDescricao,
    setTipoBdtsOcorrenciasDescricao,
  ] = useState('');

  const [bdtLoading, setBdtLoading] = useState(false);
  const [listaAbertosLoading, setListaAbertosLoading] = useState(false);

  const [toogleAlteraKm, setToogleAlteraKm] = useState(null);
  const [alteraKmInicial, setAlteraKmInicial] = useState(0);

  const [loadingDeletaAbastecimento, setLoadingDeletaAbastecimento] = useState(
    false
  );
  const [loadingDeletaOcorrencia, setLoadingDeletaOcorrencia] = useState(false);
  const [verificaManutencao, setVerificaManutencao] = useState(false);
  const bdtAberto = async () => {
    setListaAbertosLoading(true);
    const result = await api.get('/bdts-listar', {
      params: {
        fk_usuario_id: getPerfil().id,
        dispensaAberta: true,
      },
    });
    setListaAbertosLoading(false);
    setToogleAlteraKm(false);
    setBdtsAbertos(result.data);
    // if (result.data === null) {
    //   setBdt({});
    //   return;
    // } else {
    //   setBdt(result.data);
    //   buscaOcorrencias(result.data.id);
    //   buscaAbastecimentos(result.data.id);
    // }
  };

  const buscaBdt = async () => {
    setBdtLoading(true);
    const result = await api.get('/bdts', {
      params: {
        id: bdtSelecionado,
      },
    });

    setAlteraKmInicial(bdt?.quilometragem_inicio);
    setBdt(result.data);
    buscaManutencao(result.data.veiculo.id);
    setBdtLoading(false);
  };

  //gerar novo
  const [excessaoVtrPosto, setexcessaoVtrPosto] = useState(false);
  const [listSelectVtr, setListSelectVtr] = useState([]);
  const [vtrSelected, setVtrSelected] = useState(null);
  const [kmInicial, setKmInicial] = useState('');
  const [kmMinimo, setkmMinimo] = useState('');
  const [excessaoJustificativa, setExcessaoJustificativa] = useState('');

  const [loadingGerar, setLoadingGerar] = useState(false);
  const [bdtList, setBdtList] = useState([]);

  const { search } = useLocation();
  const placaTag = new URLSearchParams(search).get('placaid');

  const geraNovoBdt = async () => {
    try {
      if (vtrSelected === null) {
        toast.error('Preencha VTR');
        return;
      }

      if (kmInicial === '' || kmInicial < 0) {
        toast.error('Valor Negativo Invalido');
        return;
      }

      if (kmInicial < kmMinimo) {
        toast.error('KM inicial deve ser maior que o KM anterior');
        return;
      }
      if (excessaoVtrPosto && excessaoJustificativa.length === 0) {
        toast.error('Campo justificativa não pode ser vazio');
        return;
      }
      if (excessaoJustificativa.length > 200) {
        toast.error('Campo justificativa com valor maximo de 200 caracteres');
        return;
      }

      const corpoEnvio = {};
      corpoEnvio.excessao = excessaoVtrPosto;
      corpoEnvio.vtr_id = vtrSelected.value;
      corpoEnvio.km_inicial = kmInicial;
      if (corpoEnvio.excessao === true) {
        corpoEnvio.excessao_justificativa = excessaoJustificativa;
      }
      setLoadingGerar(true);
      const result = await api.post('/frota-add-bdt', corpoEnvio);

      toast.success('BDT Cadastrado');
      // props.bdtAberto();
      setLoadingGerar(false);
      bdtAberto();
      setVtrSelected(null);
      setKmInicial('');
      setexcessaoVtrPosto(false);
      setExcessaoJustificativa('');
    } catch (error) {
      setLoadingGerar(false);
      toast.error('Não Criado em contato');
    }
  };

  const listarVtr = async () => {
    setLoadingSelect(true);
    const result = await api.get('/frota-veiculos', {
      params: {
        excessaoVtrPosto: excessaoVtrPosto,
      },
    });
    // console.log(result.data);
    const selectPlacas = [];

    const verificarManutencao = vtr => {
      console.log(vtr);
      if (vtr?.manutencoes.length > 0) {
        if (
          vtr.manutencoes[0].data_iniciado !== null &&
          vtr.manutencoes[0].data_executado === null
        ) {
          return;
        }
      }
      verificaStatus(vtr);
    };

    const verificaStatus = vtr => {
      if (vtr.status_veiculos.length > 0) {
        if (vtr.status_veiculos[0].tipos_status_veiculo.utilizavel === false) {
          return;
        }
      }

      selectPlacas.push({ label: vtr.placa, value: vtr.id });
    };

    result.data.forEach(vtr => {
      verificarManutencao(vtr);
    });
    setListSelectVtr(selectPlacas);

    setLoadingSelect(false);

    if (placaTag !== null && placaTag !== '') {
      const buscaSelect = selectPlacas.find(
        placa => placa.value === Number(placaTag)
      );

      if (buscaSelect === undefined) {
        setexcessaoVtrPosto(true);
        setLoadingSelect(true);
        const result = await api.get('/frota-veiculos', {
          params: {
            excessaoVtrPosto: true,
          },
        });
        const selectPlacas = [];

        result.data.forEach(vtr => {
          selectPlacas.push({ label: vtr.placa, value: vtr.id });
        });
        setListSelectVtr(selectPlacas);

        setLoadingSelect(false);
        const buscaSelectExcessao = selectPlacas.find(
          placa => placa.value === Number(placaTag)
        );

        if (buscaSelectExcessao === undefined) {
          toast.error('Vtr Invalida');
        } else {
          setVtrSelected(buscaSelectExcessao);
        }
      } else {
        setVtrSelected(buscaSelect);
      }
    }
  };

  const buscaUltimoKm = async () => {
    const result = await api.get('/busca-vtr', {
      params: {
        veiculo_id: vtrSelected.value,
      },
    });

    if (result.data === null) {
      setkmMinimo(0);
      setKmInicial(0);
      return;
    }
    if (result.data.quilometragem_fim !== null) {
      setkmMinimo(result.data.quilometragem_fim);
      setKmInicial(result.data.quilometragem_fim);
    } else {
      setkmMinimo(result.data.quilometragem_inicio);
      setKmInicial(result.data.quilometragem_inicio);
    }
  };

  // OCORRENCIAS
  const listTiposBdtsOcorrencias = async () => {
    const select = [];
    const result = await api.get('/tipo-bdts-ocorrencias');
    result.data.forEach(comb => {
      select.push({ label: comb.tipo_ocorrencia, value: comb.id });
    });
    setTipoBdtsOcorrencias(select);
  };

  const novaDescricao = async () => {
    if (tipoBdtsOcorrenciasSelecionado === null) {
      toast.error('Preencha Tipo de Descrição');

      return;
    }

    if (tipoBdtsOcorrenciasDescricao === '') {
      toast.error('Preencha Descrição');
      return;
    }
    setNovaDescricaoLoading(true);
    const result = await api.post('/bdts-ocorrencias', {
      descricao: tipoBdtsOcorrenciasDescricao,
      fk_bdts_id: bdt.id,
      fk_tipo_bdts_ocorrencias_id: tipoBdtsOcorrenciasSelecionado.value,
    });

    if (result.data.erro) {
      bdtAberto();
      setBdtSelecionado(null);
      toast.error(result.data.erro);
      return;
    }
    toast.success('Descrição Criada com sucesso!');
    setModalDescricao(null);
    setTipoBdtsOcorrenciasDescricao('');
    setTipoBdtsOcorrenciasSelecionado(null);
    // buscaOcorrencias(bdt.id);
    setNovaDescricaoLoading(false);
    buscaBdt(bdtSelecionado);
  };

  const deletarAbastecimento = async abastecimento_id => {
    setLoadingDeletaAbastecimento(true);
    try {
      const result = await api.delete(
        `/bdts-deleta-abastecimento/${abastecimento_id}`
      );
      if (result.data.erro) {
        toast.error(result.data.erro);
      }
      if (result.data.ok) {
        toast.success(result.data.ok);
        buscaBdt(bdtSelecionado);
      }
    } catch (error) {
      toast.error('Erro interno, informe a ADM');
    }
    setLoadingDeletaAbastecimento(false);
  };

  const deletarDescricao = async ocorrencia_id => {
    setLoadingDeletaOcorrencia(true);
    try {
      const result = await api.delete(
        `/bdts-deleta-descricao/${ocorrencia_id}`
      );
      if (result.data.erro) {
        toast.error(result.data.erro);
      }
      if (result.data.ok) {
        toast.success(result.data.ok);
        buscaBdt(bdtSelecionado);
      }
    } catch (error) {
      toast.error('Erro interno, informe a ADM');
    }
    setLoadingDeletaOcorrencia(false);
  };

  // ABASTECIMENTO
  const [novaDescricaoLoading, setNovaDescricaoLoading] = useState(false);
  const [modalAbastecimento, setModalAbastecimento] = useState(null);
  const [tipoCombustiveis, setTipoCombustiveis] = useState([]);
  const [
    tipoCombustiveisSelecionado,
    setTipoCombustiveisSelecionado,
  ] = useState(null);

  const [abastecimentoKm, setAbastecimentoKm] = useState(0);
  const [abastecimentoLitros, setAbastecimentoLitros] = useState(0);
  const [abastecimentoValor, setAbastecimentoValor] = useState('');
  const [novoAbastecimentoLoading, setNovoAbastecimentoLoading] = useState(
    false
  );

  const [deleteAbastecimento, setDeleteAbastecimento] = useState(null);

  const [manutencaoAberta, setManutencaoAberta] = useState(null);

  const [confirmaManutencao, setConfirmaManutencao] = useState(false);
  // const deleteAbastecimento = abasId => {};
  // const [bdtsAbastecimento, setBdtsAbastecimento] = useState([]);
  // const buscaAbastecimentos = async idBdts => {
  //   const result = await api.get('/bdts-abastecimentos', {
  //     params: {
  //       fk_bdts_id: idBdts,
  //     },
  //   });

  //   setBdtsAbastecimento(result.data);
  //   // setBdtsOcorrencias(result.data);
  // };
  const updateKmInicial = async id => {
    const result = await api.put(`/bdts/${id}`, {
      quilometragem_inicio: alteraKmInicial,
    });

    if (result.data.ok) {
      toast.success(result.data.ok);
    }
    if (result.data.erro) {
      toast.error(result.data.erro);
    }
    bdtAberto();
    buscaBdt();
  };

  const updateManutencao = async idManutencao => {
    const result = await api.put(`/manutencoes/${idManutencao}`, {
      data_iniciado: new Date(),
    });
    setConfirmaManutencao(false);
    buscaManutencao(bdt.veiculo.id);
  };

  const listTiposCombustiveis = async () => {
    const select = [];
    const result = await api.get('/tipo-combustiveis');
    result.data.forEach(comb => {
      select.push({ label: comb.tipo_combustivel, value: comb.id });
    });
    setTipoCombustiveis(select);
  };
  const buscaManutencao = async vrt_id => {
    const result = await api.get('/verifica-manutencao', {
      params: {
        fk_veiculos_id: vrt_id,
        manutencao_aberta: true,
      },
    });

    setManutencaoAberta(result.data);
  };
  const novoAbastecimento = async () => {
    if (tipoCombustiveisSelecionado === null) {
      toast.error('Preencha Tipo de Combustível');
      return;
    }

    if (abastecimentoKm <= 0) {
      toast.error('Preencha KM de abastecimento');
      return;
    }
    if (abastecimentoKm < bdt.quilometragem_inicio) {
      toast.error('Abastecimento menor do que KM inicial');
      return;
    }

    if (abastecimentoLitros <= 0) {
      toast.error('Preencha Litros adequadamente');
      return;
    }
    if (abastecimentoLitros > 90) {
      toast.error('Quantidade de litros excedida');
      return;
    }
    // if (abastecimentoLitros <= 90) {
    //   toast.error('Quantidade de litros excedida');
    //   return;
    // }

    if (abastecimentoValor <= 0) {
      toast.error('Preencha Valor abastecido');
      return;
    }

    const trocaPorPonto = abastecimentoValor.toString().replace(',', '.');
    setNovoAbastecimentoLoading(true);
    const result = await api.post('/bdts-abastecimentos', {
      quilometragem: abastecimentoKm,
      quantidade: abastecimentoLitros,
      valor_total: trocaPorPonto,
      fk_tipos_combustiveis_id: tipoCombustiveisSelecionado.value,
      fk_bdts_id: bdt.id,
    });

    setModalAbastecimento(null);

    setAbastecimentoKm(0);
    setAbastecimentoLitros(0);
    setAbastecimentoValor('');
    setTipoCombustiveisSelecionado(null);
    setNovoAbastecimentoLoading(false);
    // buscaAbastecimentos(bdtSelecionado);
    if (result.data.erro) {
      bdtAberto();
      setBdtSelecionado(null);
      toast.error(result.data.erro);
      return;
    }
    buscaBdt(bdtSelecionado);
  };

  const listaBdts = async () => {
    const result = await api.get('/bdts-listar', {
      params: {
        fk_usuario_id: getPerfil().id,
        dispensaAberta: false,
      },
    });

    setBdtList(result.data);
  };

  useEffect(() => {
    bdtAberto();
  }, []);

  useEffect(() => {
    if (bdtSelecionado === null) {
      return;
    }

    buscaBdt(bdtSelecionado);

    // buscaManutencao(result.data.veiculo.id);
    listTiposBdtsOcorrencias();
    listTiposCombustiveis();
  }, [bdtSelecionado]);
  // ADD NOVO BDT
  useEffect(() => {
    listaBdts();
    listarVtr();
    setVtrSelected(null);
    setExcessaoJustificativa('');
  }, [excessaoVtrPosto]);

  // PEGAR ULTIMO KM DA VTR
  useEffect(() => {
    if (vtrSelected !== null) {
      buscaUltimoKm();
    } else {
      setKmInicial('');
    }
  }, [vtrSelected]);

  useEffect(() => {
    setAlteraKmInicial(bdt?.quilometragem_inicio);
  }, [toogleAlteraKm]);
  return (
    <>
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle className="pb-2 text-center border-bottom border-light">
            BDT Online
          </MDBCardTitle>
          <MDBRow>
            <MDBCol size="12" md="4" middle className="text-center my-2">
              <label> Viatura de outro posto?</label>
              <MDBSwitch
                checked={excessaoVtrPosto}
                onChange={() => setexcessaoVtrPosto(!excessaoVtrPosto)}
                labelLeft="Não"
                labelRight="Sim"
              />
            </MDBCol>
            <MDBCol size="12" md="4" middle>
              <Select
                placeholder="Placa"
                isLoading={loadingSelect}
                options={listSelectVtr}
                onChange={e => {
                  setVtrSelected(e);
                  // e === null
                  //   ? setInputFilterFuncao()
                  //   : setInputFilterFuncao(e.value);
                }}
                value={vtrSelected}
              />
            </MDBCol>
            <MDBCol size="12" md="4" middle>
              <MDBInput
                outline
                type="number"
                label="KM inicial"
                // className={kmInicial < 0 ? ' is-invalid' : ''}
                onChange={e => {
                  setKmInicial(e.target.value);
                }}
                value={kmInicial}
              />
            </MDBCol>
          </MDBRow>
          {excessaoVtrPosto ? (
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  type="textarea"
                  rows="5"
                  label="Descreva o motivo"
                  maxLength="200"
                  onChange={e => setExcessaoJustificativa(e.target.value)}
                  className={
                    excessaoJustificativa.length > 200 ? ' is-invalid' : ''
                  }
                />
              </MDBCol>
            </MDBRow>
          ) : (
            ''
          )}

          <MDBRow center>
            {loadingGerar ? (
              <div className="d-flex align-items-center">
                <MDBSpinner />
                <div className="ml-2">Carregando</div>
              </div>
            ) : (
              <MDBBtn onClick={() => geraNovoBdt()}>Assumir VTR</MDBBtn>
            )}

            {/* setBdtAtivo(true) */}
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBRow center className="h4-responsive mt-3">
        <MDBCol size="12" md="2"></MDBCol>
        <MDBCol className="text-center">Meus Boletins abertos</MDBCol>

        <MDBCol
          className="cursor-pointer"
          size="12"
          md="2"
          onClick={() => bdtAberto()}
        >
          <MDBIcon icon="redo" /> Atualizar
        </MDBCol>
      </MDBRow>
      <MDBRow
        style={{ borderRadius: '5px' }}
        className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center black darken-1  d-none d-md-flex text-white font-weight-bolder"
      >
        <MDBCol size="12" md="2">
          Placa
        </MDBCol>
        <MDBCol size="12" md="3">
          Modelo
        </MDBCol>
        <MDBCol size="12" md="3">
          Data
        </MDBCol>
        <MDBCol size="12" md="2">
          KM inicial
        </MDBCol>
        <MDBCol size="12" md="2" />
      </MDBRow>
      {listaAbertosLoading ? (
        <Loading />
      ) : (
        <>
          {bdtsAbertos.map(e => (
            <MDBRow
              key={`20${e.id}`}
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 bg-white indigo-text font-weight-bold"
            >
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Placa:</span>
                <span>{e.veiculo.placa}</span>
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Modelo:</span>
                <span>{e.veiculo.modelo}</span>
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Data:</span>
                <span>
                  {format(new Date(e.data_apresentacao), 'dd/MM/yyyy HH:mm')}
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">KM inicial:</span>
                <span>{e.quilometragem_inicio}</span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-end">
                <div
                  onClick={() => setBdtSelecionado(e.id)}
                  className="text-danger cursor-pointer"
                >
                  <MDBIcon className="mr-1" far icon="edit" size="1x" />
                  <span>Editar</span>
                </div>
              </div>
            </MDBRow>
          ))}
        </>
      )}

      <MDBModal
        key={bdt.id}
        isOpen={bdtSelecionado !== null}
        toggle={() => setBdtSelecionado(null)}
        size="lg"
      >
        <MDBModalHeader toggle={() => setBdtSelecionado(null)}>
          BDT Em Andamento
        </MDBModalHeader>
        <MDBModalBody style={{ overflow: 'scroll' }}>
          <MDBContainer>
            {manutencaoAberta !== null ? (
              manutencaoAberta.data_executado === null &&
              manutencaoAberta.data_iniciado === null ? (
                <MDBTypography
                  key={`a${bdt.id}`}
                  blockquote
                  bqColor="primary"
                  className="font-weight-bold primary-text"
                >
                  <MDBBox tag="p" mb={0} className="bq-title">
                    ATENÇÃO
                  </MDBBox>
                  <MDBRow>
                    <MDBCol>
                      Existe uma manutenção programada para este veículo!
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <MDBCol>
                      Data de Agendamento:
                      {manutencaoAberta.data_agendado &&
                        format(
                          new Date(manutencaoAberta.data_agendado),
                          ' dd/MM/yyyy HH:mm'
                        )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBBtn
                      color="primary"
                      className="font-weight-bolder"
                      size="sm"
                      onClick={() => setConfirmaManutencao(true)}
                    >
                      Colocar em manutençao
                    </MDBBtn>
                  </MDBRow>
                  <MDBModal
                    toggle={() => {
                      setConfirmaManutencao(false);
                    }}
                    size="lg"
                    isOpen={confirmaManutencao === true}
                  >
                    <MDBModalHeader>
                      <MDBRow center>
                        <MDBCol className="red-text">
                          Colocar VTR em manutenção
                        </MDBCol>
                      </MDBRow>
                    </MDBModalHeader>
                    <MDBModalBody className="pb-2 red-text">
                      <MDBRow className="mb-3">
                        <MDBCol>
                          Tem certeza que deseja colocar esta viatura em
                          manutenção?
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-3">
                        <MDBCol>
                          A viatura só deve ser colocada em manutenção quando
                          chegar na oficina.
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mb-3">
                        <MDBCol>
                          A viatura será desabilitada, podendo deixar disponível
                          pela administração.
                        </MDBCol>
                      </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter>
                      <MDBBtn
                        onClick={() => setConfirmaManutencao(false)}
                        className="font-weight-bold"
                        color="danger"
                      >
                        Cancelar
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => updateManutencao(manutencaoAberta.id)}
                        className="font-weight-bold"
                        color="success"
                      >
                        Confirmar
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModal>
                </MDBTypography>
              ) : (
                <MDBTypography
                  key={`a${bdt.id}`}
                  blockquote
                  bqColor="danger"
                  className="font-weight-bold danger-text"
                >
                  <MDBBox tag="p" mb={0} className="bq-title">
                    ATENÇÃO
                  </MDBBox>
                  <MDBRow>
                    <MDBCol>Este Veículo está em manutenção</MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-2">
                    <MDBCol>
                      Data de Agendamento:
                      {manutencaoAberta.data_agendado &&
                        format(
                          new Date(manutencaoAberta.data_agendado),
                          ' dd/MM/yyyy HH:mm'
                        )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol>
                      Início da Manutenção:
                      {manutencaoAberta.data_iniciado &&
                        format(
                          new Date(manutencaoAberta.data_iniciado),
                          ' dd/MM/yyyy HH:mm'
                        )}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-4">
                    <small>
                      <i>
                        **Somente a administração pode retirar o veículo da
                        manutenção
                      </i>
                    </small>
                  </MDBRow>
                </MDBTypography>
              )
            ) : (
              ''
            )}

            <MDBRow>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start"
              >
                <span>GERÊNCIA:</span>

                <span className="text-danger font-weight-bolder mx-2 ">
                  {bdt?.veiculo?.veiculos_gerencia[0].gerencia.nome}
                </span>
              </MDBCol>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start"
              >
                <span>USUÁRIO: </span>
                <span className="text-danger font-weight-bolder mx-2">
                  {bdt?.usuario?.nome_social}
                </span>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start"
              >
                <span>PLACA:</span>
                <span className="text-danger font-weight-bolder mx-2">
                  {bdt?.veiculo?.placa}
                </span>
              </MDBCol>
            </MDBRow>

            <MDBRow between>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start"
              >
                <span>INÍCIO:</span>
                <span className="text-danger font-weight-bolder mx-2">
                  {bdt.data_apresentacao &&
                    format(
                      new Date(bdt?.data_apresentacao),
                      'dd/MM/yyyy HH:mm'
                    )}
                </span>
              </MDBCol>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start align-items-center"
              >
                <span>KM INICIAL:</span>
                <span className="text-danger font-weight-bolder mx-2">
                  {bdt?.quilometragem_inicio}
                </span>
                {!toogleAlteraKm && (
                  <MDBIcon
                    onClick={() => {
                      setToogleAlteraKm(true);
                    }}
                    className="black-text cursor-pointer"
                    icon="edit"
                  />
                )}
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex justify-content-between justify-content-md-start"
              ></MDBCol>
              <MDBCol
                size="12"
                md="6"
                className="mt-2 d-flex  justify-content-between justify-content-md-start"
              >
                {toogleAlteraKm && (
                  <div>
                    <input
                      type="number"
                      style={{
                        border: '1px solid grey',
                        borderRadius: '5px',
                      }}
                      value={alteraKmInicial}
                      onChange={e => {
                        setAlteraKmInicial(e.target.value);
                      }}
                    />
                    <MDBBtn onClick={() => updateKmInicial(bdt.id)} size="sm">
                      Salvar
                    </MDBBtn>
                  </div>
                )}
                {toogleAlteraKm && (
                  <MDBIcon
                    onClick={() => {
                      setToogleAlteraKm(false);
                    }}
                    className="black-text cursor-pointer"
                    icon="close"
                  />
                )}
              </MDBCol>
            </MDBRow>
            {console.log(
              manutencaoAberta?.data_iniciado !== null,
              manutencaoAberta?.data_executado !== null
            )}
            {bdtLoading ? (
              <Loading />
            ) : (
              <MDBRow center className="my-3">
                <MDBBtn
                  className="d-flex align-items-center justify-content-center"
                  size="md"
                  onClick={() => setModalDescricao(bdtSelecionado)}
                >
                  <MDBIcon far icon="plus-square" size="2x" />
                  <span className="ml-2">Alteração</span>
                </MDBBtn>
                {manutencaoAberta === null ? (
                  <MDBBtn
                    className="d-flex align-items-center justify-content-center"
                    size="md"
                    onClick={() => setModalAbastecimento(bdtSelecionado)}
                  >
                    <MDBIcon far icon="plus-square" size="2x" />
                    <span className="ml-2">Abastecimento</span>
                  </MDBBtn>
                ) : (
                  manutencaoAberta?.data_iniciado === null &&
                  manutencaoAberta?.data_executado === null && (
                    <MDBBtn
                      className="d-flex align-items-center justify-content-center"
                      size="md"
                      onClick={() => setModalAbastecimento(bdtSelecionado)}
                    >
                      <MDBIcon far icon="plus-square" size="2x" />
                      <span className="ml-2">Abastecimento</span>
                    </MDBBtn>
                  )
                )}
              </MDBRow>
            )}
          </MDBContainer>
          <br />
          <MDBRow>
            <MDBCol className="h4-responsive">Abastecimentos</MDBCol>
          </MDBRow>

          {bdt.abastecimentos?.map(abast => (
            <MDBContainer
              key={
                abast.id === undefined
                  ? parseInt(Math.random() * 20)
                  : `abast${abast.id}`
              }
              className="grey lighten-3 mb-2"
              style={{ borderRadius: '10px' }}
            >
              {deleteAbastecimento === abast.id ? (
                <MDBRow
                  between
                  className="grey white-text p-2 font-weight-bold"
                  style={{ borderRadius: '10px 10px 0px 0px' }}
                >
                  <MDBCol middle className="d-flex h3-responsive">
                    Deletar abastecimento?
                  </MDBCol>
                  {loadingDeletaAbastecimento ? (
                    <MDBCol className=" d-flex align-items-center justify-content-center justify-md-content-end">
                      <MDBSpinner />
                      <div className="ml-3 font-weight-bolder">Deletando</div>
                    </MDBCol>
                  ) : (
                    <>
                      <MDBCol className="text-right">
                        <MDBBtn
                          color="success"
                          size="sm"
                          onClick={() => {
                            deletarAbastecimento(abast.id);
                          }}
                        >
                          Sim
                        </MDBBtn>
                        <MDBBtn
                          color="danger"
                          size="sm"
                          onClick={() => setDeleteAbastecimento(null)}
                        >
                          Não
                        </MDBBtn>
                      </MDBCol>
                    </>
                  )}
                </MDBRow>
              ) : (
                <MDBRow end>
                  <small>
                    <div
                      className=" red accent-2 d-flex text-white align-items-center p-1 px-3 font-weight-bolder cursor-pointer "
                      style={{ borderRadius: '0px 15px' }}
                      onClick={() => setDeleteAbastecimento(abast.id)}
                    >
                      <MDBIcon fas icon="trash-alt" />
                      <div className="ml-2">Deletar</div>
                    </div>
                  </small>
                </MDBRow>
              )}

              <MDBRow between className="mt-2">
                <MDBCol>Data:</MDBCol>
                <MDBCol>
                  {format(
                    new Date(abast.data_abastecimento),
                    'dd/MM/yyyy HH:mm'
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow between className="mt-2">
                <MDBCol>Quilometragem:</MDBCol>
                <MDBCol>{abast?.quilometragem}</MDBCol>
              </MDBRow>
              <MDBRow between className="mt-2">
                <MDBCol>Quantidade:</MDBCol>
                <MDBCol>{abast?.quantidade} Litros</MDBCol>
              </MDBRow>
              <MDBRow between className="mt-2">
                <MDBCol>Valor:</MDBCol>
                <MDBCol>
                  R$ {abast?.valor_total.toString().replace('.', ',')}{' '}
                </MDBCol>
              </MDBRow>
              <MDBRow between className="mt-2">
                <MDBCol>Combustivel:</MDBCol>
                <MDBCol>{abast?.tipos_combustivei.tipo_combustivel}</MDBCol>
              </MDBRow>
              <br />
            </MDBContainer>
          ))}

          <br />
          <MDBRow>
            <MDBCol className="h4-responsive">Descrições</MDBCol>
          </MDBRow>

          {bdt.bdts_ocorrencias?.map(oco => (
            <>
              <MDBContainer
                key={`12${oco.id}`}
                center
                style={{ borderRadius: '10px 10px 0px 0px' }}
                className="mt-2 align-items-center text-white font-weight-bolder"
              >
                {deleteDescricao === oco.id ? (
                  <MDBRow
                    between
                    className="grey darken-2 white-text font-weight-bold"
                    style={{ borderRadius: '10px 10px 0px 0px' }}
                  >
                    <MDBCol middle className="d-flex h3-responsive">
                      Deletar descrição?
                    </MDBCol>
                    {loadingDeletaOcorrencia ? (
                      <MDBCol className=" d-flex align-items-center justify-content-end justify-md-content-end">
                        <MDBSpinner />
                        <div className="ml-3 font-weight-bolder">Deletando</div>
                      </MDBCol>
                    ) : (
                      <>
                        <MDBCol className="text-right">
                          <MDBBtn
                            onClick={() => {
                              deletarDescricao(oco.id);
                            }}
                            color="success"
                            size="sm"
                          >
                            Sim
                          </MDBBtn>
                          <MDBBtn
                            color="danger"
                            size="sm"
                            onClick={() => setDeleteDescricao(null)}
                          >
                            Não
                          </MDBBtn>
                        </MDBCol>
                      </>
                    )}
                  </MDBRow>
                ) : (
                  <>
                    <MDBRow
                      className="grey lighten-1"
                      between
                      style={{ borderRadius: '10px 10px 0px 0px' }}
                    >
                      <MDBCol middle className=" align-items-center black-text">
                        Data:{' '}
                        {format(new Date(oco.createdAt), 'dd/MM/yyyy HH:mm')}
                      </MDBCol>
                      <MDBCol>
                        <MDBRow end className="pb-3">
                          <small>
                            <div
                              className="red accent-2 d-flex text-white align-items-center p-1 px-3 font-weight-bolder cursor-pointer "
                              style={{ borderRadius: '0px 10px 0px 15px' }}
                              onClick={() => setDeleteDescricao(oco.id)}
                            >
                              <MDBIcon fas icon="trash-alt" />
                              <div className="ml-2">Deletar</div>
                            </div>
                          </small>
                        </MDBRow>
                      </MDBCol>
                    </MDBRow>
                  </>
                )}

                <MDBRow className="py-2 align-items-center grey lighten-3 text-danger font-weight-bolder">
                  <MDBCol className="font-weight-italic">
                    Tipo: {oco.tipo_bdts_ocorrencia.tipo_ocorrencia}
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  style={{ borderRadius: '0px 0px 10px 10px' }}
                  className="align-items-center grey lighten-3 text-dark py-2"
                >
                  <MDBCol> {oco.descricao}</MDBCol>
                </MDBRow>
              </MDBContainer>
            </>
          ))}

          <br />
          <br />
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="secondary" onClick={() => setBdtSelecionado(null)}>
            Fechar
          </MDBBtn>
          {/* <MDBBtn onClick={() => novaDescricao()} color="primary">
            Salvar
          </MDBBtn> */}
        </MDBModalFooter>
        <MDBModal
          isOpen={modalDescricao !== null}
          toggle={() => setModalDescricao(null)}
          size="lg"
        >
          <MDBModalHeader toggle={() => setModalDescricao(null)}>
            Criar nova Descrição
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size="8">
                <Select
                  options={tipoBdtsOcorrencias}
                  onChange={e => setTipoBdtsOcorrenciasSelecionado(e)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  onChange={e =>
                    setTipoBdtsOcorrenciasDescricao(e.target.value)
                  }
                  style={{ borderRadius: '5px' }}
                  type="textarea"
                  outline
                  rows="15"
                  maxLength="600"
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {novaDescricaoLoading === true ? (
              <Loading />
            ) : (
              <>
                <MDBBtn
                  color="secondary"
                  onClick={() => setModalDescricao(null)}
                >
                  Fechar
                </MDBBtn>
                <MDBBtn onClick={() => novaDescricao()} color="primary">
                  Salvar
                </MDBBtn>
              </>
            )}
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={modalAbastecimento !== null}
          toggle={() => setModalAbastecimento(null)}
          size="lg"
        >
          <MDBModalHeader toggle={() => setModalAbastecimento(null)}>
            Criar novo abastecimento
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol middle>
                <Select
                  placeholder="Tipo de Combustível"
                  isClearable="true"
                  options={tipoCombustiveis}
                  onChange={e => {
                    setTipoCombustiveisSelecionado(e);
                    // e === null
                    // ? setInputFilterFuncao()
                    // : setInputFilterFuncao(e.value);
                  }}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  outline
                  label="KM de Abastecimento"
                  type="number"
                  onChange={e => setAbastecimentoKm(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  outline
                  label="Litros"
                  type="number"
                  onChange={e => setAbastecimentoLitros(e.target.value)}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  outline
                  label="Valor"
                  onChange={e => {
                    setAbastecimentoValor(MaskReal(e.target.value));
                  }}
                  value={abastecimentoValor}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {novoAbastecimentoLoading === true ? (
              <Loading />
            ) : (
              <>
                <MDBBtn
                  color="secondary"
                  onClick={() => setModalAbastecimento(null)}
                >
                  Fechar
                </MDBBtn>
                <MDBBtn onClick={() => novoAbastecimento()} color="primary">
                  Salvar
                </MDBBtn>
              </>
            )}
          </MDBModalFooter>
        </MDBModal>
      </MDBModal>
    </>
  );
}
