import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { format, setSeconds } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Loading from '../../../components/Loading';
import { toast } from 'react-toastify';

// import { Container } from './styles';

export default function App() {
  let history = useHistory();
  const [totalVagas, setTotalVagas] = useState(0);
  const [loading, setLoading] = useState(false);

  const [tipoBusca, setTipoBusca] = useState({
    label: 'Nº da Vaga',
    value: 'numvaga',
  });

  const [inputValue, setInputValue] = useState('');

  const [descricaoVaga, setDescricaoVaga] = useState({});
  const [vagas, setVagas] = useState([]);
  const [listavagas, setListaVagas] = useState([]);
  const [modalEdit, setModalEdit] = useState(0);
  // const [qtdTotal, setQtdTotal] = useState(0);
  // const [page, setPage] = useState('1');

  const [modalCriar, setModalCriar] = useState(false);

  const [funcoesLista, setFuncoesLista] = useState([]);
  const [cargosLista, setCargosLista] = useState([]);

  const [inputCriaFuncao, setInputCriaFuncao] = useState();
  const [inputCriaCargo, setInputCriaCargo] = useState();

  const [inputFilterFuncao, setInputFilterFuncao] = useState();
  const [inputFilterCargo, setInputFilterCargo] = useState();

  const [vagaNumero, setVagaNumero] = useState('');

  const listarVagas = async () => {
    // setLoading(true);

    const result = await api.get('/lista-vagas', {
      params: {
        id: inputValue,
        funcao_id: inputFilterFuncao,
        cargo_id: inputFilterCargo,
        //   // valor: inputValue,
      },
    });
    // const result = await api.get('/lista-vagas', {
    //   params: {
    //     tipo: tipoBusca.value,
    //     valor: inputValue,
    //   },
    // });

    setTotalVagas(result.data.length);
    setListaVagas(result.data);
  };

  const showVaga = async idVaga => {
    setLoading(true);

    const result = await api.get('/mostra-vaga', {
      params: {
        // pag: page,
        tipo: tipoBusca.value,
        valor: idVaga,
      },
    });
    if (result.data.length === 0) {
      setLoading(false);
      return;
    } else {
      const { vaga_usuarios, ...rest } = result.data[0];

      setVagas(vaga_usuarios);
      setDescricaoVaga(rest);
      setLoading(false);
    }

    // setLoading(false);
  };

  const alterarAtivoVaga = async (idVaga, ativo) => {
    try {
      const result = await api.put('/vagas-alterar', {
        id: idVaga,
        ativo: ativo,
      });
      if (result.data.ok) {
        toast.success(result.data.ok);
        showVaga(modalEdit);
        listarVagas();
      } else {
        toast.error(result.data.error);
      }
    } catch (error) {
      toast.error('Erro, Entre em contato com administrador');
    }
  };

  const funcoes = async () => {
    const select = [];
    const result = await api.get('/funcao');
    result.data.forEach(funcao => {
      select.push({ label: funcao.nome, value: funcao.id });
    });

    setFuncoesLista(select);
  };

  const cargos = async () => {
    const select = [];
    const result = await api.get('/list-cargos');

    result.data.forEach(cargo => {
      select.push({ label: cargo.simbolo, value: cargo.id });
    });
    setCargosLista(select);
  };

  const criaVaga = async () => {
    const result = await api.post('/vagas-criar', {
      funcao_id: inputCriaFuncao,
      cargo_id: inputCriaCargo,
    });
    setVagaNumero(result.data.id);
  };
  // useEffect(() => {
  // showVaga();
  // }, [page]);
  useEffect(() => {
    // showVaga();
    listarVagas();
    funcoes();
    cargos();
  }, []);
  useEffect(() => {
    if (modalEdit !== 0) {
      showVaga(modalEdit);
    }
  }, [modalEdit]);

  return (
    <NavBar>
      <MDBContainer>
        <MDBRow center>
          {/* <MDBCol size="12" md="4" middle>
            <Select
              placeholder="Tipo de Busca"
              value={tipoBusca}
              options={[
                { label: 'ID Funcional', Value: 'idfuncional' },
                { label: 'Nº da Vaga', value: 'numvaga' },
              ]}
              onChange={e => {
                setTipoBusca(e);
              }}
            />
          </MDBCol> */}
          <MDBCol size="12" md="4" middle>
            <MDBInput
              value={inputValue}
              label={tipoBusca.label}
              type="number"
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="4" middle>
            <Select
              placeholder="Funções"
              isClearable="true"
              options={funcoesLista}
              onChange={e => {
                e === null
                  ? setInputFilterFuncao()
                  : setInputFilterFuncao(e.value);
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="4" middle>
            <Select
              placeholder="Cargos"
              options={cargosLista}
              onChange={e => {
                e === null
                  ? setInputFilterCargo()
                  : setInputFilterCargo(e.value);
              }}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow between className="px-4">
          <MDBBtn
            size="sm"
            onClick={() => {
              listarVagas();
            }}
          >
            Buscar
          </MDBBtn>

          <MDBBtn
            color="dark-green"
            size="sm"
            onClick={() => {
              setModalCriar(true);
            }}
          >
            nova vaga
          </MDBBtn>
        </MDBRow>
        {/* {loading === true ? (
          ''
        ) : (
          <MDBRow center className="my-4 align-items-center">
            <div>
              Página {page} de {qtdTotal}
            </div>
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page == 1) {
                  return;
                }
                setPage(Number(page) - 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-left"
            />
            |
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page >= qtdTotal) {
                  return;
                }
                setPage(Number(page) + 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-right"
            />
          </MDBRow>
        )} */}
      </MDBContainer>

      <MDBContainer className="p-0">
        {/* <MDBRow center>

          <MDBCol size="12" md="4" middle>
            <MDBInput
              value={inputValue}
              label={tipoBusca.label}
              onChange={e => {
                setInputValue(e.target.value);
              }}
            />
          </MDBCol>
          <MDBCol size="12" md="1" middle>
            <MDBBtn
              size="sm"
              onClick={() => {
                showVaga();
              }}
            >
              Buscar
            </MDBBtn>
          </MDBCol>
        </MDBRow> */}

        {/* {Object.keys(descricaoVaga).length === 0 ? (
          ''
        ) : (
          <MDBCard reverse>
            <MDBCardBody cascade className="text-center">
              <h5 className="indigo-text">
                <strong>DESCRIÇÃO DA VAGA: {descricaoVaga.id}</strong>
              </h5>
              <MDBCardText>
                <MDBRow>
                  <MDBCol>
                    Ativa: {descricaoVaga.ativo === false ? 'Não' : 'Sim'}
                  </MDBCol>
                  <MDBCol>
                    Disponível:
                    {descricaoVaga.disponivel === false ? (
                      <span className="text-danger"> Não</span>
                    ) : (
                      <span className="text-success"> Sim</span>
                    )}
                  </MDBCol>
                  <MDBCol>Cargo: {descricaoVaga?.cargo?.nome}</MDBCol>
                  <MDBCol>Símbolo: {descricaoVaga?.cargo?.simbolo}</MDBCol>
                  <MDBCol>Função: {descricaoVaga?.funcao?.nome}</MDBCol>
                </MDBRow>
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        )} */}

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="1">
            Vaga
          </MDBCol>
          <MDBCol size="12" md="3">
            Função
          </MDBCol>
          <MDBCol size="12" md="2">
            Cargo
          </MDBCol>
          <MDBCol size="12" md="1">
            Disp
          </MDBCol>
          <MDBCol size="12" md="1">
            Ativo
          </MDBCol>
          <MDBCol size="12" md="3">
            Último Preenchimento
          </MDBCol>
          <MDBCol size="12" md="1">
            Total:{totalVagas}
          </MDBCol>
        </MDBRow>
        {loading === true ? (
          <Loading />
        ) : listavagas.length !== 0 ? (
          listavagas.map(vaga => (
            <>
              <MDBRow
                key={vaga.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">
                    Nº da Vaga:
                  </span>
                  <span className="font-weight-bolder">{vaga.id}</span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Função:</span>
                  <span>{vaga.funcao.nome}</span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="2"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Cargo:</span>
                  <span>
                    {vaga?.cargo?.simbolo}
                    {/* {vaga.cargo &&
                      vaga?.cargo?.simbolo + ' - ' + vaga?.cargo?.nome} */}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Disp:</span>
                  <span>
                    {vaga.disponivel ? (
                      <span className="text-success font-weight-bolder">
                        Sim
                      </span>
                    ) : (
                      <span className="text-danger font-weight-bolder">
                        Não
                      </span>
                    )}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Ativo:</span>
                  <span>
                    {vaga.ativo ? (
                      <span className="text-success font-weight-bolder">
                        Sim
                      </span>
                    ) : (
                      <span className="text-danger font-weight-bolder">
                        Não
                      </span>
                    )}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="3"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                  <span>
                    {vaga.vaga_usuarios &&
                      vaga?.vaga_usuarios[0]?.usuario?.nome_social}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="1"
                  className="mb-md-0 d-flex justify-content-between justify-content-md-end"
                >
                  {/* <span className="d-sm-inline d-md-none mr-3">icon</span> */}

                  <MDBIcon
                    style={{ cursor: 'Pointer' }}
                    icon="edit"
                    size="1x"
                    onClick={() => setModalEdit(vaga.id)}
                  />
                </MDBCol>
                {/* <MDBModal isOpen={modalEdit} toggle={() => modalToogle()}>
                  <MDBModalHeader toggle={() => modalToogle()}>
                    MDBModal title
                  </MDBModalHeader>
                  <MDBModalBody>(...)</MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn color="secondary" onClick={() => modalToogle()}>
                      Cancelar
                    </MDBBtn>
                    <MDBBtn color="primary">Salvar</MDBBtn>
                  </MDBModalFooter>
                </MDBModal> */}
                {/* {format(
                    zonedTimeToUtc(
                      new Date(vaga.createdAt),
                      'America/Sao_Paulo'
                    ),
                    'dd/MM/yyyy'
                  )}
               */}
              </MDBRow>
            </>
          ))
        ) : (
          <MDBRow center className="font-weight-bolder p-2 text-danger">
            Sem Registro
          </MDBRow>
        )}
        <br />

        <MDBModal
          // backdrop={false}
          // fullHeight
          // frame
          // position="right"
          size="lg"
          isOpen={modalEdit !== 0}
          toggle={() => {
            setModalEdit(0);
          }}
          fade={false}
        >
          <MDBModalHeader
            toggle={() => {
              setModalEdit(0);
            }}
          >
            Detalhamento de vaga
          </MDBModalHeader>
          <MDBModalBody>
            <br />
            <MDBCard reverse>
              <MDBCardBody cascade className="text-center pb-0">
                <MDBRow center className="grey-text pb-2">
                  <strong>DESCRIÇÃO DA VAGA: {descricaoVaga.id}</strong>
                </MDBRow>
                <MDBCardText>
                  <MDBRow>
                    <MDBCol size="12" md="6">
                      Ativa:
                      {descricaoVaga.ativo === false ? (
                        <span className="text-danger"> Não</span>
                      ) : (
                        <span className="text-success"> Sim</span>
                      )}
                    </MDBCol>

                    <MDBCol size="12" md="6">
                      Símbolo: {descricaoVaga?.cargo?.simbolo}
                    </MDBCol>
                    <MDBCol size="12" md="6">
                      Disponível:
                      {descricaoVaga.disponivel === false ? (
                        <span className="text-danger"> Não</span>
                      ) : (
                        <span className="text-success"> Sim</span>
                      )}
                    </MDBCol>

                    <MDBCol size="12" md="6">
                      Função: {descricaoVaga?.funcao?.nome}
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow end>
                    {descricaoVaga.ativo === true ? (
                      <MDBBtn
                        outline
                        color="danger"
                        size="sm"
                        onClick={() => {
                          alterarAtivoVaga(descricaoVaga.id, false);
                        }}
                      >
                        Desativar vaga
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        outline
                        color="success"
                        size="sm"
                        onClick={() => {
                          alterarAtivoVaga(descricaoVaga.id, true);
                        }}
                      >
                        Reativar vaga
                      </MDBBtn>
                    )}
                  </MDBRow>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
            <MDBRow center className="grey-text p-2">
              <strong>HISTÓRICO DA VAGA</strong>
            </MDBRow>

            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
            >
              {/* <MDBCol size="12" md="1">
            Vaga
          </MDBCol> */}
              <MDBCol size="12" md="3">
                Início da Vaga:
              </MDBCol>
              <MDBCol size="12" md="3">
                Fim da Vaga:
              </MDBCol>
              <MDBCol size="12" md="6">
                Nome:
              </MDBCol>
            </MDBRow>
            {loading === true ? (
              <Loading />
            ) : vagas.length !== 0 ? (
              vagas.map(vaga => (
                <>
                  <MDBRow
                    key={`vg${vaga.id}`}
                    style={{ borderRadius: '5px' }}
                    className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
                  >
                    {/* <div className="col-12 col-md-1 mb-md-0 d-flex justify-content-between justify-content-md-start">
                  {vaga.vaga_id}
                </div> */}

                    <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      <span className="d-sm-inline d-md-none mr-3">
                        Dia Início
                      </span>

                      {format(
                        zonedTimeToUtc(
                          new Date(vaga.dt_inicio),
                          'America/Sao_Paulo'
                        ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                    </div>
                    <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      <span className="d-sm-inline d-md-none mr-3">
                        Dia Fim
                      </span>
                      {vaga.dt_fim === null ? (
                        <span className="indigo-text font-weight-bolder">
                          Até o Momento
                        </span>
                      ) : (
                        format(
                          zonedTimeToUtc(
                            new Date(vaga.dt_fim),
                            'America/Sao_Paulo'
                          ),
                          'dd/MM/yyyy - HH:mm'
                        )
                      )}
                    </div>

                    <div className="col-12 col-md-6 mb-md-0 text-center text-md-left">
                      <span className="d-sm-inline d-md-none mr-3">nome:</span>
                      <span className="font-weight-bolder ">
                        {`${vaga.usuario.nome} ${vaga.usuario.sobrenome}`}
                      </span>
                    </div>
                  </MDBRow>
                </>
              ))
            ) : (
              <MDBRow center className="font-weight-bolder p-2 text-danger">
                Sem Registro
              </MDBRow>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow between className="w-100">
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setModalEdit(0);
                }}
              >
                Fechar
              </MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          // backdrop={false}
          // fullHeight
          // frame
          // position="right"
          size="lg"
          isOpen={modalCriar}
          toggle={() => {
            setModalCriar(!modalCriar);
          }}
          fade={false}
        >
          <MDBModalHeader
            toggle={() => {
              setModalCriar(!modalCriar);
            }}
          >
            Criação de vaga
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow around>
              <MDBCol size="12" md="5">
                <Select
                  placeholder="Função"
                  options={funcoesLista}
                  onChange={e => setInputCriaFuncao(e.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <Select
                  placeholder="Cargo"
                  options={cargosLista}
                  onChange={e => setInputCriaCargo(e.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <MDBBtn
                  size="sm"
                  onClick={() => {
                    criaVaga();
                  }}
                >
                  Criar Vaga
                </MDBBtn>
              </MDBCol>
            </MDBRow>

            {vagaNumero !== '' ? (
              <MDBRow
                center
                middle
                className="h4-responsive text-danger font-weight-bolder p-5"
              >
                Nova Vaga Nº:
                {vagaNumero}
              </MDBRow>
            ) : (
              ''
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBRow between className="w-100">
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setModalCriar(false);
                }}
              >
                Fechar
              </MDBBtn>
            </MDBRow>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
