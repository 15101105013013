import React from 'react';
import {
  MDBContainer,
  // MDBBox,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  // MDBIcon,
  MDBBtn,
  // MDBSwitch,
} from 'mdbreact';
// import ImgModal from '../../../components/ImgModal';
import { toast } from 'react-toastify';
// import crlv from '../../../assets/crlv.jpeg';
// import VeiculoFrente from '../../../assets/golFrente.jpeg';
// import veiculoTrazeira from '../../../assets/golTrazeira.jpeg';
import NavBar from '../../../components/NavBar';
import { useState, useEffect, useRef } from 'react';

import { format } from 'date-fns';

import Select from 'react-select';
// import UserJpg from '../../../assets/user.jpg';
// import { Container } from './styles';
import api from '../../../services/api';
import { getData } from '../../../services/auth';

export default function VisualizaVtr(props) {
  const [vtr, setVtr] = useState(null);
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState(null);
  const [tiposStatusVeiculos, setTiposStatusVeiculos] = useState([]);
  const [
    tiposStatusVeiculosSelecionado,
    setTiposStatusVeiculosSelecionado,
  ] = useState(null);
  const [statusVeiculosDescricao, setStatusVeiculosDescricao] = useState(null);
  const [statusAtual, setStatusAtual] = useState(null);
  const [modalManutencao, setModalManutencao] = useState(null);
  const [selectTiposManutencao, setSelectTiposManutencao] = useState(null);

  const [manutencaoDataAgendamento, setManutencaoDataAgendamento] = useState(
    null
  );
  const [manutencaoSelecionada, setManutencaoSelecionada] = useState(null);
  const [manutecaoKm, setManutencaoKm] = useState('');

  const [ultimaManutencao, setUltimaManutencao] = useState(null);

  const [iniciaManutencao, setIniciaManutencao] = useState(false);
  const [encerraManutencao, setEncerraManutencao] = useState(null);
  const [dataExecutaManutencao, setdataExecutaManutencao] = useState(null);
  const [dataIniciaManutencao, setdataIniciaManutencao] = useState(null);

  const [descricaoCancelamento, setDescricaoCancelamento] = useState(null);

  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
  };

  const buscaVtr = async () => {
    const result = await api.get(`/busca-veiculo/${props.match.params.vtrid}`);

    setVtr(result.data);
  };

  const alteraGerencia = async () => {
    const result = await api.put(
      `/veiculo-alterar/${props.match.params.vtrid}`,
      {
        gerencia_id: gerenciaSelecionada.value,
      }
    );
    if (result.data.ok) {
      toast.success(result.data.ok);
      buscaVtr();
    } else {
      toast.error(result.data.erro);
    }
  };

  const buscaTipoStatusVeiculos = async () => {
    const result = await api.get('/tipos-status-veiculos');
    const select = [];
    result.data.forEach(element => {
      select.push({ value: element.id, label: element.status_veiculo });
    });
    setTiposStatusVeiculos(select);
  };

  const alteraStatus = async () => {
    if (
      tiposStatusVeiculosSelecionado === null ||
      statusVeiculosDescricao === null ||
      statusVeiculosDescricao === '' ||
      statusVeiculosDescricao === undefined
    ) {
      return toast.error('Preencha todos os campos');
    }
    try {
      const result = await api.post('/status-veiculos', {
        fk_tipos_status_veiculos_id: tiposStatusVeiculosSelecionado.value,
        descricao: statusVeiculosDescricao,
        fk_veiculos_id: props.match.params.vtrid,
      });
      setTiposStatusVeiculosSelecionado(null);
      setStatusVeiculosDescricao(null);

      setStatusAtual(result.data);
      buscaVtr();
      buscaStatusVeiculos();
    } catch (error) {
      toast.error(error);
    }
  };

  const buscaStatusVeiculos = async () => {
    const result = await api.get('/status-veiculos', {
      params: {
        fk_veiculos_id: props.match.params.vtrid,
        limit: '1',
      },
    });

    setStatusAtual(result.data.rows[0]);
  };

  const buscaTiposManutencao = async () => {
    const result = await api.get('/tipos-manutencoes');

    const selectTipos = [];
    result.data.forEach(tip => {
      selectTipos.push({ label: tip.tipo_manutencao, value: tip.id });
    });
    setSelectTiposManutencao(selectTipos);
  };

  const buscaManutencao = async () => {
    const result = await api.get('/verifica-manutencao', {
      params: {
        fk_veiculos_id: props.match.params.vtrid,
        // limit: 1,
      },
    });

    setUltimaManutencao(result.data);
  };

  const criarManutencao = async () => {
    if (
      manutecaoKm === '' ||
      manutencaoDataAgendamento === '' ||
      manutencaoDataAgendamento === null ||
      manutencaoSelecionada === null
    ) {
      toast.error('Preecha todos os campos');
      return;
    }
    const result = await api.post('/manutencoes', {
      quilometragem: manutecaoKm,
      data_agendado: manutencaoDataAgendamento,
      fk_tipos_manutencoes_id: manutencaoSelecionada.value,
      fk_veiculos_id: props.match.params.vtrid,
    });

    if (result.data.error) {
      toast.error(result.data.error);
      return;
    }
    toast.success('Manutenção castrada');
    setManutencaoKm('');
    setManutencaoDataAgendamento(null);
    setManutencaoSelecionada(null);
    buscaManutencao();
    setModalManutencao(null);
  };

  const finalizaManutencao = async () => {
    if (dataExecutaManutencao === null) {
      toast.error('Insira data de encerramento');
      return;
    }

    const body = { data_executado: dataExecutaManutencao };

    if (
      ultimaManutencao?.id &&
      ultimaManutencao.data_executado === null &&
      ultimaManutencao?.data_iniciado === null &&
      descricaoCancelamento === null
    ) {
      toast.error('Preencha Descrição para cancelar');
      return;
    } else {
      body.descricao = descricaoCancelamento;
    }

    const result = await api.put(`/manutencoes/${ultimaManutencao.id}`, body);
    if (result.data.ok) {
      toast.success(result.data.ok);
      buscaManutencao();
      setEncerraManutencao(null);
    }
    // console.log(result);
  };

  const iniciarManutencao = async () => {
    if (dataIniciaManutencao === null) {
      toast.error('Insira data de inicio');
      return;
    }
    const result = await api.put(`/manutencoes/${ultimaManutencao.id}`, {
      data_iniciado: dataIniciaManutencao,
    });

    if (result.data.ok) {
      toast.success(result.data.ok);
      buscaManutencao();
      setIniciaManutencao(false);
    }
    // console.log(result);
  };

  const exibeStatusVtr = () => {
    if (vtr?.atividade_fim !== null) {
      return <div className="danger-text  ml-2">DESATIVADA</div>;
    }
    if (statusAtual?.tipos_status_veiculo?.utilizavel === false) {
      return <div className="danger-text  ml-2">INATIVA</div>;
    }

    if (
      // ultimaManutencao === null ||
      // ultimaManutencao === undefined ||
      ultimaManutencao !== null &&
      ultimaManutencao?.data_iniciado !== null &&
      ultimaManutencao?.data_executado === null
    ) {
      return (
        <div className="text-center danger-text pl-2">VTR EM MANUTENÇÃO</div>
      );
    }

    if (
      ultimaManutencao !== null &&
      // ultimaManutencao === undefined ||
      ultimaManutencao.data_agendado !== null &&
      ultimaManutencao.data_iniciado === null &&
      ultimaManutencao.data_executado === null
    ) {
      return (
        <div className="text-center blue-text pl-2">
          AGENDADO PARA MANUTENÇÃO
        </div>
      );
    }

    return <div className="green-text  ml-2">VTR ATIVA</div>;

    // if (
    //   statusAtual?.tipos_status_veiculo?.utilizavel === true ||
    //   statusAtual === null ||
    //   statusAtual === undefined
    // ) {
    //   if (
    //     ultimaManutencao === null ||
    //     ultimaManutencao === undefined ||
    //     ultimaManutencao.data_iniciado !== null
    //   ) {
    //     if (vtr?.ativo === false) {
    //       return <div className="danger-text  ml-2">DESATIVADA</div>;
    //     }
    //     return (
    //       <>
    //         <div className="green-text  ml-2">VTR ATIVA</div>
    //       </>
    //     );
    //   } else {
    //     return (
    //       <div className="danger-text  ml-2">
    //         <div className="danger-text  ml-2">VTR INATIVA</div>
    //         <br />
    //         <div className="text-center">VTR EM MANUTENÇÃO</div>
    //       </div>
    //     );
    //   }
    // } else {
    //   return (
    //     <div className="danger-text  ml-2">
    //       <>
    //         <div className="danger-text  ml-2">VTR INATIVA</div>
    //         <br />
    //         <div className="text-center">VIATURA COM STATUS BLOQUEADA</div>
    //       </>
    //     </div>
    //   );
    // }
  };

  useEffect(() => {
    buscaVtr();
    carregaGerencias();
    buscaTipoStatusVeiculos();
    buscaStatusVeiculos();
    buscaManutencao();
  }, []);

  // useEffect(() => {
  //   if (tiposStatusVeiculosSelecionado?.value === 'manutencao') {
  //     setModalManutencao(props.match.params.vtrid);
  //   }
  // }, [tiposStatusVeiculosSelecionado]);

  useEffect(() => {
    if (modalManutencao !== null) {
      buscaTiposManutencao();
    }
  }, [modalManutencao]);

  return (
    <NavBar>
      <MDBContainer fluid>
        <MDBRow className="d-flex flex-column flex-md-row  align-items-stretch">
          <MDBCol md="5" className="d-flex flex-column  mt-2 mt-md-0 h-100">
            <MDBCard className="p-3">
              <h4 className="h4-responsive ml-2 grey-text font-weight-bolder">
                Informações:
              </h4>
            </MDBCard>
            <MDBCard className="mt-2">
              <MDBCardBody className="d-flex flex-column">
                <div className="grey-text ">
                  <MDBInput
                    label="Placa"
                    icon="car"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={vtr?.placa}
                    disabled
                  />

                  <MDBInput
                    label="Lotação"
                    icon="map-pin"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={vtr?.veiculos_gerencia[0].gerencia.nome}
                    disabled
                  />

                  {/* <MDBInput
                    label="Sublotação"
                    icon="fas fa-map-marker-alt"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value="COORDENAÇAO"
                    disabled
                  /> */}
                </div>
                <MDBRow className="m-1 p-2 border rounded">
                  <MDBCol size="12 d-flex">
                    <div>STATUS:</div>
                    {exibeStatusVtr()}
                  </MDBCol>

                  <hr className="my-1" />
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mt-2 mt-md-2">
              <h5 className="mt-2 ml-2">Alteração de Status:</h5>
              <MDBCardBody>
                {getData().acessos.find(e => e.rota === '/auditor-frota') !==
                undefined ? (
                  <>
                    <MDBCol size="12">
                      STATUS:{' '}
                      {statusAtual?.tipos_status_veiculo?.status_veiculo}
                    </MDBCol>
                    <MDBCol>
                      <small className="text-muted">Informações:</small>
                      <br />
                      {statusAtual?.descricao}
                    </MDBCol>
                    <MDBCol size="12" className="mt-3">
                      {ultimaManutencao !== null &&
                      ultimaManutencao.data_agendado !== null &&
                      ultimaManutencao.data_executado === null ? (
                        <small className="text-danger">
                          *** Veículo não pode ser alterado com manutenção
                          agendada
                        </small>
                      ) : (
                        <Select
                          placeholder="Altera Status"
                          options={tiposStatusVeiculos}
                          value={tiposStatusVeiculosSelecionado}
                          onChange={e => setTiposStatusVeiculosSelecionado(e)}
                        />
                      )}

                      {tiposStatusVeiculosSelecionado !== null ? (
                        <div className="text-center">
                          <MDBInput
                            type="textarea"
                            label="Descreva informações adicionais"
                            outline
                            maxLength={200}
                            counter={200}
                            onChange={e =>
                              setStatusVeiculosDescricao(e.target.value)
                            }
                          />
                          <MDBBtn onClick={() => alteraStatus()} size="sm">
                            ALTERAR
                          </MDBBtn>
                        </div>
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </>
                ) : (
                  ''
                )}
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mt-2 mt-md-2">
              <h5 className="mt-2 ml-2">Alterar Lotação:</h5>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol className="text-center">
                    <Select
                      options={listaGerencias}
                      placeholder="Gerência"
                      onChange={e => setGerenciaSelecionada(e)}
                    />
                    {gerenciaSelecionada !== null ? (
                      <MDBBtn onClick={() => alteraGerencia()} size="sm">
                        ALTERAR
                      </MDBBtn>
                    ) : (
                      ''
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
            {/* <MDBCard className="mt-2 mt-md-2">
            <h5 className="mt-2 ml-2">fotos:</h5>

            <MDBCardBody className="d-flex flex-row">
              <ImgModal title="Documento" teste={crlv} />
              <ImgModal title="Frente" teste={VeiculoFrente} />
              <ImgModal title="Traseira" teste={veiculoTrazeira} />
              <br />
            </MDBCardBody>
          </MDBCard> */}
          </MDBCol>

          <MDBCol
            md="7"
            className="d-flex flex-column justify-content-between mt-2 mt-md-0"
          >
            <MDBCard>
              <MDBCardBody>
                <MDBContainer className="border  grey lighten-4 rounded">
                  <MDBRow className="m-1">Manutenção</MDBRow>
                  <hr className="my-1" />
                  <MDBRow className="m-1">
                    <small className="text-muted">Informações:</small>
                  </MDBRow>
                  {ultimaManutencao === null ||
                  ultimaManutencao === undefined ? (
                    'Não Existe manutencao para este veiculo!'
                  ) : (
                    <>
                      <MDBRow className="mt-2 red-text font-weight-bolder">
                        <MDBCol>
                          Tipo de Manutencão:{' '}
                          {ultimaManutencao?.tipos_manutenco.tipo_manutencao}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol className="mt-2" size="6">
                          Agendado:{' '}
                          {ultimaManutencao &&
                            format(
                              new Date(ultimaManutencao?.data_agendado),
                              'dd/MM/yyyy HH:mm'
                            )}
                        </MDBCol>
                        <MDBCol className="mt-2" size="6">
                          Quilometragem: {ultimaManutencao?.quilometragem}
                        </MDBCol>
                        <MDBCol className="mt-2" size="6">
                          Iniciada em:{' '}
                          {ultimaManutencao?.data_iniciado === null
                            ? 'Não'
                            : format(
                                new Date(ultimaManutencao?.data_iniciado),
                                'dd/MM/yyyy HH:mm'
                              )}
                        </MDBCol>
                        <MDBCol className="mt-2" size="6">
                          {ultimaManutencao?.data_iniciado === null &&
                          ultimaManutencao.data_executado !== null ? (
                            <span className="text-danger">Cancelada em: </span>
                          ) : (
                            'Executado em: '
                          )}

                          {ultimaManutencao &&
                          ultimaManutencao.data_executado !== null
                            ? format(
                                new Date(ultimaManutencao?.data_executado),
                                'dd/MM/yyyy HH:mm'
                              )
                            : 'Não'}
                          {/* {ultimaManutencao?.data_executado === null
                    ? 'Aberto'
                    : format(
                        new Date(ultimaManutencao?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )} */}
                        </MDBCol>
                      </MDBRow>
                    </>
                  )}

                  <MDBCol
                    size="12"
                    className="text-center font-weight-bolder mt-2"
                  >
                    {ultimaManutencao &&
                    ultimaManutencao.data_executado !== null &&
                    vtr?.atividade_fim === null ? (
                      <MDBBtn
                        onClick={() =>
                          setModalManutencao(props.match.params.vtrid)
                        }
                        size="sm"
                      >
                        Agendar Manutenção
                      </MDBBtn>
                    ) : (
                      ''
                    )}
                    {ultimaManutencao === null ||
                    ultimaManutencao === undefined ? (
                      <MDBBtn
                        onClick={() =>
                          setModalManutencao(props.match.params.vtrid)
                        }
                        size="sm"
                      >
                        Agendar Manutenção
                      </MDBBtn>
                    ) : (
                      ''
                    )}

                    {getData().acessos.find(
                      e => e.rota === '/auditor-frota'
                    ) !== undefined &&
                    ultimaManutencao?.id &&
                    ultimaManutencao.data_executado === null ? (
                      ultimaManutencao?.data_iniciado === null ? (
                        <>
                          <MDBBtn
                            onClick={() => setIniciaManutencao(true)}
                            size="sm"
                            color="green"
                            className="font-weight-bolder"
                          >
                            Iniciar Manutenção
                          </MDBBtn>
                          <MDBBtn
                            onClick={() =>
                              setEncerraManutencao(ultimaManutencao.id)
                            }
                            size="sm"
                            color="red"
                            className="font-weight-bolder"
                          >
                            Cancelar Manutenção
                          </MDBBtn>
                        </>
                      ) : (
                        <MDBBtn
                          onClick={() =>
                            setEncerraManutencao(ultimaManutencao.id)
                          }
                          size="sm"
                          color="red"
                          className="font-weight-bolder"
                        >
                          Encerrar Manutenção
                        </MDBBtn>
                      )
                    ) : (
                      ''
                    )}
                  </MDBCol>
                </MDBContainer>

                <MDBTable className="mt-2" striped>
                  <MDBTableBody>
                    <tr>
                      <td style={{ fontSize: '13px' }}>RENAVAM</td>
                      <td style={{ fontSize: '13px' }}>{vtr?.renavam}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>MODELO</td>
                      <td style={{ fontSize: '13px' }}>
                        {vtr?.fabricante} / {vtr?.modelo}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>COR</td>
                      <td style={{ fontSize: '13px' }}>{vtr?.cor}</td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>CARACTERIZADA</td>
                      <td
                        style={{ fontSize: '13px' }}
                        className="

                       font-weight-bold"
                      >
                        {vtr?.veiculos_caracteristicas[0]?.adesivado ===
                        true ? (
                          <div className="success-text">Sim</div>
                        ) : (
                          <div className="red-text">Não</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>GIROFLEX</td>
                      <td
                        style={{ fontSize: '13px' }}
                        className="green-text font-weight-bold"
                      >
                        {vtr?.veiculos_caracteristicas[0]?.giroflex === true ? (
                          <div className="success-text">Sim</div>
                        ) : (
                          <div className="red-text">Não</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>SIRENE</td>
                      <td
                        style={{ fontSize: '13px' }}
                        className="green-text font-weight-bold"
                      >
                        {vtr?.veiculos_caracteristicas[0]?.sirene === true ? (
                          <div className="success-text">Sim</div>
                        ) : (
                          <div className="red-text">Não</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>BDT inicial KM</td>
                      <td
                        style={{ fontSize: '13px' }}
                        className="font-weight-bold"
                      >
                        {vtr?.bdts[0]?.quilometragem_inicio}
                        {/* {vtr?.bdts[0]?.data_apresentacao
                        ? format(
                            new Date(vtr?.bdts[0]?.data_apresentacao),
                            'dd-MM-yyyy HH:mm'
                          )
                        : ''} */}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>BDT final KM</td>
                      <td
                        style={{ fontSize: '13px' }}
                        className="font-weight-bold"
                      >
                        {vtr?.bdts[0]?.quilometragem_fim}
                        {/* {vtr?.bdts[0]?.quilometragem_inicio} */}
                        {/* {format(
                        new Date(vtr?.bdts[0]?.data_dispensa),
                        'dd/MM/yyyy HH:mm'
                      )} */}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontSize: '13px' }}>
                        Data Inicio Último BDT
                      </td>
                      <td
                        style={{ fontSize: '13px' }}
                        className=" font-weight-bold"
                      >
                        {vtr?.bdts[0]?.data_apresentacao
                          ? format(
                              new Date(vtr?.bdts[0]?.data_apresentacao),
                              'dd-MM-yyyy HH:mm'
                            )
                          : ''}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
            {/* <MDBCard className="mt-2">
            <MDBCardBody>
              Último Registro
              <MDBTable striped>
                <MDBTableBody>
                  <tr>
                    <td className="p-0" style={{ fontSize: '13px' }}>
                      Condutor
                    </td>
                    <td className="p-0" style={{ fontSize: '13px' }}>
                      KM Final
                    </td>
                    <td className="p-0" style={{ fontSize: '13px' }}>
                      Data
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: '13px' }}>Carlos</td>
                    <td style={{ fontSize: '13px' }}>2123444</td>
                    <td style={{ fontSize: '13px' }}>21/11/2019</td>
                  </tr>
                </MDBTableBody>
              </MDBTable>
            </MDBCardBody>
          </MDBCard> */}
            {/* <MDBCard className="mt-2">
            <MDBCardBody className="text-center">
              <MDBBtn outline color="grey">
                <MDBIcon icon="edit" className="mr-1" /> Editar Dados
              </MDBBtn>
              <MDBBtn outline color="grey">
                <MDBIcon icon="eye" className="mr-1" />
                Histórico com funcionalidade
              </MDBBtn>
            </MDBCardBody>
          </MDBCard> */}
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <MDBCard className="p-3 my-3">Histórico:</MDBCard>
          </MDBCol>
        </MDBRow>
        <MDBRow>Manutencao</MDBRow>
        <MDBModal
          isOpen={modalManutencao !== null}
          toggle={() => setModalManutencao(null)}
        >
          <MDBModalHeader toggle={() => setModalManutencao(null)}>
            Manutenção
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol size="12">
                  <MDBInput
                    label="quilometragem"
                    outline
                    type="number"
                    onChange={e => setManutencaoKm(e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12">
                  <MDBInput
                    placeholder="Data Agendamento"
                    type="datetime-local"
                    outline
                    // value={() => format(new Date(), 'dd/MM/yyyy HH:mm')}
                    onChange={e => setManutencaoDataAgendamento(e.target.value)}
                  />
                </MDBCol>
                <MDBCol size="12">
                  <Select
                    placeholder="Tipo de Manutenção"
                    outline
                    options={selectTiposManutencao}
                    // value={() => format(new Date(), 'dd/MM/yyyy HH:mm')}
                    onChange={e => setManutencaoSelecionada(e)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setModalManutencao(null)}>
              Cancelar
            </MDBBtn>
            <MDBBtn onClick={() => criarManutencao()} color="success">
              Criar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={iniciaManutencao !== false}
          toggle={() => setIniciaManutencao(false)}
        >
          <MDBModalHeader toggle={() => setIniciaManutencao(false)}>
            Iniciar Manutenção
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol className="mt-2" size="12">
                  Agendado:{' '}
                  {ultimaManutencao &&
                    format(
                      new Date(ultimaManutencao?.data_agendado),
                      'dd/MM/yyyy HH:mm'
                    )}
                </MDBCol>
                <MDBCol className="mt-2" size="12">
                  Executado:{' '}
                  {ultimaManutencao && ultimaManutencao.data_executado !== null
                    ? format(
                        new Date(ultimaManutencao?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )
                    : 'ABERTO'}
                  {/* {ultimaManutencao?.data_executado === null
                    ? 'Aberto'
                    : format(
                        new Date(ultimaManutencao?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )} */}
                </MDBCol>
                <MDBCol className="mt-2" size="6">
                  Quilometragem: {ultimaManutencao?.quilometragem}
                </MDBCol>
                <MDBCol className="mt-2" size="6">
                  Tipos Manutencão:
                  {ultimaManutencao?.tipos_manutenco.tipo_manutencao}
                </MDBCol>

                <MDBCol size="12">
                  <MDBInput
                    label="Executado em"
                    type="datetime-local"
                    outline
                    valueDefault={' '}
                    // value={() => format(new Date(), 'dd/MM/yyyy HH:mm')}
                    onChange={e => setdataIniciaManutencao(e.target.value)}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => setIniciaManutencao(false)}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn onClick={() => iniciarManutencao()} color="success">
              Iniciar Manutenção
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={encerraManutencao !== null}
          toggle={() => setEncerraManutencao(null)}
        >
          <MDBModalHeader toggle={() => setEncerraManutencao(null)}>
            {ultimaManutencao?.id &&
            ultimaManutencao.data_executado === null &&
            ultimaManutencao?.data_iniciado === null
              ? 'Cancelar '
              : 'Encerrar '}
            Manutenção
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol className="mt-2" size="12">
                  Agendado:{' '}
                  {ultimaManutencao &&
                    format(
                      new Date(ultimaManutencao?.data_agendado),
                      'dd/MM/yyyy HH:mm'
                    )}
                </MDBCol>
                <MDBCol className="mt-2" size="12">
                  Executado:{' '}
                  {ultimaManutencao && ultimaManutencao.data_executado !== null
                    ? format(
                        new Date(ultimaManutencao?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )
                    : 'ABERTO'}
                  {/* {ultimaManutencao?.data_executado === null
                    ? 'Aberto'
                    : format(
                        new Date(ultimaManutencao?.data_executado),
                        'dd/MM/yyyy HH:mm'
                      )} */}
                </MDBCol>
                <MDBCol className="mt-2" size="6">
                  Quilometragem: {ultimaManutencao?.quilometragem}
                </MDBCol>
                <MDBCol className="mt-2" size="6">
                  Tipos Manutencão:
                  {ultimaManutencao?.tipos_manutenco.tipo_manutencao}
                </MDBCol>

                <MDBCol size="12">
                  <MDBInput
                    label="Encerrado em"
                    type="datetime-local"
                    outline
                    valueDefault={' '}
                    // value={() => format(new Date(), 'dd/MM/yyyy HH:mm')}
                    onChange={e => setdataExecutaManutencao(e.target.value)}
                  />
                </MDBCol>
                {ultimaManutencao?.id &&
                  ultimaManutencao.data_executado === null &&
                  ultimaManutencao?.data_iniciado === null && (
                    <MDBCol size="12">
                      <MDBInput
                        onChange={e => setDescricaoCancelamento(e.target.value)}
                        style={{ borderRadius: '5px' }}
                        type="textarea"
                        outline
                        rows="3"
                        label="Descreva o motivo "
                        maxLength="200"
                      />
                    </MDBCol>
                  )}
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setModalManutencao(null)}>
              Cancelar
            </MDBBtn>
            <MDBBtn onClick={() => finalizaManutencao()} color="success">
              Encerrar Manutenção
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
