import { createGlobalStyle } from 'styled-components';

import 'font-awesome/css/font-awesome.css';
import 'react-toastify/dist/ReactToastify.css';
const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;
}
.cursor-pointer{
  cursor: pointer !important
}


body, html {
  /* background-image: linear-gradient(to right, white , silver); */
  /* background-image: radial-gradient(white 5%, silver 15%, silver 60%); */
  background-image: radial-gradient(circle, white 10%, #52658c 200%);
  font-family: 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 100%;
  width: 100%;
  background-attachment: fixed;

}
@media print {

  body, html {
      background: transparent;
  }
}

`;

export default GlobalStyle;
