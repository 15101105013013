import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, addYears } from 'date-fns';

import { zonedTimeToUtc } from 'date-fns-tz';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBView,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCollapse,
  MDBIcon,
  MDBSpinner,
  MDBInput,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import api from '../../../../services/api';
import Loading from '../../../../components/Loading';
import ZoomImage from '../../../../components/zoomImage';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getData, getPerfil } from '../../../../services/auth';

export const ModalPage = props => {
  const [state, setState] = useState(false);

  let history = useHistory();

  const [posto, setPosto] = useState('');
  const [ala, setAla] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [estadoOrigem, setEstadoOrigem] = useState('');
  const [cidadeOrigem, setCidadeOrigem] = useState('');
  const [estadoDestino, setEstadoDestino] = useState('');
  const [cidadeDestino, setCidadeDestino] = useState('');
  const [evasao, setEvasao] = useState('');
  const [abordagemAtiva, setAbordagemAtiva] = useState(true);
  const [abordagemId, setAbordagemId] = useState('');
  const [loading, setLoading] = useState(false);

  const [toggleCollapse, setToggleCollapse] = useState(false);
  const [imagens, setImagens] = useState([]);
  const [loadingBusca, setLoadingBusca] = useState(false);

  const [modalRevisao, setModalRevisao] = useState(false);

  const [revisaoMotivos, setRevisaoMotivos] = useState([]);
  const [revisaoMotivosSelecionado, setRevisaoMotivosSelecionado] = useState(
    null
  );
  const [revisao, setRevisao] = useState(null);
  const [emRevisao, setEmRevisao] = useState('');
  const [usuario, setUsuario] = useState(null);
  const [usuarioId, setUsuarioId] = useState(null);

  const [abordagemSelecionada, setAbordagemSelecionada] = useState(false);
  const [deletadoPor, setDeletadoPor] = useState(null);
  const [apoiadoVolante, setApoiadoVolante] = useState(null);

  const [duplicaAbordagem, setDuplicaAbordagem] = useState(null);

  const [justificaDuplicacao, setJustificaDuplicacao] = useState('');
  const [botaoDuplicaConfirma, setBotaoDuplicaConfirma] = useState(false);

  const [criadoEm, setCriadoEm] = useState('');
  const [descricao, setDescricao] = useState('');
  const trataDate = date => {
    return format(
      zonedTimeToUtc(new Date(date), 'America/Sao_Paulo'),
      'dd/MM/yyyy - HH:mm'
    );
  };

  const postoAla = async idAbordagem => {
    const posto = await api.get(`/abordagem/${idAbordagem}`);

    // emRevisao
    if (posto.data?.sgo_revisaos.length > 0) {
      if (
        posto.data?.sgo_revisaos.filter(res => res.data_final === null).length >
        0
      ) {
        setEmRevisao('Em Revisão');
      } else {
        setEmRevisao('Revisado');
      }
    } else {
      setEmRevisao('');
    }

    setAbordagemId(idAbordagem);
    setPosto(posto.data.lotacao.gerencia.nome);
    setAla(posto.data.lotacao.ala.nome);
    setUsuario(posto.data.usuario.nome_social);
    setUsuarioId(posto.data.usuario.id);
    setApoiadoVolante(posto.data.apoiado_por_gerencia_id);
    setDataInicio(trataDate(posto.data.dt_h_inicio));
    setDataFim(trataDate(posto.data.dt_h_fim));

    setEstadoOrigem(posto.data.cidade_origem.estado.nome);
    setCidadeOrigem(posto.data.cidade_origem.nome);
    setEstadoDestino(posto.data.cidade_destino.estado.nome);
    setCidadeDestino(posto.data.cidade_destino.nome);
    setAbordagemAtiva(posto.data.ativo);
    setEvasao(posto.data.evasao);
    setCriadoEm(posto.data.createdAt);
    setDescricao(posto.data.descricao);

    if (posto.data.deletado_por !== null) {
      setDeletadoPor(posto.data.deletado_por.nome_social);
    } else {
      setDeletadoPor(null);
    }
  };

  const [veiculos, setVeiculos] = useState([]);

  const veiculosAbordados = async idAbordagem => {
    const veiAbordados = await api.get(`/veiculos-abordagem/${idAbordagem}`);

    setVeiculos(veiAbordados.data);
  };

  const [abordados, setAbordados] = useState([]);

  const listaAbordados = async idAbordagem => {
    const lista = await api.get(`/abordados-abordagem/${idAbordagem}`);

    setAbordados(lista.data);
  };

  const [empresas, setEmpresas] = useState([]);

  const listaEmpresas = async idAbordagem => {
    const lista = await api.get(`/empresas-abordagem/${idAbordagem}`);

    setEmpresas(lista.data);
  };

  const [ocorrencias, setOcorrencias] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState('');
  // deletar
  const [id, setId] = useState('');

  const listaOcorrencias = async idAbordagem => {
    setLoading(true);
    const lista = await api.get(`/inteligencia-abordagem/${idAbordagem}`);
    setOcorrencias(lista.data);

    setLoading(false);

    // deletar
    setId(idAbordagem);
  };

  const toggle = () => {
    if (state === false) {
      setState(true);
      setToggleCollapse(false);
      postoAla(props.abordagem);
      veiculosAbordados(props.abordagem);
      listaAbordados(props.abordagem);
      listaEmpresas(props.abordagem);
      listaOcorrencias(props.abordagem);
    } else {
      // props.childToParent(null);
      setState(false);
    }
  };

  const buscaFoto = async () => {
    setLoadingBusca(true);
    const result = await api.get('/sgo-imagem', {
      params: {
        abordagem_id: props.abordagem,
      },
    });
    setImagens(result.data);
    setLoadingBusca(false);
  };

  const buscaRevisaoMotivos = async () => {
    const result = await api.get('/sgo-motivo-revisao');
    const select = [];
    result.data.forEach(element => {
      select.push({ value: element.id, label: element.motivo });
    });
    setRevisaoMotivos(select);
  };

  const enviarRevisao = async () => {
    if (revisaoMotivosSelecionado === null) {
      toast.error('Selecione o motivo');
      return;
    }
    if (revisao === null) {
      toast.error('Insira a descrição');
      return;
    }
    try {
      const result = await api.post('/sgo-revisao', {
        abordagem_id: props.abordagem,
        comentario_inicial: revisao,
        sgo_revisao_motivo_id: revisaoMotivosSelecionado.value,
      });
      toast.success(result.data.id && 'Revisão Criada');
      setModalRevisao(false);
      setRevisaoMotivosSelecionado(null);
      setRevisao(null);
    } catch (error) {
      toast.error('Erro: Entre em contato com ADM');
    }

    // console.log({
    //   abordagem_id: props.abordagem,
    //   comentario_inicial: revisao,
    //   sgo_revisao_motivo_id: revisaoMotivosSelecionado,
    // });
  };

  const excluiAbordagem = async id => {
    // rota de backend

    const excluiAbordagem = await api.put(`/desativa-abordagem/${id}`);

    if (excluiAbordagem.data.erro) {
      toast.error(excluiAbordagem.data.erro);
    } else {
      toast.success(excluiAbordagem.data.ok);
      setAbordagemSelecionada(false);
      toggle();
    }
  };

  const replicarAbordagem = async id => {
    if (justificaDuplicacao === '') {
      toast.error('Insira Motivo da Duplicação');
      return;
    }
    setBotaoDuplicaConfirma(true);
    const result = await api.post(`/duplica-abordagem/${id}`, {
      justificativa: justificaDuplicacao,
    });
    setBotaoDuplicaConfirma(false);
    if (result.data.ok) {
      toast.success('Agora o senhor pode editar sua nova abordagem', {
        autoClose: 10000,
        className: 'font-weight-bold',
      });
      // console.log(`/abordagem/${result.data.ok}`);
      history.push(`/abordagem/${result.data.ok}`);
    }
  };

  useEffect(() => {
    if (props.abordagem !== null) {
      // props.childToParent(`teste null ${props.abordagem}`);
      toggle();
      setImagens([]);
    }
    // props.childToParent(`teste ${props.abordagem}`);
  }, [props.abordagem]);

  useEffect(() => {
    setImagens([]);
    if (toggleCollapse) {
      buscaFoto();
    }

    return;
  }, [toggleCollapse]);

  useEffect(() => {
    if (state === false) {
      props.childToParent(null);
    }
  }, [state]);

  useEffect(() => {
    if (modalRevisao === true) {
      buscaRevisaoMotivos();
    }
  }, [modalRevisao]);

  return (
    <>
      <MDBModal
        animation="bottom"
        size="lg"
        isOpen={state}
        // toggle={() => toggle()}
      >
        <MDBModalHeader toggle={() => setState(false)}>
          Visualização
          {/* ( ID: {id} ) */}
        </MDBModalHeader>
        {loading === true ? (
          <Loading />
        ) : (
          <MDBModalBody className="p-0 mt-2">
            <MDBContainer>
              <MDBRow between>
                <MDBCol className="text-center">
                  <Link
                    to={`/imprime-abordagem/${abordagemId}`}
                    className="d-flex flex-column align-items-center "
                    style={{
                      color: 'rgba(0,0,0,0.6)',
                    }}
                    // target={'_blank'}
                    // rel="noopener noreferrer"
                  >
                    <span className="mx-5 my-2 text-danger font-weight-bolder">
                      IMPRIMIR
                    </span>
                  </Link>
                </MDBCol>
              </MDBRow>
              <MDBRow end>
                {emRevisao !== '' ? (
                  <MDBCol className="text-right font-weight-bold danger-text mx-5">
                    {emRevisao}
                  </MDBCol>
                ) : (
                  ''
                )}
              </MDBRow>

              {/* DADOS DE ABORDAGEM */}
              <MDBCard narrow>
                <MDBView
                  className="mdb-color color-block-5 mb-3 pr-0 pr-md-5 py-2"
                  cascade
                >
                  <MDBRow
                    center
                    className="text-white font-weight-bolder text-center text-md-right"
                  >
                    Abordagem Nº: {abordagemId}
                  </MDBRow>
                  <MDBRow
                    between
                    className={`ml-2 font-weight-bolder ${
                      abordagemAtiva === false ? 'danger-text' : ''
                    }`}
                  >
                    <MDBCol size="12" md="6">
                      {abordagemAtiva === false ? (
                        'Deletada'
                      ) : getData().acessos.find(
                          t => t.rota === 'deleta-abordagem'
                        ) !== undefined ? (
                        <MDBBtn
                          className="font-weight-bolder m-0"
                          color="danger"
                          size="sm"
                          onClick={() =>
                            setAbordagemSelecionada(props.abordagem)
                          }
                        >
                          deletar
                        </MDBBtn>
                      ) : (
                        ''
                      )}
                    </MDBCol>

                    <MDBCol size="12" md="6">
                      {getData().acessos.find(
                        t => t.rota === 'duplica-abordagem'
                      ) !== undefined ? (
                        criadoEm &&
                        Date.now() < addYears(new Date(criadoEm), 1) &&
                        abordagemAtiva === true ? (
                          <MDBBtn
                            className="font-weight-bolder m-0 float-right"
                            color="danger"
                            size="sm"
                            onClick={() => setDuplicaAbordagem(props.abordagem)}
                          >
                            Duplicar
                          </MDBBtn>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBView>

                <MDBCardBody className="pt-0">
                  <MDBRow start>
                    <MDBCol
                      size="12"
                      md="6"
                      className="text-start font-weight-bolder"
                    >
                      Criado por: {usuario} <br />
                      Criado em:{' '}
                      {criadoEm &&
                        format(new Date(criadoEm), 'dd/MM/yyyy HH:mm')}
                    </MDBCol>
                    {deletadoPor !== null ? (
                      <MDBCol
                        size="12"
                        md="6"
                        className="text-left text-danger text-md-right font-weight-bolder"
                      >
                        Deletado por: {deletadoPor}
                      </MDBCol>
                    ) : (
                      ''
                    )}
                  </MDBRow>
                  <br />
                  <MDBRow between>
                    <MDBCol
                      className="mt-2 mt-md-0 text-left"
                      size="12"
                      md="4"
                      middle
                    >
                      {/* <Select
                  placeholder="Posto"
                  // options={listaGerencias}
                  // onChange={e => {
                  //   carregaLotacoes(e.value);
                  // }}

                /> */}
                      Posto: {posto}
                    </MDBCol>
                    <MDBCol
                      className="mt-2 mt-md-0 text-center"
                      size="12"
                      md="4"
                      middle
                    >
                      {/* <Select
                  placeholder="Ala"
                  options={listaLotacoes}
                  isLoading={loadLotacao}
                  value={lotacaoSelecionada?.value}
                  onChange={e => {
                    setLotacaoSelecionada(e.value);
                  }}
                /> */}
                      Ala: {ala}
                    </MDBCol>
                    <MDBCol
                      className="mt-2 mt-md-0 text-right"
                      size="12"
                      md="4"
                      middle
                    >
                      {/* <Select
                  placeholder="Ala"
                  options={listaLotacoes}
                  isLoading={loadLotacao}
                  value={lotacaoSelecionada?.value}
                  onChange={e => {
                    setLotacaoSelecionada(e.value);
                  }}
                /> */}
                      Evasão: {evasao === true ? 'Sim' : 'Não'}
                    </MDBCol>
                  </MDBRow>
                  <br />
                  <MDBRow between>
                    <MDBCol
                      className="mt-3 mt-md-0 text-left"
                      size="12"
                      md="6"
                      middle
                    >
                      Data Início: {dataInicio}
                      {/* <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="datetime-local"
                  onChange={e => {
                    setDataInicio(e.target.value);
                  }}
                /> */}
                    </MDBCol>
                    <MDBCol
                      className="mt-3 mt-md-0 text-right"
                      size="12"
                      md="6"
                      middle
                    >
                      Data Fim: {dataFim}
                      {/* <MDBInput
                  type="datetime-local"
                  style={{ marginTop: '-28px' }}
                  onChange={e => {
                    setDataFim(e.target.value);
                  }}
                /> */}
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-3">
                    <MDBCol>
                      Apoiado pela volante:{' '}
                      {apoiadoVolante === null ? 'Não' : 'Sim'}
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol size="12" md="6" middle>
                      Origem: {`${estadoOrigem} / ${cidadeOrigem}`}
                    </MDBCol>

                    <MDBCol size="12" md="6" middle>
                      Destino: {`${estadoDestino} / ${cidadeDestino}`}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
              <br />
              {/* DADOS DE VEÍCULOS */}
              <MDBCard narrow>
                <MDBView
                  className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
                  cascade
                >
                  <h4 className="text-white font-weight-bolder">Veículos</h4>
                </MDBView>

                <MDBCardBody className="pt-0">
                  {veiculos.length === 0 ? (
                    <MDBRow center>Sem Veículos</MDBRow>
                  ) : (
                    ''
                  )}
                  {veiculos.map(veiculo => (
                    <MDBRow
                      key={veiculo.id}
                      className="mt-1 p-2"
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                    >
                      <MDBCol>{`Tipo: ${veiculo.tipo_veiculo.nome}`}</MDBCol>
                      {veiculo.placa !== null ? (
                        <MDBCol>{`Placa: ${veiculo.placa}`}</MDBCol>
                      ) : (
                        <MDBCol>{`Chassi: ${veiculo.chassi}`}</MDBCol>
                      )}
                    </MDBRow>
                  ))}
                </MDBCardBody>
              </MDBCard>
              <br />
              {/* DADOS DE ABORDADOS */}
              <MDBCard narrow>
                <MDBView
                  className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
                  cascade
                >
                  <h4 className="text-white font-weight-bolder">Abordados</h4>
                </MDBView>

                <MDBCardBody className="pt-0">
                  {abordados.length === 0 ? (
                    <MDBRow center>Sem Abordados</MDBRow>
                  ) : (
                    ''
                  )}
                  {abordados &&
                    abordados.map((abordado, index) =>
                      abordado.ativo ? (
                        <div key={`f${index}`}>
                          <MDBRow
                            className="mt-3"
                            style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                          >
                            {abordado.nome !== null ? (
                              <MDBCol sm="12" md="6">
                                Nome: {abordado.nome}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.cpf !== null ? (
                              <MDBCol sm="12" md="6">
                                Cpf: {abordado.cpf}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.tipo !== null ? (
                              <MDBCol sm="12" md="6">
                                Tipo: {abordado.tipo}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.condutor !== null ? (
                              <MDBCol sm="12" md="6">
                                Condutor:{' '}
                                {abordado.condutor === true ? 'Sim' : 'Não'}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                          </MDBRow>

                          {abordado.condutor === true ? (
                            <MDBRow
                              style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                            >
                              <br />
                              {abordado.cnh !== null ? (
                                <MDBCol>CNH: {abordado.cnh}</MDBCol>
                              ) : (
                                ''
                              )}
                              {abordado.cnh_categoria !== null ? (
                                <MDBCol>
                                  Categoria: {abordado.cnh_categoria}
                                </MDBCol>
                              ) : (
                                ''
                              )}

                              {abordado.cnh_validade !== null ? (
                                <MDBCol>
                                  CNH validade:{' '}
                                  {format(
                                    new Date(abordado.cnh_validade),
                                    'dd/MM/yyyy'
                                  )}
                                </MDBCol>
                              ) : (
                                ''
                              )}
                            </MDBRow>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        <div key={`f${index}`}>
                          <MDBRow className=" mt-3 deep-orange lighten-4 red-text px-2">
                            <small>Deletado</small>
                          </MDBRow>

                          <MDBRow className=" deep-orange lighten-4">
                            {abordado.nome !== null ? (
                              <MDBCol sm="12" md="6">
                                Nome: {abordado.nome}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.cpf !== null ? (
                              <MDBCol sm="12" md="6">
                                Cpf: {abordado.cpf}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.tipo !== null ? (
                              <MDBCol sm="12" md="6">
                                Tipo: {abordado.tipo}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                            {abordado.condutor !== null ? (
                              <MDBCol sm="12" md="6">
                                Condutor:{' '}
                                {abordado.condutor === true ? 'Sim' : 'Não'}
                              </MDBCol>
                            ) : (
                              ''
                            )}
                          </MDBRow>
                          {abordado.condutor === true ? (
                            <MDBRow className="deep-orange lighten-4">
                              <br />
                              {abordado.cnh !== null ? (
                                <MDBCol>CNH: {abordado.cnh}</MDBCol>
                              ) : (
                                ''
                              )}
                              {abordado.cnh_categoria !== null ? (
                                <MDBCol>
                                  Categoria: {abordado.cnh_categoria}
                                </MDBCol>
                              ) : (
                                ''
                              )}

                              {abordado.cnh_validade !== null ? (
                                <MDBCol>
                                  CNH validade:{' '}
                                  {format(
                                    new Date(abordado.cnh_validade),
                                    'dd/MM/yyyy'
                                  )}
                                </MDBCol>
                              ) : (
                                ''
                              )}
                            </MDBRow>
                          ) : (
                            ''
                          )}
                        </div>
                      )
                    )}
                </MDBCardBody>
              </MDBCard>
              <br />
              {/* DADOS DE EMPRESAS */}
              <MDBCard narrow>
                <MDBView
                  className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
                  cascade
                >
                  <h4 className="text-white font-weight-bolder">Empresas</h4>
                </MDBView>

                <MDBCardBody className="pt-0">
                  {empresas.length === 0 ? (
                    <MDBRow center>Sem Empresas</MDBRow>
                  ) : (
                    ''
                  )}
                  {empresas.map(empresa => (
                    <>
                      <MDBRow
                        key={empresa.id}
                        className="p-1 mt-3 mdb-color lighten-4"
                      >
                        <MDBCol>CNPJ: {empresa.cnpj}</MDBCol>
                        {empresa.nome === '' || empresa.nome === null ? (
                          ''
                        ) : (
                          <MDBCol>Nome: {empresa.nome}</MDBCol>
                        )}
                      </MDBRow>
                      {empresa.documento_fiscals.map(documento => (
                        <>
                          {documento.ativo ? (
                            ''
                          ) : (
                            <MDBRow className="mx-1">
                              <MDBCol className="deep-orange lighten-4 red-text">
                                <small>Deletado</small>
                              </MDBCol>{' '}
                            </MDBRow>
                          )}

                          <MDBRow
                            key={documento.id}
                            className={`mx-1 ${
                              documento.ativo
                                ? 'grey lighten-5'
                                : 'deep-orange lighten-4'
                            }`}
                          >
                            <MDBCol>Tipo: {documento.tipo}</MDBCol>
                            {documento.numero === '' ||
                            documento.numero === null ? (
                              ''
                            ) : (
                              <MDBCol>Número: {documento.numero}</MDBCol>
                            )}

                            {documento.valor === '' ||
                            documento.valor === null ? (
                              ''
                            ) : (
                              <MDBCol>
                                Valor: R${' '}
                                {documento.valor.toString().replace('.', ',')}
                              </MDBCol>
                            )}
                            {documento.icms === '' ||
                            documento.icms === null ? (
                              ''
                            ) : (
                              <MDBCol>
                                ICMS: R${' '}
                                {documento.icms.toString().replace('.', ',')}
                              </MDBCol>
                            )}
                          </MDBRow>
                          <MDBRow
                            className={`mx-1 mb-1 ${
                              documento.ativo
                                ? 'grey lighten-5'
                                : 'deep-orange lighten-4'
                            }`}
                            // style={{
                            //   backgroundColor: `${
                            //     documento.ativo
                            //       ? 'rgba(0,0,0,0.1)'
                            //       : 'deep-orange lighten-4'
                            //   }`,
                            // }}
                          >
                            {documento.chave.split('https').length > 1 ? (
                              <>
                                Chave: {documento.chave}
                                <br />
                                <a href={documento.chave}>
                                  Veja o Manifesto Completo
                                </a>
                              </>
                            ) : (
                              `Chave: ${documento.chave}`
                            )}
                          </MDBRow>
                        </>
                      ))}
                    </>
                  ))}
                </MDBCardBody>
              </MDBCard>
              <br />
              {/* DADOS DE EMPRESAS */}
              <MDBCard narrow>
                <MDBView
                  className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
                  cascade
                >
                  <h4 className="text-white font-weight-bolder">Descrição</h4>
                </MDBView>

                <MDBCardBody className="pt-0 text-center">
                  {descricao === '' || descricao === null
                    ? 'Sem descrição'
                    : descricao}
                </MDBCardBody>
              </MDBCard>

              <br />
              {ocorrencias.map((oco, index) => (
                <>
                  <MDBCard narrow key={`b${index}`}>
                    <MDBView
                      className="elegant-color mb-3 pr-0 pr-md-5 py-2  d-flex justify-content-between"
                      cascade
                    >
                      <h4
                        className={`ml-2 font-weight-bolder ${
                          oco.ativo === false ? 'danger-text' : ''
                        }`}
                      >
                        {`${oco.ativo === false ? 'Deletada' : ''}`}
                      </h4>
                      <h4 className="text-white font-weight-bolder">
                        Ocorrência Nº: {oco.id}
                      </h4>
                    </MDBView>

                    <MDBCardBody className="pt-0">
                      <MDBRow start className=" h5-responsive px-3">
                        {oco.razao_autuacao.autuado === true
                          ? 'Autuado'
                          : 'Não Autuado'}
                        : {oco.razao_autuacao.descricao}
                      </MDBRow>
                      <MDBRow>
                        <MDBCol size="12" md="4" middle>
                          Registro de Ocorrência: Nº:
                          {oco.ro === null ? (
                            <p className="danger-text">R.O não informado</p>
                          ) : (
                            oco.ro
                          )}
                        </MDBCol>
                        <MDBCol
                          className="mt-2 mt-md-0"
                          size="12"
                          md="4"
                          middle
                        >
                          Autuado: {oco.autuado === true ? 'Sim' : 'Não'}
                        </MDBCol>

                        <MDBCol
                          className="mt-3 mt-md-0"
                          size="12"
                          md="4"
                          middle
                        >
                          <span style={{ fontSize: '14px' }}>
                            Data de Criação:
                          </span>
                          <div>{trataDate(oco.createdAt)}</div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow
                        center
                        className="font-weight-bolder"
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      >
                        Pessoas Autuadas:
                      </MDBRow>

                      {oco.autuado_ocorrencia.map((autuado, index) =>
                        autuado.ativo ? (
                          <MDBRow className="py-2 mx-1" key={`c${index}`}>
                            <MDBCol>Nome: {autuado.abordado.nome}</MDBCol>
                            <MDBCol>Tipo: {autuado.abordado.tipo}</MDBCol>
                            <MDBCol>CPF: {autuado.abordado.cpf}</MDBCol>
                          </MDBRow>
                        ) : (
                          <>
                            <MDBRow
                              key={`c${index}`}
                              className="deep-orange lighten-4 red-text px-2 mx-1"
                            >
                              <small>Deletado</small>
                            </MDBRow>
                            <MDBRow className="py-2 mx-1 deep-orange lighten-4">
                              <MDBCol>Nome: {autuado.abordado.nome}</MDBCol>
                              <MDBCol>Tipo: {autuado.abordado.tipo}</MDBCol>
                              <MDBCol>CPF: {autuado.abordado.cpf}</MDBCol>
                            </MDBRow>
                          </>
                        )
                      )}
                      <br />
                      <MDBRow
                        center
                        className="font-weight-bolder"
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      >
                        Autuações:
                      </MDBRow>

                      {oco.item_grupo_tipo_ocorrencia.map(
                        (ocorrencia, index) => (
                          <div
                            key={`d${index}`}
                            style={{
                              backgroundColor: 'rgba(0,0,0,0.08)',
                              borderRadius: '5px',
                              // border: '2px red',
                            }}
                            className={`mt-2 p-0 mx-0 border ${
                              ocorrencia.ativo === false
                                ? 'deep-orange lighten-4 '
                                : ''
                            }`}
                          >
                            {ocorrencia.ativo === false ? (
                              <MDBRow
                                start
                                className="mx-0 text-danger font-italic"
                              >
                                Deletado
                              </MDBRow>
                            ) : (
                              ''
                            )}
                            <MDBRow className="mx-0">
                              <MDBCol size="12" md="6" className="m-2">
                                Tipo: {ocorrencia.tipo_ocorrencium.nome}
                              </MDBCol>
                              <MDBCol size="12" md="5" className="m-2">
                                Grupo: {ocorrencia.grupo.nome}
                              </MDBCol>

                              <MDBCol size="12" md="6" className="m-2">
                                Item: {ocorrencia.item.nome}
                              </MDBCol>
                              <MDBCol size="12" md="5" className="m-2">
                                Quantidade:{' '}
                                {`${
                                  ocorrencia.quantidade === null
                                    ? 0
                                    : ocorrencia.quantidade
                                }
                              (${ocorrencia.unidade_medida.nome &&
                                ocorrencia.unidade_medida.nome})

                                `}
                              </MDBCol>
                            </MDBRow>
                          </div>
                        )
                      )}

                      <br />
                      <MDBRow
                        center
                        className="font-weight-bolder"
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      >
                        Agentes Responsáveis:
                      </MDBRow>

                      {oco.equipe_ocorrencia.map(agente => (
                        <div className="mt-2">
                          <MDBRow className="p-2">
                            <MDBCol>
                              Nome: {agente.usuario.nome}{' '}
                              {agente.usuario.sobrenome}
                            </MDBCol>
                            <MDBCol>
                              Nome Social: {agente.usuario.nome_social}
                            </MDBCol>
                          </MDBRow>
                        </div>
                      ))}

                      <br />
                      <MDBRow
                        center
                        className="font-weight-bolder"
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      >
                        Dinâmica da Ocorrência:
                      </MDBRow>

                      {oco.ocorrencia_dinamicas.map((descricao, index) => (
                        <div
                          key={`e${index}`}
                          className={`mx-1 mt-2 p-2 ${
                            descricao.ativo
                              ? 'grey lighten-3 rounded'
                              : 'deep-orange lighten-4 rounded'
                          }`}
                          // className={`mt-2

                          // ${
                          //   descricao.ativo
                          //     ? 'text-success border-success'
                          //     : 'text-danger border-danger'
                          // } border rounded`}
                        >
                          <MDBRow between className="p-2">
                            <MDBCol
                              size="12"
                              md="6"
                              className="font-italic text-left"
                            >
                              {descricao.ativo ? (
                                <div className="green-text font-weight-bold">
                                  Ativa
                                </div>
                              ) : (
                                <div className="text-danger">Deletada</div>
                              )}
                            </MDBCol>
                            <MDBCol
                              size="12"
                              md="6"
                              className="font-italic text-right"
                            >
                              Data: {trataDate(descricao.createdAt)}
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="p-2 text-left">
                            <MDBCol> Dinâmica: {descricao.dinamica}</MDBCol>
                          </MDBRow>
                        </div>
                      ))}
                    </MDBCardBody>
                  </MDBCard>
                  <br />
                </>
              ))}
            </MDBContainer>
            <MDBContainer>
              <MDBRow center>
                <MDBCol
                  onClick={() => setToggleCollapse(!toggleCollapse)}
                  style={{ marginBottom: '1rem' }}
                  className="d-flex align-items-center font-weight-bold justify-content-center cursor-pointer"
                >
                  <MDBIcon icon="angle-double-down" />
                  <div className="ml-2">Visualizar Fotos</div>
                </MDBCol>
              </MDBRow>
              <MDBCollapse
                className="text-center"
                id="basicCollapse"
                isOpen={toggleCollapse}
              >
                {loadingBusca && (
                  <MDBRow center className="my-2 align-items-center">
                    <MDBSpinner small />{' '}
                    <span className="ml-2 font-weight-bolder ">Carregando</span>
                  </MDBRow>
                )}
                <MDBRow center>
                  {imagens.ok ? (
                    <MDBRow
                      center
                      className="py-3 font-weight-bold danger-text"
                    >
                      {imagens.ok}
                    </MDBRow>
                  ) : (
                    imagens.map(res => (
                      <MDBCol size="12" md="4">
                        <ZoomImage
                          src={`${Buffer.from(res.imagem, 'base64')}`}
                          alt="Zoom da Imagem"
                        />

                        {/* <img
                          key={res.id}
                          // id={res.id}

                          // style={{ objectFit: 'cover' }}

                          className="img-fluid col-12 my-3"
                          src={`${Buffer.from(res.imagem, 'base64')}`}
                          // onClick={e => {
                          //   setModalFoto({ id: res.id, base: res.imagem });
                          //   // src={imagens}
                          // }}
                        /> */}
                      </MDBCol>
                    ))
                  )}
                </MDBRow>
              </MDBCollapse>
            </MDBContainer>
          </MDBModalBody>
        )}
        <MDBModalFooter>
          {usuarioId === getPerfil().id ? (
            ''
          ) : getData().acessos.find(t => t.rota === '/revisao-abordagens') ===
              undefined ||
            getData().acessos.find(t => t.rota === '/revisao-abordagens') ===
              null ? (
            ''
          ) : (
            <MDBBtn
              onClick={() => setModalRevisao(true)}
              className="font-weight-bold"
            >
              Colocar em Revisao
            </MDBBtn>
          )}
          <MDBBtn color="secondary" onClick={() => setState(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>

        <MDBModal
          isOpen={modalRevisao}
          toggle={() => setModalRevisao(false)}
          fullHeight
          position="right"
        >
          <MDBModalHeader toggle={() => setModalRevisao(false)}>
            Colocar em Revisão
          </MDBModalHeader>
          <MDBModalBody>
            <Select
              placeholder="Selecione o Tipo"
              options={revisaoMotivos}
              onChange={e => setRevisaoMotivosSelecionado(e)}
            />
            <MDBInput
              placeholder="Descreva o Motivo da Revisão"
              outline
              rows="15"
              type="textarea"
              maxlength={600}
              counter={600}
              value={revisao}
              onChange={e => {
                const valor = e.target.value
                  .replace(/;/g, '.')
                  .replace(/"/g, "'");
                setRevisao(valor);
              }}
            />
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setModalRevisao(false)}>
              Cancelar
            </MDBBtn>
            <MDBBtn onClick={() => enviarRevisao()} color="success">
              Concluir
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={props.abordagem === abordagemSelecionada}
          size="lg"
          toggle={() => {
            setAbordagemSelecionada(false);
          }}
          // onClick={() => setAbordagemSelecionada('')}
        >
          <MDBModalHeader
            className="grey lighten-4"
            toggle={() => {
              setAbordagemSelecionada(false);
            }}
            // onClick={() => setAbordagemSelecionada('')}
          >
            <span className="text-weight-bold danger-text">ATENÇÃO</span>
          </MDBModalHeader>
          <MDBModalBody className="h4-responsive my-5 text-center">
            Você realmente deseja apagar esta abordagem e todas as ocorrências
            feitas a partir dela ?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => {
                setAbordagemSelecionada(false);
              }}
            >
              NÃO
            </MDBBtn>
            <MDBBtn
              onClick={() => excluiAbordagem(abordagemSelecionada)}
              color="primary"
            >
              SIM
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={duplicaAbordagem !== null}
          size="lg"
          toggle={() => {
            setDuplicaAbordagem(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setDuplicaAbordagem(null);
            }}
          >
            Duplicar Abordagem
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center className="font-weight-bolder  white-text">
              <MDBCol
                size="11"
                className="font-weight-bolder bg-danger pb-3 rounded"
              >
                <p className="h4-responsive font-weight-bolder">Atenção</p>{' '}
                <div className="text-center">
                  A duplicação gerará históricos que poderão ser auditados no
                  futuro.
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  placeholder="Descreva o Motivo da Duplicação"
                  outline
                  rows="15"
                  type="textarea"
                  disabled={botaoDuplicaConfirma}
                  maxLength={1200}
                  counter={1200}
                  onChange={e => setJustificaDuplicacao(e.target.value)}
                  // onChange={e => setRevisao(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {botaoDuplicaConfirma ? (
              <MDBRow center className="my-2 align-items-center">
                <MDBSpinner small />{' '}
                <span className="ml-2 font-weight-bolder ">Carregando</span>
              </MDBRow>
            ) : (
              <MDBBtn color="red" onClick={() => setDuplicaAbordagem(null)}>
                Cancelar
              </MDBBtn>
            )}

            <MDBBtn
              color="green"
              disabled={botaoDuplicaConfirma}
              onClick={() => replicarAbordagem(duplicaAbordagem)}
            >
              Confirmar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBModal>
    </>
  );
};
