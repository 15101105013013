import React from 'react';
// import imgloading from '../../assets/loading.gif';
// import { Container } from './styles';
import { MDBSpinner } from 'mdbreact';
export default function Loading(props) {
  return (
    <div
      // style={{
      // position: 'fixed',
      // zIndex: '1000',
      // marginTop: '45vh',
      // marginLeft: '3vw',
      // }}
      className="w-100 my-4 d-flex justify-content-center align-items-center"
    >
      <MDBSpinner crazy yellow />
      <span className="mx-3 font-weight-bold">Carregando...</span>
    </div>
  );
}
