import React from 'react';
import { useState, useEffect } from 'react';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  MDBAnimation,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import api from '../../../../../services/api';

export default function Autuacoes(props) {
  // const idReq = props.match.params.abordagem_id;

  // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  const [modalRemoveAutuacao, setModalRemoveAutuacao] = useState(null);
  const [modalAddAutuacao, setModalAddAutuacao] = useState(null);
  // const [autuados, setAutuados] = useState([]);
  // const [modalAutuado, setModalAutuado] = useState(false);
  // autuado selecionado
  // const [autuadoSelecionado, setAutuadoSelecionado] = useState({
  //   label: '',
  //   value: '',
  //   cpf: '',
  // });
  // const [ocorrenciaSelecionada, setOcorrenciaSelecionada] = useState(null);
  // const [selectAutuados, setSelectAutuados] = useState([]);
  // const modalAdicionaAutuado = oco_id => {
  //   setModalAutuado(!modalAutuado);
  //   PreencheSelectAutuados();
  //   setOcorrenciaSelecionada(oco_id);
  // };
  const [selectUnidadeMedida, setSelectUnidadeMedida] = useState([]);
  const [unidadeMedidaSelecionada, setUnidadeMedidaSelecionada] = useState({
    label: '',
    value: '',
  });
  const buscaUnidadeMedida = async () => {
    // const result = await api.get(`/unidade_medida/${item}`);
    const result = await api.get(`/unidade_medida`);

    const unidadeMedida = [];
    result.data.forEach(medida => {
      unidadeMedida.push({
        label: medida.nome,
        value: medida.id,
        unidade_medida_id: medida.unidade_medida_id,
      });
    });
    setSelectUnidadeMedida(unidadeMedida);
  };

  const [selectTiposOcorrencias, setSelectTiposOcorrencias] = useState([]);
  const [
    tiposOcorrenciasSelecionada,
    setTiposOcorrenciasSelecionada,
  ] = useState({ label: '', value: '' });

  const carregaSelectTipoOcorrencias = async naturezaId => {
    const result = await api.get(`/tipo-ocorrencias/${naturezaId}`);
    const tiposOcorrencias = [];
    result.data.forEach(tipos => {
      tiposOcorrencias.push({
        label: tipos.nome,
        value: tipos.id,
      });
    });
    setSelectTiposOcorrencias(tiposOcorrencias);
  };

  // LISTA DE GRUPOS
  const [selectGrupo, setSelectGrupo] = useState([]);
  // GRUPO SELECIONADO
  const [grupoSelecionado, setGrupoSelecionado] = useState({
    label: '',
    value: '',
  });
  const carregaSelectGrupo = async tipoId => {
    const result = await api.get(`/grupos/${tipoId}`);

    const grupos = [];
    result.data.forEach(grupo => {
      grupos.push({
        label: grupo.nome,
        value: grupo.id,
      });
    });
    setSelectGrupo(grupos);
  };
  const [selectItens, setSelectItens] = useState([]);
  // ITEM SELECIONADO
  const [itemSelecionado, setItemSelecionado] = useState({
    item_grupo_id: '',
    label: '',
    unidade_medida_id: '',
    value: '',
  });
  const buscaItensPorGrupo = async cargaId => {
    const result = await api.get(`/itens-por-grupo/${cargaId}`);

    const itens = [];
    result.data.forEach(carga => {
      itens.push({
        label: carga.item.nome,
        value: carga.item.id,
        item_grupo_id: carga.id,
        unidade_medida_id: carga.item.unidade_medida_id,
      });
    });
    setSelectItens(itens);
  };

  const [cargaQtd, setCargaQtd] = useState('');

  const adicionarAlteracao = async (natureza_id, razao_autuacao_id) => {
    setModalAddAutuacao(true);
    // console.log(natureza_id, razao_autuacao_id);

    carregaSelectTipoOcorrencias(natureza_id);
    buscaUnidadeMedida();
  };

  // ADD CARGA
  const adicionaAutuacao = async () => {
    if (tiposOcorrenciasSelecionada.value === '') {
      toast.error('Insira o Tipo');
      return;
    }
    if (grupoSelecionado.value === '') {
      toast.error('Insira o Grupo');
      return;
    }
    if (itemSelecionado.value === '') {
      toast.error('Insira o Item');
      return;
    }
    if (cargaQtd === '') {
      toast.error('Insira o Quantidade');
      return;
    }

    if (cargaQtd < 1) {
      toast.error('Quantidade Inválida');
      return;
    }
    if (unidadeMedidaSelecionada.value === '') {
      toast.error('Insira a Unidade de Medida');
      return;
    }
    setLoadingEnviar(true);

    const result = await api.post('/item-grupo-tipo-ocorrencia', {
      quantidade: cargaQtd,
      item_id: itemSelecionado.value,
      grupo_id: grupoSelecionado.value,
      tipo_ocorrencia_id: tiposOcorrenciasSelecionada.value,
      ocorrencia_id: props.ocorrencia_id,
      unidade_medida_id: unidadeMedidaSelecionada.value,
    });

    setLoadingEnviar(false);
    props.listar();
    // let duplicado = false;
    // cargas.forEach(carga => {
    //   if (
    //     carga.grupo.value === grupoSelecionado.value &&
    //     carga.item.value === itemSelecionado.value
    //   ) {
    //     toast.error('Carga já Inserida');
    //     duplicado = true;
    //     return;
    //   }
    // });
    // if (duplicado === true) {
    //   return;
    // }
    // setCargas([
    //   ...cargas,
    //   {
    //     tipo_ocorrencia_id: tiposOcorrenciasSelecionada.value,
    //     tipo_label: tiposOcorrenciasSelecionada.label,
    //     grupo: grupoSelecionado,
    //     item: itemSelecionado,
    //     unidade_medida: unidadeMedidaSelecionada,
    //     qtd: parseFloat(cargaQtd).toFixed(2),
    //   },
    // ]);

    // setCargaQtd();
    // setModalCarga(false);
  };

  const excluirAutuacao = async () => {
    setLoadingDeletar(true);
    const resultado = await api.put(
      `/item-grupo-tipo-ocorrencia/${modalRemoveAutuacao}`,
      {
        ativo: false,
      }
    );
    setLoadingDeletar(false);
    setModalRemoveAutuacao(null);
    resultado.data.ok
      ? toast.success(resultado.data.ok)
      : toast.error(resultado.data.error);
    props.listar();
  };
  // SELECT QUE PREENCHE AUTUADOS
  // const PreencheSelectAutuados = async () => {
  //   const select = [];
  //   setLoadingListar(true);
  //   const result = await api.get(`/sgo/busca-abordados/${props.abordagem_id}`);

  //   result.data.forEach(abordado => {
  //     select.push({
  //       label: abordado.nome,
  //       value: abordado.id,
  //       cpf: abordado.cpf,
  //     });
  //   });

  //   setSelectAutuados(select);
  //   setLoadingListar(false);
  // };

  return (
    <>
      <MDBRow
        center
        className="font-weight-bolder"
        style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
      >
        Autuações:
      </MDBRow>

      {props.tipo_ocorrencia.item_grupo_tipo_ocorrencia.map(
        item_grupo_tipo_ocorrencia =>
          item_grupo_tipo_ocorrencia.ativo === true ? (
            <div
              style={{
                backgroundColor: 'rgba(0,0,0,0.08)',
                borderRadius: '10px',
              }}
              className="mt-2"
            >
              <MDBRow className="p-2">
                <MDBCol size="12" md="6" className="m-2">
                  Tipo: {item_grupo_tipo_ocorrencia.tipo_ocorrencium.nome}
                </MDBCol>
                <MDBCol size="12" md="5" className="m-2">
                  Grupo: {item_grupo_tipo_ocorrencia.grupo.nome}
                </MDBCol>

                <MDBCol size="12" md="6" className="m-2">
                  Item: {item_grupo_tipo_ocorrencia.item.nome}
                </MDBCol>
                <MDBCol size="12" md="5" className="m-2">
                  Quantidade:{' '}
                  {`${item_grupo_tipo_ocorrencia.quantidade}
              ( ${item_grupo_tipo_ocorrencia.unidade_medida.nome} )`}
                </MDBCol>
              </MDBRow>

              <MDBRow end className="p-2">
                <div
                  style={{ cursor: 'pointer' }}
                  className="danger-text mx-3"
                  onClick={() => {
                    // console.log(item_grupo_tipo_ocorrencia.id);
                    setModalRemoveAutuacao(item_grupo_tipo_ocorrencia.id);
                    // setModalDeleteAbordado(abordado.id);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </div>
              </MDBRow>
            </div>
          ) : (
            ''
          )
      )}

      <MDBRow center>
        <MDBBtn
          color="mdb-color"
          onClick={() => {
            adicionarAlteracao(
              props.tipo_ocorrencia.razao_autuacao.natureza_ocorrencia_id,
              props.tipo_ocorrencia.razao_autuacao_id
            );
          }}
        >
          Adicionar Autuação
        </MDBBtn>
      </MDBRow>

      {/* MODAL REMOVE GRUPO TIPO OCORRENCIA */}
      <MDBModal
        isOpen={modalRemoveAutuacao !== null}
        size="lg"
        toggle={() => {
          setModalRemoveAutuacao(null);
        }}
        // onClick={() => setOcorrenciaSelecionada('')}
      >
        <MDBModalHeader
          className="grey lighten-4"
          toggle={() => {
            setModalRemoveAutuacao(null);
          }}
          // onClick={() => setOcorrenciaSelecionada('')}
        >
          <span className="text-weight-bold danger-text">ATENÇÃO</span>
        </MDBModalHeader>
        <MDBModalBody className="h4-responsive my-5 text-center">
          Deseja realmente remover esta autuação?
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color="secondary"
            onClick={() => {
              setModalRemoveAutuacao(null);
            }}
          >
            NÃO
          </MDBBtn>
          <MDBBtn
            disabled={loadingDeletar}
            onClick={() => excluirAutuacao()}
            color="primary"
          >
            {loadingDeletar ? 'Removendo' : 'SIM'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA CRIACAO DE AUTUACOES */}
      <MDBModal
        isOpen={modalAddAutuacao}
        toggle={() => {
          setModalAddAutuacao(!modalAddAutuacao);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAddAutuacao(!modalAddAutuacao);
          }}
        >
          Inserir Autuado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" middle>
              <Select
                placeholder="Tipo"
                options={selectTiposOcorrencias}
                value={
                  tiposOcorrenciasSelecionada.value === ''
                    ? null
                    : tiposOcorrenciasSelecionada
                }
                onChange={e => {
                  setTiposOcorrenciasSelecionada({
                    label: e.label,
                    value: e.value,
                  });
                  carregaSelectGrupo(e.value);
                }}
              />
            </MDBCol>

            <MDBCol className="mt-3" size="12" middle>
              <Select
                placeholder="Grupo"
                options={selectGrupo}
                value={grupoSelecionado.value === '' ? null : grupoSelecionado}
                onChange={e => {
                  setGrupoSelecionado({ label: e.label, value: e.value });
                  buscaItensPorGrupo(e.value);
                }}
              />
            </MDBCol>

            <MDBCol className="mt-3" size="12" middle>
              <Select
                placeholder="Item"
                options={selectItens}
                value={itemSelecionado.value === '' ? null : itemSelecionado}
                onChange={e => {
                  setItemSelecionado({
                    item_grupo_id: e.item_grupo_id,
                    label: e.label,
                    unidade_medida_id: e.unidade_medida_id,
                    value: e.value,
                  });
                  buscaUnidadeMedida(e.unidade_medida_id);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow center className="text-danger font-weight-bolder">
            * Digitar (ponto / virgula) somente quando houver decimais
          </MDBRow>
          <MDBRow>
            <MDBCol middle>
              <MDBInput
                className="text-danger"
                onChange={e => {
                  setCargaQtd(e.target.value);
                }}
                value={cargaQtd}
                size="12"
                md="6"
                label="Quantidade*"
                type="number"
                min="1"
              />
            </MDBCol>
            <MDBCol middle size="12" md="6">
              <Select
                placeholder="Unidade de Medida"
                options={selectUnidadeMedida}
                value={
                  unidadeMedidaSelecionada.value === ''
                    ? null
                    : unidadeMedidaSelecionada
                }
                onChange={e => {
                  setUnidadeMedidaSelecionada({
                    label: e.label,
                    value: e.value,
                  });
                }}
              />
            </MDBCol>
          </MDBRow>

          <br />

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalAddAutuacao(!modalAddAutuacao);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            disabled={loadingEnviar}
            onClick={() => {
              adicionaAutuacao();
            }}
            color="success"
          >
            {loadingEnviar ? 'Carregando...' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
