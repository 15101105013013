import React, { Component } from 'react';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';

class ModalPage extends Component {
  state = {
    modal: false,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    return (
      <MDBContainer>
        {/* BUTTON */}
        <img
          style={{ cursor: 'pointer', width: '100px' }}
          onClick={this.toggle}
          src={this.props.teste}
          className="img-fluid"
          alt=""
        />

        {/* MODAL */}
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>
            {this.props.title}
          </MDBModalHeader>
          <img
            style={{ cursor: 'pointer' }}
            onClick={this.toggle}
            src={this.props.teste}
            className="img-fluid"
            alt=""
          />
          <MDBModalBody />
          <MDBModalFooter>
            <MDBBtn color="gray" onClick={this.toggle}>
              Fechar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}
export default ModalPage;
