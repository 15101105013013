import React, { useEffect, useState } from 'react';

import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import Select from 'react-select';

import { getData, getPerfil } from '../../../services/auth';
import NavBar from '../../../components/NavBar';
import Loading from '../../../components/Loading';
import Add from './components/Add';
// import { Link } from 'react-router-dom';
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBSwitch,
  MDBIcon,
  MDBSpinner,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from 'mdbreact';
// import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

import { toast } from 'react-toastify';
import { format } from 'date-fns';

// import { Container } from './styles';

export default function Bdts(props) {
  const [loadingSelect, setLoadingSelect] = useState(false);

  // const [qtdTotal, setQtdTotal] = useState(0);
  // const [page, setPage] = useState('1');

  // BDT DE FROTA

  const [bdtList, setBdtList] = useState([]);

  const [modelVer, setModelVer] = useState(null);

  const [bdtShow, setBdtShow] = useState({});

  const [bdtLoadingLista, setBdtLoadingLista] = useState(false);

  const listaBdts = async () => {
    setBdtLoadingLista(true);

    const result = await api.get('/bdts-listar', {
      params: {
        fk_usuario_id: getPerfil().id,
        dispensaAberta: false,
      },
    });

    setBdtLoadingLista(false);
    setBdtList(result.data);
  };

  const buscaBdt = async bdtId => {
    const result = await api.get('/bdts', {
      params: {
        id: bdtId,
      },
    });
    setBdtShow(result.data);
  };

  useEffect(() => {
    if (modelVer === null) {
      return;
    }
    buscaBdt(modelVer);
  }, [modelVer]);
  useEffect(() => {
    listaBdts();
  }, []);

  return (
    <NavBar>
      <br />
      <Add listaBdts={() => listaBdts()} />
      <br />
      <MDBRow center className="h4-responsive">
        Meus Boletins de trânsito
      </MDBRow>
      <MDBRow
        style={{ borderRadius: '5px' }}
        className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
      >
        <MDBCol size="12" md="2">
          Placa
        </MDBCol>
        <MDBCol size="12" md="2">
          Modelo
        </MDBCol>
        <MDBCol size="12" md="2">
          Data Início
        </MDBCol>
        <MDBCol size="12" md="2">
          Data Fim
        </MDBCol>
        <MDBCol size="12" md="2">
          KM inicial
        </MDBCol>
        <MDBCol size="12" md="2" />
      </MDBRow>
      {bdtLoadingLista ? (
        <Loading />
      ) : (
        <>
          {bdtList.map(e => (
            <MDBRow
              key={`li${e.id}`}
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3  bg-white font-weight-bolder"
            >
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Placa:</span>
                <span>{e.veiculo.placa}</span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Modelo:</span>
                <span>{e.veiculo.modelo}</span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Data Inicio:</span>
                <span>
                  {format(new Date(e.data_apresentacao), 'dd/MM/yyyy HH:mm')}
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">
                  Data Dispensa:
                </span>
                <span>
                  {format(new Date(e.data_dispensa), 'dd/MM/yyyy HH:mm')}
                </span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">KM inicial:</span>
                <span>{e.quilometragem_inicio}</span>
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-end">
                <div
                  onClick={() => setModelVer(e.id)}
                  className="text-danger cursor-pointer"
                >
                  <MDBIcon className="mr-1" far icon="edit" size="1x" />
                  <span>Ver</span>
                </div>
              </div>
            </MDBRow>
          ))}
        </>
      )}

      <MDBModal
        toggle={() => {
          setModelVer(null);
        }}
        size="lg"
        isOpen={modelVer !== null}
      >
        {/* <MDBModalHeader></MDBModalHeader> */}
        <MDBModalHeader>
          <MDBRow center>
            {/* <MDBCol md="2"></MDBCol> */}
            <MDBCol>Boletim de Tráfego</MDBCol>
            {/* <MDBCol
                    className="cursor-pointer"
                    md="2"
                    onClick={() => bdtAberto()}
                  >
                    <MDBIcon icon="redo" /> Atualizar
                  </MDBCol> */}
          </MDBRow>
        </MDBModalHeader>
        <MDBModalBody className="pb-2">
          <MDBRow>
            <MDBCol
              size="12"
              md="6"
              className="mt-2 d-flex  justify-content-between justify-content-md-start"
            >
              <span>GERÊNCIA:</span>

              <span className="text-danger font-weight-bolder mx-2 ">
                {bdtShow?.veiculo?.veiculos_gerencia[0].gerencia.nome}
              </span>
            </MDBCol>
            <MDBCol
              size="12"
              md="6"
              className="mt-2 d-flex  justify-content-between justify-content-md-start"
            >
              <span>USUÁRIO: </span>
              <span className="text-danger font-weight-bolder mx-2">
                {bdtShow?.usuario?.nome_social}
              </span>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              size="12"
              md="6"
              className="mt-2 d-flex  justify-content-between justify-content-md-start"
            >
              <span>PLACA:</span>
              <span className="text-danger font-weight-bolder mx-2">
                {bdtShow?.veiculo?.placa}
              </span>
            </MDBCol>
            <MDBCol
              size="12"
              md="6"
              className="mt-2 d-flex  justify-content-between justify-content-md-start"
            >
              <span>KM INICIAL:</span>
              <span className="text-danger font-weight-bolder mx-2">
                {bdtShow?.quilometragem_inicio}
              </span>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              size="12"
              md="6"
              className="mt-2 d-flex  justify-content-between justify-content-md-start"
            >
              <span>INÍCIO:</span>
              <span className="text-danger font-weight-bolder mx-2">
                {bdtShow.data_apresentacao &&
                  format(
                    new Date(bdtShow?.data_apresentacao),
                    'dd/MM/yyyy hh:mm'
                  )}
              </span>
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow>
            <MDBCol className="h4-responsive">Abastecimentos</MDBCol>
          </MDBRow>
          {bdtShow.abastecimentos && bdtShow.abastecimentos.length === 0 ? (
            <MDBRow
              center
              className="text-danger h5-responsive font-weight-bolder"
            >
              <i>Sem Abastecimentos</i>
            </MDBRow>
          ) : (
            bdtShow.abastecimentos &&
            bdtShow.abastecimentos.map(oco => (
              <div
                key={`loco${oco.id}`}
                className="grey lighten-3 p-3 mt-2"
                style={{ borderRadius: '10px' }}
              >
                <MDBRow between className="mt-2">
                  <MDBCol>Data:</MDBCol>
                  <MDBCol>
                    {oco.data_abastecimento &&
                      format(
                        new Date(oco.data_abastecimento),
                        'dd/MM/yyyy hh:mm'
                      )}
                  </MDBCol>
                </MDBRow>
                <MDBRow between className="mt-2">
                  <MDBCol>Quilometragem:</MDBCol>
                  <MDBCol>{oco?.quilometragem}</MDBCol>
                </MDBRow>
                <MDBRow between className="mt-2">
                  <MDBCol>Quantidade:</MDBCol>
                  <MDBCol>{oco?.quantidade} Litros</MDBCol>
                </MDBRow>
                <MDBRow between className="mt-2">
                  <MDBCol>Valor:</MDBCol>
                  <MDBCol>
                    R$ {oco?.valor_total.toString().replace('.', ',')}
                  </MDBCol>
                  {/* Valor: R${oco?.valor_total.toString().replace('.', ',')} */}
                </MDBRow>
                <MDBRow between className="mt-2">
                  <MDBCol>Combustivel:</MDBCol>
                  <MDBCol>
                    {oco?.tipos_combustivei.tipo_combustivel
                      .toString()
                      .replace('.', ',')}
                  </MDBCol>
                </MDBRow>
              </div>
            ))
          )}

          <br />
          <MDBRow>
            <MDBCol className="h4-responsive">Descrições</MDBCol>
          </MDBRow>
          {bdtShow.bdts_ocorrencias && bdtShow.bdts_ocorrencias.length === 0 ? (
            <MDBRow
              center
              className="text-danger h5-responsive font-weight-bolder"
            >
              <i>Sem Descrições</i>
            </MDBRow>
          ) : (
            bdtShow.bdts_ocorrencias &&
            bdtShow.bdts_ocorrencias.map(oco => (
              <div key={`show${oco.id}`} className="mx-2">
                <MDBRow
                  center
                  style={{ borderRadius: '10px 10px 0px 0px' }}
                  className="p-2 mt-2 align-items-center grey darken-2 text-white font-weight-bolder"
                >
                  Data:{' '}
                  {oco.createdAt &&
                    format(new Date(oco?.createdAt), 'dd/MM/yyyy hh:mm')}
                </MDBRow>
                <MDBRow className="p-2 align-items-center grey lighten-3 text-danger font-weight-bolder">
                  <MDBCol className="font-weight-italic">
                    Tipo: {oco?.tipo_bdts_ocorrencia?.tipo_ocorrencia}
                  </MDBCol>
                </MDBRow>
                <MDBRow
                  style={{ borderRadius: '0px 0px 10px 10px' }}
                  className="p-2 align-items-center grey lighten-3 text-dark"
                >
                  <MDBCol> {oco?.descricao}</MDBCol>
                </MDBRow>
              </div>
            ))
          )}

          <br />
          <br />
          {/* <MDBRow center>
                <MDBBtn
                  className="d-flex align-items-center justify-content-center"
                  size="md"
                  onClick={() => setModelVer(null)}
                >
                  <MDBIcon far icon="plus-square" size="2x" />
                  <span className="ml-2">Alteração</span>
                </MDBBtn>
                <MDBBtn
                  className="d-flex align-items-center justify-content-center"
                  size="md"
                  onClick={() => setModalAbastecimento(true)}
                >
                  <MDBIcon far icon="plus-square" size="2x" />
                  <span className="ml-2">Abastecimento</span>
                </MDBBtn>
              </MDBRow> */}
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn onClick={() => setModelVer(null)} color="danger" size="sm">
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <br />
    </NavBar>
  );
}
