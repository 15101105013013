import React from 'react';
import { useState, useEffect } from 'react';
import MaskReal from '../../../../components/MaskReal';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  MDBAnimation,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import axios from 'axios';
import api from '../../../../services/api';

import { validarCNPJ } from '../../Components/ValidarCnpj';
import { QRCodeScanner } from '../../../../components/QRCodeScanner';
export default function Abordados(props) {
  // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  // LISTA DE EMPRESAS
  const [empresas, setEmpresas] = useState([]);

  // MODAIS DE EMPRESA - ADD E DELETE
  const [modalAddEmpresa, setModalAddEmpresa] = useState(null);
  const [modalDeleteEmpresa, setModalDeleteEmpresa] = useState(null);

  // DADOS
  const [cnpj, setCnpj] = useState('');
  const [empresaNome, setEmpresaNome] = useState('');

  const [modalDeleteAbordado, setModalDeleteAbordado] = useState('');

  // MODAL NOTA ID
  const [modalNotaId, setModalNotaId] = useState(null);
  const [switchTipoNota, setSwitchTipoNota] = useState(false);
  const [modalDeleteNodaId, setModalDeleteNodaId] = useState(null);

  const [numero, setNumero] = useState('');
  const [chave, setChave] = useState('');
  const [valor, setValor] = useState('');
  const [icms, setIcms] = useState('');

  const [dadosMdfe, setDadosMdfe] = useState({});

  useEffect(() => {
    setNumero('');
    setChave('');
    setValor('');
    setIcms('');
  }, [switchTipoNota]);

  const listaEmpresas = async () => {
    setLoadingListar(true);
    const lista = await api.get(`/empresas-abordagem/${props.abordagemId}`, {
      params: {
        ativo: true,
      },
    });

    // filtra somente os documentos ativos
    const empresas = [];
    lista.data.forEach(empresa => {
      let novaArray = empresa.documento_fiscals.filter(
        doc => doc.ativo === true
      );
      empresas.push({ ...empresa, documento_fiscals: novaArray });
    });

    setLoadingListar(false);
    setEmpresas(empresas);
  };

  const adicionaEmpresa = async () => {
    const validade = validarCNPJ(cnpj);

    if (validade === false) {
      toast.error('CNPJ inválido');
      return;
    }
    if (empresaNome.length > 150) {
      toast.error('Excedeu limite de caracteres');
      return;
    }
    setLoadingEnviar(true);
    const teste = await api.post(`/empresas`, {
      abordagem_id: props.abordagemId,
      cnpj: cnpj,
      nome: empresaNome,
    });
    setLoadingEnviar(false);
    listaEmpresas();

    // let duplicado = false;
    // empresas.forEach(emp => {
    //   if (emp.cnpj === cnpj) {
    //     duplicado = true;

    //     return;
    //   }
    // });
    // if (duplicado === true) {
    //   toast.error('CNPJ já inserido');
    //   return;
    // }

    // const empresa = {};

    // setEmpresas([
    //   ...empresas,
    //   {
    //     // abordagemTipoPlacaChassi: switchChassi === false ? 'Placa' : 'Chassi',
    //     cnpj,
    //     empresaNome,
    //     notas: [],
    //   },
    // ]);
    setCnpj('');
    setEmpresaNome('');
    setModalAddEmpresa(null);
    // setModalEmpresa('false');
  };

  const handlePostRequest = async url => {
    const result = url.split('MDFe=');
    const valor = result[1].split('&');

    const requestBody = {
      // Seu corpo de requisição aqui
      chave: valor[0],
    };

    try {
      const response = await axios.post(
        'https://ok.nuvem.live/api/mdfe',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer SaR1fBG!988X6FW3jkca-zYFjsHMXTvr7hikQLCQKzqWsA==frkto0=7htTdsUylNIRd?cb2b7badnp-!Q40KHzFng7hel5eEj0vdT8k2fDmumI/8x6JcUhU2lGzTRq1`,
          },
        }
      );
      setNumero(response.data.dados_mdfe.numero);
      setDadosMdfe({
        data_emissao: response.data.dados_mdfe.data_emissao,
        uf_inicio: response.data.dados_mdfe.uf_inicio,
        uf_fim: response.data.dados_mdfe.uf_fim,
        cnpj: response.data.emitente.cnpj,
        eventos_servicos: response.data.eventos_servicos,
      });
    } catch (error) {
      toast.error('QRCode inválido');
      return;
      console.error('Erro ao fazer a requisição:', error);
    }
  };

  const adicionaNota = async () => {
    if (numero && numero !== '' && numero.length > 20) {
      toast.error('Máximo de caracteres excedidos');
      return;
    }
    if (chave && chave !== '' && chave.length > 255) {
      toast.error('Máximo de caracteres excedidos');
      return;
    }
    // se for manifesto
    if (switchTipoNota === true) {
      if (numero === '') {
        toast.error('Preencha o campo Número');
        return;
      }
      setLoadingEnviar(true);
      const novaNota = await api.post('/documento-fiscal', {
        tipo: 'Manifesto',
        numero: numero,
        chave: chave,
        abordagem_id: props.abordagemId,
        abordagem_empresa_id: modalNotaId,
      });
      setLoadingEnviar(false);

      setSwitchTipoNota(false);
      // setEmpresas(addNotas);
      setNumero('');
      setChave('');
      setValor('');
      setIcms('');
      // setIdEmpresaParaNota('');
      setModalNotaId(null);
      setDadosMdfe({});
      listaEmpresas();
    } else {
      if (chave === '') {
        toast.error('Preencha o campo Chave');
        return;
      }
      if (valor === '') {
        toast.error('Preencha o campo valor');
        return;
      }
      if (icms === '') {
        toast.error('Preencha o campo icms');
        return;
      }
      setLoadingEnviar(true);
      const novaNota = await api.post('/documento-fiscal', {
        tipo: 'Nota Fiscal',
        numero: numero,
        chave: chave,
        valor: valor.replace(',', '.'),
        icms: icms.replace(',', '.'),
        abordagem_id: props.abordagemId,
        abordagem_empresa_id: modalNotaId,
      });
      setLoadingEnviar(false);
      // setEmpresas(addNotas);
      setNumero('');
      setChave('');
      setValor('');
      setIcms('');
      // setIdEmpresaParaNota('');
      setDadosMdfe({});
      setModalNotaId(null);
      listaEmpresas();
    }
  };

  const deletarNota = async () => {
    setLoadingDeletar(true);
    const result = await api.put(`/documento-fiscal/${modalDeleteNodaId}`);
    setModalDeleteNodaId(null);
    setLoadingDeletar(false);
    listaEmpresas();
  };

  // FUNCOES DE ABORDADOS
  // ADICIONA ABORDADO

  // REMOVE ABORDADO
  const deletarEmpresa = async idEmpresa => {
    setLoadingDeletar(true);
    const deletado = await api.put(`/empresas-abordagem/${idEmpresa}`);
    setModalDeleteEmpresa(null);
    listaEmpresas();
    setLoadingDeletar(false);
    // if (deletado.data.error) {
    //   toast.error(deletado.data.error);
    //   setLoadingDeletar(false);
    //   return;
    // }
    // if (deletado.data.ok) {
    //   toast.success(deletado.data.ok);
    //   setLoadingDeletar(false);
    // }

    // setLoadingDeletar(false);
    // setModalDeleteAbordado('');
    // listaEmpresas();
  };

  // const alteraAbordado = async () => {
  //   setLoadingDeletar(true);
  //   const { id, ...resto } = abordadoUpdate;
  //   const data = parseISO(abordadoUpdate.cnh_validade);
  //   const alterado = await api.put(`/abordado-abordagem/${id}`, {
  //     ...resto,
  //     cnh_validade: data,
  //   });

  //   if (alterado.data.error) {
  //     toast.error(alterado.data.error);
  //     return;
  //   }
  //   if (alterado.data.ok) {
  //     toast.success(alterado.data.ok);
  //   }

  //   setLoadingDeletar(false);
  //   setAbordadoUpdate(null);
  //   listaEmpresas();
  // };

  const handleQRCodeValue = value => {
    setChave(value);
    handlePostRequest(value);
  };

  useEffect(() => {
    listaEmpresas();
    // teste();

    // listaTiposVeiculos();
  }, [props.abordagemId]);

  return (
    <>
      {loadingListar === true ? (
        <div className="d-flex justify-content-center align-items-center">
          <MDBSpinner />
          <div className="ml-3 d-flex align-items-center font-weight-bolder">
            Carregando
          </div>
        </div>
      ) : empresas.length === 0 ? (
        <MDBRow center>Sem Empresas</MDBRow>
      ) : (
        empresas.map(empresa => (
          <>
            <MDBRow
              className="p-1 mt-3 mdb-color lighten-4"
              // style={{
              //   backgroundColor: 'rgba(0,0,0,0.1)',
              // }}
            >
              <MDBCol>CNPJ: {empresa.cnpj}</MDBCol>
              {empresa.nome === '' || empresa.nome === null ? (
                ''
              ) : (
                <MDBCol>Nome: {empresa.nome}</MDBCol>
              )}
              <MDBCol className="text-right d-print-none">
                <span
                  className="cursor-pointer danger-text "
                  onClick={() => {
                    setModalDeleteEmpresa(empresa.id);
                    // deletarEmpresa(empresa.id);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </span>
              </MDBCol>
            </MDBRow>
            {empresa.documento_fiscals.map(documento => (
              <>
                <MDBRow
                  className="mx-1 py-2"
                  style={{
                    borderTop: '2px solid rgba(0,0,0,0.1)',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  }}
                >
                  <MDBCol size="12" md="3">
                    Tipo: {documento.tipo}
                  </MDBCol>
                  {documento.numero === '' || documento.numero === null ? (
                    ''
                  ) : (
                    <MDBCol size="12" md="3">
                      Número: {documento.numero}
                    </MDBCol>
                  )}

                  {documento.valor === '' || documento.valor === null ? (
                    ''
                  ) : (
                    <MDBCol size="12" md="3">
                      Valor: R$ {documento.valor.toString().replace('.', ',')}
                    </MDBCol>
                  )}
                  {documento.icms === '' || documento.icms === null ? (
                    ''
                  ) : (
                    <MDBCol size="12" md="3">
                      ICMS: R$ {documento.icms.toString().replace('.', ',')}
                    </MDBCol>
                  )}
                </MDBRow>

                <MDBRow
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  }}
                  className="mx-1 py-2"
                >
                  {documento.chave === '' || documento.chave === null ? (
                    ''
                  ) : (
                    <MDBCol>
                      {documento.chave.split('https').length > 1 ? (
                        <>
                          Chave: {documento.chave}
                          <br />
                          <a href={documento.chave}>
                            Veja o Manifesto Completo
                          </a>
                        </>
                      ) : (
                        `Chave: ${documento.chave}`
                      )}
                    </MDBCol>
                  )}
                </MDBRow>
                <MDBRow
                  style={{
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  }}
                  className="mx-1"
                >
                  <MDBCol className="d-flex justify-content-end align-items-center">
                    <span
                      className="cursor-pointer danger-text "
                      onClick={() => {
                        setModalDeleteNodaId(documento.id);
                        // deletarDocumento(documento.id)
                      }}
                    >
                      <MDBIcon
                        className="mr-1 "
                        far
                        icon="trash-alt"
                        size="1x"
                      />
                      <span style={{ fontSize: '14px' }}>Remover</span>
                    </span>
                  </MDBCol>
                </MDBRow>
              </>
            ))}
            <MDBRow
              center
              className="mx-1 py-2"
              style={{
                backgroundColor: 'rgba(0,0,0,0.15)',
              }}
            >
              <MDBBtn
                color="warning"
                size="12"
                md="12"
                className="text-center my-2 "
                onClick={() => {
                  setModalNotaId(empresa.id);
                }}
              >
                <MDBIcon className="black-text" icon="plus" />
                <span className="pl-2 black-text">Documento Fiscal</span>
              </MDBBtn>
            </MDBRow>
          </>
        ))
      )}

      <br />
      <MDBRow center>
        <MDBBtn
          color="mdb-color "
          onClick={() => {
            setModalAddEmpresa(!modalAddEmpresa);
          }}
        >
          Adicionar Empresa
        </MDBBtn>
      </MDBRow>

      {/* MODAL PARA CRIAÇÃO DE EMPRESAS */}
      <MDBModal
        size="lg"
        isOpen={modalAddEmpresa !== null}
        toggle={() => {
          setModalAddEmpresa(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAddEmpresa(null);
          }}
        >
          Inserir Empresa Abordada
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className="w-100 font-weight-light md-form form-group form-lg"
                type="text"
                placeholder="CNPJ"
                onChange={e => setCnpj(e.target.value.replace(/\D+/g, ''))}
                value={cnpj}
                mask="99.999.999/9999-99"
                maskChar=" "
              />
            </MDBCol>
            <MDBCol size="12" md="8" middle>
              <MDBInput
                type="text"
                label="Nome"
                onChange={e => setEmpresaNome(e.target.value)}
                value={empresaNome}
                counter={150}
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalAddEmpresa(null);
            }}
          >
            Cancelar
          </button>
          <MDBBtn
            disabled={loadingEnviar}
            onClick={() => {
              adicionaEmpresa();
            }}
            className="btn btn-success"
          >
            {loadingEnviar ? 'Carregando' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA CRIAÇÃO DE NOTAS */}
      <MDBModal
        size="lg"
        isOpen={modalNotaId !== null}
        toggle={() => {
          setModalNotaId(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalNotaId(null);
          }}
        >
          Inserir Nota Fiscal
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <div className="text-center">
              <span className="h4-responsive">Condutor do veículo</span>

              <MDBSwitch
                checked={switchTipoNota}
                labelLeft="Nota Fiscal"
                labelRight="Manifesto"
                onChange={() => {
                  setSwitchTipoNota(!switchTipoNota);
                }}
              />
            </div>
          </MDBRow>
          {switchTipoNota === false ? (
            <MDBAnimation>
              <MDBRow>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Danfe"
                    counter={20}
                    onChange={e => setNumero(e.target.value)}
                    value={numero}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Chave"
                    counter={255}
                    onChange={e => setChave(e.target.value)}
                    value={chave}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    label="Valor"
                    onChange={e => setValor(MaskReal(e.target.value))}
                    value={valor}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    label="ICMS"
                    onChange={e => setIcms(MaskReal(e.target.value))}
                    value={icms}
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          ) : (
            <MDBAnimation>
              <MDBRow>
                <MDBCol>
                  <QRCodeScanner onQRCodeScan={handleQRCodeValue} />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Número do Manifesto"
                    onChange={e => setNumero(e.target.value)}
                    value={numero}
                    counter={20}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Chave"
                    onChange={e => setChave(e.target.value)}
                    value={chave}
                    counter={255}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {dadosMdfe.data_emissao &&
                    `Data da Emissao: ${format(
                      new Date(dadosMdfe.data_emissao),
                      'dd/MM/yyyy HH:mm'
                    )}`}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {dadosMdfe.uf_inicio && ` uf_inicio: ${dadosMdfe.uf_inicio}`}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {dadosMdfe.uf_fim && ` uf_fim: ${dadosMdfe.uf_fim}`}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>{dadosMdfe.cnpj && `cnpj: ${dadosMdfe.cnpj}`}</MDBCol>
              </MDBRow>

              {dadosMdfe.eventos_servicos &&
                dadosMdfe.eventos_servicos.map(serv => (
                  <MDBRow>
                    <MDBCol>
                      Data Autorizacao:
                      {format(
                        new Date(serv.data_autorizacao),
                        'dd/MM/yyyy HH:mm'
                      )}
                    </MDBCol>
                    <MDBCol>Evento: {serv.evento}</MDBCol>
                  </MDBRow>
                ))}
            </MDBAnimation>
          )}

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalNotaId(null);
            }}
          >
            Cancelar
          </button>
          <MDBBtn
            disabled={loadingEnviar}
            onClick={() => {
              adicionaNota();
            }}
            className="btn btn-success"
          >
            {loadingEnviar ? 'Carregando' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR NOTAS */}
      <MDBModal
        isOpen={modalDeleteNodaId !== null}
        toggle={() => {
          setModalDeleteNodaId(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteNodaId(null);
          }}
        >
          Deletar Nota Fiscal
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar essa nota fiscal?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteNodaId(null);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            disabled={loadingDeletar}
            onClick={() => {
              deletarNota();
              // setModalDeleteNota(!modalDeleteNota);
            }}
            color="warning"
          >
            {loadingDeletar ? 'Deletando' : 'Deletar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR EMPRESAS */}
      <MDBModal
        isOpen={modalDeleteEmpresa}
        toggle={() => {
          setModalDeleteEmpresa(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteEmpresa(null);
          }}
        >
          Deletar Empresa Abordada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar essa empresa?
          <br />
          <br />
          Obs: Todos os documentos fiscais desta empresa também serão deletadas
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteEmpresa(null);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            disabled={loadingDeletar}
            onClick={() => {
              deletarEmpresa(modalDeleteEmpresa);
              // setModalDeleteEmpresa(!modalDeleteEmpresa);
            }}
            color="warning"
          >
            {loadingDeletar ? 'Deletando' : 'Deletar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
