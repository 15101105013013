import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBRow,
  MDBCol,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNav,
  MDBSideNavLink,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBContainer,
} from 'mdbreact';

import logoHorizontal from '../../assets/logohocohorizontal.png';
import { logout } from '../../services/auth';
import api from '../../services/api';

export default function NavbarPage(props) {
  const [alertas, setAlertas] = useState([]);
  const history = useHistory();
  const specialCaseNavbarStyles = {
    WebkitBoxOrient: 'horizontal',
    flexDirection: 'row',
  };
  const [isOpen, setIsOpen] = useState(false);

  const sair = () => {
    logout();
    history.push('/');
  };

  const buscaAlertas = async () => {
    const result = await api.get('/alertas');

    setAlertas(result.data);
  };

  useEffect(() => {
    buscaAlertas();
  }, []);

  return (
    <>
      <MDBNavbar color="white" light expand="md">
        <MDBNavbarBrand className="m-0 p-0">
          <img style={{ width: '108px' }} src={logoHorizontal} />
        </MDBNavbarBrand>
        <MDBNavbarNav right style={specialCaseNavbarStyles}>
          <MDBNavItem
            onClick={() => setIsOpen(true)}
            className="d-flex align-items-center mr-3 cursor-pointer"
            style={{ position: 'relative' }}
          >
            <MDBIcon far icon="bell" style={{ fontSize: '20px' }} />
            {alertas.length > 0 && (
              <span
                style={{
                  position: 'absolute',
                  top: '30px',
                  right: '20px',
                  transform: 'translate(50%, -50%)',
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '50%',
                  padding: '0.25em 0.5em',
                  fontSize: '0.75em',
                  fontWeight: 'bold',
                }}
              >
                {alertas.length}
              </span>
            )}
          </MDBNavItem>

          <MDBNavItem>
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                <MDBIcon icon="user" />
              </MDBDropdownToggle>

              <MDBDropdownMenu right>
                <MDBDropdownItem
                  onClick={() => {
                    history.push('/meu-perfil');
                  }}
                >
                  Meu Perfil
                </MDBDropdownItem>
                <MDBDropdownItem onClick={() => sair()}>Sair</MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBNavbar>
      <MDBModal
        overflowScroll
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        size="lg"
      >
        <MDBModalHeader
          className=" font-weight-bold"
          toggle={() => setIsOpen(false)}
        >
          Meus Alertas
        </MDBModalHeader>
        <MDBModalBody>
          <MDBContainer>
            {alertas && alertas.length === 0 ? (
              <MDBRow>
                <MDBCol className="green-text h3 text-center">
                  Parabéns você não tem alertas
                </MDBCol>
              </MDBRow>
            ) : (
              <MDBRow
                center
                className="text-danger h5-responsive font-weight-bold mb-5"
              >
                Atenção, os alertas somente serão excluidos quando o sistema
                detectar que o erro foi sanado.
              </MDBRow>
            )}
            {alertas &&
              alertas.length > 0 &&
              alertas.map(alertas => (
                <MDBRow className="p-3 font-weight-bolder border-top ">
                  <MDBCol middle size="12" md="6">
                    {alertas.texto}
                  </MDBCol>
                  {alertas.link && (
                    <MDBCol
                      size="12"
                      md="6"
                      className="d-flex justify-content-center justify-content-md-end "
                    >
                      <Link
                        to={alertas.link}
                        className="d-flex flex-column align-items-center btn btn-sm font-weight-bold"
                        style={{
                          color: 'rgba(0,0,0,0.6)',
                          maxWidth: '120px',
                        }}
                      >
                        ABRIR
                      </Link>
                    </MDBCol>
                  )}
                </MDBRow>
              ))}
          </MDBContainer>
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn color="danger" size="sm" onClick={() => setIsOpen(false)}>
            Fechar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
