import React, { useEffect, useState } from 'react';
import PrivateRoute from '../../components/PrivateRoutes';

import Frota from '../../pages/Frota/ListaFrota';
import AddFrota from '../../pages/Frota/AddFrota';
import VisualizarFrota from '../../pages/Frota/VisualizarFrota';
import BDT from '../../pages/Frota/Bdt';
import Listar from '../../pages/Frota/Bdt/listar';
import ShowVtr from '../../pages/Frota/ShowVtr';
import Auditoria from '../../pages/Frota/Auditoria';

export default function Add(props) {
  return (
    <>
      <PrivateRoute path="/add-frota" component={AddFrota} />
      <PrivateRoute path="/frota" component={Frota} />
      <PrivateRoute path="/visualizar-frota" component={VisualizarFrota} />
      <PrivateRoute path="/bdt" component={BDT} />
      <PrivateRoute path="/bdt-listar" component={Listar} />
      <PrivateRoute path="/visualiza-vtr/:vtrid" component={ShowVtr} />
      <PrivateRoute path="/auditor-frota" component={Auditoria} />
    </>
  );
}
