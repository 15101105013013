import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBRow,
  MDBIcon,
  MDBSelect,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBDatePickerV5,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
// nome dos meses e etc
// import pt from 'date-fns/locale/pt';

import { zonedTimeToUtc } from 'date-fns-tz';

import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';

import { useState, useEffect, useRef } from 'react';

// import { compareAsc } from 'date-fns';

import api from '../../services/api';
import { toast } from 'react-toastify';
// import { utcToZonedTime } from 'date-fns-tz/esm';

// import { Container } from './styles';

function FrequenciaAuditoria() {
  const [modalVisualizaServico, setModalVisualizarServico] = useState(false);
  const [modalEditFrequencia, setModalEditFrequencia] = useState(false);

  const [historico, setHistorico] = useState([]);
  const [dadosDeServico, setDadosDeServico] = useState({});
  const [listaGerencias, setListaGerencias] = useState([]);

  // pesquisa por gerencia/lotacao/datainicio/datafim
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [lotacaoSelecionada, setLotacaoSelecionada] = useState('');

  const [listaLotacoes, setListaLotacoes] = useState([]);
  const [frequenciaIdSelecionada, setFrequenciaIdSelecionada] = useState({});

  const [editaStatusFrequencia, setEditaStatusFrequencia] = useState();
  const [editaMotivoFrequencia, setEditaMotivoFrequencia] = useState();

  const [idServicoClicado, setIdServicoClicado] = useState();

  const [idDeleteFrequencia, setIdDeleteFrequencia] = useState(null);
  const [idReativarFrequencia, setIdReativarFrequencia] = useState(null);

  const [idAlteraServico, setIdAlteraServico] = useState(null);
  const [insereAgente, setInsereAgente] = useState(false);

  const [selectAgentes, setSelectAgentes] = useState([]);

  const [agenteSelecionado, setAgenteSelecionado] = useState(null);

  const [loadingInsereAgente, setLoadingInsereAgente] = useState(false);

  // funcoes de abrir e fechar
  const visualizaServico = () => {
    setModalVisualizarServico(!modalVisualizaServico);
    setInsereAgente(false);
  };

  // carrega as lotacoes do select
  const carregaLotacoes = gerenciaid => {
    if (gerenciaid === undefined || gerenciaid === '' || gerenciaid === null) {
      return;
    }
    api.get(`/lotacao-por-gerencia/${gerenciaid}`).then(linhas => {
      let selectLotacao = [];
      linhas.data.map(linha => {
        selectLotacao.push({
          text: `${linha.escala.nome}/ ${linha.ala.nome}`,
          value: String(linha.id),
        });
      });
      setListaLotacoes(selectLotacao);
    });
  };

  // carrega as gerencias do select
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          text: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
    // carregaLotacoes(gerenciaSelecionada);
  };

  // carrega lista de historicos apresentados abaixo dos selects
  const listaHistorico = async () => {
    if (!lotacaoSelecionada) {
      toast.error('Selecione a Gerência e Lotação');
      return;
    }
    if (!dataInicio) {
      toast.error('Selecione a Data de Início');
      return;
    }
    if (!dataFim) {
      toast.error('Selecione a Data Final');
      return;
    }
    if (dataInicio > dataFim) {
      toast.error('Data Início maior que a Final');
      return;
    }
    // if (dataFim > formatISO(new Date())) {
    //   toast.error('O Futuro a DEUS pertence');
    //   return;
    // }
    const response = await api.get('/servico-auditoria', {
      params: {
        lotacao_id: lotacaoSelecionada,
        data_inicio: dataInicio,
        data_fim: dataFim,
      },
    });
    // console.log(response);
    // response.data.map(servico => {
    //   servico.frequencia.map(freq => {
    //     const formatada = freq.dt_h_frequencia;
    //     const parsedDate = parseISO(formatada);
    //     // const znDate = zonedTimeToUtc(parsedDate, 'America/Sao_Paulo');

    //     const dataFormatada = format(parsedDate, 'dd/MM/yyyy HH:mm');

    //     freq.dt_h_frequencia = dataFormatada;
    //   });
    // });

    setHistorico(response.data);
  };

  // altera a frequencia
  const updateFrequencia = async (id, dados) => {
    if (dados.presente === true) {
      dados.observacao = 'AUD';
    }

    const response = await api.put(`/frequencia-auditoria/${id}`, dados);
    // setMotivoFalta('');
    toast.success('Alterção feita com sucesso!');
    listaHistorico();
    setModalVisualizarServico(false);
    setModalEditFrequencia(false);
    setEditaMotivoFrequencia();
    // verificaServico();
    // listar();
  };

  // carrega modal do servico clicado
  const carregaDadosDeServico = async idHistorico => {
    // const response = await api.get(`/servico/${idHistorico}`);
    const teste = historico.filter(serv => serv.id === idHistorico);

    setIdServicoClicado(idHistorico);
    setDadosDeServico(...teste);
  };

  const funcModalEditFrequencia = frequenciaId => {
    buscaFrequenciaId(frequenciaId);
    setModalEditFrequencia(!modalEditFrequencia);
  };

  const alterarAgenteFrequencia = async (frequenciaId, status) => {
    const response = await api.put(`/frequencia/delete/${frequenciaId}`, {
      status: status,
    });
    if (response.data.ok) {
      toast.success(response.data.ok);
      setModalVisualizarServico(false);
      setModalEditFrequencia(false);
      setIdDeleteFrequencia(null);
      setIdReativarFrequencia(null);
      listaHistorico();
    } else {
      toast.error(response.data.error);
    }
  };

  const agenteInsert = async (servicoId, IdUsuario) => {
    setLoadingInsereAgente(true);
    const response = await api.post(
      `/frequencia-insere-agente/${servicoId}/${IdUsuario}`
    );

    // if (response.data.ok) {
    //   toast.success(response.data.ok);
    //   setModalVisualizarServico(false);
    //   setModalEditFrequencia(false);
    //   setIdDeleteFrequencia(null);
    //   setIdReativarFrequencia(null);
    //   listaHistorico();
    // } else {
    //   toast.danger(response.data.error);
    // }
    if (response.data.ok) {
      toast.success(response.data.ok);
      setModalVisualizarServico(false);
      listaHistorico();
      setInsereAgente(false);
      setLoadingInsereAgente(false);
      setAgenteSelecionado(null);
    } else {
      toast.error(response.data.erro);
      setLoadingInsereAgente(false);
    }
  };
  const selectInsereAgentes = async () => {
    const result = await api.get('/frequencia-busca-agentes', {
      params: { gerencia_id: dadosDeServico.lotacao.gerencia_id },
    });

    const subtracaoAgentes = result.data.filter(
      a1 =>
        !dadosDeServico.frequencia.filter(a2 => a1.id == a2.usuario_id).length
    );

    const agentesSelect = [];
    subtracaoAgentes.forEach(element => {
      agentesSelect.push({
        label: `${element.nome_social} / ${element.lotacao.ala.nome}`,
        value: element.id,
      });
    });
    setSelectAgentes(agentesSelect);
  };

  // fazer uma requisicao e descobrir se esse servico ja existe

  useEffect(() => {
    // listar();

    carregaGerencias();
  }, []);

  // modal lateral direito que faz a edicao da frequencia selecionada
  const buscaFrequenciaId = id => {
    const frequenciaSelecionada = dadosDeServico.frequencia.filter(
      frequencia => frequencia.id === id
    );
    const [objetoFrequenciaSelecionada] = frequenciaSelecionada;

    setEditaStatusFrequencia(objetoFrequenciaSelecionada.presente);
    setFrequenciaIdSelecionada(objetoFrequenciaSelecionada);
  };

  const alteraServico = async (id, tipo) => {
    // alterar o ativo do servico id e do servico_id em frequencia
    const alterar = await api.put(`/altera-servico/${id}`, { status: tipo });
    setIdAlteraServico(null);
    listaHistorico();
    // setEditaStatusFrequencia(objetoFrequenciaSelecionada.presente);
    // setFrequenciaIdSelecionada(objetoFrequenciaSelecionada);
  };

  const frequencias = array => {
    let presente = 0;
    let ausente = 0;
    let semVerificacao = 0;
    array.map(frequencia => {
      if (frequencia.presente === true && frequencia.ativo === true) {
        presente++;
      }
      if (frequencia.presente === false && frequencia.ativo === true) {
        ausente++;
      }
      if (frequencia.presente === null && frequencia.ativo === true) {
        semVerificacao++;
      }
    });

    return { presente, ausente, semVerificacao };
  };

  useEffect(() => {
    if (insereAgente === true) {
      selectInsereAgentes();
    }
  }, [insereAgente]);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-between mx-3">
            <MDBCol className="h3-responsive text-center">
              Auditoria de Frequência
            </MDBCol>
          </MDBRow>

          <MDBRow
            style={{
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: '5px',
            }}
            className="mb-3"
          >
            <MDBCol className="font-weight-bold" col="12" md="4">
              <MDBSelect
                className="font-weight-bold"
                options={listaGerencias}
                label="Gerência"
                getValue={t => {
                  // setGerenciaSelecionada(t[0]);
                  // console.log(...t);
                  carregaLotacoes(t[0]);
                }}
              />
            </MDBCol>
            <MDBCol className="font-weight-bold" col="12" md="4">
              <MDBSelect
                options={listaLotacoes}
                selected="Selecione a Lotação"
                label="Lotação"
                getValue={t => {
                  // setGerenciaSelecionada(t[0]);
                  // console.log(...t);
                  setLotacaoSelecionada(t[0]);
                }}
              />
            </MDBCol>
            <MDBCol className="mt-0 mt-sm-2" col="12" md="2">
              <div>Data Início</div>
              <input
                className="w-100"
                type="date"
                onChange={t => {
                  setDataInicio(t.target.value);
                }}
              />
            </MDBCol>
            <MDBCol className="mt-0 mt-sm-2" col="12" md="2">
              <div>Data Fim</div>
              <input
                className="w-100"
                type="date"
                onChange={t => {
                  setDataFim(t.target.value);
                }}
              />
            </MDBCol>
            <MDBCol col="12" className="text-center">
              <button
                type="button"
                className="btn btn-outline-primary btn-rounded waves-effect"
                onClick={listaHistorico}
              >
                Procurar
              </button>
            </MDBCol>
          </MDBRow>

          <MDBRow
            between
            className="p-2 d-none d-md-flex mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1 rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Escala/Ala
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Dia Início
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Data Término
            </div>
            <div className="col-12 col-md-1 d-flex flex-column font-weight-bold text-white">
              <span>Frequência</span>
              {/*
                    <span className="text-danger">Ausentes</span> */}
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-between font-weight-bold text-white"></div>
          </MDBRow>

          {historico.map(linha => (
            <MDBRow
              key={linha.id}
              between
              className={`p-2 mr-1 ml-1 mr-md-3 ml-md-3 align-items-center bg-white border-top  ${
                linha.ativo ? 'border-light' : 'border border-danger'
              }`}
            >
              <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Escala/Ala</span>
                {linha.lotacao.escala.nome} / {linha.lotacao.ala.nome}
              </div>
              <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Dia Início</span>
                {format(new Date(linha.dt_h_inicio), 'dd/MM/yyyy - HH:mm')}
              </div>
              <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Dia Término</span>
                {linha.dt_h_fim === null ? (
                  <>Serviço Aberto</>
                ) : (
                  format(new Date(linha.dt_h_fim), 'dd/MM/yyyy - HH:mm')
                )}
              </div>
              <div className="col-12 col-md-1 mb-md-0 d-flex justify-content-between justify-content-md-start font-weight-bolder">
                <div className="d-sm-inline d-md-none mr-3">Frequencia</div>
                <div className="d-flex">
                  <div className="text-success">
                    {frequencias(linha.frequencia).presente}
                  </div>
                  <div className="mx-1 grey-text">/</div>
                  <div className="text-danger">
                    {frequencias(linha.frequencia).ausente}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end">
                {/* <span className="d-sm-inline d-md-none mr-3">Hora Término</span> */}

                <div
                  className="d-flex align-items-center font-weight-bold"
                  style={{ cursor: 'pointer', color: '#59698d' }}
                  onClick={() => {
                    carregaDadosDeServico(linha.id);

                    visualizaServico();
                  }}
                >
                  <MDBIcon far icon="share-square" />
                  {/* <MDBIcon fas icon="expand-arrows-alt" /> */}
                  <div className="ml-2">Visualizar</div>
                </div>

                {linha.dt_h_fim !== null ? (
                  linha.ativo ? (
                    <div
                      className="d-flex align-items-center font-weight-bold ml-4"
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => {
                        // carregaDadosDeServico(linha.id);

                        setIdAlteraServico(linha);
                        // visualizaServico();
                      }}
                    >
                      <MDBIcon far icon="trash-alt" />
                      {/* <MDBIcon fas icon="expand-arrows-alt" /> */}
                      <div className="ml-2">Excluir</div>
                    </div>
                  ) : (
                    <div
                      className="d-flex align-items-center font-weight-bold ml-4"
                      style={{ cursor: 'pointer', color: 'green' }}
                      onClick={() => {
                        // carregaDadosDeServico(linha.id);

                        setIdAlteraServico(linha);
                        // visualizaServico();
                      }}
                    >
                      <div className="ml-2">Reativar</div>
                    </div>
                  )
                ) : (
                  ''
                )}
              </div>
            </MDBRow>
          ))}
        </MDBContainer>

        <MDBModal
          isOpen={modalVisualizaServico}
          toggle={() => {
            setModalVisualizarServico({
              modalVisualizaServico: !modalVisualizaServico,
            });
          }}
          // locale={moment.locale('pt-BR')}
          language="pt-BR"
          size="lg"
        >
          <MDBModalHeader toggle={visualizaServico}>
            {dadosDeServico.lotacao
              ? `${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`
              : ''}
          </MDBModalHeader>
          <MDBModalBody style={{ backgroundColor: 'rgba(82,101,140,0.2)' }}>
            {Object.entries(dadosDeServico) == 0 ? (
              ''
            ) : (
              <>
                <MDBRow>
                  <MDBCol className="d-flex" col="12" md="6">
                    <div className="mr-2">Data Início:</div>
                    <div className="font-weight-bolder">
                      {format(
                        new Date(dadosDeServico.dt_h_inicio),
                        ' dd/MM/yyyy - HH:mm'
                      )}
                    </div>
                  </MDBCol>
                  <MDBCol className="d-flex" col="12" md="6">
                    <div className="mr-2">Data Fim:</div>
                    <div className="font-weight-bolder">
                      {dadosDeServico.dt_h_fim
                        ? format(
                            new Date(dadosDeServico.dt_h_fim),
                            ' dd/MM/yyyy - HH:mm'
                          )
                        : 'Serviço Aberto'}
                    </div>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow className="d-flex justify-content-center font-weight-bold grey-text">
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Presentes</div>
                    <div className="text-success">
                      {frequencias(dadosDeServico.frequencia).presente}
                    </div>
                  </MDBCol>
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Ausentes</div>
                    <div className="text-danger">
                      {frequencias(dadosDeServico.frequencia).ausente}
                    </div>
                  </MDBCol>
                  <MDBCol className="text-center" col="12" md="3">
                    <div>Total</div>
                    <div>
                      {frequencias(dadosDeServico.frequencia).presente +
                        frequencias(dadosDeServico.frequencia).ausente +
                        frequencias(dadosDeServico.frequencia).semVerificacao}
                    </div>
                  </MDBCol>
                </MDBRow>
                <hr />
                <br />

                <MDBRow
                  between
                  className="p-2 mr-1 ml-1 d-none font-weight-bold d-md-flex rounded-top grey darken-1 rounded-top"
                  // className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
                >
                  <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
                    Nome Social
                  </div>

                  <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
                    Frequência
                  </div>

                  <div className="col-12 col-md-4 d-flex justify-content-between font-weight-bold text-white">
                    Motivo
                  </div>
                  <div className="col-12 col-md-1 d-flex p-0 font-weight-bold text-white">
                    Alterar
                  </div>
                </MDBRow>

                {dadosDeServico.frequencia.map(frequencia => (
                  <MDBRow
                    key={frequencia.id}
                    between
                    className={`p-2 mr-1 ml-1 align-items-center bg-white ${
                      frequencia.ativo === true
                        ? 'border-bottom border-silver '
                        : 'border border-danger'
                    }`}
                  >
                    <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Nome Social
                      </span>
                      <span
                        style={{ color: 'rgba(0,0,0,0.7)' }}
                        className="font-weight-bold"
                      >
                        {frequencia.usuario.nome_social}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Frequência
                      </span>
                      <span className="font-weight-bold">
                        {frequencia.presente === true ? (
                          <span className="text-success">Presente</span>
                        ) : (
                          ''
                        )}
                        {frequencia.presente === false ? (
                          <span className="text-danger">Ausente</span>
                        ) : (
                          ''
                        )}
                        {frequencia.presente === null ? (
                          <span className="text-warning">Apurar</span>
                        ) : (
                          ''
                        )}
                      </span>
                    </div>
                    <div className="col-12 col-md-4 mb-md-0 d-flex justify-content-between justify-content-md-start">
                      {/* {setor.gerencia.nome} */}
                      <span className="d-sm-inline d-md-none mr-3">
                        Observação
                      </span>
                      <span
                        style={{ color: 'rgba(0,0,0,0.7)' }}
                        className="font-weight-bolder"
                      >
                        {frequencia.auditor_id && frequencia.auditor_id !== null
                          ? 'AUD | '
                          : ''}
                        {frequencia.ativo === true ? (
                          frequencia.observacao
                        ) : (
                          <span className="danger-text font-italic">
                            Deletado
                          </span>
                        )}
                      </span>
                    </div>
                    {/* <div
                      className="col-12 col-md-1 mb-md-0 d-flex justify-content-center align-items-center justify-content-md-start"
                      onClick={() => {
                        // carregaDadosDeServico(linha.id);
                        funcModalEditFrequencia(frequencia.id);
                      }}
                    >
                      <MDBIcon icon="trash-alt" style={{ cursor: 'Pointer' }} />
                    </div> */}
                    <div
                      className="col-12 col-md-1 mb-md-0 d-flex justify-content-center align-items-center justify-content-md-start"
                      onClick={() => {
                        // carregaDadosDeServico(linha.id);
                        funcModalEditFrequencia(frequencia.id);
                      }}
                    >
                      <div className="mr-2 d-md-none font-weight-bold d-flex">
                        Editar
                      </div>

                      <MDBIcon icon="edit" style={{ cursor: 'Pointer' }} />
                    </div>
                  </MDBRow>
                ))}
                <MDBRow center>
                  <MDBCol className="text-center" size="12" md="3">
                    {insereAgente ? (
                      <MDBBtn
                        size="sm"
                        color="danger"
                        onClick={() => setInsereAgente(false)}
                      >
                        Cancelar
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        size="sm"
                        color="dark-green"
                        onClick={() => setInsereAgente(true)}
                      >
                        Inserir Agente
                      </MDBBtn>
                    )}
                  </MDBCol>
                  {/* <MDBCol size="12" md="8"> */}
                  <MDBCollapse
                    className="col-12 col-md-6 animated"
                    isOpen={insereAgente === true}
                  >
                    <Select
                      options={selectAgentes}
                      onChange={e => {
                        // if (e === null) {
                        //   // setAgenteSelecionado('');
                        //   return;
                        // }
                        setAgenteSelecionado(e);
                      }}
                      value={agenteSelecionado}
                      placeholder="Agente"
                    />
                  </MDBCollapse>

                  {insereAgente === true && (
                    <MDBCol size="3" className="text-center">
                      <MDBBtn
                        onClick={() =>
                          agenteInsert(
                            dadosDeServico.id,
                            agenteSelecionado.value
                          )
                        }
                        size="sm"
                        color="success"
                        disabled={loadingInsereAgente}
                      >
                        {loadingInsereAgente === true
                          ? 'Carregando'
                          : 'Inserir'}
                      </MDBBtn>
                    </MDBCol>
                  )}

                  {/* </MDBCol> */}
                </MDBRow>

                <MDBModal
                  isOpen={modalEditFrequencia}
                  toggle={() => setModalEditFrequencia(!modalEditFrequencia)}
                  fullHeight
                  backdrop={true}
                  position="right"
                  // showModal={carregaModalEditFrequencia}
                >
                  <MDBModalHeader
                    toggle={() => setModalEditFrequencia(!modalEditFrequencia)}
                  >
                    Auditoria
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBRow
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      className="mx-2 p-2"
                    >
                      Nome Social:
                    </MDBRow>
                    <MDBRow
                      style={{ border: '2px solid rgba(0,0,0,0.1)' }}
                      className="font-weight-bold mx-2 p-2"
                    >
                      {frequenciaIdSelecionada &&
                        frequenciaIdSelecionada?.usuario?.nome_social}
                    </MDBRow>
                    <br />

                    <MDBRow
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      className="mx-2 p-2"
                    >
                      Nome Completo:
                    </MDBRow>
                    <MDBRow
                      style={{ border: '2px solid rgba(0,0,0,0.1)' }}
                      className="font-weight-bold mx-2 p-2"
                    >
                      {`${frequenciaIdSelecionada?.usuario?.nome} ${frequenciaIdSelecionada?.usuario?.sobrenome}`}
                    </MDBRow>

                    <br />
                    <MDBRow
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      className="mx-2 p-2"
                    >
                      Data:
                    </MDBRow>
                    <MDBRow
                      style={{ border: '2px solid rgba(0,0,0,0.1)' }}
                      className="font-weight-bold mx-2 p-2"
                    >
                      {frequenciaIdSelecionada.dt_h_frequencia
                        ? format(
                            zonedTimeToUtc(
                              new Date(frequenciaIdSelecionada.dt_h_frequencia),
                              'America/Sao_Paulo'
                            ),
                            'dd/MM/yyyy - HH:mm'
                          )
                        : ''}
                    </MDBRow>
                    <br />

                    <MDBRow
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                      className="mx-2 p-2"
                    >
                      Alterar Auditoria:
                    </MDBRow>
                    <MDBRow
                      style={{ border: '2px solid rgba(0,0,0,0.1)' }}
                      className="font-weight-bold mx-2 p-2"
                    >
                      <MDBCol className="d-sm-none d-md-block" col="12" md="4">
                        Status
                      </MDBCol>
                      <MDBCol col="12" md="8">
                        <select
                          // value={frequenciaIdSelecionada.presente}
                          defaultValue={frequenciaIdSelecionada.presente}
                          className="browser-default custom-select"
                          onChange={v => {
                            setEditaStatusFrequencia(v.target.value);
                          }}
                        >
                          <option value="true">Presente</option>
                          <option value="false">Ausente</option>
                        </select>
                      </MDBCol>
                    </MDBRow>
                    {/* {editaStatusFrequencia.presente === 'false'
                      ? 'ausente'
                      : 'presente'} */}
                    {/* {console.log(editaStatusFrequencia)} */}
                    <MDBRow
                      style={{ border: '2px solid rgba(0,0,0,0.1)' }}
                      className="font-weight-bold mx-2 p-2"
                    >
                      <MDBCol col="12" md="4">
                        Motivo
                      </MDBCol>
                      <MDBCol col="12" md="8">
                        <select
                          className="browser-default custom-select"
                          onChange={v => {
                            setEditaMotivoFrequencia(v.target.value);
                          }}
                        >
                          <option value="" selected disabled>
                            Selecione
                          </option>
                          <option>Não comparecimento</option>
                          <option>Férias</option>
                          <option>Licença Saúde</option>
                          <option>Licença Maternidade/Paternidade</option>
                          <option>Licença Matrimonial</option>
                          <option>Licença Falecimento</option>
                          <option>Dispensa Coordenação</option>
                          <option>Em Treinamento</option>
                          <option>Aguardando Perícia</option>
                          <option>Afastado das funções</option>
                          <option>Exonerado</option>
                        </select>
                      </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow center>
                      {/* <MDBCol size="12" md="12" className="center-text"> */}

                      {frequenciaIdSelecionada.ativo === true ? (
                        <MDBBtn
                          onClick={() => {
                            setIdDeleteFrequencia(frequenciaIdSelecionada.id);
                          }}
                          size="sm"
                          color="danger"
                        >
                          Deletar agente
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          onClick={() => {
                            setIdReativarFrequencia(frequenciaIdSelecionada.id);
                          }}
                          size="sm"
                          color="danger"
                        >
                          Reativar Agente
                        </MDBBtn>
                      )}
                      {/* </MDBCol> */}
                    </MDBRow>
                  </MDBModalBody>

                  <MDBModalFooter>
                    <MDBBtn
                      color="dark-green"
                      onClick={() => {
                        if (
                          editaMotivoFrequencia === undefined &&
                          editaStatusFrequencia === false
                        ) {
                          toast.error('Selecione o Motivo da Ausência');
                          return;
                        }

                        updateFrequencia(frequenciaIdSelecionada.id, {
                          presente: editaStatusFrequencia,
                          observacao: editaMotivoFrequencia,
                        });
                      }}
                    >
                      Salvar
                    </MDBBtn>
                    <MDBBtn
                      color="danger"
                      onClick={() =>
                        setModalEditFrequencia(!modalEditFrequencia)
                      }
                    >
                      Fechar
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModal>
              </>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={visualizaServico}>
              Fechar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={idDeleteFrequencia !== null}
          toggle={() => {
            setIdDeleteFrequencia(null);
          }}
          // locale={moment.locale('pt-BR')}
          language="pt-BR"
          size="lg"
        >
          <MDBModalHeader toggle={() => setIdDeleteFrequencia(null)}>
            {/* {dadosDeServico.lotacao
              ? `${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`
              : ''} */}
            Atenção
          </MDBModalHeader>
          <MDBModalBody style={{ backgroundColor: 'rgba(82,101,140,0.2)' }}>
            <MDBRow className="h3-responsive font-weight-bolder">
              <MDBCol>Realmente deseja deletar o agente do serviço ?</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Nome Social:{' '}
                {frequenciaIdSelecionada &&
                  frequenciaIdSelecionada?.usuario?.nome_social}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Nome:
                {` ${frequenciaIdSelecionada?.usuario?.nome} ${frequenciaIdSelecionada?.usuario?.sobrenome}`}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Data:{' '}
                {frequenciaIdSelecionada.dt_h_frequencia
                  ? format(
                      zonedTimeToUtc(
                        new Date(frequenciaIdSelecionada.dt_h_frequencia),
                        'America/Sao_Paulo'
                      ),
                      'dd/MM/yyyy - HH:mm'
                    )
                  : ''}
              </MDBCol>
            </MDBRow>
            <br />
            <br />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="warning" onClick={() => setIdDeleteFrequencia(null)}>
              Fechar
            </MDBBtn>

            <MDBBtn
              onClick={() => {
                alterarAgenteFrequencia(idDeleteFrequencia, false);
              }}
              color="danger"
            >
              Deletar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={idReativarFrequencia !== null}
          toggle={() => {
            setIdReativarFrequencia(null);
          }}
          // locale={moment.locale('pt-BR')}
          language="pt-BR"
          size="lg"
        >
          <MDBModalHeader toggle={() => setIdReativarFrequencia(null)}>
            {/* {dadosDeServico.lotacao
              ? `${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`
              : ''} */}
            Reativação
          </MDBModalHeader>
          <MDBModalBody style={{ backgroundColor: 'rgba(82,101,140,0.2)' }}>
            <MDBRow className="h3-responsive font-weight-bolder">
              <MDBCol>Realmente deseja reativar o agente do serviço ?</MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Nome Social:{' '}
                {frequenciaIdSelecionada &&
                  frequenciaIdSelecionada?.usuario?.nome_social}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Nome:
                {` ${frequenciaIdSelecionada?.usuario?.nome} ${frequenciaIdSelecionada?.usuario?.sobrenome}`}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Data:{' '}
                {frequenciaIdSelecionada.dt_h_frequencia
                  ? format(
                      zonedTimeToUtc(
                        new Date(frequenciaIdSelecionada.dt_h_frequencia),
                        'America/Sao_Paulo'
                      ),
                      'dd/MM/yyyy - HH:mm'
                    )
                  : ''}
              </MDBCol>
            </MDBRow>
            <br />
            <br />
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn
              color="warning"
              onClick={() => setIdReativarFrequencia(null)}
            >
              Fechar
            </MDBBtn>

            <MDBBtn
              onClick={() => {
                alterarAgenteFrequencia(idReativarFrequencia, true);
              }}
              color="danger"
            >
              Reativar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          isOpen={idAlteraServico !== null}
          toggle={() => {
            setIdAlteraServico(null);
          }}
          // locale={moment.locale('pt-BR')}
          language="pt-BR"
          size="lg"
        >
          <MDBModalHeader toggle={() => setIdAlteraServico(null)}>
            {/* {dadosDeServico.lotacao
              ? `${dadosDeServico.lotacao.escala.nome} / ${dadosDeServico.lotacao.ala.nome}`
              : ''} */}
            Atenção
          </MDBModalHeader>
          <MDBModalBody style={{ backgroundColor: 'rgba(82,101,140,0.2)' }}>
            <MDBRow className="h3-responsive font-weight-bolder">
              <MDBCol>
                {`
               Realmente deseja ${
                 idAlteraServico && idAlteraServico.ativo
                   ? 'deletar'
                   : 'reativar'
               } o serviço ?
              `}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Escala / Ala:{' '}
                {idAlteraServico &&
                  `${idAlteraServico.lotacao.escala.nome} / ${idAlteraServico.lotacao.ala.nome}`}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Data Início:
                {idAlteraServico &&
                  format(
                    new Date(idAlteraServico.dt_h_inicio),
                    ' dd/MM/yyyy - HH:mm'
                  )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="h5-responsive font-weight-bolder">
                Data Término:{' '}
                {idAlteraServico &&
                  format(
                    new Date(idAlteraServico.dt_h_fim),
                    ' dd/MM/yyyy - HH:mm'
                  )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>

          <MDBModalFooter>
            <MDBBtn color="warning" onClick={() => setIdAlteraServico(null)}>
              Fechar
            </MDBBtn>
            {idAlteraServico && idAlteraServico.ativo ? (
              <MDBBtn
                onClick={() => {
                  alteraServico(idAlteraServico.id, false);
                }}
                color="danger"
              >
                Deletar
              </MDBBtn>
            ) : (
              <MDBBtn
                onClick={() => {
                  alteraServico(idAlteraServico.id, true);
                }}
                color="success"
              >
                Reativar
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>

        <br />
        <br />
      </NavBar>
    </>
  );
}

export default FrequenciaAuditoria;
