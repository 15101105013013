import React, { Component } from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBIcon,
  MDBBtn,
} from 'mdbreact';
import ImgModal from '../../../components/ImgModal';

import NavBar from '../../../components/NavBar';
import crlv from '../../../assets/crlv.jpeg';
import VeiculoFrente from '../../../assets/golFrente.jpeg';
import veiculoTrazeira from '../../../assets/golTrazeira.jpeg';
// import UserJpg from '../../../assets/user.jpg';
// import { Container } from './styles';
// import api from '../../../services/api';

export default function VisualizaVtr(props) {
  // let foto;
  // if (!this.state.foto) {
  //   foto = UserJpg;
  // } else {
  //   foto = this.state.foto.url;
  // }

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex flex-column flex-md-row  align-items-stretch">
            <MDBCol md="5" className="d-flex flex-column  mt-2 mt-md-0 h-100">
              <MDBCard className="p-3">
                <h4 className=" ml-2 grey-text">Informações:</h4>
              </MDBCard>
              <MDBCard className="mt-2">
                <MDBCardBody className="d-flex flex-column">
                  <form>
                    <div className="grey-text ">
                      <MDBInput
                        label="Placa"
                        icon="car"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value="LKP 2232"
                        disabled
                      />

                      <MDBInput
                        label="Lotação"
                        icon="fas fa-map-marker-alt"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value="BARREIRA FISCAL"
                        disabled
                      />

                      <MDBInput
                        label="Sublotação"
                        icon="fas fa-map-marker-alt"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value="COORDENAÇAO"
                        disabled
                      />
                    </div>
                    <div className=" d-flex justify-content-between">
                      <div className=" ml-2">STATUS DA VTR:</div>
                      <div
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          borderRadius: '5px',
                        }}
                        className="pt-1 pb-1 pl-4 pr-4 font-weight-bold"
                      >
                        ATIVO
                      </div>
                    </div>
                    <br />
                    <div className=" d-flex justify-content-between">
                      <div className=" ml-2">CONDIÇÃO:</div>
                      <div
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: '5px',
                        }}
                        className="pt-1 pb-1 pl-4 pr-4 font-weight-bold"
                      >
                        EM USO
                      </div>
                    </div>
                  </form>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="mt-2 mt-md-2">
                <h5 className="mt-2 ml-2">fotos:</h5>

                <MDBCardBody className="d-flex flex-row">
                  <ImgModal title="Documento" teste={crlv} />
                  <ImgModal title="Frente" teste={VeiculoFrente} />
                  <ImgModal title="Traseira" teste={veiculoTrazeira} />
                  <br />
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol
              md="7"
              className="d-flex flex-column justify-content-between mt-2 mt-md-0"
            >
              <MDBCard>
                <MDBCardBody>
                  <MDBTable striped>
                    <MDBTableBody>
                      <tr>
                        <td style={{ fontSize: '13px' }}>RENAVAM</td>
                        <td style={{ fontSize: '13px' }}>9BMRN4BWXAE074420</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>MODELO</td>
                        <td style={{ fontSize: '13px' }}>GOL G4</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>COR</td>
                        <td style={{ fontSize: '13px' }}>Prata</td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>CARACTERIZADA</td>
                        <td
                          style={{ fontSize: '13px' }}
                          className="red-text font-weight-bold"
                        >
                          NÃO
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>GIROFLEX</td>
                        <td
                          style={{ fontSize: '13px' }}
                          className="green-text font-weight-bold"
                        >
                          SIM
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>SIRENE</td>
                        <td
                          style={{ fontSize: '13px' }}
                          className="green-text font-weight-bold"
                        >
                          SIM
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
              <MDBCard className="mt-2">
                <MDBCardBody>
                  Último Registro
                  <MDBTable striped>
                    <MDBTableBody>
                      <tr>
                        <td className="p-0" style={{ fontSize: '13px' }}>
                          Condutor
                        </td>
                        <td className="p-0" style={{ fontSize: '13px' }}>
                          KM Final
                        </td>
                        <td className="p-0" style={{ fontSize: '13px' }}>
                          Data
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '13px' }}>Carlos</td>
                        <td style={{ fontSize: '13px' }}>2123444</td>
                        <td style={{ fontSize: '13px' }}>21/11/2019</td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCardBody>
              </MDBCard>
              <MDBCard className="mt-2">
                <MDBCardBody className="text-center">
                  <MDBBtn outline color="grey">
                    <MDBIcon icon="edit" className="mr-1" /> Editar Dados
                  </MDBBtn>
                  <MDBBtn outline color="grey">
                    <MDBIcon icon="eye" className="mr-1" />
                    Histórico
                  </MDBBtn>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        {/* <div>foto: {this.state.foto.url?this.state.foto.url:null}</div> */}
        {/* <div>arquivo: {this.url}</div> */}
      </NavBar>
    </>
  );
}
