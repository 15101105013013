import React from 'react';
import { MDBNotification } from 'mdbreact';
// import { Container } from './styles';

export default function Notificacao(props) {
  
  return (
    <MDBNotification
      style={{ position: 'absolute', top: 100, right: 20 }}
      show
      fade
      autohide={6000}
      iconClassName={props.color}
      title={props.title}
      message={props.message}
    />
  );
}
