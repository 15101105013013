import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';

import { getData, getPerfil } from '../../../../services/auth';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBSwitch,
  MDBCardText,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCardHeader,
} from 'mdbreact';

import api from '../../../../services/api';
import { format, parseISO, setSeconds } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Loading from '../../../../components/Loading';
import { toast } from 'react-toastify';
import NavBar from '../../../../components/NavBar';

// import { Container } from './styles';

export default function ListagemBdts(props) {
  let history = useHistory();

  const [loadingSelect, setLoadingSelect] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const [listSelectVtr, setListSelectVtr] = useState([]);
  const [vtrSelected, setVtrSelected] = useState(null);

  const [data, setData] = useState([]);
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState(null);

  const [dataApresentacao, setDataApresentacao] = useState(null);
  const [dataDispensa, setDataDispensa] = useState(null);
  const listarVtrs = async () => {
    setLoadingSelect(true);
    const result = await api.get('/frota-veiculos', {
      params: {
        // excessaoVtrPosto: true,
        id: gerenciaSelecionada.value,
      },
    });
    const selectPlacas = [];
    result.data.forEach(vtr => {
      selectPlacas.push({ label: vtr.placa, value: vtr.id });
    });

    setListSelectVtr(selectPlacas);
    setLoadingSelect(false);
  };

  const buscaBdt = async () => {
    if (gerenciaSelecionada === null) {
      toast.error('Selecione Gerência');
      return;
    }
    if (vtrSelected === null) {
      toast.error('Selecione Viatura');
      return;
    }
    const params = { fk_veiculos_id: vtrSelected.value };
    if (dataApresentacao !== null) {
      params.data_apresentacao = parseISO(dataApresentacao);
    }
    if (dataDispensa !== null) {
      params.data_dispensa = dataDispensa;
    }
    setLoadingData(true);
    const result = await api.get('/bdts-vtr', {
      params,
    });

    setLoadingData(false);
    setData(result.data);
  };

  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });
      setListaGerencias(select);
    });
  };

  // ADD NOVO BDT
  useEffect(() => {
    carregaGerencias();
  }, []);

  useEffect(() => {
    if (gerenciaSelecionada !== null) {
      listarVtrs();
    }
    setVtrSelected(null);
  }, [gerenciaSelecionada]);

  return (
    <NavBar>
      <MDBContainer>
        <MDBRow className="align-items-end">
          <MDBCol size="12" md="3">
            <Select
              className="font-weight-bolder"
              options={listaGerencias}
              placeholder="Gerência"
              onChange={e => setGerenciaSelecionada(e)}
              // isLoading={loadingSelect}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <Select
              className="font-weight-bolder"
              options={listSelectVtr}
              placeholder="Selecione VTR"
              onChange={e => setVtrSelected(e)}
              value={vtrSelected}
              isLoading={loadingSelect}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Data Início</div>
            <input
              className="border border-light w-100 rounded grey-text"
              style={{ padding: '.375rem .75rem' }}
              type="date"
              outline
              // className="bg-white"
              label="Data Inicio"
              onChange={e => setDataApresentacao(`${e.target.value} 00:00`)}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Data Fim</div>
            <input
              className="border border-light w-100 rounded grey-text"
              style={{ padding: '.375rem .75rem' }}
              type="date"
              outline
              // className="bg-white"
              label="Data Fim"
              onChange={e => setDataDispensa(`${e.target.value} 23:59`)}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-2" center>
          <MDBBtn
            onClick={() => {
              buscaBdt();
            }}
            size="sm"
          >
            Buscar
          </MDBBtn>
        </MDBRow>

        {loadingData ? (
          <Loading />
        ) : (
          <>
            {/* <MDBRow className="m-5 h4-responsive">
              <MDBCol>Placa: {data?.placa}</MDBCol>
              <MDBCol>Modelo: {data?.modelo}</MDBCol>
            </MDBRow> */}
            {data.length === 0 ? (
              <MDBRow
                center
                className="mt-3 h5-responsive text-danger font-weight-bolder"
              >
                Vazio
              </MDBRow>
            ) : (
              <>
                {data?.map(bdt => (
                  <MDBCard className="my-4" key={`bdt${bdt.id}`}>
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol className="text-danger">
                          Usuario:
                          {bdt?.usuario?.nome_social}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-2">
                        <MDBCol>
                          Data Apresentacao:
                          <br />
                          {format(
                            new Date(bdt?.data_apresentacao),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </MDBCol>

                        <MDBCol>
                          Data Dispensa:
                          <br />
                          {bdt.data_dispensa === null ? (
                            <div className="text-danger font-weight-bolder">
                              BDT ABERTO
                            </div>
                          ) : (
                            format(
                              new Date(bdt?.data_dispensa),
                              'dd/MM/yyyy HH:mm'
                            )
                          )}
                        </MDBCol>
                        <MDBCol>
                          KM Inicial:
                          <br />
                          {bdt?.quilometragem_inicio}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="mt-2">
                        <MDBCol>
                          Foi Excessão:
                          {bdt?.excessao === true ? ' Sim' : ' Não'}
                        </MDBCol>
                      </MDBRow>
                      {bdt?.excessao === true ? (
                        <MDBRow>
                          <MDBCol>
                            Justificatica:
                            {bdt?.excessao_justificativa}
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ''
                      )}
                      <MDBRow className="h4-responsive mt-4">
                        <MDBCol>Ocorrências</MDBCol>
                      </MDBRow>
                      {bdt?.bdts_ocorrencias?.map(oco => (
                        <div
                          key={`oc${oco.id}`}
                          className="border-top border-light"
                        >
                          <MDBRow className="mt-3">
                            <MDBCol>
                              Criado em:{' '}
                              {format(
                                new Date(oco?.createdAt),
                                'dd/MM/yyyy HH:mm'
                              )}
                            </MDBCol>
                            <MDBCol>
                              Tipo: {oco?.tipo_bdts_ocorrencia?.tipo_ocorrencia}
                            </MDBCol>
                          </MDBRow>

                          <MDBRow>
                            <MDBCol>Descrição: {oco?.descricao}</MDBCol>
                          </MDBRow>
                        </div>
                      ))}
                      <MDBRow className="h4-responsive mt-4">
                        <MDBCol>Abastecimento</MDBCol>
                      </MDBRow>
                      {bdt?.abastecimentos?.map(abas => (
                        <div
                          key={`ab${abas.id}`}
                          className="border-top border-light"
                        >
                          <MDBRow className="mt-3">
                            <MDBCol>
                              Data:{' '}
                              {format(
                                new Date(abas?.data_abastecimento),
                                'dd/MM/yyyy HH:mm'
                              )}
                            </MDBCol>
                            <MDBCol>
                              Tipo: {abas?.tipos_combustivei?.tipo_combustivel}
                            </MDBCol>
                          </MDBRow>

                          <MDBRow>
                            <MDBCol>
                              Quantidade: <br />
                              {abas?.quantidade} Litros
                            </MDBCol>
                            <MDBCol>
                              KM de Abastecimento: <br /> {abas?.quilometragem}
                            </MDBCol>
                            <MDBCol>
                              Valor Total: <br />
                              R$: {abas?.valor_total}
                            </MDBCol>
                          </MDBRow>
                        </div>
                      ))}
                    </MDBCardBody>
                  </MDBCard>
                ))}
              </>
            )}
          </>
        )}
        <br />
      </MDBContainer>
    </NavBar>
  );
}
