import React, { useEffect, useState } from 'react';
import { parseISO, toDate } from 'date-fns';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBView,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBIcon,
  MDBMedia,
  MDBFileInput,
  MDBSpinner,
  toast,
} from 'mdbreact';

import Compressor from 'compressorjs';
import api from '../../services/api';

function ImageMeta(props) {
  const [stateExifImage, setExifImage] = useState(null);
  const [dados, setDad] = useState({});
  const [compressedFileView, setCompressedFileView] = useState(null);
  const [compressedFileBase64, setCompressedFileBase64] = useState(null);

  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingBusca, setLoadingBusca] = useState(false);
  const [loadingHandle, setLoadingHandle] = useState(false);

  const [imagens, setImagens] = useState([]);
  const [imageSelected, setImageSelected] = useState(null);
  // const [modalFoto, setModalFoto] = useState({ id: null, base: null });
  // { id: null, base: null }
  // componentizar
  const buscaFotos = async () => {
    setLoadingBusca(true);

    // const result = await api.get('/sgo-imagem', {
    const result = await api.get(props.getFotos.buscaFotosURL, {
      params: {
        //     // abordagem_id: props.abordagem_id,
        ...props.getFotos.buscaFotosWhere,
      },
    });

    setImagens(result.data);
    setLoadingBusca(false);
  };

  useEffect(() => {
    buscaFotos();
  }, []);

  useEffect(() => {
    handleChange(imageSelected);
  }, [imageSelected]);

  // componentizar
  const enviaFoto = async () => {
    setLoadingSend(true);

    // const result = await api.post('/sgo-insere-imagem', {

    const result = await api.post(props.postFotos.enviarFotoURL, {
      file: compressedFileBase64,
      data: props.postFotos.enviarRefId,
    });

    buscaFotos();
    setCompressedFileBase64(null);
    setCompressedFileView(null);
    setLoadingSend(false);
  };

  function handleChange(files_list) {
    if (files_list) {
      if (
        files_list[0].type !== 'image/jpeg' &&
        files_list[0].type !== 'image/png'
      ) {
        setCompressedFileView(null);
        setCompressedFileBase64(null);
        toast.error('Somente PNG, JPG e JPEG');

        return;
      }
    }

    setLoadingHandle(true);
    if (files_list && files_list.length > 0) {
      for (let file of files_list) {
        if (file) {
          let quality = 1;
          if (file.size > 200000) {
            quality = 0.6;
          }
          new Compressor(file, {
            // quality: Math.round((1000000 / file.size) * 100) / 100,
            quality: quality,
            maxHeight: 1024,
            maxWidth: 1024,
            success: compressedResult => {
              setCompressedFileView(compressedResult);

              const reader = new FileReader();

              reader.readAsDataURL(compressedResult);
              reader.onload = () => {
                setCompressedFileBase64(reader.result);
                setLoadingHandle(false);
              };
            },
          });
        }
      }
    }
  }

  return (
    <MDBContainer>
      {loadingBusca && (
        <MDBRow center className="align-items-center my-3">
          <MDBSpinner />
          <div className="ml-3 font-weight-bold">Carregando...</div>
        </MDBRow>
      )}
      <MDBRow>
        {imagens.ok ? (
          <MDBCol center className="py-3 font-weight-bold danger-text">
            {imagens.ok}
          </MDBCol>
        ) : (
          imagens.map(res => (
            <MDBCol key={res.id} size="12" className="my-2">
              <img
                // key={res.id}
                // id={res.id}
                // width="200"
                // height="200"
                style={{ objectFit: 'cover' }}
                className="img-fluid"
                src={`${Buffer.from(res.imagem, 'base64')}`}
                // onClick={e => {
                //   setModalFoto({ id: res.id, base: res.imagem });
                //   // src={imagens}
                // }}
              />
            </MDBCol>
          ))
        )}
      </MDBRow>

      {/* {imagens > 0 && console.log('console.log', URL.createObjectURL(imagens))} */}
      {/* {imagens > 0 && <img src={URL.createObjectURL(imagens)} />} */}

      {/* ))} */}
      {imagens.length < 2 || imagens.ok ? (
        <MDBFileInput
          type="file"
          id="file"
          btnTitle="Selecione"
          textFieldTitle="Buscar imagem"
          accept=".jpg, .png"
          value={imageSelected}
          getValue={e => {
            setImageSelected(e);
          }}
        />
      ) : (
        <MDBRow center className="font-weight-bold danger-text">
          Limite: {props.maxImages} fotos
        </MDBRow>
      )}

      <hr />

      {!!compressedFileView && (
        <MDBRow className="align-items-center justify-content-center">
          <MDBCol size="12">
            {loadingHandle ? (
              <div className="d-flex align-items-center justify-content-center">
                <MDBSpinner />
                <div className="ml-3 font-weight-bolder">Carregando...</div>
              </div>
            ) : (
              <img
                width="200"
                height="200"
                className="img-thumbnail cursor-pointer"
                src={URL.createObjectURL(compressedFileView)}
                // src={imagens}
              />
            )}
          </MDBCol>
          <MDBCol size="12" md="6" className="text-center">
            {loadingSend ? (
              <div className="d-flex align-items-center justify-content-center">
                <MDBSpinner />
                <div className="ml-3 font-weight-bolder">Carregando...</div>
              </div>
            ) : (
              <MDBBtn
                disabled={loadingSend}
                color="dark-green"
                size="sm"
                onClick={() => enviaFoto()}
              >
                Enviar
              </MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
      )}
    </MDBContainer>
  );
}

export default ImageMeta;
