import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { getData } from '../../services/auth';

import Select from 'react-select';
import {
  MDBContainer,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBCollapse,
} from 'mdbreact';
// import { getData } from '../../services/auth';

import api from '../../services/api';
import NavBar from '../../components/NavBar';

function Gerencia() {
  const [collapseFiltro, setCollapseFiltro] = useState(false);
  const [checkNovaArea, setCheckNovaArea] = useState('false');

  const [dadosGerencia, setDadosGerencia] = useState([]);

  const [novosDadosGerencia, setNovosDadosGerencia] = useState({});

  const [optionsEscala, setOptionsEscala] = useState([]);

  const setOptions = async () => {
    const lotacoes = await api.get('/lotacao');

    // setDadosEscalas(lotacoes.data)

    const listaEscala = [];
    lotacoes.data.map(lotacao => {
      listaEscala.push({
        value: lotacao.id,
        label: `${lotacao.escala.nome} / ${lotacao.ala.nome}`,
      });
    });
    // console.log(listaEscala)
    setOptionsEscala(listaEscala);
  };

  const Carregamentos = async () => {
    const gerencias = await api.get('/gerencia');
    setDadosGerencia(gerencias.data);

    setOptions();

    // const response = await api.get('/escala-calendario');
    // setDadosCalendario(response.data)
  };

  const enviaCalendario = async () => {
    if (!novosDadosGerencia.nome) {
      toast.error('Preencha o campo Nova Gerência!');
      return;
    }
    if (!novosDadosGerencia.sigla) {
      toast.error('Preencha o campo Sigla!');
      return;
    }
    if (!novosDadosGerencia.estrutura_formal) {
      toast.error('Preencha o campo Estrutura Formal!');
      return;
    }

    try {
      const calendario = await api.post('/gerencia', novosDadosGerencia);

      toast.success('Gerência Adicionada!');
      Carregamentos();
    } catch (error) {
      toast.success('Erro ao criar Nova Gerência');
    }
    // const calendario = await api.post('/gerencia', novosDadosGerencia);

    // Carregamentos();
  };

  // if(response.data.erro){
  //   toast.error(response.data.erro);
  //   return
  // }

  // toast.success('Escala de Serviço Adicionada!');
  // loadEscalaServico();

  // const selectArea = async () => {
  //   const response = await api.get('/area');
  //   setArea(response.data);

  //   let optionArea = []
  //   response.data.map(area => {
  //     optionArea.push({ value: area.id , label: area.nome })
  //   })

  //   setOptionArea(optionArea)

  // }

  useEffect(() => {
    Carregamentos();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-between mx-3 align-items-center">
            <MDBCol col="4" md="4" className="h4-responsive font-weight-bold">
              Gerências
            </MDBCol>
            <MDBCol col="8" md="4" className="d-flex justify-content-end">
              <MDBBtn
                color="primary"
                onClick={() => setCollapseFiltro(!collapseFiltro)}
                className="d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="plus" size="2x" />
                <h5 className="m-0">Nova Gerência</h5>
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBCollapse id="basicCollapse" isOpen={collapseFiltro}>
            <MDBRow
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
              style={{
                borderBottom: '5px solid rgba(0,0,0,0.3)',
                borderRadius: '5px',
              }}
            >
              <h4>Insira nova Gerência </h4>
            </MDBRow>
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
            >
              <MDBCol col="4">
                <MDBInput
                  onChange={e => {
                    setNovosDadosGerencia({
                      ...novosDadosGerencia,
                      nome: e.target.value,
                    });
                  }}
                  label="Nova Gerência"
                  type="text"
                />
              </MDBCol>

              <MDBCol col="4">
                <MDBInput
                  onChange={e => {
                    setNovosDadosGerencia({
                      ...novosDadosGerencia,
                      sigla: e.target.value,
                    });
                  }}
                  label="Sigla"
                  type="text"
                />
              </MDBCol>

              <MDBCol col="4" className="d-flex align-items-center w-100">
                <div
                  style={{ fontSize: '20px', marginBottom: '7px' }}
                  className="mr-1 text-danger"
                >
                  {getData().gerencia}.
                </div>
                <MDBInput
                  style={{ fontSize: '20px' }}
                  onChange={e => {
                    setNovosDadosGerencia({
                      ...novosDadosGerencia,

                      estrutura_formal:
                        getData().gerencia + '.' + e.target.value,

                      // estrutura_formal: `${getData().gerencia}.${e.target.value}`,
                    });
                  }}
                  label="Estrutura Formal"
                  type="text"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
            >
              {/* <MDBCol col="5" className="d-flex align-items-end w-100">
            <Select
              className="text-left w-100 mb-4 mt-4"
              // onChange={e => {
              //   setNovoSetor({ ...novoSetor, guarnicao: e.value });
              // }}
              placeholder="Ala"
              // options={optionGuarnicao}
            />
          </MDBCol> */}
              <MDBBtn
                color="success"
                onClick={() => enviaCalendario()}
                className="h-50 col-8 col-md-2 d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="plus" size="2x" />
                <h5 className="m-0">Cadastrar</h5>
              </MDBBtn>
            </MDBRow>
          </MDBCollapse>

          <MDBRow
            between
            style={{ borderRadius: '5px' }}
            className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1"
          >
            <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
              Nome
            </div>

            <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
              Sigla
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white">
              Estrutura Formal
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-end font-weight-bold text-white">
              Status
            </div>
          </MDBRow>

          {dadosGerencia.map(gerencia => (
            <MDBRow
              key={gerencia.id}
              between
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
            >
              <div className="col-12 col-md-3 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Nome:</span>
                {/* {format(new Date(calendario.dt_servico), 'dd/MM/yyyy')} */}
                {gerencia.nome}
              </div>

              <div className="col-12 col-md-3 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Sigla:</span>
                {gerencia.sigla}
              </div>
              <div className="col-12 col-md-3 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">
                  Estrutura Formal:
                </span>
                {gerencia.estrutura_formal}
              </div>

              <div className="col-12 col-md-3 d-flex justify-content-end">
                <span className="d-sm-inline d-md-none mr-3">Status:</span>
                {gerencia.ativo ? (
                  <span className="font-weight-bold text-success ">
                    {' '}
                    <div
                    // onClick={() => ativarDesativar(lotacao.id, lotacao.ativo)}
                    // style={{ cursor: 'pointer' }}
                    >
                      Ativo
                      {/* <MDBIcon className="ml-1" far icon="thumbs-up" /> */}
                    </div>
                  </span>
                ) : (
                  <div
                    // style={{ cursor: 'pointer' }}
                    // onClick={() => ativarDesativar(lotacao.id, lotacao.ativo)}
                    className="font-weight-bold text-danger "
                  >
                    Inativo
                    {/* <MDBIcon className="ml-1" far icon="thumbs-down" /> */}
                  </div>
                )}
              </div>
            </MDBRow>
          ))}
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Gerencia;
