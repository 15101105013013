import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  format,
  parseISO,
  isFuture,
  subHours,
  subYears,
  differenceInHours,
} from 'date-fns';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBView,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSwitch,
  MDBAnimation,
  MDBSelect,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import InputMask from 'react-input-mask';
import axios from 'axios';
import api from '../../../services/api';
import { validarCNPJ } from '../Components/ValidarCnpj';
import { validarCPF } from '../../../components/ValidarCpf';
import { getPerfil, getData } from '../../../services/auth';
import MaskReal from '../../../components/MaskReal';

import { ExternalStateExample } from '../../../components/localizacaogps';
import { Context } from '../ContextApi/Context.js';
import { BarcodeScanner } from '../../../components/BarcodeScanner';
import { QRCodeScanner } from '../../../components/QRCodeScanner';
export default function App() {
  let history = useHistory();

  const [verificaAdm, setVerificaAdm] = useState();
  useEffect(() => {
    setVerificaAdm(getData().escala);
  }, []);
  // ARRAY DE VEICULOS ABORDADOS
  const [veiculos, setVeiculos] = useState([]);
  const [veiculoEstrangeiro, setVeiculoEstrangeiro] = useState(false);
  // MODAL PARA ADICIONAR NOVO VEICULO
  const [modalVeiculo, setModalVeiculo] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  // DELETAR VEICULOS
  const [modalDeleteVeiculo, setModalDeleteVeiculos] = useState(false);
  const [idDelecaoVeiculo, setIdDelecaoVeiculo] = useState();

  // SWITCH TRUE OU FALSE PARA PLACA OU CHASSI - ONDE FALSE = PLACA E TRUE = CHASSI
  const [switchChassi, setSwitchChassi] = useState(false);

  const [apoioVolante, setApoioVolante] = useState(false);

  // VALOR DO CAMPO DA PLACA OU CHASSI
  const [
    abordagemNumeroPlacaOuChassi,
    setAbordagemNumeroPlacaOuChassi,
  ] = useState('');

  // VALOR DO SELECT DE TIPO
  const [abordagemTipo, setAbordagemTipo] = useState('');

  // VALOR DO LABEL DO SELECT , PARA MOSTRAR NA LISTA
  const [abordagemTipoLabel, setAbordagemTipoLabel] = useState('');

  // FUNCOES DE VEICULOS

  const verificaPlaca = async placa => {
    if (placa === '' || placa.length < 7) {
      setModalVeiculo(true);
    } else {
      try {
        const lista = await api.get(`/veiculo-placa/${placa}`);
        setModalVeiculo(true);
        setAbordagemTipo(lista.data.tipo_veiculo_id);
        setAbordagemTipoLabel(
          selectVeiculos.find(opt => opt.value === lista.data.tipo_veiculo_id)
            .label
        );
      } catch (error) {
        setModalVeiculo(true);
        setAbordagemTipo('');
        setAbordagemTipoLabel('');
      }
    }
  };
  const adicionaVeiculo = () => {
    if (abordagemNumeroPlacaOuChassi === '') {
      toast.error(
        `Preencha o campo ${switchChassi === false ? 'Placa' : 'Chassi'}`
      );
      return;
    }

    if (veiculoEstrangeiro === false) {
      if (switchChassi === false && abordagemNumeroPlacaOuChassi.length !== 7) {
        toast.error(`Placa deve conter 7 caracteres`);
        return;
      }
    }

    if (switchChassi === false && veiculoEstrangeiro === false) {
      const regex = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';

      if (abordagemNumeroPlacaOuChassi.match(regex) === null) {
        toast.error('Placa Inválida');
        return;
      }
    }

    if (switchChassi === true && abordagemNumeroPlacaOuChassi.length !== 17) {
      toast.error(`Chassi deve conter 17 caracteres`);
      return;
    }
    if (abordagemTipo === '') {
      toast.error(`Preencha o campo Tipo`);
      return;
    }
    setVeiculos([
      ...veiculos,
      {
        abordagemTipoPlacaChassi: switchChassi === false ? 'Placa' : 'Chassi',
        abordagemNumeroPlacaOuChassi: abordagemNumeroPlacaOuChassi,
        tipo: abordagemTipo,
        tipoLabel: abordagemTipoLabel,
      },
    ]);
    setSwitchChassi(false);
    setAbordagemNumeroPlacaOuChassi('');
    setAbordagemTipo('');
    setAbordagemTipoLabel('');
    setModalVeiculo(!modalVeiculo);
  };

  const deletarVeiculo = indexArray => {
    if (veiculos.length > -1) {
      const newVeiculos = veiculos;
      newVeiculos.splice(indexArray, 1);

      setVeiculos(newVeiculos);
    }
  };

  //
  //
  // ABORDADOS
  //
  //

  // LISTA DE ABORDADOS
  const [abordados, setAbordados] = useState([]);

  // MODAL DE ABORDADOS
  const [modalAbordado, setModalAbordado] = useState(false);
  const [abordadoCpf, setAbordadoCpf] = useState('');
  const [abordadoNome, setAbordadoNome] = useState('');
  const [abordadoTipo, setAbordadoTipo] = useState('');

  const [idDelecaoAbordado, setIdDelecaoAbordado] = useState();
  const [modalDeleteAbordado, setModalDeleteAbordado] = useState(false);

  // CONDUTOR DO VEÍCULO ABORDADO
  const [switchCondutor, setSwitchCondutor] = useState(false);
  const [abordadoCnh, setAbordadoCnh] = useState('');
  const [abordadoCategoria, setAbordadoCategoria] = useState('');
  const [abordadoValidade, setAbordadoValidade] = useState('');
  const [abordadoEstrangeiro, setAbordadoEstrangeiro] = useState(false);

  const [loadingCPF, setLoadingCPF] = useState(false);

  // FUNCOES DE ABORDADOS
  const verificaAbordado = async cpf => {
    if (cpf === '' || cpf.length < 11) {
      setModalAbordado(true);
    } else {
      setLoadingCPF(true);
      try {
        const lista = await api.get(`/abordados-cpf/${cpf}`);

        if (lista.data === null) {
          setModalAbordado(true);
        } else {
          setAbordadoNome(lista.data.nome);
          setAbordadoTipo(lista.data.tipo);
          if (lista.data.condutor === true) {
            setSwitchCondutor(lista.data.condutor);
            setAbordadoCnh(lista.data.cnh);
            setAbordadoCategoria(lista.data.cnh_categoria);
            setAbordadoValidade(
              format(parseISO(lista.data.cnh_validade), 'yyyy-MM-dd')
            );
          }

          setModalAbordado(true);
        }
      } catch (error) {
        setModalAbordado(true);
        setAbordadoNome('');
        setAbordadoTipo('');
        setSwitchCondutor(false);
        setAbordadoCnh('');
        setAbordadoCategoria('');
        setAbordadoValidade('');
        setAbordadoEstrangeiro(false);
      }

      setLoadingCPF(false);
    }
  };

  // ADICIONA ABORDADO
  const adicionaAbordado = () => {
    if (abordadoNome === '') {
      toast.error('Preencha o campo Nome');
      return;
    }

    if (abordadoEstrangeiro === false) {
      if (abordadoCpf !== '' && abordadoCpf.length !== 11) {
        toast.error('CPF deve conter 11 números');
        return;
      }
      if (validarCPF(abordadoCpf) === false) {
        toast.error('CPF Inválido');
        return;
      }
    }

    if (abordadoNome.length > 150) {
      toast.error('Limite de Caracteres excedido');
      return;
    }
    if (abordadoTipo === '') {
      toast.error('Preencha o campo Função');
      return;
    }
    if (switchCondutor === true) {
      if (abordadoCnh === '') {
        toast.error('Preencha o campo CNH');
        return;
      }
      if (abordadoCategoria === '') {
        toast.error('Preencha o campo Categoria');
        return;
      }
      if (abordadoValidade === '') {
        toast.error('Preencha o campo Validade');
        return;
      }
    }

    const condutor = {};

    if (switchCondutor === true) {
      condutor.abordadoCnh = abordadoCnh;
      condutor.abordadoCategoria = abordadoCategoria;

      // condutor.abordadoValidade = parseISO(new Date(abordadoValidade));
      condutor.abordadoValidade = parseISO(abordadoValidade);
    }

    abordados.forEach(abo => {
      if (abo.abordadoCpf === abordadoCpf) {
        toast.error('Abordado Repetido');
        return;
      }
    });

    setAbordados([
      ...abordados,
      {
        // abordagemTipoPlacaChassi: switchChassi === false ? 'Placa' : 'Chassi',
        abordadoCpf,
        abordadoNome,
        abordadoTipo,
        switchCondutor,
        ...condutor,
      },
    ]);
    setSwitchCondutor(false);
    setAbordadoCpf('');
    setAbordadoNome('');
    setAbordadoTipo('');
    setAbordadoCnh('');
    setAbordadoCategoria('');
    setAbordadoValidade('');
    setModalAbordado(!modalAbordado);
    setAbordadoEstrangeiro(false);
  };

  // REMOVE ABORDADO
  const deletarAbordado = indexArray => {
    if (abordados.length > -1) {
      const newAbordados = abordados;
      newAbordados.splice(indexArray, 1);

      setAbordados(newAbordados);
    }
  };

  //
  //
  // EMPRESAS ABORDADAS
  //
  //
  // MODAL DE EMPRESAS
  const [modalEmpresa, setModalEmpresa] = useState(false);

  // DELETAR EMPRESAS
  const [idDelecaoEmpresa, setIdDelecaoEmpresa] = useState();
  const [modalDeleteEmpresa, setModalDeleteEmpresa] = useState(false);

  // LISTA DE EMPRESAS
  const [empresas, setEmpresas] = useState([]);

  const [cnpj, setCnpj] = useState('');
  const [empresaNome, setEmpresaNome] = useState('');
  const [cpfValido, setCpfValido] = useState(null);

  // FUNCOES
  // FUNCOES DE EMPRESAS
  // ADICIONA EMPRESA
  const adicionaEmpresa = () => {
    const validade = validarCNPJ(cnpj);

    if (empresaNome.length > 150) {
      toast.error('Excedeu limite de caracteres');
      return;
    }
    // if(cnpj === ''){
    //   toast.error('Preencha CNPJ');
    //   return;
    // }
    if (validade === false) {
      toast.error('CNPJ inválido');
      return;
    }
    let duplicado = false;
    empresas.forEach(emp => {
      if (emp.cnpj === cnpj) {
        duplicado = true;

        return;
      }
    });
    if (duplicado === true) {
      toast.error('CNPJ já inserido');
      return;
    }

    const empresa = {};

    setEmpresas([
      ...empresas,
      {
        // abordagemTipoPlacaChassi: switchChassi === false ? 'Placa' : 'Chassi',
        cnpj,
        empresaNome,
        notas: [],
      },
    ]);
    setCnpj('');
    setEmpresaNome('');
    setModalEmpresa(!modalEmpresa);
    // setModalEmpresa('false');
  };

  // REMOVE ABORDADO
  const deletarEmpresa = indexArray => {
    if (empresas.length > -1) {
      const newEmpresas = empresas;
      newEmpresas.splice(indexArray, 1);

      setEmpresas(newEmpresas);
    }
  };

  //
  //
  // NOTAS FISCAIS DE EMPRESAS ABORDADAS
  //
  //
  // MODAL DE NOTAS
  const [modalNota, setModalNota] = useState(false);

  // DELETAR EMPRESAS
  const [idDelecaoNota, setIdDelecaoNota] = useState();
  const [modalDeleteNota, setModalDeleteNota] = useState(false);

  // LISTA DE NOTAS
  // const [notas, setNotas] = useState([]);

  // tipo false : nota fiscal / tipo: true manifesto
  const [switchTipoNota, setSwitchTipoNota] = useState(true);

  // const [numeroManifesto, setNumeroManifesto] = useState('');
  // const [chaveManifesto, setChaveManifesto] = useState('');

  const [numero, setNumero] = useState('');
  const [chave, setChave] = useState('');
  const [valor, setValor] = useState('');
  const [icms, setIcms] = useState('');

  // VARIAVEL QUE GUARDA ID DA EMPRESA PARA ADICIONAR e DELETAR A NOTA FISCAL
  const [idEmpresaParaNota, setIdEmpresaParaNota] = useState('');

  const [descricao, setDescricao] = useState('');
  // FUNCOES
  // FUNCOES DE EMPRESAS
  // ADICIONA EMPRESA
  const adicionaNota = async () => {
    if (numero && numero !== '' && numero.length > 20) {
      toast.error('Máximo de caracteres excedidos');
      return;
    }
    if (chave && chave.length < 10) {
      toast.error('Mínimo de 10 caracteres em CHAVE');
      return;
    }
    if (chave && chave !== '' && chave.length > 255) {
      toast.error('Máximo de caracteres excedidos em CHAVE');
      return;
    }
    // se for manifesto
    if (switchTipoNota === true) {
      if (numero === '') {
        toast.error('Preencha o campo Número');
        return;
      }
      const addNotas = empresas;
      addNotas[idEmpresaParaNota].notas.push({
        tipo: 'Manifesto',
        numero,
        chave,

        duplicado: await verificaDuplicado({ chave: chave }),
      });

      setSwitchTipoNota(true);
      setEmpresas(addNotas);
      setNumero('');
      setChave('');
      setValor('');
      setIcms('');
      setIdEmpresaParaNota('');
      setModalNota(!modalNota);
    } else {
      if (chave === '') {
        toast.error('Preencha o campo Chave');
        return;
      }
      const addNotas = empresas;
      addNotas[idEmpresaParaNota].notas.push({
        tipo: 'Nota Fiscal',
        numero,
        chave,
        duplicado: await verificaDuplicado({ chave }),
        valor: valor.toString().replace(',', '.'),
        icms: icms.toString().replace(',', '.'),
      });

      setEmpresas(addNotas);
      setNumero('');
      setChave('');
      setValor('');
      setIcms('');
      setIdEmpresaParaNota('');
      setModalNota(!modalNota);
    }
  };

  // REMOVE NOTA
  const deletarNota = (indexEmpresa, indexArray) => {
    const removerNotas = empresas;

    if (removerNotas[indexEmpresa].notas.length > -1) {
      removerNotas[indexEmpresa].notas.splice(indexArray, 1);

      setEmpresas(removerNotas);
    }
  };

  //
  //
  // FUNCOES DO SISTEMA
  //
  //
  //

  // GERENCIAS
  const [listaGerencias, setListaGerencias] = useState([]);
  // const [gerenciasSelecionada, setGerenciasSelecionada] = useState([]);
  // carrega as gerencias do select
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
    });
  };

  // LOTACOES
  const [listaLotacoes, setListaLotacoes] = useState([]);
  const [lotacaoSelecionada, setLotacaoSelecionada] = useState({
    label: '',
    value: '',
  });
  const [loadLotacao, setLoadLotacao] = useState(false);
  // carrega as lotacoes do select
  const carregaLotacoes = gerenciaid => {
    if (gerenciaid === undefined || gerenciaid === '' || gerenciaid === null) {
      return;
    }
    setLotacaoSelecionada({ label: '', value: '' });

    api.get(`/lotacao-por-gerencia/${gerenciaid}`).then(linhas => {
      let selectLotacao = [];
      linhas.data.map(linha => {
        selectLotacao.push({
          label: `${linha.escala.nome}/ ${linha.ala.nome}`,
          value: String(linha.id),
        });
      });
      setLoadLotacao(false);
      setListaLotacoes(selectLotacao);
    });
  };

  // DATAS INICIAIS E FINAIS
  const [dataInicio, setDataInicio] = useState(
    format(new Date(), "yyyy-MM-dd'T'HH:mm")
  );
  const [dataFim, setDataFim] = useState('');

  // ORIGEM DESTINO
  const [listaOrigemEstado, setListaOrigemEstado] = useState([]);
  const [listaOrigemCidade, setListaOrigemCidade] = useState([]);
  // const [listaDestinoEstado, setListaDestinoEstado] = useState([]);
  const [listaDestinoCidade, setListaDestinoCidade] = useState([]);

  // carrega as gerencias do select
  const carregaOrigemDestino = () => {
    api.get('/estados').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaOrigemEstado(select);
      // setListaDestinoEstado(select);
    });
  };

  const [loadingOrigem, setLoadingOrigem] = useState(false);
  const [cidadeOrigemSelecionada, setCidadeOrigemSelecionada] = useState({
    label: '',
    value: '',
  });
  const [evasao, setEvasao] = useState({
    label: 'Não',
    value: false,
  });

  // carrega as cidades do select
  const carregaOrigemSelect = estadoId => {
    if (estadoId === undefined || estadoId === '' || estadoId === null) {
      return;
    }
    setLoadingOrigem(true);
    api.get(`/cidades/${estadoId}`).then(linhas => {
      let selectOrigem = [];
      linhas.data.map(linha => {
        selectOrigem.push({
          label: `${linha.nome}`,
          value: String(linha.id),
        });
      });
      setLoadingOrigem(false);
      setListaOrigemCidade(selectOrigem);
      setCidadeOrigemSelecionada({ label: '', value: '' });
    });
  };

  const [loadingDestino, setLoadingDestino] = useState(false);
  const [cidadeDestinoSelecionada, setCidadeDestinoSelecionada] = useState({
    label: '',
    value: '',
  });

  // carrega as cidades do select
  const carregaDestinoSelect = estadoId => {
    if (estadoId === undefined || estadoId === '' || estadoId === null) {
      return;
    }
    setLoadingDestino(true);
    api.get(`/cidades/${estadoId}`).then(linhas => {
      let selectDestino = [];
      linhas.data.map(linha => {
        selectDestino.push({
          label: `${linha.nome}`,
          value: String(linha.id),
        });
      });
      setLoadingDestino(false);
      setListaDestinoCidade(selectDestino);
      setCidadeDestinoSelecionada({ label: '', value: '' });
    });
  };

  // SELECT DE TIPO DE VEICULOS
  const [selectVeiculos, setSelectVeiculos] = useState([]);

  const listaTiposVeiculos = async () => {
    const lista = await api.get('/tipo-veiculos');
    const select = [];
    lista.data.forEach(t => {
      select.push({
        label: t.nome,
        value: t.id,
      });
    });
    setSelectVeiculos(select);
  };

  const [modalConcluir, setModalConcluir] = useState(false);

  const criaAbordagem = () => {
    api
      .post('/add-abordagem', {
        lotacao:
          (verificaAdm !== 'Adm' && lotacaoSelecionada.value === '') ||
          lotacaoSelecionada === ''
            ? getData().lotacao_id
            : lotacaoSelecionada,
        evasao: evasao.value,
        data_inicio: format(
          new Date(dataInicio),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        data_fim:
          dataFim === ''
            ? format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
            : format(new Date(dataFim), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        cidade_origem: cidadeOrigemSelecionada,
        cidade_destino: cidadeDestinoSelecionada,

        veiculos,
        abordados,
        empresas,
      })
      .then(t => {
        history.push(`/add-ocorrencia/${t.data.id}`);
      });
  };

  // mapa
  const [coordenadas, setCoordenadas] = useState({
    newGeo: false,
  });
  const [modalLatLong, setModalLatLong] = useState(false);

  const [loadingAbordagem, setLoadingAbordagem] = useState(false);

  const finalizaAbordagem = () => {
    setLoadingAbordagem(true);
    let latitude = coordenadas.geo ? coordenadas.geo[0] : null;
    let longitude = coordenadas.geo ? coordenadas.geo[1] : null;

    api
      .post('/add-abordagem', {
        lotacao:
          (verificaAdm !== 'Adm' && lotacaoSelecionada.value === '') ||
          lotacaoSelecionada === ''
            ? getData().lotacao_id
            : lotacaoSelecionada,
        data_inicio: format(
          new Date(dataInicio),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
        ),
        data_fim:
          dataFim === ''
            ? format(new Date(dataInicio), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
            : format(new Date(dataFim), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
        evasao: evasao.value,
        cidade_origem: cidadeOrigemSelecionada,
        cidade_destino: cidadeDestinoSelecionada,
        apoio_volante: apoioVolante,
        veiculos,
        abordados,
        empresas,
        latitude,
        longitude,
        descricao,
      })
      .then(t => {
        setLoadingAbordagem(false);
        history.push(`/minhas-abordagens`);
      })
      .catch(() => setLoadingAbordagem(false));
  };

  // const buscarLocalizacao = () => {
  //   let coordenadas = '';
  //   if (navigator.geolocation) {
  //     coordenadas = navigator.geolocation.getCurrentPosition();
  //   } else {
  //     console.log('Geo nao suportada');
  //   }
  //   console.log(coordenadas);
  // };

  // const barcode = useRef();

  // const [qrCodeValue, setQRCodeValue] = useState('');

  // const handleQRCodeValue = value => {
  //   setChave(value);
  //   // console.log(value);
  // };
  // const verificaDuplicado = async query => {
  //   const duplicado = await api.get('/manifesto', {
  //     params: query,
  //   });

  //   return duplicado.data;
  // };
  const verificaDuplicado = useCallback(async query => {
    try {
      const duplicado = await api.get('/manifesto', {
        params: query,
      });

      return duplicado.data;
    } catch (error) {
      console.error('Erro ao verificar duplicado:', error);
      // Trate o erro conforme necessário
    }
  }, []);

  const handleQRCodeValue = async url => {
    const result = url.split('MDFe=');

    if (result[1] === undefined) {
      toast.error('QRCode Inválido');
      return;
    }

    const valor = result[1].split('&');

    const requestBody = {
      // Seu corpo de requisição aqui
      chave: valor[0],
    };

    // setManifestosDuplicados(duplicado.data);

    try {
      const response = await axios.post(
        'https://ok.nuvem.live/api/mdfe',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer SaR1fBG!988X6FW3jkca-zYFjsHMXTvr7hikQLCQKzqWsA==frkto0=7htTdsUylNIRd?cb2b7badnp-!Q40KHzFng7hel5eEj0vdT8k2fDmumI/8x6JcUhU2lGzTRq1`,
          },
        }
      );

      setEmpresas([
        ...empresas,
        {
          cnpj: response.data.emitente.cnpj.replace(/\D+/g, ''),
          empresaNome: response.data.emitente.nome_razao_social,
          notas: [
            {
              tipo: 'Manifesto',
              numero: response.data.dados_mdfe.numero,
              data_emissao: response.data.dados_mdfe.data_emissao,
              data_encerramento: response.data.dados_mdfe.data_encerramento,
              condutores: response.data.condutores,
              chave: url,
              duplicado: await verificaDuplicado({ chave: valor[0] }),
              autorizacoes: response?.data?.eventos_servicos,
            },
          ],
        },
      ]);

      setModalEmpresa(!modalEmpresa);
      setModalNota(false);

      //   setNumero(response.data.dados_mdfe.numero);
      //   setDadosMdfe({
      //     data_emissao: response.data.dados_mdfe.data_emissao,
      //     uf_inicio: response.data.dados_mdfe.uf_inicio,
      //     uf_fim: response.data.dados_mdfe.uf_fim,
      //     cnpj: response.data.emitente.cnpj,
      //     eventos_servicos: response.data.eventos_servicos,
      //   });
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      return result.json({ erro: 'Erro! Entre em contato com suporte' });
    }
  };

  useEffect(() => {
    carregaGerencias();
    carregaOrigemDestino();
    listaTiposVeiculos();
  }, []);

  // useEffect(() => {
  //   if (modalLatLong === true) {
  //     coordenadas();
  //   }
  // }, [modalLatLong]);
  return (
    <NavBar>
      <Context.Provider value={[coordenadas, setCoordenadas]}>
        <MDBContainer>
          {/* <MDBRow className="h4-responsive" center>
          Registro de Abordagem
        </MDBRow>
        <br /> */}
          {/* DADOS DE ABORDAGEM */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">
                Registrar Abordagem
              </h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <MDBRow>
                {verificaAdm !== 'Adm' ? (
                  // console.log(getData(), getPerfil())
                  ''
                ) : (
                  <>
                    <MDBCol size="12" md="3" middle>
                      <Select
                        id="posto"
                        placeholder="Posto"
                        options={listaGerencias}
                        onChange={e => {
                          carregaLotacoes(e.value);
                        }}
                      />
                    </MDBCol>
                    <MDBCol className="mt-2 mt-md-0" size="12" md="3" middle>
                      <Select
                        id="ala"
                        placeholder="Ala"
                        options={listaLotacoes}
                        isLoading={loadLotacao}
                        value={lotacaoSelecionada?.value}
                        onChange={e => {
                          setLotacaoSelecionada(e.value);
                        }}
                      />
                    </MDBCol>
                  </>
                )}
                <MDBCol className="mt-3 mt-md-0" size="12" md="3" middle>
                  <span style={{ fontSize: '14px' }}>Data Início</span>
                  <MDBInput
                    id="dt-inicio"
                    style={{ marginTop: '-28px' }}
                    type="datetime-local"
                    value={dataInicio}
                    onChange={e => {
                      setDataInicio(e.target.value);

                      // setDataInicio(
                      //   format(
                      //     new Date(e.target.value),
                      //     "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
                      //   )
                      // );
                    }}
                  />
                </MDBCol>
                <MDBCol className="mt-3 mt-md-0" size="12" md="3" middle>
                  <span style={{ fontSize: '14px' }}>Data Fim</span>
                  <MDBInput
                    id="dt-fim"
                    type="datetime-local"
                    style={{ marginTop: '-28px' }}
                    onChange={e => {
                      setDataFim(e.target.value);
                    }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  Origem
                </MDBCol>
                <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                  <Select
                    id="estado"
                    placeholder="Estado"
                    options={listaOrigemEstado}
                    onChange={e => carregaOrigemSelect(e.value)}
                  />
                </MDBCol>
                <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                  <Select
                    placeholder="Cidade"
                    options={listaOrigemCidade}
                    value={cidadeOrigemSelecionada?.value}
                    onChange={e => {
                      setCidadeOrigemSelecionada(e.value);
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  Destino
                </MDBCol>
                <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                  <Select
                    placeholder="Estado"
                    options={listaOrigemEstado}
                    onChange={e => carregaDestinoSelect(e.value)}
                  />
                </MDBCol>
                <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                  <Select
                    placeholder="Cidade"
                    isLoading={loadingDestino}
                    value={cidadeDestinoSelecionada?.value}
                    onChange={e => setCidadeDestinoSelecionada(e.value)}
                    options={listaDestinoCidade}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  Veículo Interceptado após Evasão
                </MDBCol>
                <MDBCol size="12" md="4" middle>
                  <Select
                    placeholder="Evasão"
                    value={evasao}
                    options={[
                      { label: 'Sim', value: true },
                      { label: 'Não', value: false },
                    ]}
                    onChange={e => setEvasao(e)}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBRow center>Apoiado pela Operação Volante</MDBRow>
                  <MDBRow center>
                    <MDBSwitch
                      id="volante"
                      labelLeft="Não"
                      labelRight="Sim"
                      getValue={e => setApoioVolante(!apoioVolante)}
                      checked={apoioVolante}
                      // defaultChecked
                      // disabled
                      // id="flexSwitchCheckCheckedDisabled"
                      // label="Disabled checked switch checkbox input"
                    />
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow end className="mt-3">
                <MDBCol size="12" md="4">
                  <MDBRow center>
                    <MDBBtn
                      color={`${
                        coordenadas.geo === undefined ? 'dark-green' : 'danger'
                      }`}
                      className="font-weight-bolder"
                      size="sm"
                      onClick={() => setModalLatLong(!modalLatLong)}
                    >
                      {coordenadas.geo === undefined
                        ? 'Inserir Localização'
                        : 'Alterar Localização'}
                    </MDBBtn>
                  </MDBRow>
                  <MDBRow center>
                    {coordenadas.geo && coordenadas.geo[0] !== undefined ? (
                      <>
                        <MDBRow>
                          <MDBCol size="10">
                            Lat: {coordenadas?.geo[0]}
                            <br />
                            Long: {coordenadas?.geo[1]}
                          </MDBCol>
                          <MDBCol size="2" middle>
                            <MDBIcon
                              className="text-danger cursor-pointer"
                              size="1x"
                              far
                              icon="trash-alt"
                              onClick={() =>
                                setCoordenadas({
                                  newGeo: false,
                                })
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ''
                    )}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE VEÍCULOS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Veículos</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              {veiculos.map((veiculo, index) => (
                <>
                  <MDBRow
                    key={`A${index}`}
                    // style={{ border: '1px solid silver' }}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderTop: '1px solid rgba(0,0,0,0.1)',
                    }}
                    between
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                  >
                    <MDBCol>
                      <span className="font-weight-bolder  mr-3">
                        {veiculo.abordagemTipoPlacaChassi}:
                      </span>
                      <span>{veiculo.abordagemNumeroPlacaOuChassi}</span>
                    </MDBCol>

                    <MDBCol>
                      <span className=" font-weight-bolder  mr-3">Tipo:</span>
                      <span>{veiculo.tipoLabel}</span>
                    </MDBCol>

                    <div className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="danger-text "
                        onClick={() => {
                          setModalDeleteVeiculos(true);
                          setIdDelecaoVeiculo(index);
                          // deletarVeiculo(index);
                        }}
                      >
                        <MDBIcon
                          className="mr-1 "
                          far
                          icon="trash-alt"
                          size="1x"
                        />
                        <span style={{ fontSize: '14px' }}>Remover</span>
                      </div>
                    </div>
                  </MDBRow>
                </>
              ))}
              <br />
              <MDBRow center>
                <MDBCol size="12" md="3">
                  <InputMask
                    style={{
                      border: 0,
                      borderBottom: '1px solid #ced4da',
                      fontSize: '1.25rem',
                    }}
                    className={`w-100 font-weight-light md-form form-group`}
                    // disabled={abordadoEstrangeiro}
                    type="text"
                    placeholder="Placa"
                    onChange={e => {
                      setAbordagemNumeroPlacaOuChassi(
                        e.target.value.toUpperCase()
                      );
                    }}
                    // onChange={e =>
                    //   setAbordadoCpf(e.target.value.replace(/\D+/g, ''))
                    // }
                    // onBlur={e => {
                    //   if (e.target.value !== '') {
                    //     if (!validarCPF(abordadoCpf)) {
                    //       setCpfValido(false);
                    //       return;
                    //     }
                    //     setCpfValido(true);
                    //   }
                    // }}
                    value={abordagemNumeroPlacaOuChassi}
                    mask="aaa9*99"
                    maskChar=" "
                  />
                  {/* <MDBInput
                    maxLength={'7'}
                    type="text"
                    placeholder={'Placa'}
                    onChange={e => {
                      setAbordagemNumeroPlacaOuChassi(
                        e.target.value.toUpperCase()
                      );
                    }}
                    value={abordagemNumeroPlacaOuChassi}
                  /> */}
                </MDBCol>
                <MDBCol size="12" md="6">
                  <MDBBtn
                    color="mdb-color"
                    onClick={() => {
                      verificaPlaca(abordagemNumeroPlacaOuChassi);
                    }}
                  >
                    Adicionar Veículo
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE ABORDADOS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Abordados</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              {abordados.map((abordado, index) => (
                <>
                  <MDBRow
                    key={`b${index}`}
                    // style={{ border: '1px solid silver' }}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderTop: '1px solid rgba(0,0,0,0.1)',
                    }}
                    between
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                  >
                    <MDBCol>
                      <span className="font-weight-bolder  mr-3">CPF:</span>
                      <span>{abordado.abordadoCpf}</span>
                    </MDBCol>

                    <MDBCol>
                      <span className=" font-weight-bolder  mr-3">Nome:</span>
                      <span>{abordado.abordadoNome}</span>
                    </MDBCol>
                    <MDBCol>
                      <span className=" font-weight-bolder  mr-3">Tipo:</span>
                      <span>{abordado.abordadoTipo}</span>
                    </MDBCol>
                  </MDBRow>
                  {abordado.switchCondutor === true ? (
                    <MDBRow
                      key={`c${index}`}
                      // style={{ border: '1px solid silver' }}
                      style={{
                        backgroundColor: 'rgba(0,0,0,0.1)',
                      }}
                      between
                      className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                    >
                      <MDBCol>
                        <span className="font-weight-bolder  mr-3">CNH:</span>
                        <span>{abordado.abordadoCnh}</span>
                      </MDBCol>
                      <MDBCol>
                        <span className="font-weight-bolder  mr-3">
                          Categoria:
                        </span>
                        <span>{abordado.abordadoCategoria}</span>
                      </MDBCol>
                      <MDBCol>
                        <span className="font-weight-bolder  mr-3">
                          Validade:
                        </span>
                        <span>
                          {abordado.abordadoValidade &&
                            format(abordado.abordadoValidade, 'dd-MM-yyyy')}
                        </span>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ''
                  )}
                  <MDBRow
                    center
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                  >
                    <div className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="danger-text "
                        onClick={() => {
                          setModalDeleteAbordado(true);
                          setIdDelecaoAbordado(index);
                          // deletarVeiculo(index);
                        }}
                      >
                        <MDBIcon
                          className="mr-1 "
                          far
                          icon="trash-alt"
                          size="1x"
                        />
                        <span style={{ fontSize: '14px' }}>Remover</span>
                      </div>
                    </div>
                  </MDBRow>
                </>
              ))}
              <br />
              <MDBRow center>
                <MDBCol size="12" md="3" offset="3">
                  <InputMask
                    style={{
                      border: 0,
                      borderBottom: '1px solid #ced4da',
                      fontSize: '1.25rem',
                    }}
                    className={`w-100 font-weight-light md-form form-group form-lg ${abordadoEstrangeiro ===
                      true && 'red lighten-5'}`}
                    disabled={abordadoEstrangeiro}
                    type="text"
                    placeholder="CPF"
                    onChange={e =>
                      setAbordadoCpf(e.target.value.replace(/\D+/g, ''))
                    }
                    // onBlur={e => {
                    //   if (e.target.value !== '') {
                    //     if (!validarCPF(abordadoCpf)) {
                    //       setCpfValido(false);
                    //       return;
                    //     }
                    //     setCpfValido(true);
                    //   }
                    // }}
                    value={abordadoCpf}
                    mask="999.999.999-99"
                    maskChar=" "
                  />
                </MDBCol>
                <MDBCol middle size="12" md="6">
                  {loadingCPF ? (
                    <div className="d-flex align-items-center">
                      Buscando CPF
                    </div>
                  ) : (
                    <MDBBtn
                      color="mdb-color"
                      onClick={() => {
                        verificaAbordado(abordadoCpf);
                      }}
                    >
                      Adicionar Abordado
                    </MDBBtn>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <br />
          {/* DADOS DE EMPRESAS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Empresas</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              {empresas.map((empresa, index) => (
                <>
                  <MDBRow
                    key={`d${index}`}
                    // style={{ border: '1px solid silver' }}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      borderTop: '1px solid rgba(0,0,0,0.1)',
                    }}
                    between
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                  >
                    <MDBCol size="12" md="4">
                      <span className="font-weight-bolder  mr-3">CNPJ:</span>
                      <span>{empresa.cnpj}</span>
                    </MDBCol>

                    <MDBCol size="12" md="8">
                      <span className=" font-weight-bolder  mr-3">Nome:</span>
                      <span>{empresa.empresaNome}</span>
                    </MDBCol>
                  </MDBRow>
                  {/*


                NOTAS FISCAIS


                  */}

                  {empresas[index].notas &&
                    empresas[index].notas.map((t, ind) => (
                      <div
                        className="mr-1 ml-1 mr-md-3 ml-md-3 "
                        style={{
                          backgroundColor: 'rgba(0,0,0,0.2)',
                          // border: '1px solid rgba(0,0,0,0.1)',
                        }}
                      >
                        <MDBRow
                          // className="bg-warning"
                          className="p-2 mr-0 ml-0 bg-warning font-weight-bold"
                        >
                          <MDBCol>
                            {t.tipo === 'Nota Fiscal'
                              ? 'Nota Fiscal '
                              : 'Manifesto '}
                          </MDBCol>
                          {/* {`Nº: ${ind + 1} - `} */}
                        </MDBRow>
                        {
                          <>
                            {t.duplicado.length > 0 && (
                              <MDBRow
                                center
                                className="font-weight-bold text-danger"
                              >
                                <MDBCol className="text-center">
                                  CHAVE DUPLICADA EM:
                                </MDBCol>
                              </MDBRow>
                            )}

                            {t.duplicado.map(dup => (
                              <MDBRow
                                //  key={e.id}
                                // style={{ border: '1px solid silver' }}
                                between
                                className="p-2 mr-0 ml-0 bg-danger  mt-2"
                              >
                                <MDBCol size="12" md="4">
                                  <span className="font-weight-bolder  mr-3">
                                    Data:
                                  </span>
                                  <span>
                                    {/* {new Date(dup.createdAt)}
                                  Data:{' '} */}
                                    {dup.createdAt &&
                                      format(
                                        new Date(dup?.createdAt),
                                        'dd/MM/yyyy HH:mm'
                                      )}
                                    {/* {parseISO(dup.createdAt)} */}
                                    {/* {format(
                                    new Date(dup?.createdAt, 'dd/MM/yyyy HH:mm')
                                  )} */}
                                  </span>
                                </MDBCol>

                                <MDBCol size="12" md="8">
                                  <span className=" font-weight-bolder  mr-3">
                                    Abordagem:
                                  </span>
                                  <span>{dup.abordagem_id}</span>
                                </MDBCol>
                              </MDBRow>
                            ))}
                          </>
                        }

                        {t.condutores &&
                          t.condutores.map(cond => (
                            <MDBRow className="p-2 mr-0 ml-0">
                              <MDBCol size="12" md="6">
                                {`Condutor: ${cond.condutor}`}
                              </MDBCol>
                              <MDBCol size="12" md="6">
                                {`CPF: ${cond.cpf}`}
                              </MDBCol>
                            </MDBRow>
                          ))}
                        {t.data_emissao && (
                          <MDBRow className="p-2 mr-0 ml-0">
                            <MDBCol
                              size="12"
                              md="6"
                            >{`Emissão: ${t.data_emissao &&
                              format(
                                new Date(t?.data_emissao),
                                'dd/MM/yyyy HH:mm'
                              )}`}</MDBCol>
                            <MDBCol size="12" md="6">
                              {t.data_encerramento &&
                              t?.data_encerramento === null ? (
                                <div className="green-text">
                                  Encerramento: Aberto
                                </div>
                              ) : (
                                <div className="red-text">
                                  Encerramento:{' '}
                                  {t.data_encerramento &&
                                    format(
                                      new Date(t?.data_encerramento),
                                      'dd/MM/yyyy HH:mm'
                                    )}
                                </div>
                              )}
                            </MDBCol>
                          </MDBRow>
                        )}

                        <MDBRow
                          key={`d${ind}`}
                          // style={{ border: '1px solid silver' }}
                          className="p-2 mr-0 ml-0"
                          between
                        >
                          <MDBCol size="12" md="3">
                            {`Número: ${t.numero}`}
                          </MDBCol>

                          {t.tipo === 'Nota Fiscal' ? (
                            <>
                              <MDBCol size="12" md="3">
                                {`Valor: ${t.valor
                                  .toString()
                                  .replace('.', ',')}`}
                              </MDBCol>
                              <MDBCol size="12" md="3">
                                {`Icms: ${t.icms.toString().replace('.', ',')}`}
                              </MDBCol>
                            </>
                          ) : (
                            ''
                          )}
                        </MDBRow>
                        {t.autorizacoes
                          ? t.autorizacoes.map(aut => (
                              <MDBRow className="m-2 p-2 border">
                                <MDBCol size="12" md="6">
                                  Data:{' '}
                                  {format(
                                    new Date(aut?.data_autorizacao),
                                    'dd/MM/yyyy HH:mm'
                                  )}
                                </MDBCol>
                                <MDBCol size="12" md="6">
                                  Tipo: {aut?.evento}
                                </MDBCol>
                              </MDBRow>
                            ))
                          : ''}
                        {/* {t?.autorizacoes?.data_autorizacao}/
                        {t?.autorizacoes?.evento} */}
                        <MDBRow>
                          <br />
                          <MDBCol className="mt-3" size="12">
                            <div
                              style={{ cursor: 'pointer' }}
                              className="danger-text text-center"
                              onClick={() => {
                                setModalDeleteNota(true);
                                setIdEmpresaParaNota(index);
                                setIdDelecaoNota(ind);
                                // deletarVeiculo(index);
                              }}
                            >
                              <MDBIcon
                                className="mr-1 "
                                far
                                icon="trash-alt"
                                size="1x"
                              />
                              <span style={{ fontSize: '14px' }}>
                                Remover Nota
                              </span>
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    ))}
                  <MDBRow
                    key={`e${index}`}
                    // style={{ border: '1px solid silver' }}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.1)',
                      border: '1px solid rgba(0,0,0,0.1)',
                    }}
                    center
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                  >
                    <MDBBtn
                      color="warning"
                      onClick={() => {
                        setModalNota(!modalNota);
                        // VARIAVEL QUE GUARDA ID DA EMPRESA PARA ADICIONAR A NOTA FISCAL
                        setIdEmpresaParaNota(index);
                      }}
                    >
                      <MDBIcon className="black-text" icon="plus" />
                      <span className="pl-2 black-text">Documento Fiscal</span>
                    </MDBBtn>
                  </MDBRow>
                  <MDBRow
                    center
                    className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                  >
                    <div className="col-12 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                      <div
                        style={{ cursor: 'pointer' }}
                        className="danger-text"
                        onClick={() => {
                          setModalDeleteEmpresa(true);
                          setIdDelecaoEmpresa(index);
                          // deletarVeiculo(index);
                        }}
                      >
                        <MDBIcon
                          className="mr-1 "
                          far
                          icon="trash-alt"
                          size="1x"
                        />
                        <span style={{ fontSize: '14px' }}>
                          Remover Empresa
                        </span>
                      </div>
                    </div>
                  </MDBRow>
                </>
              ))}
              <br />
              <MDBRow center>
                <MDBBtn
                  color="mdb-color"
                  onClick={() => {
                    setModalEmpresa(!modalEmpresa);
                  }}
                >
                  Adicionar Empresa
                </MDBBtn>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <br />
          {/* DADOS DE DESCRICAO */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Descrição</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <MDBRow center>
                <MDBCol className="text-danger text-center">
                  Atenção: A descrição não é obrigatória e pode servir para
                  informar para abordagens sem ocorrência.
                  <br />
                  <br />
                  {250 - descricao.length} Caracteres permitidos
                </MDBCol>
              </MDBRow>
              <MDBRow className="px-3">
                <MDBCol>
                  <MDBInput
                    onChange={e => setDescricao(e.target.value)}
                    style={{ borderRadius: '5px' }}
                    type="textarea"
                    outline
                    rows="5"
                    // counter
                    maxLength="250"
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {/* DADOS DE DESCRICAO */}
          {/* <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">
              Dinâmica da Abordagem
            </h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBInput
              counter={200}
              outline
              type="textarea"
              rows="3"
              label="Descreva a Dinâmica"
              value={dinamicaAbordagem}
              onChange={t => setDinamicaAbordagem(t.target.value)}
            />
          </MDBCardBody>
        </MDBCard> */}
          {/* <br /> */}
          {/* <MDBRow>
          <MDBBtn>Inserir foto</MDBBtn>
          <SendImg />
        </MDBRow> */}
          <MDBRow center>
            <MDBBtn
              onClick={() => {
                if (verificaAdm === 'Adm' && lotacaoSelecionada.value === '') {
                  toast.error('Preencha o campo Lotação');
                  return;
                }
                if (dataInicio === '') {
                  toast.error('Preencha o campo Data Início');
                  return;
                }
                const dataInicioDate = new Date(dataInicio);
                // Verificar se dataInicio não está no futuro
                if (isFuture(dataInicioDate)) {
                  toast.error('A Data Início não pode estar no futuro');
                  return;
                }
                if (verificaAdm === 'Adm') {
                  // Verificar se dataInicio não é anterior a 6 horas do momento atual
                  const UmAnoAtras = subYears(new Date(), 1);
                  if (dataInicioDate < UmAnoAtras) {
                    toast.error('A Data Início não pode ser anterior a 1 Ano');
                    return;
                  }
                } else {
                  const VinteQuatroHorasAtras = subHours(new Date(), 24);
                  if (dataInicioDate < VinteQuatroHorasAtras) {
                    toast.error(
                      'A Data Início não pode ser anterior a 24 horas'
                    );
                    return;
                  }
                }

                if (dataFim === '') {
                  const dataFimDate = new Date(dataFim);
                  // const sevenDays = subDays(new Date(dataInicio), 7);
                  // console.log(sevenDays);
                  // if (dataInicio > sevenDays) {
                  //   toast.error('Mais de 7 dias em atraso');
                  //   return;
                  // }
                  if (dataFimDate < dataInicio) {
                    toast.error('Data Início maior que Data Fim');
                    return;
                  }
                  // Verificar se a diferença entre dataInicio e dataFim não é maior que 48 horas
                  if (differenceInHours(dataFimDate, dataInicioDate) > 48) {
                    toast.error(
                      'A diferença entre a Data Início e a Data Fim não pode ser maior que 48 horas'
                    );
                    return;
                  }
                }
                // if (verificaAdm === 'Adm' && dataFim === '') {
                //   toast.error('Preecha o campo Data Fim');
                //   return;
                // }

                if (cidadeOrigemSelecionada.value === '') {
                  toast.error('Preencha o Campo Cidade Origem');
                  return;
                }
                if (cidadeDestinoSelecionada.value === '') {
                  toast.error('Preencha o Campo Cidade Destino');
                  return;
                }
                // if (dinamicaAbordagem === '') {
                //   toast.error('Preencha o Campo Dinâmica');
                //   return;
                // }

                // enviarAbordagem();
                setModalConcluir(true);
              }}
              color="dark-green"
              disabled={loadingAbordagem}
            >
              {loadingAbordagem ? 'Carregando' : 'Concluir'}
            </MDBBtn>
          </MDBRow>
        </MDBContainer>

        {/* MODAL PARA CRIACAO DE VEICULOS  antigo antigo antigo*/}
        {/* <MDBModal
        isOpen={modalVeiculo}
        toggle={() => {
          setModalVeiculo(!modalVeiculo);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalVeiculo(!modalVeiculo);
          }}
        >
          Inserir Veículo Abordado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <MDBSwitch
              checked={switchChassi}
              labelLeft="Placa"
              labelRight="Chassi"
              onChange={() => {
                setSwitchChassi(!switchChassi);
                setAbordagemNumeroPlacaOuChassi('');
              }}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" middle>
              <MDBInput
                maxlength={switchChassi === false ? '7' : '17'}
                type="text"
                placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                onChange={e => setAbordagemNumeroPlacaOuChassi(e.target.value)}
                value={abordagemNumeroPlacaOuChassi}
              />
            </MDBCol>

            <MDBCol size="12" middle>
              <div>Tipo de Veículo:</div>
              <Select
                placeholder="Tipo"
                options={selectVeiculos}
                onChange={e => {
                  setAbordagemTipo(e.value);
                  setAbordagemTipoLabel(e.label);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalVeiculo(!modalVeiculo);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaVeiculo();
            }}
            color="success"
          >
            Cadastrar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA CRIACAO DE VEICULOS */}
        <MDBModal
          isOpen={modalVeiculo}
          toggle={() => {
            setModalVeiculo(!modalVeiculo);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalVeiculo(!modalVeiculo);
            }}
          >
            Inserir Veículo Abordado
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBSwitch
                checked={switchChassi}
                labelLeft="Placa"
                labelRight="Chassi"
                onChange={() => {
                  setSwitchChassi(!switchChassi);
                  setAbordagemNumeroPlacaOuChassi('');
                }}
              />
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" middle>
                <MDBInput
                  maxLength={
                    veiculoEstrangeiro === true
                      ? '10'
                      : switchChassi === false
                      ? '7'
                      : '17'
                  }
                  type="text"
                  placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                  onChange={e => {
                    setAbordagemNumeroPlacaOuChassi(
                      e.target.value.toUpperCase()
                    );
                  }}
                  value={abordagemNumeroPlacaOuChassi}
                />
              </MDBCol>
              <MDBCol
                size="12"
                middle
                className="d-flex justify-content-center"
              >
                <MDBInput
                  containerClass="form-check checkbox-teal"
                  labelClass="form-check-label"
                  label="Veículo Estrangeiro"
                  type="checkbox"
                  id="veiculo"
                  checked={veiculoEstrangeiro}
                  onClick={e => {
                    setVeiculoEstrangeiro(!veiculoEstrangeiro);
                  }}
                />
              </MDBCol>

              <MDBCol size="12" middle>
                <div>Tipo de Veículo:</div>
                <Select
                  placeholder="Tipo"
                  options={selectVeiculos}
                  onChange={e => {
                    setAbordagemTipo(e.value);
                    setAbordagemTipoLabel(e.label);
                  }}
                  value={selectVeiculos.find(
                    option => option.value === abordagemTipo
                  )}
                />
              </MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalVeiculo(!modalVeiculo);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                adicionaVeiculo();
              }}
              disabled={loadingEnviar}
              color="success"
            >
              {loadingEnviar ? 'Carregando...' : 'Cadastrar'}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA DELETAR VEICULOS */}
        <MDBModal
          isOpen={modalDeleteVeiculo}
          toggle={() => {
            setModalDeleteVeiculos({
              modalDeleteVeiculo: !modalDeleteVeiculo,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalDeleteVeiculos(!modalDeleteVeiculo);
            }}
          >
            Deletar Veículo Abordado
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            Tem certeza que deseja deletar esse veículo?
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalDeleteVeiculos(!modalDeleteVeiculo);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                deletarVeiculo(idDelecaoVeiculo);
                setModalDeleteVeiculos(!modalDeleteVeiculo);
              }}
              color="warning"
            >
              Deletar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA CRIAÇÃO DE ABORDADO */}
        <MDBModal
          size="lg"
          isOpen={modalAbordado}
          toggle={() => {
            setModalAbordado(!modalAbordado);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalAbordado(!modalAbordado);
            }}
          >
            Inserir Pessoa Abordada
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              {/* {cpfValido === false && (
                <MDBCol className="bg-danger py-3 font-weight-bold text-white">
                  Você irá inserir um CPF inválido
                </MDBCol>
              )}
              {cpfValido !== false && ''
              // <MDBCol className="bg-success font-weight-bold text-white">
              //   CPF válido
              // </MDBCol>
              } */}
            </MDBRow>
            <MDBRow>
              <MDBInput
                containerClass="form-check checkbox-teal"
                labelClass="form-check-label"
                label="Abordado sem CPF ou Estrangeiro"
                type="checkbox"
                id="checkcpf"
                checked={abordadoEstrangeiro}
                onClick={e => {
                  if (abordadoEstrangeiro === false) {
                    setAbordadoCpf('');
                  }
                  setAbordadoEstrangeiro(!abordadoEstrangeiro);
                }}
              />
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" md="4" middle>
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                    marginTop: '35px',
                  }}
                  className={`w-100 font-weight-light md-form form-group form-lg ${abordadoEstrangeiro ===
                    true && 'red lighten-5'}`}
                  disabled={abordadoEstrangeiro}
                  type="text"
                  placeholder="CPF"
                  onChange={e =>
                    setAbordadoCpf(e.target.value.replace(/\D+/g, ''))
                  }
                  // onBlur={e => {
                  //   if (e.target.value !== '') {
                  //     if (!validarCPF(abordadoCpf)) {
                  //       setCpfValido(false);
                  //       return;
                  //     }
                  //     setCpfValido(true);
                  //   }
                  // }}
                  value={abordadoCpf}
                  mask="999.999.999-99"
                  maskChar=" "
                />
              </MDBCol>
              <MDBCol size="12" md="4" middle>
                <MDBInput
                  type="text"
                  label="Nome"
                  counter={150}
                  onChange={e => setAbordadoNome(e.target.value)}
                  value={abordadoNome}
                />
              </MDBCol>

              <MDBCol size="12" md="4" middle>
                <Select
                  placeholder="Função"
                  options={[
                    { label: 'Condutor', value: 'Condutor' },
                    { label: 'Chapa', value: 'Chapa' },
                    { label: 'Carona', value: 'Carona' },
                    { label: 'Parentes', value: 'Parentes' },
                    { label: 'Cônjuges', value: 'Cônjuges' },
                    { label: 'Passageiro', value: 'Passageiro' },
                  ]}
                  value={[
                    { label: 'Condutor', value: 'Condutor' },
                    { label: 'Chapa', value: 'Chapa' },
                    { label: 'Carona', value: 'Carona' },
                    { label: 'Parentes', value: 'Parentes' },
                    { label: 'Cônjuges', value: 'Cônjuges' },
                    { label: 'Passageiro', value: 'Passageiro' },
                  ].find(option => option.label === abordadoTipo)}
                  onChange={e => {
                    setAbordadoTipo(e.label);
                  }}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow center>
              <div className="text-center">
                <span className="h4-responsive">Condutor do veículo</span>

                <MDBSwitch
                  checked={switchCondutor}
                  labelLeft="Não"
                  labelRight="Sim"
                  onChange={() => {
                    setSwitchCondutor(!switchCondutor);
                  }}
                />
              </div>
            </MDBRow>
            {switchCondutor === true ? (
              <MDBAnimation type="zoomIn" duration="500ms">
                <MDBRow>
                  <MDBCol size="12" md="4" middle>
                    <MDBInput
                      type="text"
                      label="CNH"
                      onChange={e => setAbordadoCnh(e.target.value)}
                      value={abordadoCnh}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4" middle>
                    <Select
                      placeholder="Categoria"
                      options={[
                        { label: 'A', value: 'A' },
                        { label: 'B', value: 'B' },
                        { label: 'C', value: 'C' },
                        { label: 'D', value: 'D' },
                        { label: 'E', value: 'E' },
                        { label: 'AB', value: 'AB' },
                        { label: 'AC', value: 'AC' },
                        { label: 'AD', value: 'AD' },
                        { label: 'AE', value: 'AE' },
                      ]}
                      value={[
                        { label: 'A', value: 'A' },
                        { label: 'B', value: 'B' },
                        { label: 'C', value: 'C' },
                        { label: 'D', value: 'D' },
                        { label: 'E', value: 'E' },
                        { label: 'AB', value: 'AB' },
                        { label: 'AC', value: 'AC' },
                        { label: 'AD', value: 'AD' },
                        { label: 'AE', value: 'AE' },
                      ].find(option => option.value === abordadoCategoria)}
                      onChange={e => {
                        setAbordadoCategoria(e.value);
                      }}
                    />
                  </MDBCol>

                  <MDBCol size="12" md="4" middle>
                    <MDBInput
                      type="date"
                      label="Validade"
                      onChange={e => setAbordadoValidade(e.target.value)}
                      value={abordadoValidade}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            ) : (
              ''
            )}
          </MDBModalBody>
          <MDBModalFooter between>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModalAbordado(!modalAbordado);
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                adicionaAbordado();
              }}
              className="btn btn-success"
            >
              Cadastrar
            </button>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA DELETAR VEICULOS */}
        <MDBModal
          isOpen={modalDeleteAbordado}
          toggle={() => {
            setModalDeleteAbordado({
              modalDeleteAbordado: !modalDeleteAbordado,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalDeleteAbordado(!modalDeleteAbordado);
            }}
          >
            Deletar Pessoa Abordada
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            Tem certeza que deseja deletar esse abordado?
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalDeleteAbordado(!modalDeleteAbordado);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                deletarAbordado(idDelecaoVeiculo);
                setModalDeleteAbordado(!modalDeleteAbordado);
              }}
              color="warning"
            >
              Deletar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA CRIAÇÃO DE EMPRESAS */}
        <MDBModal
          size="lg"
          isOpen={modalEmpresa}
          toggle={() => {
            setModalEmpresa(!modalEmpresa);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalEmpresa(!modalEmpresa);
            }}
          >
            Inserir Empresa Abordada
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <QRCodeScanner onQRCodeScan={handleQRCodeValue} />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol size="12" md="4" middle>
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                    marginTop: '35px',
                  }}
                  className="w-100 font-weight-light md-form form-group form-lg"
                  type="text"
                  placeholder="CNPJ"
                  onChange={e => setCnpj(e.target.value.replace(/\D+/g, ''))}
                  value={cnpj}
                  mask="99.999.999/9999-99"
                  maskChar=" "
                />
              </MDBCol>
              <MDBCol size="12" md="8" middle>
                <MDBInput
                  type="text"
                  label="Nome"
                  onChange={e => setEmpresaNome(e.target.value)}
                  value={empresaNome}
                  counter={150}
                />
              </MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter between>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModalEmpresa(!modalEmpresa);
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                adicionaEmpresa();
              }}
              className="btn btn-success"
            >
              Cadastrar
            </button>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA DELETAR EMPRESAS */}
        <MDBModal
          isOpen={modalDeleteEmpresa}
          toggle={() => {
            setModalDeleteEmpresa({
              modalDeleteEmpresa: !modalDeleteEmpresa,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalDeleteEmpresa(!modalDeleteEmpresa);
            }}
          >
            Deletar Empresa Abordada
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            Tem certeza que deseja deletar essa empresa?
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalDeleteEmpresa(!modalDeleteEmpresa);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                deletarEmpresa(idDelecaoEmpresa);
                setModalDeleteEmpresa(!modalDeleteEmpresa);
              }}
              color="warning"
            >
              Deletar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA CRIAÇÃO DE NOTAS */}
        <MDBModal
          size="lg"
          isOpen={modalNota}
          toggle={() => {
            setModalNota(!modalNota);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalNota(!modalNota);
            }}
          >
            Inserir Nota Fiscal
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <div className="text-center">
                <span className="h4-responsive">Condutor do veículo</span>

                <MDBSwitch
                  checked={switchTipoNota}
                  labelLeft="Nota Fiscal"
                  labelRight="Manifesto"
                  onChange={() => {
                    setSwitchTipoNota(!switchTipoNota);
                  }}
                />
              </div>
            </MDBRow>
            {switchTipoNota === false ? (
              <MDBAnimation>
                <MDBRow>
                  <MDBCol size="12" middle>
                    <MDBInput
                      type="text"
                      label="Danfe"
                      counter={20}
                      onChange={e => setNumero(e.target.value)}
                      value={numero}
                    />
                  </MDBCol>
                  <MDBCol size="12" middle>
                    <MDBInput
                      type="text"
                      label="Chave"
                      counter={255}
                      onChange={e => setChave(e.target.value)}
                      value={chave}
                    />
                    {/*
                    <BarcodeScanner
                    // onDataUpdate={() => pegaDadosFilhoBarCode()}
                    // ref={barcode}
                    // data={result}
                    /> */}
                  </MDBCol>
                  <MDBCol size="12" middle>
                    <MDBInput
                      label="Valor"
                      onChange={e => setValor(MaskReal(e.target.value))}
                      value={valor}
                    />
                  </MDBCol>
                  <MDBCol size="12" middle>
                    <MDBInput
                      label="ICMS"
                      onChange={e => setIcms(MaskReal(e.target.value))}
                      value={icms}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            ) : (
              <MDBAnimation>
                <MDBRow>
                  <MDBCol size="12" middle>
                    <MDBInput
                      type="text"
                      label="Número do Manifesto"
                      onChange={e => setNumero(e.target.value)}
                      value={numero}
                      counter={20}
                    />

                    {/* <MDBBtn onClick={() => pegaDadosFilhoBarCode()}>
                      teste
                    </MDBBtn> */}
                  </MDBCol>
                  <MDBCol size="12" middle>
                    <MDBInput
                      type="text"
                      label="Chave"
                      onChange={e => setChave(e.target.value)}
                      value={chave}
                      counter={255}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBAnimation>
            )}

            <br />
          </MDBModalBody>
          <MDBModalFooter between>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModalNota(!modalNota);
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                adicionaNota();
              }}
              className="btn btn-success"
            >
              Cadastrar
            </button>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA DELETAR NOTAS */}
        <MDBModal
          isOpen={modalDeleteNota}
          toggle={() => {
            setModalDeleteNota({
              modalDeleteNota: !modalDeleteNota,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalDeleteNota(!modalDeleteNota);
            }}
          >
            Deletar Nota Fiscal
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            Tem certeza que deseja deletar essa nota fiscal?
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalDeleteNota(!modalDeleteNota);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                deletarNota(idEmpresaParaNota, idDelecaoNota);
                setModalDeleteNota(!modalDeleteNota);
              }}
              color="warning"
            >
              Deletar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA CRIAR LATLONG */}
        <MDBModal
          isOpen={modalLatLong}
          toggle={() => {
            setModalLatLong({
              modalLatLong: !modalLatLong,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalLatLong(!modalLatLong);
            }}
          >
            Insira no mapa
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            <MDBRow center>
              <MDBCol>
                <ExternalStateExample />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter between>
            <MDBBtn
              color="danger"
              onClick={() => {
                setModalLatLong(!modalLatLong);
              }}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                setCoordenadas({
                  newGeo: true,
                });
                setModalLatLong(!modalLatLong);
              }}
              color="success"
            >
              Salvar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        {/* MODAL PARA CONCLUIR CADASTRO */}
        <MDBModal
          isOpen={modalConcluir}
          toggle={() => {
            setModalConcluir({
              modalConcluir: !modalConcluir,
            });
          }}
        >
          <MDBModalHeader
            toggle={() => {
              setModalConcluir(!modalConcluir);
            }}
          >
            Concluir Cadastro
          </MDBModalHeader>
          <MDBModalBody className="text-danger font-weight-bolder">
            Deseja Realmente fechar esta abordagem?
          </MDBModalBody>
          <MDBModalFooter between>
            {/* <MDBBtn
            color="black"
            onClick={() => {
              criaAbordagem();
              setModalConcluir(!modalConcluir);
            }}
          >
            Criar Ocorrência
          </MDBBtn> */}
            <MDBBtn
              onClick={() => {
                // deletarNota(idEmpresaParaNota, idDelecaoNota);
                // setModalDeleteNota(!modalDeleteNota);

                finalizaAbordagem();
                setModalConcluir(!modalConcluir);
              }}
              disabled={loadingAbordagem}
              color="success"
            >
              {loadingAbordagem ? 'Carregando' : 'Finalizar'}
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                // deletarNota(idEmpresaParaNota, idDelecaoNota);
                // setModalDeleteNota(!modalDeleteNota);

                setModalConcluir(!modalConcluir);
              }}
              color="danger"
            >
              Cancelar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </Context.Provider>
    </NavBar>
  );
}
