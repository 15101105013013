import React, { useEffect, useState, useCallback } from 'react';
import {
  MDBCard,
  MDBView,
  MDBCardBody,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBCollapse,
  MDBInput,
} from 'mdbreact';
import { format } from 'date-fns';
import api from '../../../../services/api';
import { toast } from 'react-toastify';

const Revisoes = props => {
  const [revisoes, setRevisoes] = useState([]);
  const [collapseId, setCollapseId] = useState(null);
  const [resposta, setResposta] = useState('');
  const [loadingResposta, setLoadingResposta] = useState(false);

  const buscaRevisoes = useCallback(async () => {
    try {
      const result = await api.get('/sgo-revisao', {
        params: { abordagem_id: props.abordagemId },
      });
      // Adicione uma verificação para garantir que os dados dos revisores estejam presentes
      const revisoesCompletas =
        result.data.length > 0 &&
        result.data.map(rev => ({
          ...rev,
          revisor_inicial: rev.revisor_inicial || {
            nome_social: 'Não disponível',
          },
          sgo_revisao_motivo: rev.sgo_revisao_motivo || {
            motivo: 'Não disponível',
          },
        }));
      setRevisoes(revisoesCompletas);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao buscar revisões');
    }
  }, [props.abordagemId]);

  useEffect(() => {
    buscaRevisoes();
  }, [buscaRevisoes]);

  const enviarResposta = useCallback(
    async (id, resposta) => {
      if (resposta === null || resposta === '') {
        toast.error('Preencha os campos');
        return;
      }
      setLoadingResposta(true);
      try {
        await api.put(`/sgo-revisao/${id}`, {
          data_resposta: new Date(),
          comentario_final: resposta,
        });
        setLoadingResposta(false);
        setCollapseId(null);
        buscaRevisoes();
      } catch (error) {
        setLoadingResposta(false);
        toast.error('Erro ao enviar resposta');
      }
    },
    [buscaRevisoes]
  );

  return (
    <>
      {revisoes.length > 0 &&
        revisoes.map((rev, index) => (
          <MDBCard key={index} narrow>
            <MDBView
              className={`${
                rev.data_final !== null
                  ? 'teal darken-2'
                  : rev.comentario_final !== null
                  ? 'blue darken-3'
                  : 'deep-orange'
              } lighten-1 mb-3 text-center text-md-center pr-0 pr-md-5 py-2`}
              cascade
            >
              <h4 className="text-white font-weight-bolder">
                Revisão {index + 1}
              </h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <MDBRow between>
                <MDBCol className="text-left">
                  Inicio:
                  {rev.data_inicio &&
                    format(new Date(rev?.data_inicio), ' dd/MM/yyyy HH:mm')}
                </MDBCol>
                <MDBCol className="text-center">
                  Revisor Inicial:
                  {rev.revisor_inicial?.nome_social}
                </MDBCol>
                <MDBCol className="text-right">
                  Motivo: {rev.sgo_revisao_motivo?.motivo}
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow start className="grey lighten-4 pt-3">
                <MDBCol>Descrição:</MDBCol>
              </MDBRow>
              <MDBRow center className="grey lighten-4 pb-3">
                <MDBCol>{rev?.comentario_inicial}</MDBCol>
              </MDBRow>

              {rev?.comentario_final !== null ? (
                <>
                  <MDBRow className="mt-3 grey lighten-4 pt-3">
                    <MDBCol>
                      Resposta:{' '}
                      {rev?.data_resposta &&
                        format(
                          new Date(rev?.data_resposta),
                          'dd/MM/yyyy HH:mm'
                        )}
                    </MDBCol>
                  </MDBRow>

                  <MDBRow center className=" grey lighten-4 pb-3">
                    <MDBCol>{rev?.comentario_final}</MDBCol>
                  </MDBRow>
                </>
              ) : (
                ''
              )}

              <MDBCollapse id={rev?.id} isOpen={collapseId === rev.id}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      type="textarea"
                      className="rounded"
                      onChange={e => {
                        const updatedValue = e.target.value
                          .replace(/;/g, '.')
                          .replace(/"/g, "'");
                        setResposta(updatedValue);
                      }}
                      value={resposta}
                      background
                      rows="3"
                      outline
                    />
                  </MDBCol>
                </MDBRow>

                <MDBRow center>
                  {collapseId !== rev.id ? (
                    ''
                  ) : (
                    <MDBBtn
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setResposta('');
                        setCollapseId(null);
                      }}
                    >
                      <span className="ml-2 font-weight-bolder">Cancelar</span>
                    </MDBBtn>
                  )}
                  <MDBBtn
                    onClick={() => enviarResposta(rev.id, resposta)}
                    size="sm"
                    color="green"
                    disabled={loadingResposta}
                  >
                    {loadingResposta === false ? 'Enviar' : 'Enviando'}
                  </MDBBtn>
                </MDBRow>
              </MDBCollapse>
              <MDBRow end>
                {collapseId === rev.id ||
                rev.comentario_final !== null ||
                rev.data_final !== null ? (
                  ''
                ) : (
                  <MDBBtn
                    size="sm"
                    onClick={() => {
                      if (collapseId === rev.id) {
                        setResposta('');
                        setCollapseId(null);
                        return;
                      }
                      setResposta('');
                      setCollapseId(rev.id);
                    }}
                  >
                    <span className="ml-2 font-weight-bolder">Responder</span>
                  </MDBBtn>
                )}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        ))}
    </>
  );
};

export default Revisoes;
