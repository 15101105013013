import React, { useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import InputMask from 'react-input-mask';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBCardHeader,
} from 'mdbreact';
import { isPast, getYear } from 'date-fns';

import api from '../../../../services/api';
import { getData } from '../../../../services/auth';
import format from 'date-fns/format';
import Paginacao from '../../../../components/Paginacao';

export function Status() {
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState([]);

  // manutencao
  const [manutencaoSelected, setManutencaoSelected] = useState(null);
  // subtipos de manutencao
  const [listaManutencao, setListaManutencao] = useState([]);
  // subtipos de manutencao selecionado
  const [manutencaoSubSelecionado, setManutencaoSubSelecionado] = useState(
    null
  );

  const [listaStatus, setListaStatus] = useState([]);
  const [statusSelected, setStatusSelected] = useState(null);

  const [listaVtrs, setListaVtrs] = useState([]);
  const [vtrSelect, setVtrSelect] = useState(null);

  const [resultaDados, setResultaDados] = useState([]);
  const [dadosManutencao, setDadosManutencao] = useState([]);
  const [dadosStatus, setDadosStatus] = useState([]);
  // select de gerecias

  const [page, setPage] = useState(1);
  const [qtdTotal, setQtdTotal] = useState(0);
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
      setGerenciaSelecionada(select[0]);
      carregaVtr(select[0]);
    });
  };

  const carregaVtr = gerencia => {
    setGerenciaSelecionada(gerencia);
    setVtrSelect(null);
    api
      .get('/frota', {
        params: { gerencia_id: gerencia.value, ativo: true },
      })
      .then(t => {
        const veiculosSelect = [];
        t.data.forEach(element => {
          veiculosSelect.push({ label: element.placa, value: element.id });
        });
        setListaVtrs(veiculosSelect);
      });
  };

  // busca subtipos
  const buscaStatus = () => {
    api.get('/tipos-status-veiculos').then(t => {
      const select = [];
      t.data.forEach(element => {
        select.push({ label: element.status_veiculo, value: element.id });
      });
      setListaStatus(select);
    });
  };

  const buscaDadosStatus = async numPage => {
    const where = {};
    if (numPage !== undefined) {
      where.page = numPage;
      setPage(numPage);
    }

    if (gerenciaSelecionada !== null) {
      where.fk_gerencia_id = gerenciaSelecionada.value;
    }

    if (statusSelected !== null) {
      where.fk_tipos_status_veiculos_id = statusSelected.value;
    }

    if (vtrSelect !== null) {
      where.fk_veiculos_id = vtrSelect.value;
    }

    const result = await api.get('/status-veiculos', {
      params: where,
    });

    // setResultaDados(result.data);
    setDadosStatus(result.data.rows);

    setQtdTotal(result.data.count);

    // setDadosStatus(result.data);
  };
  const handlePageChange = newPage => {
    setPage(newPage);
  };

  useEffect(() => {
    buscaDadosStatus(page);
  }, [page]);

  useEffect(() => {
    carregaGerencias();
    buscaStatus();
  }, []);

  return (
    <>
      <MDBCard>
        <MDBCardHeader className="bg-white">Filtros de Status</MDBCardHeader>
        <MDBCardBody>
          <MDBRow>
            {/* <MDBCol col="12" md="4">
      <Select
        className="mt-4"
        outline
        required
        placeholder="Selecione o tipo"
        options={listTipos}
        onChange={e => {
          buscaTipoSelecionado(e);
        }}
        value={tipoSelected}
      />

      {tipoSelected?.value === 1 && (
        <Select
          className="mt-5"
          outline
          isClearable
          placeholder="Tipo de Manutencao"
          options={listaManutencao}
          onChange={e => setManutencaoSubSelecionado(e)}
          value={manutencaoSubSelecionado}
        />
      )}
      {tipoSelected?.value === 2 && (
        <Select
          className="mt-5"
          outline
          isClearable
          placeholder="Tipo de Status"
          options={listaStatus}
          value={statusSelected}
          onChange={e => setStatusSelected(e)}
        />
      )}
    </MDBCol> */}
            <MDBCol col="12" md="4" bottom className=" mt-md-0">
              <Select
                className="mt-4"
                outline
                required
                placeholder="Selecione Posto"
                options={listaGerencias}
                value={gerenciaSelecionada}
                onChange={e => carregaVtr(e)}
              />
            </MDBCol>
            <MDBCol col="12" md="4" middle className=" mt-md-0">
              <Select
                className="mt-5"
                outline
                isClearable
                placeholder="Tipo de Status"
                options={listaStatus}
                onChange={e => setStatusSelected(e)}
                value={statusSelected}
              />
            </MDBCol>
            <MDBCol col="12" md="4" middle className=" mt-md-0">
              <Select
                className="mt-4 mt-md-5"
                outline
                placeholder="Selecione VTR"
                isClearable
                options={listaVtrs}
                onChange={e => setVtrSelect(e)}
                value={vtrSelect}
              />
            </MDBCol>
          </MDBRow>
          {/* <MDBRow>
            <MDBCol col="12" md="4" bottom>
              <MDBInput
                className="mt-5 mt-md-4"
                label="Data Início"
                outline
                type="date"
                // onChange={e =>
                // (atividadeInicioRef.current = e.target.value)
                // }
                // onChange={e => this.setState({ km: e.target.value })}
                // value={this.state.km}
              />
            </MDBCol>
            <MDBCol col="12" md="4" bottom>
              <MDBInput
                className="mt-5"
                label="Data Fim"
                outline
                type="date"
                // onChange={e =>
                // (atividadeInicioRef.current = e.target.value)
                // }
                // onChange={e => this.setState({ km: e.target.value })}
                // value={this.state.km}
              />
            </MDBCol>
          </MDBRow> */}
          <br />
          <MDBRow center>
            <MDBBtn
              onClick={() => {
                // buscarManutencao(tipoSelected);
                // buscaDadosManutencoes();
                buscaDadosStatus(1);
              }}
              size="sm"
              color="green"
            >
              Buscar
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <MDBCard className="mt-3">
        <MDBCardHeader className="grey lighten-3">Manutenções</MDBCardHeader>
        <MDBCardBody>
          {dadosStatus.map(status => (
            <div
              key={status.id}
              className="border rounded p-2 mb-3 grey lighten-4"
            >
              <MDBRow>
                <MDBCol size="4">
                  <div> Status:</div>{' '}
                  <div> {status.tipos_status_veiculo.status_veiculo}</div>
                </MDBCol>
                <MDBCol size="4">
                  <div> Placa:</div>
                  <div> {status.veiculo.placa}</div>
                </MDBCol>

                <MDBCol size="4">
                  <div> Criado em:</div>
                  <div>
                    {format(new Date(status?.createdAt), 'dd/MM/yyyy HH:mm')}
                  </div>
                </MDBCol>
              </MDBRow>

              <MDBRow className="mt-2">
                <MDBCol size="12">
                  <div> Descricao:</div>
                  <div> {status.descricao}</div>
                </MDBCol>
              </MDBRow>
            </div>
          ))}
          <Paginacao
            page={page}
            qtdTotal={qtdTotal}
            onPageChange={handlePageChange}
          />
        </MDBCardBody>
      </MDBCard>
    </>
  );
}
