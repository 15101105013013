import React, { useEffect, useState } from 'react';
import { parseISO, toDate } from 'date-fns';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBView,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBIcon,
  MDBMedia,
  MDBFileInput,
  MDBSpinner,
  toast,
} from 'mdbreact';
import EXIF from 'exif-js';
import Compressor from 'compressorjs';
import api from '../../services/api';

function ImageMeta(props) {
  const [stateExifImage, setExifImage] = useState(null);
  const [exifData, setExifData] = useState({});
  const [compressedFileView, setCompressedFileView] = useState(null);
  const [compressedFileBase64, setCompressedFileBase64] = useState(null);

  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingBusca, setLoadingBusca] = useState(false);
  const [loadingHandle, setLoadingHandle] = useState(false);

  const [imagens, setImagens] = useState([]);
  const [imageSelected, setImageSelected] = useState(null);
  const [modalFoto, setModalFoto] = useState({ id: null, base: null });
  const [zoom, setZoom] = useState(1);
  // { id: null, base: null }
  // componentizar
  const buscaFotos = async () => {
    setLoadingBusca(true);
    // const result = await api.get('/sgo-imagem', {
    const result = await api.get(props.getFotos.buscaFotosURL, {
      params: {
        abordagem_id: props.abordagem_id,
        ...props.getFotos.buscaFotosWhere,
      },
    });

    setImagens(result.data);
    setLoadingBusca(false);
  };

  const handleZoomIn = () => {
    setZoom(zoom * 1.2); // Aumenta o zoom em 20%
  };

  const handleZoomOut = () => {
    setZoom(zoom / 1.2); // Diminui o zoom em 20%
  };

  const handleZoomReset = () => {
    setZoom(1); // Reseta o zoom para 100%
  };

  useEffect(() => {
    buscaFotos();
  }, []);

  useEffect(() => {
    handleChange(imageSelected);
  }, [imageSelected]);

  // componentizar
  const enviaFoto = async () => {
    setLoadingSend(true);

    // const result = await api.post('/sgo-insere-imagem', {
    try {
      const result = await api.post(props.postFotos.enviarFotoURL, {
        file: compressedFileBase64,
        data: exifData,
      });
      console.log(result);
    } catch (error) {
      console.log(error);
      return;
    }

    buscaFotos();
    setExifImage(null);
    setExifData({});
    setCompressedFileView(null);
    setCompressedFileBase64(null);
    setLoadingSend(false);
  };

  const deletaFoto = async foto => {
    // setLoadingSend(true);

    // const result = await api.post('/sgo-insere-imagem', {

    const result = await api.delete(
      `${props.deleteFotos.deletarFotoURL}/${foto.id}`
    );
    if (result.data.ok) {
      toast.success(result.data.ok);
    }
    if (result.data.erro) {
      toast.error(result.data.erro);
    }

    setModalFoto({ id: null, base: null });
    buscaFotos();
    // buscaFotos();
    // setExifImage(null);
    // setExifData({});
    // setCompressedFileView(null);
    // setCompressedFileBase64(null);
    // setLoadingSend(false);
  };

  function converteCoord(grau, min, sec, dir) {
    // var parts = input.split(/[^\d\w\.]+/);

    let coord = Number(grau) + Number(min) / 60 + Number(sec) / (60 * 60);
    if (dir == 'S' || dir == 'O' || dir == 'W') {
      coord = coord * -1;
    }

    return coord;
  }

  function handleChange(files_list) {
    if (files_list) {
      if (
        files_list[0].type !== 'image/jpeg' &&
        files_list[0].type !== 'image/png'
      ) {
        setExifImage(null);
        setExifData({});
        setCompressedFileView(null);
        setCompressedFileBase64(null);
        toast.error('Somente PNG, JPG e JPEG');

        return;
      }
    }

    setLoadingHandle(true);
    if (files_list && files_list.length > 0) {
      for (let file of files_list) {
        if (file) {
          EXIF.getData(file, function() {
            let exifDataPretty = EXIF.pretty(this);

            if (exifDataPretty) {
              const dados = props.postFotos.enviarRefId;

              let exifAllTags = EXIF.getAllTags(this);

              if (exifAllTags.DateTimeOriginal) {
                let trata = exifAllTags.DateTimeOriginal.split(' ');
                dados.exifdata = `${trata[0].split(':').join('-')} ${trata[1]}`;
              } else if (exifAllTags.DateTime) {
                let trata = exifAllTags.DateTime.split(' ');
                dados.exifdata = `${trata[0].split(':').join('-')} ${trata[1]}`;
              } else {
                dados.exifdata = null;
              }

              if (
                Object.keys(exifAllTags).length > 0 &&
                Object.keys(exifAllTags).indexOf('GPSLatitude') !== -1
              ) {
                let lat = converteCoord(
                  exifAllTags['GPSLatitude'][0],
                  exifAllTags['GPSLatitude'][1],
                  exifAllTags['GPSLatitude'][2],
                  exifAllTags['GPSLatitudeRef']
                );
                // const lat = `${exifAllTags['GPSLatitude'][0]}°${exifAllTags['GPSLatitude'][1]}'${exifAllTags['GPSLatitude'][2]}"${exifAllTags['GPSLatitudeRef']}`;
                dados.exiflatitude = lat;
              }

              if (
                Object.keys(exifAllTags).length > 0 &&
                Object.keys(exifAllTags).indexOf('GPSLongitude') !== -1
              ) {
                // const long = `${exifAllTags['GPSLongitude'][0]}º${exifAllTags['GPSLongitude'][1]}'${exifAllTags['GPSLongitude'][2]}"${exifAllTags['GPSLongitudeRef']}`;
                // dados.exiflongitude = long;
                let long = converteCoord(
                  exifAllTags['GPSLongitude'][0],
                  exifAllTags['GPSLongitude'][1],
                  exifAllTags['GPSLongitude'][2],
                  exifAllTags['GPSLongitudeRef']
                );
                dados.exiflongitude = long;
              }

              setExifData(dados);
              // setExifImage(file.name);
            } else {
              // const dados = { abordagem_id: props.abordagem_id };
              const dados = props.postFotos.enviarRefId;
              dados.exifdata = null;
              // console.log('nao tem dados');
              // console.log('akiiiii');
              setExifImage(null);
              setExifData(dados);
            }
          });
          let quality = 1;
          if (file.size > 200000) {
            quality = 0.6;
          }
          new Compressor(file, {
            // quality: Math.round((1000000 / file.size) * 100) / 100,
            quality: quality,
            maxHeight: 1024,
            maxWidth: 1024,
            success: compressedResult => {
              setCompressedFileView(compressedResult);

              const reader = new FileReader();

              reader.readAsDataURL(compressedResult);
              reader.onload = () => {
                setCompressedFileBase64(reader.result);
                setLoadingHandle(false);
              };
            },
          });
        }
      }
    }
  }

  return (
    <MDBContainer>
      {loadingBusca && (
        <MDBRow center className="align-items-center my-3">
          <MDBSpinner />
          <div className="ml-3 font-weight-bold">Carregando...</div>
        </MDBRow>
      )}
      <MDBRow>
        {imagens.ok ? (
          <MDBCol center className="py-3 font-weight-bold danger-text">
            {imagens.ok}
          </MDBCol>
        ) : (
          imagens.map(res => (
            <MDBCol key={res.id} size="6" md="3" className="my-2">
              <img
                // key={res.id}
                // id={res.id}
                width="100"
                height="100"
                style={{ objectFit: 'cover' }}
                className="cursor-pointer"
                src={`${Buffer.from(res.imagem, 'base64')}`}
                onClick={e => {
                  setModalFoto({ id: res.id, base: res.imagem });
                  // src={imagens}
                }}
              />
            </MDBCol>
          ))
        )}
      </MDBRow>

      {/* {imagens > 0 && console.log('console.log', URL.createObjectURL(imagens))} */}
      {/* {imagens > 0 && <img src={URL.createObjectURL(imagens)} />} */}

      {/* ))} */}
      {imagens.length < 5 || imagens.ok ? (
        <MDBFileInput
          type="file"
          id="file"
          btnTitle="Selecione"
          textFieldTitle="Buscar imagem"
          accept=".jpg, .png"
          value={imageSelected}
          getValue={e => {
            setImageSelected(e);
          }}
        />
      ) : (
        <MDBRow center className="font-weight-bold danger-text">
          Limite: {props.maxImages} fotos
        </MDBRow>
      )}

      <hr />

      {!!compressedFileView && (
        <MDBRow className="align-items-center justify-content-center">
          <MDBCol size="12" md="6">
            {loadingHandle ? (
              <div className="d-flex align-items-center justify-content-center">
                <MDBSpinner />
                <div className="ml-3 font-weight-bolder">Carregando...</div>
              </div>
            ) : (
              <img
                className="img-thumbnail cursor-pointer"
                src={URL.createObjectURL(compressedFileView)}
                // src={imagens}
              />
            )}
          </MDBCol>
          <MDBCol size="12" md="6" className="text-center">
            {loadingSend ? (
              <div className="d-flex align-items-center justify-content-center">
                <MDBSpinner />
                <div className="ml-3 font-weight-bolder">Carregando...</div>
              </div>
            ) : (
              <MDBBtn
                disabled={loadingSend}
                color="dark-green"
                size="sm"
                onClick={() => enviaFoto()}
              >
                Enviar
              </MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
      )}

      <MDBModal
        isOpen={modalFoto.id !== null}
        size="lg"
        toggle={() => {
          setModalFoto({ id: null, base: null });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalFoto({ id: null, base: null });
          }}
        >
          Deletar Imagem
        </MDBModalHeader>
        <MDBModalBody>
          {modalFoto.id !== null && (
            <>
              <div style={{ overflow: 'auto' }}>
                <img
                  className="w-100"
                  src={`${Buffer.from(modalFoto.base, 'base64')}`}
                  style={{
                    transform: `scale(${zoom})`,
                    transition: 'transform 0.3s ease',
                  }}
                />
              </div>
              <MDBRow center style={{ marginTop: '20px' }}>
                <MDBIcon
                  icon="search-plus"
                  size="2x"
                  className="mr-5"
                  style={{ cursor: 'pointer' }}
                  onClick={handleZoomIn}
                />
                <MDBIcon
                  icon="search-minus"
                  size="2x"
                  className="mr-5"
                  style={{ cursor: 'pointer' }}
                  onClick={handleZoomOut}
                />

                <MDBIcon
                  fas
                  icon="compress"
                  size="2x"
                  className="mr-5"
                  style={{ cursor: 'pointer' }}
                  onClick={handleZoomReset}
                />
              </MDBRow>
            </>
          )}
        </MDBModalBody>

        <MDBModalFooter between>
          <MDBBtn
            color="grey"
            onClick={() => {
              setModalFoto({ id: null, base: null });
            }}
          >
            FECHAR
          </MDBBtn>
          <MDBBtn
            color="danger"
            onClick={() => {
              // setModalFoto([]);
              deletaFoto(modalFoto);
            }}
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </MDBContainer>
  );
}

export default ImageMeta;
