import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { saveAs } from 'file-saver';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import { format, parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBCard,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardText,
  MDBCardTitle,
  MDBCardT,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
  MDBCardBody,
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

export default function App() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [qtdTotal, setQtdTotal] = useState(0);
  const [qtdPorPagina, setQtdPorPagina] = useState({
    label: '10 registros',
    value: 10,
  });

  const [novoAssunto, setNovoAssunto] = useState('');

  const [relatos, setRelatos] = useState([]);

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');

  const [listaGerencias, setListaGerencias] = useState([]);

  const [gerenciaSelecionada, setGerenciaSelecionada] = useState({
    value: '',
    label: '',
  });
  // const [listaAssuntos, setListaAssuntos] = useState([]);
  // const [assuntoSelecionado, setAssuntoSelecionado] = useState({
  //   label: '',
  //   value: '',
  // });

  const [dataInicio, setDataInicio] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const [agentes, setAgentes] = useState({ label: '', value: '' });

  const [agenteSelecionado, setAgenteSelecionado] = useState();
  const carregaSelects = () => {
    // const select = [];
    // api
    //   .get('/lpd/lista-assunto', {
    //     params: { ativo: true },
    //   })
    //   .then(assuntos => {
    //     assuntos.data.forEach(element => {
    //       select.push({ label: element.assunto, value: element.id });
    //     });
    //     setListaAssuntos(select);
    //   });
    const agentes = [];
    api
      .get('/agentes-inteligencia', {
        params: { ativo: true },
      })
      .then(agente => {
        agente.data.forEach(element => {
          agentes.push({ label: element.nome_social, value: element.id });
        });

        setAgentes(agentes);
      });

    // api.get('/filtra-gerencia').then(linhas => {
    //   let select = [];
    //   linhas.data.map(linha => {
    //     select.push({
    //       label: linha.nome,
    //       value: String(linha.id),
    //     });
    //   });

    //   setListaGerencias(select);
    // });
  };

  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    setLoading(true);

    const params = {
      // lpd_assunto_id: assuntoSelecionado.value,
      data_inicio: dataInicio,
      data_final: dataFinal,
      // gerencia_id: gerenciaSelecionada.value,
      // qtd_por_pagina: qtdPorPagina.value,
      agente_id: agenteSelecionado,
      page: page,
    };

    const lista = await api.get('/relatos', {
      params: params,
    });

    setQtdTotal(Math.ceil(Number(lista.data.count) / Number(10)));
    const novaLista = [];
    lista.data.rows.forEach(element => {
      let arrayRelato = element.relato.split('\n');
      novaLista.push({ ...element, arrayRelato: arrayRelato });
    });
    setRelatos(novaLista);
    setLoading(false);
  };

  // const getDownloadFile = async () => {
  //   // return api
  //   //   .get('/relato-download', {
  //   //     responseType: 'blob',
  //   //   })
  //   //   .then(response => {
  //   //     // return response;
  //   //     // response.blob();
  //   //     return window.URL.createObjectURL(
  //   //       new Blob(response, { type: 'application/pdf' })
  //   //     );
  //   //   });
  //   api
  //     .get('/relato-download', {
  //       responseType: 'blob',
  //     })
  //     // .then(response => response.blob())
  //     .then(blob => {
  //       // Create blob link to download
  //       const url = window.URL.createObjectURL(blob.data);
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', `relato.pdf`);

  //       // Append to html link element page
  //       document.body.appendChild(link);

  //       // Start download
  //       link.click();

  //       // Clean up and remove the link
  //       link.parentNode.removeChild(link);
  //     });
  // };

  const downloadFile = id => {
    api
      .get('/relato-download', {
        params: { id: id },
        responseType: 'blob',
      })
      .then(blob => saveAs(blob.data, 'relato.pdf'));
  };
  //DOWNLOAD
  // const downloadPDF = async id => {
  //   api
  //     .get('/relato-download', {
  //       params: { id: id },
  //     })
  //     .then(t => {
  //       getDownloadFile().then(blob => saveAs(blob, 'file.pdf'));
  //     });
  // };

  useEffect(() => {
    carregaSelects();
    buscaAssuntos();
  }, []);

  useEffect(() => {
    buscaAssuntos();
  }, [page, qtdPorPagina]);

  return (
    <NavBar>
      <MDBContainer fluid className="p-0 m-0">
        <MDBRow className="h4-responsive" center>
          Relatório de Inteligência
        </MDBRow>
        <MDBRow center>
          {/* <MDBCol size="12" md="3">
            <div>Selecione a Gerência</div>
            <Select
              options={listaGerencias}
              isClearable
              onChange={e => {
                if (e === null) {
                  setGerenciaSelecionada({ label: '', value: '' });
                  return;
                }
                setGerenciaSelecionada(e);
              }}
              value={gerenciaSelecionada}
              placeholder="Gerência"
            />
          </MDBCol> */}
          {/* <MDBCol size="12" md="3">
            <div>Assunto</div>
            <Select
              options={listaAssuntos}
              onChange={e => {
                if (e === null) {
                  setAssuntoSelecionado({ label: '', value: '' });
                  return;
                }
                setAssuntoSelecionado(e);
              }}
              placeholder="Assuntos"
              isClearable
            />
          </MDBCol> */}
          <MDBCol size="12" md="3" className="mb-3 md-md-0">
            <div>Agente</div>
            <Select
              options={agentes}
              onChange={e => {
                if (e === null) {
                  setAgenteSelecionado('');
                  return;
                }
                setAgenteSelecionado(e.value);
              }}
              // value={agenteSelecionado}
              placeholder="Agente"
              isClearable
            />
          </MDBCol>

          <MDBCol size="12" md="3">
            <span>Data Início</span>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataInicio(parseISO(e.target.value))}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Data Final</div>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataFinal(parseISO(e.target.value))}
            />
          </MDBCol>
          <MDBRow center>
            <MDBCol middle center size="12">
              <MDBBtn
                disabled={loading}
                onClick={() => {
                  setPage(Number(1));
                  buscaAssuntos();
                }}
              >
                Buscar
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBRow>
        {loading === true ? (
          <MDBRow className="mt-5 text-center d-flex justify-content-center align-items-center">
            <MDBSpinner />
            <span className="ml-3 font-weight-bold">Carregando</span>
          </MDBRow>
        ) : relatos.length !== 0 ? (
          relatos.map(item => (
            <MDBCard className="mt-3" key={item.id}>
              <MDBCardTitle
                style={{
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  borderBottom: '1px solid rgba(0,0,0,0.1)',
                }}
                className="text-center p-2 h5-responsive"
              >
                <MDBRow>
                  {/* <MDBCol size="12" md="4">
                    <span>Assunto:</span> <br />
                    <span className="font-weight-bolder">
                      {item.lpd_assunto.assunto}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol> */}
                  <MDBCol size="12" md="4">
                    <span>Gerência:</span> <br />
                    <span className="font-weight-bolder">
                      {item.lotacao.gerencia.nome}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <span>Agente:</span> <br />
                    <span className="font-weight-bolder">
                      {' '}
                      {item.usuario.nome_social}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <span>Criação:</span>
                    <br />
                    <span className="font-weight-bolder">
                      {format(
                        zonedTimeToUtc(
                          new Date(item.createdAt),
                          'America/Sao_Paulo'
                        ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                      {/* {console.log(item)} */}
                    </span>
                  </MDBCol>
                </MDBRow>
              </MDBCardTitle>
              <MDBCardBody>
                {/* {item.relato.replace(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                    document.createElement('br')
                  )} */}
                {/* {(item.relato = item.relato.split(
                    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g
                  )).map(item => item)} */}

                {item.arrayRelato.map(linha => (
                  <MDBRow className="mx-2 ">
                    <p style={{ width: '100%', wordWrap: 'break-word' }}>
                      {linha}
                    </p>

                    <br />
                  </MDBRow>
                ))}
                <MDBRow end className="mx-2">
                  {item.relato_pdf === null ? (
                    ''
                  ) : (
                    <div
                      className="text-danger d-flex justify-content-center align-items-center cursor-pointer"
                      onClick={() => {
                        // downloadPDF(item.relato_pdf.id);
                        downloadFile(item.relato_pdf.id);
                      }}
                    >
                      <MDBIcon far icon="file-pdf" />
                      <div className="ml-2">Arquivo PDF</div>
                      {/* /relato-download */}
                    </div>
                  )}
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          ))
        ) : (
          'Sem Dados'
        )}
        <br />
        <MDBRow className="justify-content-center align-items-center">
          {/* <MDBCol size="2">teste</MDBCol> */}
          <MDBCol
            size="8"
            className="d-flex justify-content-center align-items-center"
          >
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  // className={`mx-3 grey-text`}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(1));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-left"
                />
              </span>
              <span>Primeira Página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>

            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(page) - 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-left"
                />
              </span>
              <span>Página Anterior</span>
            </MDBTooltip>
            <div>
              Página {page} de {qtdTotal}
            </div>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page >= qtdTotal) {
                      return;
                    }
                    setPage(Number(page) + 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-right"
                />
              </span>
              <span>Próxima página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == qtdTotal) {
                      return;
                    }
                    setPage(Number(qtdTotal));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-right"
                />
              </span>
              <span>Última página</span>
            </MDBTooltip>
          </MDBCol>
          {/* <MDBCol className="text-center" size="2">
            <span className="font-weight-bolder">Quantidade:</span>
            <Select
              className="text-center"
              placeholder="Quantidade"
              options={[
                { label: '10 registros', value: 10 },
                { label: '20 registros', value: 20 },
                { label: '50 registros', value: 50 },
              ]}
              value={qtdPorPagina}
              onChange={e => setQtdPorPagina(e)}
            />
          </MDBCol> */}
        </MDBRow>
        <br />
      </MDBContainer>
    </NavBar>
  );
}
