import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  format,
  startOfToday,
  endOfToday,
  getDate,
  parseISO,
  parse,
  differenceInDays,
  startOfDay,
} from 'date-fns';
import { getData } from '../../services/auth';

import Select from 'react-select';
import { toast } from 'react-toastify';

import api from '../../services/api';
import {
  MDBContainer,
  MDBCol,
  MDBIcon,
  MDBRow,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBTooltip,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from 'mdbreact';
import NavBar from '../../components/NavBar';
import { useHistory } from 'react-router';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
function EscalaCalendario() {
  const [collapseAddCalendario, setCollapseAddCalendario] = useState(false);
  const [collapseFiltro, setCollapseFiltro] = useState(false);
  const [checkNovaArea, setCheckNovaArea] = useState('false');

  const [dadosCalendario, setDadosCalendario] = useState([]);
  const [dadosGerencias, setDadosGerencias] = useState([]);

  const [lotacaoSelecionada, setLotacaoSelecionada] = useState({
    value: '',
    label: 'Lotação',
  });
  const [dataInicioSelecionada, setDataInicioSelecionada] = useState('');
  const [dataFimSelecionada, setDataFimSelecionada] = useState('');

  const [novosDadoscalendario, setNovosDadoscalendario] = useState({});

  const [optionsEscala, setOptionsEscala] = useState([]);
  const [toogleModalExcluir, setToogleModalExcluir] = useState(false);

  // deletar calendario de lotacao a partir da data
  const [deletaData, setDeletaData] = useState('2020/11/11');
  const [deleteLotacaoID, setDeleteLotacaoID] = useState('');

  const estLogica = getData().gerencia;

  let history = useHistory();
  const geraModulos = async props => {
    // const response = await api.get(`/gerenciaid-modulo/${gerencia_id}`);

    const response = await api.get(`/verifica-acesso`, {
      params: { path: `${window.location.pathname}` },
    });
    if (response.data === null) {
      toast.error('Acesso Negado, Falha reportada ao administrador!');
      history.push('/app');
      return;
    }

    // const acesso = await api.get(`/verifica-acesso-usuario/${idReq}`);
    // setVerificaAcessoUsuario(acesso.data);
  };

  useEffect(() => {
    geraModulos();
  }, []);

  const setGerencias = async () => {
    const gerencias = await api.get('/gerencia', {
      params: {
        estrutura_logica: estLogica,
      },
    });

    let listaGerencias = [];
    gerencias.data.map(gerencia => {
      listaGerencias.push({
        value: gerencia.id,
        label: gerencia.nome,
      });
    });

    setDadosGerencias(listaGerencias);
  };

  const modalExcluir = () => {
    setToogleModalExcluir(!toogleModalExcluir);
  };

  // excluir todos os registros de lotacao a partir da data
  const excluirCalendario = async (data, lotacao_id) => {
    // sistema somente poderá deletar calendario a partir do dia seguinte

    if (differenceInDays(new Date(), new Date(data)) > 0) {
      toast.error('Datas Retroativas não podem ser deletadas!');
      return;
    }

    const result = await api.post(`/remove-calendario`, {
      data: data,
      lotacao_id: lotacao_id,
    });
    modalExcluir();
    Carregamentos();
    toast.success('Calendário Deletado');
  };

  const Carregamentos = async () => {
    const calendario = await api.get('/escala-calendario', {
      params: {
        lotacao_id: lotacaoSelecionada.value,
        data_inicio: dataInicioSelecionada,
        data_fim: dataFimSelecionada,
      },
    });
    setDadosCalendario(calendario.data);
    setGerencias();
  };

  const enviaCalendario = async () => {
    if (!novosDadoscalendario.data_inicio) {
      toast.error('Insira a Data de Início');
      return;
    }
    if (!novosDadoscalendario.lotacao) {
      toast.error('Insira a Lotação');
      return;
    }
    if (!novosDadoscalendario.periodo) {
      toast.error('Insira o Período');
      return;
    }

    if (novosDadoscalendario.data_inicio <= startOfToday(new Date())) {
      toast.error('Datas Retroativas não podem ser criadas!');
      return;
    }

    const calendario = await api.post(
      '/escala-calendario',
      novosDadoscalendario
    );

    toast.success('Calendario criado com sucesso');

    Carregamentos();
    // console.log(novosDadoscalendario);
  };

  const carregaLotacao = async gerencia => {
    setOptionsEscala([]);
    const lotacoes = await api.get('/lotacao', {
      params: {
        gerencia_id: gerencia,
      },
    });

    const arrayLotacoes = [];
    lotacoes.data.map(t => {
      arrayLotacoes.push(t.id);
    });

    let listaEscala = [];
    lotacoes.data.map(lotacao => {
      listaEscala.push({
        value: lotacao.id,
        label: lotacao.escala.nome + ' / ' + lotacao.ala.nome,
      });
    });
    // traz o primeiro objeto do array de lotacoes e insere no select , valor : Todos
    setLotacaoSelecionada(listaEscala[0]);

    setOptionsEscala(listaEscala);
  };

  useEffect(() => {
    Carregamentos();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex justify-content-between mx-3 align-items-center">
            <MDBCol col="4" md="4" className="h4-responsive text-center">
              Calendário
            </MDBCol>
            <MDBCol col="4" md="4" className="d-flex justify-content-center">
              <MDBBtn
                color="primary"
                onClick={() => {
                  if (collapseAddCalendario === true) {
                    setCollapseAddCalendario(!collapseAddCalendario);
                  }
                  setCollapseFiltro(!collapseFiltro);
                }}
                className="d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="filter" size="2x" />
                <h5 className="m-0">Filtro</h5>
              </MDBBtn>
            </MDBCol>
            <MDBCol col="4" md="4" className="d-flex justify-content-end">
              <MDBBtn
                color="primary"
                onClick={() => {
                  if (collapseFiltro === true) {
                    setCollapseFiltro(!collapseFiltro);
                  }
                  setCollapseAddCalendario(!collapseAddCalendario);
                }}
                className="d-flex justify-content-around align-items-center"
              >
                <MDBIcon className="mr-2" icon="plus" size="2x" />
                <h5 className="m-0">Novo calendário</h5>
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          {/* collapse de calendario */}
          <MDBCollapse id="basicCollapse" isOpen={collapseAddCalendario}>
            <MDBRow
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
              style={{
                borderBottom: '5px solid rgba(0,0,0,0.3)',
                borderRadius: '5px',
              }}
            >
              <h4>Insira novo calendário </h4>
            </MDBRow>

            <div
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white"
            >
              <MDBRow>
                <MDBCol col="4">
                  <div
                    style={{ fontSize: '13px', color: 'rgba(0,0,0,0.6)' }}
                    className="text-left"
                  >
                    Data de hora calendario
                  </div>
                  <MDBInput
                    onChange={e => {
                      const teste = zonedTimeToUtc(e.target.value);
                      // console.log(teste);
                      // console.log(e.target.value);
                      setNovosDadoscalendario({
                        ...novosDadoscalendario,
                        data_inicio: teste,
                      });
                    }}
                    type="datetime-local"
                  />
                </MDBCol>
                <MDBCol col="4" className="d-flex align-items-end w-100">
                  <Select
                    className="text-left w-100 mb-4 mt-4"
                    onChange={e => {
                      carregaLotacao(e.value);

                      // setNovosDadoscalendario({
                      //   ...novosDadoscalendario,
                      //   gerencia: e.value,
                      // });
                    }}
                    placeholder="Gerência"
                    options={dadosGerencias}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol col="4" className="d-flex align-items-end w-100">
                  <Select
                    className="text-left w-100 mb-4 mt-4"
                    onChange={e => {
                      setNovosDadoscalendario({
                        ...novosDadoscalendario,
                        lotacao: e.value,
                      });
                    }}
                    placeholder="Lotação"
                    options={optionsEscala}
                  />
                </MDBCol>
                <MDBCol col="4" className="d-flex align-items-end w-100">
                  <Select
                    className="text-left w-100 mb-4 mt-4"
                    onChange={e => {
                      setNovosDadoscalendario({
                        ...novosDadoscalendario,
                        periodo: e.value,
                      });
                    }}
                    placeholder="Período"
                    options={[
                      { value: '0', label: '1 Dia' },
                      { value: '15', label: '15 Dias' },
                      { value: '30', label: '30 Dias' },
                      { value: '60', label: '60 Dias' },
                      { value: '90', label: '90 Dias' },
                      { value: '180', label: '180 Dias' },
                      { value: '365', label: '365 Dias' },
                    ]}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow className="justify-content-center">
                <MDBBtn
                  color="success"
                  onClick={() => enviaCalendario()}
                  className="h-50 col-8 col-md-2 d-flex justify-content-around align-items-center"
                >
                  <MDBIcon className="mr-2" icon="plus" size="2x" />
                  <h5 className="m-0">Cadastrar</h5>
                </MDBBtn>
              </MDBRow>
            </div>
          </MDBCollapse>

          {/* collapse de filtros */}
          <MDBCollapse id="basicCollapse2" isOpen={collapseFiltro}>
            <MDBRow className="rounded-top p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center text-end  bg-white">
              <MDBCol md="3" col="12" className="mt-2">
                <Select
                  className="text-left w-100 mb-4 mt-4"
                  onChange={e => {
                    carregaLotacao(e.value);
                  }}
                  placeholder="Gerência"
                  options={dadosGerencias}
                />
              </MDBCol>
              <MDBCol md="3" col="12" className="mt-2">
                <Select
                  className="text-left w-100 mb-4 mt-4"
                  onChange={e => {
                    setLotacaoSelecionada({
                      value: e.value,
                      label: e.label,
                    });
                  }}
                  value={lotacaoSelecionada}
                  placeholder="Lotação"
                  options={optionsEscala}
                />
              </MDBCol>
              <MDBCol md="3" col="12" className="mt-2">
                <span
                  style={{ fontSize: '0.9rem', color: 'gray' }}
                  className="font-weight-bolder"
                >
                  Data Início
                </span>
                <input
                  type="date"
                  style={{ border: '1px silver solid', borderRadius: '3px' }}
                  className="px-2 py-1 w-100"
                  onChange={e => {
                    setDataInicioSelecionada(e.target.value);
                  }}
                />
              </MDBCol>
              <MDBCol
                className="flex-collumn justify-content-center align-items-center mt-2"
                md="3"
                col="12"
              >
                <span
                  style={{ fontSize: '0.9rem', color: 'gray' }}
                  className="font-weight-bolder"
                >
                  Data Término
                </span>
                <input
                  type="date"
                  className="px-2 py-1 w-100"
                  style={{ border: '1px silver solid', borderRadius: '3px' }}
                  onChange={e => {
                    setDataFimSelecionada(e.target.value);
                  }}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="rounded-bottom mr-1 ml-1 mr-md-3 ml-md-3 justify-content-center text-center  bg-white">
              <MDBBtn
                color="mdb-color"
                onClick={() => Carregamentos()}
                className=" d-flex justify-content-around align-items-center "
              >
                Buscar
              </MDBBtn>
            </MDBRow>
          </MDBCollapse>

          <MDBRow
            between
            style={{ borderRadius: '5px' }}
            className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Data Início
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Data Fim
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Gerência
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between font-weight-bold text-white">
              Escala
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between font-weight-bold text-white">
              Ala
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between font-weight-bold text-white"></div>
          </MDBRow>

          {dadosCalendario.map(calendario => (
            <MDBRow
              key={calendario.id}
              between
              style={{ borderRadius: '5px' }}
              className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
            >
              <div className="col-12 col-md-2 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Data Início:</span>

                {format(
                  new Date(calendario.dt_h_inicio_cal),
                  'dd/MM/yyyy - HH:mm'
                )}
              </div>
              <div className="col-12 col-md-2 mb-4 mb-md-0 d-flex justify-content-center justify-content-md-start">
                <span className="d-sm-inline d-md-none mr-3">Data Fim:</span>
                {/* {format(new Date(zonedTimeToUtc(calendario.dt_servico)), 'HH:mm')} */}

                {format(
                  new Date(calendario.dt_h_fim_cal),
                  'dd/MM/yyyy - HH:mm'
                )}
              </div>
              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Gerencia:</span>

                {calendario.lotacao.gerencia.nome}
              </div>

              <div className="col-12 col-md-2 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Escala:</span>
                {calendario.lotacao.escala.nome}
              </div>
              <div className="col-12 col-md-1 d-flex justify-content-between">
                <span className="d-sm-inline d-md-none mr-3">Ala:</span>
                {calendario.lotacao.ala.nome}
              </div>
              <div className="col-12 col-md-1 d-flex justify-content-center justify-md-content-center">
                <span className="d-sm-inline d-md-none mr-3">Ala:</span>

                <MDBTooltip domElement tag="span" material placement="top">
                  <span className="blue-text">
                    <MDBIcon
                      style={{ cursor: 'Pointer' }}
                      className="red-text"
                      far
                      icon="trash-alt"
                      onClick={() => {
                        setDeletaData(calendario.dt_h_inicio_cal);
                        setDeleteLotacaoID(calendario.lotacao.id);
                        modalExcluir();
                      }}
                    />
                  </span>
                  <span className="font-weight-bolder">Excluir</span>
                </MDBTooltip>
              </div>
            </MDBRow>
          ))}
          <br />
          <br />
          <MDBModal isOpen={toogleModalExcluir} toggle={modalExcluir}>
            <MDBModalHeader toggle={modalExcluir}>
              Excluir Calendário
            </MDBModalHeader>

            <MDBModalBody className="lh-lg">
              Você irá excluir <span className="h6 text-danger">todas</span> as
              datas de calendario para essa lotação a partir do dia{' '}
              <span className="h5-responsive text-danger font-weight-bolder">
                {deletaData && format(new Date(deletaData), 'dd/MM/yyyy')}
              </span>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={modalExcluir}>
                Cancelar
              </MDBBtn>
              <MDBBtn
                color="success"
                onClick={() => {
                  // envio de objeto
                  excluirCalendario(deletaData, deleteLotacaoID);
                }}
              >
                Excluir
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default EscalaCalendario;
