import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';

import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

export default function App() {
  const history = useHistory();

  const [addCollapse, setAddCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const [novoAssunto, setNovoAssunto] = useState('');

  const [assuntos, setAssuntos] = useState([]);

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');
  // INSERE NOVO ASSUNTO
  const enviaNovoAssunto = async () => {
    if (novoAssunto === '') {
      toast.error('Insira o Assunto');
      return;
    }
    const result = await api.post('/lpd/adicionar-assunto', {
      assunto: novoAssunto,
    });
    if (result.data.erro) {
      toast.error(result.data.erro);
      return;
    }

    if (result.data.id) {
      toast.success('Assunto Inserido');
      buscaAssuntos();
      return;
    }

    toast.error('Falha Sistemica, Informe ao Administrador');
  };

  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    const lista = await api.get('/lpd/lista-assunto');
    setAssuntos(lista.data);
  };

  // ATIVA E DESATIVA ASSUNTO
  const alterarItem = async (id, ativo) => {
    const teste = await api.put(`/lpd/edita-assunto/${id}`, {
      ativo,
    });
    setModalAltera(false);
    if (teste.data.sucesso) {
      toast.success(teste.data.sucesso);
    }
    if (teste.data.error) {
      toast.success(teste.data.error);
    }

    buscaAssuntos();
  };

  useEffect(() => {
    buscaAssuntos();
  }, []);

  return (
    <NavBar>
      <MDBContainer className="p-0">
        <MDBRow className="h4-responsive" center>
          Livro de Parte Diária
        </MDBRow>
        <MDBRow className="mr-md-3 ml-md-3 justify-content-center justify-content-md-start">
          <MDBBtn onClick={() => setAddCollapse(!addCollapse)}>
            Adicionar
          </MDBBtn>
        </MDBRow>

        <MDBCollapse isOpen={addCollapse}>
          <MDBRow center style={{ borderRadius: '5px' }} className="bg-white">
            <MDBCol size="12" md="5">
              <MDBInput
                label="Novo Assunto"
                outline
                // counter={15}
                maxlength="50"
                onChange={e => setNovoAssunto(e.target.value)}
              />
            </MDBCol>
            <MDBCol size="12" md="1" middle className="text-center">
              <MDBBtn onClick={() => enviaNovoAssunto()} size="sm">
                Inserir
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCollapse>

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="3">
            Assunto
          </MDBCol>

          <MDBCol size="12" md="1" />

          <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white" />
        </MDBRow>

        {loading === true
          ? 'loading'
          : assuntos.length !== 0
          ? assuntos.map(item => (
              <MDBRow
                key={item.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol size="12" md="8">
                  {item.assunto}
                </MDBCol>
                <MDBCol
                  className="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0"
                  size="12"
                  md="4"
                  onClick={() => {
                    setModalAltera(true);
                    setItemModal({
                      id: item.id,
                      assunto: item.assunto,
                      ativo: item.ativo,
                    });
                  }}
                >
                  {item.ativo === true ? (
                    <div className="d-flex justify-content-end align-items-center red-text cursor-pointer">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 ">Desativar</div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center success-text cursor-pointer">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 ">Reativar</div>
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
            ))
          : 'Sem Dados'}
        <br />
        <MDBModal
          isOpen={modalAltera}
          toggle={() => setModalAltera(!modalAltera)}
          size="lg"
        >
          <MDBModalHeader toggle={() => setModalAltera(!modalAltera)}>
            Alteração
          </MDBModalHeader>
          <MDBModalBody>
            {/* <MDBRow className="font-weight-bolder">
            Deseja desativar este item ?
          </MDBRow> */}
            <MDBRow
              className="p-2 mb-1"
              style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
            >
              <MDBCol className="font-weight-bolder" size="12" md="4">
                Assunto:
              </MDBCol>
              <MDBCol>{itemModal.assunto}</MDBCol>
            </MDBRow>

            <MDBRow
              className="p-2 mb-1"
              style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
            >
              <MDBCol className="font-weight-bolder" size="12" md="4">
                Ativo:
              </MDBCol>
              <MDBCol>
                {itemModal.ativo === true ? (
                  <div className="d-flex ">
                    <span className="success-text font-weight-bold">Sim</span>
                  </div>
                ) : (
                  <div className="d-flex">
                    <span className="danger-text font-weight-bold">Não</span>
                  </div>
                )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-between">
            <MDBBtn
              color={itemModal.ativo === false ? 'success' : 'danger'}
              onClick={() => setModalAltera(false)}
            >
              {itemModal.ativo === true ? (
                <div className="d-flex ">
                  <div
                    className="font-weight-bold cursor-pointer"
                    onClick={() => {
                      alterarItem(itemModal['id'], false);
                    }}
                  >
                    Desativar
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <div
                    className="font-weight-bold cursor-pointer"
                    onClick={() => {
                      alterarItem(itemModal['id'], true);
                    }}
                  >
                    Reativar
                  </div>
                </div>
              )}
            </MDBBtn>
            <MDBBtn onClick={() => setModalAltera(false)}>Fechar</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
