import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBView,
  MDBSwitch,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import Select from 'react-select';
import api from '../../../services/api';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

// import { Container } from './styles';

export default function AddOcorrencia(props) {
  let history = useHistory();
  // pega o parametro de id da url criptografado e descriptogra para dentro da variavel
  // const idReq = base64_url_decode(props.match.params.id);
  const idReq = props.match.params.abordagem_id;

  const verificaPermissao = async () => {
    const result = await api.get(`/abordagem/${idReq}`, {
      params: { per_id: 'ok' },
    });
    if (result.data.error) {
      toast.error(result.data.error);
      history.push('/');
    }
  };

  const [loading, setLoading] = useState(false);

  //
  //
  // DADOS DA OCORRENCIA
  //
  //

  // NATUREZA DA OCORRENCIA FISCAL / POLICIAL
  const [selectNatureza, setSelectNatureza] = useState([]);
  const [naturezaSelecionada, setNaturezaSelecionada] = useState({
    label: '',
    value: '',
  });

  const listaNatureza = async () => {
    const lista = await api.get('/sgo/natureza');
    const select = [];
    lista.data.forEach(linha => {
      select.push({
        label: linha.nome,
        value: linha.id,
      });
    });
    setSelectNatureza(select);
  };
  // RO
  const [numRo, setNumRo] = useState('');
  // FOI AUTUADO?
  const [switchAutuado, setSwitchAutuado] = useState(true);

  // RAZAO DA AUTUAÇÃO
  const [selectRazao, setSelectRazao] = useState([]);
  const [razaoSelecionada, setRazaoSelecionada] = useState({
    label: '',
    value: '',
  });

  // LOADING DO CAMPO RAZAO AUTUACAO
  const [razaoLoading, setRazaoLoading] = useState(false);

  const listaRazao = async () => {
    if (naturezaSelecionada.value === '') {
      return;
    }

    setRazaoLoading(true);
    let result;
    if (naturezaSelecionada.value === '2') {
      result = await api.get(`/razao`, {
        params: {
          autuado: true,
          natureza_ocorrencia_id: naturezaSelecionada,
        },
      });
    } else {
      result = await api.get(`/razao`, {
        params: {
          autuado: switchAutuado,
          natureza_ocorrencia_id: naturezaSelecionada,
        },
      });
    }

    const select = [];
    result.data.forEach(t => {
      select.push({
        label: t.descricao,
        value: t.id,
      });
    });
    setSelectRazao(select);
    setRazaoLoading(false);
    setRazaoSelecionada({
      label: '',
      value: '',
    });
  };

  useEffect(() => {
    listaRazao();
  }, [switchAutuado, naturezaSelecionada]);
  //
  //
  //
  // AUTUADO
  //
  //
  //

  //lista do select de autuados
  const [selectAutuados, setSelectAutuados] = useState([]);
  // lista de pessoas autuadas
  const [autuados, setAutuados] = useState([]);

  // autuado selecionado
  const [autuadoSelecionado, setAutuadoSelecionado] = useState({
    label: '',
    value: '',
    cpf: '',
  });
  const [modalAutuado, setModalAutuado] = useState(false);
  const [modalDeleteAutuado, setModalDeleteAutuado] = useState(false);
  const [idDelecaoAutuado, setIdDelecaoAutuado] = useState('');

  // ADD AUTUADO
  const adicionaAutuado = async () => {
    let duplicado = false;
    autuados.forEach(autuado => {
      if (autuado.id === autuadoSelecionado.value) {
        toast.error('Usuário já Inserido');
        duplicado = true;
        return;
      }
    });
    if (duplicado === true) {
      return;
    }
    setAutuados([
      ...autuados,
      {
        cpf: autuadoSelecionado.cpf,
        nome: autuadoSelecionado.label,
        id: autuadoSelecionado.value,
      },
    ]);
    setModalAutuado(false);
  };

  // REMOVE AUTUADO
  const deletarAutuado = indexArray => {
    if (autuados.length > -1) {
      const newAutuado = autuados;
      newAutuado.splice(indexArray, 1);

      setAutuados(newAutuado);
    }
  };

  // SELECT QUE PREENCHE AUTUADOS
  const PreencheSelectAutuados = async () => {
    const select = [];
    const result = await api.get(`/sgo/busca-abordados/${idReq}`);
    result.data.forEach(abordado => {
      select.push({
        label: abordado.nome,
        value: abordado.id,
        cpf: abordado.cpf,
      });
    });
    setSelectAutuados(select);
  };

  //
  //
  //
  // CARGAS
  //
  //
  //

  // ARRAY DE CARGAS INSERIDAS
  const [cargas, setCargas] = useState([]);
  // MODAL
  const [modalCarga, setModalCarga] = useState(false);

  // LISTA DE TIPOS OCORRENCIAS
  const [selectTiposOcorrencias, setSelectTiposOcorrencias] = useState([]);
  // TIPO OCORRENCIA SELECIONADA
  const [
    tiposOcorrenciasSelecionada,
    setTiposOcorrenciasSelecionada,
  ] = useState({ label: '', value: '' });

  // LISTA DE GRUPOS
  const [selectGrupo, setSelectGrupo] = useState([]);
  // GRUPO SELECIONADO
  const [grupoSelecionado, setGrupoSelecionado] = useState({
    label: '',
    value: '',
  });

  // ITEM SELECIONADO
  const [itemSelecionado, setItemSelecionado] = useState({
    item_grupo_id: '',
    label: '',
    unidade_medida_id: '',
    value: '',
  });
  const carregaSelectGrupo = async tipoId => {
    const result = await api.get(`/grupos/${tipoId}`);

    const grupos = [];
    result.data.forEach(grupo => {
      grupos.push({
        label: grupo.nome,
        value: grupo.id,
      });
    });
    setSelectGrupo(grupos);
  };

  // LISTA DE ITENS
  const [selectItens, setSelectItens] = useState([]);

  const carregaSelectTipoOcorrencias = async naturezaId => {
    if (naturezaId.value === '') {
      return;
    }
    const result = await api.get(`/tipo-ocorrencias/${naturezaId}`);
    const tiposOcorrencias = [];
    result.data.forEach(tipos => {
      tiposOcorrencias.push({
        label: tipos.nome,
        value: tipos.id,
      });
    });
    setSelectTiposOcorrencias(tiposOcorrencias);
  };

  const buscaItensPorGrupo = async cargaId => {
    const result = await api.get(`/itens-por-grupo/${cargaId}`);

    const itens = [];
    result.data.forEach(carga => {
      itens.push({
        label: carga.item.nome,
        value: carga.item.id,
        item_grupo_id: carga.id,
        unidade_medida_id: carga.item.unidade_medida_id,
      });
    });
    setSelectItens(itens);
  };

  const [selectUnidadeMedida, setSelectUnidadeMedida] = useState([]);

  const buscaUnidadeMedida = async item => {
    // const result = await api.get(`/unidade_medida/${item}`);
    const result = await api.get(`/unidade_medida`);

    const unidadeMedida = [];
    result.data.forEach(medida => {
      unidadeMedida.push({
        label: medida.nome,
        value: medida.id,
        unidade_medida_id: medida.unidade_medida_id,
      });
    });
    setSelectUnidadeMedida(unidadeMedida);
  };

  const [cargaQtd, setCargaQtd] = useState('');

  const [unidadeMedidaSelecionada, setUnidadeMedidaSelecionada] = useState({
    label: '',
    value: '',
  });

  // ADD CARGA
  const adicionaCarga = async () => {
    if (tiposOcorrenciasSelecionada.value === '') {
      toast.error('Insira o Tipo');
      return;
    }
    if (grupoSelecionado.value === '') {
      toast.error('Insira o Grupo');
      return;
    }
    if (itemSelecionado.value === '') {
      toast.error('Insira o Item');
      return;
    }
    if (cargaQtd === '') {
      toast.error('Insira o Quantidade');
      return;
    }

    if (cargaQtd < 1) {
      toast.error('Quantidade Inválida');
      return;
    }
    if (unidadeMedidaSelecionada.value === '') {
      toast.error('Insira a Unidade de Medida');
      return;
    }

    let duplicado = false;
    cargas.forEach(carga => {
      if (
        carga.grupo.value === grupoSelecionado.value &&
        carga.item.value === itemSelecionado.value
      ) {
        toast.error('Carga já Inserida');
        duplicado = true;
        return;
      }
    });
    if (duplicado === true) {
      return;
    }
    setCargas([
      ...cargas,
      {
        tipo_ocorrencia_id: tiposOcorrenciasSelecionada.value,
        tipo_label: tiposOcorrenciasSelecionada.label,
        grupo: grupoSelecionado,
        item: itemSelecionado,
        unidade_medida: unidadeMedidaSelecionada,
        qtd: parseFloat(cargaQtd).toFixed(2),
      },
    ]);

    setCargaQtd();
    setModalCarga(false);
  };

  const [modalDeleteCarga, setModalDeleteCarga] = useState(false);
  const [idDelecaoCarga, setIdDelecaoCarga] = useState('');

  // REMOVE CARGA
  const deletarCarga = indexArray => {
    if (cargas.length > -1) {
      const newCargas = cargas;
      newCargas.splice(indexArray, 1);

      setCargas(newCargas);
    }
  };

  //
  //
  //
  // NOTAS FISCAIS
  //
  //
  //

  // const [notasFiscais, setNotasFiscais] = useState([]);
  // const [modalNotasFiscais, setModalNotasFiscais] = useState(false);

  // LISTA DE EMPRESAS
  // const [selectEmpresas, setSelectEmpresas] = useState([]);
  // const [empresaSelecionada, setEmpresaSelecionada] = useState();

  // const carregaSelectEmpresas = async abordagem_id => {
  //   const result = await api.get(`/sgo/abordagens/${abordagem_id}`);

  //   console.log(result);
  //   const empresas = [];
  //   result.data.forEach(empresa => {
  //     empresas.push({
  //       label: empresa.nome,
  //       value: empresa.id,
  //     });
  //   });
  //   setSelectEmpresas(empresas);
  // };

  //
  //
  //
  // EQUIPE
  //
  //
  //

  const [equipe, setEquipe] = useState([]);
  const [modalEquipe, setModalEquipe] = useState(false);

  // LISTA DE AGENTES
  const [selectAgentes, setSelectAgentes] = useState([]);

  const buscaAgentes = async () => {
    const select = [];
    const agentes = await api.get('/busca-usuarios-por-ala', {
      params: { abordagem_id: idReq },
    });

    agentes.data.forEach(agente => {
      select.push({
        label: agente.nome_social,
        // value: agente.id,
        value: agente.id,
      });
    });

    setSelectAgentes(select);
  };

  const [agenteSelecionado, setAgenteSelecionado] = useState({
    label: '',
    value: '',
  });
  // ADD AUTUADO
  const adicionaAgente = async () => {
    let duplicado = false;
    equipe.forEach(agente => {
      // console.log(agente.grupo.value, itemSelecionado.value);
      if (agente.value === agenteSelecionado.value) {
        toast.error('Agente já Inserido');
        duplicado = true;
        return;
      }
    });
    if (duplicado === true) {
      return;
    }
    setEquipe([...equipe, agenteSelecionado]);
  };

  const [modalDeleteEquipe, setModalDeleteEquipe] = useState(false);
  const [idDelecaoEquipe, setIdDelecaoEquipe] = useState('');

  // REMOVE AGENTE
  const deletarAgente = indexArray => {
    if (equipe.length > -1) {
      const newEquipe = equipe;
      newEquipe.splice(indexArray, 1);

      setEquipe(newEquipe);
    }
  };

  //
  //
  //
  // DINAMICA
  //
  //
  //
  //
  const [dinamicaOcorrencia, setDinamicaOcorrencia] = useState('');

  // ENVIO DO FORM
  const [modalEnviarOcorrencia, setModalEnviarOcorrencia] = useState(false);

  const enviarOcorrencia = () => {
    // console.log(equipe);
    setLoading(true);
    api
      .post('/add-ocorrencia', {
        equipe,
        autuado: switchAutuado,
        razao_autuacao: razaoSelecionada,
        autuados,
        cargas,
        ro: numRo,
        dinamicaOcorrencia,
        abordagem_id: idReq,
      })
      .then(t => {
        setLoading(false);
        history.push(`/minhas-abordagens`);
      })
      .catch(t => {
        setLoading(false);
      });

    // console.log(enviar);
  };

  //
  //
  //
  //
  useEffect(() => {
    verificaPermissao();
    listaNatureza();
    PreencheSelectAutuados();
    buscaAgentes();
    // carregaSelectEmpresas(idReq);
  }, []);

  useEffect(() => {
    carregaSelectTipoOcorrencias(naturezaSelecionada);
    if (naturezaSelecionada === 2) {
      setSwitchAutuado(true);
    }
  }, [naturezaSelecionada]);

  useEffect(() => {
    setUnidadeMedidaSelecionada({
      label: '',
      value: '',
    });
  }, [itemSelecionado]);

  useEffect(() => {
    setItemSelecionado({
      item_grupo_id: '',
      label: '',
      unidade_medida_id: '',
      value: '',
    });
  }, [grupoSelecionado]);

  useEffect(() => {
    setGrupoSelecionado({
      label: '',
      value: '',
    });
  }, [tiposOcorrenciasSelecionada]);

  useEffect(() => {
    setTiposOcorrenciasSelecionada({
      label: '',
      value: '',
    });
  }, [razaoSelecionada, cargas]);

  return (
    <NavBar>
      {/* DADOS DE ABORDAGEM */}
      <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <h4 className="text-white font-weight-bolder">
            Criação de Ocorrência
          </h4>
        </MDBView>

        <MDBCardBody className="pt-0">
          <MDBRow>
            <MDBCol size="12" md="3" middle>
              {cargas && cargas.length > 0 ? (
                <span className="d-flex justify-content-center text-danger">
                  Para alterar remova as alterações
                </span>
              ) : (
                ''
              )}
              <Select
                placeholder="Natureza"
                options={selectNatureza}
                value={naturezaSelecionada?.value}
                isDisabled={cargas && cargas.length > 0 ? true : false}
                onChange={e => {
                  // 1 = policial
                  // if (naturezaSelecionada === 2) {
                  //   setSwitchAutuado(true);
                  // }

                  setNaturezaSelecionada(e.value);
                }}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="12" md="3" className="mt-3">
              {/* <div className={naturezaSelecionada === 2 ? `d-none` : 'd-block'}> */}
              <span className="d-flex justify-content-center">
                Foi Autuado ?
              </span>
              <MDBSwitch
                // className={`d-flex justify-content-center  `}
                checked={switchAutuado}
                labelLeft="Não"
                labelRight="Sim"
                value={switchAutuado}
                // disabled={naturezaSelecionada === 2}
                onChange={e => {
                  if (naturezaSelecionada === 1) {
                    setSwitchAutuado(!switchAutuado);
                  }
                  if (naturezaSelecionada === 2) {
                    setSwitchAutuado(true);
                  }
                }}
              />
              {/* </div> */}
            </MDBCol>
            <MDBCol className="mt-2 mt-md-0" size="12" md="6" middle>
              <Select
                placeholder="Razão"
                type="text"
                options={selectRazao}
                isLoading={razaoLoading}
                value={razaoSelecionada?.value}
                onChange={e => {
                  setRazaoSelecionada(e.value);
                }}
              />
            </MDBCol>
            <MDBCol className="mt-2 mt-md-0" size="12" md="3" middle>
              <MDBInput
                value={numRo}
                onChange={e => setNumRo(e.target.value)}
                label="Nº ( Autuação / RO )"
              />
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <br />

      {/* DADOS DE AUTUADOS */}
      <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <div className="text-white h4-responsive font-weight-bolder">
            Pessoa Autuada
          </div>
        </MDBView>

        <MDBCardBody className="pt-0">
          {autuados.map((autuado, index) => (
            <>
              <MDBRow
                key={`A${index}`}
                // style={{ border: '1px solid silver' }}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderTop: '1px solid rgba(0,0,0,0.1)',
                }}
                between
                className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
              >
                <MDBCol>
                  <span className="font-weight-bolder  mr-3">Nome:</span>
                  <span>{autuado.nome}</span>
                </MDBCol>

                <MDBCol>
                  <span className=" font-weight-bolder  mr-3">CPF:</span>
                  <span>{autuado.cpf}</span>
                </MDBCol>

                <div className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                  <div
                    style={{ cursor: 'pointer' }}
                    className="danger-text "
                    onClick={() => {
                      setModalDeleteAutuado(true);
                      setIdDelecaoAutuado(index);
                      // deletarVeiculo(index);
                    }}
                  >
                    <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                    <span style={{ fontSize: '14px' }}>Remover</span>
                  </div>
                </div>
              </MDBRow>
            </>
          ))}
          <br />
          <MDBRow center>
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                setModalAutuado(!modalAutuado);
              }}
            >
              Adicionar Autuado
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <br />

      {/* DADOS DE CARGAS */}
      <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <div className="text-white h4-responsive font-weight-bolder">
            Autuação
          </div>
        </MDBView>

        <MDBCardBody className="pt-0">
          {cargas.map((carga, index) => (
            <>
              <MDBRow
                key={`C${index}`}
                // style={{ border: '1px solid silver' }}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderTop: '1px solid rgba(0,0,0,0.1)',
                }}
                between
                className="mt-3"
                // className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
              >
                <MDBCol size="12" md="6" className="p-1 p-md-2">
                  <span className="font-weight-bolder">Tipo:</span>
                  <span className="ml-2">{carga.tipo_label}</span>
                </MDBCol>
                <MDBCol size="12" md="6" className="p-1 p-md-2">
                  <span className="font-weight-bolder">Grupo:</span>
                  <span className="ml-2">{carga.grupo.label}</span>
                </MDBCol>

                <MDBCol size="12" md="6" className="p-1 p-md-2">
                  <span className=" font-weight-bolder">Item:</span>
                  <span className="ml-2">{carga.item.label}</span>
                </MDBCol>
                <MDBCol size="12" md="6" className="p-1 p-md-2">
                  <span className=" font-weight-bolder">Quantidade:</span>
                  <span className="ml-2">{`${carga.qtd} ${
                    carga.qtd > 1
                      ? carga.unidade_medida.label + 's'
                      : carga.unidade_medida.label
                  }`}</span>
                </MDBCol>
              </MDBRow>
              <MDBRow
                style={{ borderRadius: '0px 0px 10px 10px', cursor: 'pointer' }}
                center
                className="deep-orange darken-4 text-white font-weight-bolder"
                onClick={() => {
                  setModalDeleteCarga(true);
                  setIdDelecaoCarga(index);
                  // deletarVeiculo(index);
                }}
              >
                <div className="p-1">
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </div>
              </MDBRow>
            </>
          ))}

          <br />
          <MDBRow center>
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                setModalCarga(!modalCarga);
              }}
            >
              Adicionar Autuação
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <br />

      {/* DADOS DE NOTAS FISCAIS */}
      {/* <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <div className="text-white h4-responsive font-weight-bolder">
            Notas Fiscais Autuadas
          </div>
        </MDBView>

        <MDBCardBody className="pt-0">
          {notasFiscais.map((notaFiscal, index) => (
            <>
              <MDBRow
                key={`C${index}`}
                // style={{ border: '1px solid silver' }}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderTop: '1px solid rgba(0,0,0,0.1)',
                }}
                between
                className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
              >
                <MDBCol>
                  <span className="font-weight-bolder  mr-3">Nota Fiscal:</span>

                </MDBCol>

                <MDBCol>
                  <span className=" font-weight-bolder  mr-3">CPF:</span>

                </MDBCol>

                <div className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                  <div
                    style={{ cursor: 'pointer' }}
                    className="danger-text "
                    onClick={() => {
                      setModalDeleteAutuado(true);
                      setIdDelecaoAutuado(index);

                    }}
                  >
                    <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                    <span style={{ fontSize: '14px' }}>Remover</span>
                  </div>
                </div>
              </MDBRow>
            </>
          ))}
          <br />
          <MDBRow center>
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                setModalNotasFiscais(!modalNotasFiscais);
              }}
            >
              Adicionar Nota
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>

      <br /> */}
      {/* DADOS DE EQUIPE */}
      <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <div className="text-white h4-responsive font-weight-bolder">
            Equipe
          </div>
        </MDBView>

        <MDBCardBody className="pt-0">
          {equipe.map((agente, index) => (
            <>
              <MDBRow
                key={`C${index}`}
                // style={{ border: '1px solid silver' }}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                  borderTop: '1px solid rgba(0,0,0,0.1)',
                }}
                between
                className="p-2 mr-1 ml-1 mr-md-3 ml-md-3 "
              >
                <MDBCol size="12" md="3" className="font-weight-bolder  mr-3">
                  Nome Social:
                </MDBCol>
                <MDBCol size="12" md="5">
                  {agente.label}
                </MDBCol>

                <MDBCol className="col-12 col-md-2 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center justify-content-md-end">
                  <div
                    style={{ cursor: 'pointer' }}
                    className="danger-text "
                    onClick={() => {
                      setModalDeleteEquipe(true);
                      setIdDelecaoEquipe(index);
                      // deletarVeiculo(index);
                    }}
                  >
                    <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                    <span style={{ fontSize: '14px' }}>Remover</span>
                  </div>
                </MDBCol>
              </MDBRow>
            </>
          ))}
          <br />
          <MDBRow center>
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                setModalEquipe(!modalEquipe);
              }}
            >
              Adicionar Agente
            </MDBBtn>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
      <br />

      {/* DADOS DE DESCRICAO */}
      <MDBCard narrow>
        <MDBView
          className="grey darken-4 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
          cascade
        >
          <h4 className="text-white font-weight-bolder">
            Dinâmica da Ocorrência
          </h4>
        </MDBView>

        <MDBCardBody className="pt-0">
          <MDBInput
            outline
            type="textarea"
            counter={2000}
            className={dinamicaOcorrencia.length > 2000 ? ' is-invalid' : ''}
            rows="3"
            label="Descreva a Dinâmica"
            value={dinamicaOcorrencia}
            onChange={t => setDinamicaOcorrencia(t.target.value)}
          />
        </MDBCardBody>
      </MDBCard>
      <br />

      <MDBRow center>
        <MDBBtn
          onClick={() => {
            // SE FOR OCORRENCIA POLICIAL E FOR AUTUADO PRECISA DO NUMERO DO R.O
            if (naturezaSelecionada === 2) {
              if (switchAutuado === true) {
                if (numRo === '') {
                  toast.error('Ocorrências policiais precisam de R.O');
                  return;
                }
              }
            }

            // if(switchAutuado === true && ){

            // }

            if (razaoSelecionada.value === '') {
              toast.error('Preencha Razão Autuação');
              return;
            }
            if (equipe.length === 0) {
              toast.error('Preencha a Equipe');
              return;
            }

            if (cargas.length === 0) {
              toast.error('Preencha Autuação');
              return;
            }
            if (dinamicaOcorrencia === '') {
              toast.error('Preencha Dinâmica da Ocorrência');
              return;
            }
            if (dinamicaOcorrencia.length > 2000) {
              toast.error('Dinâmica excedeu limite de caracteres');
              return;
            }

            // if (dinamicaOcorrencia === '') {
            //   toast.error('Preencha Dinâmica da Ocorrência');
            //   return;
            // }

            setModalEnviarOcorrencia(true);
          }}
          color="dark-green"
        >
          Enviar
        </MDBBtn>
      </MDBRow>

      {/* MODAL PARA CRIACAO DE AUTUADOS */}
      <MDBModal
        isOpen={modalAutuado}
        toggle={() => {
          setModalAutuado(!modalAutuado);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAutuado(!modalAutuado);
          }}
        >
          Inserir Autuado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center></MDBRow>
          <MDBRow>
            <MDBCol size="12" middle>
              <Select
                placeholder="Escolha o abordado"
                options={selectAutuados}
                onChange={e => {
                  setAutuadoSelecionado(e);
                }}
              />
              {/* <MDBInput
                // maxlength={switchChassi === false ? '7' : '17'}
                type="text"
                label="Nome"
                // placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                onChange={e => setNome(e.target.value)}
                value={nome}
              /> */}
            </MDBCol>

            {/* <MDBCol size="12" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className="w-100 font-weight-light md-form form-group form-lg"
                type="text"
                placeholder="CPF"
                onChange={e => setCpf(e.target.value.replace(/\D+/g, ''))}
                value={cpf}
                mask="999.999.999-99"
                maskChar=" "
                disabled
              />
            </MDBCol> */}
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalAutuado(!modalAutuado);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaAutuado();
            }}
            color="success"
          >
            Cadastrar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR AUTUADO */}
      <MDBModal
        isOpen={modalDeleteAutuado}
        toggle={() => {
          setModalDeleteAutuado({
            modalDeleteAutuado: !modalDeleteAutuado,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteAutuado(!modalDeleteAutuado);
          }}
        >
          Deletar Pessoa Autuada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse autuado?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteAutuado(!modalDeleteAutuado);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarAutuado(idDelecaoAutuado);
              setModalDeleteAutuado(!modalDeleteAutuado);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA CRIACAO DE CARGAS */}
      <MDBModal
        isOpen={modalCarga}
        toggle={() => {
          setModalCarga(!modalCarga);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalCarga(!modalCarga);
          }}
        >
          Inserir Autuação
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" middle>
              {naturezaSelecionada.value === '' ? (
                <span className="text-danger">
                  Natureza precisa ser selecionada
                </span>
              ) : (
                ''
              )}
              <Select
                placeholder="Tipo"
                options={selectTiposOcorrencias}
                value={
                  tiposOcorrenciasSelecionada.value === ''
                    ? null
                    : tiposOcorrenciasSelecionada
                }
                onChange={e => {
                  setTiposOcorrenciasSelecionada({
                    label: e.label,
                    value: e.value,
                  });
                  carregaSelectGrupo(e.value);
                }}
              />
            </MDBCol>

            <MDBCol className="mt-3" size="12" middle>
              <Select
                placeholder="Grupo"
                options={selectGrupo}
                value={grupoSelecionado.value === '' ? null : grupoSelecionado}
                onChange={e => {
                  setGrupoSelecionado({ label: e.label, value: e.value });
                  buscaItensPorGrupo(e.value);
                }}
              />
            </MDBCol>

            <MDBCol className="mt-3" size="12" middle>
              <Select
                placeholder="Item"
                options={selectItens}
                value={itemSelecionado.value === '' ? null : itemSelecionado}
                onChange={e => {
                  setItemSelecionado({
                    item_grupo_id: e.item_grupo_id,
                    label: e.label,
                    unidade_medida_id: e.unidade_medida_id,
                    value: e.value,
                  });
                  buscaUnidadeMedida(e.unidade_medida_id);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow center className="text-danger font-weight-bolder">
            * Digitar (ponto / virgula) somente quando houver decimais
          </MDBRow>
          <MDBRow>
            <MDBCol middle>
              <MDBInput
                className="text-danger"
                onChange={e => {
                  // var teste = parseFloat(e.target.value).toFixed(2);
                  // var teste = parseFloat(e.target.value).toFixed(2);
                  setCargaQtd(
                    // (Math.round(
                    e.target.value
                    //  * 100) / 100).toFixed(2)
                  );
                  // setCargaQtd(e.target.value);
                }}
                // onBlur={e => {
                //   setCargaQtd(parseFloat(e.target.value).toFixed(2));
                // }}
                value={cargaQtd}
                size="12"
                md="6"
                label="Quantidade*"
                type="number"
                min="1"
              />
            </MDBCol>
            <MDBCol middle size="12" md="6">
              <Select
                placeholder="Unidade de Medida"
                options={selectUnidadeMedida}
                value={
                  unidadeMedidaSelecionada.value === ''
                    ? null
                    : unidadeMedidaSelecionada
                }
                onChange={e => {
                  setUnidadeMedidaSelecionada({
                    label: e.label,
                    value: e.value,
                  });
                }}
              />
            </MDBCol>
          </MDBRow>

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalCarga(!modalCarga);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaCarga();
            }}
            color="success"
          >
            Cadastrar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR CARGA */}
      <MDBModal
        isOpen={modalDeleteCarga}
        toggle={() => {
          setModalDeleteCarga({
            modalDeleteCarga: !modalDeleteCarga,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteCarga(!modalDeleteCarga);
          }}
        >
          Deletar Carga Autuada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse autuado?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteCarga(!modalDeleteCarga);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarCarga(idDelecaoCarga);
              setModalDeleteCarga(!modalDeleteCarga);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA CRIACAO DE EQUIPE */}
      <MDBModal
        isOpen={modalEquipe}
        toggle={() => {
          setModalEquipe(!modalEquipe);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalEquipe(!modalEquipe);
          }}
        >
          Inserir Agentes
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" middle>
              <Select
                placeholder="Agentes"
                options={selectAgentes}
                // value={agenteSelecionado?.value}
                onChange={e => {
                  setAgenteSelecionado(e);
                }}
              />
            </MDBCol>
          </MDBRow>

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalEquipe(!modalEquipe);
            }}
          >
            Fechar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaAgente();
            }}
            color="success"
          >
            Cadastrar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR EQUIPE */}
      <MDBModal
        isOpen={modalDeleteEquipe}
        toggle={() => {
          setModalDeleteEquipe({
            modalDeleteEquipe: !modalDeleteEquipe,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteEquipe(!modalDeleteEquipe);
          }}
        >
          Deletar Agente
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse agente da sua equipe?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteEquipe(!modalDeleteEquipe);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarAgente(idDelecaoEquipe);
              setModalDeleteEquipe(!modalDeleteEquipe);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA FINALIZAR OCORRÊNCIA */}
      <MDBModal
        isOpen={modalEnviarOcorrencia}
        toggle={() => {
          setModalEnviarOcorrencia({
            modalEnviarOcorrencia: !modalEnviarOcorrencia,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalEnviarOcorrencia(!modalEnviarOcorrencia);
          }}
        >
          Finalizar Ocorrência
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja finalizar a ocorrência?
        </MDBModalBody>

        {loading === true ? (
          <MDBModalFooter center>
            <div className="d-flex justify-content-center">
              <MDBSpinner />
              <div className="ml-3 d-flex align-items-center font-weight-bolder">
                Enviando
              </div>
            </div>
          </MDBModalFooter>
        ) : (
          <>
            <MDBModalFooter between>
              <MDBBtn
                color="danger"
                onClick={() => {
                  setModalEnviarOcorrencia(!modalEnviarOcorrencia);
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                onClick={() => {
                  enviarOcorrencia();
                }}
                color="success"
              >
                Finalizar
              </MDBBtn>
            </MDBModalFooter>
          </>
        )}
      </MDBModal>
    </NavBar>
  );
}
