import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';

import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
  MDBFileInput,
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

export default function App() {
  const history = useHistory();
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);

  const [modalEnviar, setModalEnviar] = useState(false);
  const [modalPDF, setModalPDF] = useState('');

  const [loadingFile, setLoadingFile] = useState(false);
  const [imagemInseridaNome, setImagemInseridaNome] = useState('');
  // const [assuntoSelecionado, setAssuntoSelecionado] = useState({
  //   value: '',
  //   label: '',
  // });

  const [relato, setRelato] = useState('');

  // const [modalAltera, setModalAltera] = useState(false);
  // const [itemModal, setItemModal] = useState('');
  const adicionaPDF = () => {
    if (image === '') {
      toast.error('Insira o PDF');
      return;
    }
    if (image.type !== 'application/pdf') {
      toast.error('Somente arquivos PDF');
      return;
    }

    setModalPDF(false);
    setImagemInseridaNome(image.name);
  };

  // INSERE NOVO ASSUNTO
  const enviaNovoAssunto = async () => {
    // if (assuntoSelecionado.label === '') {
    //   toast.error('Insira o Assunto');
    //   return;
    // }
    setLoading(true);
    if (relato === '') {
      toast.error('Insira o Relato');
      setLoading(false);
      return;
    }

    const dados = {
      relato: relato,
      missao_id: 1,
      assunto_relato_agente_id: 1,
    };

    if (image !== '') {
      const formData = new FormData();
      formData.append('file', image);

      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await api.post('/pdf-relato', formData, headers);

      if (response.data.id !== '') {
        dados.relato_pdf_id = response.data.id;
      }
    }

    const result = await api.post('/inteligencia', dados);

    // reseta imagens
    // setModalPDF(false);
    setImage('');
    setImagemInseridaNome('');

    if (result.data.erro) {
      toast.error(result.data.erro);
      setLoading(false);
      return;
    }
    if (result.data.sucesso) {
      toast.success(result.data.sucesso);
      setLoading(false);
      setRelato('');
      setModalEnviar(false);
      // history.push('/lpd');
      return;
    }
    setLoading(false);
    toast.error('Falha Sistemica, Informe ao Administrador');
  };

  const uploadFile = async () => {
    // setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', image);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await api.post('/pdf-relato', formData, headers);

    // setLoadingFile(false);
    setModalPDF(false);
    setImage('');
    // setImagemInserida({ id: response.data.id, nome: response.data.nome });
    return response.data;
    // .then(response => {
    //   console.log(response)

    // }).catch(error => console.log(error))
    // rota: '/upload-file'
    // nome: file`
  };

  const toogleModal = () => {
    setImage('');
    setModalPDF(!modalPDF);
  };

  // BUSCA TODOS OS ASSUNTOS
  // const buscaAssuntos = async () => {
  //   const select = [];
  //   const lista = await api.get('/lpd/lista-assunto', {
  //     params: { ativo: true },`
  //   });
  //   lista.data.forEach(element => {
  //     select.push({ label: element.assunto, value: element.id });
  //   });
  //   setAssuntos(select);
  // };

  // useEffect(() => {
  //   buscaAssuntos();
  // }, []);

  return (
    <NavBar>
      <MDBRow className="h4-responsive" center>
        Relatótio de Missão
      </MDBRow>

      <MDBContainer className="p-0 bg-white" style={{ borderRadius: '5px' }}>
        {/* <MDBRow between className="p-3">
          <MDBCol size="12" md="4">
            <Select
              options={assuntos}
              onChange={e => setAssuntoSelecionado(e.value)}
            />
          </MDBCol>
        </MDBRow> */}

        <MDBRow className="px-3">
          <MDBCol size="12">
            <MDBInput
              onChange={e => setRelato(e.target.value)}
              style={{ borderRadius: '5px' }}
              type="textarea"
              value={relato}
              outline
              rows="20"
              maxlength="5000"
              counter
            />
          </MDBCol>
        </MDBRow>

        <MDBRow between className="px-3">
          <MDBCol size="6">
            <MDBBtn onClick={() => setModalEnviar(!modalEnviar)}>Enviar</MDBBtn>
          </MDBCol>
          <MDBCol
            size="6"
            className="d-flex justify-content-end align-items-center"
          >
            {imagemInseridaNome !== '' ? (
              // <MDBCol className="d-flex justify-content-end align-items-center font-weight-bolder">
              //   {imagemInseridaNome}
              //   {/* <MDBBtn color="danger"> */}
              //   <MDBIcon
              //     className="text-danger"
              //     size="2x"
              //     far
              //     icon="times-circle"
              //   />
              //   {/* </MDBBtn> */}
              // </MDBCol>

              <>
                <div>{imagemInseridaNome}</div>

                <MDBTooltip domElement tag="span" material placement="top">
                  <span>
                    <MDBIcon
                      className="text-danger ml-2 cursor-pointer"
                      size="1x"
                      far
                      icon="times-circle"
                      onClick={() => {
                        setImagemInseridaNome('');
                        setImage('');
                      }}
                    />
                  </span>
                  <span className="font-weight-bolder">Remover PDF</span>
                </MDBTooltip>

                {/* <div>{imagemInseridaNome}</div>

              <MDBIcon
                className="text-danger ml-2 cursor-pointer"
                size="2x"
                far
                icon="times-circle"
                onClick={() => {
                  setImagemInseridaNome('');
                  setImage('');
                }}
              /> */}
              </>
            ) : (
              <MDBBtn
                color="elegant"
                className="d-flex justify-content-center align-items-center font-weight-bolder"
                onClick={toogleModal}
              >
                <MDBIcon far icon="file-pdf" size="1x" className="mr-3" />
                <div>Inserir PDF</div>
              </MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBModal
        isOpen={modalEnviar}
        toggle={() => setModalEnviar(!modalEnviar)}
      >
        <MDBModalHeader toggle={() => setModalEnviar(!modalEnviar)}>
          Deseja Realmente enviar este relatório?
        </MDBModalHeader>
        <MDBModalBody>
          Atenção: Após o envio, o relatório não poderá ser alterado ou
          deletado!
          <br />
          {loading === true ? (
            <MDBRow center className="align-items-center">
              <MDBSpinner className="mr-2" red /> <span> Enviando </span>
            </MDBRow>
          ) : (
            ''
          )}
        </MDBModalBody>
        <MDBModalFooter>
          {loading === true ? (
            ''
          ) : (
            <MDBBtn color="danger" onClick={() => setModalEnviar(!modalEnviar)}>
              Cancelar
            </MDBBtn>
          )}

          <MDBBtn disabled={loading} color="success" onClick={enviaNovoAssunto}>
            {loading === true ? 'Enviando' : 'Confirmar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      <MDBModal isOpen={modalPDF} toggle={toogleModal}>
        <MDBModalHeader toggle={toogleModal}>
          Deseja inserir arquivo PDF ?
        </MDBModalHeader>
        <MDBModalBody>
          Atenção: Após o envio, o relatório não poderá ser alterado ou
          deletado!
          <br />
          <MDBFileInput
            textFieldTitle="Arquivo PDF"
            btnTitle="Inserir"
            btnColor="default"
            // allowedFileExtensions={['jpg', 'png']}
            value={image}
            getValue={e => {
              // setImagemInseridaNome(e[0].name);
              setImage(e[0]);
            }}
          />
          {loadingFile === true ? (
            <MDBRow center className="align-items-center">
              <MDBSpinner className="mr-2" red /> <span> Enviando </span>
            </MDBRow>
          ) : (
            ''
          )}
        </MDBModalBody>
        <MDBModalFooter>
          {loadingFile === true ? (
            ''
          ) : (
            <MDBBtn color="danger" onClick={toogleModal}>
              Cancelar
            </MDBBtn>
          )}

          <MDBBtn disabled={loadingFile} color="success" onClick={adicionaPDF}>
            Inserir
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
      <br />
    </NavBar>
  );
}
