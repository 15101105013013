import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';

import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBSpinner,
  MDBInput,
} from 'mdbreact';

import NavBar from '../../../../components/NavBar';
import api from '../../../../services/api';

export default function App() {
  const history = useHistory();

  const [addCollapse, setAddCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  const [assuntos, setAssuntos] = useState([]);

  const [selectUnidadesMedidas, setSelectUnidadesMedidas] = useState();

  const [modalAltera, setModalAltera] = useState(false);
  const [itemModal, setItemModal] = useState('');

  const [unidadeMedidaSelecionada, setUnidadeMedidaSelecionada] = useState('');
  const [novoDado, setNovoDado] = useState('');
  const [motivo, setMotivo] = useState('');
  // INSERE NOVO ASSUNTO
  const enviaNovoAssunto = async () => {
    if (unidadeMedidaSelecionada === '') {
      toast.error('Insira o Unidade de Medida');
      return;
    }
    if (novoDado === '') {
      toast.error('Insira o Nome do Dado');
      return;
    }
    if (motivo === '') {
      toast.error('Insira o Motivo');
      return;
    }

    const result = await api.post('/tipo-dado-operacional-add-assunto', {
      unidade_medida_id: unidadeMedidaSelecionada.value,
      nome: novoDado,
      motivo,
    });

    if (result.data.error) {
      toast.error(result.data.error);
      return;
    }

    if (result.data.ok) {
      toast.success(result.data.ok);
      buscaAssuntos();
      setNovoDado('');
      setUnidadeMedidaSelecionada('');
      setMotivo('');
      return;
    }
  };

  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    const lista = await api.get('/tipo-dado-operacional');
    // console.log(lista.data);
    setAssuntos(lista.data);
  };

  const unidadesMedidasSelect = async () => {
    const lista = await api.get('/select/unidades-medidas');
    // console.log(lista.data);
    setSelectUnidadesMedidas(lista.data);
  };

  // ATIVA E DESATIVA ASSUNTO
  const alterarItem = async (id, ativo) => {
    const teste = await api.put(`/tipo-dado-operacional-editar/${id}`, {
      ativo: ativo,
    });
    setModalAltera(false);
    if (teste.data.sucesso) {
      toast.success(teste.data.sucesso);
    }
    if (teste.data.error) {
      toast.success(teste.data.error);
    }
    setModalAltera(false);
    buscaAssuntos();
  };

  useEffect(() => {
    unidadesMedidasSelect();
    buscaAssuntos();
  }, []);

  return (
    <NavBar>
      <MDBContainer className="p-0">
        <MDBRow className="h4-responsive" center>
          Tipo de Dados Operacionais
        </MDBRow>

        <MDBRow className="mr-md-3 ml-md-3 justify-content-center justify-content-md-start">
          <MDBBtn onClick={() => setAddCollapse(!addCollapse)}>
            Adicionar Assunto
          </MDBBtn>
        </MDBRow>

        <MDBCollapse isOpen={addCollapse}>
          <MDBRow center style={{ borderRadius: '5px' }}>
            <MDBCard className="col-11">
              <MDBCardBody cascade className="text-center">
                <MDBCardTitle>Novo Assunto</MDBCardTitle>

                <MDBRow center>
                  <MDBCol size="12" md="5" middle>
                    <MDBInput
                      label="Novo Dado Operacional"
                      outline
                      type="text"
                      // counter={15}
                      maxLength="50"
                      value={novoDado}
                      onChange={e => setNovoDado(e.target.value)}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="5" middle>
                    <Select
                      options={selectUnidadesMedidas}
                      value={unidadeMedidaSelecionada}
                      onChange={t => {
                        setUnidadeMedidaSelecionada(t);
                      }}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow center>
                  <MDBCol size="12">
                    <MDBInput
                      rows="5"
                      label="Motivo da Inserção"
                      outline
                      type="textarea"
                      // counter={15}
                      maxLength="50"
                      value={motivo}
                      onChange={e => setMotivo(e.target.value)}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow center>
                  <MDBCol size="12" md="1" middle className="text-center">
                    <MDBBtn onClick={() => enviaNovoAssunto()} size="sm">
                      Inserir
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBRow>
        </MDBCollapse>

        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="3">
            Assunto
          </MDBCol>
          <MDBCol size="12" md="3">
            Medida
          </MDBCol>

          <MDBCol size="12" md="1" />

          <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white" />
        </MDBRow>

        {loading === true
          ? 'loading'
          : assuntos.length !== 0
          ? assuntos.map(item => (
              <MDBRow
                key={item.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol size="12" md="3">
                  {item.nome}
                </MDBCol>
                <MDBCol size="12" md="5">
                  {item.unidade_medida.nome}
                </MDBCol>
                <MDBCol
                  className="d-flex justify-content-center justify-content-md-end mt-3 mt-md-0"
                  size="12"
                  md="4"
                  onClick={() => {
                    setModalAltera(true);
                    setItemModal({
                      id: item.id,
                      nome: item.nome,
                      ativo: item.ativo,
                    });
                  }}
                >
                  {item.ativo === true ? (
                    <div className="d-flex justify-content-end align-items-center red-text cursor-pointer">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 ">Desativar</div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end align-items-center success-text cursor-pointer">
                      <MDBIcon far icon="trash-alt" />
                      <div className="font-weight-bolder ml-2 ">Reativar</div>
                    </div>
                  )}
                </MDBCol>
              </MDBRow>
            ))
          : 'Sem Dados'}
        <br />
        <MDBModal
          isOpen={modalAltera}
          toggle={() => setModalAltera(!modalAltera)}
          size="lg"
        >
          <MDBModalHeader toggle={() => setModalAltera(!modalAltera)}>
            Alteração
          </MDBModalHeader>
          <MDBModalBody>
            {/* <MDBRow className="font-weight-bolder">
            Deseja desativar este item ?
          </MDBRow> */}
            <MDBRow
              className="p-2 mb-1"
              style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
            >
              <MDBCol className="font-weight-bolder" size="12" md="4">
                Nome:
              </MDBCol>
              <MDBCol>{itemModal.nome}</MDBCol>
            </MDBRow>

            <MDBRow
              className="p-2 mb-1"
              style={{ backgroundColor: 'rgba(0,0,0,0.04)' }}
            >
              <MDBCol className="font-weight-bolder" size="12" md="4">
                Ativo:
              </MDBCol>
              <MDBCol>
                {itemModal.ativo === true ? (
                  <div className="d-flex ">
                    <span className="success-text font-weight-bold">Sim</span>
                  </div>
                ) : (
                  <div className="d-flex">
                    <span className="danger-text font-weight-bold">Não</span>
                  </div>
                )}
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter className="justify-content-between">
            {itemModal.ativo === true ? (
              <MDBBtn
                color="danger"
                onClick={() => alterarItem(itemModal['id'], false)}
              >
                Desativar
              </MDBBtn>
            ) : (
              <MDBBtn
                color="success"
                onClick={() => alterarItem(itemModal['id'], true)}
              >
                Reativar
              </MDBBtn>
            )}

            <MDBBtn onClick={() => setModalAltera(false)}>Fechar</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
