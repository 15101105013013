import React, { useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import InputMask from 'react-input-mask';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBCardHeader,
} from 'mdbreact';
import { isPast, getYear, format } from 'date-fns';
import NavBar from '../../components/NavBar';
import api from '../../services/api';
import { getData } from '../../services/auth';
import Paginacao from '../../components/Paginacao';
import { AddDenuncia } from './add/index';
import { Relatos } from './Relatos/index';

export default function Denuncias() {
  // const history = useHistory();
  const [abreModal, setAbreModal] = useState(false);
  const [abreRelato, setAbreRelato] = useState(null);

  const [listaDenuncias, setListaDenuncias] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [search, setSearch] = useState('');

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    // E aqui você faria mais alguma lógica, como buscar novos dados com base na nova página
  };

  const addAbreModal = bool => {
    setAbreModal(bool);
  };
  const abreModalRelato = id => {
    setAbreRelato(id);
  };

  const buscaDenuncias = async () => {
    const params = {
      page: currentPage,
    };
    if (search !== '') {
      params.protocolo = search; // A chave deve corresponder exatamente ao que o backend espera
    }
    const result = await api.get('/dd-denuncia', { params });
    setListaDenuncias(result.data);
  };

  const atualizaStatusNoModal = (denunciaId, novoStatus) => {
    // Atualiza o status na lista de denúncias
    setListaDenuncias(listaAntiga => ({
      ...listaAntiga,
      rows: listaAntiga.rows.map(denuncia =>
        denuncia.id === denunciaId
          ? { ...denuncia, dd_status: { status: novoStatus } }
          : denuncia
      ),
    }));

    // Atualiza o status da denúncia que está aberta no modal
    if (abreRelato && abreRelato.id === denunciaId) {
      setAbreRelato(denunciaAtual => ({
        ...denunciaAtual,
        dd_status: { status: novoStatus },
      }));
    }
  };

  useEffect(() => {
    buscaDenuncias();
  }, [currentPage]);

  return (
    <NavBar>
      <MDBContainer>
        <h3 className="h3-responsive text-center font-weight-bolder">
          Disque Denuncia
        </h3>

        <MDBRow
          center
          className="d-flex justify-content-center align-items-center"
        >
          <MDBCol>
            <MDBInput
              onChange={e => setSearch(e.target.value)}
              value={search}
              outline
              className="bg-white"
              label="Insira o protocolo"
            />
          </MDBCol>
          <MDBCol>
            <MDBBtn onClick={() => buscaDenuncias()} size="sm" color="grey">
              Filtrar
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <MDBRow between>
          <MDBCol className="h4-responsive font-weight-bolder">
            Lista de Denúncias
          </MDBCol>
          <MDBCol className="text-right">
            <MDBBtn onClick={() => addAbreModal(true)} color="green" size="sm">
              Criar Denúncia
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow
          style={{ borderRadius: '5px' }}
          className=" mx-3 px-3 d-none d-md-flex text-white font-weight-bolder grey darken-1"
        >
          <div className="col-12 col-md-3">Protocolo</div>
          <div className="col-12 col-md-2">Data Início</div>
          <div className="col-12 col-md-2">Classificacao</div>
          <div className="col-12 col-md-2">Status</div>
          <div className="col-12 col-md-1">Sigilo</div>
        </MDBRow>
        {listaDenuncias?.rows?.map(denuncia => (
          <MDBRow
            key={denuncia.id}
            style={{ borderRadius: '5px' }}
            className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3  bg-white"
          >
            <MDBCol
              size="12"
              md="3"
              className="d-flex justify-content-between font-weight-bolder"
            >
              {denuncia.protocolo}
              {/* {console.log(
       vtr?.status_veiculos[0].tipos_status_veiculo.utilizavel
     )} */}
              {/* {vtr?.manutencoes[0]?.data_executado === undefined ||
       vtr?.manutencoes[0]?.data_executado === null ||
       (vtr?.status_veiculos[0]?.tipos_status_veiculo.utilizavel ===
         false || vtr.ativo === false ? (
         <MDBIcon
           className="text-danger"
           style={{ fontSize: '21px' }}
           icon="window-close"
         />
       ) : (
         <MDBIcon
           className="text-success"
           style={{ fontSize: '21px' }}
           icon="check-circle"
         />
       ))} */}

              {/* <span
       className={`${
         vtr.ativo ? 'text-success' : 'text-danger'
       } font-weight-bold`}
     >
       {vtr.ativo ? (
         <MDBIcon style={{ fontSize: '21px' }} icon="check-circle" />
       ) : (
         <MDBIcon style={{ fontSize: '21px' }} icon="window-close" />
       )}
     </span> */}
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Data:</span>
              <span>
                {format(new Date(denuncia.createdAt), 'dd/MM/yyyy HH:mm')}
              </span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Classificacao:</span>
              <span>{denuncia.dd_classificacao.classificacao}</span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Status:</span>
              <span>{denuncia.dd_status.status}</span>
            </MDBCol>
            <MDBCol size="12" md="2" className="d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Sigilo:</span>
              <span>{denuncia.dd_nivel_sigilo.nivel_sigilo}</span>
            </MDBCol>

            <MDBCol className="col-12 col-md-1 d-flex justify-content-end font-weight-bolder">
              <div
                onClick={() => abreModalRelato(denuncia)}
                className="danger-text cursor-pointer"
              >
                <MDBIcon className="mr-1 " far icon="fas fa-eye" size="1x" />

                <span>Ver</span>
              </div>
            </MDBCol>
          </MDBRow>
        ))}

        <Paginacao
          page={currentPage}
          qtdTotal={listaDenuncias.count}
          onPageChange={handlePageChange}
        />
      </MDBContainer>
      <AddDenuncia
        openModal={abreModal}
        addAbreModal={addAbreModal}
        buscaDenuncias={buscaDenuncias}
      />

      <Relatos
        openModal={abreRelato}
        abreModalRelato={abreModalRelato}
        atualizaStatusNoModal={atualizaStatusNoModal}
      />
    </NavBar>
  );
}
