import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBRow,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCloseIcon,
  MDBSpinner,
  MDBAnimation,
  MDBSelect,
} from 'mdbreact';

import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { getData } from '../../../services/auth';
import { useState, useEffect } from 'react';

import InputMask from 'react-input-mask';
// import { compareAsc } from 'date-fns';

import { validarCNPJ } from '../../../components/ValidarCnpj';
import api from '../../../services/api';
import { toast } from 'react-toastify';
import Select from 'react-select';

export function Relatos(props) {
  const [collapseInsereRelato, setcollapseInsereRelato] = useState(false);
  const [assuntos, setAssuntos] = useState([]);
  const [assuntoSelecionado, setAssuntosSelecionado] = useState(null);
  const [insereRelato, setInsereRelato] = useState('');

  const [relatos, setRelatos] = useState([]);

  const [listaStatus, setListaStatus] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState(null);

  const [insereEmpresa, setInsereEmpresa] = useState(false);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [cnpj, setCnpj] = useState('');
  const [empresaNome, setEmpresaNome] = useState('');

  const [modalPlaca, setModalPlaca] = useState(null);
  const [listaPlacas, setListaPlacas] = useState([]);
  const [placa, setPlaca] = useState('');

  const buscaAssuntos = async () => {
    const result = await api.get('/dd-assuntos');

    const select = [];

    result.data.forEach(element => {
      select.push({ value: element.id, label: element.assunto });
    });
    setAssuntos(select);
  };

  const enviaRelato = async () => {
    if (assuntoSelecionado === null) {
      toast.error('Selecione o Assunto');
      return;
    }
    if (insereRelato === '') {
      toast.error('Preencha descrição');
      return;
    }
    try {
      const result = await api.post('/dd-relatos', {
        dd_assuntos_id: assuntoSelecionado.value,
        dd_denuncia_id: props.openModal.id,
        relato: insereRelato,
        empresas: listaEmpresa,
        placas: listaPlacas,
      });

      buscaRelatos(props.openModal.id);
      if (result.data.ok) {
        setcollapseInsereRelato(false);
        setAssuntosSelecionado(null);
        setInsereRelato('');
        setEmpresaNome('');
        setCnpj('');
        setListaEmpresa([]);
        setListaPlacas([]);
        setPlaca('');
        toast.success(result.data.ok);
      }
      if (result.data.error) toast.success(result.data.error);
    } catch (error) {
      toast.error('Error, avise a Adminstração');
    }
  };

  const buscaRelatos = async denuncia_id => {
    const result = await api.get('/dd-relatos', {
      params: { dd_denuncia_id: denuncia_id },
    });

    console.log(result.data);
    setRelatos(result.data);
  };

  const buscaStatus = async () => {
    const select = [];
    const result = await api.get('/dd-status');
    result.data.forEach(element => {
      select.push({ label: element.status, value: element.id });
    });
    setListaStatus(select);
    // const result = await api.put('dd-denuncia', {
    //   params: { dd_denuncia_id: denuncia_id , dd_status_id:  },
    // });
  };

  const alteraStatusDenuncia = async () => {
    const result = await api.put(`/dd-denuncia/${props.openModal.id}`, {
      dd_status_id: statusSelecionado.value,
    });
    if (result.status === 200) {
      toast.success('Status alterado com sucesso!');
      props.atualizaStatusNoModal(props.openModal.id, statusSelecionado.label);
      setStatusSelecionado(null);
      setAssuntosSelecionado(null);
    } else {
      toast.error('Status não alterado, tente mais tarde');
    }
  };

  const imprimirModal = () => {
    const elementos = document.getElementsByClassName('modalDenunciaRelato');

    if (elementos.length > 0) {
      const janela = window.open('', '', 'height=600,width=800');
      janela.document.write('<html><head><title>Impressão</title>');
      // Aqui você pode adicionar estilos específicos para a impressão se necessário
      janela.document.write('</head><body>');

      Array.from(elementos).forEach(conteudo => {
        janela.document.write(conteudo.innerHTML);
      });

      janela.document.write('</body></html>');
      janela.document.close();

      setTimeout(() => {
        janela.focus();
        janela.print();
      }, 500); // Ajuste o atraso conforme necessário
    } else {
      console.error('Nenhum elemento para impressão foi encontrado!');
    }
  };

  const adicionaEmpresa = async () => {
    const validade = validarCNPJ(cnpj);

    if (validade === false) {
      toast.error('CNPJ inválido');
      return;
    }
    if (empresaNome.length > 150) {
      toast.error('Excedeu limite de caracteres');
      return;
    }
    if (empresaNome === '') {
      toast.error('Nome da Empresa inválido');
      return;
    }
    const found = listaEmpresa.find(element => element.cnpj === cnpj);

    if (found && found.cnpj) {
      toast.error('CNPJ já existe');
      return;
    }
    setListaEmpresa([
      ...listaEmpresa,
      {
        cnpj: cnpj,
        nome: empresaNome,
      },
    ]);

    setCnpj('');
    setEmpresaNome('');
    // setModalAddEmpresa(null);
    // setModalEmpresa('false');
  };

  const deletaEmpresa = cnpj => {
    const resto = listaEmpresa.filter(element => element.cnpj !== cnpj);
    setListaEmpresa(resto);
  };

  const adicionaPlaca = async () => {
    const regex = '[A-Z]{3}[0-9][0-9A-Z][0-9]{2}';

    if (placa.match(regex) === null) {
      toast.error('Placa Inválida');
      return;
    }

    const found = listaPlacas.find(element => element === placa);

    if (found) {
      toast.error('Placa já existe');
      return;
    }
    setListaPlacas([...listaPlacas, placa]);
  };

  const deletaPlaca = placa => {
    const resto = listaPlacas.filter(element => element !== placa);
    setListaPlacas(resto);
  };

  useEffect(() => {
    if (collapseInsereRelato === true) {
      buscaAssuntos(); // Chamada de função correta com parênteses
    }
  }, [collapseInsereRelato]); // Adicione collapseInsereRelato como uma dependência para o useEffect

  useEffect(() => {
    if (props.openModal) {
      buscaRelatos(props.openModal.id);
    }
    setInsereRelato('');

    setPlaca('');
    setCnpj('');
    setListaPlacas([]);
    setListaEmpresa([]);
    setEmpresaNome('');

    // if (props.openModal.id) {
    //   buscaRelatos(props.openModal.id);
    // }
  }, [props.openModal]);
  useEffect(() => {
    buscaStatus();
  }, []);
  return (
    <>
      <MDBModal
        size="lg"
        isOpen={props.openModal !== null}
        toggle={() => {
          props.abreModalRelato(null);
        }}
        keyboard={false}
        disableBackdrop
      >
        <MDBModalHeader
          toggle={() => {
            // setModalPreviaServico(!modalPreviaServico);

            props.abreModalRelato(null);
          }}
        >
          Criar Relato
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow between className="modalDenunciaRelato">
            <MDBCol>Protocolo: {props.openModal?.protocolo}</MDBCol>
            <MDBCol className="text-center">
              Data:
              {props.openModal?.createdAt &&
                format(
                  new Date(props.openModal?.createdAt),
                  ' dd/MM/yyyy HH:mm'
                )}
            </MDBCol>
            <MDBCol className="text-right">
              Nível Sigilo: {props.openModal?.dd_nivel_sigilo.nivel_sigilo}
            </MDBCol>
          </MDBRow>
          <MDBRow between className="pb-3  modalDenunciaRelato">
            <MDBCol>
              Classificação: {props.openModal?.dd_classificacao.classificacao}
            </MDBCol>
            <MDBCol className="text-right">
              Status: {props.openModal?.dd_status.status}
            </MDBCol>
          </MDBRow>
          <MDBRow className="py-3 border-top border-light">
            <MDBCol className="h4-responsive font-weight-bolder modalDenunciaRelato">
              DENÚNCIA
            </MDBCol>
            <MDBCol className="d-flex" size="12" md="6">
              <Select
                className="w-100"
                options={listaStatus}
                onChange={e => {
                  setStatusSelecionado(e);
                }}
                value={statusSelecionado}
                placeholder="Alterar Status da Denúncia"
              />
              {statusSelecionado !== null && (
                <MDBBtn
                  onClick={() => alteraStatusDenuncia()}
                  className="col-5"
                  color="green"
                  size="sm"
                >
                  Salvar
                </MDBBtn>
              )}
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3 modalDenunciaRelato">
            <MDBCol>
              {props.openModal?.denuncia.split('\n').map((linha, index) => (
                <div key={index}>
                  {linha} <br />
                </div>
              ))}
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3 border-top border-light modalDenunciaRelato">
            <MDBCol className="h4-responsive font-weight-bolder">
              RELATOS
            </MDBCol>
          </MDBRow>

          {relatos.map(rel => (
            <div
              key={rel.id}
              className="border-bottom border-light my-4 modalDenunciaRelato"
            >
              <MDBRow between>
                <MDBCol className="font-weight-bolder">
                  Criação: {rel.usuario.nome_social}
                </MDBCol>
              </MDBRow>
              {rel.dd_empresas.length > 0 && (
                <MDBRow>
                  <MDBCol className=" h5-responsive text-right font-weight-bolder blue-grey-text">
                    Empresas relacionadas:
                  </MDBCol>
                </MDBRow>
              )}

              {rel.dd_empresas.map(empresa => (
                <MDBRow className="m-2 grey lighten-4">
                  <MDBCol size="12">Nome: {empresa.nome}</MDBCol>
                  <MDBCol size="12">CNPJ: {empresa.cnpj}</MDBCol>
                </MDBRow>
              ))}
              <br />
              {rel.dd_veiculos.length > 0 && (
                <MDBRow>
                  <MDBCol className=" h5-responsive text-right font-weight-bolder blue-grey-text">
                    Placas relacionadas:
                  </MDBCol>
                </MDBRow>
              )}

              <MDBRow end className="m-2 mb-5 font-weight-bolder">
                {rel.dd_veiculos.map(veiculos => (
                  <MDBCol size="3" className="ml-2 p-3 grey lighten-4">
                    Placa: {veiculos.placa}
                  </MDBCol>
                ))}
              </MDBRow>

              <MDBRow>
                <MDBCol className="font-weight-bolder">
                  Assunto: {rel.dd_assunto.assunto}
                </MDBCol>
                <MDBCol className="font-weight-bolder text-right">
                  Data: {format(new Date(rel.createdAt), 'dd/MM/yyyy HH:mm')}
                </MDBCol>
              </MDBRow>
              <MDBRow className="my-3  ">
                <MDBCol>
                  {rel.relato.split('\n').map((linha, index) => (
                    <div key={index}>
                      {linha} <br />
                    </div>
                  ))}
                </MDBCol>
              </MDBRow>
            </div>
          ))}

          <br />
          <MDBRow className="border-top border-light">
            <MDBCol>
              <MDBBtn
                onClick={() => setcollapseInsereRelato(!collapseInsereRelato)}
                size="sm"
                color="green"
              >
                Criar Relato
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          <MDBCollapse id="basicCollapse" isOpen={collapseInsereRelato}>
            <MDBRow>
              <MDBCol>
                <MDBBtn
                  size="sm"
                  className="mb-0 font-weight-bold blue darken-3 d-flex align-items-center"
                  onClick={() => setInsereEmpresa(true)}
                >
                  <MDBIcon size="2x" far icon="plus-square" />
                  <div className="ml-2">Empresas</div>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {/* <MDBRow className="mx-0 mx-md-1 d-none d-md-flex rounded-top text-black font-weight-bolder grey lighten-1">
                  <div className="col-12 col-md-4 d-flex align-items-center">
                    CNPJ
                  </div>
                  <div className="col-12 col-md-4 d-flex align-items-center">
                    Nome
                  </div>
                  <div className="col-12 col-md-4 d-flex justify-content-end"></div>
                </MDBRow> */}
                {listaEmpresa.map(empresa => (
                  <MDBRow
                    // key={denuncia.id}
                    // style={{ borderRadius: '5px' }}
                    className="p-2 mx-1  grey lighten-2 mb-2"
                    between
                  >
                    <MDBCol size="11">
                      <MDBCol className="d-flex font-weight-bolder">
                        <span className="mr-3">CNPJ:</span>
                        {empresa.cnpj}
                      </MDBCol>

                      <MDBCol className="d-flex">
                        <span className="mr-3">Nome:</span>
                        <span>{empresa.nome}</span>
                      </MDBCol>
                    </MDBCol>
                    <MDBCol
                      size="1"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <MDBCloseIcon
                        onClick={() => deletaEmpresa(empresa.cnpj)}
                      />
                    </MDBCol>
                  </MDBRow>
                ))}
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-3">
              <MDBCol className="text-left">
                <MDBBtn
                  size="sm"
                  className=" font-weight-bold blue darken-3 mb-0 d-flex align-items-center"
                  onClick={() => setModalPlaca(props.openModal.id)}
                >
                  <MDBIcon size="2x" far icon="plus-square" />
                  <div className="ml-2">Placas</div>
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBRow className="mx-0 mx-md-1 d-none d-md-flex rounded-top text-black font-weight-bolder grey lighten-1">
                  <div className="col-12 col-md-4 d-flex align-items-center">
                    CNPJ
                  </div>

                  <div className="col-12 col-md-4 d-flex justify-content-end"></div>
                </MDBRow>
                <MDBRow>
                  {listaPlacas.map(placa => (
                    <MDBCol size="12" md="6">
                      <MDBRow
                        // key={denuncia.id}
                        // style={{ borderRadius: '5px' }}
                        between
                        className="p-2 mx-1 grey lighten-2 mb-2"
                      >
                        <MDBCol
                          size="8"
                          className="d-flex justify-content-between font-weight-bolder"
                        >
                          <span className="d-sm-inline d-md-none mr-3">
                            Placa:
                          </span>
                          {placa}
                        </MDBCol>

                        <MDBCol
                          size="4"
                          className="d-flex justify-content-end align-items-center"
                        >
                          <MDBCloseIcon
                            onClick={() => deletaPlaca(placa)}
                            className="d-flex justify-content-end align-items-center"
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol size="12" md="5">
                <Select
                  options={assuntos}
                  onChange={e => {
                    setAssuntosSelecionado(e);
                  }}
                  value={assuntoSelecionado}
                  placeholder="Assunto"
                />
              </MDBCol>
            </MDBRow>

            <MDBRow>
              <MDBCol>
                <MDBInput
                  label="Descreva o Relato"
                  outline
                  rows="12"
                  type="textarea"
                  onChange={e => setInsereRelato(e.target.value)}
                  value={insereRelato}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBBtn onClick={() => enviaRelato()}>Enviar</MDBBtn>
            </MDBRow>
          </MDBCollapse>
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBCol>
            <MDBBtn
              outline
              className="d-flex align-items-center"
              rounded
              size="sm"
              onClick={imprimirModal}
            >
              <MDBIcon size="1x" far icon="file-pdf" />
              <div className="ml-2">Imprimir</div>
            </MDBBtn>
          </MDBCol>
          <MDBCol className="text-right">
            <MDBBtn
              size="sm"
              color="danger"
              onClick={() => props.abreModalRelato(null)}
            >
              Cancelar
            </MDBBtn>
          </MDBCol>

          {/* <MDBBtn onClick={() => console.log('sucess')} color="success">
            Confirmar
          </MDBBtn> */}
        </MDBModalFooter>
        <MDBModal
          size="lg"
          isOpen={insereEmpresa === true}
          toggle={() => {
            setInsereEmpresa(false);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              // setModalPreviaServico(!modalPreviaServico);

              setInsereEmpresa(false);
            }}
          >
            Criar Relato
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size="12" md="4" middle>
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                    marginTop: '35px',
                  }}
                  className="w-100 font-weight-light md-form form-group form-lg"
                  type="text"
                  placeholder="CNPJ"
                  onChange={e => setCnpj(e.target.value.replace(/\D+/g, ''))}
                  value={cnpj}
                  mask="99.999.999/9999-99"
                  maskChar=" "
                />
              </MDBCol>
              <MDBCol size="12" md="8" middle>
                <MDBInput
                  type="text"
                  label="Nome"
                  onChange={e => setEmpresaNome(e.target.value)}
                  value={empresaNome}
                  counter={150}
                  maxLength={150}
                />
              </MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter between>
            <button
              className="btn btn-danger"
              onClick={() => {
                setInsereEmpresa(false);
              }}
            >
              Cancelar
            </button>
            <MDBBtn
              // disabled={loadingEnviar}
              onClick={() => {
                adicionaEmpresa();
              }}
              className="btn btn-success"
            >
              Enviar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          size="lg"
          isOpen={modalPlaca !== null}
          toggle={() => {
            setModalPlaca(null);
          }}
        >
          <MDBModalHeader
            toggle={() => {
              // setModalPreviaServico(!modalPreviaServico);

              setModalPlaca(null);
            }}
          >
            Criar Placa
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol size="12" md="4" middle>
                <MDBInput
                  maxLength={7}
                  type="text"
                  placeholder={'Placa'}
                  onChange={e => {
                    setPlaca(e.target.value.toUpperCase());
                  }}
                  value={placa}
                />
              </MDBCol>
            </MDBRow>
            <br />
          </MDBModalBody>
          <MDBModalFooter between>
            <button
              className="btn btn-danger"
              onClick={() => {
                setModalPlaca(null);
              }}
            >
              Cancelar
            </button>
            <MDBBtn
              // disabled={loadingEnviar}
              onClick={() => {
                adicionaPlaca();
              }}
              className="btn btn-success"
            >
              Enviar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBModal>
    </>
  );
}
