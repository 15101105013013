import React, { useEffect, useState } from 'react';
import { Html5QrcodeScanner, Html5QrcodeScanType } from 'html5-qrcode';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';
export const QRCodeScanner = ({ onQRCodeScan }) => {
  const [abreCamera, setAbreCamera] = useState(false);
  const [url, setUrl] = useState('');
  // useEffect(() => {
  const qrCodeSuccessCallback = qrCodeMessage => {
    setUrl(qrCodeMessage);
    // qrCodeScanner.clear();
    qrCodeScanner.clear();
    setAbreCamera(false);
    onQRCodeScan(qrCodeMessage);
    // Aqui, você pode lidar com a mensagem do código QR como desejar.
    // Por exemplo, enviar para um servidor, exibir na tela, etc.
  };

  const qrCodeErrorCallback = errorMessage => {
    // console.log('nao funcionou');
    // console.error('Error while scanning QR code:', errorMessage);
    // qrCodeScanner.stop();
  };
  var qrCodeScanner = '';
  // Cria uma instância do leitor QRCode
  // const qrCodeScanner = new Html5QrcodeScanner(
  //   'qrCodeReader',
  //   {
  //     fps: 20,
  //     qrbox: 250,
  //     experimentalFeatures: {
  //       useBarCodeDetectorIfSupported: true,
  //     },
  //     rememberLastUsedCamera: true,
  //     supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
  //   },
  //   qrCodeSuccessCallback,
  //   qrCodeErrorCallback
  // );

  // Inicializa o leitor QRCode
  useEffect(() => {
    if (abreCamera) {
      qrCodeScanner = new Html5QrcodeScanner(
        'qrCodeReader',
        {
          fps: 20,
          qrbox: 250,
          experimentalFeatures: {
            useBarCodeDetectorIfSupported: true,
          },
          rememberLastUsedCamera: true,
          supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        },
        qrCodeSuccessCallback,
        qrCodeErrorCallback
      );
      qrCodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    }
  }, [abreCamera]);

  // Quando o componente é desmontado, pare a leitura do QRCode
  // return qrCodeScanner.stop();
  // }, []);

  return (
    <MDBRow>
      {abreCamera === true ? (
        <div id="qrCodeReader" style={{ width: '100%' }} />
      ) : (
        <MDBCol size="12" className="d-flex justify-content-center">
          <MDBBtn
            onClick={() => setAbreCamera(true)}
            color="black"
            outline
            className="d-flex align-items-center"
          >
            <MDBIcon icon="qrcode" size="2x" />
            <span className="ml-2 font-weight-bolder">Insira com QR Code</span>
          </MDBBtn>
        </MDBCol>

        // <MDBBtn onClick={() => setAbreCamera(true)}>QRCODE</MDBBtn>
      )}
    </MDBRow>
  );
};
