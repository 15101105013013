import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from 'react-select';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBView,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSpinner,
  MDBCollapse,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import InputMask from 'react-input-mask';
import api from '../../../services/api';
import { validarCNPJ } from '../Components/ValidarCnpj';

import Veiculos from '../Components/Veiculos';
import Abordados from '../Components/Abordados';
import Empresas from '../Components/Empresas';
import Revisoes from '../Components/Revisoes';
import Autuados from '../Components/Ocorrencias/Autuados';
import Autuacoes from '../Components/Ocorrencias/Autuacoes';
import Equipe from '../Components/Ocorrencias/Equipe';

import { ExternalStateExample } from '../../../components/localizacaogps';
import { Context } from '../ContextApi/Context';
import { logout } from '../../../services/auth';

export default function App(props) {
  const [idReq, setIdReq] = useState(props.match.params.abordagem_id);

  useEffect(() => {
    setIdReq(props.match.params.abordagem_id);
  }, [props.match.params.abordagem_id]);

  let history = useHistory();
  const [coordenadas, setCoordenadas] = useState({
    newGeo: false,
  });

  const [idAbordagem, setIdAbordagem] = useState();

  const [dataInicioAbordagem, setDataInicioAbordagem] = useState('');
  const [posto, setPosto] = useState('');
  const [ala, setAla] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [estadoOrigem, setEstadoOrigem] = useState('');
  const [cidadeOrigem, setCidadeOrigem] = useState('');
  const [estadoDestino, setEstadoDestino] = useState('');
  const [cidadeDestino, setCidadeDestino] = useState('');

  const [modalExcluiAbordagem, setModalExcluiAbordagem] = useState(false);
  const [abordagemSelecionada, setAbordagemSelecionada] = useState();

  const [modalExcluiOcorrencia, setModalExcluiOcorrencia] = useState(false);
  const [ocorrenciaSelecionada, setOcorrenciaSelecionada] = useState();

  const [modalEditarRO, setModalEditarRO] = useState(null);
  const [editaRO, setEditaRO] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingOcorrencias, setLoadingOcorrencias] = useState(false);

  const [loadingDinamica, setLoadingDinamica] = useState(false);

  const [dinamica, setDinamica] = useState({
    ocorrencia_id: null,
    dinamica: null,
  });

  const [selectGerencia, setSelectGerencia] = useState('');

  const [revisao, setRevisao] = useState([]);

  const [collapseId, setCollapseId] = useState(null);

  const [apoioVolante, setApoioVolante] = useState(null);

  const [modalLatLong, setModalLatLong] = useState(false);

  const [coordBanco, setCoordBanco] = useState([]);

  const permissaoAcesso = async () => {
    try {
      const result = await api.get(`/permissao-abordagem/${idReq}`);
      if (result.data === null) {
        logout();
        toast.error('Acesso Proibido, informado ao Administrador!');
        history.push('/');
      }
    } catch (error) {
      logout();
      toast.error('Erro, informar ao Suporte!');
      history.push('/');
    }
  };

  const excluiAbordagem = async id => {
    // rota de backend

    const excluiAbordagem = await api.put(`/desativa-abordagem/${id}`);

    history.push('/minhas-abordagens');
  };

  const excluiOcorrencia = async id => {
    // rota de backend

    const excluiOcorrencia = await api.put(`/desativa-ocorrencia/${id}`);
    listaOcorrencias();
    setModalExcluiOcorrencia(false);
    setOcorrenciaSelecionada('');
  };

  const trataDate = date => {
    return format(
      zonedTimeToUtc(new Date(date), 'America/Sao_Paulo'),
      'dd/MM/yyyy - HH:mm'
    );
  };

  const abordagem = async () => {
    const posto = await api.get(`/abordagem/${idReq}`);

    if (
      posto.data.sgo_coordenadas_abordagems !== undefined &&
      posto.data.sgo_coordenadas_abordagems.length > 0
    ) {
      setCoordBanco(posto.data.sgo_coordenadas_abordagems[0]);
    }

    // useState({
    //   newGeo: false,
    // }

    setApoioVolante(posto.data.apoiado_por_gerencia_id);
    setDataInicioAbordagem(posto.data.dt_h_inicio);

    setIdAbordagem(posto.data.id);
    setPosto(posto.data.lotacao.gerencia.nome || '');
    setAla(posto.data.lotacao.ala.nome);

    setDataInicio(trataDate(posto.data.dt_h_inicio));
    setDataFim(trataDate(posto.data.dt_h_fim));

    setEstadoOrigem(posto.data.cidade_origem.estado.nome);
    setCidadeOrigem(posto.data.cidade_origem.nome);
    setEstadoDestino(posto.data.cidade_destino.estado.nome);
    setCidadeDestino(posto.data.cidade_destino.nome);

    setSelectGerencia(posto.data.lotacao.gerencia.estrutura_logica);

    setRevisao(posto.data.sgo_revisaos);
  };

  const [ocorrencias, setOcorrencias] = useState([]);

  const [resposta, setResposta] = useState('');
  const [loadingResposta, setLoadingResposta] = useState(false);

  const listaOcorrencias = async () => {
    setLoadingOcorrencias(true);
    const lista = await api.get(`/ocorrencias-abordagem/${idReq}`);

    setLoadingOcorrencias(false);
    setOcorrencias(lista.data);
  };

  const edicaoDeRO = async () => {
    setLoading(true);
    await api.put(`edita-ro/${modalEditarRO}`, {
      ro: editaRO,
    });
    setLoading(false);
    setModalEditarRO(null);
    listaOcorrencias();
  };

  const edicaoDinamica = async () => {
    if (dinamica.ocorrencia_id === '') {
      toast.error('Erro, entre em contato com a administração');
    }
    if (dinamica.dinamica === '') {
      toast.error('Preencha a dinâmica');
      return;
    }

    setLoadingDinamica(true);
    const result = await api.post('/add-dinamica', dinamica);
    if (result.data.ok) {
      toast.success(result.data.ok);
    } else {
      toast.error(result.data.error);
    }
    setLoadingDinamica(false);
    setDinamica({
      ocorrencia_id: null,
      dinamica: null,
    });
    listaOcorrencias();
  };

  const buscaRevisoes = useCallback(async () => {
    try {
      const result = await api.get('/sgo-revisao', {
        params: { abordagem_id: idReq },
      });

      setRevisao(result.data);
    } catch (error) {
      toast.error('Erro ao buscar revisões');
    }
  }, [idReq]);

  const enviarResposta = async (id, resposta) => {
    if (resposta === null || resposta === '') {
      toast.error('Preencha os campos');
      return;
    }
    setLoadingResposta(true);
    const result = await api.put(`/sgo-revisao/${id}`, {
      data_resposta: new Date(),
      comentario_final: resposta,
    });
    setLoadingResposta(false);

    setCollapseId(null);
    buscaRevisoes();
  };

  const insereCoordenadas = async () => {
    const result = await api.post('/coordenadas', {
      latitude: coordenadas.geo[0],
      longitude: coordenadas.geo[1],
      abordagem_id: idReq,
    });
  };

  useEffect(() => {
    permissaoAcesso();
    abordagem();
    // veiculosAbordados();
    // listaAbordados();
    // listaEmpresas();

    listaOcorrencias();
    buscaRevisoes();
  }, [idReq]);

  useEffect(() => {
    if (coordenadas.geo && coordenadas.geo[0] !== undefined) {
      insereCoordenadas();
      setCoordBanco(coordenadas.geo);
    }
  }, [coordenadas]);

  //
  //
  // FUNCOES DO SISTEMA
  //
  //
  //

  return (
    <NavBar>
      <Context.Provider value={[coordenadas, setCoordenadas]}>
        <MDBContainer>
          <MDBRow center className="h4-responsive pb-3">
            Visualização e Edição de Abordagem
          </MDBRow>

          {/* {revisao.length > 0 &&
            revisao.map((rev, index) => (
              <>
                <MDBCard key={index} narrow>
                  <MDBView
                    className={`${
                      rev.data_final !== null
                        ? 'teal darken-2'
                        : rev.comentario_final !== null
                        ? 'blue darken-3'
                        : 'deep-orange'
                    } lighten-1 mb-3 text-center text-md-center pr-0 pr-md-5 py-2`}
                    cascade
                  >
                    <h4 className="text-white font-weight-bolder">
                      Revisão {index + 1}
                    </h4>
                  </MDBView>

                  <MDBCardBody className="pt-0">
                    <MDBRow between>
                      <MDBCol className="text-left">
                        Inicio:
                        {rev.data_inicio &&
                          format(
                            new Date(rev?.data_inicio),
                            ' dd/MM/yyyy HH:mm'
                          )}
                      </MDBCol>
                      <MDBCol className="text-center">
                        Revisor Inicial:
                        {console.log(rev)}
                        {rev?.revisor_inicial?.nome_social}
                      </MDBCol>
                      <MDBCol className="text-right">
                        Motivo: {rev?.sgo_revisao_motivo?.motivo}
                      </MDBCol>
                    </MDBRow>
                    <br />
                    <MDBRow start className="grey lighten-4 pt-3">
                      <MDBCol>Descrição:</MDBCol>
                    </MDBRow>
                    <MDBRow center className="grey lighten-4 pb-3">
                      <MDBCol>{rev?.comentario_inicial}</MDBCol>
                    </MDBRow>

                    {rev?.comentario_final !== null ? (
                      <>
                        <MDBRow className="mt-3 grey lighten-4 pt-3">
                          <MDBCol>
                            Resposta:{' '}
                            {rev?.data_resposta &&
                              format(
                                new Date(rev?.data_resposta),
                                'dd/MM/yyyy HH:mm'
                              )}
                          </MDBCol>
                        </MDBRow>

                        <MDBRow center className=" grey lighten-4 pb-3">
                          <MDBCol>{rev?.comentario_final}</MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ''
                    )}

                    <MDBCollapse id={rev?.id} isOpen={collapseId === rev.id}>
                      <MDBRow>
                        <MDBCol>
                          <MDBInput
                            type="textarea"
                            className="rounded"
                            onChange={e => {
                              // Substitui ";" por "." e "\"" por "'"
                              const updatedValue = e.target.value
                                .replace(/;/g, '.')
                                .replace(/"/g, "'");
                              setResposta(updatedValue);
                            }}
                            value={resposta}
                            background
                            rows="3"
                            outline
                          />
                        </MDBCol>
                      </MDBRow>

                      <MDBRow center>
                        {collapseId !== rev.id ? (
                          ''
                        ) : (
                          <MDBBtn
                            size="sm"
                            color="danger"
                            onClick={() => {
                              setResposta('');
                              setCollapseId(null);
                            }}
                          >
                            <span className="ml-2 font-weight-bolder">
                              Cancelar
                            </span>
                          </MDBBtn>
                        )}
                        <MDBBtn
                          onClick={() => enviarResposta(rev.id, resposta)}
                          size="sm"
                          color="green"
                          disabled={loadingResposta}
                        >
                          {loadingResposta === false ? 'Enviar' : 'Enviando'}
                        </MDBBtn>
                      </MDBRow>
                    </MDBCollapse>
                    <MDBRow end>
                      {collapseId === rev.id ||
                      rev.comentario_final !== null ||
                      rev.data_final !== null ? (
                        ''
                      ) : (
                        <MDBBtn
                          size="sm"
                          onClick={() => {
                            if (collapseId === rev.id) {
                              setResposta('');
                              setCollapseId(null);
                              return;
                            }
                            setResposta('');
                            setCollapseId(rev.id);
                          }}
                        >
                          <span className="ml-2 font-weight-bolder">
                            Responder
                          </span>
                        </MDBBtn>
                      )}
                    </MDBRow>
                  </MDBCardBody>
                </MDBCard>
                <br />
              </>
            ))} */}
          <Revisoes abordagemId={idReq} />
          {/* DADOS DE ABORDAGEM */}
          <MDBCard narrow>
            <MDBView className="mdb-color color-block-5 mb-3  " cascade>
              <span className="d-flex justify-content-between align-items-center text-center text-md-right pr-0 pr-md-5 py-2">
                <MDBBtn
                  onClick={() => {
                    setModalExcluiAbordagem(!modalExcluiAbordagem);
                    setAbordagemSelecionada(idAbordagem);
                  }}
                  className="mx-3"
                  size="sm"
                  color="danger"
                >
                  <MDBIcon fas className="white-text mr-3" icon="trash-alt" />
                  <span>Apagar</span>
                </MDBBtn>

                <h4 className="text-white font-weight-bolder">
                  Abordagem Nº: {idAbordagem}
                </h4>
              </span>
            </MDBView>
            <MDBCardBody className="pt-0">
              <MDBRow>
                <MDBCol size="12" md="3" middle>
                  Posto: {posto}
                </MDBCol>
                <MDBCol className="mt-2 mt-md-0" size="12" md="3" middle>
                  Ala: {ala}
                </MDBCol>
                <MDBCol className="mt-3 mt-md-0" size="12" md="3" middle>
                  <span style={{ fontSize: '14px' }}>Data Início</span>
                  <div>{dataInicio}</div>
                </MDBCol>
                <MDBCol className="mt-3 mt-md-0" size="12" md="3" middle>
                  <span style={{ fontSize: '14px' }}>Data Fim</span>
                  <div>{dataFim}</div>
                </MDBCol>
              </MDBRow>
              <hr />
              <MDBRow>
                <MDBCol size="12" md="6" middle>
                  Origem: {`${estadoOrigem} / ${cidadeOrigem}`}
                </MDBCol>

                <MDBCol size="12" md="6" middle>
                  Destino: {`${estadoDestino} / ${cidadeDestino}`}
                </MDBCol>
              </MDBRow>
              <MDBRow className="mt-2">
                <MDBCol size="12" md="12" middle>
                  Apoiado pela volante: {apoioVolante === null ? 'Não' : 'Sim'}
                </MDBCol>
              </MDBRow>
              <MDBRow end className="mt-3">
                <MDBCol size="12" md="4">
                  <MDBRow center>
                    <MDBBtn
                      color={`${
                        coordenadas.geo === undefined && coordBanco.length === 0
                          ? 'dark-green'
                          : 'danger'
                      }`}
                      className="font-weight-bolder"
                      size="sm"
                      onClick={() => setModalLatLong(!modalLatLong)}
                    >
                      {coordenadas.geo === undefined && coordBanco.length === 0
                        ? 'Inserir Localização'
                        : 'Alterar Localização'}
                    </MDBBtn>
                  </MDBRow>
                  <MDBRow center>
                    {coordBanco.id && coordenadas.geo === undefined ? (
                      <MDBRow center className="d-flex">
                        <MDBCol size="12">
                          <a
                            className="w-100 d-flex align-items-center"
                            target="_blank"
                            href={`https://www.google.com.br/maps/search/?api=1&query=${coordBanco.latitude},${coordBanco.longitude}`}
                          >
                            <MDBIcon fas icon="map-marked-alt" size="2x" />
                            <div className="ml-2">Ver Localização</div>
                          </a>
                        </MDBCol>
                      </MDBRow>
                    ) : coordenadas.geo && coordenadas.geo[0] !== undefined ? (
                      <>
                        <MDBRow>
                          <MDBCol size="12">
                            <a
                              className="w-100 d-flex align-items-center"
                              target="_blank"
                              href={`https://www.google.com.br/maps/search/?api=1&query=${coordenadas?.geo[0]},${coordenadas?.geo[1]}`}
                            >
                              <MDBIcon fas icon="map-marked-alt" size="2x" />
                              <div className="ml-2">Ver Localização</div>
                            </a>
                          </MDBCol>
                        </MDBRow>
                      </>
                    ) : (
                      ''
                    )}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE VEÍCULOS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">
                <span>Veículos</span>
              </h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <Veiculos abordagemId={idReq} />
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE ABORDADOS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Abordados</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <Abordados abordagemId={idReq} />
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE EMPRESAS */}
          <MDBCard narrow>
            <MDBView
              className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
              cascade
            >
              <h4 className="text-white font-weight-bolder">Empresas</h4>
            </MDBView>

            <MDBCardBody className="pt-0">
              <Empresas abordagemId={idReq} />
            </MDBCardBody>
          </MDBCard>
          <br />

          {/* DADOS DE DESCRICAO */}
          {/* <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">
              Dinâmica da Abordagem
            </h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            {dinamicaAbordagem.map(dinamica => (
              <MDBRow className="px-5">{dinamica.dinamica}</MDBRow>
            ))} */}
          {/* <MDBInput
              counter={200}
              outline
              type="textarea"
              rows="3"
              label="Descreva a Dinâmica"
              // value={dinamicaAbordagem}
              // onChange={t => setDinamicaAbordagem(t.target.value)}
            /> */}
          {/* </MDBCardBody>
        </MDBCard>
        <br /> */}
          {loadingOcorrencias ? (
            <div className="d-flex justify-content-center align-items-center mb-5">
              <MDBSpinner />
              <div className="ml-3 d-flex align-items-center font-weight-bolder">
                Carregando Ocorrências
              </div>
            </div>
          ) : (
            ''
          )}

          {loadingOcorrencias === false &&
            ocorrencias.map((oco, index) => (
              <>
                <MDBCard narrow key={oco.id}>
                  <MDBView
                    className="elegant-color color-block-5 mb-3  "
                    cascade
                  >
                    <span className="d-flex justify-content-between align-items-center text-center text-md-right pr-0 pr-md-5 py-2">
                      <MDBBtn
                        onClick={() => {
                          setModalExcluiOcorrencia(!modalExcluiOcorrencia);
                          setOcorrenciaSelecionada(oco.id);
                        }}
                        className="mx-3"
                        size="sm"
                        color="danger"
                      >
                        <MDBIcon
                          fas
                          className="white-text mr-3"
                          icon="trash-alt"
                        />
                        <span>Apagar</span>
                      </MDBBtn>

                      <h4 className="text-white font-weight-bolder">
                        Ocorrência Nº: {oco.id}
                      </h4>
                    </span>
                  </MDBView>

                  <MDBCardBody className="pt-0">
                    <MDBRow between className=" h5-responsive px-3">
                      <MDBCol size="12" md="6">
                        {oco.razao_autuacao.autuado === true
                          ? 'Autuado'
                          : 'Não Autuado'}
                        : {oco.razao_autuacao.descricao}
                      </MDBCol>
                      <MDBCol
                        // className="text-right text-md-center"
                        size="12"
                        md="6"
                      >
                        Tipo: {oco.razao_autuacao.natureza_ocorrencia.nome}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol size="12" md="4" middle>
                        Registro de Ocorrência: Nº:{oco.ro}
                        <MDBIcon
                          className="text-danger ml-2 cursor-pointer"
                          icon="edit"
                          onClick={() => setModalEditarRO(oco.id)}
                        />
                      </MDBCol>
                      <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                        {/* <Select
                  placeholder="Ala"
                  options={listaLotacoes}
                  isLoading={loadLotacao}
                  value={lotacaoSelecionada?.value}
                  onChange={e => {
                    setLotacaoSelecionada(e.value);
                  }}
                /> */}
                        Autuado: {oco.autuado === true ? 'Sim' : 'Não'}
                      </MDBCol>

                      <MDBCol className="mt-3 mt-md-0" size="12" md="4" middle>
                        <span style={{ fontSize: '14px' }}>
                          Data de Criação:
                        </span>
                        <div>{trataDate(oco.createdAt)}</div>
                        {/* <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="datetime-local"
                  onChange={e => {
                    setDataInicio(e.target.value);
                  }}
                /> */}
                      </MDBCol>
                    </MDBRow>

                    <Autuados
                      listar={() => listaOcorrencias()}
                      ocorrencia={oco.autuado_ocorrencia}
                      abordagem_id={idReq}
                      ocorrencia_id={oco.id}
                    />
                    <Autuacoes
                      listar={() => listaOcorrencias()}
                      tipo_ocorrencia={oco}
                      abordagem_id={idReq}
                      ocorrencia_id={oco.id}
                    />
                    <Equipe
                      listar={() => listaOcorrencias()}
                      gerencia={selectGerencia}
                      equipe={oco.equipe_ocorrencia}
                      abordagem_id={idReq}
                      abordagem_data={dataInicioAbordagem}
                      ocorrencia_id={oco.id}
                    />
                    {/* <MDBRow
                    center
                    className="font-weight-bolder"
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                  >
                    Autuações:
                  </MDBRow>

                  {oco.item_grupo_tipo_ocorrencia.map(ocorrencia => (
                    <div
                      style={{
                        backgroundColor: 'rgba(0,0,0,0.08)',
                        borderRadius: '10px',
                      }}
                      className="mt-2"
                    >
                      <MDBRow className="p-2">
                        <MDBCol size="12" md="6" className="m-2">
                          Tipo: {ocorrencia.tipo_ocorrencium.nome}
                        </MDBCol>
                        <MDBCol size="12" md="5" className="m-2">
                          Grupo: {ocorrencia.grupo.nome}
                        </MDBCol>

                        <MDBCol size="12" md="6" className="m-2">
                          Item: {ocorrencia.item.nome}
                        </MDBCol>
                        <MDBCol size="12" md="5" className="m-2">
                          Quantidade: {ocorrencia.quantidade}
                        </MDBCol>
                      </MDBRow>
                    </div>
                  ))}

                  <br /> */}
                    <br />
                    <MDBRow
                      center
                      className="font-weight-bolder"
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                    >
                      Dinâmica da Ocorrência:
                    </MDBRow>
                    {oco.ocorrencia_dinamicas.map(descricao =>
                      descricao.ativo === true ? (
                        <div
                          // style={{
                          //   backgroundColor: 'rgba(0,0,0,0.08)',
                          //   borderRadius: '10px',
                          // }}
                          key={descricao.id}
                          className="mt-2"
                        >
                          <MDBRow className="p-2">
                            <MDBCol>Dinâmica: {descricao.dinamica}</MDBCol>
                          </MDBRow>
                          <MDBRow center>
                            <MDBBtn
                              color="mdb-color"
                              onClick={() => {
                                setDinamica({
                                  ocorrencia_id: oco.id,
                                  dinamica: descricao.dinamica,
                                });

                                // setModalEquipe(true);
                              }}
                            >
                              Alterar Dinâmica
                            </MDBBtn>
                          </MDBRow>
                        </div>
                      ) : (
                        ''
                      )
                    )}
                  </MDBCardBody>
                </MDBCard>
                <br />
              </>
            ))}

          {/* <MDBRow center>
          <MDBBtn
            onClick={() => {
              if (lotacaoSelecionada.value === '') {
                toast.error('Preencha o campo Lotação');
                return;
              }
              if (dataInicio === '') {
                toast.error('Preencha o campo Data Início');
                return;
              }
              if (dataFim === '') {
                toast.error('Preencha o campo Data Fim');
                return;
              }
              if (dataFim < dataInicio) {
                toast.error('Data Início maior que Data Fim');
                return;
              }
              if (cidadeOrigemSelecionada.value === '') {
                toast.error('Preencha o Campo Cidade Origem');
                return;
              }
              if (cidadeDestinoSelecionada.value === '') {
                toast.error('Preencha o Campo Cidade Destino');
                return;
              }
              if (dinamicaAbordagem === '') {
                toast.error('Preencha o Campo Dinâmica');
                return;
              }
              enviarAbordagem();
              setModalConcluir(true);
            }}
            color="dark-green"
          >
            Concluir
          </MDBBtn>
        </MDBRow> */}

          {/* modal de abordagem */}
          <MDBModal
            isOpen={modalExcluiAbordagem}
            size="lg"
            toggle={() => {
              setModalExcluiAbordagem(false);
              setAbordagemSelecionada('');
            }}
            // onClick={() => setAbordagemSelecionada('')}
          >
            <MDBModalHeader
              className="grey lighten-4"
              toggle={() => {
                setModalExcluiAbordagem(false);
                setAbordagemSelecionada('');
              }}
              // onClick={() => setAbordagemSelecionada('')}
            >
              <span className="text-weight-bold danger-text">ATENÇÃO</span>
            </MDBModalHeader>
            <MDBModalBody className="h4-responsive my-5 text-center">
              Você realmente deseja apagar esta abordagem e todas as ocorrências
              feitas a partir dela ?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setModalExcluiAbordagem(false);
                  setAbordagemSelecionada('');
                }}
              >
                NÃO
              </MDBBtn>
              <MDBBtn
                onClick={() => excluiAbordagem(abordagemSelecionada)}
                color="primary"
              >
                SIM
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/*  */}
          {/*  */}
          {/* modal de ocorrencia */}
          <MDBModal
            isOpen={modalExcluiOcorrencia}
            size="lg"
            toggle={() => {
              setModalExcluiOcorrencia(false);
              setOcorrenciaSelecionada('');
            }}
            // onClick={() => setOcorrenciaSelecionada('')}
          >
            <MDBModalHeader
              className="grey lighten-4"
              toggle={() => {
                setModalExcluiOcorrencia(false);
                setOcorrenciaSelecionada('');
              }}
              // onClick={() => setOcorrenciaSelecionada('')}
            >
              <span className="text-weight-bold danger-text">ATENÇÃO</span>
            </MDBModalHeader>
            <MDBModalBody className="h4-responsive my-5 text-center">
              Deseja realmente apagar esta ocorrência?
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setModalExcluiOcorrencia(false);
                  setOcorrenciaSelecionada('');
                }}
              >
                NÃO
              </MDBBtn>
              <MDBBtn
                onClick={() => excluiOcorrencia(ocorrenciaSelecionada)}
                color="primary"
              >
                SIM
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* modal editar numero de ocorrencia */}
          <MDBModal
            isOpen={modalEditarRO !== null}
            size="lg"
            toggle={() => {
              setModalEditarRO(null);
            }}
            // onClick={() => setOcorrenciaSelecionada('')}
          >
            <MDBModalHeader
              className="grey lighten-4"
              toggle={() => {
                setModalEditarRO(null);
              }}
              // onClick={() => setOcorrenciaSelecionada('')}
            >
              <span className="text-weight-bold danger-text">
                Editar Registro de Ocorrência
              </span>
            </MDBModalHeader>
            <MDBModalBody center className="h4-responsive my-5 text-center">
              <MDBRow center>
                <MDBCol size="12" md="8">
                  <MDBInput
                    onChange={t => setEditaRO(t.target.value)}
                    maxLength="15"
                    label="Insira o novo número de ocorrência"
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setModalEditarRO(null);
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                disabled={loading}
                onClick={() => edicaoDeRO()}
                color="success"
              >
                {loading ? 'Carregando' : 'Editar'}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* alterar dinamica */}
          <MDBModal
            isOpen={dinamica.ocorrencia_id !== null}
            size="lg"
            toggle={() => {
              setDinamica({
                ocorrencia_id: null,
                dinamica: null,
              });
            }}
            // onClick={() => setOcorrenciaSelecionada('')}
          >
            <MDBModalHeader
              className="grey lighten-4"
              toggle={() => {
                setDinamica({
                  ocorrencia_id: null,
                  dinamica: null,
                });
              }}
              // onClick={() => setOcorrenciaSelecionada('')}
            >
              <span className="text-weight-bold danger-text">
                Editar Dinâmica da Ocorrência
              </span>
            </MDBModalHeader>
            <MDBModalBody center className="h4-responsive my-5 text-center">
              <MDBRow center>
                <MDBCol size="12" md="12">
                  <MDBInput
                    outline
                    type="textarea"
                    counter={2000}
                    maxLength={2000}
                    // className={
                    //   dinamica.dinamica.length > 600 ? ' is-invalid' : ''
                    // }
                    rows="6"
                    label="Descreva a Dinâmica"
                    value={dinamica.dinamica}
                    onChange={t =>
                      setDinamica({
                        ocorrencia_id: dinamica.ocorrencia_id,
                        dinamica: t.target.value,
                      })
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                color="secondary"
                onClick={() => {
                  setDinamica({
                    ocorrencia_id: null,
                    dinamica: null,
                  });
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                disabled={loadingDinamica}
                onClick={() => edicaoDinamica()}
                color="success"
              >
                {loadingDinamica ? 'Carregando' : 'Editar'}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>

          {/* MODAL PARA CRIAR LATLONG */}
          <MDBModal
            isOpen={modalLatLong}
            toggle={() => {
              setModalLatLong({
                modalLatLong: !modalLatLong,
              });
            }}
          >
            <MDBModalHeader
              toggle={() => {
                setModalLatLong(!modalLatLong);
              }}
            >
              Insira no mapa
            </MDBModalHeader>
            <MDBModalBody className="text-danger font-weight-bolder">
              <MDBRow center>
                <MDBCol>
                  <ExternalStateExample />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter between>
              <MDBBtn
                color="danger"
                onClick={() => {
                  setModalLatLong(!modalLatLong);
                }}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                onClick={() => {
                  setCoordenadas({
                    newGeo: true,
                  });
                  setModalLatLong(!modalLatLong);
                }}
                color="success"
              >
                Salvar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>

        {/* MODAL PARA CRIACAO DE VEICULOS */}
        {/* <MDBModal
        isOpen={modalVeiculo}
        toggle={() => {
          setModalVeiculo(!modalVeiculo);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalVeiculo(!modalVeiculo);
          }}
        >
          Inserir Veículo Abordado
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <MDBSwitch
              checked={switchChassi}
              labelLeft="Placa"
              labelRight="Chassi"
              onChange={() => {
                setSwitchChassi(!switchChassi);
                setAbordagemNumeroPlacaOuChassi('');
              }}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" middle>
              <MDBInput
                maxlength={switchChassi === false ? '7' : '17'}
                type="text"
                placeholder={switchChassi === false ? 'Placa' : 'Chassi'}
                onChange={e => setAbordagemNumeroPlacaOuChassi(e.target.value)}
                value={abordagemNumeroPlacaOuChassi}
              />
            </MDBCol>

            <MDBCol size="12" middle>
              <div>Tipo de Veículo:</div>
              <Select
                placeholder="Tipo"
                options={selectVeiculos}
                onChange={e => {
                  setAbordagemTipo(e.value);
                  setAbordagemTipoLabel(e.label);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalVeiculo(!modalVeiculo);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              adicionaVeiculo();
            }}
            color="success"
          >
            Cadastrar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA DELETAR VEICULOS */}
        {/* <MDBModal
        isOpen={modalDeleteVeiculo}
        toggle={() => {
          setModalDeleteVeiculos({
            modalDeleteVeiculo: !modalDeleteVeiculo,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteVeiculos(!modalDeleteVeiculo);
          }}
        >
          Deletar Veículo Abordado
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse veículo?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteVeiculos(!modalDeleteVeiculo);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarVeiculo(idDelecaoVeiculo);
              setModalDeleteVeiculos(!modalDeleteVeiculo);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA CRIAÇÃO DE ABORDADO */}
        {/* <MDBModal
        size="lg"
        isOpen={modalAbordado}
        toggle={() => {
          setModalAbordado(!modalAbordado);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAbordado(!modalAbordado);
          }}
        >
          Inserir Pessoa Abordada
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className="w-100 font-weight-light md-form form-group form-lg"
                type="text"
                placeholder="CPF"
                onChange={e =>
                  setAbordadoCpf(e.target.value.replace(/\D+/g, ''))
                }
                value={abordadoCpf}
                mask="999.999.999-99"
                maskChar=" "
              />
            </MDBCol>
            <MDBCol size="12" md="4" middle>
              <MDBInput
                type="text"
                label="Nome"
                onChange={e => setAbordadoNome(e.target.value)}
                value={abordadoNome}
              />
            </MDBCol>

            <MDBCol size="12" md="4" middle>
              <Select
                placeholder="Função"
                options={[
                  { label: 'Condutor', value: 'Condutor' },
                  { label: 'Chapa', value: 'Chapa' },
                  { label: 'Carona', value: 'Carona' },
                  { label: 'Parentes', value: 'Parentes' },
                  { label: 'Cônjuges', value: 'Cônjuges' },
                ]}
                onChange={e => {
                  setAbordadoTipo(e.label);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow center>
            <div className="text-center">
              <span className="h4-responsive">Condutor do veículo</span>

              <MDBSwitch
                checked={switchCondutor}
                labelLeft="Não"
                labelRight="Sim"
                onChange={() => {
                  setSwitchCondutor(!switchCondutor);
                }}
              />
            </div>
          </MDBRow>
          {switchCondutor === true ? (
            <MDBAnimation type="zoomIn" duration="500ms">
              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="text"
                    label="CNH"
                    onChange={e => setAbordadoCnh(e.target.value)}
                    value={abordadoCnh}
                  />
                </MDBCol>
                <MDBCol size="12" md="4" middle>
                  <Select
                    placeholder="Categoria"
                    options={[
                      { label: 'A', value: 'A' },
                      { label: 'B', value: 'B' },
                      { label: 'C', value: 'C' },
                      { label: 'D', value: 'D' },
                      { label: 'E', value: 'E' },
                      { label: 'AB', value: 'AB' },
                      { label: 'AC', value: 'AC' },
                      { label: 'AD', value: 'AD' },
                      { label: 'AE', value: 'AE' },
                    ]}
                    onChange={e => {
                      setAbordadoCategoria(e.value);
                    }}
                  />
                </MDBCol>

                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="date"
                    label="Validade"
                    onChange={e => setAbordadoValidade(e.target.value)}
                    value={abordadoValidade}
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          ) : (
            ''
          )}
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalAbordado(!modalAbordado);
            }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              adicionaAbordado();
            }}
            className="btn btn-success"
          >
            Cadastrar
          </button>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA DELETAR VEICULOS */}
        {/* <MDBModal
        isOpen={modalDeleteAbordado}
        toggle={() => {
          setModalDeleteAbordado({
            modalDeleteAbordado: !modalDeleteAbordado,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteAbordado(!modalDeleteAbordado);
          }}
        >
          Deletar Pessoa Abordada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse abordado?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteAbordado(!modalDeleteAbordado);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarAbordado(idDelecaoVeiculo);
              setModalDeleteAbordado(!modalDeleteAbordado);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA CRIAÇÃO DE EMPRESAS */}
        {/* <MDBModal
        size="lg"
        isOpen={modalEmpresa}
        toggle={() => {
          setModalEmpresa(!modalEmpresa);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalEmpresa(!modalEmpresa);
          }}
        >
          Inserir Empresa Abordada
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className="w-100 font-weight-light md-form form-group form-lg"
                type="text"
                placeholder="CNPJ"
                onChange={e => setCnpj(e.target.value.replace(/\D+/g, ''))}
                value={cnpj}
                mask="99.999.999/9999-99"
                maskChar=" "
              />
            </MDBCol>
            <MDBCol size="12" md="8" middle>
              <MDBInput
                type="text"
                label="Nome"
                onChange={e => setEmpresaNome(e.target.value)}
                value={empresaNome}
              />
            </MDBCol>
          </MDBRow>
          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalEmpresa(!modalEmpresa);
            }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              adicionaEmpresa();
            }}
            className="btn btn-success"
          >
            Cadastrar
          </button>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA DELETAR EMPRESAS */}
        {/* <MDBModal
        isOpen={modalDeleteEmpresa}
        toggle={() => {
          setModalDeleteEmpresa({
            modalDeleteEmpresa: !modalDeleteEmpresa,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteEmpresa(!modalDeleteEmpresa);
          }}
        >
          Deletar Empresa Abordada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar essa empresa?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteEmpresa(!modalDeleteEmpresa);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarEmpresa(idDelecaoEmpresa);
              setModalDeleteEmpresa(!modalDeleteEmpresa);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA CRIAÇÃO DE NOTAS */}
        {/* <MDBModal
        size="lg"
        isOpen={modalNota}
        toggle={() => {
          setModalNota(!modalNota);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalNota(!modalNota);
          }}
        >
          Inserir Nota Fiscal
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow center>
            <div className="text-center">
              <span className="h4-responsive">Condutor do veículo</span>

              <MDBSwitch
                checked={switchTipoNota}
                labelLeft="Nota Fiscal"
                labelRight="Manifesto"
                onChange={() => {
                  setSwitchTipoNota(!switchTipoNota);
                }}
              />
            </div>
          </MDBRow>
          {switchTipoNota === false ? (
            <MDBAnimation>
              <MDBRow>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Danfe"
                    onChange={e => setNumero(e.target.value)}
                    value={numero}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Chave"
                    onChange={e => setChave(e.target.value)}
                    value={chave}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Valor"
                    onChange={e => setValor(e.target.value.replace(',', '.'))}
                    value={valor}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="ICMS"
                    onChange={e => setIcms(e.target.value.replace(',', '.'))}
                    value={icms}
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          ) : (
            <MDBAnimation>
              <MDBRow>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Número do Manifesto"
                    onChange={e => setNumero(e.target.value)}
                    value={numero}
                  />
                </MDBCol>
                <MDBCol size="12" middle>
                  <MDBInput
                    type="text"
                    label="Chave"
                    onChange={e => setChave(e.target.value)}
                    value={chave}
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          )}

          <br />
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalNota(!modalNota);
            }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              adicionaNota();
            }}
            className="btn btn-success"
          >
            Cadastrar
          </button>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA DELETAR NOTAS */}
        {/* <MDBModal
        isOpen={modalDeleteNota}
        toggle={() => {
          setModalDeleteNota({
            modalDeleteNota: !modalDeleteNota,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteNota(!modalDeleteNota);
          }}
        >
          Deletar Nota Fiscal
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar essa nota fiscal?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteNota(!modalDeleteNota);
            }}
          >
            Cancelar
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              deletarNota(idEmpresaParaNota, idDelecaoNota);
              setModalDeleteNota(!modalDeleteNota);
            }}
            color="warning"
          >
            Deletar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal> */}

        {/* MODAL PARA CONCLUIR CADASTRO */}
        {/* <MDBModal
        isOpen={modalConcluir}
        toggle={() => {
          setModalConcluir({
            modalConcluir: !modalConcluir,
          });
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalConcluir(!modalConcluir);
          }}
        >
          Concluir Cadastro
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Deseja Criar um Ocorrência para esta abordagem
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              criaOcorrencia();
              setModalConcluir(!modalConcluir);
            }}
          >
            Criar Ocorrência
          </MDBBtn>
          <MDBBtn
            onClick={() => {
              // deletarNota(idEmpresaParaNota, idDelecaoNota);
              // setModalDeleteNota(!modalDeleteNota);

              finalizaAbordagem();
              setModalConcluir(!modalConcluir);
            }}
            color="success"
          >
            Finalizar
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
     */}
      </Context.Provider>
    </NavBar>
  );
}
