import React, { useState, useEffect } from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBCol,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavMenu from '../../components/NavMenu';

import Api from '../../services/api';

import efetivo from '../../assets/efetivo.jpg';
import alocacao from '../../assets/alocacao.jpg';
import frota2 from '../../assets/frota2.jpg';

import gerenciaImg from '../../assets/gerencia.jpg';
// import cargosImg from '../../assets/cargos.jpg';
import ocorrenciaImg from '../../assets/ocorrencia.jpg';
// import frotaImg from '../../assets/frota.jpg';
import ocrImg from '../../assets/ocr.jpg';
import monitoramento from '../../assets/monitoramento.png';
import parteDiaria from '../../assets/partediaria.png';
import nuint from '../../assets/nuint2.png';

// import { Container } from './styles';

export default function App() {
  const [modulos, setModulos] = useState([]);

  const teste = async () => {
    const response = await Api.get('/permissao-de-acesso');

    setModulos(response.data);
  };

  useEffect(() => {
    // teste()
  }, []);

  // modulos.map((modulo)=> {
  //   // modulo.map((acesso) => {
  //     modulo.acessos.map((acessos) => {
  //       console.log(acessos.modulo)
  //     })
  //   // })
  //   })

  return (
    <>
      <NavMenu />
      <MDBContainer fluid>
        {/* espacamento  */}

        <MDBRow className="d-flex justify-content-start">
          <MDBCol col="12" md="6">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${efetivo})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE EFETIVO</MDBCardTitle>
              <MDBCardText className="grey-text text-left">
                Registrar o ingresso do servidor na Secretária de Governo.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around  pt-3 mt-3"
              >
                <Link
                  to="/usuario/add"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/estatisticas"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol col="12" md="6">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${alocacao})`,
                backgroundSize: 'cover',
              }}
              // className="justify-content-between card-body mr-2 ml-2 mt-3 mt-md-0 blue-grey-text text-center"
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">ALOCAÇÃO</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Criação e Gerenciamento das operaçoes e suboperações.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/gerencias"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Gerência
                  </span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
                <Link
                  to="/lotacao"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Lotação</span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
                {/* <Link
              to="/alocacao"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Alocação</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link> */}

                <Link
                  to="/escala-calendario"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Calendário
                  </span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="d-flex justify-content-start">
          <MDBCol col="12" md="4" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${ocorrenciaImg})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  mt-md-0 blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE SERVIÇO</MDBCardTitle>
              <MDBCardText className="grey-text text-left">
                Registrar o ingresso do servidor na Secretária de Governo.
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around  pt-3 mt-3"
              >
                <Link
                  to="/controle-de-presenca"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Controle de presença
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                {/* <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Estatísticas
                </span>
                <MDBIcon icon="chart-pie" size="2x" />
              </Link> */}
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
          <MDBCol col="12" md="8" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${parteDiaria})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">PARTE DIÁRIA</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Alterações diárias como: falta de servidores, avaria veicular,
                acidente e etc.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="d-flex justify-content-start">
          <MDBCol col="12" md="4" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${parteDiaria})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE PATRIMÔNIO</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Registrar o ingresso de novos cargos e administrar os
                existentes.
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3 "
              >
                <Link
                  to="/add-patrimonio"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/patrimonio"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
          <MDBCol col="12" md="4" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${frota2})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE FROTA</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Controle dos veículos empregados na Secretária de Governo.
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3 "
              >
                <Link
                  to="/add-frota"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Veículos
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/frota"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Relatórios
                  </span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol col="12" md="4" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${ocrImg})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">
                ANÁLISES DE CAMERAS OCR
              </MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Análise dos meta dados das câmeras OCR nas rodovias do estado.
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="d-flex justify-content-center">
          <MDBCol col="12" md="8" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${ocorrenciaImg})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">
                GESTÃO DE PRESTAÇÃO DE SERVIÇOS
              </MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Sistema de acompanhamento dos serviços prestados por empresas
                contratadas, fazendo a gestão do Acordo do Nivel de Serviço
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
          <MDBCol col="12" md="4" sm="12">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${monitoramento})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">MONITORAMENTO</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Controle de cameras dos postos fiscais no CICC, criação de
                relatórios e despacho de demandas.
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3 "
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Veículos
                  </span>
                  <MDBIcon icon="car" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Relatórios
                  </span>

                  <MDBIcon far icon="clipboard" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow className="d-flex justify-content-center">
          <MDBCol>
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${nuint})`,
                backgroundSize: 'cover',
                opacity: '0.5',
              }}
              className="justify-content-between card-body white-text text-center"
            >
              <MDBCardTitle className="h5"> </MDBCardTitle>
              <br />
              <br />
              <br />
              <MDBCardText className="grey-text text-left" />

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center white-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center white-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center white-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>

          <MDBCol>
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${gerenciaImg})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">DENÚNCIAS</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Informações de denúncias da Central Disque Denúncia
                <br />
                <br />
              </MDBCardText>
              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <br />
        <br />
      </MDBContainer>
    </>
  );
}
