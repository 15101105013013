import React from 'react';
import { useState, useEffect } from 'react';
// import Api from '../../services/api';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBSwitch,
  MDBModalFooter,
  MDBIcon,
  MDBSpinner,
  MDBAnimation,
  toast,
} from 'mdbreact';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import api from '../../../../services/api';
import { validarCPF } from '../../../../components/ValidarCpf';

export default function Abordados(props) {
  // LOADING
  const [loadingListar, setLoadingListar] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [loadingDeletar, setLoadingDeletar] = useState(false);

  // LISTA DE ABORDADOS
  const [abordados, setAbordados] = useState([]);

  // MODAL DE ABORDADOS
  const [modalAbordado, setModalAbordado] = useState(false);
  const [abordadoCpf, setAbordadoCpf] = useState('');
  const [abordadoNome, setAbordadoNome] = useState('');
  const [abordadoTipo, setAbordadoTipo] = useState('');

  const [modalDeleteAbordado, setModalDeleteAbordado] = useState('');

  // CONDUTOR DO VEÍCULO ABORDADO
  const [switchCondutor, setSwitchCondutor] = useState(false);
  const [abordadoCnh, setAbordadoCnh] = useState('');
  const [abordadoCategoria, setAbordadoCategoria] = useState(null);
  const [abordadoValidade, setAbordadoValidade] = useState('');

  // EDITAR ABORDADO
  const [abordadoUpdate, setAbordadoUpdate] = useState(null);
  const [abordadoEstrangeiro, setAbordadoEstrangeiro] = useState(false);
  const listaAbordados = async () => {
    setLoadingListar(true);
    const lista = await api.get(`/abordados-abordagem/${props.abordagemId}`, {
      params: { ativo: true },
    });

    setLoadingListar(false);
    setAbordados(lista.data);
  };

  // FUNCOES DE ABORDADOS
  // ADICIONA ABORDADO
  const adicionaAbordado = async () => {
    if (abordadoNome === '') {
      toast.error('Preencha o campo Nome');
      return;
    }
    if (abordadoEstrangeiro === false) {
      if (abordadoCpf !== '' && abordadoCpf.length !== 11) {
        toast.error('CPF deve conter 11 números');
        return;
      }
      if (validarCPF(abordadoCpf) === false) {
        toast.error('CPF Inválido');
        return;
      }
    }

    if (abordadoNome.length > 150) {
      toast.error('Limite de Caracteres excedido');
      return;
    }
    if (abordadoTipo === '') {
      toast.error('Preencha o campo Função');
      return;
    }
    if (switchCondutor === true) {
      if (abordadoCnh === '') {
        toast.error('Preencha o campo CNH');
        return;
      }
      if (abordadoCategoria === '' || abordadoCategoria === null) {
        toast.error('Preencha o campo Categoria');
        return;
      }
      if (abordadoValidade === '') {
        toast.error('Preencha o campo Validade');
        return;
      }
    }

    const condutor = {};

    if (switchCondutor === true) {
      condutor.condutor = true;
      condutor.cnh = abordadoCnh;
      condutor.cnh_categoria = abordadoCategoria.value;
      condutor.cnh_validade = parseISO(abordadoValidade);
    }

    // setAddAbordado({
    //   // abordagemTipoPlacaChassi: switchChassi === false ? 'Placa' : 'Chassi',
    //   cpf: abordadoCpf,
    //   nome: abordadoNome,
    //   tipo: abordadoTipo,
    //   abordagem_id: modalDeleteAbordado,
    //   ...condutor,
    // });
    setLoadingEnviar(true);
    const newUser = await api.post('/abordado-abordagem', {
      cpf: abordadoCpf,
      nome: abordadoNome,
      tipo: abordadoTipo,
      abordagem_id: props.abordagemId,
      ...condutor,
    });

    setAbordadoCpf('');
    setAbordadoNome('');
    setAbordadoTipo('');
    // setSwitchCondutor(false)
    setAbordadoCnh('');
    setAbordadoCategoria('');
    setAbordadoValidade('');

    setModalAbordado(false);
    setLoadingEnviar(false);
    listaAbordados();
  };

  // REMOVE ABORDADO
  const deletarAbordado = async idAbordado => {
    let semAbordados = 0;

    abordados.forEach(t => {
      if (t.ativo === true) {
        semAbordados++;
      }
    });

    if (semAbordados === 1) {
      toast.error('Precisa conter pelo menos 1 abordado');
      return;
    }

    setLoadingDeletar(true);
    const deletado = await api.delete(`/abordados-abordagem/${idAbordado}`);

    if (deletado.data.error) {
      toast.error(deletado.data.error);
      setLoadingDeletar(false);
      return;
    }
    if (deletado.data.ok) {
      toast.success(deletado.data.ok);
    }

    setLoadingDeletar(false);
    setModalDeleteAbordado('');
    listaAbordados();
  };

  const alteraAbordado = async () => {
    setLoadingDeletar(true);
    const { id, ...resto } = abordadoUpdate;
    const data = parseISO(abordadoUpdate.cnh_validade);
    const alterado = await api.put(`/abordado-abordagem/${id}`, {
      ...resto,
      cnh_validade: data,
    });
    if (abordadoEstrangeiro === false) {
      if (abordadoUpdate.cpf !== '' && abordadoUpdate.cpf.length !== 11) {
        toast.error('CPF deve conter 11 números');
        return;
      }
      if (validarCPF(abordadoUpdate.cpf) === false) {
        toast.error('CPF Inválido');
        return;
      }
    }

    if (alterado.data.error) {
      toast.error(alterado.data.error);
      return;
    }
    if (alterado.data.ok) {
      toast.success(alterado.data.ok);
    }

    setLoadingDeletar(false);
    setAbordadoUpdate(null);
    listaAbordados();
  };

  useEffect(() => {
    listaAbordados();
    // listaTiposVeiculos();
  }, [props.abordagemId]);

  return (
    <>
      {loadingListar === true ? (
        <div className="d-flex justify-content-center align-items-center">
          <MDBSpinner />
          <div className="ml-3 d-flex align-items-center font-weight-bolder">
            Carregando
          </div>
        </div>
      ) : abordados.length === 0 ? (
        <MDBRow center>Sem Abordados</MDBRow>
      ) : (
        abordados.map((abordado, index) => (
          <div key={`b${index}`}>
            <MDBRow
              // style={{ border: '1px solid silver' }}
              style={{
                backgroundColor: 'rgba(0,0,0,0.1)',
                borderTop: '1px solid rgba(0,0,0,0.1)',
              }}
              between
            >
              <MDBCol size="12" md="3">
                <span className="font-weight-bolder  mr-3">CPF:</span>
                <span>{abordado.cpf}</span>
              </MDBCol>

              <MDBCol size="12" md="5">
                <span className=" font-weight-bolder  mr-3">Nome:</span>
                <span>{abordado.nome}</span>
              </MDBCol>
              <MDBCol size="12" md="3">
                <span className=" font-weight-bolder  mr-3">Tipo:</span>
                <span>{abordado.tipo}</span>
              </MDBCol>
            </MDBRow>
            {abordado.condutor === true ? (
              <MDBRow
                key={`c${index}`}
                // style={{ border: '1px solid silver' }}
                style={{
                  backgroundColor: 'rgba(0,0,0,0.1)',
                }}
                between
              >
                <MDBCol size="12" md="3">
                  <span className="font-weight-bolder  mr-3">CNH:</span>
                  <span>{abordado.cnh}</span>
                </MDBCol>
                <MDBCol size="12" md="5">
                  <span className="font-weight-bolder  mr-3">Categoria:</span>
                  <span>{abordado.cnh_categoria}</span>
                </MDBCol>
                <MDBCol size="12" md="3">
                  <span className="font-weight-bolder  mr-3">Validade:</span>
                  <span>
                    {format(new Date(abordado.cnh_validade), 'dd/MM/yyyy')}
                  </span>
                </MDBCol>
              </MDBRow>
            ) : (
              ''
            )}

            <MDBRow
              center
              style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
              className="py-3 mb-2 d-print-none"
            >
              <div className="col-12 col-md-4 mt-3 mt-md-0 d-flex font-weight-bold justify-content-center ">
                <div
                  style={{ cursor: 'pointer' }}
                  className="danger-text mx-3"
                  onClick={() => {
                    setAbordadoUpdate({
                      id: abordado.id,
                      abordagem_id: abordado.abordagem_id,
                      cpf: abordado.cpf,
                      nome: abordado.nome,
                      tipo: abordado.tipo,
                      condutor: abordado.condutor,
                      cnh: abordado.cnh,
                      cnh_categoria: abordado.cnh_categoria,
                      cnh_validade: abordado.cnh_validade,
                    });
                    setSwitchCondutor(abordado.condutor);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="edit" size="1x" />
                  <span style={{ fontSize: '14px' }}>Alterar</span>
                </div>

                <div
                  style={{ cursor: 'pointer' }}
                  className="danger-text mx-3"
                  onClick={() => {
                    setModalDeleteAbordado(abordado.id);
                    // setIdDelecaoAbordado(index);
                    // deletarVeiculo(index);
                    // console.log(abordado);
                  }}
                >
                  <MDBIcon className="mr-1 " far icon="trash-alt" size="1x" />
                  <span style={{ fontSize: '14px' }}>Remover</span>
                </div>
              </div>
            </MDBRow>
          </div>
        ))
      )}

      <br />
      <MDBRow center className="d-print-none">
        <MDBBtn
          color="mdb-color"
          onClick={() => {
            setModalAbordado(!modalAbordado);
          }}
        >
          Adicionar Abordado
        </MDBBtn>
      </MDBRow>

      {/* MODAL PARA CRIAÇÃO DE ABORDADO */}
      <MDBModal
        size="lg"
        isOpen={modalAbordado}
        toggle={() => {
          setModalAbordado(!modalAbordado);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalAbordado(!modalAbordado);
          }}
        >
          Inserir Pessoa Abordada
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBInput
              containerClass="form-check checkbox-teal"
              labelClass="form-check-label"
              label="Abordado Estrangeiro"
              type="checkbox"
              id="cpf"
              checked={abordadoEstrangeiro}
              onClick={e => {
                if (abordadoEstrangeiro === false) {
                  setAbordadoCpf('');
                }
                setAbordadoEstrangeiro(!abordadoEstrangeiro);
              }}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className={`w-100 font-weight-light md-form form-group form-lg ${abordadoEstrangeiro ===
                  true && 'red lighten-5'}`}
                disabled={abordadoEstrangeiro}
                type="text"
                placeholder="CPF"
                onChange={e =>
                  setAbordadoCpf(e.target.value.replace(/\D+/g, ''))
                }
                value={abordadoCpf}
                mask="999.999.999-99"
                maskChar=" "
              />
            </MDBCol>
            <MDBCol size="12" md="4" middle>
              <MDBInput
                type="text"
                label="Nome"
                counter={150}
                onChange={e => setAbordadoNome(e.target.value)}
                value={abordadoNome}
              />
            </MDBCol>

            <MDBCol size="12" md="4" middle>
              <Select
                placeholder="Função"
                options={[
                  { label: 'Condutor', value: 'Condutor' },
                  { label: 'Chapa', value: 'Chapa' },
                  { label: 'Carona', value: 'Carona' },
                  { label: 'Parentes', value: 'Parentes' },
                  { label: 'Cônjuges', value: 'Cônjuges' },
                  { label: 'Passageiro', value: 'Passageiro' },
                ]}
                onChange={e => {
                  setAbordadoTipo(e.label);
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow center>
            <div className="text-center">
              <span className="h4-responsive">Condutor do veículo</span>

              <MDBSwitch
                checked={switchCondutor}
                labelLeft="Não"
                labelRight="Sim"
                onChange={() => {
                  setSwitchCondutor(!switchCondutor);
                }}
              />
            </div>
          </MDBRow>
          {switchCondutor === true ? (
            <MDBAnimation type="zoomIn" duration="500ms">
              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="text"
                    label="CNH"
                    maxLength="15"
                    onChange={e => setAbordadoCnh(e.target.value)}
                    value={abordadoCnh}
                  />
                </MDBCol>
                <MDBCol size="12" md="4" middle>
                  <Select
                    placeholder="Categoria"
                    options={[
                      { label: 'A', value: 'A' },
                      { label: 'B', value: 'B' },
                      { label: 'C', value: 'C' },
                      { label: 'D', value: 'D' },
                      { label: 'E', value: 'E' },
                      { label: 'AB', value: 'AB' },
                      { label: 'AC', value: 'AC' },
                      { label: 'AD', value: 'AD' },
                      { label: 'AE', value: 'AE' },
                    ]}
                    isClearable={true}
                    value={abordadoCategoria}
                    onChange={e => {
                      setAbordadoCategoria(e);
                    }}
                  />
                </MDBCol>

                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="date"
                    label="Validade"
                    onChange={e => setAbordadoValidade(e.target.value)}
                    value={abordadoValidade}
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          ) : (
            ''
          )}
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setModalAbordado(!modalAbordado);
            }}
          >
            Cancelar
          </button>

          <MDBBtn
            onClick={() => {
              adicionaAbordado();
            }}
            disabled={loadingEnviar}
            color="success"
          >
            {loadingEnviar ? 'Carregando...' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA EDIÇÃO DE ABORDADO */}
      <MDBModal
        size="lg"
        isOpen={abordadoUpdate !== null}
        toggle={() => {
          setAbordadoUpdate(null);
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setAbordadoUpdate(null);
          }}
        >
          Alterar Pessoa Abordada
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBInput
              containerClass="form-check checkbox-teal"
              labelClass="form-check-label"
              label="Abordado Estrangeiro"
              type="checkbox"
              id="cpf"
              checked={abordadoEstrangeiro}
              onClick={e => {
                if (abordadoEstrangeiro === false) {
                  setAbordadoUpdate({
                    ...abordadoUpdate,
                    cpf: '',
                  });
                }
                setAbordadoEstrangeiro(!abordadoEstrangeiro);
              }}
            />
          </MDBRow>
          <MDBRow>
            <MDBCol size="12" md="4" middle>
              <InputMask
                style={{
                  border: 0,
                  borderBottom: '1px solid #ced4da',
                  fontSize: '1.25rem',
                  marginTop: '35px',
                }}
                className={`w-100 font-weight-light md-form form-group form-lg ${abordadoEstrangeiro ===
                  true && 'red lighten-5'}`}
                disabled={abordadoEstrangeiro}
                type="text"
                placeholder="CPF"
                onChange={e => {
                  setAbordadoUpdate({
                    ...abordadoUpdate,
                    cpf: e.target.value.replace(/\D+/g, ''),
                  });
                }}
                value={abordadoUpdate?.cpf}
                mask="999.999.999-99"
                maskChar=" "
              />
            </MDBCol>
            <MDBCol size="12" md="4" middle>
              <MDBInput
                type="text"
                label="Nome"
                counter={150}
                onChange={e => {
                  setAbordadoUpdate({
                    ...abordadoUpdate,
                    nome: e.target.value,
                  });
                }}
                value={abordadoUpdate?.nome}
              />
            </MDBCol>

            <MDBCol size="12" md="4" middle>
              <Select
                placeholder="Função"
                options={[
                  { label: 'Condutor', value: 'Condutor' },
                  { label: 'Chapa', value: 'Chapa' },
                  { label: 'Carona', value: 'Carona' },
                  { label: 'Parentes', value: 'Parentes' },
                  { label: 'Cônjuges', value: 'Cônjuges' },
                  { label: 'Passageiro', value: 'Passageiro' },
                ]}
                value={{ label: abordadoUpdate?.tipo }}
                onChange={e => {
                  setAbordadoUpdate({
                    ...abordadoUpdate,
                    tipo: e.label,
                  });
                }}
              />
            </MDBCol>
          </MDBRow>
          <br />
          <MDBRow center>
            <div className="text-center">
              <span className="h4-responsive">Condutor do veículo</span>

              <MDBSwitch
                checked={switchCondutor}
                labelLeft="Não"
                labelRight="Sim"
                // onChange={() => {
                // setAbordadoUpdate({
                //   ...abordadoUpdate,
                //   condutor: abordadoUpdate?.condutor,
                // });
                // setSwitchCondutor(!switchCondutor);
                // }}
              />
            </div>
          </MDBRow>
          {switchCondutor === true ? (
            <MDBAnimation>
              <MDBRow>
                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="text"
                    label="CNH"
                    maxlength="15"
                    onChange={e => {
                      setAbordadoUpdate({
                        ...abordadoUpdate,
                        cnh: e.target.value,
                      });
                    }}
                    value={abordadoUpdate?.cnh}
                  />
                </MDBCol>

                <MDBCol size="12" md="4" middle>
                  <Select
                    placeholder="Categoria"
                    options={[
                      { label: 'A', value: 'A' },
                      { label: 'B', value: 'B' },
                      { label: 'C', value: 'C' },
                      { label: 'D', value: 'D' },
                      { label: 'E', value: 'E' },
                      { label: 'AB', value: 'AB' },
                      { label: 'AC', value: 'AC' },
                      { label: 'AD', value: 'AD' },
                      { label: 'AE', value: 'AE' },
                    ]}
                    value={{
                      label: abordadoUpdate?.cnh_categoria,
                      value: abordadoUpdate?.cnh_categoria,
                    }}
                    onChange={e => {
                      setAbordadoUpdate({
                        ...abordadoUpdate,
                        cnh_categoria: e.value,
                      });
                    }}
                  />
                </MDBCol>

                <MDBCol size="12" md="4" middle>
                  <MDBInput
                    type="date"
                    label="Validade"
                    onChange={e => {
                      setAbordadoUpdate({
                        ...abordadoUpdate,
                        cnh_validade: e.target.value,
                      });
                    }}
                    value={
                      abordadoUpdate?.cnh_validade
                        ? format(
                            parseISO(abordadoUpdate?.cnh_validade),
                            'yyyy-MM-dd'
                          )
                        : ''
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBAnimation>
          ) : (
            ''
          )}
        </MDBModalBody>
        <MDBModalFooter between>
          <button
            className="btn btn-danger"
            onClick={() => {
              setAbordadoUpdate(null);
            }}
          >
            Cancelar
          </button>

          <MDBBtn
            onClick={() => {
              alteraAbordado();
            }}
            disabled={loadingEnviar}
            color="warning"
          >
            {loadingEnviar ? 'Carregando...' : 'Cadastrar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>

      {/* MODAL PARA DELETAR ABORDADO */}
      <MDBModal
        isOpen={modalDeleteAbordado !== ''}
        toggle={() => {
          setModalDeleteAbordado('');
        }}
      >
        <MDBModalHeader
          toggle={() => {
            setModalDeleteAbordado('');
          }}
        >
          Deletar Pessoa Abordada
        </MDBModalHeader>
        <MDBModalBody className="text-danger font-weight-bolder">
          Tem certeza que deseja deletar esse abordado?
        </MDBModalBody>
        <MDBModalFooter between>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalDeleteAbordado('');
            }}
          >
            Cancelar
          </MDBBtn>

          <MDBBtn
            onClick={() => {
              deletarAbordado(modalDeleteAbordado);
            }}
            color="warning"
            disabled={loadingDeletar}
          >
            {loadingDeletar ? 'Deletando...' : 'Deletar'}
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    </>
  );
}
