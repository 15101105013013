import React from 'react';
import { Pie, Doughnut } from 'react-chartjs-2';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBTypography,
  MDBBox,
  MDBSpinner,
  toast,
} from 'mdbreact';

import Select from 'react-select';

// roteamento
import { useHistory } from 'react-router';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
import NavBar from '../../../components/NavBar';
import UserJpg from '../../../assets/user.jpg';
// import { Container } from './styles';
import api from '../../../services/api';
import { getData } from '../../../services/auth';
import { useState, useEffect, useRef } from 'react';

function Perfil(props) {
  const [dados, setDados] = useState({});
  // const [foto, setFoto] = useState('');
  const [lotacao, setLotacao] = useState({});
  const [nomeacao, setNomeacao] = useState([]);
  const [botaoEditar, setBotaoEditar] = useState(null);
  const [botaoEditarLotacao, setBotaoEditarLotacao] = useState(null);

  const [dadosHistoricoLotacoes, setDadosHistoricoLotacoes] = useState([]);
  const [dadosHistoricoServicos, setDadosHistoricoServicos] = useState([]);
  const [dataPie, setDataPie] = useState({
    labels: ['Outros - 70%', 'PMERJ - 30%'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#F7464A',
          '#46BFBD',
          '#FDB45C',
          '#949FB1',
          '#4D5360',
          '#AC64AD',
        ],
        hoverBackgroundColor: [
          '#FF5A5E',
          '#5AD3D1',
          '#FFC870',
          '#A8B3C5',
          '#616774',
          '#DA92DB',
        ],
      },
    ],
  });
  const [dataDoughnut, setDataDoughnut] = useState({
    labels: ['', ''],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: [
          '#F7464A',
          '#3CB371',
          '#FDB45C',
          '#949FB1',
          '#4D5360',
          '#AC64AD',
        ],
        hoverBackgroundColor: [
          '#FF5A5E',
          '#5AD3D1',
          '#FFC870',
          '#A8B3C5',
          '#616774',
          '#DA92DB',
        ],
      },
    ],
  });
  const [idReq, setIdReq] = useState(props.match.params.id);

  useEffect(() => {
    setIdReq(props.match.params.id);
  }, [props.match.params.id]);

  useEffect(() => {
    if (getData().id == idReq) {
      toast.error('Acesso Proibido, Reportado ao Administrador');
      history.push('/');
      return;
    }

    const loadDados = async () => {
      api
        .get('/verifica-funcao', {
          params: {
            rota: '/editar',
            tipo: 'efetivo',
          },
        })
        .then(res => {
          setBotaoEditar(res.data);
        });

      api
        .get('/verifica-funcao', {
          params: {
            rota: '/editar-lotacao',
            tipo: 'efetivo',
          },
        })
        .then(res => {
          setBotaoEditarLotacao(res.data);
        });

      let response = await api.get(`/usuario/${idReq}`);

      if (response.data === 'Acesso Proibido') {
        // roteamento
        toast.error('Acesso Negado, Falha reportada ao administrador!');
        history.push('/app');
        return;
      }

      const { lotacao, ...dados } = response.data;

      setNomeacao(dados.nomeacaos);

      let escala = {};
      escala.lotacaoGerencia = lotacao.gerencia.nome;
      escala.lotacaoAla = lotacao.ala.nome;
      escala.lotacaoEscala = lotacao.escala.nome;
      // escala.setorArea = lotacao.setor.area.nome
      // escala.setorGuarnicao = lotacao.setor.guarnicao.nome

      setDados(dados);
      setLotacao(escala);

      api
        .get('/verifica-funcao', {
          params: {
            rota: 'card-avaliar',
            tipo: 'efetivo',
          },
        })
        .then(res => {
          if (res.data === null) {
            setCardAvaliacao(false);
          } else {
            setCardAvaliacao(true);
            buscaElogiosCriticas();
          }
        });
    };

    const criarTabelas = async () => {
      const graficos = await api.get(`/perfil-estatisticas/${idReq}`);

      const labels = [
        `Ausente - ${(
          (Number(graficos.data?.pizza?.ausente) /
            (Number(graficos.data?.pizza?.ausente) +
              Number(graficos.data?.pizza?.presente))) *
          100
        ).toFixed(0)} %`,
        `Presente - ${(
          (Number(graficos.data?.pizza?.presente) /
            (Number(graficos.data?.pizza?.presente) +
              Number(graficos.data?.pizza?.ausente))) *
          100
        ).toFixed(0)} %`,
      ];
      setDataPie({
        labels: labels,
        // [
        //   ,
        //   'Presente',

        // `Ausente - ${((graficos.data.pizza.ausente / (graficos.data.pizza.ausente + graficos.data.pizza.presente)) * 100).toFixed(0)} %`,
        // `Outros - ${((outros / (pmerj + outros)) * 100).toFixed(0)} %`,
        // ],
        datasets: [
          {
            data: [
              graficos.data?.pizza?.ausente,
              graficos.data?.pizza?.presente,
            ],
            // data: [3, 2],
            backgroundColor: [
              '#F7464A',
              '#3CB371',
              '#FDB45C',
              '#949FB1',
              '#4D5360',
              '#AC64AD',
            ],
            hoverBackgroundColor: [
              '#FF5A5E',
              '#2E8B57',
              '#FFC870',
              '#A8B3C5',
              '#616774',
              '#DA92DB',
            ],
          },
        ],
      });

      const motivos = [];
      const qtd = [];

      graficos.data.rosca.map(mot => {
        motivos.push(mot.motivo);
        qtd.push(mot.count);
      });

      setDataDoughnut({
        labels: motivos,
        datasets: [
          {
            data: qtd,
            // data: [3, 2],
            backgroundColor: [
              '#949FB1',
              '#4D5360',
              '#AC64AD',
              '#FDB45C',
              '#F7464A',
              '#3CB371',
            ],
            hoverBackgroundColor: [
              '#A8B3C5',
              '#616774',
              '#DA92DB',
              '#FFC870',
              '#FF5A5E',
              '#2E8B57',
            ],
          },
        ],
      });
    };

    loadDados();
    criarTabelas();
  }, [idReq]);

  let history = useHistory();
  if (getData().id == idReq) {
    toast.error('Acesso Proibido, Reportado ao Administrador');
    history.push('/');
  }
  // roteamento

  const historicoLotacoes = async limit => {
    const lotacoes = await api.get(`historico-lotacoes/${idReq}`, {
      params: { limit: limit },
    });

    setDadosHistoricoLotacoes(lotacoes.data);
  };

  const historicoServicos = async limit => {
    const lotacoes = await api.get(`historico-servicos/${idReq}`, {
      params: { limit: limit },
    });
    setDadosHistoricoServicos(lotacoes.data);
  };

  // MODAL DE AVALIAÇÃO
  const [modalAvaliar, setModalAvaliar] = useState(false);
  const [modalAvaliarElogio, setModalAvaliarElogio] = useState({
    label: 'Elogio',
    value: true,
  });

  const [modalAvaliarDataFato, setModalAvaliarDataFato] = useState('');
  const [modalAvaliarDescricao, setModalAvaliarDescricao] = useState('');

  const [elogiosCriticas, setElogioCriticas] = useState({
    elogios: 0,
    criticas: 0,
  });

  const buscaElogiosCriticas = async () => {
    api
      .get('/qtd-avaliacao', {
        params: { avaliado_id: idReq },
      })
      .then(t =>
        setElogioCriticas({
          elogios: t.data.elogios,
          criticas: t.data.criticas,
        })
      );
  };

  const [modalEnviarAvaliacao, setModalEnviarAvaliacao] = useState(false);

  const enviaAvaliacao = async () => {
    if (modalAvaliarDataFato === '') {
      toast.error('Insira a data');
      return;
    }
    if (modalAvaliarDataFato > Date.now()) {
      toast.error('Data maior que o permitido');
      return;
    }
    if (modalAvaliarDescricao === '') {
      toast.error('Descrição inválida');
      return;
    }
    setModalEnviarAvaliacao(true);
    const avaliacao = await api.post('/usuario-avaliacao', {
      elogio: modalAvaliarElogio.value,
      data_fato: modalAvaliarDataFato,
      descricao: modalAvaliarDescricao,
      avaliado_id: idReq,
    });
    if (avaliacao.data.id) {
      toast.success('Avaliação Enviada');
      setModalAvaliar(false);
      setModalAvaliarElogio({
        label: 'Elogio',
        value: true,
      });
      setModalAvaliarDataFato('');
      setModalAvaliarDescricao('');
      buscaElogiosCriticas();
      setModalEnviarAvaliacao(false);
    } else {
      toast.error('Avaliação não Enviada');
      setModalEnviarAvaliacao(false);
    }
  };

  // MODAL DE HISTORICOS DE AVALIACAO
  const [modalHistoricoAvaliacao, setModalHistoricoAvaliacao] = useState(false);
  const [modalHistoricoLista, setModalHistoricoLista] = useState([]);
  const [modalHistoricoListaLoading, setModalHistoricoListaLoading] = useState(
    false
  );
  const [cardAvaliacao, setCardAvaliacao] = useState(false);

  const [cnhFotoModal, setCnhFotoModal] = useState(false);
  const [cnhFotoLoading, setCnhFotoLoading] = useState(false);
  const [fotoCnh, setFotoCnh] = useState(null);

  const [limitHistorico, setLimitHistorico] = useState(3);
  const [limitServicos, setLimitServicos] = useState(3);

  const buscaCNH = async () => {
    setCnhFotoLoading(true);
    api
      .get('/usuario-foto-cnh', {
        params: { usuario_id: idReq },
      })
      .then(t => {
        setFotoCnh(t.data[0].imagem);
        setCnhFotoLoading(false);
      })
      .catch(t => setCnhFotoLoading(false));
  };

  const listaDeAvaliacao = async () => {
    setModalHistoricoListaLoading(true);
    const historico = await api.get('/usuario-avaliacao', {
      params: { avaliado_id: idReq },
    });
    setModalHistoricoLista(historico.data);
    setModalHistoricoListaLoading(false);
  };

  useEffect(() => {
    historicoLotacoes(limitHistorico);
  }, [limitHistorico]);

  useEffect(() => {
    historicoServicos(limitServicos);
  }, [limitServicos]);

  useEffect(() => {
    if (cnhFotoModal) {
      buscaCNH();
    }
  }, [cnhFotoModal]);

  useEffect(() => {
    if (modalHistoricoAvaliacao === true) {
      listaDeAvaliacao();
    }
  }, [modalHistoricoAvaliacao]);

  // useEffect(() => {
  //   loadDados();

  //   criarTabelas();
  // }, []);

  return (
    <NavBar>
      <MDBContainer>
        <MDBRow className="d-flex flex-column-reverse flex-md-row  align-items-stretch">
          <MDBCol
            md="6"
            className="d-flex flex-column justify-content-between mt-2 mt-md-0"
          >
            <MDBCard>
              <MDBCardBody className="d-flex flex-column">
                <form>
                  <p className="h5 text-center">Informações</p>
                  <div className="grey-text ">
                    <MDBInput
                      label="Contato"
                      icon="phone"
                      group
                      type="text"
                      validate
                      error="wrong"
                      success="right"
                      value={dados.telefone}
                      disabled
                    />

                    <MDBInput
                      label="Email"
                      icon="envelope"
                      group
                      type="email"
                      validate
                      error="wrong"
                      success="right"
                      value={dados.email}
                      disabled
                    />
                  </div>
                  <div className=" d-flex justify-content-between">
                    <div className="black-text">Status:</div>
                    {dados.ativo ? (
                      <div className="text-success rounded-pill px-4  border border-success font-weight-bold">
                        Nomeado
                      </div>
                    ) : (
                      <div className="text-danger rounded-pill px-4 border border-danger font-weight-bold">
                        Exonerado
                      </div>
                    )}
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="h-100 mt-2 mt-md-4">
              <MDBCardBody className="h-100">
                <p className="h5 text-center">Lotação</p>

                <MDBTable striped>
                  <MDBTableBody>
                    <tr>
                      <td>Gerencia</td>
                      <td className="font-weight-bold">
                        {lotacao.lotacaoGerencia}
                      </td>
                    </tr>
                    <tr>
                      <td>Escala</td>
                      <td>{lotacao.lotacaoEscala}</td>
                    </tr>
                    <tr>
                      <td>ALA</td>
                      <td>{lotacao.lotacaoAla}</td>
                    </tr>

                    {/* <tr>
                      <td>Guarnição</td>
                      <td>{lotacao.setorGuarnicao}</td>
                    </tr> */}
                    {/* <tr>
                      <td>Área</td>
                      <td>{lotacao.setorArea}</td>
                    </tr> */}
                    {/* <tr>
                      <td>Último Serviço:</td>
                      <td className="red-text font-weight-bold">03/12/2019</td>
                    </tr>
                    <tr>
                      <td>Proximo Serviço:</td>
                      <td className="green-text font-weight-bold">
                        07/12/2019
                      </td>
                    </tr> */}
                  </MDBTableBody>
                </MDBTable>
              </MDBCardBody>
            </MDBCard>
            {cardAvaliacao === false ? (
              ''
            ) : (
              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100  d-flex flex-column justify-content-center ">
                  <MDBRow center className="h5">
                    Avaliação
                  </MDBRow>
                  <br />
                  <MDBRow>
                    <MDBCol className="success-text font-weight-bolder">
                      <MDBRow center>Elogios</MDBRow>
                      <MDBRow center>{elogiosCriticas.elogios}</MDBRow>
                    </MDBCol>
                    <MDBCol className="danger-text font-weight-bolder">
                      <MDBRow center>Críticas</MDBRow>
                      <MDBRow center>{elogiosCriticas.criticas}</MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <br />

                  <hr />
                  <MDBRow center>
                    <MDBBtn
                      onClick={() => setModalAvaliar(!modalAvaliar)}
                      size="sm"
                      className="d-flex align-items-center"
                    >
                      <MDBIcon icon="plus" />
                      <div className="ml-2">Avaliar</div>
                    </MDBBtn>

                    <MDBBtn
                      onClick={() =>
                        setModalHistoricoAvaliacao(!modalHistoricoAvaliacao)
                      }
                      size="sm"
                      className="d-flex align-items-center"
                    >
                      <MDBIcon icon="list-ul" />
                      <div className="ml-2">Histórico</div>
                    </MDBBtn>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            )}
          </MDBCol>

          <MDBCol md="6" className="d-flex flex-column justify-content-between">
            <MDBCard className="h-100">
              <MDBCardBody>
                <p className="h5 text-center">Perfil</p>
                <form>
                  <div className="text-center">
                    <img
                      className="img-fluid"
                      width="200"
                      alt="foto do perfil"
                      src={
                        dados.foto_usuario ? dados.foto_usuario.url : UserJpg
                      }
                    />
                  </div>

                  <div className="grey-text m-4 text-center">
                    <h5>Nome:</h5>
                    <h4 className="red-text">
                      {dados.nome} {dados.sobrenome}
                    </h4>
                    <br />

                    <div>
                      {/* <div className="black-text d-flex justify-content-between">
                        <div>Cargo:</div>
                        <div>{dados.segov_admissao}</div>
                        <div>FALTA COLOCAR</div>
                      </div>
                      <hr /> */}
                      {getData().acessos.find(e => e.rota === 'sensivel') !==
                      undefined ? (
                        <>
                          <div className="black-text d-flex justify-content-between">
                            <div>CPF: </div>
                            <div>{dados.cpf}</div>
                          </div>

                          <hr />
                          <div className="black-text d-flex justify-content-between">
                            <div>RG:</div>
                            <div>{dados.rg}</div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        ''
                      )}

                      <div className="black-text d-flex justify-content-between">
                        <div>Id Funcional:</div>
                        <div>{dados.id_funcional}</div>
                      </div>
                      <hr />

                      <div className="black-text d-flex justify-content-between">
                        <div>Tipo Sanguíneo:</div>

                        <div>
                          {!dados.tipo_sanguineo ||
                          dados.tipo_sanguineo === null
                            ? 'SEM TIPO'
                            : dados.tipo_sanguineo}
                        </div>
                      </div>
                      <hr />
                      <div className="black-text d-flex justify-content-between">
                        <div>Adido: </div>
                        <div>
                          {dados?.adido ? (
                            <td>{`${dados.adidos[0]?.orgao.sigla} - ${dados.adidos[0]?.titulo.nome}`}</td>
                          ) : (
                            <td>Não</td>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="black-text d-flex justify-content-between">
                        <div>Tipo CNH: </div>
                        <div>{dados.cnh_categoria}</div>
                      </div>

                      <div className="black-text d-flex justify-content-between">
                        <div>Validade CNH: </div>
                        <div>
                          {dados.cnh_validade &&
                            format(parseISO(dados.cnh_validade), 'dd/MM/yyyy')}
                        </div>
                      </div>
                      <div className="black-text d-flex justify-content-center">
                        {getData().acessos.find(e => e.rota === 'sensivel') !==
                        undefined ? (
                          <div>
                            <MDBRow center>
                              <MDBBtn
                                onClick={() => setCnhFotoModal(true)}
                                size="sm"
                                className="d-flex align-items-center"
                              >
                                <MDBIcon far icon="image" size="2x" />
                                {/* <MDBIcon icon="list-ul" /> */}
                                <div className="ml-2">Foto CNH</div>
                              </MDBBtn>
                            </MDBRow>
                            <hr />
                            <div className="black-text">
                              <div>Admissão:</div>
                              <br />
                              {nomeacao.map(linha => (
                                <MDBRow
                                  key={linha.id}
                                  className="d-flex justify-content-between mt-2"
                                >
                                  <MDBCol className="d-flex">
                                    <div>Data:</div>
                                    <div className="ml-2">
                                      {/* {format(new Date(linha.data), 'dd/MM/yyyy')} */}
                                      {format(
                                        parseISO(linha.data),
                                        'dd/MM/yyyy'
                                      )}
                                    </div>
                                  </MDBCol>
                                  <MDBCol className="d-flex">
                                    <div>DO Nº: </div>
                                    <div className="ml-2">
                                      {linha.diario_oficial}
                                    </div>
                                  </MDBCol>
                                  <MDBCol className="d-flex">
                                    <div>Tipo:</div>
                                    <div className="ml-2">{linha.tipo}</div>
                                  </MDBCol>
                                </MDBRow>
                              ))}
                              {/* <div>{dados.nomeacaos.diario_oficial}</div> */}
                              {/* <div>teste</div> */}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>

                      <hr />
                      <MDBRow className="justify-content-center">
                        {botaoEditar === null ? (
                          ''
                        ) : (
                          <MDBCol size="12" md="6">
                            <Link
                              to={`/editar/${dados.id}`}
                              className="btn btn-elegant text-white"
                            >
                              <MDBIcon
                                className="mr-1"
                                far
                                icon="edit"
                                size="1x"
                              />
                              <span>Editar</span>
                            </Link>
                          </MDBCol>
                        )}

                        {botaoEditarLotacao === null ? (
                          ''
                        ) : (
                          <MDBCol size="12" md="6">
                            <Link
                              to={`/editar-lotacao/${dados.id}`}
                              className="btn btn-elegant text-white"
                            >
                              <MDBIcon
                                className="mr-1"
                                far
                                icon="edit"
                                size="1x"
                              />
                              <span>Lotação</span>
                            </Link>
                          </MDBCol>
                        )}
                      </MDBRow>
                    </div>
                  </div>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <MDBCard className="h-100 mt-2 mt-md-4">
              <MDBCardBody className="h-100">
                <p className="h5 text-center">Histórico de Lotações</p>
                <MDBRow
                  between
                  style={{ backgroundColor: 'rgba(250,250,250,0.8)' }}
                  className="mx-3 p-2 d-none font-weight-bold d-md-flex rounded-top"
                >
                  <div className="col-12 col-md-3 ">Lotação</div>
                  <div className="col-12 col-md-3 ">Ala</div>

                  <div className="col-12 col-md-3">Data Início</div>
                  <div className="col-12 col-md-3 ">Data Fim</div>
                  {/* <div className="col-12 col-md-3 text-right">Local</div> */}
                </MDBRow>

                {dadosHistoricoLotacoes &&
                  dadosHistoricoLotacoes.map(lotacao => (
                    <div key={`a${lotacao.id}`}>
                      <MDBRow
                        style={{ borderTop: '1px solid silver' }}
                        between
                        className="p-2 mr-1 ml-1 mr-md-3 ml-md-3  bg-white"
                      >
                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Gerência:
                          </span>
                          <span>{`${lotacao.sigla}`}</span>
                        </div>
                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Ala:
                          </span>
                          <span>{`${lotacao.nome}`}</span>
                        </div>

                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Data Início:
                          </span>
                          {/* {lotacao.dt_inicio &&
                            format(new Date(lotacao.dt_inicio), 'dd/MM/yyyy')} */}
                          {format(
                            new Date(lotacao.dt_inicio).getTime() +
                              new Date(lotacao.dt_inicio).getTimezoneOffset() *
                                60000,
                            'dd/MM/yyyy'
                          )}
                        </div>
                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Data Fim:
                          </span>
                          {/*
                          {lotacao.dt_fim
                            ? format(new Date(lotacao.dt_fim), 'dd/MM/yyyy')
                            : 'Atualmente'} */}

                          {lotacao.dt_fim
                            ? format(
                                new Date(lotacao.dt_fim).getTime() +
                                  new Date(lotacao.dt_fim).getTimezoneOffset() *
                                    60000,
                                'dd/MM/yyyy'
                              )
                            : 'Atualmente'}
                        </div>
                      </MDBRow>
                    </div>
                  ))}
                <MDBRow className="mt-3" center>
                  {limitHistorico === undefined ? (
                    ''
                  ) : (
                    <MDBBtn size="sm" onClick={() => setLimitHistorico()}>
                      VER TUDO
                    </MDBBtn>
                  )}
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        <br />
        <MDBRow>
          <MDBCol>
            <MDBCard className="h-100 mt-2 mt-md-4">
              <MDBCardBody className="h-100">
                <p className="h5 text-center">Estatísticas de Serviços</p>
                {/* <MDBRow
                  between
                  style={{ backgroundColor: 'rgba(250,250,250,0.8)' }}
                  className="mx-3 p-2 d-none font-weight-bold d-md-flex rounded-top"
                > */}

                {/* <div className="col-12 col-md-3 text-right">Local</div> */}
                {/* </MDBRow> */}
                <MDBRow>
                  <MDBCol size="12" md="6">
                    <Pie data={dataPie} options={{ responsive: true }} />
                  </MDBCol>
                  <MDBCol size="12" md="6">
                    <Doughnut
                      data={dataDoughnut}
                      options={{ responsive: true }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <br />
        <MDBRow>
          <MDBCol>
            <MDBCard className="h-100 mt-2 mt-md-4">
              <MDBCardBody className="h-100">
                <p className="h5 text-center">Histórico de Serviços</p>
                <MDBRow
                  between
                  style={{ backgroundColor: 'rgba(250,250,250,0.8)' }}
                  className="mx-3 p-2 d-none font-weight-bold d-md-flex rounded-top"
                >
                  <div className="col-12 col-md-3 ">Lotação</div>
                  <div className="col-12 col-md-3 ">Ala</div>
                  <div className="col-12 col-md-3 ">Data</div>
                  <div className="col-12 col-md-3 ">Motivo</div>

                  {/* <div className="col-12 col-md-3 text-right">Local</div> */}
                </MDBRow>
                {dadosHistoricoServicos &&
                  dadosHistoricoServicos.map(servico => (
                    <div key={servico.id}>
                      <MDBRow
                        style={{ borderTop: '1px solid silver' }}
                        between
                        className="p-2 mr-1 ml-1 mr-md-3 ml-md-3  bg-white"
                      >
                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Lotação:
                          </span>
                          <span>{`${servico.sigla}`}</span>
                        </div>

                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Ala:
                          </span>
                          <span>{`${servico.nome}`}</span>
                        </div>

                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Data:
                          </span>
                          <span>
                            {servico.dtserv &&
                              format(new Date(servico.dtserv), 'dd/MM/yyyy')}
                          </span>
                        </div>

                        <div className="col-12 col-md-3 d-flex justify-content-between">
                          <span className="d-sm-inline d-md-none mr-3">
                            Motivo:
                          </span>
                          {servico.presente === true ? (
                            <p className="m-0 p-0 font-weight-bolder text-success">
                              Presente
                            </p>
                          ) : (
                            <p className="m-0 p-0 font-weight-bolder text-danger">
                              {servico.motivo}
                            </p>
                          )}
                        </div>
                      </MDBRow>
                    </div>
                  ))}
                <MDBRow className="mt-3" center>
                  {limitServicos === undefined ? (
                    ''
                  ) : (
                    <MDBBtn size="sm" onClick={() => setLimitServicos()}>
                      VER TUDO
                    </MDBBtn>
                  )}
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>

        <br />

        <br />
        <MDBModal
          size="lg"
          isOpen={modalAvaliar}
          toggle={() => {
            setModalAvaliar(!modalAvaliar);
          }}
        >
          <MDBModalHeader toggle={() => setModalAvaliar(!modalAvaliar)}>
            <div className="font-weight-bolder"> Adicionar Avaliação</div>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="12" md="4">
                <span>Tipo de avaliação</span>
                <Select
                  options={[
                    { label: 'Elogio', value: true },
                    { label: 'Crítica', value: false },
                  ]}
                  value={modalAvaliarElogio}
                  onChange={e => setModalAvaliarElogio(e)}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <span>Data do fato avaliado</span>
                <MDBInput
                  style={{ marginTop: '-25px' }}
                  type="date"
                  outline
                  placeholder="Data Final"
                  className="bg-white"
                  onChange={e =>
                    setModalAvaliarDataFato(parseISO(e.target.value))
                  }
                />
              </MDBCol>
            </MDBRow>

            <MDBRow className="px-3">
              <MDBCol size="12">
                <MDBInput
                  style={{
                    borderRadius: '5px',
                    border: `2px solid ${
                      modalAvaliarElogio.value
                        ? 'rgba(60, 179, 113, 0.8)'
                        : 'rgba(255, 0, 0, 0.5)'
                    }`,
                  }}
                  onChange={e => setModalAvaliarDescricao(e.target.value)}
                  type="textarea"
                  outline
                  rows="10"
                  maxlength="1000"
                  counter="1000"
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {modalEnviarAvaliacao ? (
              <div className="d-flex justify-content-center align-items-center">
                <MDBSpinner className="mr-3" yellow />{' '}
                <div className="font-weight-bolder">Carregando</div>
              </div>
            ) : (
              <>
                <MDBBtn
                  color="danger"
                  onClick={() => setModalAvaliar(!modalAvaliar)}
                >
                  Cancelar
                </MDBBtn>
                <MDBBtn onClick={enviaAvaliacao} color="success">
                  Enviar
                </MDBBtn>
              </>
            )}
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          size="lg"
          isOpen={modalHistoricoAvaliacao}
          toggle={() => {
            setModalHistoricoAvaliacao(!modalHistoricoAvaliacao);
          }}
        >
          <MDBModalHeader
            className="font-weight-bolder"
            toggle={() => setModalHistoricoAvaliacao(!modalHistoricoAvaliacao)}
          >
            <div className="font-weight-bolder"> Histórico de Avaliação</div>
          </MDBModalHeader>
          <MDBModalBody>
            {modalHistoricoListaLoading ? (
              <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBSpinner className="mr-3" yellow />{' '}
                <div className="font-weight-bolder">Carregando</div>
              </MDBRow>
            ) : (
              ''
            )}
            {modalHistoricoLista.length > 0 ? (
              modalHistoricoLista.map(avaliacao => (
                <>
                  <MDBTypography
                    key={avaliacao.id}
                    blockquote
                    bqColor={`${avaliacao.elogio ? 'success' : 'danger'}`}
                  >
                    <MDBBox
                      tag="p"
                      mb={0}
                      className="bq-title font-weight-bolder"
                    >
                      {avaliacao.elogio ? 'Elogio' : 'Crítica'}
                    </MDBBox>
                    <MDBRow between>
                      <MDBCol className="font-weight-bolder">
                        Data:{' '}
                        {format(parseISO(avaliacao.data_fato), 'dd/MM/yyyy')}
                      </MDBCol>
                      <MDBCol className="font-weight-bolder">
                        Avaliador: {avaliacao.avaliador.nome_social}
                      </MDBCol>
                    </MDBRow>

                    <p>
                      {avaliacao.descricao.split('\n').map((t, index) => (
                        <div key={index}>
                          {t} <br />
                        </div>
                      ))}
                    </p>
                  </MDBTypography>
                </>
              ))
            ) : (
              <MDBRow center className="font-weight-bolder">
                Sem Avaliação
              </MDBRow>
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() =>
                setModalHistoricoAvaliacao(!modalHistoricoAvaliacao)
              }
            >
              Fechar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal
          size="lg"
          isOpen={cnhFotoModal}
          toggle={() => {
            setCnhFotoModal(false);
          }}
        >
          <MDBModalHeader
            className="font-weight-bolder"
            toggle={() => setCnhFotoModal(false)}
          >
            <div className="font-weight-bolder"> Última CNH cadastrada</div>
          </MDBModalHeader>
          <MDBModalBody>
            {cnhFotoLoading ? (
              <MDBRow className="d-flex justify-content-center align-items-center">
                <MDBSpinner className="mr-3" yellow />{' '}
                <div className="font-weight-bolder">Carregando</div>
              </MDBRow>
            ) : (
              ''
            )}

            <MDBRow center className="font-weight-bolder">
              {fotoCnh !== null ? (
                <img
                  // key={res.id}
                  // id={res.id}
                  // width="200"
                  // height="200"
                  style={{ objectFit: 'cover' }}
                  className="img-fluid"
                  src={`${Buffer.from(fotoCnh, 'base64')}`}
                  // onClick={e => {
                  //   setModalFoto({ id: res.id, base: res.imagem });
                  //   // src={imagens}
                  // }}
                />
              ) : (
                'Sem foto'
              )}
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="danger" onClick={() => setCnhFotoModal(false)}>
              Fechar
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}

export default Perfil;
