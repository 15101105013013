import React, { useEffect, useState, useRef } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';
import InputMask from 'react-input-mask';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBSwitch,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBSelect,
  MDBCardHeader,
} from 'mdbreact';
import { isPast, getYear } from 'date-fns';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { getData } from '../../../services/auth';
import format from 'date-fns/format';

import { Manutencao } from './Components/manutencao';
import { Status } from './Components/status';
import { Alteracoes } from './Components/alteracoes';

export default function App() {
  const history = useHistory();

  const [collapse, setCollapse] = useState(false);
  const [loading, setLoading] = useState(false);

  // const listTipos = [
  //   { label: 'Manuteções', value: 1 },
  //   { label: 'Status Veículos', value: 2 },
  //   { label: 'Alteração BDT', value: 3 },
  //   { label: 'Abastecimento BDT', value: 4 },
  //   { label: 'Multas BDT', value: 5 },
  // ];

  const [tipoSelected, setTipoSelected] = useState(null);

  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState([]);

  // manutencao
  const [manutencaoSelected, setManutencaoSelected] = useState(null);
  // subtipos de manutencao
  const [listaManutencao, setListaManutencao] = useState([]);
  // subtipos de manutencao selecionado
  const [manutencaoSubSelecionado, setManutencaoSubSelecionado] = useState(
    null
  );

  const [listaStatus, setListaStatus] = useState([]);
  const [statusSelected, setStatusSelected] = useState(null);

  const [listaVtrs, setListaVtrs] = useState([]);
  const [vtrSelect, setVtrSelect] = useState(null);

  const [resultaDados, setResultaDados] = useState([]);
  const [dadosManutencao, setDadosManutencao] = useState([]);

  const [tabs, setTabs] = useState(1);
  // select de gerecias
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
      setGerenciaSelecionada(select[0]);
      carregaVtr(select[0]);
    });
  };

  const carregaVtr = gerencia => {
    setGerenciaSelecionada(gerencia);
    setVtrSelect(null);
    api
      .get('/frota', {
        params: { gerencia_id: gerencia.value, ativo: true },
      })
      .then(t => {
        const veiculosSelect = [];
        t.data.forEach(element => {
          veiculosSelect.push({ label: element.placa, value: element.id });
        });
        setListaVtrs(veiculosSelect);
      });
  };

  // faz a busca dos subtipos
  const buscaTipoSelecionado = () => {
    if (tabs === 2) {
      // buscaStatus();
    }

    if (tabs === 3) {
      console.log('tres');
    }

    if (tabs === 4) {
      console.log('quatro');
    }
  };

  // busca Resultado Final de status

  useEffect(() => {
    // buscaTipoSelecionado();
    carregaGerencias();
  }, []);

  const toggle = tab => e => {
    // if (tabs === 1) {
    //   // buscaTipoSelecionado();
    // }
    if (tabs !== tab) {
      setTabs(tab);
    }
  };

  // useEffect(() => {
  //   api
  //     .get('/frota', {
  //       params: { gerencia_id: gerenciaSelecionada.value },
  //     })
  //     .then(t => {
  //       const veiculosSelect = [];
  //       t.data.forEach(element => {
  //         veiculosSelect.push({ label: element.placa, value: element.id });
  //       });
  //       setListaVtrs(veiculosSelect);
  //     });
  // }, [gerenciaSelecionada]);

  return (
    <NavBar>
      <MDBContainer>
        <h3 className="h3-responsive text-center font-weight-bolder">
          Alterações de Veículos
        </h3>

        <MDBRow>
          <MDBCol col="12" className="m-0 p-0">
            <MDBContainer className="m-0 p-0">
              <MDBNav className="nav-tabs mt-5">
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={tabs === 1}
                    onClick={toggle(1)}
                    role="tab"
                  >
                    <div className="font-weight-bolder">Manutenção</div>
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={tabs === 2}
                    onClick={toggle(2)}
                    role="tab"
                  >
                    <div className="font-weight-bolder">Status de VTR</div>
                  </MDBNavLink>
                </MDBNavItem>
                {/* <MDBNavItem>
                  <MDBNavLink
                    link
                    to="#"
                    active={tabs === 3}
                    onClick={toggle(3)}
                    role="tab"
                  >
                    <div className="font-weight-bolder">Alterações de VTR</div>
                  </MDBNavLink>
                </MDBNavItem> */}
              </MDBNav>
              <MDBTabContent activeItem={tabs} className="m-0 p-0">
                <MDBTabPane tabId={1} role="tabpanel">
                  <div className="mt-2">
                    <Manutencao />
                  </div>
                </MDBTabPane>
                <MDBTabPane tabId={2} role="tabpanel">
                  <div className="mt-2">
                    <Status />
                  </div>
                </MDBTabPane>
                <MDBTabPane tabId={3} role="tabpanel">
                  <div className="mt-2">
                    <Alteracoes />
                  </div>
                  {/* {dadosStatus.map(status => (
                      <MDBRow>
                        <MDBCol>Placa: {status.veiculo.placa}</MDBCol>
                        <MDBCol>Status:</MDBCol>
                        <MDBCol>Descriçao:</MDBCol>
                      </MDBRow>
                    ))} */}
                </MDBTabPane>
              </MDBTabContent>
            </MDBContainer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </NavBar>
  );
}
