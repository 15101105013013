import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBCard,
  MDBView,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBIcon,
  MDBSpinner,
} from 'mdbreact';
import { useHistory } from 'react-router-dom';
import api from '../../../../services/api';
import Loading from '../../../../components/Loading';
import { toast } from 'react-toastify';

export default function Imprimir(props) {
  const history = useHistory();
  const [state, setState] = useState(false);

  const [posto, setPosto] = useState('');
  const [ala, setAla] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [estadoOrigem, setEstadoOrigem] = useState('');
  const [cidadeOrigem, setCidadeOrigem] = useState('');
  const [estadoDestino, setEstadoDestino] = useState('');
  const [cidadeDestino, setCidadeDestino] = useState('');
  const [evasao, setEvasao] = useState('');
  const [abordagemAtiva, setAbordagemAtiva] = useState(true);
  const [abordagemId, setAbordagemId] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingBusca, setLoadingBusca] = useState(false);
  const [imagens, setImagens] = useState([]);
  const [usuario, setUsuario] = useState(null);
  const [deletadoPor, setDeletadoPor] = useState(null);
  const [apoiadoVolante, setApoiadoVolante] = useState(null);

  const trataDate = date => {
    return format(
      zonedTimeToUtc(new Date(date), 'America/Sao_Paulo'),
      'dd/MM/yyyy - HH:mm'
    );
  };

  const postoAla = async idAbordagem => {
    const posto = await api.get(`/abordagem/${idAbordagem}`);

    if (posto.data.erro) {
      toast.error(posto.data.erro);
      history.push('/app');
    } else {
      veiculosAbordados(props.match.params.abordagem_id);
      listaAbordados(props.match.params.abordagem_id);
      listaEmpresas(props.match.params.abordagem_id);
      listaOcorrencias(props.match.params.abordagem_id);
      buscaFoto(props.match.params.abordagem_id);
      setUsuario(posto.data.usuario.nome_social);
      if (posto.data.deletado_por !== null) {
        setDeletadoPor(posto.data.deletado_por.nome_social);
      } else {
        setDeletadoPor(null);
      }
    }
    setAbordagemId(idAbordagem);
    setPosto(posto.data.lotacao.gerencia.nome);
    setAla(posto.data.lotacao.ala.nome);

    setDataInicio(trataDate(posto.data.dt_h_inicio));
    setDataFim(trataDate(posto.data.dt_h_fim));

    setEstadoOrigem(posto.data.cidade_origem.estado.nome);
    setCidadeOrigem(posto.data.cidade_origem.nome);
    setEstadoDestino(posto.data.cidade_destino.estado.nome);
    setCidadeDestino(posto.data.cidade_destino.nome);
    setAbordagemAtiva(posto.data.ativo);
    setEvasao(posto.data.evasao);
    setApoiadoVolante(posto.data.apoiado_por_gerencia_id);
  };
  const buscaFoto = async abordagemId => {
    setLoadingBusca(true);
    const result = await api.get('/sgo-imagem', {
      params: {
        abordagem_id: abordagemId,
      },
    });
    setImagens(result.data);
    setLoadingBusca(false);
  };

  const [veiculos, setVeiculos] = useState([]);

  const veiculosAbordados = async idAbordagem => {
    const veiAbordados = await api.get(`/veiculos-abordagem/${idAbordagem}`);

    setVeiculos(veiAbordados.data);
  };

  const [abordados, setAbordados] = useState([]);

  const listaAbordados = async idAbordagem => {
    const lista = await api.get(`/abordados-abordagem/${idAbordagem}`);

    setAbordados(lista.data);
  };

  const [empresas, setEmpresas] = useState([]);

  const listaEmpresas = async idAbordagem => {
    const lista = await api.get(`/empresas-abordagem/${idAbordagem}`);

    setEmpresas(lista.data);
  };

  const [ocorrencias, setOcorrencias] = useState([]);
  const [tipoUnidade, setTipoUnidade] = useState('');
  // deletar
  const [id, setId] = useState('');

  const listaOcorrencias = async idAbordagem => {
    setLoading(true);
    const lista = await api.get(`/inteligencia-abordagem/${idAbordagem}`);
    setOcorrencias(lista.data);

    setLoading(false);

    // deletar
    setId(idAbordagem);
  };

  useEffect(() => {
    postoAla(props.match.params.abordagem_id);
  }, []);

  return (
    <>
      <MDBContainer>
        {/* DADOS DE ABORDAGEM */}
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 pr-0 pr-md-5 py-2"
            cascade
          >
            <MDBRow
              between
              className={`ml-2 font-weight-bolder ${
                abordagemAtiva === false ? 'danger-text' : ''
              }`}
            >
              <MDBCol middle size="12" md="6">
                {abordagemAtiva === false ? 'Deletada' : ''}
              </MDBCol>
              <MDBCol
                middle
                size="12"
                md="6"
                className="text-white font-weight-bolder text-center text-md-right"
              >
                Abordagem Nº: {abordagemId}
              </MDBCol>
            </MDBRow>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow start>
              <MDBCol
                size="12"
                md="6"
                className="text-start font-weight-bolder"
              >
                Criado por: {usuario}
              </MDBCol>
              {deletadoPor !== null ? (
                <MDBCol
                  size="12"
                  md="6"
                  className="text-left text-danger text-md-right font-weight-bolder"
                >
                  Deletado por: {deletadoPor}
                </MDBCol>
              ) : (
                ''
              )}
            </MDBRow>
            <br />
            <MDBRow between>
              <MDBCol
                className="mt-2 mt-md-0 text-left"
                size="12"
                md="4"
                middle
              >
                {/* <Select
                  placeholder="Posto"
                  // options={listaGerencias}
                  // onChange={e => {
                  //   carregaLotacoes(e.value);
                  // }}

                /> */}
                Posto: {posto}
              </MDBCol>
              <MDBCol
                className="mt-2 mt-md-0 text-center"
                size="12"
                md="4"
                middle
              >
                {/* <Select
                  placeholder="Ala"
                  options={listaLotacoes}
                  isLoading={loadLotacao}
                  value={lotacaoSelecionada?.value}
                  onChange={e => {
                    setLotacaoSelecionada(e.value);
                  }}
                /> */}
                Ala: {ala}
              </MDBCol>
              <MDBCol
                className="mt-2 mt-md-0 text-right"
                size="12"
                md="4"
                middle
              >
                {/* <Select
                  placeholder="Ala"
                  options={listaLotacoes}
                  isLoading={loadLotacao}
                  value={lotacaoSelecionada?.value}
                  onChange={e => {
                    setLotacaoSelecionada(e.value);
                  }}
                /> */}
                Evasão: {evasao === true ? 'Sim' : 'Não'}
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow between>
              <MDBCol
                className="mt-3 mt-md-0 text-left"
                size="12"
                md="6"
                middle
              >
                Data Início: {dataInicio}
                {/* <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="datetime-local"
                  onChange={e => {
                    setDataInicio(e.target.value);
                  }}
                /> */}
              </MDBCol>
              <MDBCol
                className="mt-3 mt-md-0 text-right"
                size="12"
                md="6"
                middle
              >
                Data Fim: {dataFim}
                {/* <MDBInput
                  type="datetime-local"
                  style={{ marginTop: '-28px' }}
                  onChange={e => {
                    setDataFim(e.target.value);
                  }}
                /> */}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-4">
              <MDBCol>
                Apoiado pela Volante: {apoiadoVolante === null ? 'Não' : 'Sim'}
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBCol size="12" md="6" middle>
                Origem: {`${estadoOrigem} / ${cidadeOrigem}`}
              </MDBCol>

              <MDBCol size="12" md="6" middle>
                Destino: {`${estadoDestino} / ${cidadeDestino}`}
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
        {/* DADOS DE VEÍCULOS */}
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Veículos</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            {veiculos.length === 0 ? <MDBRow center>Sem Veículos</MDBRow> : ''}
            {veiculos.map(veiculo => (
              <MDBRow
                key={veiculo.id}
                className="mt-1 p-2"
                style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
              >
                <MDBCol>{`Tipo: ${veiculo.tipo_veiculo.nome}`}</MDBCol>
                {veiculo.placa !== null ? (
                  <MDBCol>{`Placa: ${veiculo.placa}`}</MDBCol>
                ) : (
                  <MDBCol>{`Chassi: ${veiculo.chassi}`}</MDBCol>
                )}
              </MDBRow>
            ))}
          </MDBCardBody>
        </MDBCard>
        <br />
        {/* DADOS DE ABORDADOS */}
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Abordados</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            {abordados.length === 0 ? (
              <MDBRow center>Sem Abordados</MDBRow>
            ) : (
              ''
            )}
            {abordados &&
              abordados.map((abordado, index) =>
                abordado.ativo ? (
                  <div key={`f${index}`}>
                    <MDBRow
                      className="mt-3"
                      style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                    >
                      {abordado.nome !== null ? (
                        <MDBCol sm="12" md="6">
                          Nome: {abordado.nome}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.cpf !== null ? (
                        <MDBCol sm="12" md="6">
                          Cpf: {abordado.cpf}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.tipo !== null ? (
                        <MDBCol sm="12" md="6">
                          Tipo: {abordado.tipo}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.condutor !== null ? (
                        <MDBCol sm="12" md="6">
                          Condutor: {abordado.condutor === true ? 'Sim' : 'Não'}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                    </MDBRow>

                    {abordado.condutor === true ? (
                      <MDBRow style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}>
                        <br />
                        {abordado.cnh !== null ? (
                          <MDBCol>CNH: {abordado.cnh}</MDBCol>
                        ) : (
                          ''
                        )}
                        {abordado.cnh_categoria !== null ? (
                          <MDBCol>Categoria: {abordado.cnh_categoria}</MDBCol>
                        ) : (
                          ''
                        )}

                        {abordado.cnh_validade !== null ? (
                          <MDBCol>
                            CNH validade:{' '}
                            {format(
                              new Date(abordado.cnh_validade),
                              'dd/MM/yyyy'
                            )}
                          </MDBCol>
                        ) : (
                          ''
                        )}
                      </MDBRow>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <div key={`f${index}`}>
                    <MDBRow className=" mt-3 deep-orange lighten-4 red-text px-2">
                      <small>Deletado</small>
                    </MDBRow>

                    <MDBRow className=" deep-orange lighten-4">
                      {abordado.nome !== null ? (
                        <MDBCol sm="12" md="6">
                          Nome: {abordado.nome}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.cpf !== null ? (
                        <MDBCol sm="12" md="6">
                          Cpf: {abordado.cpf}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.tipo !== null ? (
                        <MDBCol sm="12" md="6">
                          Tipo: {abordado.tipo}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                      {abordado.condutor !== null ? (
                        <MDBCol sm="12" md="6">
                          Condutor: {abordado.condutor === true ? 'Sim' : 'Não'}
                        </MDBCol>
                      ) : (
                        ''
                      )}
                    </MDBRow>
                    {abordado.condutor === true ? (
                      <MDBRow className="deep-orange lighten-4">
                        <br />
                        {abordado.cnh !== null ? (
                          <MDBCol>CNH: {abordado.cnh}</MDBCol>
                        ) : (
                          ''
                        )}
                        {abordado.cnh_categoria !== null ? (
                          <MDBCol>Categoria: {abordado.cnh_categoria}</MDBCol>
                        ) : (
                          ''
                        )}

                        {abordado.cnh_validade !== null ? (
                          <MDBCol>
                            CNH validade:{' '}
                            {format(
                              new Date(abordado.cnh_validade),
                              'dd/MM/yyyy'
                            )}
                          </MDBCol>
                        ) : (
                          ''
                        )}
                      </MDBRow>
                    ) : (
                      ''
                    )}
                  </div>
                )
              )}
          </MDBCardBody>
        </MDBCard>
        <br />
        {/* DADOS DE EMPRESAS */}
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-0 pr-md-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Empresas</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            {empresas.length === 0 ? <MDBRow center>Sem Empresas</MDBRow> : ''}
            {empresas.map(empresa => (
              <>
                <MDBRow
                  key={empresa.id}
                  className="p-1 mt-3 mdb-color lighten-4"
                >
                  <MDBCol>CNPJ: {empresa.cnpj}</MDBCol>
                  {empresa.nome === '' || empresa.nome === null ? (
                    ''
                  ) : (
                    <MDBCol>Nome: {empresa.nome}</MDBCol>
                  )}
                </MDBRow>
                {empresa.documento_fiscals.map(documento => (
                  <>
                    {documento.ativo ? (
                      ''
                    ) : (
                      <MDBRow className="mx-1">
                        <MDBCol className="deep-orange lighten-4 red-text">
                          <small>Deletado</small>
                        </MDBCol>{' '}
                      </MDBRow>
                    )}

                    <MDBRow
                      key={documento.id}
                      className={`mx-1 ${
                        documento.ativo
                          ? 'grey lighten-5'
                          : 'deep-orange lighten-4'
                      }`}
                    >
                      <MDBCol>Tipo: {documento.tipo}</MDBCol>
                      {documento.numero === '' || documento.numero === null ? (
                        ''
                      ) : (
                        <MDBCol>Número: {documento.numero}</MDBCol>
                      )}

                      {documento.valor === '' || documento.valor === null ? (
                        ''
                      ) : (
                        <MDBCol>
                          Valor: R${' '}
                          {documento.valor.toString().replace('.', ',')}
                        </MDBCol>
                      )}
                      {documento.icms === '' || documento.icms === null ? (
                        ''
                      ) : (
                        <MDBCol>
                          ICMS: R$ {documento.icms.toString().replace('.', ',')}
                        </MDBCol>
                      )}
                    </MDBRow>
                    <MDBRow
                      className={`mx-1 mb-1 ${
                        documento.ativo
                          ? 'grey lighten-5'
                          : 'deep-orange lighten-4'
                      }`}
                      // style={{
                      //   backgroundColor: `${
                      //     documento.ativo
                      //       ? 'rgba(0,0,0,0.1)'
                      //       : 'deep-orange lighten-4'
                      //   }`,
                      // }}
                    >
                      {documento.chave === '' || documento.chave === null ? (
                        ''
                      ) : (
                        <MDBCol>Chave: {documento.chave}</MDBCol>
                      )}
                    </MDBRow>
                  </>
                ))}
              </>
            ))}
          </MDBCardBody>
        </MDBCard>

        <br />
        {ocorrencias.map((oco, index) => (
          <>
            <MDBCard narrow key={`b${index}`}>
              <MDBView
                className="elegant-color mb-3 pr-0 pr-md-5 py-2  d-flex justify-content-between"
                cascade
              >
                <h4
                  className={`ml-2 font-weight-bolder ${
                    oco.ativo === false ? 'danger-text' : ''
                  }`}
                >
                  {`${oco.ativo === false ? 'Deletada' : ''}`}
                </h4>
                <h4 className="text-white font-weight-bolder">
                  Ocorrência Nº: {oco.id}
                </h4>
              </MDBView>

              <MDBCardBody className="pt-0">
                <MDBRow start className=" h5-responsive px-3">
                  {oco.razao_autuacao.autuado === true
                    ? 'Autuado'
                    : 'Não Autuado'}
                  : {oco.razao_autuacao.descricao}
                </MDBRow>
                <MDBRow>
                  <MDBCol size="12" md="4" middle>
                    Registro de Ocorrência: Nº:
                    {oco.ro === null ? (
                      <p className="danger-text">R.O não informado</p>
                    ) : (
                      oco.ro
                    )}
                  </MDBCol>
                  <MDBCol className="mt-2 mt-md-0" size="12" md="4" middle>
                    Autuado: {oco.autuado === true ? 'Sim' : 'Não'}
                  </MDBCol>

                  <MDBCol className="mt-3 mt-md-0" size="12" md="4" middle>
                    <span style={{ fontSize: '14px' }}>Data de Criação:</span>
                    <div>{trataDate(oco.createdAt)}</div>
                  </MDBCol>
                </MDBRow>

                <MDBRow
                  center
                  className="font-weight-bolder"
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                >
                  Pessoas Autuadas:
                </MDBRow>

                {oco.autuado_ocorrencia.map((autuado, index) =>
                  autuado.ativo ? (
                    <MDBRow className="py-2 mx-1" key={`c${index}`}>
                      <MDBCol>Nome: {autuado.abordado.nome}</MDBCol>
                      <MDBCol>Tipo: {autuado.abordado.tipo}</MDBCol>
                      <MDBCol>CPF: {autuado.abordado.cpf}</MDBCol>
                    </MDBRow>
                  ) : (
                    <>
                      <MDBRow
                        key={`c${index}`}
                        className="deep-orange lighten-4 red-text px-2 mx-1"
                      >
                        <small>Deletado</small>
                      </MDBRow>
                      <MDBRow className="py-2 mx-1 deep-orange lighten-4">
                        <MDBCol>Nome: {autuado.abordado.nome}</MDBCol>
                        <MDBCol>Tipo: {autuado.abordado.tipo}</MDBCol>
                        <MDBCol>CPF: {autuado.abordado.cpf}</MDBCol>
                      </MDBRow>
                    </>
                  )
                )}
                <br />
                <MDBRow
                  center
                  className="font-weight-bolder"
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                >
                  Autuações:
                </MDBRow>

                {oco.item_grupo_tipo_ocorrencia.map((ocorrencia, index) => (
                  <div
                    key={`d${index}`}
                    style={{
                      backgroundColor: 'rgba(0,0,0,0.08)',
                      borderRadius: '5px',
                      // border: '2px red',
                    }}
                    className={`mt-2 p-0 mx-0 border ${
                      ocorrencia.ativo === false ? 'deep-orange lighten-4 ' : ''
                    }`}
                  >
                    {ocorrencia.ativo === false ? (
                      <MDBRow start className="mx-0 text-danger font-italic">
                        Deletado
                      </MDBRow>
                    ) : (
                      ''
                    )}
                    <MDBRow className="mx-0">
                      <MDBCol size="12" md="6" className="m-2">
                        Tipo: {ocorrencia.tipo_ocorrencium.nome}
                      </MDBCol>
                      <MDBCol size="12" md="5" className="m-2">
                        Grupo: {ocorrencia.grupo.nome}
                      </MDBCol>

                      <MDBCol size="12" md="6" className="m-2">
                        Item: {ocorrencia.item.nome}
                      </MDBCol>
                      <MDBCol size="12" md="5" className="m-2">
                        Quantidade:{' '}
                        {`${
                          ocorrencia.quantidade === null
                            ? 0
                            : ocorrencia.quantidade
                        }
                              (${ocorrencia.unidade_medida.nome &&
                                ocorrencia.unidade_medida.nome})

                                `}
                      </MDBCol>
                    </MDBRow>
                  </div>
                ))}

                <br />
                <MDBRow
                  center
                  className="font-weight-bolder"
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                >
                  Agentes Responsáveis:
                </MDBRow>

                {oco.equipe_ocorrencia.map(agente => (
                  <div className="mt-2">
                    <MDBRow className="p-2">
                      <MDBCol>
                        Nome: {agente.usuario.nome} {agente.usuario.sobrenome}
                      </MDBCol>
                      <MDBCol>Nome Social: {agente.usuario.nome_social}</MDBCol>
                    </MDBRow>
                  </div>
                ))}

                <br />
                <MDBRow
                  center
                  className="font-weight-bolder"
                  style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                >
                  Dinâmica da Ocorrência:
                </MDBRow>

                {oco.ocorrencia_dinamicas.map((descricao, index) => (
                  <div
                    key={`e${index}`}
                    className={`mx-1 mt-2 p-2 ${
                      descricao.ativo
                        ? 'grey lighten-3 rounded'
                        : 'deep-orange lighten-4 rounded'
                    }`}
                    // className={`mt-2

                    // ${
                    //   descricao.ativo
                    //     ? 'text-success border-success'
                    //     : 'text-danger border-danger'
                    // } border rounded`}
                  >
                    <MDBRow between className="p-2">
                      <MDBCol
                        size="12"
                        md="6"
                        className="font-italic text-left"
                      >
                        {descricao.ativo ? (
                          <div className="green-text font-weight-bold">
                            Ativa
                          </div>
                        ) : (
                          <div className="text-danger">Deletada</div>
                        )}
                      </MDBCol>
                      <MDBCol
                        size="12"
                        md="6"
                        className="font-italic text-right"
                      >
                        Data: {trataDate(descricao.createdAt)}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="p-2 text-left">
                      <MDBCol> Dinâmica: {descricao.dinamica}</MDBCol>
                    </MDBRow>
                  </div>
                ))}
              </MDBCardBody>
            </MDBCard>
            <br />
          </>
        ))}
      </MDBContainer>
      <MDBContainer>
        {loadingBusca && (
          <MDBRow center className="my-2 align-items-center">
            <MDBSpinner small />{' '}
            <span className="ml-2 font-weight-bolder ">Carregando</span>
          </MDBRow>
        )}
        {imagens.ok ? (
          <MDBRow center className="py-3 font-weight-bold danger-text">
            {imagens.ok}
          </MDBRow>
        ) : (
          imagens.map(res => (
            <MDBRow center>
              <MDBCol size="12" md="8">
                <img
                  key={res.id}
                  // id={res.id}

                  // style={{ objectFit: 'cover' }}

                  className="img-fluid col-12 my-3"
                  src={`${Buffer.from(res.imagem, 'base64')}`}
                  // onClick={e => {
                  //   setModalFoto({ id: res.id, base: res.imagem });
                  //   // src={imagens}
                  // }}
                />
              </MDBCol>
            </MDBRow>
          ))
        )}
      </MDBContainer>
    </>
  );
}
