import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCollapse,
} from 'mdbreact';
// roteamento
import { useHistory } from 'react-router';
import NavBar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import UserJpg from '../../../assets/user.jpg';
// import { Container } from './styles';
import api from '../../../services/api';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { VerificaAcesso } from '../../../components/VerificaAcesso';

function Perfil(props) {
  const [dados, setDados] = useState({});

  const [foto, setFoto] = useState('');
  const [lotacao, setLotacao] = useState({});

  const [dadosGerencias, setDadosGerencias] = useState([]);
  const [gerencia, setGerencia] = useState('');

  // LISTAGEM DE ORGAOS PARA SELECT
  const [dadosOrgaos, setDadosOrgaos] = useState([]);
  // LISTAGEM DE TITULOS PARA SELECT
  const [dadosTitulos, setDadosTitulos] = useState([]);
  // INSERT ADIDO
  const [orgao, setOrgao] = useState('');
  const [titulo, setTitulo] = useState('');

  const [carregando, setCarregando] = useState(false);
  const [adido, setAdido] = useState(false);
  // ABRE FORMA ADIDO
  const [adidoForm, setAdidoForm] = useState(false);
  //LISTAGEM DE LOTACAO
  const [dadosLotacao, setDadosLotacao] = useState([]);
  const [lotacaoId, setLotacaoId] = useState('');

  const [buscaModulos, setBuscaModulos] = useState([]);

  const [modalAcesso, setModalAcesso] = useState(false);
  const [updateAcesso, setUpdateAcesso] = useState([]);

  const idReq = props.match.params.id;
  // roteamento
  let history = useHistory();
  // coloca dentro do select de gerencia
  const loadGerencias = async () => {
    const response = await api.get('/gerencia');
    setDadosGerencias(response.data);
    // toast.error('testeeeeeee');
  };

  // carrega select de lotacao apos selecionar gerencia
  const loadLotacao = async gerencia_id => {
    const response = await api.get('/lotacao', {
      params: {
        gerencia_id: gerencia_id,
      },
    });
    setDadosLotacao(response.data);
    // toast.error('testeeeeeee');
  };

  const loadDados = async () => {
    // const response = await api.get(`/usuario/${idReq}`);

    const response = await api.get(`/usuario/${idReq}`);
    // roteamento
    if (response.data === 'Acesso Proibido') {
      toast.error('Acesso Negado, Falha reportada ao administrador!');
      history.push('/app');
      return;
    }

    const { lotacao, ...dados } = response.data;

    // popula os dados dentro do select de lotacao
    let escala = {};
    escala.lotacaoGerencia = lotacao.gerencia.nome;
    escala.lotacaoAla = lotacao.ala.nome;
    escala.lotacaoEscala = lotacao.escala.nome;
    escala.gerenciaId = lotacao.gerencia.id;

    // escala.setorArea = lotacao.setor.area.nome
    // escala.setorGuarnicao = lotacao.setor.guarnicao.nome
    // geraModulos(escala.gerenciaId);
    setGerencia(escala.gerenciaId);
    setDados(dados);
    setLotacao(escala);
  };

  const EnviaLotacao = async () => {
    let dados = {};
    dados.lotacao_id = lotacaoId;
    if (dados.lotacao_id === '') {
      delete dados.lotacao_id;
    }

    try {
      const response = await api.put(`/usuario/${idReq}`, dados);

      toast.success('Usuário Alterado!');
      loadDados();
    } catch (error) {
      toast.error('Usuário não pôde ser alterado!');
    }
  };

  const PermissaoDeAcesso = async () => {
    const resultado = await VerificaAcesso(window.location.pathname);
    if (resultado === false) {
      history.push('/app');
    }
  };

  useEffect(() => {
    PermissaoDeAcesso();
    loadDados();
    loadGerencias();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex flex-column-reverse flex-md-row  align-items-stretch">
            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between mt-2 mt-md-0"
            >
              <MDBCard>
                <MDBCardBody className="d-flex flex-column">
                  <p className="h5 text-center">Informações</p>
                </MDBCardBody>
              </MDBCard>
              <br />

              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBTable striped className="mt-3">
                  <MDBTableBody>
                    <tr>
                      <td>Gerência Atual</td>
                      <td className="font-weight-bold">
                        {lotacao.lotacaoGerencia}
                      </td>
                    </tr>
                    <tr>
                      <td>Lotação Atual</td>
                      <td>
                        {lotacao.lotacaoEscala} / {lotacao.lotacaoAla}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCard>
              <MDBCard className="h-100 mt-2 mt-md-4">
                <p className="h5 text-center mt-3">Alterar Lotação</p>
                <MDBCardBody>
                  <span className="grey-text">Gerência</span>
                  <select
                    className="browser-default custom-select mb-3"
                    onChange={e => {
                      // loadTitulos(e.target.value);
                      setGerencia(e.target.value);
                      loadLotacao(e.target.value);
                    }}
                  >
                    <option value="0">Selecione</option>
                    {dadosGerencias.map(e => (
                      <option key={e.id} value={e.id}>
                        {e.nome}
                      </option>
                    ))}
                  </select>

                  <span className="grey-text">Lotação</span>
                  <select
                    className="browser-default custom-select mb-3"
                    onChange={e => {
                      // loadTitulos(e.target.value);

                      setLotacaoId(e.target.value);
                    }}
                  >
                    <option value="null">Selecione</option>
                    {dadosLotacao.map(e => (
                      <option key={e.id} value={e.id}>
                        {`${e.escala.nome} / ${e.ala.nome}`}
                      </option>
                    ))}
                  </select>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between"
            >
              <MDBCard className="h-100">
                <MDBCardBody>
                  <form>
                    <MDBContainer>
                      <MDBRow className="justify-content-center">
                        <p className="h5 text-center">Perfil</p>
                      </MDBRow>

                      <div className="text-center d-print-none">
                        <img
                          style={{ width: '200px', height: '200px' }}
                          alt="foto do perfil"
                          src={
                            dados.foto_usuario
                              ? dados.foto_usuario.url
                              : UserJpg
                          }
                        />
                      </div>

                      <MDBRow>
                        <MDBCol col="6" md="4">
                          <div>Status:</div>
                        </MDBCol>
                        <MDBCol
                          className="red-text d-flex justify-content-end"
                          col="6"
                          md="8"
                        >
                          {dados.ativo ? (
                            <div className="w-50 text-center text-success rounded-pill px-4  border border-success font-weight-bold">
                              Nomeado
                            </div>
                          ) : (
                            <div className="w-50 text-center text-danger rounded-pill px-4 border border-danger font-weight-bold">
                              Exonerado
                            </div>
                          )}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Nome:</div>
                        </MDBCol>
                        <MDBCol className="red-text text-right" col="6" md="8">
                          {dados.nome} {dados.sobrenome}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Nome Social:</div>
                        </MDBCol>
                        <MDBCol className="red-text text-right" col="6" md="8">
                          {dados.nome_social}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Gênero:</div>
                        </MDBCol>
                        <MDBCol col="6" md="8" className="text-right">
                          {dados.genero === 'F' ? 'Feminino' : ''}
                          {dados.genero === 'M' ? 'Masculino' : ''}
                          {dados.genero === 'N' ? 'Não Declarado' : ''}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Categoria CNH:</div>
                        </MDBCol>
                        <MDBCol className="text-right" col="6" md="8">
                          {dados.cnh_categoria}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Telefone:</div>
                        </MDBCol>
                        <MDBCol className="text-right" col="6" md="8">
                          {dados.telefone}
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="mt-2">
                        <MDBCol col="6" md="4">
                          <div>Email:</div>
                        </MDBCol>
                        <MDBCol className="text-right" col="6" md="8">
                          {dados.email}
                        </MDBCol>
                      </MDBRow>

                      <br />

                      {/* <MDBRow>
                        <span className="grey-text">Adido</span>
                        <select
                          className="browser-default custom-select"
                          onChange={e => {
                            setAdido(e.target.value);
                            setAdidoForm(!adidoForm);
                          }}
                          value={adido}
                        >
                          <option value="true">SIM</option>
                          <option value="false">NÃO</option>
                        </select>
                      </MDBRow>
                      <MDBRow> */}
                      {/* <MDBCollapse id="basicCollapse" isOpen={adidoForm}>
                          <MDBCard>
                            <MDBCardBody>
                              <div className="h5-responsive text-start font-weight-bold">
                                Adido
                              </div>
                              <MDBRow className="justify-content-center">
                                <MDBCol md="6">
                                  <span className="grey-text">Orgão</span>
                                  <select
                                    className="browser-default custom-select"
                                    onChange={e => {
                                      loadTitulos(e.target.value);
                                      setOrgao(e.target.value);
                                    }}
                                    value={orgao}
                                  >
                                    {dadosOrgaos.map(e => (
                                      <option key={e.id} value={e.id}>
                                        {e.nome}
                                      </option>
                                    ))}
                                  </select>
                                </MDBCol>
                                <MDBCol md="6">
                                  <span className="grey-text">Título</span>
                                  <select
                                    className="browser-default custom-select"
                                    onChange={e => setTitulo(e.target.value)}
                                    // defaultValue="option"
                                    value={titulo}
                                  >
                                    <option value="option" disabled>
                                      {carregando == true
                                        ? 'Carregando... '
                                        : 'Selecione seu Título'}
                                    </option>
                                    {dadosTitulos.map(e => (
                                      <option key={e.id} value={e.id}>
                                        {e.nome}
                                      </option>
                                    ))}
                                  </select>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                          <br />
                        </MDBCollapse>
                      </MDBRow> */}
                    </MDBContainer>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow className="justify-content-center mt-3">
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                EnviaLotacao();
              }}
            >
              Salvar Alterações
            </MDBBtn>
          </MDBRow>
          {/* <MDBIcon icon="check" /> */}
          {/* <MDBIcon icon="times" /> */}

          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Perfil;
