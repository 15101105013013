import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
import { getData, getPerfil } from '../../../../services/auth';
import { format, parseISO, isAfter } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBCard,
  MDBListGroup,
  MDBListGroupItem,
  MDBCardText,
  MDBCardTitle,
  MDBCardT,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
  MDBCardBody,
  MDBTypography,
  MDBBox,
} from 'mdbreact';

import NavBar from '../../../../components/NavBar';
import api from '../../../../services/api';

export default function App() {
  const [loading, setLoading] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [page, setPage] = useState(1);
  const [qtdPages, setQtdPages] = useState(0);
  const [qtdTotal, setQtdTotal] = useState(0);
  const [qtdPorPagina, setQtdPorPagina] = useState({
    label: '10 registros',
    value: 10,
  });

  const [lista, setLista] = useState([]);

  const [listaGerencias, setListaGerencias] = useState([]);

  // const [gerenciaSelecionada, setGerenciaSelecionada] = useState({
  //   value: '',
  //   label: '',
  // });
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState({
    value: getData().gerencia_id,
    label: '',
  });

  const [assuntoSelecionado, setAssuntoSelecionado] = useState({
    label: '',
    value: '',
  });

  const [dataInicio, setDataInicio] = useState('');
  const [dataFinal, setDataFinal] = useState('');

  const [modalInserir, setModalInserir] = useState(false);

  const [listaTipos, setListaTipos] = useState('');
  const [listaTiposInserir, setListaTiposInserir] = useState('');

  //
  // cadastro
  //
  const [assuntoSelecionadoInserir, setAssuntoSelecionadoInserir] = useState({
    label: '',
    value: '',
  });
  const [dataCadastro, setDataCadastro] = useState('');
  const [horaInicioCadastro, setHoraInicioCadastro] = useState('');
  const [horaFimCadastro, setHoraFimCadastro] = useState('');
  const [quantidadeCadastro, setQuantidadeCadastro] = useState('');

  const [downloadDadosEstatisticos, setDownloadDadosEstatisticos] = useState(
    []
  );
  const [csvLoading, setCsvLoading] = useState(false);

  //
  //
  // alteracao
  //
  //

  const [modalAlterar, setModalAlterar] = useState('');
  const [assuntoSelecionadoAlterar, setAssuntoSelecionadoAlterar] = useState(
    ''
  );
  const [dataCadastroEditar, setDataCadastroEditar] = useState('');
  const [horaInicioCadastroEditar, setHoraInicioCadastroEditar] = useState('');
  const [horaFimCadastroEditar, setHoraFimCadastroEditar] = useState('');
  const [quantidadeCadastroEditar, setQuantidadeCadastroEditar] = useState('');

  const idUser = getData().id;

  // const [checkSemDespacho, setCheckSemDespacho] = useState(false);

  const enviaDadosOperacionais = async () => {
    if (assuntoSelecionadoInserir.value === '') {
      toast.error('Selecione o Assunto');
      return;
    }
    if (quantidadeCadastro === '') {
      toast.error('Preencha Quantidade');
      return;
    }
    if (dataCadastro === '') {
      toast.error('Preencha data');
      return;
    }
    if ( isAfter(new Date(dataCadastro), new Date())) {
      toast.error('Não Preencha datas futuras');
      return;
    }

    if (horaInicioCadastro === '') {
      toast.error('Preencha hora início');
      return;
    }
    if (horaFimCadastro === '') {
      toast.error('Preencha hora Final');
      return;
    }
    if (horaInicioCadastro > horaFimCadastro) {
      toast.error('Hora Início maior que a fim');
      return;
    }
    setLoadingEnviar(true);

    const result = await api.post('/dados-operacionais', {
      dt_h_inicio: parseISO(`${dataCadastro} ${horaInicioCadastro}`),
      dt_h_fim: parseISO(`${dataCadastro} ${horaFimCadastro}`),
      quantidade: quantidadeCadastro,
      tipo_dado_operacional_id: assuntoSelecionadoInserir.value,

      // despacho: checkSemDespacho,
    });
    setLoadingEnviar(false);
    setModalInserir(!modalInserir);
    setDataCadastro('');
    setHoraInicioCadastro('');
    setHoraFimCadastro('');
    setQuantidadeCadastro('');
    setAssuntoSelecionadoInserir({ label: '', value: '' });

    buscaAssuntos();
  };

  const editaDadosOperacionais = async () => {
    if (quantidadeCadastroEditar === '') {
      toast.error('Preencha Quantidade');
      return;
    }
    if (dataCadastroEditar === '') {
      toast.error('Preencha data');
      return;
    }
    if ( isAfter(new Date(dataCadastroEditar), new Date())) {
      toast.error('Não Preencha datas futuras');
      return;
    }
    if (horaInicioCadastroEditar === '') {
      toast.error('Preencha hora início');
      return;
    }
    if (horaFimCadastroEditar === '') {
      toast.error('Preencha hora Final');
      return;
    }
    if (horaInicioCadastroEditar > horaFimCadastroEditar) {
      toast.error('Hora Início maior que a fim');
      return;
    }
    setLoadingEnviar(true);

    const result = await api.put(`/dados-operacionais/${modalAlterar}`, {
      dt_h_inicio: parseISO(
        `${dataCadastroEditar} ${horaInicioCadastroEditar}`
      ),
      dt_h_fim: parseISO(`${dataCadastroEditar} ${horaFimCadastroEditar}`),
      quantidade: quantidadeCadastroEditar,
      // despacho: checkSemDespacho,
    });
    if (result.data.ok) {
      toast.success(result.data.ok);
    }
    if (result.data.error) {
      toast.success(result.data.error);
    }
    setLoadingEnviar(false);
    setModalAlterar('');
    setDataCadastroEditar('');
    setHoraInicioCadastroEditar('');
    setHoraFimCadastroEditar('');
    setQuantidadeCadastroEditar('');
    setAssuntoSelecionadoAlterar();

    buscaAssuntos();
  };

  const carregaGerencias = () => {
    const select = [];

    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });

        if (linha.id === getData().gerencia_id) {
          setGerenciaSelecionada({
            label: linha.nome,
            value: String(linha.id),
          });
        }
      });

      setListaGerencias(select);
    });

    const listaTipos = [];
    const listaTiposInserir = [];
    api.get('/tipo-dado-operacional').then(res => {
      for (const tipo of res.data) {
        listaTipos.push({
          value: tipo.id,
          label: tipo.nome,
        });
        if (tipo.ativo === true) {
          listaTiposInserir.push({
            value: tipo.id,
            label: tipo.nome,
            motivo: tipo.motivo,
            unidade: tipo.unidade_medida.nome,
          });
        }
      }
    });

    setListaTipos(listaTipos);
    setListaTiposInserir(listaTiposInserir);

    // setBotaoEditarLotacao(acessoEditarLotacao.data);
  };
  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    setLoading(true);
    const params = {
      assunto_id: assuntoSelecionado.value,
      dt_h_inicio: dataInicio,
      dt_h_fim: dataFinal,
      gerencia_id: gerenciaSelecionada.value,
      // qtd_por_pagina: qtdPorPagina.value,
      page: page,
      ativo: true,
    };

    const lista = await api.get('/dados-operacionais', {
      params: params,
    });

    setLista(lista.data.rows);

    setQtdPages(Math.ceil(Number(lista.data.count) / Number(10)));
    setQtdTotal(lista.data.count);

    // console.log(novaLista);
    setDownloadDadosEstatisticos([]);
    setLoading(false);
  };

  const exportData = async () => {
    setCsvLoading(true);
    const params = {
      assunto_id: assuntoSelecionado.value,
      dt_h_inicio: dataInicio,
      dt_h_fim: dataFinal,
      gerencia_id: gerenciaSelecionada.value,
      // qtd_por_pagina: qtdPorPagina.value,
      // page: page,
      ativo: true,
    };

    const lista = await api.get('/dados-operacionais-download', {
      params: params,
    });

    const novaLista = [];
    lista.data.forEach(linha => {
      novaLista.push({
        gerencia: linha.lotacao.gerencia.nome,
        ala: linha.lotacao.ala.nome,
        evento: linha.tipo_dado_operacional.nome,
        criacao: format(
          new Date(linha.createdAt),

          'dd/MM/yyyy - HH:mm'
        ),
        dt_inicio: format(new Date(linha.dt_h_inicio), 'dd/MM/yyyy - HH:mm'),
        dt_final: format(new Date(linha.dt_h_fim), 'dd/MM/yyyy - HH:mm'),
        quantidade: linha.quantidade,
        medida: linha.tipo_dado_operacional.unidade_medida.nome,
        agente: linha.usuario.nome_social,
      });
    });

    setDownloadDadosEstatisticos(novaLista);
    // setExportList(newList);
    setCsvLoading(false);
  };

  useEffect(() => {
    carregaGerencias();
    // buscaAssuntos();
  }, []);

  useEffect(() => {
    buscaAssuntos();
  }, [page, qtdPorPagina]);

  return (
    <NavBar>
      <MDBContainer fluid className="p-0 m-0">
        <MDBRow className="h4-responsive" center>
          Dados Operacionais
        </MDBRow>
        <MDBRow center>
          <MDBCol size="12" md="3">
            <div>Selecione a Gerência</div>

            <Select
              options={listaGerencias}
              onChange={e => {
                if (e === null) {
                  setGerenciaSelecionada({ label: '', value: '' });
                  return;
                }
                setGerenciaSelecionada(e);
              }}
              value={gerenciaSelecionada}
              placeholder="Gerência"
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Evento Operacional</div>
            <Select
              options={listaTipos}
              isClearable
              onChange={e => {
                if (e === null) {
                  setAssuntoSelecionado({ label: '', value: '' });
                  return;
                }
                setAssuntoSelecionado(e);
              }}
              placeholder="Evento Operacional"
            />
          </MDBCol>

          <MDBCol size="12" md="3">
            <span>Data Início</span>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataInicio(parseISO(e.target.value))}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <div>Data Final</div>
            <MDBInput
              style={{ marginTop: '-28px' }}
              type="date"
              outline
              placeholder="Data Final"
              className="bg-white"
              onChange={e => setDataFinal(parseISO(e.target.value))}
            />
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBBtn
            disabled={loading}
            onClick={() => {
              setPage(Number(1));
              buscaAssuntos();
            }}
          >
            Buscar
          </MDBBtn>
          <MDBBtn
            disabled={loading}
            onClick={() => setModalInserir(!modalInserir)}
          >
            Inserir
          </MDBBtn>
          <MDBBtn
            size="sm"
            className="d-flex align-items-center p-2 "
            onClick={() => exportData()}
          >
            <MDBIcon fas icon="cloud-download-alt" size="2x" />
          </MDBBtn>
          {csvLoading === true ? (
            <div className="d-flex align-items-center">
              <MDBSpinner small />{' '}
              <span className="ml-2 font-weight-bolder ">Carregando</span>
            </div>
          ) : (
            ''
          )}
          {downloadDadosEstatisticos.length < 1 ? (
            ''
          ) : (
            <CsvDownloader
              className="danger-text font-weight-bolder d-flex align-items-center"
              style={{ cursor: 'pointer' }}
              filename={
                'Inteligencia_' +
                format(
                  zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
                  'ddMMyyyy_HHmm'
                )
              }
              extension=".csv"
              separator=";"
              // wrapColumnChar="'"
              datas={downloadDadosEstatisticos}
              // text="DOWNLOAD"
            >
              {'Dados_' +
                format(
                  zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
                  'ddMMyyyy_HHmm'
                )}
              .csv
            </CsvDownloader>
          )}
        </MDBRow>
        <MDBRow className="font-weight-bolder justify-content-end pr-3">
          Total: {qtdTotal}
        </MDBRow>
        {loading === true ? (
          <MDBRow className="mt-5 text-center d-flex justify-content-center align-items-center">
            <MDBSpinner />
            <span className="ml-3 font-weight-bold">Carregando</span>
          </MDBRow>
        ) : lista.length !== 0 ? (
          lista.map(item => (
            <MDBCard className="mt-3" key={item.id}>
              <MDBCardTitle
                style={{
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  borderBottom: '1px solid rgba(0,0,0,0.1)',
                }}
                className="text-center p-2 h5-responsive"
              >
                <MDBRow>
                  <MDBCol size="12" md="4">
                    <span>Gerência:</span> <br />
                    <span className="font-weight-bolder">
                      {item.lotacao.gerencia.nome} / {item.lotacao.ala.nome}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>

                  <MDBCol size="12" md="4">
                    <span>Evento Operacional:</span> <br />
                    <span className="font-weight-bolder">
                      {item.tipo_dado_operacional.nome}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>

                  <MDBCol size="12" md="3">
                    <span>Criação:</span>
                    <br />
                    <span className="font-weight-bolder">
                      {format(
                        zonedTimeToUtc(
                          new Date(item.createdAt),
                          'America/Sao_Paulo'
                        ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                    </span>
                  </MDBCol>
                  <MDBCol middle size="1">
                    {/* <span style={{ fontSize: '1rem' }}>Editar</span> */}
                    {/* <br /> */}

                    {item.usuario.id === idUser ? (
                      <MDBIcon
                        className="danger-text cursor-pointer"
                        far
                        icon="edit"
                        onClick={() => {
                          setModalAlterar(item.id);

                          setAssuntoSelecionadoAlterar({
                            tipo: item.tipo_dado_operacional.nome,
                            medida:
                              item.tipo_dado_operacional.unidade_medida.nome,
                          });
                          setDataCadastroEditar(
                            format(new Date(item.dt_h_inicio), 'yyyy-MM-dd')
                          );
                          setHoraInicioCadastroEditar(
                            format(new Date(item.dt_h_inicio), 'HH:mm')
                          );
                          setHoraFimCadastroEditar(
                            format(new Date(item.dt_h_fim), 'HH:mm')
                          );
                          setQuantidadeCadastroEditar(item.quantidade);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </MDBCol>
                </MDBRow>
              </MDBCardTitle>
              <MDBCardBody>
                <MDBRow center>
                  <MDBCol
                    className="d-flex flex-column align-items-center"
                    size="12"
                    md="4"
                  >
                    <span>Data Inicio:</span>
                    <span className="font-weight-bolder">
                      {format(
                        // zonedTimeToUtc(
                        new Date(item.dt_h_inicio),
                        //   'America/Sao_Paulo'
                        // ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol
                    className="d-flex flex-column align-items-center"
                    size="12"
                    md="4"
                  >
                    <span>Data Final:</span>
                    <span className="font-weight-bolder">
                      {format(
                        // zonedTimeToUtc(
                        new Date(item.dt_h_fim),
                        //   'America/Sao_Paulo'
                        // ),
                        'dd/MM/yyyy - HH:mm'
                      )}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                  <MDBCol
                    className="d-flex flex-column align-items-center"
                    size="12"
                    md="4"
                  >
                    <span>Quantidade/Medida:</span>
                    <span className="font-weight-bolder">
                      {item.quantidade}{' '}
                      {item.tipo_dado_operacional.unidade_medida.nome}
                    </span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow end>
                  <MDBCol
                    className="d-flex justify-content-end font-weight-light font-italic"
                    size="12"
                    md="4"
                  >
                    <span>Inserido por:</span>
                    <span className="ml-2">{item.usuario.nome_social}</span>
                    <div
                      style={{ borderBottom: '1px solid gray' }}
                      className="d-flex d-md-none my-2"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          ))
        ) : (
          'Sem Dados'
        )}
        <br />
        <MDBRow className="justify-content-center align-items-center">
          {/* <MDBCol size="2">teste</MDBCol> */}
          <MDBCol
            size="8"
            className="d-flex justify-content-center align-items-center"
          >
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  // className={`mx-3 grey-text`}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(1));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-left"
                />
              </span>
              <span>Primeira Página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>

            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(page) - 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-left"
                />
              </span>
              <span>Página Anterior</span>
            </MDBTooltip>
            <div>
              Página {page} de {qtdPages}
            </div>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdPages ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page >= qtdPages) {
                      return;
                    }
                    setPage(Number(page) + 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-right"
                />
              </span>
              <span>Próxima página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdPages ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == qtdPages) {
                      return;
                    }
                    setPage(Number(qtdPages));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-right"
                />
              </span>
              <span>Última página</span>
            </MDBTooltip>
          </MDBCol>
          {/* <MDBCol className="text-center" size="2">
            <span className="font-weight-bolder">Quantidade:</span>
            <Select
              className="text-center"
              placeholder="Quantidade"
              options={[
                { label: '10 registros', value: 10 },
                { label: '20 registros', value: 20 },
                { label: '50 registros', value: 50 },
              ]}
              value={qtdPorPagina}
              onChange={e => setQtdPorPagina(e)}
            />
          </MDBCol> */}
        </MDBRow>
        <br />
        <MDBModal
          isOpen={modalInserir}
          size="lg"
          toggle={() => setModalInserir(!modalInserir)}
        >
          <MDBModalHeader toggle={() => setModalInserir(!modalInserir)}>
            Inserir novo dado estatístico
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="12" md="8">
                <div>Evento Operacional</div>
                <Select
                  options={listaTiposInserir}
                  onChange={e => {
                    if (e === null) {
                      setAssuntoSelecionadoInserir({ label: '', value: '' });
                      return;
                    }
                    setAssuntoSelecionadoInserir(e);
                  }}
                  placeholder="Evento Operacional"
                  isClearable
                />
              </MDBCol>
              <MDBCol size="12" md="4" className="mt-1">
                <span>Insira a Quantidade</span>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="number"
                  outline
                  placeholder={
                    assuntoSelecionadoInserir.unidade === undefined
                      ? 'Unidade de Medida'
                      : assuntoSelecionadoInserir.unidade
                  }
                  className="bg-white"
                  onChange={e => setQuantidadeCadastro(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mx-2">
              {assuntoSelecionadoInserir.motivo &&
              assuntoSelecionadoInserir.motivo !== '' ? (
                <MDBTypography blockquote bqColor="warning">
                  <MDBBox tag="p" mb={0} className="bq-title">
                    Descrição do Evento Operacional
                  </MDBBox>
                  <p>{assuntoSelecionadoInserir.motivo}</p>
                </MDBTypography>
              ) : (
                ''
              )}
            </MDBRow>
            <MDBRow center className="align-items-center">
              <MDBCol size="12" md="3" className="mt-3 mt-md-0">
                <span>Data</span>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="date"
                  outline
                  placeholder="Data Final"
                  className="bg-white"
                  value={dataCadastro}
                  onChange={e =>
                    // setDataCadastro(parseISO(e.target.value))
                    setDataCadastro(e.target.value)
                  }
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <div>Hora Inicial</div>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="time"
                  outline
                  placeholder="Hora Inicial"
                  className="bg-white"
                  value={horaInicioCadastro}
                  onChange={e => setHoraInicioCadastro(e.target.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <div>Hora Final</div>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="time"
                  outline
                  placeholder="Data Final"
                  className="bg-white"
                  value={horaFimCadastro}
                  onChange={e => setHoraFimCadastro(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="secondary"
              onClick={() => setModalInserir(!modalInserir)}
            >
              Cancelar
            </MDBBtn>
            <MDBBtn
              disabled={loadingEnviar}
              onClick={() => enviaDadosOperacionais()}
              color="success"
            >
              {loadingEnviar ? 'Enviando...' : 'Enviar'}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
        <MDBModal
          isOpen={modalAlterar !== ''}
          size="lg"
          toggle={() => setModalAlterar('')}
        >
          <MDBModalHeader toggle={() => setModalAlterar('')}>
            Alterar dado estatístico
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow center>
              <MDBCol size="12" md="8">
                <div>Evento Operacional</div>
                <span className="font-weight-bolder">
                  {assuntoSelecionadoAlterar?.tipo}
                </span>
              </MDBCol>
              <MDBCol size="12" md="4" className="mt-1">
                <span>Insira a Quantidade - </span>
                <span className="font-weight-bolder">
                  {assuntoSelecionadoAlterar?.medida}
                </span>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="number"
                  outline
                  className="bg-white"
                  value={quantidadeCadastroEditar}
                  onChange={e => setQuantidadeCadastroEditar(e.target.value)}
                />
              </MDBCol>
            </MDBRow>

            <MDBRow center className="align-items-center">
              <MDBCol size="12" md="3" className="mt-3 mt-md-0">
                <span>Data</span>

                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="date"
                  outline
                  placeholder="Data Final"
                  className="bg-white"
                  value={dataCadastroEditar}
                  onChange={
                    e => {
                      setDataCadastroEditar(e.target.value);
                    }
                    // setDataCadastro(e.target.value)
                  }
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <div>Hora Inicial</div>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="time"
                  outline
                  placeholder="Hora Inicial"
                  className="bg-white"
                  value={horaInicioCadastroEditar}
                  onChange={e => {
                    setHoraInicioCadastroEditar(e.target.value);
                  }}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <div>Hora Final</div>
                <MDBInput
                  style={{ marginTop: '-28px' }}
                  type="time"
                  outline
                  placeholder="Data Final"
                  className="bg-white"
                  value={horaFimCadastroEditar}
                  onChange={e => setHoraFimCadastroEditar(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color="secondary" onClick={() => setModalAlterar('')}>
              Cancelar
            </MDBBtn>
            <MDBBtn
              disabled={loadingEnviar}
              onClick={() => editaDadosOperacionais()}
              color="success"
            >
              {loadingEnviar ? 'Alterando...' : 'Alterar'}
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    </NavBar>
  );
}
