import React, { useEffect, useState } from 'react';

import { MDBIcon, MDBRow, MDBTooltip } from 'mdbreact';

////////////////////
//////////////////// COMO USAR DENTRO DA PAGINA
////////////////////

// const [currentPage, setCurrentPage] = useState(1);

// const handlePageChange = newPage => {
//   setCurrentPage(newPage);
//   // E aqui você faria mais alguma lógica, como buscar novos dados com base na nova página
// };

//////////FRONTEND
/*      <Paginacao
          page={currentPage}
          qtdTotal={listaDenuncias.count}
          onPageChange={handlePageChange}
          />
*/
/////////// BACKEND
//
// const limit = req.query.limit ? req.query.limit : 10;
//     const result = await MODELLL.findAndCountAll({
//       limit,
//       offset: (req.query.page - 1 || 0) * Number(10),
//       order: [['id', 'DESC']],
//     });

const Paginacao = ({ page, qtdTotal, onPageChange }) => {
  // const [page, setPage] = useState(1);
  const handlePageClick = newPage => {
    // Aqui você chama a função onPageChange passando a nova página
    onPageChange(newPage);
  };
  return (
    <MDBRow className="justify-content-center my-4 align-items-center">
      <MDBTooltip domElement tag="span" material placement="top">
        <span>
          <MDBIcon
            className={`mx-3 ${
              page == 1 ? 'grey-text' : 'black-text cursor-pointer'
            } `}
            // className={`mx-3 grey-text`}
            onClick={e => {
              if (page == 1) {
                return;
              }
              // setPage(Number(1));
              handlePageClick(1);
            }}
            style={{
              fontSize: '30px',
            }}
            icon="angle-double-left"
          />
        </span>
        <span>Primeira Página</span>
      </MDBTooltip>
      <span className="grey-text font-weight-bolder">|</span>

      <MDBTooltip domElement tag="span" material placement="top">
        <span>
          <MDBIcon
            className={`mx-3 ${
              page == 1 ? 'grey-text' : 'black-text cursor-pointer'
            } `}
            onClick={e => {
              if (page == 1) {
                return;
              }
              handlePageClick(page - 1);
              // setPage(Number(page) - 1);
            }}
            style={{
              fontSize: '30px',
            }}
            icon="angle-left"
          />
        </span>
        <span>Página Anterior</span>
      </MDBTooltip>
      <div>
        Página {page} de {Math.ceil(Number(qtdTotal) / Number(10))}
      </div>
      <MDBTooltip domElement tag="span" material placement="top">
        <span>
          <MDBIcon
            className={`mx-3 ${
              page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
            } `}
            onClick={e => {
              if (page >= Math.ceil(Number(qtdTotal) / Number(10))) {
                return;
              }
              // setPage(Number(page) + 1);
              handlePageClick(page + 1);
            }}
            style={{
              fontSize: '30px',
            }}
            icon="angle-right"
          />
        </span>
        <span>Próxima página</span>
      </MDBTooltip>
      <span className="grey-text font-weight-bolder">|</span>
      <MDBTooltip domElement tag="span" material placement="top">
        <span>
          <MDBIcon
            className={`mx-3 ${
              page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
            } `}
            onClick={e => {
              if (page == Math.ceil(Number(qtdTotal) / Number(10))) {
                return;
              }
              // setPage(Math.ceil(Number(qtdTotal) / Number(10)));
              handlePageClick(Math.ceil(Number(qtdTotal) / Number(10)));
            }}
            style={{
              fontSize: '30px',
            }}
            icon="angle-double-right"
          />
        </span>
        <span>Última página</span>
      </MDBTooltip>
    </MDBRow>
  );
};

export default Paginacao;
