import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBIcon, MDBBtn, MDBCollapse } from 'mdbreact';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';

// import { Container } from './styles';
// import api from '../../../services/api';

export default class Patrimonio extends Component {
  state = {
    collapseID: '',
  };

  async componentDidMount() {
    // const response = await api.get(`/usuario/${idReq}`);
    // const { arquivo, ...dadosUsuario } = response.data;
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }));
  };

  render() {
    return (
      <>
        <NavBar />

        <MDBContainer fluid>
          <MDBRow
            style={{ borderRadius: '5px' }}
            className="flex-column-reverse flex-md-row justify-content-between align-items-center text-center"
          >
            <MDBBtn
              color="primary"
              onClick={this.toggleCollapse('basicCollapse')}
              className="col-8 col-md-2  d-flex justify-content-around align-items-center"
            >
              <MDBIcon icon="search" size="2x" />
              <h5 className="m-0">FILTRAR</h5>
            </MDBBtn>
            <h3 className="h3-responsive  font-weight-bolder">
              Listagem de Patrimônios
            </h3>
            <div className="col-2"> </div>
          </MDBRow>

          <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 text-center  bg-white"
            >
              <div className="col-12 col-md-4">
                <select className="browser-default custom-select">
                  <option selected disabled>
                    Tipo
                  </option>
                  <option value="true">Mobília</option>
                  <option value="false">Equipamento</option>
                </select>
              </div>

              <div className="col-12 col-md-4">
                <select className="browser-default custom-select">
                  <option selected disabled>
                    Gerência
                  </option>
                  <option value="true">OBF</option>
                  <option value="false">LEI SECA</option>
                </select>
              </div>
              <div className="col-12 col-md-4">
                <select className="browser-default custom-select">
                  <option selected disabled>
                    STATUS
                  </option>
                  <option value="true">OBF</option>
                  <option value="false">LEI SECA</option>
                </select>
              </div>
            </MDBRow>
          </MDBCollapse>

          <MDBRow className="m-3 d-none d-md-flex">
            <div className="col-12 col-md-3">Tipo</div>
            <div className="col-12 col-md-3">Aquisição</div>
            <div className="col-12 col-md-3">Nome</div>
            <div className="col-12 col-md-3">Numero BP</div>
          </MDBRow>
          <MDBRow
            style={{ borderRadius: '5px' }}
            className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3  bg-white"
          >
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Tipo:</span>
              <span>Equipamento</span>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Nome:</span>
              Rádio Portátil
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Numero BP:</span>
              102221
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Status:</span>
              <span className="text-success font-weight-bold">ATIVA</span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-center">
              <Link to="/visualizar-patrimonio" className="text-danger">
                <MDBIcon className="mr-1" far icon="edit" size="1x" />
                <span>Ver</span>
              </Link>
            </div>
          </MDBRow>
          <MDBRow
            style={{ borderRadius: '5px' }}
            className="p-3 mr-1 ml-1 mt-1 mr-md-3 ml-md-3  bg-white"
          >
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Tipo:</span>
              <span>Mobília</span>
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Nome:</span>
              Gaveteiro com chave
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Numero BP:</span>
              342444
            </div>
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline d-md-none mr-3">Status:</span>
              <span className="text-success font-weight-bold">ATIVA</span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-center">
              <Link to="/visualizar-patrimonio" className="text-danger">
                <MDBIcon className="mr-1" far icon="edit" size="1x" />
                <span>Ver</span>
              </Link>
            </div>
          </MDBRow>
          <br />
        </MDBContainer>

        {/* <div>foto: {this.state.foto.url?this.state.foto.url:null}</div> */}
        {/* <div>arquivo: {this.url}</div> */}
      </>
    );
  }
}
