import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';

import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from 'mdbreact';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { ModalPage } from '../Components/VerAbordagem/index.js';

import Loading from '../../../components/Loading';
import PoliceIcon from '../../../assets/policeicon.png';

// import { Container } from './styles';

export default function App() {
  const history = useHistory();
  const [abordagens, setAbordagens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFiltro, setModalFiltro] = useState(false);

  const [qtdTotal, setQtdTotal] = useState(0);
  const [page, setPage] = useState('1');
  const [linhasTotais, setLinhasTotais] = useState(0);

  const [insiraFiltroTipo, setInsiraFiltroTipo] = useState({
    label: 'Data Inicio',
    value: 'data_inicio',
    duplicar: 'false',
    type: 'date',
  });

  const [select, setSelect] = useState([]);

  const tiposFiltro = [
    {
      label: 'Data Inicio',
      value: 'data_inicio',
      duplicar: false,
      type: 'date',
    },
    { label: 'Data Fim', value: 'data_fim', duplicar: false, type: 'date' },

    // { label: 'Origem', value: 'origem', duplicar: true, type: 'date' },
    // { label: 'Destino', value: 'destino', duplicar: true, type: 'date' },
    {
      label: 'Gerência',
      value: 'gerencia',
      duplicar: true,
      type: 'select',
      select: '/select/gerencias',
    },
    {
      label: 'Ala',
      value: 'ala',
      duplicar: true,
      type: 'select',
      select: '/select/alas',
    },
    {
      label: 'Evasão',
      value: 'evasao',
      duplicar: false,
      type: 'select',
      select: '/select/evasao',
    },

    {
      label: 'Tipo de Veículo',
      value: 'tipo_veiculo',
      duplicar: true,
      type: 'select',
      select: '/select/tipo-veiculos',
    },
    { label: 'Placa', value: 'placa', duplicar: false, type: 'text' },
    { label: 'Chassi', value: 'chassi', duplicar: false, type: 'text' },

    { label: 'CPF', value: 'cpf', duplicar: false, type: 'text' },
    { label: 'Nome', value: 'nome', duplicar: false, type: 'text' },
    { label: 'CNH', value: 'cnh', duplicar: false, type: 'text' },

    { label: 'CNPJ', value: 'cnpj', duplicar: false, type: 'text' },
    {
      label: 'Nome da Empresa',
      value: 'empresa_nome',
      duplicar: true,
      type: 'text',
    },

    {
      label: 'Natureza da Ocorrência',
      value: 'natureza',
      duplicar: false,
      type: 'select',
      select: '/select/natureza-ocorrencia',
    },
    {
      label: 'Autuado',
      value: 'autuado',
      duplicar: false,
      type: 'select',
      select: '/select/autuado',
    },
    {
      label: 'Razão da Autuação',
      value: 'razao_autuacao',
      duplicar: true,
      type: 'select',
      select: '/select/razao-autuacao',
    },
    { label: 'Nº R.O', value: 'ro', duplicar: true, type: 'text' },

    {
      label: 'Autuação Tipo',
      value: 'tipo_autuacao',
      duplicar: true,
      type: 'select',
      select: '/select/tipo-ocorrencia',
    },
    {
      label: 'Grupo Autuado',
      value: 'grupo_autuacao',
      duplicar: true,
      type: 'select',
      select: '/select/grupo-ocorrencia',
    },
    {
      label: 'Autuação Item',
      value: 'item_autuacao',
      duplicar: true,
      type: 'select',
      select: '/select/item-ocorrencia',
    },

    {
      label: 'Busca por Agentes',
      value: 'agentes',
      duplicar: true,
      type: 'select',
      select: '/select/agentes',
    },
  ];

  const [filtroValor, setFiltroValor] = useState({ value: '' });

  const [filtros, setFiltros] = useState([]);

  const [placas, setPlacas] = useState([]);

  const [exportList, setExportList] = useState([]);

  const [csvLoading, setCsvLoading] = useState(false);

  const inserItem = () => {
    if (filtroValor.value === '' || filtroValor.value === undefined) {
      toast.error(`Preencha o campo`);
      return;
    }

    let duplicado = true;
    if (insiraFiltroTipo.duplicar === false) {
      filtros.forEach(res => {
        if (res.chave === insiraFiltroTipo.value) {
          duplicado = false;
        }
      });
    }
    if (duplicado === false) {
      toast.error(`Duplicidade não autorizada`);
    } else {
      const novoFiltro = {};
      novoFiltro.label = insiraFiltroTipo.label;
      novoFiltro.type = insiraFiltroTipo.type;
      novoFiltro.chave = insiraFiltroTipo.value;

      // console.log(filtroValor);

      if (insiraFiltroTipo.value === 'data_inicio') {
        novoFiltro.valor = { value: `${filtroValor.value}T00:00` };
      } else if (insiraFiltroTipo.value === 'data_fim') {
        novoFiltro.valor = { value: `${filtroValor.value}T23:59` };
      } else {
        novoFiltro.valor = filtroValor;
      }

      setFiltros([
        novoFiltro,
        // {
        //   // nome que aparece - usuario
        //   label: insiraFiltroTipo.label,
        //   // tipo teste
        //   type: insiraFiltroTipo.type,
        //   // nome da tabelaa no banco
        //   chave: insiraFiltroTipo.value,
        //   // valor ou ID do item
        //   insiraFiltroTipo.value === 'data_inicio'? valor: filtroValor : valor: filtroValor
        //   valor: filtroValor,
        // },

        ...filtros,
      ]);
    }
    setFiltroValor({ value: '' });
  };

  const enviarFiltro = async () => {
    const dados = {
      // data_inicio: '',
      // data_fim: '',
      // origem: [],
      // destino: [],
      // evasao: [],
      // tipo_veiculo: [],
      // placa: [],
      // chassi: [],
      // cpf: [],
      // nome: [],
      // cnh: [],
      // categoria_cnh: [],
      // nome: [],
      // cnpj: [],
      // empresa_nome: [],
      // natureza: '',
      // autuado: '',
      // razao_autuacao: [],
      // ro: [],
      // tipo_autuacao: [],
      // grupo_autuacao: [],
      // item_autuacao: [],
      // agentes: [],
    };

    filtros.forEach(t => {
      dados[t.chave] = dados[t.chave]
        ? [...dados[t.chave], t.valor.value]
        : [t.valor.value];

      // console.log(t.chave, t.valor);
    });

    const result = await api.get('/abordagens', {
      params: {
        pag: page,
        ...dados,
      },
    });
    setAbordagens(result.data.rows);
  };

  const listaAbordagens = async () => {
    setLoading(true);

    const dados = {
      // data_inicio: '',
      // data_fim: '',
      // origem: [],
      // destino: [],
      // evasao: [],
      // tipo_veiculo: [],
      // placa: [],
      // chassi: [],
      // cpf: [],
      // nome: [],
      // cnh: [],
      // categoria_cnh: [],
      // nome: [],
      // cnpj: [],
      // empresa_nome: [],
      // natureza: '',
      // autuado: '',
      // razao_autuacao: [],
      // ro: [],
      // tipo_autuacao: [],
      // grupo_autuacao: [],
      // item_autuacao: [],
      // agentes: [],
    };

    filtros.forEach(t => {
      dados[t.chave] = dados[t.chave]
        ? [...dados[t.chave], t.valor.value]
        : [t.valor.value];

      // console.log(t.chave, t.valor);
    });

    const result = await api.get('/inteligencia/abordagens', {
      params: {
        pag: page,
        ...dados,
      },
    });

    setAbordagens(result.data.rows);
    setLinhasTotais(result.data.count);
    setQtdTotal(Math.ceil(Number(result.data.count) / Number(10)));
    setLoading(false);
  };

  const buscaSelect = url => {
    if (insiraFiltroTipo.type === 'select') {
      api.get(insiraFiltroTipo.select).then(result => {
        const select = [];
        result.data.forEach(res => {
          select.push(res);
        });
        // console.log(result.data);
        setSelect(select);
      });
      // console.log(buscaSelect(insiraFiltroTipo.select));
      // console.log(result);
      // const select = [];
      // result.forEach(res => {
      // select.push({ label: res.nome, value: res.value });
      //   console.log(res);
      // });
    }
  };

  const exportData = () => {
    setCsvLoading(true);
    const dados = {};
    filtros.forEach(t => {
      dados[t.chave] = dados[t.chave]
        ? [...dados[t.chave], t.valor.value]
        : [t.valor.value];

      // console.log(t.chave, t.valor);
    });

    api
      .get('/export-abordagens', {
        params: {
          ...dados,
        },
      })
      .then(list => {
        const newList = [];
        list.data.map(t => {
          newList.push({
            ...t,
            inicio: format(
              zonedTimeToUtc(new Date(t.inicio), 'America/Sao_Paulo'),
              'dd/MM/yyyy - HH:mm'
            ),
            fim: format(
              zonedTimeToUtc(new Date(t.fim), 'America/Sao_Paulo'),
              'dd/MM/yyyy - HH:mm'
            ),
            criado_em: format(
              zonedTimeToUtc(new Date(t.criado_em), 'America/Sao_Paulo'),
              'dd/MM/yyyy - HH:mm'
            ),
          });
        });
        setExportList(newList);

        // setLoading(false);
        // done(true); // Proceed and get data from dataFromListOfUsersState function
        setCsvLoading(false);
      });
    // setCsvLoading(false);
  };

  useEffect(() => {
    buscaSelect();
  }, [insiraFiltroTipo]);

  useEffect(() => {
    listaAbordagens();
  }, [page]);
  useEffect(() => {
    listaAbordagens();
  }, []);
  return (
    <NavBar>
      <MDBContainer className="p-0">
        <MDBRow className="h4-responsive" center>
          Abordagens
        </MDBRow>
        <MDBRow className="mr-md-3 ml-md-3 justify-content-center justify-content-md-start">
          <MDBCol className="d-flex">
            <MDBBtn
              onClick={() => {
                setModalFiltro(!modalFiltro);
              }}
              className="d-flex align-items-center"
              size="sm"
            >
              <MDBIcon icon="filter" size="2x" />

              <div className="ml-2">Filtros</div>
            </MDBBtn>

            {/* <CSVLink
              data={() => dataFromListOfUsersState()}
              asyncOnClick={true}
              onClick={() => exportData()}
            >
              dowloafd
            </CSVLink> */}
            {/* <CsvDownloader text="Download" datas={abordagens} /> */}
            <MDBBtn
              size="sm"
              className="d-flex align-items-center p-2 "
              onClick={() => exportData()}
            >
              <MDBIcon fas icon="cloud-download-alt" size="2x" />

              {/* <span className="ml-2">Download</span> */}
            </MDBBtn>
            {csvLoading === true ? (
              <div className="d-flex align-items-center">
                <MDBSpinner small />{' '}
                <span className="ml-2 font-weight-bolder ">Carregando</span>
              </div>
            ) : (
              ''
            )}
            {exportList.length < 1 ? (
              ''
            ) : (
              <CsvDownloader
                className="danger-text font-weight-bolder d-flex align-items-center"
                style={{ cursor: 'pointer' }}
                filename={
                  'Inteligencia_' +
                  format(
                    zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
                    'ddMMyyyy_HHmm'
                  )
                }
                extension=".csv"
                separator=";"
                // wrapColumnChar="'"
                datas={exportList}
                // text="DOWNLOAD"
              >
                {'Inteligencia_' +
                  format(
                    zonedTimeToUtc(new Date(), 'America/Sao_Paulo'),
                    'ddMMyyyy_HHmm'
                  )}
                .csv
              </CsvDownloader>
            )}
          </MDBCol>
          <MDBCol className="text-right d-flex justify-content-end align-items-center font-weight-bolder">
            Total: {linhasTotais}
          </MDBCol>
          <MDBModal
            // backdrop={false}
            // fullHeight
            // frame
            position="right"
            isOpen={modalFiltro}
            toggle={() => {
              setModalFiltro(!modalFiltro);
            }}
            fade={false}
          >
            <MDBModalHeader
              toggle={() => {
                setModalFiltro(!modalFiltro);
              }}
            >
              Filtros Avançados
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow center>
                <MDBCol size="10">
                  <Select
                    options={tiposFiltro}
                    onChange={e => {
                      setInsiraFiltroTipo(e);
                      setFiltroValor({ value: '' });
                    }}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'silver',
                        primary: 'gray',
                      },
                    })}
                    value={insiraFiltroTipo}
                    placeholder="Filtrar"
                  />
                </MDBCol>

                {/* {show17 && ( */}

                {/* )} */}
              </MDBRow>
              <MDBRow className="ml-2 h6-responsive font-weight-bolder mb-2">
                Insira o filtro:
              </MDBRow>
              <MDBRow
                center
                className="ml-2 h6-responsive font-weight-bolder mb-2"
              >
                {insiraFiltroTipo.type === 'select' ? (
                  <Select
                    className="col-8 col-md-10 p-0"
                    options={select}
                    onChange={e => {
                      setFiltroValor(e);
                    }}
                    value={filtroValor}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: 'silver',
                        primary: 'gray',
                      },
                    })}
                  />
                ) : (
                  <input
                    className="col-8 col-md-10"
                    style={{
                      border: '1px solid silver',
                      color: 'gray',
                      borderRadius: '5px',
                    }}
                    type={insiraFiltroTipo.type}
                    onChange={e => {
                      setFiltroValor({ value: e.target.value });
                    }}
                    value={filtroValor.value}
                  />
                )}

                <MDBCol className="col-2">
                  <MDBIcon
                    className="mdb-color-text"
                    size="2x"
                    style={{ cursor: 'pointer' }}
                    fas
                    icon="plus-circle"
                    onClick={() => {
                      inserItem();
                    }}
                  />
                </MDBCol>
              </MDBRow>
              <br />
              <MDBRow className="ml-2 h6-responsive font-weight-bolder mb-2">
                Filtros Aplicados:
              </MDBRow>

              {filtros.length === 0 ? (
                <MDBRow center className="text-danger font-weight-bolder">
                  Sem filtros aplicados
                </MDBRow>
              ) : (
                ''
              )}

              {filtros.map((item, index) => (
                <MDBRow
                  between
                  className="stylish-color m-2 p-1 align-items-center"
                  style={{ borderRadius: '5px' }}
                >
                  <MDBCol
                    // style={{
                    // maxWidth: '45ch',
                    // whiteSpace: 'nowrap',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    // }}
                    size="10"
                    md="11"
                    className="font-weight-bolder text-white"
                  >
                    {`${item.label}: ${
                      item.type === 'select'
                        ? item.valor.label
                        : item.type === 'date'
                        ? format(new Date(item.valor.value), 'dd/MM/yyyy')
                        : item.valor.value
                    }`}
                  </MDBCol>
                  <MDBCol className="text-right text-white" size="1">
                    <MDBIcon
                      onClick={() => {
                        const array = [...filtros];
                        if (index !== -1) {
                          array.splice(index, 1);
                          setFiltros(array);
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                      icon="times"
                    />
                  </MDBCol>
                </MDBRow>
                //  <MDBChip
                //   style={{
                //     maxWidth: '40ch',
                //     whiteSpace: 'nowrap',
                //     overflow: 'hidden',
                //     textOverflow: 'ellipsis',
                //   }}
                //   className="col-10"
                //   bgColor="mdb-color lighten-2"
                //   text="white"
                //   close
                //   handleClose={() => {
                //     var array = [...filtros];
                //     if (index !== -1) {
                //       array.splice(index, 1);
                //       setFiltros(array);
                //     }
                //   }}
                // >
                //   {`${item.label}: ${
                //     item.type === 'select' ? item.valor.label : item.valor
                //   }`}
                // </MDBChip>
              ))}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBRow between className="w-100">
                <MDBBtn
                  color="secondary"
                  onClick={() => {
                    setModalFiltro(!modalFiltro);
                  }}
                >
                  Sair
                </MDBBtn>
                <MDBBtn
                  color="primary"
                  onClick={() => {
                    setPage(1);
                    listaAbordagens();
                  }}
                >
                  Filtrar
                </MDBBtn>
              </MDBRow>
            </MDBModalFooter>
          </MDBModal>
        </MDBRow>

        {/* <MDBRow className="h4-responsive justify-content-center ">
          <MDBBtn onClick={() => history.push(`/add-abordagem`)}>
            Nova Abordagem
          </MDBBtn>
        </MDBRow> */}
        {/* <MDBRow center>Lista Abordagens</MDBRow> */}
        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="3">
            Gerência / Ala
          </MDBCol>
          <MDBCol size="12" md="3">
            Início da Abordagem:
          </MDBCol>
          <MDBCol className="text-center" size="12" md="4">
            Ocorrências:
          </MDBCol>
          <MDBCol size="12" md="1" />
          {/* <div className="col-12 col-md-3 font-weight-bold text-white">
            Gerência / Ala
          </div>
          <div className="col-12 col-md-3 font-weight-bold text-white">
            Início da Abordagem:
          </div>

          <div className="col-12 col-md-1 font-weight-bold text-white">
            Ocorrências:
          </div>
          <div className="col-12 col-md-3 d-flex font-weight-bold text-white">
            sd
          </div> */}
          {/* <div className="col-12 col-md-1 d-flex flex-column font-weight-bold text-white">
            <span>Frequência</span> */}
          {/*
                    <span className="text-danger">Ausentes</span> */}
          {/* </div> */}
          <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white" />
        </MDBRow>
        {loading === true ? (
          <Loading />
        ) : abordagens.length !== 0 ? (
          abordagens.map(abordagem => (
            <>
              <MDBRow
                key={abordagem.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                  {abordagem.sigla} / {abordagem.nome}
                </div>

                <div className="col-12 col-md-3 mb-md-0 d-flex justify-content-between justify-content-md-start">
                  <span className="d-sm-inline d-md-none mr-3">Dia Início</span>

                  {format(
                    zonedTimeToUtc(
                      new Date(abordagem.dt_h_inicio),
                      'America/Sao_Paulo'
                    ),
                    'dd/MM/yyyy - HH:mm'
                  )}
                </div>

                <div className="col-12 col-md-4 mb-md-0 text-center text-md-left">
                  <span className="d-sm-inline d-md-none mr-3">
                    Ocorrências:
                  </span>
                  <MDBRow center className="font-weight-bolder">
                    <MDBCol
                      size="3"
                      className="d-flex justify-content-end  align-items-center"
                    >
                      {abordagem.qdt_fiscal > 1 ? (
                        <span className="d-flex justify-content-center mr-1">
                          {abordagem.qdt_fiscal}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {abordagem.qdt_fiscal !== null ? (
                        <MDBTooltip
                          domElement
                          tag="span"
                          material
                          placement="top"
                        >
                          <span>
                            {' '}
                            <MDBIcon
                              fas
                              icon="truck-moving"
                              className="mr-4"
                              size="1x"
                            />
                          </span>
                          <span>Ocorrência Fiscal</span>
                        </MDBTooltip>
                      ) : (
                        ''
                      )}
                    </MDBCol>

                    <MDBCol
                      size="3"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {abordagem.qdt_policial > 1 ? (
                        <span className="d-flex justify-content-end mr-1">
                          {abordagem.qdt_policial}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {abordagem.qdt_policial !== null ? (
                        <MDBTooltip
                          domElement
                          tag="span"
                          material
                          placement="top"
                        >
                          <span>
                            <img
                              className="mr-4 mb-1"
                              src={PoliceIcon}
                              height="20"
                            />
                          </span>
                          <span>Ocorrência Policial</span>
                        </MDBTooltip>
                      ) : (
                        ''
                      )}
                    </MDBCol>

                    <MDBCol
                      size="3"
                      className="d-flex justify-content-end align-items-center"
                    >
                      {abordagem.qtd_autuado > 1 ? (
                        <span className="d-flex justify-content-end mr-1">
                          {abordagem.qtd_autuado}
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {abordagem.qtd_autuado !== null ? (
                        <MDBTooltip
                          domElement
                          tag="span"
                          material
                          placement="top"
                        >
                          <span>
                            <MDBIcon
                              className="ml-1"
                              fas
                              icon="user-shield"
                              size="1x"
                            />
                          </span>
                          <span>Pessoas Autuadas</span>
                        </MDBTooltip>
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>

                <div className="col-12 col-md-1 offset-md-1 mt-3 mt-md-0 d-flex justify-content-center">
                  <ModalPage abordagem={abordagem.id} />
                </div>
              </MDBRow>
            </>
          ))
        ) : (
          <MDBRow
            center
            className="mt-2 h4-responsive text-danger font-weight-bolder"
          >
            Não há Registros
          </MDBRow>
        )}
        {loading === true ? (
          ''
        ) : (
          <MDBRow className="justify-content-center my-4 align-items-center">
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  // className={`mx-3 grey-text`}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(1));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-left"
                />
              </span>
              <span>Primeira Página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>

            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == 1 ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == 1) {
                      return;
                    }
                    setPage(Number(page) - 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-left"
                />
              </span>
              <span>Página Anterior</span>
            </MDBTooltip>
            <div>
              Página {page} de {qtdTotal}
            </div>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page >= qtdTotal) {
                      return;
                    }
                    setPage(Number(page) + 1);
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-right"
                />
              </span>
              <span>Próxima página</span>
            </MDBTooltip>
            <span className="grey-text font-weight-bolder">|</span>
            <MDBTooltip domElement tag="span" material placement="top">
              <span>
                <MDBIcon
                  className={`mx-3 ${
                    page == qtdTotal ? 'grey-text' : 'black-text cursor-pointer'
                  } `}
                  onClick={e => {
                    if (page == qtdTotal) {
                      return;
                    }
                    setPage(Number(qtdTotal));
                  }}
                  style={{
                    fontSize: '30px',
                  }}
                  icon="angle-double-right"
                />
              </span>
              <span>Última página</span>
            </MDBTooltip>
          </MDBRow>
        )}
      </MDBContainer>
    </NavBar>
  );
}
