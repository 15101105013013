import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
// import { Link } from 'react-router-dom';
// import { CSVLink } from 'react-csv';

import CsvDownloader from 'react-csv-downloader';
import { toast } from 'react-toastify';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCollapse,
  MDBIcon,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
  MDBInput,
} from 'mdbreact';

import NavBar from '../../../components/NavBar';
import api from '../../../services/api';

export default function App() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [assuntos, setAssuntos] = useState([]);
  const [assuntoSelecionado, setAssuntoSelecionado] = useState({
    value: '',
    label: '',
  });

  const [relato, setRelato] = useState('');

  // const [modalAltera, setModalAltera] = useState(false);
  // const [itemModal, setItemModal] = useState('');

  // INSERE NOVO ASSUNTO
  const enviaNovoAssunto = async () => {
    if (assuntoSelecionado.label === '') {
      toast.error('Insira o Assunto');
      return;
    }

    if (relato === '') {
      toast.error('Insira o Relato');
      return;
    }

    const result = await api.post('/lpd', {
      lpd_assunto_id: assuntoSelecionado,
      relato: relato,
    });

    if (result.data.erro) {
      toast.error(result.data.erro);
      return;
    }
    if (result.data.sucesso) {
      toast.success(result.data.sucesso);
      history.push('/lpd');
      return;
    }
    toast.error('Falha Sistemica, Informe ao Administrador');
  };

  // BUSCA TODOS OS ASSUNTOS
  const buscaAssuntos = async () => {
    const select = [];
    const lista = await api.get('/lpd/lista-assunto', {
      params: { ativo: true },
    });
    lista.data.forEach(element => {
      select.push({ label: element.assunto, value: element.id });
    });
    setAssuntos(select);
  };

  useEffect(() => {
    buscaAssuntos();
  }, []);

  return (
    <NavBar>
      <MDBRow className="h4-responsive" center>
        Criação de Parte Diária
      </MDBRow>
      <MDBContainer className="p-0 bg-white" style={{ borderRadius: '5px' }}>
        <MDBRow between className="p-3">
          <MDBCol size="12" md="4">
            <Select
              options={assuntos}
              onChange={e => setAssuntoSelecionado(e.value)}
            />
          </MDBCol>
        </MDBRow>

        <MDBRow className="px-3">
          <MDBCol size="12">
            <MDBInput
              onChange={e => setRelato(e.target.value)}
              style={{ borderRadius: '5px' }}
              type="textarea"
              outline
              rows="15"
              maxlength="2000"
            />
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBBtn onClick={enviaNovoAssunto}>Enviar</MDBBtn>
        </MDBRow>
      </MDBContainer>
      <br />
    </NavBar>
  );
}
