import React, { useState, useEffect } from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBCol,
} from 'mdbreact';
import { Link } from 'react-router-dom';
import NavMenu from '../../components/NavMenu';
import { useHistory } from 'react-router-dom';

import Api from '../../services/api';

import efetivo from '../../assets/efetivo.jpg';
import efetivofoco from '../../assets/efetivofoco.jpeg';
import alocacao from '../../assets/alocacao.jpg';
import cargos from '../../assets/cargos.jpg';
import { getData, getPerfil, logout } from '../../services/auth';

const backgrounds = {};
backgrounds.efetivo = efetivo;
backgrounds.efetivofoco = efetivofoco;
backgrounds.alocacao = alocacao;
backgrounds.cargos = cargos;

// const insereImg = url => {
// const img = require(`${url}`);
// console.log(`url(${img})`);
// require(url);
// return `url(${img})`;
// };

// const teste = kk => {
//   return `url(${require(kk)})`;
// };
// const teste = async nome => {.≥/;.""

//   const ttt = await require(nome);
//   return ttt;
// };
// import

// import { isAuthenticated } from '../../services/auth';

// import img from '../../assets/images.jpg';

// import endereco_imagem from '../../assets/efetivofoco.jpeg';
// const importarImagens = (name, diretory) => {
//   return (const name = require(diretory))
// };
// import { Container } from './styles';

export default function App(props) {
  const [modulos, setModulos] = useState([]);
  let history = useHistory();
  // import `${img.modulo.endereco_imagem.split('/')[3].split('.')[0])}` from `${img.modulo.endereco_imagem}`

  const acesso = async () => {
    try {
      const response = await Api.get('/card-acesso');

      setModulos(response.data);
    } catch (error) {
      logout();
      history.push('/');
    }

    // console.log('data', response.data);
    // response.data.map(img => {
    //   console.log(teste);
    // });
    // var splits = myString.split(' ', 3);
  };

  useEffect(() => {
    acesso();
  }, []);

  // modulos.map((modulo)=> {
  //   // modulo.map((acesso) => {
  //     modulo.acessos.map((acessos) => {
  //       console.log(acessos.modulo)
  //     })
  //   // })
  //   })
  // insereImg('../../assets/alocacao.jpg');
  return (
    <>
      <NavMenu />
      <MDBContainer fluid>
        {/* espacamento  */}

        <MDBRow className="d-flex justify-content-start">
          {modulos == [] ? (
            <MDBCol col="12" md="6">
              Vc nao tem acesso'
            </MDBCol>
          ) : (
            modulos.map(gerenciaModulo => (
              <MDBCol col="12" md="6" key={gerenciaModulo.id}>
                <br />
                {/* {insereImg(gerenciaModulo.modulo.endereco_imagem)} */}
                {/* {console.log(
                  'backgroundImage',
                  `url(${
                    gerenciaModulo.modulo.endereco_imagem
                      .split('/')[3]
                      .split('.')[0]
                  })`
                )} */}

                <MDBCard
                  style={{
                    backgroundImage: `url(${window.location.origin}/api/${gerenciaModulo.modulo.endereco_imagem})`,
                    // backgroundImage: `url(${process.env.REACT_APP_HOST}/${gerenciaModulo.modulo.endereco_imagem})`,

                    // backgroundImage: `url(${
                    //   gerenciaModulo.modulo.endereco_imagem ===
                    //   '../../assets/alocacao.jpg'
                    //     ? alocacao
                    //     : ''
                    // require('https://api.barreirafiscal.app/efetivofoco.jpeg')
                    // })`,
                    // backgroundImage: insereImg(
                    //   gerenciaModulo.modulo.endereco_imagem
                    // ),
                    // backgroundImage: `url('${require(gerenciaModulo.modulo
                    //   .endereco_imagem)}')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                  className="justify-content-between card-body text-center"
                >
                  <MDBCardTitle className="h5">
                    {gerenciaModulo.modulo.titulo}
                  </MDBCardTitle>
                  <MDBCardText className="grey-text text-left">
                    {gerenciaModulo.modulo.descricao}
                    <br />
                    <br />
                  </MDBCardText>

                  <div
                    style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                    className="flex-row d-flex justify-content-around  pt-3 mt-3"
                  >
                    {gerenciaModulo.modulo.funcionalidades.map(botoes => (
                      <Link
                        key={botoes.id}
                        to={botoes.rota}
                        className="d-flex flex-column align-items-center"
                        style={{
                          color: 'rgba(0,0,0,0.6)',
                        }}
                      >
                        <span style={{ fontSize: 10, marginBottom: 5 }}>
                          {botoes.nome}
                        </span>

                        <MDBIcon icon={botoes.icone} size="2x" />
                      </Link>
                    ))}
                    {/* <Link
                      to="/usuario/add"
                      className="d-flex flex-column align-items-center blue-grey-text"
                    >
                      <span style={{ fontSize: 10, marginBottom: 5 }}>
                        Adicionar
                      </span>
                      <MDBIcon far icon="plus-square" size="2x" />
                    </Link> */}
                    {/* <Link
                      to="/estatisticas"
                      className="d-flex flex-column align-items-center blue-grey-text"
                    >
                      <span style={{ fontSize: 10, marginBottom: 5 }}>
                        Estatísticas
                      </span>
                      <MDBIcon icon="chart-pie" size="2x" />
                    </Link> */}
                    {/* <Link
                      to="/usuarios"
                      className="d-flex flex-column align-items-center blue-grey-text"
                    >
                      <span style={{ fontSize: 10, marginBottom: 5 }}>
                        Listar
                      </span>
                      <MDBIcon icon="list" size="2x" />
                    </Link> */}
                  </div>
                </MDBCard>
              </MDBCol>
            ))
          )}

          {/* <MDBCol col="12" md="6">
            <br />

            <MDBCard
              style={{
                backgroundImage: `url(${efetivo})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE EFETIVO</MDBCardTitle>
              <MDBCardText className="grey-text text-left">
                Registrar o ingresso do servidor na Secretária de Governo.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around  pt-3 mt-3"
              >
                <Link
                  to="/usuario/add"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Adicionar
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                  to="/estatisticas"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Estatísticas
                  </span>
                  <MDBIcon icon="chart-pie" size="2x" />
                </Link>
                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol> */}

          {/* <MDBCol col="12" md="6">
            <br />
            <MDBCard
              style={{
                backgroundImage: `url(${alocacao})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">ALOCAÇÃO</MDBCardTitle>

              <MDBCardText className="grey-text text-left">
                Criação e Gerenciamento das operaçoes e suboperações.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around pt-3 mt-3"
              >
                <Link
                  to="/gerencias"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Gerência
                  </span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
                <Link
                  to="/lotacao"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Lotação</span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
                <Link
              to="/alocacao"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Alocação</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>

                <Link
                  to="/escala-calendario"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Calendário
                  </span>

                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol> */}
        </MDBRow>
        <br />

        <MDBRow className="d-flex justify-content-start">
          {/* <MDBCard
          style={{
            backgroundImage: `url(${frota2})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">GESTÃO DE FROTA</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Controle dos veículos empregados na Secretária de Governo.
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3 "
          >
            <Link
              to="/add-frota"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Veículos</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>
            <Link
              to="/frota"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Relatórios</span>

              <MDBIcon icon="list" size="2x" />
            </Link>
          </div>
        </MDBCard>

        <MDBCard
          style={{
            backgroundImage: `url(${parteDiaria})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">GESTÃO DE PATRIMÔNIO</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Registrar o ingresso de novos cargos e administrar os existentes,
            Assim como calcular o custo operacional da Operação.
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3 "
          >
            <Link
              to="/add-patrimonio"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>
            <Link
              to="/patrimonio"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>

              <MDBIcon icon="list" size="2x" />
            </Link>
          </div>
        </MDBCard> */}
          {/* <MDBCol col="12" md="6">
            <MDBCard
              style={{
                backgroundImage: `url(${ocorrenciaImg})`,
                backgroundSize: 'cover',
              }}
              className="justify-content-between card-body  mt-md-0 blue-grey-text text-center"
            >
              <MDBCardTitle className="h5">GESTÃO DE SERVIÇO</MDBCardTitle>
              <MDBCardText className="grey-text text-left">
                Registrar o ingresso do servidor na Secretária de Governo.
                <br />
                <br />
              </MDBCardText>

              <div
                style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
                className="flex-row d-flex justify-content-around  pt-3 mt-3"
              >
                <Link
                  to="/controle-de-presenca"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>
                    Controle de presença
                  </span>
                  <MDBIcon far icon="plus-square" size="2x" />
                </Link>
                <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Estatísticas
                </span>
                <MDBIcon icon="chart-pie" size="2x" />
              </Link>






                <Link
                  to="/usuarios"
                  className="d-flex flex-column align-items-center blue-grey-text"
                >
                  <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                  <MDBIcon icon="list" size="2x" />
                </Link>
              </div>
            </MDBCard>
          </MDBCol> */}
        </MDBRow>

        {/* <MDBRow className="d-flex justify-content-center">
        <MDBCard
          style={{
            backgroundImage: `url(${monitoramento})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">MONITORAMENTO</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Controle de cameras dos postos fiscais no CICC, criação de
            relatórios e despacho de demandas.
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3 "
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Veículos</span>
              <MDBIcon icon="car" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Relatórios</span>

              <MDBIcon far icon="clipboard" size="2x" />
            </Link>
          </div>
        </MDBCard>

        <MDBCard
          style={{
            backgroundImage: `url(${parteDiaria})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">PARTE DIÁRIA</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Alterações diárias como: falta de servidores, avaria veicular,
            acidente e etc.
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
              <MDBIcon icon="list" size="2x" />
            </Link>
          </div>
        </MDBCard>
        <MDBCard
          style={{
            backgroundImage: `url(${ocrImg})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">ANÁLISES DE CAMERAS OCR</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Ferramenta de análise dos meta dados das câmeras OCR das rodovias do
            estado do Rio de Janeiro.
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
          </div>
        </MDBCard>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <MDBCard
          style={{
            backgroundImage: `url(${ocorrenciaImg})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">
            GESTÃO DE PRESTAÇÃO DE SERVIÇOS
          </MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Sistema de acompanhamento dos serviços prestados por empresas
            contratadas, fazendo a gestão do Acordo do Nivel de Serviço
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
              <MDBIcon icon="list" size="2x" />
            </Link>
          </div>
        </MDBCard>

        <MDBCard
          style={{
            backgroundImage: `url(${gerenciaImg})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">DENÚNCIAS</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Informações de denúncias da Central Disque Denúncia
          </MDBCardText>
          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
          </div>
        </MDBCard>
        <MDBCard
          style={{
            backgroundImage: `url(${cargosImg})`,
            backgroundSize: 'cover',
          }}
          className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 blue-grey-text text-center"
        >
          <MDBCardTitle className="h5">GESTÃO DE PATRIMÔNIO</MDBCardTitle>

          <MDBCardText className="grey-text text-left">
            Registrar o ingresso de novos cargos e administrar os existentes,
            Assim como calcular o custo operacional da Operação
          </MDBCardText>

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center blue-grey-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
          </div>
        </MDBCard>
      </MDBRow> */}

        {/* <MDBRow className="d-flex justify-content-center">

        <MDBCard
          style={{
            backgroundImage: `url(${nuint})`,
            backgroundSize: 'cover',
            opacity: '0.5',
          }}
          className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 white-text text-center"
        >
          <MDBCardTitle className="h5"> </MDBCardTitle>
          <br />
          <br />
          <MDBCardText className="grey-text text-left" />

          <div
            style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
            className="flex-row d-flex justify-content-around pt-3 mt-3"
          >
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center white-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
              <MDBIcon far icon="plus-square" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center white-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>
                Estatísticas
              </span>
              <MDBIcon icon="chart-pie" size="2x" />
            </Link>
            <Link
              to="/usuarios"
              className="d-flex flex-column align-items-center white-text"
            >
              <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
              <MDBIcon icon="list" size="2x" />
            </Link>
          </div>
        </MDBCard>
      </MDBRow> */}
        <br />
        <br />
      </MDBContainer>
    </>
  );
}
