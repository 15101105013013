import styled from 'styled-components';

export const Container = styled.div`
  background-image: url('https://img.icons8.com/color/48/000000/marker--v1.png');
  width: 50px;
  height: 48px;
  position: absolute;
  z-index: 1050;
  /* align-self: center; */

  left: calc(50% - 25px);
  top: calc(50% - 50px);
  transition: all 0.4s ease;
`;
