import React from 'react';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBSpinner,
  MDBTypography,
  MDBBox,
  MDBBtn,
  MDBInput,
  MDBCollapse,
  MDBNav,
  MDBRow,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBFooter,
  MDBCardFooter,
  MDBCardHeader,
  toast,
  MDBNotification,
} from 'mdbreact';
import { parseISO, format } from 'date-fns';

import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Select from 'react-select';
import { useState, useEffect } from 'react';

import { compareAsc } from 'date-fns';

import api from '../../services/api';
// import { ToastContainer } from 'react-toastify';
// import { Container } from './styles';

function EfetivoAvaliacao() {
  const [modalHistoricoListaLoading, setModalHistoricoListaLoading] = useState(
    false
  );
  const [modalHistoricoLista, setModalHistoricoLista] = useState([]);
  const [loadingBotao, setLoadingBotao] = useState(null);

  const listaDeAvaliacao = async () => {
    setModalHistoricoListaLoading(true);

    const historico = await api.get('/lista-avaliacao', {
      params: { efetivacao: null },
    });
    setModalHistoricoLista(historico.data);
    setModalHistoricoListaLoading(false);
  };

  const confirmaAvaliacao = async (idAvaliacao, tipo) => {
    setLoadingBotao(idAvaliacao);

    const historico = await api.put('/avaliacao', {
      efetivacao: tipo,
      id: idAvaliacao,
    });
    if (historico.data.error) {
      toast.error(historico.data.error);
    } else {
      toast.info(historico.data.ok);

      const newArray = modalHistoricoLista.filter(
        item => item.id !== idAvaliacao
      );
      setModalHistoricoLista(newArray);
    }

    setModalHistoricoListaLoading(false);
    setLoadingBotao(null);
  };

  useEffect(() => {
    listaDeAvaliacao();
  }, []);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow center className="h3-responsive font-weight-bolder">
            Efetivar Avaliação
          </MDBRow>

          {modalHistoricoListaLoading ? (
            <MDBRow className="d-flex justify-content-center align-items-center">
              <MDBSpinner className="mr-3" yellow />{' '}
              <div className="font-weight-bolder">Carregando</div>
            </MDBRow>
          ) : (
            ''
          )}

          {modalHistoricoLista.length > 0 ? (
            modalHistoricoLista.map(avaliacao => (
              <MDBRow key={avaliacao.id} className="mb-5">
                <MDBCol size="12">
                  <MDBCard className="h-100 mt-2 mt-md-4">
                    <MDBCardHeader className="bg-white text-center m-0">
                      <MDBRow between className="m-2">
                        <MDBCol className="font-weight-bolder text-left">
                          Agente: {avaliacao.avaliado.nome_social}
                          {/* Data do Fato:{' '}
                          {format(parseISO(avaliacao.data_fato), 'dd/MM/yyyy')} */}
                        </MDBCol>
                        <MDBCol className="font-weight-bolder  text-center">
                          Posto:{' '}
                          {`${avaliacao.avaliado.lotacao.gerencia.nome} / ${avaliacao.avaliado.lotacao.ala.nome}`}
                        </MDBCol>
                        <MDBCol className="font-weight-bolder  text-right">
                          Data do Fato:{' '}
                          {format(parseISO(avaliacao.data_fato), 'dd/MM/yyyy')}
                        </MDBCol>
                      </MDBRow>
                      <MDBRow between className="m-2">
                        <MDBCol className="  text-left">
                          Avaliador: {avaliacao.avaliador.nome_social}
                        </MDBCol>
                        <MDBCol></MDBCol>
                        <MDBCol className="  text-right">
                          Data da Avaliação:{' '}
                          {format(parseISO(avaliacao.createdAt), 'dd/MM/yyyy')}
                        </MDBCol>
                      </MDBRow>
                    </MDBCardHeader>
                    <MDBCardBody className="h-100">
                      <MDBTypography
                        className="h-100"
                        key={avaliacao.id}
                        blockquote
                        bqColor={`${avaliacao.elogio ? 'success' : 'danger'}`}
                      >
                        <MDBBox
                          tag="p"
                          mb={0}
                          className="bq-title font-weight-bolder"
                        >
                          {avaliacao.elogio ? 'Elogio' : 'Crítica'}
                        </MDBBox>

                        <p>
                          {avaliacao.descricao.split('\n').map((t, index) => (
                            <div key={index}>
                              {t} <br />
                            </div>
                          ))}
                        </p>
                      </MDBTypography>
                    </MDBCardBody>
                    <MDBCardFooter className="text-center m-0">
                      {loadingBotao === avaliacao.id ? (
                        <MDBRow className="d-flex justify-content-center align-items-center">
                          <MDBSpinner className="mr-3" yellow />{' '}
                          <div className="font-weight-bolder">Carregando</div>
                        </MDBRow>
                      ) : (
                        <>
                          <MDBBtn
                            onClick={() =>
                              confirmaAvaliacao(avaliacao.id, true)
                            }
                            color="success"
                          >
                            Aceitar
                          </MDBBtn>

                          <MDBBtn
                            onClick={() =>
                              confirmaAvaliacao(avaliacao.id, false)
                            }
                            color="danger"
                          >
                            Negar
                          </MDBBtn>
                        </>
                      )}
                    </MDBCardFooter>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            ))
          ) : (
            <MDBRow center className="font-weight-bolder mt-5">
              <MDBCard className="col-12 h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100 text-center">
                  Sem Processos para Avaliação
                </MDBCardBody>
              </MDBCard>
            </MDBRow>
          )}
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default EfetivoAvaliacao;
