import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import Select from 'react-select';
// import { Link } from 'react-router-dom';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBCardUp,
  MDBCardText,
} from 'mdbreact';
import NavBar from '../../../components/NavBar';
import api from '../../../services/api';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Loading from '../../../components/Loading';
import { saveAs } from 'file-saver';
import { getData, getPerfil } from '../../../services/auth';
import SendImg from '../../../components/SendImg';
// import { Container } from './styles';

export default function App() {
  let history = useHistory();
  const [revisao, setRevisao] = useState([]);
  const [loading, setLoading] = useState(false);

  const [qtdTotal, setQtdTotal] = useState(0);
  const [page, setPage] = useState('1');

  const [modalRevisao, setModalRevisao] = useState(null);

  // SELECT GERENCIA
  const [listaGerencias, setListaGerencias] = useState([]);
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState(null);
  const carregaGerencias = () => {
    api.get('/filtra-gerencia').then(linhas => {
      let select = [];
      linhas.data.map(linha => {
        select.push({
          label: linha.nome,
          value: String(linha.id),
        });
      });

      setListaGerencias(select);
    });
  };

  const [revisaoMotivos, setRevisaoMotivos] = useState([]);
  const [revisaoMotivosSelecionado, setRevisaoMotivoSelecionado] = useState(
    null
  );

  const [statusSelecionado, setStatusSelecionado] = useState({
    label: 'Em Aberto',
    value: 'aberta',
  });

  const [verRevisao, setVerRevisao] = useState(null);

  const [loadingRevisar, setLoadingRevisar] = useState(false);

  const [dataForDownload, setDataForDownload] = useState(null);

  const buscaRevisaoMotivos = async () => {
    const result = await api.get('/sgo-motivo-revisao');
    const select = [];
    result.data.forEach(element => {
      select.push({ value: element.id, label: element.motivo });
    });
    setRevisaoMotivos(select);
  };

  const buscaRevisoes = async () => {
    const params = { pag: page };
    if (gerenciaSelecionada !== null) {
      params.gerencia_id = gerenciaSelecionada.value;
    }
    if (revisaoMotivosSelecionado !== null) {
      params.motivo_id = revisaoMotivosSelecionado.value;
    }
    // params.data_final = null;
    // console.log(statusSelecionado);
    // if (statusSelecionado?.value !== null) {
    if (statusSelecionado.value !== null) {
      params.status = statusSelecionado.value;
    }
    // if (statusSelecionado.value === 'respondida') {
    //   params.data_resposta = null;
    // }
    // }

    setLoading(true);
    const result = await api.get('/sgo-lista-revisao', {
      params,
    });

    setRevisao(result.data.rows);
    setQtdTotal(result.data.count);
    // result.data.rows.map(abord => {
    //   const newRow = [];
    //   abord.ocorrencia.forEach(oco => {
    //     if (oco.ativo === true) newRow.push(oco);
    //   });
    //   abord.ocorrencia = newRow;
    // });

    // setAbordagens(result.data.rows);
    // setQtdTotal(Math.ceil(Number(result.data.count) / Number(10)));
    setLoading(false);
  };

  const buscaRevisaoId = async idRevisao => {
    const result = await api.get(`/sgo-busca-revisao/${idRevisao}`);

    setVerRevisao(result.data);
  };

  const downloadFile = () => {
    api
      .get('/sgo-revisao/download', {
        params: {
          gerencia_id: (gerenciaSelecionada && gerenciaSelecionada.value) || '',
        },
      })
      .then(res => {
        if (res.data.length === 0) {
          setDataForDownload([{ resposta: 'nao existe revisoes' }]);
          return;
        }
        setDataForDownload(res.data); // Armazena os dados no estado
      })
      .catch(error => {
        console.error('Erro ao fazer download do arquivo:', error);
      });
  };

  const concluirRevisao = async id => {
    setLoadingRevisar(true);
    const result = await api.put(`/sgo-revisao/${id}`, {
      data_final: new Date(),
      revisor_final_id: getPerfil().id,
    });
    setLoadingRevisar(false);
    buscaRevisoes();
    buscaRevisaoId(modalRevisao);
  };

  useEffect(() => {
    buscaRevisoes();
    setDataForDownload(null);
  }, [page, revisaoMotivosSelecionado, gerenciaSelecionada, statusSelecionado]);

  useEffect(() => {
    buscaRevisoes();
    carregaGerencias();
    buscaRevisaoMotivos();
  }, []);

  useEffect(() => {
    if (modalRevisao !== null) {
      buscaRevisaoId(modalRevisao);
    } else {
      setVerRevisao(null);
    }
  }, [modalRevisao]);

  return (
    <NavBar>
      <MDBContainer className="p-0">
        <MDBRow className="h4-responsive" center>
          Revisão Abordagens
        </MDBRow>

        <MDBRow center className="px-3 pb-3">
          <MDBCol size="12" md="3">
            <Select
              placeholder="Gerencia"
              options={listaGerencias}
              onChange={e => setGerenciaSelecionada(e)}
              value={gerenciaSelecionada}
              isClearable={true}
            />
          </MDBCol>
          <MDBCol size="12" md="3">
            <Select
              placeholder="Motivo"
              options={revisaoMotivos}
              onChange={e => setRevisaoMotivoSelecionado(e)}
              value={revisaoMotivosSelecionado}
              isClearable={true}
            />
            {/* <MDBInput className="bg-white" outline /> */}
          </MDBCol>
          <MDBCol size="12" md="3">
            <Select
              placeholder="Status"
              options={[
                { label: 'Em Aberto', value: 'aberta' },
                { label: 'Respondida', value: 'respondida' },
                { label: 'Concluída', value: 'concluida' },
              ]}
              onChange={e => setStatusSelecionado(e)}
              value={statusSelecionado}
              isClearable={false}
            />
          </MDBCol>
          <MDBCol size="12" md="3" className="text-right">
            {dataForDownload !== null ? (
              <CSVLink
                data={dataForDownload}
                filename={`Revisao-${format(new Date(), 'dd-MM-yyyy')}.csv`}
                className="hidden"
                separator={';'}
                target="_blank"
                onClick={() => {
                  // Isso garante que o link é "usado" uma vez e impede downloads repetidos
                  setTimeout(() => setDataForDownload(null), 500);
                }}
              >
                <MDBBtn color="success" size="sm">
                  Salvar
                </MDBBtn>
              </CSVLink>
            ) : (
              <MDBBtn size="sm" onClick={() => downloadFile()}>
                Baixar
              </MDBBtn>
            )}
          </MDBCol>
        </MDBRow>
        <MDBRow
          style={{ borderRadius: '5px' }}
          className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center grey darken-1  d-none d-md-flex text-white font-weight-bolder"
        >
          <MDBCol size="12" md="1" className="text-center">
            Abordagem
          </MDBCol>
          <MDBCol size="12" md="2" className="text-center">
            Data Inicial
          </MDBCol>
          <MDBCol size="12" md="2" className="text-center">
            Data Final
          </MDBCol>
          <MDBCol size="12" md="2" className="text-start">
            Motivo
          </MDBCol>
          <MDBCol size="12" md="2" className="text-center">
            Feita por
          </MDBCol>
          <MDBCol size="12" md="2" className="text-center">
            Concluída por
          </MDBCol>

          {/* <div className="col-12 col-md-3 font-weight-bold text-white">
            Gerência / Ala
          </div>
          <div className="col-12 col-md-3 font-weight-bold text-white">
            Início da Abordagem:
          </div>

          <div className="col-12 col-md-1 font-weight-bold text-white">
            Ocorrências:
          </div>
          <div className="col-12 col-md-3 d-flex font-weight-bold text-white">
            sd
          </div> */}
          {/* <div className="col-12 col-md-1 d-flex flex-column font-weight-bold text-white">
            <span>Frequência</span> */}
          {/*
                    <span className="text-danger">Ausentes</span> */}
          {/* </div> */}
          <div className="col-12 col-md-3 d-flex justify-content-between font-weight-bold text-white"></div>
        </MDBRow>
        {loading === true ? (
          <Loading />
        ) : (
          revisao.length !== 0 &&
          revisao.map(revisao => (
            <>
              <MDBRow
                key={revisao.id}
                style={{ borderRadius: '5px' }}
                className="p-2 mr-1 ml-1 mt-1 mr-md-3 ml-md-3 align-items-center bg-white"
              >
                <MDBCol
                  size="12"
                  md="1"
                  className="d-flex justify-content-between justify-content-md-center"
                >
                  <span className="d-sm-inline d-md-none mr-3">Abordagem</span>
                  <span>{revisao.abordagem_id}</span>
                </MDBCol>

                <MDBCol
                  size="12"
                  md="2"
                  className="d-flex justify-content-between justify-content-md-center"
                >
                  <span className="d-sm-inline d-md-none mr-3">Início</span>
                  <span>
                    {format(
                      zonedTimeToUtc(
                        new Date(revisao.data_inicio),
                        'America/Sao_Paulo'
                      ),
                      'dd/MM/yyyy'
                    )}
                  </span>
                </MDBCol>
                <MDBCol
                  size="12"
                  md="2"
                  className="d-flex justify-content-between justify-content-md-center"
                >
                  <span className="d-sm-inline d-md-none mr-3">Data Final</span>
                  <span>
                    {revisao.data_final === null ? (
                      revisao.data_resposta !== null ? (
                        <span className="info-text font-weight-bolder">
                          Respondido
                        </span>
                      ) : (
                        <span className="danger-text font-weight-bolder">
                          Em aberto
                        </span>
                      )
                    ) : (
                      format(
                        zonedTimeToUtc(
                          new Date(revisao.data_inicio),
                          'America/Sao_Paulo'
                        ),
                        'dd/MM/yyyy'
                      )
                    )}
                  </span>
                </MDBCol>

                <MDBCol
                  size="12"
                  md="2"
                  className="d-flex justify-content-between justify-content-md-start"
                >
                  <span className="d-sm-inline d-md-none mr-3">Motivo</span>
                  <span>{revisao.sgo_revisao_motivo.motivo}</span>
                </MDBCol>

                <MDBCol
                  size="12"
                  md="2"
                  className="d-flex justify-content-between justify-content-md-center"
                >
                  <span className="d-sm-inline d-md-none mr-3">
                    Criada por:
                  </span>
                  <span>{revisao.revisor_inicial.nome_social}</span>
                </MDBCol>

                <MDBCol
                  size="12"
                  md="2"
                  className="d-flex justify-content-between justify-content-md-center"
                >
                  <span className="d-sm-inline d-md-none mr-3">
                    Concluída por:
                  </span>
                  <span> {revisao?.revisor_final?.nome_social}</span>
                </MDBCol>

                <div className="col-12 col-md-1 mt-2 mt-md-0  d-flex justify-content-center justify-md-content-end">
                  <div
                    className="d-flex align-items-center font-weight-bold"
                    style={{ cursor: 'pointer', color: '#59698d' }}
                    onClick={() => setModalRevisao(revisao.id)}
                  >
                    <MDBIcon far icon="eye" />

                    <div className="ml-2">Ver</div>
                  </div>
                </div>
              </MDBRow>
            </>
          ))
        )}
        {loading === true ? (
          ''
        ) : (
          <MDBRow center className="my-4 align-items-center">
            <div>
              Página {page} de {Math.ceil(qtdTotal / 10)}
            </div>
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page == 1) {
                  return;
                }
                setPage(Number(page) - 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-left"
            />
            |
            <MDBIcon
              className="mx-3"
              onClick={e => {
                if (page >= qtdTotal) {
                  return;
                }
                setPage(Number(page) + 1);
              }}
              style={{
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon="angle-right"
            />
          </MDBRow>
        )}
      </MDBContainer>
      {/* MODAL PARA CRIACAO DE VEICULOS */}
      <MDBModal
        isOpen={modalRevisao}
        toggle={() => {
          setModalRevisao(null);
        }}
        size="lg"
      >
        <MDBModalHeader
          toggle={() => {
            setModalRevisao(null);
          }}
        >
          Revisão
        </MDBModalHeader>
        <MDBModalBody>
          <MDBRow>
            <MDBCol className="font-weight-bolder">
              Abordagem Nº:
              <Link
                to={`/imprime-abordagem/${verRevisao?.abordagem_id}`}
                className="cursor-pointer ml-2"
                style={{
                  color: 'rgba(0,0,0,0.6)',
                }}
                // target={'_blank'}
                // rel="noopener noreferrer"
              >
                <span className="text-danger font-weight-bolder">
                  {verRevisao?.abordagem_id}
                </span>
              </Link>
            </MDBCol>
            <MDBCol>
              <span>Abordagem Criada por:</span>
              <span className="ml-2">
                {verRevisao?.abordagem.usuario.nome_social}
              </span>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol>
              Data Inicial da Abordagem:{' '}
              {verRevisao &&
                format(
                  new Date(verRevisao?.abordagem.dt_h_inicio),
                  'dd/MM/yyyy HH:mm'
                )}
            </MDBCol>
            <MDBCol>
              Data Final da Abordagem:{' '}
              {verRevisao &&
                format(
                  new Date(verRevisao?.abordagem.dt_h_fim),
                  'dd/MM/yyyy HH:mm'
                )}
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol className="d-flex">
              <div className="mr-2">Abordagem:</div>
              {verRevisao?.abordagem?.ativo === true ? (
                <div className="green-text font-weight-bolder">Ativa</div>
              ) : (
                <div className="red-text font-weight-bolder">Deletada</div>
              )}
            </MDBCol>
          </MDBRow>

          <hr />
          <MDBCard
            shadow="0"
            border="light"
            className="mb-3"
            style={{
              backgroundColor: `${
                verRevisao && verRevisao?.data_final === null
                  ? '#FFCCBC'
                  : '#C8E6C9'
              }`,
            }}
          >
            <MDBCardText className="py-2 text-center">
              <MDBRow>
                {verRevisao?.revisor_final === null ? (
                  ''
                ) : (
                  <MDBCol className="ml-2">
                    Revisor final: {verRevisao?.revisor_final?.nome_social}
                  </MDBCol>
                )}

                <MDBCol>
                  {verRevisao && verRevisao?.data_final === null
                    ? 'Revisao em andamento'
                    : verRevisao?.data_final &&
                      `Finalizada em ${format(
                        new Date(verRevisao?.data_final),
                        'dd/MM/yyyy HH:mm'
                      )}`}
                </MDBCol>
              </MDBRow>
            </MDBCardText>
          </MDBCard>
          <MDBCard
            shadow="0"
            border="light"
            background="white"
            className="mb-3"
          >
            <MDBCardHeader className="bg-light">
              <MDBRow>
                <MDBCol>
                  Revisor Inicial: {verRevisao?.revisor_inicial.nome_social}
                </MDBCol>
                <MDBCol className="text-right">
                  Motivo: {verRevisao?.sgo_revisao_motivo.motivo}
                </MDBCol>
              </MDBRow>
            </MDBCardHeader>
            <MDBCardBody>
              <MDBRow>
                <MDBCol>
                  <span>Revisão Criada:</span>
                  <span className="ml-2">
                    {verRevisao &&
                      format(
                        new Date(verRevisao?.data_inicio),
                        'dd/MM/yyyy HH:mm'
                      )}
                  </span>
                </MDBCol>
              </MDBRow>

              <MDBRow className="mt-2">
                <MDBCol>Comentario Inicial:</MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>{verRevisao?.comentario_inicial}</MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          {verRevisao?.comentario_final === null ? (
            ''
          ) : (
            <MDBCard
              shadow="0"
              border="light"
              background="white"
              className="mb-3"
            >
              <MDBCardHeader style={{ backgroundColor: '#B3E5FC' }}>
                <MDBRow>
                  <MDBCol>
                    Corrigido Por:
                    {verRevisao?.abordagem.usuario.nome_social === null ? (
                      'Aberto'
                    ) : (
                      <span className="ml-2">
                        {verRevisao?.abordagem.usuario.nome_social}
                      </span>
                    )}
                  </MDBCol>
                  {/* <MDBCol className="text-right">
                 Motivo: {verRevisao?.sgo_revisao_motivo.motivo}
               </MDBCol> */}
                </MDBRow>
              </MDBCardHeader>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol>
                    Resposta em{' '}
                    {verRevisao?.data_resposta &&
                      format(
                        new Date(verRevisao?.data_resposta),
                        'dd/MM/yyyy HH:mm'
                      )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {' '}
                    {verRevisao?.comentario_final === null
                      ? 'Aberto'
                      : verRevisao?.comentario_final}
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          )}

          {/* <MDBCard>
            <MDBCardBody>
              <MDBCardTitle className="h5-responsive">Abordagem</MDBCardTitle> */}

          {/* </MDBCardBody>
          </MDBCard> */}
        </MDBModalBody>
        <MDBModalFooter center>
          <MDBBtn
            color="danger"
            onClick={() => {
              setModalRevisao(null);
            }}
          >
            Cancelar
          </MDBBtn>
          {verRevisao?.data_final === null ? (
            <MDBBtn
              color="green"
              onClick={() => {
                concluirRevisao(verRevisao.id);
              }}
              disabled={loadingRevisar}
            >
              {loadingRevisar === true ? 'Carregando' : ' Concluir Revisão'}
            </MDBBtn>
          ) : (
            ''
          )}
        </MDBModalFooter>
      </MDBModal>
    </NavBar>
  );
}
