import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBTable,
  MDBTableBody,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBFileInput,
  MDBSpinner,
} from 'mdbreact';
// roteamento
import { useHistory } from 'react-router';
import NavBar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import InputMask from 'react-input-mask';
import UserJpg from '../../../assets/user.jpg';
import Select from 'react-select';
import { getData } from '../../../services/auth';
// import { Container } from './styles';
import api from '../../../services/api';
import Compressor from 'compressorjs';
import { useState, useEffect, useCallback, useRef } from 'react';

import SendBlob from '../../../components/SendBLob';

function Perfil(props) {
  const [dados, setDados] = useState({});

  const [foto, setFoto] = useState('');
  const [lotacao, setLotacao] = useState({});

  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [idFuncional, setIdFuncional] = useState('');
  const [ativo, setAtivo] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobreNome] = useState('');
  const [nomeSocial, setNomeSocial] = useState('');
  const [genero, setGenero] = useState('');
  const [rg, setRg] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cnh, setCnh] = useState('');
  const [cnhCategoria, setCnhCategoria] = useState('');
  const [cnhValidade, setCnhValidade] = useState('');

  const [dadosGerencias, setDadosGerencias] = useState([]);
  const [gerencia, setGerencia] = useState('');

  const [cargoSimboloAtual, setCargoSimboloAtual] = useState('');

  const [modalResetSenha, setModalResetSenha] = useState(false);
  const [modalDiarioOficial, setModalDiarioOficial] = useState(false);
  const [diarioOficial, setDiarioOficial] = useState({
    diario_oficial: '',
    data: '',
    tipo: '',
  });
  const [addAcesso, setAddAcesso] = useState([]);
  // LISTAGEM DE ORGAOS PARA SELECT
  const [dadosOrgaos, setDadosOrgaos] = useState([]);
  // LISTAGEM DE TITULOS PARA SELECT
  const [dadosTitulos, setDadosTitulos] = useState([]);
  // INSERT ADIDO
  const [orgao, setOrgao] = useState('');
  const [titulo, setTitulo] = useState('');

  const [carregando, setCarregando] = useState(false);
  const [adido, setAdido] = useState(false);
  const [dadosAdido, setDadosAdido] = useState({});
  // ABRE FORMA ADIDO
  const [adidoForm, setAdidoForm] = useState(false);
  //LISTAGEM DE LOTACAO
  const [dadosLotacao, setDadosLotacao] = useState([]);
  const [lotacaoId, setLotacaoId] = useState('');

  const [buscaModulos, setBuscaModulos] = useState([]);

  const [modalAcesso, setModalAcesso] = useState(false);
  const [updateAcesso, setUpdateAcesso] = useState([]);

  // FUNCOES
  const [optionFuncoes, setOptionFuncoes] = useState([]);
  const [funcaoSelecionada, setFuncaoSelecionada] = useState();

  // CARGOS
  const [optionCargos, setOptionCargos] = useState([]);
  const [cargoSelecionado, setCargoSelecionado] = useState();
  // cargos para fazer o select das vagas
  const [cargosDisponiveis, setCargosDisponiveis] = useState([]);

  // VAGAS DISPONIVEIS
  const [vagasDisponiveis, setVagasDisponiveis] = useState([]);
  const [vagaSelecionada, setVagaSelecionada] = useState();

  // FOTOS usuarios
  const [modalFoto, setModalFoto] = useState(false);
  const [image, setImage] = useState('');
  const [loadingFoto, setLoadingFoto] = useState(false);

  // FOTOS CNH
  const [modalFotoCnh, setModalFotoCnh] = useState(false);
  const [imageCnh, setImageCnh] = useState('');
  const [loadingFotoCnh, setLoadingFotoCnh] = useState(false);

  const [alterarTitulo, setAlterarTitulo] = useState(false);
  const [selectTitulosLoading, setSelectTitulosLoading] = useState(false);
  const [compressedFileView, setCompressedFileView] = useState(false);

  const [tipoSanguineo, setTipoSanguineo] = useState('');

  const [compressedFileBase64, setCompressedFileBase64] = useState('');

  const [feriasAntigas, setFeriasAntigas] = useState([]);
  const [feriasSecc, setFeriasSecc] = useState([]);
  const [loading, setLoading] = useState(false);
  const idReq = props.match.params.id;
  // roteamento

  // carrega o select de alteracao de titulo (graduacao / patente)
  useEffect(() => {
    if (alterarTitulo) {
      loadTitulos(dados.adidos[0].orgao_id);
    }
  }, [alterarTitulo]);
  let history = useHistory();
  if (getData().id == idReq) {
    toast.error('Acesso Proibido, Reportado ao Administrador');
    history.push('/');
  }
  const alteraTitulo = async () => {
    if (titulo === '') {
      toast.error('Selecione o Título');
    }
    setCarregando(true);
    const result = await api.put('/usuario-titulo', {
      id: dados.adidos[0].id,
      titulo: titulo,
    });

    setDadosAdido(result.data.titulo.label);
    setCarregando(false);
  };
  const fetchFerias = async () => {
    try {
      const response = await api.get(`/ferias-historico/${idReq}`);
      setFeriasAntigas(response.data.feriasAntigas);
      setFeriasSecc(response.data.feriasSecc);
    } catch (error) {
      console.error('Erro ao buscar as férias do usuário:', error);
    } finally {
      setLoading(false);
    }
  };
  // coloca dentro do select de gerencia
  const loadGerencias = async () => {
    const response = await api.get('/gerencia');
    setDadosGerencias(response.data);
    // toast.error('testeeeeeee');
  };

  const geraModulos = async gerencia_id => {
    // const response = await api.get(`/gerenciaid-modulo/${gerencia_id}`);
    const response = await api.get(
      `/verifica-acesso-usuario/${gerencia_id}/${idReq}`
    );

    const acessos = [];

    const verificaFuncionalidades = funcionalidades => {
      const funcs = [];
      funcionalidades.forEach(func => {
        const { id, nome, acessos, ...rest } = func;

        funcs.push({ id, nome, acesso: acessos.length > 0 });
      });
      return funcs;
    };

    response.data.forEach(t => {
      acessos.push({
        id: t.modulo.id,
        modulo: t.modulo.titulo,
        funcionalidades: verificaFuncionalidades(t.modulo.funcionalidades),
      });
    });

    setBuscaModulos(acessos);
    setUpdateAcesso(acessos);

    // const acesso = await api.get(`/verifica-acesso-usuario/${idReq}`);
    // setVerificaAcessoUsuario(acesso.data);
  };

  const loadTitulos = async orgao_id => {
    setCarregando(true);
    const response = await api.get(`${orgao_id}/titulo`);
    const select = [];
    response.data.forEach(res => {
      select.push({ label: res.nome, value: res.id });
    });

    setDadosTitulos(select);

    // // toast.error('testeeeeeee');
    setCarregando(false);
  };
  // coloca dentro do select de orgaos
  const loadOrgao = async () => {
    const response = await api.get('/orgao');
    setDadosOrgaos(response.data);
    // toast.error('testeeeeeee');
  };
  // carrega select de lotacao apos selecionar gerencia
  const loadLotacao = async gerencia_id => {
    const response = await api.get('/lotacao', {
      params: {
        gerencia_id: gerencia_id,
      },
    });
    setDadosLotacao(response.data);
    // toast.error('testeeeeeee');
  };

  const loadDados = async () => {
    // const response = await api.get(`/usuario/${idReq}`);

    const response = await api.get(`/usuario/${idReq}`);

    if (response.data === 'Acesso Proibido') {
      // roteamento
      toast.error('Acesso Negado, Falha reportada ao administrador!');
      history.push('/app');
      return;
    }

    const { lotacao, ...dados } = response.data;

    setTelefone(dados.telefone);
    setEmail(dados.email);
    setCpf(dados.cpf);
    setNome(dados.nome);
    setSobreNome(dados.sobrenome);
    setIdFuncional(dados.id_funcional);
    setAtivo(dados.ativo);
    setGenero(dados.genero);
    setRg(dados.rg);
    setNomeSocial(dados.nome_social);
    setDataNascimento(dados.data_nascimento);
    setCnh(dados.cnh);
    setCnhCategoria(dados.cnh_categoria);
    setCnhValidade(dados.cnh_validade);
    setAdido(dados.adido);
    setTipoSanguineo(dados.tipo_sanguineo);

    if (dados.adido) {
      setDadosAdido(dados.adidos[0].titulo.nome);
    }

    // setDadosAdido(dados.)

    if (dados?.vaga_usuarios[0]?.vaga?.cargo?.simbolo) {
      setCargoSimboloAtual(dados.vaga_usuarios[0].vaga.cargo.simbolo);
    }
    // popula os dados dentro do select de lotacao
    let escala = {};
    escala.lotacaoGerencia = lotacao.gerencia.nome;
    escala.lotacaoAla = lotacao.ala.nome;
    escala.lotacaoEscala = lotacao.escala.nome;
    escala.gerenciaId = lotacao.gerencia.id;

    // escala.setorArea = lotacao.setor.area.nome
    // escala.setorGuarnicao = lotacao.setor.guarnicao.nome
    geraModulos(escala.gerenciaId);
    setGerencia(escala.gerenciaId);
    setDados(dados);

    setLotacao(escala);
  };

  const EnviarUpdate = async () => {
    let dados = {};

    dados.telefone = telefone;
    dados.email = email;
    dados.cpf = cpf;
    dados.id_funcional = idFuncional;
    dados.ativo = ativo;
    dados.nome = nome;
    dados.genero = genero;
    dados.sobrenome = sobrenome;
    dados.lotacao_id = lotacaoId;
    dados.rg = rg;
    dados.nome_social = nomeSocial;
    dados.data_nascimento = dataNascimento;
    dados.cnh = cnh;
    dados.cnh_categoria = cnhCategoria;
    dados.cnh_validade = cnhValidade;
    if (dados.lotacao_id === '') {
      delete dados.lotacao_id;
    }
    if (tipoSanguineo !== '') {
      dados.tipo_sanguineo = tipoSanguineo;
    }

    try {
      const response = await api.put(`/usuario/${idReq}`, dados);

      toast.success('Usuário Alterado!');
      loadDados();
    } catch (error) {
      toast.error('Usuário não pôde ser alterado!');
    }
  };

  const enviaNovaNomeacao = async () => {
    if (
      diarioOficial === '' ||
      diarioOficial === undefined ||
      diarioOficial?.num_sei === '' ||
      diarioOficial?.num_sei === undefined
    ) {
      toast.error('Preencha o campo Número do SEI!');
      return;
    }
    if (
      diarioOficial === '' ||
      diarioOficial === undefined ||
      diarioOficial?.data === ''
    ) {
      toast.error('Preencha o campo Data de Nomeação!');
      return;
    }
    if (
      diarioOficial === '' ||
      diarioOficial === undefined ||
      diarioOficial?.tipo === ''
    ) {
      toast.error('Preencha o campo Tipo!');
      return;
    }
    // if (
    //   vagaSelecionada === '' ||
    //   vagaSelecionada === undefined ||
    //   vagaSelecionada?.num_sei === ''
    // ) {
    //   toast.error('Preencha o campo Número do SEI!');
    //   return;
    // }
    if (
      diarioOficial.tipo === 'Nomeação' &&
      (vagaSelecionada === '' ||
        vagaSelecionada === undefined ||
        vagaSelecionada?.value === '')
    ) {
      toast.error('Preencha o campo Número da Vaga!');
      return;
    }

    if (
      diarioOficial.tipo === 'Nomeação' ||
      diarioOficial.tipo === 'Nomeação em Cargo' ||
      diarioOficial.tipo === 'Exoneração de Cargo'
    ) {
      if (vagaSelecionada === '' || vagaSelecionada === undefined) {
        toast.error('Preencha o campo Número da Vaga!');
        return;
      }
      diarioOficial.vaga_id = vagaSelecionada.value;
    }

    // if (diarioOficial.tipo === 'Alteração de Vaga') {
    //   if (vagaSelecionada === '') {
    //     toast.error('Preencha o campo Número da Vaga!');
    //     return;
    //   }
    // }

    try {
      const response = await api.post(`/nomeacao`, {
        ...diarioOficial,
        usuario_id: idReq,
      });
      if (response.data.ok) {
        toast.success('Diário oficial inserido com Sucesso');
      }
      if (response.data.error) {
        toast.error('Diário oficial não inserido');
      }

      modalDO();

      if (response.data.tipo === 'Exoneração') {
        setAtivo(false);
      }

      if (response.data.tipo === 'Nomeação') {
        setAtivo(true);
      }
      setDiarioOficial({
        diario_oficial: '',
        data: '',
        tipo: '',
      });
      // geraModulos(gerencia);
    } catch (error) {
      toast.error('Diário oficial não pôde ser alterado!');
    }
  };

  const enviaResetSenha = async () => {
    const password = dados.cpf.slice(0, 5);

    try {
      const response = await api.put(`/usuario/${idReq}`, {
        password: password,
      });

      toast.success('Password foi Resetado!');
      modalReset();
    } catch (error) {
      toast.error('O Password não pôde ser alterado!');
    }
  };

  const alteraAcesso = async () => {
    const funcionalidade = [];
    updateAcesso.forEach(mod => {
      mod.funcionalidades.forEach(func => {
        if (func.acesso === true) {
          funcionalidade.push({ funcionalidade_id: func.id });
        }
      });
    });

    try {
      const response = await api
        .post(`/altera-acesso/${idReq}`, funcionalidade)
        .then(t => {
          geraModulos(gerencia);
        });
      setUpdateAcesso([]);
      toggleModalAcesso();

      toast.success('Alteração feita com Sucesso');
    } catch (error) {
      toast.error('Acesso não pôde ser alterado!');
    }
  };

  const alteraPermissao = (funcionalidade, isChecked) => {
    setUpdateAcesso(currentModulos =>
      currentModulos.map(modulo => {
        if (modulo.funcionalidades.id === funcionalidade.moduloId) {
          return {
            ...modulo,
            funcionalidades: modulo.funcionalidades.map(f => {
              if (f.id === funcionalidade.id) {
                return { ...f, acesso: isChecked };
              }
              return f;
            }),
          };
        }
        return modulo;
      })
    );
  };

  const modalReset = () => {
    setModalResetSenha(!modalResetSenha);
  };
  const modalDO = () => {
    setModalDiarioOficial(!modalDiarioOficial);
  };
  const toggleModalAcesso = useCallback(() => {
    setModalAcesso(prevModalAcesso => !prevModalAcesso);
  }, []);
  const selectFuncoes = async () => {
    const selectFuncoes = [];
    const funcoes = await api.get('/funcao');
    funcoes.data.forEach(t => {
      selectFuncoes.push({ label: t.nome, value: t.id });
    });
    setOptionFuncoes(selectFuncoes);
  };

  const selectCargo = async idFuncao => {
    const selectCargos = [];
    const cargos = await api.get('/cargos', {
      params: {
        gerencia_id: gerencia,
        funcao_id: idFuncao.value,
      },
    });

    cargos.data.forEach(t => {
      selectCargos.push({ label: `${t.simbolo} / ${t.nome}`, value: t.id });
    });
    // cargos para fazer o select das vagas
    setCargosDisponiveis(cargos.data);
    setCargoSelecionado('');
    setVagaSelecionada('');
    setOptionCargos(selectCargos);
  };

  const selectVaga = idCargo => {
    const selectVagas = [];
    cargosDisponiveis.forEach(cargo => {
      if (cargo.id === idCargo) {
        cargo.vagas.forEach(vaga => {
          selectVagas.push({ label: vaga.id, value: vaga.id });
        });
        setVagaSelecionada('');
        setVagasDisponiveis(selectVagas);
      }
    });
  };

  const uploadFile = async idUser => {
    if (image === '') {
      toast.error('Insira uma foto');

      return;
    }
    if (
      image.type === 'image/png' ||
      image.type === 'image/jpeg' ||
      image.type === 'image/jpg'
    ) {
    } else {
      toast.error('Somente arquivos PNG, JPG, JPEG');
      return;
    }
    setLoadingFoto(true);
    // setLoadingFile(true);
    const formData = new FormData();
    formData.append('file', image);

    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await api.post(
      `/foto-usuario/${idUser}`,
      formData,
      headers
    );
    // setLoadingFile(false);
    setModalFoto(false);
    setImage('');
    loadDados();
    setLoadingFoto(false);
    // setImagemInserida({ id: response.data.id, nome: response.data.nome });
    return response.data;
    // .then(response => {
    //   console.log(response)

    // }).catch(error => console.log(error))
    // rota: '/upload-file'
    // nome: file`
  };

  const uploadFileCNH = async idUser => {
    if (imageCnh === '') {
      toast.error('Insira uma foto');

      return;
    }
    if (
      imageCnh.type === 'image/png' ||
      imageCnh.type === 'image/jpeg' ||
      imageCnh.type === 'image/jpg'
    ) {
    } else {
      toast.error('Somente arquivos PNG, JPG, JPEG');
      return;
    }

    let quality = 1;
    if (imageCnh.size > 200000) {
      quality = 0.6;
    }
    new Compressor(imageCnh, {
      // quality: Math.round((1000000 / file.size) * 100) / 100,
      quality: quality,
      maxHeight: 1024,
      maxWidth: 1024,
      success: compressedResult => {
        setCompressedFileView(compressedResult);

        const reader = new FileReader();

        reader.readAsDataURL(compressedResult);
        reader.onload = () => {
          setCompressedFileBase64(reader.result);
          // setLoadingHandle(false);
        };
      },
    });

    setLoadingFotoCnh(true);
    // setLoadingFile(true);
    // const formData = new FormData();
    // formData.append('file', imageCnh);

    // const headers = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // };

    const response = await api.post(
      `/usuario-foto-cnh/${idUser}`,
      compressedFileView
    );
    // setLoadingFile(false);
    setModalFotoCnh(false);
    setImageCnh('');

    setLoadingFotoCnh(false);

    // setImagemInserida({ id: response.data.id, nome: response.data.nome });
    return response.data;
    // .then(response => {
    //   console.log(response)

    // }).catch(error => console.log(error))
    // rota: '/upload-file'
    // nome: file`
  };

  useEffect(() => {
    loadDados();
    loadGerencias();
    loadOrgao();
    selectFuncoes();
    fetchFerias();
  }, []);

  // quando a gerencia é alterada, pesquisa os modulos referentes a essa gerencia
  useEffect(() => {
    // verfica se o objeto está vazio
    const qtd = Object.entries(gerencia);
    if (qtd.length == 0) {
      return;
    }
    geraModulos(gerencia);
  }, [gerencia]);

  // useEffect(() => {
  //   console.log(buscaModulos);
  // }, [buscaModulos]);

  return (
    <>
      <NavBar>
        <MDBContainer>
          <MDBRow className="d-flex flex-column-reverse flex-md-row  align-items-stretch">
            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between mt-2 mt-md-0"
            >
              <MDBCard>
                <MDBCardBody className="d-flex flex-column">
                  <p className="h5 text-center">Informações</p>
                </MDBCardBody>
              </MDBCard>
              <br />
              <MDBCard>
                <MDBCardBody>
                  <div className=" d-flex justify-content-between">
                    <div className="black-text">Status:</div>
                    {ativo ? (
                      <div className="text-success rounded-pill px-4  border border-success font-weight-bold">
                        Nomeado
                      </div>
                    ) : (
                      <div className="text-danger rounded-pill px-4 border border-danger font-weight-bold">
                        Exonerado
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="d-flex ">
                    <MDBIcon icon="phone mr-3 " size="2x" />

                    <InputMask
                      style={{
                        border: 0,
                        borderBottom: '1px solid #ced4da',
                        fontSize: '1rem',
                      }}
                      className="d-flex w-100 font-weight-light"
                      label="Telefone"
                      type="text"
                      placeholder="Telefone"
                      onChange={e => setTelefone(e.target.value)}
                      value={telefone}
                      // value={telefone}
                      mask="(99) 9999 - 99999"
                      maskChar=" "
                    />
                  </div>
                  <MDBInput
                    label="Email"
                    icon="envelope"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <MDBInput
                    label="Id Funcional"
                    icon="envelope"
                    group
                    type="email"
                    validate
                    error="wrong"
                    success="right"
                    value={idFuncional}
                    onChange={e => setIdFuncional(e.target.value)}
                  />
                  <MDBInput
                    label="RG"
                    icon="envelope"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={rg}
                    onChange={e => setRg(e.target.value)}
                  />

                  <MDBInput
                    label="CNH"
                    icon="envelope"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    value={cnh}
                    onChange={e => setCnh(e.target.value)}
                  />

                  <div>
                    <span className="grey-text">CNH Categoria</span>
                    <select
                      className="browser-default custom-select"
                      onChange={e => setCnhCategoria(e.target.value)}
                      value={cnhCategoria || ''}
                    >
                      <option value="">Sem Carteira</option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                      <option>E</option>
                      <option>AB</option>
                      <option>AC</option>
                      <option>AD</option>
                      <option>AE</option>
                    </select>
                  </div>
                  <br />
                  <MDBInput
                    label="CNH Validade"
                    icon="envelope"
                    group
                    type="date"
                    validate
                    error="wrong"
                    success="right"
                    value={cnhValidade || ''}
                    onChange={e => setCnhValidade(e.target.value)}
                  />
                  <MDBRow>
                    <MDBCol size="12" className="text-right">
                      <MDBBtn size="sm" onClick={() => setModalFotoCnh(true)}>
                        Foto CNH
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>

              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBTable striped className="mt-3">
                  <MDBTableBody>
                    <tr>
                      <td>Gerência Atual</td>
                      <td className="font-weight-bold">
                        {lotacao.lotacaoGerencia}
                      </td>
                    </tr>
                    <tr>
                      <td>Lotação Atual</td>
                      <td>
                        {lotacao.lotacaoEscala} / {lotacao.lotacaoAla}
                      </td>
                    </tr>
                  </MDBTableBody>
                </MDBTable>
              </MDBCard>
              <MDBCard className="h-100 mt-2 mt-md-4">
                <p className="h5 text-center mt-3">Alterar Lotação</p>
                <MDBCardBody>
                  <span className="grey-text">Gerência</span>
                  <select
                    className="browser-default custom-select mb-3"
                    onChange={e => {
                      // loadTitulos(e.target.value);
                      setGerencia(e.target.value);
                      loadLotacao(e.target.value);
                    }}
                  >
                    <option value="0">Selecione</option>
                    {dadosGerencias.map(e => (
                      <option key={e.id} value={e.id}>
                        {e.nome}
                      </option>
                    ))}
                  </select>

                  <span className="grey-text">Lotação</span>
                  <select
                    className="browser-default custom-select mb-3"
                    onChange={e => {
                      // loadTitulos(e.target.value);

                      setLotacaoId(e.target.value);
                    }}
                  >
                    <option value="null">Selecione</option>
                    {dadosLotacao.map(e => (
                      <option key={e.id} value={e.id}>
                        {`${e.escala.nome} / ${e.ala.nome}`}
                      </option>
                    ))}
                  </select>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

            <MDBCol
              md="6"
              className="d-flex flex-column justify-content-between"
            >
              <MDBCard className="h-100">
                <MDBCardBody>
                  <form>
                    <MDBContainer>
                      <MDBRow className="justify-content-center">
                        <p className="h5 text-center">Perfil </p>
                      </MDBRow>
                      {/* <MDBRow center>
                        <MDBCol
                          size="4"
                          className="d-flex justify-content-center bg-danger"
                          style={{
                            opacity: '0.7',
                            cursor: 'pointer',
                          }}
                        >
                          <MDBIcon
                            style={{
                              fontSize: '25px',
                            }}
                            icon="edit"
                            onClick={() => setModalFoto(true)}
                          />
                          <div className="ml-2">Alterar Foto</div>
                        </MDBCol>
                      </MDBRow> */}
                      <MDBRow className="d-flex flex-column">
                        <MDBCol className="text-center ">
                          {/* {console.log(
                            dados.foto_usuario
                              ? dados.foto_usuario.url
                              : UserJpg
                          )} */}
                          <img
                            style={{
                              width: '200px',
                              // height: '200px',
                            }}
                            alt="foto do perfil"
                            src={
                              dados.foto_usuario
                                ? dados.foto_usuario.url
                                : UserJpg
                            }
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow center>
                        <div
                          onClick={() => setModalFoto(true)}
                          className="d-flex justify-content-center align-items-center z-depth-1-half"
                          style={{
                            backgroundColor: 'white',
                            opacity: '0.7',
                            width: '50px',
                            height: '50px',
                            borderRadius: '25px',
                            marginTop: '-60px',
                            marginLeft: '130px',
                            zIndex: '1000',
                            cursor: 'pointer',
                          }}
                        >
                          <MDBIcon
                            style={{
                              fontSize: '25px',
                            }}
                            icon="edit"
                          />
                        </div>
                      </MDBRow>
                      <MDBRow className="justify-content-center">
                        <h5>Nome:</h5>
                        <h4 className="red-text ml-3">
                          {dados.nome} {dados.sobrenome}
                        </h4>
                      </MDBRow>
                      {dados.vaga_usuarios ? (
                        <MDBTable striped className="mt-3 mb-0">
                          <MDBTableBody>
                            <tr>
                              <td>Cargo</td>
                              <td className="font-weight-bold  text-right">
                                {dados.vaga_usuarios[0]?.vaga.cargo.nome}
                              </td>
                            </tr>
                            <tr>
                              <td>Símbolo</td>
                              <td className="font-weight-bold text-right">
                                {dados.vaga_usuarios[0]?.vaga.cargo.simbolo}
                              </td>
                            </tr>
                            <tr>
                              <td>Função</td>
                              <td className="font-weight-bold  text-right">
                                {dados.vaga_usuarios[0]?.vaga.funcao.nome}
                              </td>
                            </tr>
                            <tr>
                              <td>Nº da Vaga:</td>
                              <td className="font-weight-bold  text-right">
                                {dados.vaga_usuarios[0]?.vaga.id}
                              </td>
                            </tr>
                            {dados.adidos.length > 0 ? (
                              <tr>
                                <td>Adido:</td>
                                <td className="font-weight-bold  text-right">
                                  {`${dados.adidos[0].orgao.sigla} -
                                ${dadosAdido}`}
                                  {alterarTitulo ? (
                                    <MDBIcon
                                      className="red-text pl-2 cursor-pointer"
                                      icon="times"
                                      onClick={() => {
                                        setAlterarTitulo(!alterarTitulo);
                                      }}
                                    />
                                  ) : (
                                    <MDBIcon
                                      className="red-text pl-2 cursor-pointer"
                                      icon="edit"
                                      onClick={() => {
                                        setAlterarTitulo(!alterarTitulo);
                                      }}
                                    />
                                  )}
                                </td>
                              </tr>
                            ) : (
                              ''
                            )}
                          </MDBTableBody>
                        </MDBTable>
                      ) : (
                        ''
                      )}
                      {alterarTitulo ? (
                        <MDBRow
                          className="mx-1 py-2"
                          style={{
                            borderLeft: 'silver solid 1px',
                            borderRight: 'silver solid 1px',
                            borderBottom: 'silver solid 1px',
                          }}
                        >
                          <MDBCol size="12" md="8">
                            <Select
                              isLoading={selectTitulosLoading}
                              options={dadosTitulos}
                              placeholder="Selecione o Título"
                              onChange={e => {
                                setTitulo(e);
                              }}
                              value={titulo}
                            />
                          </MDBCol>
                          <MDBCol className="text-center" size="12" md="4">
                            <MDBBtn
                              onClick={() => alteraTitulo()}
                              color="green"
                              disabled={carregando}
                              size="sm"
                            >
                              {carregando ? 'Alterando' : 'Alterar'}
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ''
                      )}

                      {/* <MDBRow>
                      <MDBCol col="12">
                        <MDBInput
                          label="Cargo"
                          icon="envelope"
                          type="email"
                          validate
                          error="wrong"
                          success="right"
                          value={dados.cargo && dados.cargo}
                        />
                      </MDBCol>
                    </MDBRow> */}
                      <br />
                      <MDBRow>
                        <MDBCol col="6">
                          <div>Gênero:</div>
                        </MDBCol>
                        <MDBCol col="6">
                          <select
                            className="browser-default custom-select"
                            onChange={option => {
                              setGenero(option.target.value);
                            }}
                            value={genero}
                          >
                            <option>Alterar Sexo</option>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                          </select>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12" className="p-3">
                          <div
                            style={{ color: 'rgba(0,0,0,0.4)' }}
                            className="ml-5"
                          >
                            {' '}
                            cpf:
                          </div>
                          <div className="d-flex align-items-center">
                            <MDBIcon icon="address-card mr-3 " size="2x" />
                            <InputMask
                              style={{
                                border: 0,
                                borderBottom: '1px solid #ced4da',
                              }}
                              className="d-flex w-100 font-weight-light"
                              label="CPF"
                              type="text"
                              placeholder="CPF"
                              disabled
                              // onChange={e =>
                              //   setCpf(e.target.value.replace(/\D+/g, ''))
                              // }
                              value={cpf}
                              mask="999.999.999-99"
                              maskChar=" "
                            />
                          </div>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12">
                          <MDBInput
                            className="my-0"
                            label="Nome"
                            icon="envelope"
                            group
                            type="text"
                            validate
                            success="right"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12">
                          <MDBInput
                            className="my-0"
                            label="Sobrenome"
                            icon="envelope"
                            group
                            type="text"
                            validate
                            success="right"
                            value={sobrenome}
                            onChange={e => setSobreNome(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12">
                          <MDBInput
                            className="my-0"
                            label="Nome Social"
                            icon="envelope"
                            group
                            type="text"
                            validate
                            success="right"
                            value={nomeSocial}
                            onChange={e => setNomeSocial(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12">
                          <MDBInput
                            className="my-0"
                            label="Data de Nascimento"
                            icon="envelope"
                            group
                            type="date"
                            validate
                            success="right"
                            value={dataNascimento}
                            onChange={e => setDataNascimento(e.target.value)}
                          />
                        </MDBCol>
                      </MDBRow>
                      <MDBRow>
                        <MDBCol col="12">
                          <span className="grey-text">Tipo Sanguíneo</span>
                          <select
                            className="browser-default custom-select"
                            onChange={e => setTipoSanguineo(e.target.value)}
                            value={tipoSanguineo || ''}
                          >
                            <option value="">Tipo Sanguíneo</option>
                            <option>A+</option>
                            <option>A-</option>
                            <option>B+</option>
                            <option>B-</option>
                            <option>AB+</option>
                            <option>AB-</option>
                            <option>O+</option>
                            <option>O-</option>
                          </select>
                        </MDBCol>
                      </MDBRow>

                      <br />

                      <MDBRow>
                        <MDBCol col="12" md="6" className="text-center mt-2">
                          <div>Resetar senha do usuário:</div>
                          <MDBBtn onClick={modalReset} color="warning">
                            Reset
                          </MDBBtn>
                        </MDBCol>

                        <MDBCol col="12" md="6" className="text-center mt-2">
                          <div> Inserir publicação D.O:</div>

                          <MDBBtn onClick={modalDO} color="primary">
                            Inserir
                          </MDBBtn>
                        </MDBCol>
                      </MDBRow>

                      {/* <MDBRow>
                        <span className="grey-text">Adido</span>
                        <select
                          className="browser-default custom-select"
                          onChange={e => {
                            setAdido(e.target.value);
                            setAdidoForm(!adidoForm);
                          }}
                          value={adido}
                        >
                          <option value="true">SIM</option>
                          <option value="false">NÃO</option>
                        </select>
                      </MDBRow>
                      <MDBRow> */}
                      {/* <MDBCollapse id="basicCollapse" isOpen={adidoForm}>
                          <MDBCard>
                            <MDBCardBody>
                              <div className="h5-responsive text-start font-weight-bold">
                                Adido
                              </div>
                              <MDBRow className="justify-content-center">
                                <MDBCol md="6">
                                  <span className="grey-text">Orgão</span>
                                  <select
                                    className="browser-default custom-select"
                                    onChange={e => {
                                      loadTitulos(e.target.value);
                                      setOrgao(e.target.value);
                                    }}
                                    value={orgao}
                                  >
                                    {dadosOrgaos.map(e => (
                                      <option key={e.id} value={e.id}>
                                        {e.nome}
                                      </option>
                                    ))}
                                  </select>
                                </MDBCol>
                                <MDBCol md="6">
                                  <span className="grey-text">Título</span>
                                  <select
                                    className="browser-default custom-select"
                                    onChange={e => setTitulo(e.target.value)}
                                    // defaultValue="option"
                                    value={titulo}
                                  >
                                    <option value="option" disabled>
                                      {carregando == true
                                        ? 'Carregando... '
                                        : 'Selecione seu Título'}
                                    </option>
                                    {dadosTitulos.map(e => (
                                      <option key={e.id} value={e.id}>
                                        {e.nome}
                                      </option>
                                    ))}
                                  </select>
                                </MDBCol>
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                          <br />
                        </MDBCollapse>
                      </MDBRow> */}
                    </MDBContainer>
                  </form>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mb-4">
            <MDBCol>
              <MDBCard className="h-100 mt-2 mt-md-4">
                <MDBCardBody className="h-100">
                  <MDBRow>
                    <MDBCol size="12">
                      <h4 className="deep-orange lighten-5 text-center p-2">
                        Férias Antigas
                      </h4>
                      {feriasAntigas.length > 0 ? (
                        feriasAntigas.map(ferias => (
                          <>
                            <MDBRow between key={ferias.id} className="mt-3">
                              <MDBCol>
                                Ano de Referência: {ferias.ano_referencia}
                                <br />
                                Dias Restantes:{' '}
                                {ferias.dias -
                                  (ferias.ferias?.reduce(
                                    (acc, curr) => acc + curr.qtd_dias,
                                    0
                                  ) || 0)}
                              </MDBCol>

                              {ferias.ferias && ferias.ferias.length > 0 ? (
                                <>
                                  <MDBCol className="text-center">
                                    Períodos Tirados:
                                    {ferias.ferias.map(t => (
                                      <div key={t.id}>
                                        {`${format(
                                          parseISO(t.data_inicio),
                                          'dd/MM/yyyy'
                                        )} - ${format(
                                          parseISO(t.data_fim),
                                          'dd/MM/yyyy'
                                        )}`}
                                      </div>
                                    ))}
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    {ferias.ferias.some(
                                      t => t.data_coordenador === null
                                    ) ? (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-warning">
                                          Aguardando Confirmação
                                        </h4>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-success">
                                          Confirmada
                                        </h4>
                                      </MDBCol>
                                    )}
                                  </MDBCol>
                                </>
                              ) : (
                                <MDBCol className="text-right">
                                  <h5>Status</h5>
                                  <h4 className="text-danger">Pendente</h4>
                                </MDBCol>
                              )}
                            </MDBRow>
                            <hr />
                          </>
                        ))
                      ) : (
                        <p>Nenhuma férias antiga registrada.</p>
                      )}
                    </MDBCol>
                    <MDBCol size="12">
                      <h4 className="mdb-color lighten-5 lighten-5 text-center p-2">
                        Férias Recebidas
                      </h4>
                      {feriasSecc.length > 0 ? (
                        feriasSecc.map(ferias => (
                          <>
                            <MDBRow between key={ferias.id}>
                              <MDBCol>
                                Ano de Referência: {ferias.ano_referencia}
                                <br />
                                Dias Restantes:{' '}
                                {30 -
                                  ferias.ferias.reduce(
                                    (acc, curr) => acc + curr.qtd_dias,
                                    0
                                  )}
                              </MDBCol>

                              {ferias.ferias && ferias.ferias.length > 0 ? (
                                <>
                                  <MDBCol className="text-center">
                                    Períodos Tirados:
                                    {ferias.ferias.map(t => (
                                      <div key={t.id}>
                                        {`${format(
                                          parseISO(t.data_inicio),
                                          'dd/MM/yyyy'
                                        )} - ${format(
                                          parseISO(t.data_fim),
                                          'dd/MM/yyyy'
                                        )}`}
                                      </div>
                                    ))}
                                  </MDBCol>
                                  <MDBCol className="text-right">
                                    {ferias.ferias.some(
                                      t => t.data_coordenador === null
                                    ) ? (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-warning">
                                          Aguardando Confirmação
                                        </h4>
                                      </MDBCol>
                                    ) : (
                                      <MDBCol>
                                        <h5>Status</h5>
                                        <h4 className="text-success">
                                          Confirmada
                                        </h4>
                                      </MDBCol>
                                    )}
                                  </MDBCol>
                                </>
                              ) : (
                                <MDBCol className="text-right">
                                  <h5>Status</h5>
                                  <h4 className="text-danger">Pendente</h4>
                                </MDBCol>
                              )}
                            </MDBRow>
                            <hr />
                          </>
                        ))
                      ) : (
                        <p>Nenhuma férias SECC registrada.</p>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>

          {dados.enderecos ? (
            <MDBRow>
              <MDBCol>
                <MDBCard className="mt-3 px-4">
                  <p className="h5 text-center mt-3">Endereço</p>
                  {/* MUDAR ENDERECO */}
                  {/* <MDBIcon
                    className="red-text pl-2 cursor-pointer"
                    icon="edit"
                    onClick={() => {
                      setAlterarTitulo(!alterarTitulo);
                    }}
                  /> */}
                  <MDBTable striped className="mt-3">
                    <MDBTableBody>
                      <tr>
                        <td>Endereço</td>
                        <td className="font-weight-bold  text-right">
                          {`${dados.enderecos[0]?.logradouro} Nº:${dados.enderecos[0]?.numero}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Complemento</td>
                        <td className="font-weight-bold  text-right">
                          {`${
                            dados.enderecos[0]?.complemento === null
                              ? ''
                              : dados.enderecos[0]?.complemento
                          } `}
                        </td>
                      </tr>
                      <tr>
                        <td>CEP</td>
                        <td className="font-weight-bold  text-right">
                          {`${dados.enderecos[0]?.cep} `}
                        </td>
                      </tr>
                      <tr>
                        <td>Bairro</td>
                        <td className="font-weight-bold  text-right">
                          {`${dados.enderecos[0]?.bairro}`}
                        </td>
                      </tr>
                      <tr>
                        <td>Cidade / UF</td>
                        <td className="font-weight-bold  text-right">
                          {`${dados.enderecos[0]?.cidade} / ${dados.enderecos[0]?.uf}`}
                        </td>
                      </tr>
                    </MDBTableBody>
                  </MDBTable>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          ) : (
            ''
          )}
          <MDBRow className="justify-content-center mt-3">
            <MDBBtn
              color="mdb-color"
              onClick={() => {
                EnviarUpdate();
              }}
            >
              Salvar Alterações
            </MDBBtn>
          </MDBRow>

          {/* <MDBIcon icon="check" /> */}
          {/* <MDBIcon icon="times" /> */}
          <MDBCard className="mt-3">
            <MDBRow
              style={{ borderRadius: '5px' }}
              className="px-5 align-items-center grey  lighten-2"
            >
              <MDBCol className="h3-responsive">Acessos Atuais</MDBCol>

              <MDBBtn
                className="mr-3"
                onClick={toggleModalAcesso}
                color="primary"
              >
                Alterar Acesso
              </MDBBtn>
            </MDBRow>
            {buscaModulos == []
              ? ''
              : buscaModulos.map(linha => (
                  <div key={linha.id}>
                    <MDBCardBody style={{ borderTop: '2px solid silver' }}>
                      <div className="h5-responsive text-start font-weight-bold">
                        {linha.modulo}
                      </div>

                      <MDBRow className="justify-content-center">
                        {linha.funcionalidades.map(funcionalidades => (
                          <MDBCol md="3" key={funcionalidades.id}>
                            <div className="d-flex align-items-center">
                              <div>{funcionalidades.nome} - </div>
                              <div className="ml-2">
                                {funcionalidades.acesso === true ? (
                                  <MDBIcon
                                    className="text-success"
                                    icon="check"
                                  />
                                ) : (
                                  <MDBIcon
                                    className="text-danger"
                                    icon="times"
                                  />
                                )}
                              </div>
                            </div>
                          </MDBCol>
                        ))}
                      </MDBRow>
                    </MDBCardBody>
                  </div>
                ))}
          </MDBCard>
          <br />
          <MDBModal
            isOpen={modalResetSenha}
            toggle={() => {
              setModalResetSenha({
                modalResetSenha: !modalResetSenha,
              });
            }}
          >
            <MDBModalHeader toggle={modalReset}>
              Resetar senha do usuário?
            </MDBModalHeader>
            <MDBModalBody>
              Após a alteração da senha do usuário, o processo não poderá ser
              desfeito.
              <br />
              <br />
              <div className="text-danger">
                Obs: A senha do usuário será redefinida com os primeiros 5
                digitos do CPF.
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={modalReset}>
                Cancelar
              </MDBBtn>
              <MDBBtn onClick={enviaResetSenha} color="success">
                Resetar Senha
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            size="lg"
            isOpen={modalDiarioOficial}
            toggle={() => {
              setModalDiarioOficial({
                modalDiarioOficial: !modalDiarioOficial,
              });
            }}
          >
            <MDBModalHeader toggle={modalDO}>
              Adicionar Diário oficial
            </MDBModalHeader>
            <MDBModalBody>
              <MDBRow
                start
                style={{ fontSize: '13px' }}
                className="ml-5 align-items-center"
              >
                <MDBIcon icon="asterisk" />{' '}
                <div className="ml-2">Preenchimento Obrigatório</div>
              </MDBRow>
              <br />
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    label="Data *"
                    // icon="user-tag"
                    group
                    // size="lg"
                    type="date"
                    onChange={e =>
                      setDiarioOficial({
                        ...diarioOficial,
                        data: e.target.value,
                      })
                    }
                    // value={doData}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    label="Número Diário Oficial"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    // value={idFuncional}
                    onChange={e =>
                      setDiarioOficial({
                        ...diarioOficial,
                        diario_oficial: e.target.value,
                      })
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    label="Número do SEI *"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    // value={idFuncional}
                    onChange={e =>
                      setDiarioOficial({
                        ...diarioOficial,
                        num_sei: e.target.value,
                      })
                    }
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
                    label="Número do BOL"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                    // value={idFuncional}
                    onChange={e =>
                      setDiarioOficial({
                        ...diarioOficial,
                        num_bol: e.target.value,
                      })
                    }
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow center>
                <div className="d-flex align-items-center col-6">
                  <div>Tipo:</div>

                  <select
                    className="ml-3  browser-default custom-select w-100"
                    onChange={e =>
                      setDiarioOficial({
                        ...diarioOficial,
                        tipo: e.target.value,
                      })
                    }

                    // value={genero}
                  >
                    <option>Tipo</option>
                    {/* <option value="Exoneração">Exoneração</option>
                    <option value="Nomeação">Nomeação</option> */}
                    {ativo == true ? (
                      <>
                        {adido === true ? (
                          cargoSimboloAtual === 'REQINT' ? (
                            <option value="Nomeação em Cargo">
                              Nomeação em Cargo
                            </option>
                          ) : (
                            <option value="Exoneração de Cargo">
                              Exoneração de Cargo
                            </option>
                          )
                        ) : (
                          ''
                        )}
                        <option value="Exoneração">Exoneração</option>
                      </>
                    ) : (
                      <option value="Nomeação">Nomeação</option>
                    )}
                  </select>
                </div>
              </MDBRow>
              {diarioOficial.tipo === 'Nomeação' ||
              diarioOficial.tipo === 'Nomeação em Cargo' ||
              diarioOficial.tipo === 'Exoneração de Cargo' ? (
                <>
                  <br />
                  <MDBRow center className="h5-responsive font-weight-bolder">
                    Quadro de vagas
                  </MDBRow>
                  <MDBRow className="py-3 d-flex align-items-center">
                    <MDBCol size="12" md="3">
                      <Select
                        placeholder="Função *"
                        options={optionFuncoes}
                        value={funcaoSelecionada}
                        onChange={t => {
                          setFuncaoSelecionada(t);
                          selectCargo(t);
                        }}
                      />
                    </MDBCol>
                    <MDBCol size="12" md="3">
                      <Select
                        placeholder="Cargo *"
                        onChange={t => {
                          setCargoSelecionado(t);
                          selectVaga(t.value);
                          // setVagaSelecionada(vagasDisponiveis[0]);
                        }}
                        value={cargoSelecionado}
                        options={optionCargos}
                      />
                    </MDBCol>
                    <MDBCol size="12" md="3">
                      <Select
                        onChange={t => setVagaSelecionada(t)}
                        options={vagasDisponiveis}
                        placeholder="Nº da Vaga *"
                        value={vagaSelecionada}
                      />
                    </MDBCol>
                    <MDBCol size="12" md="3">
                      <MDBInput
                        label="Gee"
                        group
                        type="number"
                        // value={idFuncional}
                        onChange={e =>
                          setDiarioOficial({
                            ...diarioOficial,
                            gee: e.target.value,
                          })
                        }
                      />
                    </MDBCol>
                  </MDBRow>
                </>
              ) : (
                ''
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={modalDO}>
                Cancelar
              </MDBBtn>
              <MDBBtn onClick={enviaNovaNomeacao} color="success">
                Inserir D.O.
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal size="lg" isOpen={modalAcesso} toggle={toggleModalAcesso}>
            <MDBModalHeader toggle={toggleModalAcesso}>
              Alterar Acesso do Usuário
            </MDBModalHeader>

            <MDBModalBody>
              {updateAcesso == []
                ? ''
                : updateAcesso.map(linha => (
                    <div key={linha.id}>
                      <MDBCard className="mt-2">
                        <MDBCardBody>
                          <div className="h5-responsive text-start font-weight-bold">
                            {linha.modulo}
                          </div>
                          <MDBRow className="justify-content-center">
                            {linha.funcionalidades.map(funcionalidade => (
                              <MDBCol md="3" key={`func${funcionalidade.id}`}>
                                <MDBInput
                                  containerClass="form-check checkbox-teal"
                                  labelClass="form-check-label"
                                  label={funcionalidade.nome}
                                  type="checkbox"
                                  className="checkbox-rounded"
                                  checked={funcionalidade.acesso}
                                  id={funcionalidade.id}
                                  getValue={t => {
                                    alteraPermissao(funcionalidade, t);
                                  }}
                                />
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCard>
                    </div>
                  ))}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="danger" onClick={toggleModalAcesso}>
                Cancelar
              </MDBBtn>
              <MDBBtn onClick={alteraAcesso} color="success">
                Alterar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            size="lg"
            isOpen={modalFoto}
            toggle={() => setModalFoto(!modalFoto)}
          >
            <MDBModalHeader toggle={() => setModalFoto(!modalFoto)}>
              Alterar Foto do Usuário
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <MDBRow center>
                <img
                  style={{ width: '200px' }}
                  alt="foto do perfil"
                  src={dados.foto_usuario ? dados.foto_usuario.url : UserJpg}
                />
              </MDBRow>
              <MDBRow center>
                <MDBCol size="8">
                  <MDBFileInput
                    textFieldTitle="Arquivo de foto"
                    btnTitle="Inserir"
                    btnColor="default"
                    allowedFileExtensions={['jpg', 'png', 'jpeg']}
                    value={image}
                    getValue={
                      e => {
                        setImage(e[0]);
                      }
                      // setImagemInseridaNome(e[0].name);
                    }
                  />
                </MDBCol>
              </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                disabled={loadingFoto}
                color="danger"
                onClick={() => setModalFoto(!modalFoto)}
              >
                Cancelar
              </MDBBtn>
              <MDBBtn
                disabled={loadingFoto}
                onClick={() => uploadFile(idReq)}
                color="success"
              >
                {loadingFoto === true ? (
                  <>
                    <span> Enviando ... </span>
                  </>
                ) : (
                  'Alterar'
                )}
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <MDBModal
            size="lg"
            isOpen={modalFotoCnh}
            toggle={() => setModalFotoCnh(!modalFotoCnh)}
          >
            <MDBModalHeader toggle={() => setModalFotoCnh(!modalFotoCnh)}>
              Foto da CNH
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <SendBlob
                // abordagem_id={modalFoto}
                maxImages="1"
                getFotos={{
                  buscaFotosURL: `/usuario-foto-cnh`,
                  buscaFotosWhere: { usuario_id: idReq },
                }}
                postFotos={{
                  enviarFotoURL: `/usuario-foto-cnh`,
                  enviarRefId: { usuario_id: idReq },
                }}
                // deleteFotos={{
                //   deletarFotoURL: '/sgo-delete-img',
                //   // refId: { id: modalFoto },
                // }}
              />
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                disabled={loadingFotoCnh}
                color="danger"
                onClick={() => setModalFotoCnh(!modalFotoCnh)}
              >
                Fechar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
          <br />
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
}

export default Perfil;
