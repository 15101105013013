import React from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBRow,
  MDBIcon,
} from 'mdbreact';
import { Link } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import img from '../../assets/images.jpg';
import gerenciaImg from '../../assets/gerencia.jpg';
import cargosImg from '../../assets/cargos.jpg';

// import { Link } from '@material-ui/core';
const Adm = /* props */ () => {
  return (
    <>
      <NavBar />
      <MDBContainer fluid>
        {/* espacamento  */}

        <MDBRow className="d-flex justify-content-center">
          <MDBCard
            style={{
              backgroundImage: `url(${img})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-5 mr-2 ml-2 mt-3 mt-md-0 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">GESTÃO DE EFETIVO</MDBCardTitle>
            <MDBCardText className="grey-text text-left">
              Registrar o ingresso do servidor na Secretária de Governo.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around  pt-3 mt-3"
            >
              <Link
                to="/usuario/add"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>
              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Estatísticas
                </span>
                <MDBIcon icon="chart-pie" size="2x" />
              </Link>
              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${gerenciaImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 mt-md-0 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">GESTÃO DE GERÊNCIAS</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Criação e Gerenciamento das operaçoes e suboperações.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3"
            >
              <Link
                to="/gerencia/add"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Adicionar</span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>

              <Link
                to="/gerencias"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>Listar</span>

                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
          <MDBCard
            style={{
              backgroundImage: `url(${cargosImg})`,
              backgroundSize: 'cover',
            }}
            className="justify-content-between card-body col-12 col-md-3 mr-2 ml-2 mt-3 mt-md-0 blue-grey-text text-center"
          >
            <MDBCardTitle className="h5">GESTÃO DE CARGOS</MDBCardTitle>

            <MDBCardText className="grey-text text-left">
              Registrar o ingresso de novos cargos e administrar os existentes,
              Assim como calcular o custo operacional da Operação.
            </MDBCardText>

            <div
              style={{ borderTop: '1px rgba(0,0,0,0.1) solid' }}
              className="flex-row d-flex justify-content-around pt-3 mt-3 "
            >
              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Patrimônios
                </span>
                <MDBIcon far icon="plus-square" size="2x" />
              </Link>

              <Link
                to="/usuarios"
                className="d-flex flex-column align-items-center blue-grey-text"
              >
                <span style={{ fontSize: 10, marginBottom: 5 }}>
                  Estatísticas
                </span>
                <MDBIcon icon="list" size="2x" />
              </Link>
            </div>
          </MDBCard>
        </MDBRow>

        <br />
        <br />
      </MDBContainer>
    </>
  );
};

export default Adm;
