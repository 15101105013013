import React from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from 'mdbreact';
import { Link } from 'react-router-dom';

import NavBar from '../../components/NavBar';
import img from '../../assets/images.jpg';
import therley from '../../assets/therley.jpeg';

// import { Link } from '@material-ui/core';
const Adm = /* props */ () => {
  return (
    <>
      <NavBar>
        <MDBContainer fluid>
          {/* espacamento  */}
          <MDBRow className="font-weight-bolder">
            <MDBCol size="12" md="6">
              <MDBRow>
                Nome:{' '}
                <span className="font-weight-bolder">
                  Thérley Junior Neves Fagundes
                </span>
              </MDBRow>
              <MDBRow>Id: 5095608-6</MDBRow>
              <MDBRow>Gerência: Mato Verde</MDBRow>
              <MDBRow>Ala: Charlie</MDBRow>
              <MDBRow>Nomeação: 03/09/2018</MDBRow>
              <MDBRow className="h4">
                Situação:
                <span className="ml-2 text-success font-weight-bolder">
                  Ativo
                </span>
              </MDBRow>
            </MDBCol>
            <MDBCol size="12" md="6">
              <br />
              <div className="text-center">
                <img
                  className="img-fluid"
                  width="300"
                  alt="foto do perfil"
                  src={therley}
                />
              </div>
            </MDBCol>
          </MDBRow>
          <br />
        </MDBContainer>
      </NavBar>
    </>
  );
};

export default Adm;
