import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Select from 'react-select';

import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBCardBody,
  MDBCard,
  MDBIcon,
  MDBInput,
  MDBContainer,
  MDBCollapse,
  MDBCardTitle,
  MDBCardText,
  MDBView,
  MDBCardImage,
  MDBTypography,
  MDBBox,
  MDBSwitch,
} from 'mdbreact';
import InputMask from 'react-input-mask';
import axios from 'axios';
import api from '../../../services/api';
import NavBar from '../../../components/NavBar';

function AddUsuario() {
  // CAMPOS A SEREM PREENCHIDOS

  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [nomeSocial, setNomeSocial] = useState('');

  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [idFuncional, setIdFuncional] = useState('');

  const [genero, setGenero] = useState('M');
  const [dataNascimento, setDataNascimento] = useState('');
  const [tipoSanguineo, setTipoSanguineo] = useState(null);

  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');

  const [cnh, setCnh] = useState('');
  const [cnhCategoria, setCnhCategoria] = useState('');
  const [cnhValidade, setCnhValidade] = useState('');

  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');

  // SWITCHS ( SIM / NAO )
  const [adido, setAdido] = useState(false);
  const [orgao, setOrgao] = useState('');
  const [titulo, setTitulo] = useState('');

  // DADOS DE DIARIO OFICIAL
  const [diarioOficial, setDiarioOficial] = useState('');
  const [doData, setDoData] = useState('');
  const [numSei, setNumSei] = useState('');
  const [numBol, setNumBol] = useState('');

  // VALORES SELECIONADOS
  const [gerenciaSelecionada, setGerenciaSelecionada] = useState('');
  const [lotacaoSelecionada, setLotacaoSelecionada] = useState();

  // CONTROLES DOS MODULOS EXISTENTES
  const [buscaModulos, setBuscaModulos] = useState([]);

  // CONTROLE DE ACESSO AOS MODULOS E FUNCIONALIDADES
  const [addAcesso, setAddAcesso] = useState([]);

  // FUNCOES
  const [optionFuncoes, setOptionFuncoes] = useState([]);
  const [funcaoSelecionada, setFuncaoSelecionada] = useState();

  // CARGOS
  const [optionCargos, setOptionCargos] = useState([]);
  const [cargoSelecionado, setCargoSelecionado] = useState();
  // cargos para fazer o select das vagas
  const [cargosDisponiveis, setCargosDisponiveis] = useState([]);

  // VAGAS DISPONIVEIS
  const [vagasDisponiveis, setVagasDisponiveis] = useState([]);
  const [vagaSelecionada, setVagaSelecionada] = useState();
  const [gee, setGee] = useState('');

  // DADOS
  const [dadosOrgaos, setDadosOrgaos] = useState([]);
  const [dadosGerencias, setDadosGerencias] = useState([]);
  const [dadosLotacoes, setDadosLotacoes] = useState([]);
  const [dadosTitulos, setDadosTitulos] = useState([]);

  // LOADINGS
  const [selectLotacaoLoading, setSelectLotacaoLoading] = useState(false);
  const [selectTitulosLoading, setSelectTitulosLoading] = useState(false);

  // SELECT GERENCIAS
  const loadGerencias = async () => {
    const optionSelect = [];
    const response = await api.get('/gerencia');
    response.data.forEach(element => {
      optionSelect.push({ label: element.nome, value: element.id });
    });

    setDadosGerencias(optionSelect);
  };

  // SELECT LOTACAO
  const loadLotacoes = async () => {
    if (gerenciaSelecionada === '') {
      return;
    }
    setSelectLotacaoLoading(true);
    const optionSelect = [];
    const response = await api.get('/lotacao', {
      params: {
        gerencia_id: gerenciaSelecionada,
      },
    });
    response.data.forEach(element => {
      optionSelect.push({
        label: `${element.escala.nome}/${element.ala.nome}`,
        value: element.id,
      });
    });

    setDadosLotacoes(optionSelect);
    setLotacaoSelecionada(optionSelect[0]);
    setSelectLotacaoLoading(false);
  };

  // PEGA API VIA CEP E INCLUI NO CAMPOS OS DADOS
  const localizaCep = () => {
    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(response => {
      setBairro(response.data.bairro);
      setCidade(response.data.localidade);
      setUf(response.data.uf);
      setLogradouro(response.data.logradouro);
    });
  };

  // GERA OS MODULOS DE ACESSO
  const geraModulos = async gerencia_id => {
    const response = await api.get(`/gerenciaid-modulo/${gerencia_id}`);
    setBuscaModulos(response.data);
  };

  // quando a gerencia é alterada, pesquisa os modulos referentes a essa gerencia
  useEffect(() => {
    // const teste = Object.entries(gerenciaSelecionada);

    if (gerenciaSelecionada == '') {
      return;
    }

    geraModulos(gerenciaSelecionada);

    // GERA AS FUNCOES DO SELECT DE BAIXO
    selectFuncoes();
  }, [gerenciaSelecionada]);

  // SELECT ORGAOS
  const loadOrgao = async () => {
    const select = [];
    const response = await api.get('/orgao');
    response.data.forEach(linha => {
      select.push({ label: linha.nome, value: linha.id });
    });
    setDadosOrgaos(select);
  };

  // SELECT TITULOS
  const loadTitulos = async orgao => {
    const select = [];
    setSelectTitulosLoading(true);
    const response = await api.get(`${orgao}/titulo`);
    response.data.forEach(linha => {
      select.push({ label: linha.nome, value: linha.id });
    });

    setDadosTitulos(select);
    // toast.error('testeeeeeee');
    setTitulo(select[0]);
    setSelectTitulosLoading(false);
  };

  const selectFuncoes = async () => {
    const selectFuncoes = [];
    const funcoes = await api.get('/funcao');
    funcoes.data.forEach(t => {
      selectFuncoes.push({ label: t.nome, value: t.id });
    });
    setOptionFuncoes(selectFuncoes);
  };

  const selectCargo = async idFuncao => {
    const selectCargos = [];
    const cargos = await api.get('/cargos', {
      params: {
        gerencia_id: gerenciaSelecionada,
        funcao_id: idFuncao.value,
      },
    });

    cargos.data.forEach(t => {
      selectCargos.push({ label: `${t.simbolo} / ${t.nome}`, value: t.id });
    });
    // cargos para fazer o select das vagas
    setCargosDisponiveis(cargos.data);
    setCargoSelecionado('');
    setVagaSelecionada('');
    setOptionCargos(selectCargos);
  };

  const selectVaga = idCargo => {
    const selectVagas = [];
    cargosDisponiveis.forEach(cargo => {
      if (cargo.id === idCargo) {
        cargo.vagas.forEach(vaga => {
          selectVagas.push({ label: vaga.id, value: vaga.id });
        });
        setVagaSelecionada('');
        setVagasDisponiveis(selectVagas);
      }
    });
  };

  const enviaDados = async () => {
    if (nome === '') {
      toast.error('Preencha o campo Nome!');
      return;
    }
    if (sobrenome === '') {
      toast.error('Preencha o campo Sobrenome!');
      return;
    }
    if (nomeSocial === '') {
      toast.error('Preencha o campo Nome Social!');
      return;
    }

    if (cpf === '' || cpf.length !== 11) {
      toast.error('Preencha o campo CPF!');
      return;
    }
    if (rg === '') {
      toast.error('Preencha o campo RG!');
      return;
    }
    if (idFuncional === '') {
      toast.error('Preencha o campo Id Funcional!');
      return;
    }
    if (genero === '') {
      toast.error('Preencha o campo Gênero!');
      return;
    }
    if (dataNascimento === '') {
      toast.error('Preencha o campo Data de Nascimento!');
      return;
    }
    // if (tipoSanguineo === null) {
    //   toast.error('Preencha o Tipo Sanguíneo');
    //   return;
    // }

    if (email === '') {
      toast.error('Preencha o campo Email!');
      return;
    }
    if (telefone === '') {
      toast.error('Preencha o campo Telefone!');
      return;
    }
    if (doData === '') {
      toast.error('Preencha o Data de Nomeação!');
      return;
    }
    if (numSei === '') {
      toast.error('Preencha o campo Nº do SEI!');
      return;
    }

    if (gerenciaSelecionada === '') {
      toast.error('Preencha o campo Gerência e Lotação!');
      return;
    }

    if (adido === true) {
      if (orgao === '' || titulo === '') {
        toast.error('Preencha os campos Orgão e Titulo!');
        return;
      }
    }

    if (
      vagaSelecionada === '' ||
      vagaSelecionada === undefined ||
      vagaSelecionada?.value === ''
    ) {
      toast.error('Preencha o campo Número da Vaga!');
      return;
    }

    // if (cep === '') {
    //   toast.error('Preencha o campo Cep!');
    //   return;
    // }
    // if (numero === '') {
    //   toast.error('Preencha o campo Número Residencial!');
    //   return;
    // }

    const dados = {
      nome /* campo requerido */,
      sobrenome /* campo requerido */,
      nome_social: nomeSocial /* campo requerido */,
      cpf /* campo requerido */,
      rg /* campo requerido */,
      id_funcional: idFuncional /* campo requerido */,
      genero,
      data_nascimento: dataNascimento /* campo requerido */,
      tipo_sanguineo: tipoSanguineo /*campo requerido */,
      email /* campo requerido */,
      telefone /* campo requerido */,
      adido,

      diario_oficial: diarioOficial,
      data: doData,

      lotacao_id: lotacaoSelecionada.value /* campo requerido */,
      add_acesso: addAcesso,
      // novos campos
      vaga_selecionada: vagaSelecionada.value,
      gee: gee,
    };
    if (cep !== '') {
      dados.cep = cep;
    }
    if (bairro !== '') {
      dados.bairro = bairro;
    }
    if (cidade !== '') {
      dados.cidade = cidade;
    }
    if (uf !== '') {
      dados.uf = uf;
    }
    if (logradouro !== '') {
      dados.logradouro = logradouro;
    }
    if (numero !== '') {
      dados.numero = numero;
    }
    if (complemento !== '') {
      dados.complemento = complemento;
    }

    if (adido === true) {
      dados.orgao_id = orgao;
      dados.titulo_id = titulo.value;
    }
    if (cnh !== '') {
      dados.cnh = cnh;
    }
    if (cnhCategoria !== '') {
      dados.cnh_categoria = cnhCategoria;
    }
    if (cnhValidade !== '') {
      dados.cnh_validade = cnhValidade;
    }
    if (numSei !== '') {
      dados.num_sei = numSei;
    }
    if (numBol !== '') {
      dados.num_bol = numBol;
    }

    try {
      const teste = await api.post('/usuario', dados);

      if (teste.data.erro) {
        toast.error(teste.data.erro);
        return;
      }
      toast.success('Usuário Adicionado!');
      setNome('');
      setSobrenome('');
      setCpf('');
      setEmail('');
      setTelefone('');
      setIdFuncional('');
      setDiarioOficial('');
      setDataNascimento('');
      setNomeSocial('');
      setRg('');
      setCnh('');
      setGenero('M');
      setCnhCategoria('');
      setCnhValidade('');

      setDoData('');
      setNumSei('');
      setNumBol('');

      setOrgao('');
      setTitulo('');
      setAdido(false);
      setCep('');
      setBairro('');
      setCidade('');
      setUf('');
      setLogradouro('');
      setNumero('');
      setComplemento('');
      setFuncaoSelecionada('');
      setCargoSelecionado('');
      setVagaSelecionada('');

      setAddAcesso([]);
      setBuscaModulos([]);
      setGee('');
    } catch (error) {
      console.log(error);
      toast.error('Erro, entre em contato com administrador!');
    }

    window.scrollTo(0, 0);
  };

  // CARREGAMENTOS INICIAIS
  useEffect(() => {
    loadGerencias();
    loadOrgao();
  }, []);

  // useEffect(() => {
  //   setVagaSelecionada(vagasDisponiveis[0]);
  // }, [cargoSelecionado]);

  // useEffect(() => {
  //   if (optionCargos[0] === undefined) {
  //     setCargoSelecionado(null);
  //     setVagaSelecionada(null);
  //     return;
  //   }
  //   setCargoSelecionado(optionCargos[0]);
  //   setVagaSelecionada(null);
  // }, [optionCargos]);

  // useEffect(() => {
  //   if (vagasDisponiveis[0] === undefined) {
  //     setVagaSelecionada(null);
  //     return;
  //   }
  //   setVagaSelecionada(vagasDisponiveis[0]);
  // }, [vagasDisponiveis]);

  // SELECT LOTACAO
  useEffect(() => {
    loadLotacoes();
  }, [gerenciaSelecionada]);

  return (
    <NavBar>
      {/* {this.state.notificacao} */}

      <MDBContainer>
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Dados Pessoais</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow
              start
              style={{ fontSize: '13px' }}
              className="ml-5 align-items-center"
            >
              <MDBIcon icon="asterisk" />{' '}
              <div className="ml-2">Preenchimento Obrigatório</div>
            </MDBRow>
            <MDBRow className="px-3">
              <MDBCol className="mt-3" size="12" md="4">
                <MDBInput
                  label="Nome *"
                  group
                  size="lg"
                  // style={{ borderColor: 'red' }}
                  onChange={e => setNome(e.target.value)}
                  value={nome}
                />
              </MDBCol>
              <MDBCol className="mt-3" size="12" md="4">
                <MDBInput
                  label="Sobrenome *"
                  group
                  size="lg"
                  onChange={e => setSobrenome(e.target.value)}
                  value={sobrenome}
                />
              </MDBCol>
              <MDBCol className="mt-3" size="12" md="4">
                <MDBInput
                  label="Nome Social *"
                  group
                  size="lg"
                  onChange={e => setNomeSocial(e.target.value)}
                  value={nomeSocial}
                />
              </MDBCol>
              <MDBCol className="mt-3" size="12" md="4">
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                  }}
                  className="w-100 font-weight-light md-form form-group form-lg"
                  type="text"
                  placeholder="CPF *"
                  onChange={e => setCpf(e.target.value.replace(/\D+/g, ''))}
                  value={cpf}
                  mask="999.999.999-99"
                  maskChar=" "
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <MDBInput
                  label="RG *"
                  group
                  size="lg"
                  onChange={e => setRg(e.target.value)}
                  value={rg}
                />
              </MDBCol>
              <MDBCol className="mt-3" size="12" md="4">
                <InputMask
                  style={{
                    border: 0,
                    borderBottom: '1px solid #ced4da',
                    fontSize: '1.25rem',
                  }}
                  className="w-100 font-weight-light md-form form-group form-lg"
                  type="text"
                  placeholder="Id Funcional *"
                  onChange={e =>
                    setIdFuncional(e.target.value.replace(/\D+/g, ''))
                  }
                  value={idFuncional}
                  mask="99999999999"
                  maskChar=" "
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <div className="mt-2">
                  <span className="grey-text">Gênero *</span>
                  <select
                    className="browser-default custom-select"
                    onChange={e => setGenero(e.target.value)}
                    value={genero}
                  >
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="N">Não Declarado</option>
                  </select>
                </div>
              </MDBCol>
              <MDBCol size="12" md="4">
                <MDBInput
                  className="mt-3"
                  label="Data de Nascimento *"
                  group
                  size="lg"
                  type="date"
                  onChange={e => setDataNascimento(e.target.value)}
                  value={dataNascimento}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <span
                  style={{
                    fontSize: '14px',
                  }}
                  className="grey-text"
                >
                  Tipo sanguíneo
                </span>
                <select
                  className="browser-default custom-select"
                  onChange={e => setTipoSanguineo(e.target.value)}
                  value={tipoSanguineo}
                >
                  <option value="">Tipo Sanguíneo</option>
                  <option>A+</option>
                  <option>A-</option>
                  <option>B+</option>
                  <option>B-</option>

                  <option>AB+</option>
                  <option>AB-</option>
                  <option>O+</option>
                  <option>O-</option>
                </select>

                {/* <MDBInput
                      label="Categoria da CNH"
                      type="email"
                      group
                      size="lg"
                      onChange={e => setCnhCategoria(e.target.value)}
                      value={cnhCategoria}
                    /> */}
              </MDBCol>
            </MDBRow>
            <MDBRow className="px-3">
              <MDBTypography className="w-100" blockquote bqColor="warning">
                <MDBBox tag="div" mb={0} className="bq-title">
                  Dados de Contato
                </MDBBox>
                <MDBRow>
                  <MDBCol size="12" md="4">
                    <MDBInput
                      label="Email *"
                      type="email"
                      group
                      size="lg"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <InputMask
                      style={{
                        border: 0,
                        borderBottom: '1px solid #ced4da',
                        fontSize: '1.25rem',
                        marginTop: '40px',
                      }}
                      className="w-100 font-weight-light md-form form-group form-lg"
                      type="text"
                      placeholder="Telefone *"
                      onChange={e =>
                        setTelefone(e.target.value.replace(/\D+/g, ''))
                      }
                      value={telefone}
                      mask="(99) 99999-9999"
                      maskChar=" "
                    />
                  </MDBCol>
                </MDBRow>
              </MDBTypography>
            </MDBRow>

            <MDBRow className="px-3">
              <MDBTypography className="w-100" blockquote bqColor="primary">
                <MDBBox tag="div" mb={0} className="bq-title">
                  Carteira Nacional de Habilitação
                </MDBBox>
                <MDBRow>
                  <MDBCol size="12" md="4">
                    <MDBInput
                      label="Nº CHN"
                      type="email"
                      group
                      size="lg"
                      onChange={e => setCnh(e.target.value)}
                      value={cnh}
                    />
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <span
                      style={{
                        fontSize: '14px',
                      }}
                      className="grey-text"
                    >
                      CNH Categoria
                    </span>
                    <select
                      className="browser-default custom-select"
                      onChange={e => setCnhCategoria(e.target.value)}
                      value={cnhCategoria}
                    >
                      <option value="">Sem Carteira</option>
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                      <option>E</option>
                      <option>AB</option>
                      <option>AC</option>
                      <option>AD</option>
                      <option>AE</option>
                    </select>

                    {/* <MDBInput
                      label="Categoria da CNH"
                      type="email"
                      group
                      size="lg"
                      onChange={e => setCnhCategoria(e.target.value)}
                      value={cnhCategoria}
                    /> */}
                  </MDBCol>
                  <MDBCol size="12" md="4">
                    <MDBInput
                      label="Validade da CNH"
                      type="date"
                      group
                      size="lg"
                      onChange={e => setCnhValidade(e.target.value)}
                      value={cnhValidade}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBTypography>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Endereço</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow className="px-3">
              <MDBCol className="mt-3 " size="12" md="4">
                <MDBRow>
                  <MDBCol size="8" md="8" className="pr-0">
                    <InputMask
                      style={{
                        border: 0,
                        borderBottom: '1px solid #ced4da',
                        fontSize: '1.25rem',
                      }}
                      className="w-100 font-weight-light md-form form-group form-lg"
                      type="text"
                      placeholder="CEP"
                      onChange={e => setCep(e.target.value.replace(/\D+/g, ''))}
                      value={cep}
                      mask="99999-999"
                      maskChar=" "
                    />
                  </MDBCol>
                  <MDBCol size="8" md="3" className="pl-0">
                    <MDBBtn
                      onClick={() => localizaCep(cep)}
                      floating
                      social="tw"
                      size="sm"
                    >
                      <MDBIcon icon="search" className="pr-0" />
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
            <MDBRow className="px-3">
              <MDBCol size="12" md="4">
                <MDBInput
                  className="mt-3"
                  label="Bairro"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setBairro(e.target.value)}
                  value={bairro}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <MDBInput
                  className="mt-3"
                  label="Cidade"
                  group
                  disabled
                  size="lg"
                  type="text"
                  onChange={e => setCidade(e.target.value)}
                  value={cidade}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <MDBInput
                  className="mt-3"
                  label="UF"
                  group
                  disabled
                  size="lg"
                  type="text"
                  onChange={e => setUf(e.target.value)}
                  value={uf}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow className="px-3">
              <MDBCol size="12" md="5">
                <MDBInput
                  className="mt-3"
                  label="logradouro"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setLogradouro(e.target.value)}
                  value={logradouro}
                />
              </MDBCol>
              <MDBCol size="12" md="2">
                <MDBInput
                  className="mt-3"
                  label="Número"
                  group
                  size="lg"
                  type="number"
                  onChange={e => setNumero(e.target.value)}
                  value={numero}
                />
              </MDBCol>
              <MDBCol size="12" md="5">
                <MDBInput
                  className="mt-3"
                  label="Complemento"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setComplemento(e.target.value)}
                  value={complemento}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Adido</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow center className="px-3">
              <MDBCol size="6" md="2">
                Usuário é Adido?
              </MDBCol>
              <MDBCol size="6" md="2">
                <MDBSwitch
                  checked={adido}
                  labelLeft="Não"
                  labelRight="Sim"
                  onChange={() => {
                    setAdido(!adido);
                  }}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow center className="px-3">
              <MDBCol size="12" md="4">
                <Select
                  options={dadosOrgaos}
                  placeholder="Selecione o Orgão"
                  isDisabled={!adido}
                  onChange={e => {
                    setOrgao(e.value);
                    loadTitulos(e.value);
                  }}
                />
              </MDBCol>
              <MDBCol size="12" md="4">
                <Select
                  isLoading={selectTitulosLoading}
                  options={dadosTitulos}
                  placeholder="Selecione o Título"
                  isDisabled={!adido}
                  onChange={e => setTitulo(e)}
                  value={titulo}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>

        <br />
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Nomeação</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow
              start
              style={{ fontSize: '13px' }}
              className="ml-5 align-items-center"
            >
              <MDBIcon icon="asterisk" />{' '}
              <div className="ml-2">Preenchimento Obrigatório</div>
            </MDBRow>
            <MDBRow className="px-3">
              <MDBCol size="12" md="6">
                <MDBInput
                  className="mt-3"
                  label="Nº Diário Oficial"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setDiarioOficial(e.target.value)}
                  value={diarioOficial}
                />
              </MDBCol>
              <MDBCol size="12" md="6">
                <MDBInput
                  className="mt-3"
                  label="Data de Nomeação *"
                  group
                  size="lg"
                  type="date"
                  onChange={e => setDoData(e.target.value)}
                  value={doData}
                />
              </MDBCol>
              <MDBCol size="12" md="6">
                <MDBInput
                  className="mt-3"
                  label="Nº do SEI *"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setNumSei(e.target.value)}
                  value={numSei}
                />
              </MDBCol>

              <MDBCol size="12" md="6">
                <MDBInput
                  className="mt-3"
                  label="Nº BOL"
                  group
                  size="lg"
                  type="text"
                  onChange={e => setNumBol(e.target.value)}
                  value={numBol}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>

        <br />
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Lotação</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow
              start
              style={{ fontSize: '13px' }}
              className="ml-5 align-items-center"
            >
              <MDBIcon icon="asterisk" />{' '}
              <div className="ml-2">Preenchimento Obrigatório</div>
            </MDBRow>
            <MDBRow center className="px-3">
              <MDBCol size="12" md="3">
                <Select
                  placeholder="Selecione a Gerência *"
                  options={dadosGerencias}
                  onChange={t => setGerenciaSelecionada(t.value)}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <Select
                  placeholder="Selecione a Lotação *"
                  isLoading={selectLotacaoLoading}
                  options={dadosLotacoes}
                  value={lotacaoSelecionada && lotacaoSelecionada}
                  onChange={t => setLotacaoSelecionada(t)}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow className="px-3">
              <MDBTypography className="w-100" blockquote bqColor="danger">
                <MDBBox tag="div" mb={0} className="bq-title">
                  Nível de Acesso
                </MDBBox>
                <MDBRow>
                  {buscaModulos == []
                    ? ''
                    : buscaModulos.map(linha => (
                        <>
                          <MDBCard className="mt-2 mx-2 w-100" key={linha.id}>
                            <MDBCardBody>
                              <div className="h5-responsive text-start font-weight-bold">
                                {linha.modulo.titulo}
                              </div>
                              <MDBRow className="justify-content-start">
                                {linha.modulo.funcionalidades.map(
                                  funcionalidades => (
                                    <MDBCol
                                      md="3"
                                      className="my-3"
                                      key={funcionalidades.id}
                                    >
                                      <MDBInput
                                        containerClass="form-check checkbox-teal"
                                        labelClass="form-check-label"
                                        label={funcionalidades.nome}
                                        type="checkbox"
                                        className="checkbox-rounded"
                                        // filled
                                        id={funcionalidades.id}
                                        getValue={t => {
                                          if (t == true) {
                                            setAddAcesso([
                                              {
                                                funcionalidade_id:
                                                  funcionalidades.id,
                                              },
                                              ...addAcesso,
                                            ]);
                                          } else {
                                            const deletaDado = [];
                                            addAcesso.map(obj => {
                                              if (
                                                obj.funcionalidade_id ===
                                                funcionalidades.id
                                              ) {
                                                return;
                                              }
                                              deletaDado.push(obj);
                                            });
                                            setAddAcesso(deletaDado);
                                            // addAcesso.filter((t)=>{
                                            //   return t !==
                                            // })
                                          }
                                        }}
                                        // onChange={t => {
                                        //   t.getValue() == true
                                        //     ? // console.log(t.target.id);
                                        //       console.log(t.value)
                                        //     : console.log('falso');
                                        // }}
                                        // getValue={t => {
                                        //   console.log(t);
                                        // }}
                                      />
                                    </MDBCol>
                                  )
                                )}
                              </MDBRow>
                            </MDBCardBody>
                          </MDBCard>
                        </>
                      ))}
                </MDBRow>
              </MDBTypography>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
        <MDBCard narrow>
          <MDBView
            className="mdb-color color-block-5 mb-3 text-center text-md-right pr-5 py-2"
            cascade
          >
            <h4 className="text-white font-weight-bolder">Função</h4>
          </MDBView>

          <MDBCardBody className="pt-0">
            <MDBRow
              start
              style={{ fontSize: '13px' }}
              className="ml-5 align-items-center"
            >
              <MDBIcon icon="asterisk" />{' '}
              <div className="ml-2">Preenchimento Obrigatório</div>
            </MDBRow>
            <MDBRow center>
              {gerenciaSelecionada === '' ? (
                <div className="text-danger text-center">
                  ** Selecione a Gerência
                </div>
              ) : (
                ''
              )}
            </MDBRow>

            <MDBRow center className="px-3 d-flex align-items-center">
              <MDBCol size="12" md="3">
                <Select
                  placeholder="Função *"
                  options={optionFuncoes}
                  value={funcaoSelecionada}
                  onChange={t => {
                    setFuncaoSelecionada(t);
                    selectCargo(t);
                  }}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <Select
                  placeholder="Cargo *"
                  onChange={t => {
                    setCargoSelecionado(t);
                    selectVaga(t.value);
                    // setVagaSelecionada(vagasDisponiveis[0]);
                  }}
                  value={cargoSelecionado}
                  options={optionCargos}
                />
              </MDBCol>

              <MDBCol size="12" md="2">
                <Select
                  onChange={t => setVagaSelecionada(t)}
                  options={vagasDisponiveis}
                  placeholder="Nº da Vaga *"
                  value={vagaSelecionada}
                />
              </MDBCol>
              <MDBCol size="12" md="3">
                <MDBInput
                  label="Gee"
                  group
                  type="number"
                  // value={idFuncional}
                  onChange={e => setGee(e.target.value)}
                />
              </MDBCol>
            </MDBRow>
          </MDBCardBody>
        </MDBCard>
        <br />
        <MDBRow center>
          <MDBBtn onClick={enviaDados} color="mdb-color">
            Criar Usuário
          </MDBBtn>
        </MDBRow>

        <br />
        <br />
        <br />
      </MDBContainer>
    </NavBar>
  );
}

export default AddUsuario;
